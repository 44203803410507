import { SpecialAsset, SpecialAssetSymbol } from '../special-assets'
import { METH } from '../../tokens/METH'
import mapChainIdMapValues from '../../../utils/mapChainIdMapValues'

export const METH_SPECIAL_ASSET: () => SpecialAsset = () => ({
  symbol: SpecialAssetSymbol.mETH,
  safeSymbol: SpecialAssetSymbol.mETH,
  cleanSymbol: 'mETH',
  cleanName: 'Mantle ETH',
  hasExternalRewards: false,
  documentationUrl: undefined,
  isIsolationMode: false,
  isBorrowingEnabled: true,
  chainIdToAddressMap: mapChainIdMapValues(METH, t => t?.address),
  isolationModeInfo: undefined,
})
