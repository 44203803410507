import { Token } from '@dolomite-exchange/sdk-core'
import { ChainId, ChainIdMap, initializeSingleValueChainIdMap } from '../chainId'

const decimals = 18
const symbol = 'esGMX'
const name = 'Escrowed GMX'

export const ES_GMX: ChainIdMap<Token | undefined> = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  new Token(ChainId.ARBITRUM_ONE, '0xf42Ae1D54fd613C9bb14810b0588FaAa09a426cA', decimals, symbol, name),
)
