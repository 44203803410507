import { MarginPositionType } from '../types/marginPositionData'
import { Token } from '@dolomite-exchange/v2-sdk'

function isUsdc(token: Token): boolean {
  return token.symbol === 'USDC' || token.symbol === 'USDC.e'
}

export default function derivePositionTypeFromTokens(heldToken?: Token, owedToken?: Token): MarginPositionType {
  if (!owedToken || !heldToken) {
    return MarginPositionType.UNKNOWN
  }

  if (isUsdc(owedToken)) {
    return MarginPositionType.LONG
  } else if (owedToken.symbol === 'USDT') {
    if (isUsdc(heldToken)) {
      return MarginPositionType.SHORT
    } else {
      return MarginPositionType.LONG
    }
  } else if (owedToken.symbol === 'DAI') {
    if (isUsdc(heldToken) || heldToken.symbol === 'USDT') {
      return MarginPositionType.SHORT
    } else {
      return MarginPositionType.LONG
    }
  } else if (isUsdc(heldToken)) {
    return MarginPositionType.SHORT
  } else if (heldToken.symbol === 'DAI') {
    return MarginPositionType.SHORT
  } else {
    return MarginPositionType.CROSS
  }
}
