import { useMemo } from 'react'
import JSBI from 'jsbi'
import { Trade } from './tradeData'
import { ammTradeGql } from './queryObjects'
import { gql } from '@apollo/client'
import {
  AmmTrade,
  createCurrencyAmount,
  createFraction,
  createFractionUSD,
  createTransaction,
  EntityType,
} from './gqlTypeHelpers'
import { Pair, Token } from '@dolomite-exchange/v2-sdk'
import { BIG_INT_ZERO, ZERO_FRACTION } from '../constants'
import { deriveMarketFromTokensReq } from '../utils/marketUtils'
import { useGraphqlResult } from '../state/graphql/hooks'
import { GraphqlClientType } from '../state/graphql/actions'
import { RefreshFrequency } from '../state/chain/hooks'

const TRADES_BY_PAIR = gql`
    query poolTradesByPair($blockNumber: Int!, $pairAddress: String!) {
        ammTrades(
            block: { number_gte: $blockNumber }
            where: { pair: $pairAddress },
            orderBy: serialId,
            orderDirection: desc,
            first: 100
        ) {
            ${ammTradeGql()}
        }
    }
`

interface TradesResponse {
  ammTrades: AmmTrade[]
}

export function useAmmTradeDataByPair(
  pair: Pair | undefined,
): {
  loading: boolean
  error: boolean
  data: Trade[]
} {
  const variables = useMemo(() => {
    if (!pair) {
      return undefined
    }
    return {
      pairAddress: pair.liquidityToken.address.toLowerCase(),
    }
  }, [pair])

  const queryState = useGraphqlResult<TradesResponse>(
    GraphqlClientType.Dolomite,
    TRADES_BY_PAIR.loc!.source.body,
    variables,
    RefreshFrequency.Medium,
  )

  return useMemo(() => {
    const { loading, error, result } = queryState
    const anyLoading = Boolean(loading)
    const anyError = Boolean(error)

    const trades = (result?.ammTrades ?? [])
      .map<Trade | undefined>(trade => {
        if (!pair) {
          return undefined
        }

        const amount0In = createFraction(trade.amount0In)
        const amount0Out = createFraction(trade.amount0Out)

        const takerToken: Token = createFraction(trade.amount0In).greaterThan(ZERO_FRACTION) ? pair.token0 : pair.token1
        const makerToken: Token = createFraction(trade.amount0Out).greaterThan(ZERO_FRACTION)
          ? pair.token0
          : pair.token1
        const takerTokenDeltaWei = createCurrencyAmount(
          takerToken,
          amount0In.greaterThan(ZERO_FRACTION) ? trade.amount0In : trade.amount1In,
        )
        const makerTokenDeltaWei = createCurrencyAmount(
          makerToken,
          amount0Out.greaterThan(ZERO_FRACTION) ? trade.amount0Out : trade.amount1Out,
        )
        const market = deriveMarketFromTokensReq(takerTokenDeltaWei.currency, makerTokenDeltaWei.currency)
        const primaryDeltaWei = takerToken.symbol === market.primary ? takerTokenDeltaWei : makerTokenDeltaWei
        const secondaryDeltaWei = takerToken.symbol === market.secondary ? takerTokenDeltaWei : makerTokenDeltaWei
        const transaction = createTransaction(trade.transaction)
        return {
          id: trade.id,
          type: EntityType.Trade,
          serialId: JSBI.BigInt(trade.serialId),
          transaction: transaction,
          logIndex: JSBI.BigInt(trade.logIndex),
          takerAccount: {
            account: trade.to,
            accountNumber: BIG_INT_ZERO,
            lastUpdatedTimestamp: new Date(),
            lastUpdatedBlockNumber: transaction.blockNumber,
          },
          makerAccount: undefined,
          primary: primaryDeltaWei.currency,
          secondary: secondaryDeltaWei.currency,
          market: market,
          takerDeltaWei: takerTokenDeltaWei,
          makerDeltaWei: makerTokenDeltaWei,
          primaryDeltaWei: primaryDeltaWei,
          secondaryDeltaWei: secondaryDeltaWei,
          primaryPriceWei: secondaryDeltaWei.asFraction.divide(primaryDeltaWei.asFraction),
          takerAmountUSD: createFractionUSD(trade.amountUSD),
          makerAmountUSD: createFractionUSD(trade.amountUSD),
          traderAddress: pair.liquidityToken.address,
          isExpiration: false,
        }
      })
      .filter(trade => !!trade)
      .map(trade => trade!)

    return {
      loading: anyLoading,
      error: anyError,
      data: trades,
    }
  }, [pair, queryState])
}
