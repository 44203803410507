import { Space as SpaceGql } from './gqlTypeHelpers'
import { useMemo } from 'react'
import { NO_VARIABLES, useGraphqlResult } from '../state/graphql/hooks'
import { GraphqlClientType } from '../state/graphql/actions'

import { RefreshFrequency } from '../state/chain/hooks'
import { DOLOMITE_API_SERVER_URL } from '@dolomite-exchange/zap-sdk'

interface XP {
  name: string
  value: string
}

export enum CampaignType {
  MILESTONES,
  SPECIAL,
  SOCIAL,
}

export interface Credential {
  id: string
  name: string
  description: string
}

export interface GalxeCampaignData {
  id: string
  name: string
  description: string
  endTime: number
  xp: XP[]
  isPrivate: boolean
  type: CampaignType
  credentials: Credential[]
}

export function useGalxeCampaignData(): {
  loading: boolean
  error: boolean
  data: GalxeCampaignData[] | undefined
} {
  const state = useGraphqlResult<SpaceGql>(
    GraphqlClientType.Fetch,
    `${DOLOMITE_API_SERVER_URL}/galxe/campaigns`,
    NO_VARIABLES,
    RefreshFrequency.Slow,
  )
  return useMemo(() => {
    const { loading, error, result } = state
    const anyLoading = Boolean(loading)
    const anyError = Boolean(error)

    const campaignDatas = (result?.data.space.campaigns.edges ?? []).reduce((memo, value) => {
      const node = value.node
      const templates = node.nftTemplates
      if (templates) {
        memo.push({
          id: node.id,
          name: node.name,
          description: node.description,
          endTime: node.endTime,
          xp: templates[0]?.traits,
          isPrivate: templates[0]?.traits.some(trait => trait.name === 'Private') ?? false,
          type:
            templates[0]?.traits[1]?.value === 'Special'
              ? CampaignType.SPECIAL
              : templates[0]?.traits[1]?.value === 'Milestone'
              ? CampaignType.MILESTONES
              : CampaignType.SOCIAL,
          credentials: node.credentialGroups[0]?.credentials,
        })
      }
      return memo
    }, [] as GalxeCampaignData[])

    return {
      loading: anyLoading,
      error: anyError,
      data: campaignDatas,
    }
  }, [state])
}
