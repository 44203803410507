import React, { useMemo } from 'react'
import { CurrencyAmount, Pair } from '@dolomite-exchange/v2-sdk'
import styled from 'styled-components/macro'
import { useActiveWeb3React } from '../../hooks'
import { useTradeState } from '../../state/trade/hooks'
import { Field } from '../../state/trade/actions'
import { useToken } from '../../hooks/Tokens'
import ArrowForward from '@material-ui/icons/ArrowForward'
import toDate from '../../utils/toDate'
import { useAmmTradeDataByPair } from '../../types/poolTradeData'
import Column from '../../components/Orders/Column'
import { formatAmount } from '../../utils/formatAmount'
import cleanCurrencySymbol from '../../utils/cleanCurrencySymbol'
import { ChainId } from '../../constants'

const RecentTradesWrapper = styled.div`
  display: inline-block;
  width: 300px;
  height: 100%;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    max-width: 400px;
    height: 320px;
    margin-bottom: 5px;
    padding: 0 10px;
  `};
`

const TradesTable = styled.div`
  height: calc(100% - 10px);
  width: 100%;
  border-radius: 8px;
  display: inline-block;
  vertical-align: top;
  font-family: 'Open Sans', sans-serif;
  text-align: left;
  padding: 15px 5px 10px 20px;
  font-size: 12px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 20px 2px 0px;
    font-size: 12px;
    margin: 0;
    height: 100%;
    width: 100%;
  `};
`

export const ColumnTitles = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  margin-bottom: 5px;
  color: ${({ theme }) => theme.text3};
`

export const TransactionRows = styled.div`
  margin-bottom: 5px;
  overflow-y: scroll;
  height: calc(100% - 25px);

  ::-webkit-scrollbar {
    display: none;
  }
`

const TransactionRow = styled.div`
  width: 100%;
  height: 20px;
  padding: 2px 0;
  display: flex;
  justify-content: space-between;

  a {
    text-decoration: none !important;

    :hover {
      color: ${({ theme }) => theme.text2} !important;
    }
  }

  svg {
    font-size: 14px;
    margin-bottom: -2px;
  }
`

const Unit = styled.span`
  color: ${({ theme }) => theme.text3};
`

export default function RecentTrades({ isMobile }: { isMobile?: boolean }) {
  const { chainId } = useActiveWeb3React()
  const isInvalidChain = chainId !== ChainId.ARBITRUM_ONE
  const {
    [Field.INPUT]: { currencyId: inputCurrencyId },
    [Field.OUTPUT]: { currencyId: outputCurrencyId },
  } = useTradeState()
  const token0 = useToken(inputCurrencyId)
  const token1 = useToken(outputCurrencyId)
  const pair = useMemo(() => {
    if (!token0 || !token1 || isInvalidChain) {
      return undefined
    }

    return new Pair(CurrencyAmount.fromRawAmount(token0, '0'), CurrencyAmount.fromRawAmount(token1, '0'), chainId)
  }, [chainId, isInvalidChain, token0, token1])
  const { data: tradeData, loading: tradesLoading } = useAmmTradeDataByPair(pair)
  const tradeMemo = useMemo(() => tradeData, [tradeData])

  return (
    <RecentTradesWrapper>
      <TradesTable>
        <ColumnTitles>
          <Column width={73} textAlign={'left'}>
            Trade
          </Column>
          <Column width={27} textAlign={'right'}>
            Time
          </Column>
        </ColumnTitles>
        <TransactionRows>
          {tradeMemo.map((trade, i) => {
            const fromAsset = trade.takerDeltaWei.currency
            const toAsset =
              trade.primary.address === trade.takerDeltaWei.currency.address
                ? trade.secondaryDeltaWei
                : trade.primaryDeltaWei

            return (
              <TransactionRow key={`recentTrades-${i}`}>
                <Column width={73} textAlign={'left'}>
                  {formatAmount(trade.takerDeltaWei)} <Unit>{cleanCurrencySymbol(fromAsset)}</Unit>
                  <ArrowForward />
                  {formatAmount(toAsset)} <Unit>{cleanCurrencySymbol(toAsset.currency)}</Unit>
                </Column>
                <Column width={27} textAlign={'right'}>
                  {toDate(trade.transaction.timestamp as Date).replace('a few seconds ago', '< 1 min ago')}
                </Column>
              </TransactionRow>
            )
          })}
        </TransactionRows>
      </TradesTable>
    </RecentTradesWrapper>
  )
}
