import gql from 'graphql-tag'
import {
  AmmPairDayData as AmmPairDayDataGql,
  createCurrencyAmount,
  createFraction,
  createFractionUSD,
} from './gqlTypeHelpers'
import { ammPairDayDataGql } from './queryObjects'
import { useMemo } from 'react'
import { Fraction } from '@dolomite-exchange/sdk-core'
import { CurrencyAmount, Pair, Token } from '@dolomite-exchange/v2-sdk'
import { useGraphqlResult } from '../state/graphql/hooks'
import { GraphqlClientType } from '../state/graphql/actions'
import { useAllTokens } from '../hooks/Tokens'
import { toChecksumAddress } from '../utils/toChecksumAddress'
import { RefreshFrequency } from '../state/chain/hooks'

const DAY_DATA_BY_PAIR_GQL = gql`
    query allAmmPairData($blockNumber: Int!, $pairAddress: String!) {
        ammPairDayDatas(
            orderBy: dayStartUnix
            orderDirection: desc
            block: { number_gte: $blockNumber }
            where: {
                pairAddress: $pairAddress,
            }
            first: 365
        ) {
            ${ammPairDayDataGql(false)}
        }
    }
`

interface AmmPairDayDataResponse {
  ammPairDayDatas: AmmPairDayDataGql[]
}

export interface AmmPairDayData {
  dayStartUnix: number
  dailyVolumeUSD: Fraction
  token0: Token
  token1: Token
  reserve0: CurrencyAmount<Token>
  reserve1: CurrencyAmount<Token>
  reserveUSD: Fraction
  totalSupply: Fraction
}

/**
 * Returns the last year of daily data for the given pair.
 */
export function useLastYearPairDayData(
  pair: Pair | string | undefined,
): {
  loading: boolean
  error: boolean
  data: AmmPairDayData[]
} {
  const pairAddress = pair
    ? typeof pair === 'string'
      ? pair.toLowerCase()
      : pair.liquidityToken.address.toLowerCase()
    : undefined

  const variables = useMemo(() => {
    if (!pairAddress) {
      return undefined
    }
    return {
      pairAddress,
    }
  }, [pairAddress])
  const queryState = useGraphqlResult<AmmPairDayDataResponse>(
    GraphqlClientType.Dolomite,
    DAY_DATA_BY_PAIR_GQL.loc!.source.body,
    variables,
    RefreshFrequency.Slow,
  )
  const tokenMap = useAllTokens()

  return useMemo(() => {
    const { loading, error, result } = queryState

    const anyLoading = Boolean(loading)
    const anyError = Boolean(error)

    const pairDayData = (result?.ammPairDayDatas ?? [])
      .map<AmmPairDayData | undefined>(value => {
        const token0 = tokenMap[toChecksumAddress(value.token0.id)]
        const token1 = tokenMap[toChecksumAddress(value.token1.id)]
        if (!token0 || !token1) {
          return undefined
        }

        return {
          dayStartUnix: value.dayStartUnix,
          token0: token0,
          token1: token1,
          dailyVolumeUSD: createFractionUSD(value.dailyVolumeUSD),
          reserve0: createCurrencyAmount(token0, value.reserve0),
          reserve1: createCurrencyAmount(token1, value.reserve1),
          reserveUSD: createFractionUSD(value.reserveUSD),
          totalSupply: createFraction(value.totalSupply),
        }
      })
      .filter((value): value is AmmPairDayData => Boolean(value))
      .sort((a, b) => a.dayStartUnix - b.dayStartUnix)

    return {
      loading: anyLoading,
      error: anyError,
      data: pairDayData,
    }
  }, [queryState, tokenMap])
}
