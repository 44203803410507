import { Token } from '@dolomite-exchange/sdk-core'
import { ChainId, ChainIdMap, initializeSingleValueChainIdMap } from '../chainId'

const decimals = 18
const symbol = 'wOETH'
const name = 'Wrapped OETH'

function getToken(chainId: ChainId, address: string) {
  return new Token(chainId, address, decimals, symbol, name)
}

export const WO_ETH: ChainIdMap<Token | undefined> = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  getToken(ChainId.ARBITRUM_ONE, '0xd8724322f44e5c58d7a815f542036fb17dbbf839'),
)
