import { gql } from '@apollo/client'
import { AmmPairDayData as AmmPairDayDataGql, createFractionUSD } from './gqlTypeHelpers'
import { ammPairDayDataGql } from './queryObjects'
import { useMemo } from 'react'
import { Fraction } from '@dolomite-exchange/sdk-core'
import { Pair } from '@dolomite-exchange/v2-sdk'
import { useGraphqlResult } from '../state/graphql/hooks'
import { GraphqlClientType } from '../state/graphql/actions'
import { RefreshFrequency, useBlockTimestamp } from '../state/chain/hooks'

const LAST_WEEK_PAIR_DATA_GQL = gql`
    query lastWeekOfAmmPairData($blockNumber: Int!, $pairAddress: String!, $endTimestamp: Int!, $startTimestamp: Int!) {
        ammPairDayDatas(
            orderBy: dayStartUnix
            orderDirection: desc
            block: { number_gte: $blockNumber }
            where: {
                pairAddress: $pairAddress,
                dayStartUnix_lte: $endTimestamp,
                dayStartUnix_gt: $startTimestamp
            }
        ) {
            ${ammPairDayDataGql(false)}
        }
    }
`

interface AmmPairDayDataResponse {
  ammPairDayDatas: AmmPairDayDataGql[]
}

export interface AmmPairDayData {
  dayStartUnix: number
  dailyVolumeUSD: Fraction
}

export function useCurrentWeekPairDayData(
  pair: Pair | string | undefined,
): {
  loading: boolean
  error: boolean
  data: AmmPairDayData[]
} {
  const timestamp = useBlockTimestamp()

  const startTimestamp = useMemo(() => {
    return timestamp
      .div('86400')
      .sub('7')
      .mul('86400')
      .toNumber()
  }, [timestamp])

  const endTimestamp = useMemo(() => {
    return timestamp
      .div('86400')
      .mul('86400')
      .toNumber()
  }, [timestamp])

  const pairAddress = pair ? (typeof pair === 'string' ? pair : pair.liquidityToken.address.toLowerCase()) : undefined

  const variables = useMemo(() => {
    if (!pairAddress) {
      return undefined
    }

    return {
      pairAddress,
      startTimestamp,
      endTimestamp,
    }
  }, [pairAddress, endTimestamp, startTimestamp])

  const queryState = useGraphqlResult<AmmPairDayDataResponse>(
    GraphqlClientType.Dolomite,
    LAST_WEEK_PAIR_DATA_GQL.loc!.source.body,
    variables,
    RefreshFrequency.Medium,
  )

  return useMemo(() => {
    const { loading, error, result } = queryState

    const anyLoading = Boolean(loading)
    const anyError = Boolean(error)

    const pairDayData = (result?.ammPairDayDatas ?? []).map<AmmPairDayData>(value => {
      return {
        dayStartUnix: value.dayStartUnix,
        dailyVolumeUSD: createFractionUSD(value.dailyVolumeUSD),
      }
    })

    return {
      loading: anyLoading,
      error: anyError,
      data: pairDayData,
    }
  }, [queryState])
}
