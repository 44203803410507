import { gql } from '@apollo/client'
import { AmmBurn, createCurrencyAmount, createFraction, createFractionUSD, createTransaction } from './gqlTypeHelpers'
import { ammBurnGql } from './queryObjects'
import { useAllTokens } from '../hooks/Tokens'
import { useMemo } from 'react'
import { AmmMintOrBurn } from './apiTypeHelpers'
import { ZERO_ADDRESS } from '../constants'
import { toChecksumAddress } from '../utils/toChecksumAddress'
import { Pair } from '@dolomite-exchange/v2-sdk'
import { useGraphqlResult } from '../state/graphql/hooks'
import { GraphqlClientType } from '../state/graphql/actions'
import { RefreshFrequency } from '../state/chain/hooks'

const BURNS_BY_WALLET_GQL = gql`
    query burnsByWallet($blockNumber: Int!, $walletAddress: String!, $skip: Int!) {
        ammBurns(
            block: { number_gte: $blockNumber },
            where: { sender: $walletAddress },
            orderBy: serialId,
            orderDirection: desc,
            first: 100,
            skip: $skip,
        ) {
            ${ammBurnGql()}
        }
    }
`

const BURNS_BY_PAIR_GQL = gql`
    query burnsByPair($blockNumber: Int!, $pairAddress: String!, $skip: Int!) {
        ammBurns(
            block: { number_gte: $blockNumber },
            where: { pair: $pairAddress },
            orderBy: serialId,
            orderDirection: desc,
            first: 100,
            skip: $skip,
        ) {
            ${ammBurnGql()}
        }
    }
`

const BURNS_BY_BOTH_GQL = gql`
    query burnsByBoth($blockNumber: Int!, $pairAddress: String!, $walletAddress: String!) {
        ammBurns(
            block: { number_gte: $blockNumber },
            where: { pair: $pairAddress, to: $walletAddress },
            orderBy: serialId,
            orderDirection: desc,
            first: 100,
        ) {
            ${ammBurnGql()}
        }
    }
`

interface AmmBurnResponse {
  ammBurns: AmmBurn[]
}

/**
 * @param walletAddress The user's wallet address
 * @param pageIndex 0-index page of data to load
 */
export function useAmmBurnDataByWallet(
  walletAddress: string | undefined,
  pageIndex: number,
): {
  loading: boolean
  error: boolean
  data: AmmMintOrBurn[]
} {
  const variables = useMemo(() => {
    if (!walletAddress) {
      return undefined
    }
    return {
      walletAddress: walletAddress.toLowerCase() ?? ZERO_ADDRESS,
      skip: pageIndex * 100,
    }
  }, [pageIndex, walletAddress])
  const queryState = useGraphqlResult<AmmBurnResponse>(
    GraphqlClientType.Dolomite,
    BURNS_BY_WALLET_GQL.loc!.source.body,
    variables,
    RefreshFrequency.Fast,
  )

  const tokenMap = useAllTokens()

  return useMemo(() => {
    const anyLoading = Boolean(queryState.loading)
    const anyError = Boolean(queryState.error)
    const burns = (queryState.result?.ammBurns ?? [])
      .map<AmmMintOrBurn | undefined>(burn => {
        const token0 = tokenMap[toChecksumAddress(burn.pair.token0.id)]
        const token1 = tokenMap[toChecksumAddress(burn.pair.token1.id)]
        if (!token0 || !token1) {
          return undefined
        }

        return {
          isMint: false,
          transaction: createTransaction(burn.transaction),
          logIndex: Number.parseInt(burn.logIndex),
          account: burn.to,
          token0: token0,
          token1: token1,
          amount0: createCurrencyAmount(token0, burn.amount0),
          amount1: createCurrencyAmount(token1, burn.amount1),
          liquidity: createFraction(burn.liquidity),
          pairAddress: toChecksumAddress(burn.pair.id),
          amountUSD: createFractionUSD(burn.amountUSD),
        }
      })
      .filter(burn => !!burn)
      .map<AmmMintOrBurn>(burn => burn!)

    return {
      loading: anyLoading,
      error: anyError,
      data: burns,
    }
  }, [queryState, tokenMap])
}

export function useAmmBurnDataByPair(
  pair: Pair | undefined,
  pageIndex: number,
): {
  loading: boolean
  error: boolean
  data: AmmMintOrBurn[]
} {
  const variables = useMemo(() => {
    if (!pair) {
      return undefined
    }
    return {
      pairAddress: pair.liquidityToken.address.toLowerCase(),
      skip: pageIndex * 100,
    }
  }, [pageIndex, pair])
  const queryState = useGraphqlResult<AmmBurnResponse>(
    GraphqlClientType.Dolomite,
    BURNS_BY_PAIR_GQL.loc!.source.body,
    variables,
    RefreshFrequency.Medium,
  )

  const tokenMap = useAllTokens()

  return useMemo(() => {
    const anyLoading = Boolean(queryState.loading)
    const anyError = Boolean(queryState.error)
    const burns = (queryState.result?.ammBurns ?? [])
      .map<AmmMintOrBurn | undefined>(burn => {
        const token0 = tokenMap[toChecksumAddress(burn.pair.token0.id)]
        const token1 = tokenMap[toChecksumAddress(burn.pair.token1.id)]
        if (!token0 || !token1) {
          return undefined
        }

        return {
          isMint: false,
          transaction: createTransaction(burn.transaction),
          logIndex: Number.parseInt(burn.logIndex),
          account: burn.to,
          token0: token0,
          token1: token1,
          amount0: createCurrencyAmount(token0, burn.amount0),
          amount1: createCurrencyAmount(token1, burn.amount1),
          liquidity: createFraction(burn.liquidity),
          pairAddress: toChecksumAddress(burn.pair.id),
          amountUSD: createFractionUSD(burn.amountUSD),
        }
      })
      .filter(burn => !!burn)
      .map<AmmMintOrBurn>(burn => burn!)

    return {
      loading: anyLoading,
      error: anyError,
      data: burns,
    }
  }, [queryState, tokenMap])
}

export function useAmmBurnDataByWalletPair(
  pair: Pair | undefined,
  walletAddress: string | undefined,
): {
  loading: boolean
  error: boolean
  data: AmmMintOrBurn[]
} {
  const variables = useMemo(() => {
    if (!pair || !walletAddress) {
      return undefined
    }
    return {
      pairAddress: pair.liquidityToken.address.toLowerCase(),
      walletAddress: walletAddress.toLowerCase(),
    }
  }, [pair, walletAddress])
  const queryState = useGraphqlResult<AmmBurnResponse>(
    GraphqlClientType.Dolomite,
    BURNS_BY_BOTH_GQL.loc!.source.body,
    variables,
    RefreshFrequency.Fast,
  )

  return useMemo(() => {
    const anyLoading = Boolean(queryState.loading)
    const anyError = Boolean(queryState.error)
    const burns = (queryState.result?.ammBurns ?? [])
      .map<AmmMintOrBurn | undefined>(burn => {
        if (!pair) {
          return undefined
        }

        return {
          isMint: true,
          transaction: createTransaction(burn.transaction),
          logIndex: Number.parseInt(burn.logIndex),
          account: burn.to,
          token0: pair.token0,
          token1: pair.token1,
          amount0: createCurrencyAmount(pair.token0, burn.amount0),
          amount1: createCurrencyAmount(pair.token1, burn.amount1),
          liquidity: createFraction(burn.liquidity),
          pairAddress: toChecksumAddress(burn.pair.id),
          amountUSD: createFractionUSD(burn.amountUSD),
        }
      })
      .filter(burn => !!burn)
      .map(burn => burn!)

    return {
      loading: anyLoading,
      error: anyError,
      data: burns,
    }
  }, [queryState, pair])
}
