import { Fraction, Percent } from '@dolomite-exchange/sdk-core'
import { ZERO_FRACTION, ZERO_PERCENT } from '../constants'
import { BorrowPositionAmount } from '../types/borrowPositionData'
import { InterestRate } from '../types/interestRateData'

export function calculateNetInterestRatePercent(
  borrowAmounts: BorrowPositionAmount[],
  interestRateMap: Record<string, InterestRate | undefined>,
): Percent {
  const totalSupplyUSD = borrowAmounts.reduce((acc, borrowAmount) => {
    if (borrowAmount.isPositive) {
      return acc.add(borrowAmount.amountUSD)
    } else {
      return acc
    }
  }, ZERO_FRACTION)

  if (totalSupplyUSD.equalTo(ZERO_FRACTION)) {
    return ZERO_PERCENT
  }

  const netInterest = borrowAmounts.reduce<Fraction>((memo, amount) => {
    const interestRate = interestRateMap[amount.token.address]
    if (!interestRate) {
      return memo
    }

    if (amount.isPositive) {
      return memo.add(amount.amountUSD.multiply(interestRate.totalSupplyInterestRate))
    } else {
      return memo.subtract(amount.amountUSD.multiply(interestRate.totalBorrowInterestRate))
    }
  }, ZERO_FRACTION)

  const netInterestFraction = netInterest.divide(totalSupplyUSD)
  return new Percent(netInterestFraction.numerator, netInterestFraction.denominator)
}

export function calculateSupplyInterestRatePercent(
  borrowAmounts: BorrowPositionAmount[],
  interestRateMap: Record<string, InterestRate | undefined>,
): Percent {
  const totalSupplyUSD = borrowAmounts.reduce((acc, borrowAmount) => {
    if (borrowAmount.isPositive) {
      return acc.add(borrowAmount.amountUSD)
    } else {
      return acc
    }
  }, ZERO_FRACTION)

  if (totalSupplyUSD.equalTo(ZERO_FRACTION)) {
    return ZERO_PERCENT
  }

  const supplyInterest = borrowAmounts.reduce<Fraction>((memo, amount) => {
    const interestRate = interestRateMap[amount.token.address]
    if (!interestRate) {
      return memo
    }

    if (amount.isPositive) {
      return memo.add(amount.amountUSD.multiply(interestRate.totalSupplyInterestRate))
    } else {
      return memo
    }
  }, ZERO_FRACTION)

  const supplyInterestRateFraction = supplyInterest.divide(totalSupplyUSD)
  return new Percent(supplyInterestRateFraction.numerator, supplyInterestRateFraction.denominator)
}

export function calculateBorrowInterestRatePercent(
  borrowAmounts: BorrowPositionAmount[],
  interestRateMap: Record<string, InterestRate | undefined>,
): Percent {
  const totalSupplyUSD = borrowAmounts.reduce((acc, borrowAmount) => {
    if (borrowAmount.isPositive) {
      return acc.add(borrowAmount.amountUSD)
    } else {
      return acc
    }
  }, ZERO_FRACTION)

  if (totalSupplyUSD.equalTo(ZERO_FRACTION)) {
    return ZERO_PERCENT
  }

  const borrowInterest = borrowAmounts.reduce<Fraction>((memo, amount) => {
    const interestRate = interestRateMap[amount.token.address]
    if (!interestRate) {
      return memo
    }

    if (!amount.isPositive) {
      return memo.add(amount.amountUSD.multiply(interestRate.totalBorrowInterestRate))
    } else {
      return memo
    }
  }, ZERO_FRACTION)

  const borrowInterestRateFraction = borrowInterest.divide(totalSupplyUSD)
  return new Percent(borrowInterestRateFraction.numerator, borrowInterestRateFraction.denominator)
}
