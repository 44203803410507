import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'

const FirstVisitWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
`

const FirstVisitInner = styled.div`
  width: 600px;
  height: fit-content;
  border-radius: 8px;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
  background: ${({ theme }) => theme.bg2};
  margin: calc(50vh - 430px) auto 0;
  overflow: hidden;
  padding: 20px 30px;

  @media screen and (max-width: 850px) {
    margin-top: 8vh;
  }

  @media screen and (max-height: 850px) {
    margin: 7vh auto 0;
    max-width: 95%;
    max-height: 85vh;
    overflow-y: scroll;
  }

  @media screen and (max-width: 615px) {
    max-width: 95%;
    max-height: 80vh;
    overflow-y: scroll;
  }

  @media screen and (max-width: 420px) {
    margin-top: 5vh;
  }
`

const FirstVisitInnerMobile = styled(FirstVisitInner)`
  width: 80%;
  max-width: 400px;
  min-width: 280px;
  padding: 15px 20px;

  > div > button {
    width: 100%;
    cursor: pointer;
    display: inline-block;
    vertical-align: top;
    border: none;
    height: 49px;
    font-size: 18px;
    line-height: 22px;
    padding: 12px 28px;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.green1} !important;
    color: ${({ theme }) => theme.text1} !important;
    margin: 0 auto 10px !important;
  }
`

const Title = styled.div`
  font-size: 32px;
  font-weight: 500;
  margin-bottom: 20px;
`

const Subtitle = styled.div`
  font-size: 15px;
  font-weight: 200;
  margin-bottom: 15px;
  line-height: 21px;

  a {
    color: ${({ theme }) => theme.text1};
    font-weight: 700;
    text-decoration: none;

    :hover {
      color: ${({ theme }) => theme.text2};
    }
  }

  @media screen and (max-height: 700px) {
    font-size: 13px;
  }

  @media screen and (max-width: 420px) {
    font-size: 13px;
    line-height: 18px;
  }
`

const LimitedSubtitle = styled(Subtitle)`
  max-height: 30vh;
  overflow-y: scroll;
  background-color: ${({ theme }) => theme.bg1};
  padding: 10px 15px;
  border-radius: 3px;
`

const StartButtonsWrapper = styled.div<{ disabled: boolean }>`
  width: 100%;
  text-align: right;
  margin-top: 30px;
  margin-bottom: 10px;
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'auto')};

  button {
    cursor: pointer;
    display: inline-block;
    vertical-align: top;
    border: none;
    height: 34px;
    font-size: 15px;
    line-height: 22px;
  }

  button:nth-of-type(1) {
    color: ${({ theme }) => theme.text3};
    background: none;
    margin-right: 8px;
  }

  button:nth-of-type(2) {
    padding: 6px 12px;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.green1};
    color: ${({ theme }) => theme.text1};
  }

  @media screen and (max-width: 420px) {
    margin-bottom: 0;
    margin-top: 15px;
  }
`

const TOS = styled.div<{ hidden: boolean }>`
  width: 100%;
  display: block;
  visibility: ${({ hidden }) => (hidden ? 'hidden' : 'visible')};
`

const Checkbox = styled.div`
  display: inline-block;
  vertical-align: top;
  height: 17px;
  width: 17px;
  border: 2px solid #f9f9f9;
  background: none;
  border-radius: 3px;
  cursor: pointer;
  margin-right: 5px;
  margin-top: 1px;
`

const Checked = styled.div<{ isChecked: boolean }>`
  height: 7px;
  width: 7px;
  background: ${({ isChecked }) => (isChecked ? '#f9f9f9' : 'none')};
  border-radius: 1px;
  margin-left: 3px;
  margin-top: 3px;
`

const TOSText = styled.div`
  display: inline-block;
  vertical-align: top;
  font-size: 14px;

  a {
    color: ${({ theme }) => theme.text1};
    text-decoration: none;
    font-weight: 700;
  }

  @media screen and (max-width: 420px) {
    font-size: 12px;
  }
`

const Cookies = styled.div`
  width: 100%;
  font-size: 14px;
  font-weight: 200;
  margin-top: 10px;

  @media screen and (max-width: 420px) {
    font-size: 13px;
    line-height: 18px;
  }
`

export default function FirstVisitPopover({
  approvedToS,
  setHasCompletedApprovals,
}: {
  approvedToS: boolean
  setHasCompletedApprovals: () => void
}) {
  // get all popups
  const [currentStep, setCurrentStep] = useState(0)
  const [hasApprovedToS, setHasApprovedToS] = useState(approvedToS)
  const [hasApprovedStrategies, setHasApprovedStrategies] = useState(false)

  useEffect(() => {
    if (currentStep === 12) setHasCompletedApprovals()
  }, [currentStep, setHasCompletedApprovals])

  return (
    <FirstVisitWrapper>
      <FirstVisitInner>
        <Title>Welcome to Dolomite!</Title>
        <Subtitle>
          {`This is the Strategies page, where you can utilize pre-built strategies built upon the assets and features of Dolomite.`}
        </Subtitle>
        <Subtitle>
          {`You can begin by selecting a strategy with the help of the available filters. Clicking the button on the strategy you're interested in will allow you to read more information about how the strategy works, as well as the risks and historical interest rate of the strategy.`}
        </Subtitle>
        <Subtitle>
          {`To better understand how to use Strategies, we recommend you check out our `}
          <a href={'https://docs.dolomite.io/guide/strategies'} target={'_blank'} rel={'noreferrer'}>
            Strategies guide.
          </a>
        </Subtitle>
        <LimitedSubtitle>
          {`The Strategies listed and information provided in this section of the Site constitute neither an offer to sell nor a solicitation of any offer to buy any regulated commodity instrument, security, or investment product; nor does any Strategy or its inclusion on the Site constitute investment advice.  None of the Site, Leavitt Innovations, Inc., Dolomite Foundation, Dolomite Ltd (collectively, the "Company"), or Dolomite generally provide any investment or other advisory services, nor do they constitute regulated financial entities or fiduciaries of any kind in any jurisdiction. 

The Site does not contain all of the information necessary to make a purchase, sale, trade or investment decision, including, but not limited to, detailed information pertinent to the risks of entering into transactions in crypto-assets generally, or any crypto-asset in specific. Taking positions in crypto-assets or leveraged positions of any type involves a substantial degree of risk. There can be no assurance that the expected economic outcomes will be achieved; financial losses may occur, and you could lose some or all of your position's value. Neither the Strategies nor any other information herein should be relied upon for accounting, legal or tax advice or investment recommendations. You should consult your tax, legal, accounting or other advisors before acting on the Strategies or related information provided on the Site. 

THE STRATEGIES AND THE RELATED PORTIONS OF THE SITE ARE SUBJECT TO THE GENERAL SITE TERMS OF SERVICE WARRANTY DISCLAIMER, INCLUDED AT https://dolomite.io/Dolomite-Terms-of-Service.pdf. FURTHERMORE, NO REPRESENTATION OF WARRANTY, EXPRESS OR IMPLIED, IS MADE, IMPLIED OR AEFFECTED BY THE STRATEGIES, THE COMPANY OR SITE, OR ANY OTHER PERSON, WITH RESPECT TO THE STRATEGIES, AS TO THE ACCURACY OR COMPLETENESS OF THE INFORMATION SET FORTH IN OR REPRESENTED BY THE STRATEGIES OR WITHIN RELATED INFORMATION ON THE SITE (THE "INFORMATION"). NOTHING CONTAINED IN THE INFORMATION IS, OR SHALL BE RELIED UPON AS A PROMISE OR REPRESENTATION, WHETHER AS TO THE PAST OR THE FUTURE. ALL ESTIMATES, PROJECTIONS AND SIMILAR ("FORWARD-LOOKING STATEMENTS") ARE BASED ON ASSUMPTIONS WHICH MAY NOT PROVE ACCURATE OR OTHERWISE OBTAIN. SUCH FORWARD-LOOKING STATEMENTS INVOLVE KNOWN AND UNKNOWN RISKS AND UNCERTAINTIES AND NO REPRESENTATION IS MADE AS TO THEIR ATTAINABILITY AND NOTHING IN THE INFORMATION SHALL BE RELIED UPON AS A PROMISE OR REPRESENTATION AS TO THE FUTURE PERFORMANCE OF ANY STRATEGIES.

By clicking where indicated or proceeding and using any of the Strategies or acting on any of the Information, you (1) acknowledge and hereby represent that you agree with the above disclaimers in full and represent to all of the assertions therein relevant to users of the Strategies, and (2) agree to release, indemnify and hold the Company harmless for any claims for liability that  contravene the foregoing disclaimers and covenants. If you do not agree to the foregoing, you may not use the Strategies.
`}
        </LimitedSubtitle>
        <TOS hidden={false}>
          <Checkbox onClick={() => setHasApprovedStrategies(!hasApprovedStrategies)}>
            <Checked isChecked={hasApprovedStrategies} />
          </Checkbox>
          <TOSText style={{ width: `calc(100% - 24px)`, marginBottom: '10px' }}>
            {`I recognize that loans on this interface are supplied by one or more third-parties through an open 
            protocol, and is not provided or endorsed by Dolomite Ltd. or any of its or the Dolomite protocol's 
            affiliates, as acknowledged and agreed in the TOS.`}
          </TOSText>
        </TOS>
        <TOS hidden={approvedToS}>
          <Checkbox onClick={() => setHasApprovedToS(!hasApprovedToS)}>
            <Checked isChecked={hasApprovedToS} />
          </Checkbox>
          <TOSText>
            I agree to the Dolomite{' '}
            <a target={'_blank'} rel={'noreferrer'} href={'https://dolomite.io/Dolomite-Terms-of-Service.pdf'}>
              Terms of Service
            </a>
          </TOSText>
        </TOS>
        <Cookies>
          By proceeding, you agree to the use of a small number of strictly necessary cookies that Dolomite uses to run
          properly.
        </Cookies>
        <StartButtonsWrapper disabled={!hasApprovedToS || !hasApprovedStrategies}>
          <button style={{ display: 'none' }} onClick={() => hasApprovedToS && setCurrentStep(12)}></button>
          <button onClick={() => hasApprovedToS && hasApprovedStrategies && setCurrentStep(12)}>Start</button>
        </StartButtonsWrapper>
      </FirstVisitInner>
    </FirstVisitWrapper>
  )
}
