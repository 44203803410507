import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import PageTitle from '../../components/common/PageTitle'
import { StatLoader, unixToDate } from './index'
import { TableLoader } from '../../components/Loader'
import { BackButton, BackButtonWrapper, Change } from './TokenStats'
import BarChart from '../../components/Stats/Charts/BarChart'
import LineChart from '../../components/Stats/Charts/LineChart'
import {
  Button,
  ChartLabel,
  ChartText,
  ChartTextLarge,
  ChartValue,
  RowBetween,
  RowFixed,
} from '../../components/Stats/Charts/styled'
import { useLastYearPairDayData } from '../../types/ammPairAllDayData'
import { Link, useHistory, useParams } from 'react-router-dom'
import { CurrencyAmount, Pair } from '@dolomite-exchange/v2-sdk'
import { useFiatValueWithLoadingIndicator } from '../../hooks/useFiatValue'
import useTheme from '../../hooks/useTheme'
import calculatePercentageChange from '../../utils/calculatePercentageChange'
import { sumFractions } from '../../utils/numberOperations'
import { ChainId, ZERO_FRACTION } from '../../constants'
import JSBI from 'jsbi'
import { useCurrentDayPairHourlyData, usePreviousDayPairHourlyData } from '../../types/ammPairHourData'
import getLogoOrDefault from '../../components/common/TokenLogos'
import { useAmmPairData } from '../../types/ammPairData'
import { useAmmMintDataByPair } from '../../types/ammMintData'
import { useAmmBurnDataByPair } from '../../types/ammBurnData'
import { useAmmTradeDataByPair } from '../../types/poolTradeData'
import { useActiveWeb3React } from '../../hooks'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { toChecksumAddress } from '../../utils/toChecksumAddress'
import cleanCurrencySymbol from '../../utils/cleanCurrencySymbol'
import { formatAmount } from '../../utils/formatAmount'
import { tryParseAmountWithNoCurrency } from '../../state/trade/hooks'
import TradeTable from 'components/Stats/Tables/TradeTable'
import MintOrBurnTable from '../../components/Stats/Tables/MintOrBurnsTable'
import { useMarketIndices } from '../../hooks/useDolomiteMarginProtocol'

enum ChartTypes {
  volume,
  tvl,
}

const TRADING = 0
const MINTS = 1
const BURNS = 2

const PoolStatsWrapper = styled.div`
  margin: 25px auto 0;
  width: calc(90% - 80px);
  max-width: 1400px;

  @media screen and (max-width: 730px) {
    width: calc(100% - 40px);
  }
`

const Title = styled.div`
  height: 25px;
  font-size: 21px;
  font-weight: 500;
  margin-bottom: 20px;
`

const ChartWrapper = styled.div`
  width: 68%;
  max-width: calc(100% - 320px);
  display: inline-block;
  vertical-align: top;

  @media screen and (max-width: 880px) {
    width: 100%;
  }
`

const LinksWrapper = styled.div`
  width: 100%;
`

const Links = styled.div`
  width: 100%;
  height: 40px;
  margin: 20px 0;
`

const TokenLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.text2} !important;
  font-size: 15px;
  transition: transform 0.2s ease-in-out;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
  background-color: ${({ theme }) => theme.bg1};
  padding: 10px 15px;
  border-radius: 8px;
  margin-right: 10px;
  float: left;

  &:hover {
    background-color: ${({ theme }) => theme.bg2} !important;
  }

  div {
    height: 18px;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-bottom: 10px;
  `};
`

const NavLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.text2} !important;
  font-size: 15px;
  transition: transform 0.2s ease-in-out;
  background-color: ${({ theme }) => theme.bg1};
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
  padding: 10px 15px;
  border-radius: 8px;
  margin-left: 10px;
  float: right;

  &:hover {
    background-color: ${({ theme }) => theme.bg2} !important;
  }

  @media screen and (max-width: 600px) {
    display: none;
  }
`

const NavMobile = styled(NavLink)`
  display: none !important;
  margin-top: -10px;

  @media screen and (max-width: 600px) {
    display: inline-block !important;
  }
`

const PoolDataRow = styled(RowBetween)`
  @media screen and (max-width: 880px) {
    flex-direction: column;

    > div {
      width: 100%;
      max-width: 100%;
      margin-bottom: 15px;
    }
  }
`

const PoolData = styled.div`
  width: 30%;
  min-width: 300px;
  height: 367px;
  display: inline-block;
  vertical-align: top;
  background-color: ${({ theme }) => theme.bg1};
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
  padding: 25px 30px 20px;
  border-radius: 8px;
`

const TotalReserve = styled.div`
  width: calc(100% + 30px);
  background-color: ${({ theme }) => theme.bg2};
  padding: 10px 15px 10px;
  border-radius: 8px;
  margin-left: -15px;
  margin-top: -10px;
  margin-bottom: 15px;
`

const ReserveRow = styled.div`
  height: 18px;
  font-size: 16px;
  margin-bottom: 5px;
`

const ReserveValue = styled.span`
  float: right;
`

const ReserveTitle = styled.div`
  color: ${({ theme }) => theme.text2};
  font-size: 14px;
  margin-bottom: 5px;
`

const PoolTitle = styled.div`
  color: ${({ theme }) => theme.text2};
`

const PoolValue = styled.div`
  color: ${({ theme }) => theme.text1};
  font-size: 25px;
`

const TokenLogoWrapper = styled.div`
  display: inline-block;
  margin-right: 2px;
  margin-top: 2px;
  vertical-align: top;
  height: 100%;

  img {
    height: 100%;
  }
`

const Transactions = styled.div`
  padding-bottom: 50px;

  @media screen and (max-width: 660px) {
    padding-bottom: 100px;
  }
`

const StyledTabs = styled(({ ...rest }) => <Tabs classes={{ indicator: 'indicator' }} {...rest} />)`
  font-family: 'Open Sans', sans-serif !important;
  justify-content: normal !important;
  min-height: 0 !important;
  margin-top: 15px;
  margin-bottom: 8px;

  /*noinspection CssUnusedSymbol*/

  .indicator {
    background-color: #f9f9f9 !important;
    bottom: 0 !important;
    display: block !important;
    height: 1.4px !important;
    transform: scale(0.5, 1) !important;
  }
`

const StyledTab = styled(({ ...rest }) => (
  <Tab
    classes={{
      root: 'root',
      selected: 'selected',
    }}
    {...rest}
  />
))`
  font-family: 'Open Sans', sans-serif !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  margin-left: 0 !important;
  margin-right: 13px !important;
  text-transform: capitalize !important;
  padding: 0 !important;
  padding-bottom: 3.5px !important;
  padding-left: 4px !important;
  padding-right: 4px !important;
  max-width: 264px;
  min-width: 0 !important;
  color: #606375 !important;
  min-height: 0 !important;

  ${({ selected }) =>
    selected &&
    `
    color: #f9f9f9 !important;
  `}
  .root span {
    font-size: 18px !important;
  }
`

const TokenLogo = ({ symbol }: { symbol: string | undefined }) => {
  return symbol !== undefined ? (
    <TokenLogoWrapper>
      <img src={getLogoOrDefault(symbol || '')} alt={`${symbol} logo`} />
    </TokenLogoWrapper>
  ) : null
}

export default function PoolStats() {
  const { t } = useTranslation()
  const { chainId } = useActiveWeb3React()
  const { poolId } = useParams<{ poolId: string }>()
  const [indexMap] = useMarketIndices()
  const { data: pairDataMap } = useAmmPairData(indexMap)
  const [loading, setLoading] = useState<boolean>(true)
  const { data: currentDayPairHourlyData } = useCurrentDayPairHourlyData(poolId)
  const { data: previousDayPairHourlyData } = usePreviousDayPairHourlyData(poolId)
  const { data: pairDataAllYear, loading: isLoading } = useLastYearPairDayData(poolId)
  const token0 = useMemo(() => pairDataMap[toChecksumAddress(poolId)]?.token0, [pairDataMap, poolId])
  const token1 = useMemo(() => pairDataMap[toChecksumAddress(poolId)]?.token1, [pairDataMap, poolId])
  const [chartDataType, setChartDataType] = useState(ChartTypes.volume)
  const [label, setLabel] = useState<string | undefined>()
  const [chartValue, setChartValue] = useState<number | undefined>()
  const theme = useTheme()
  const pair = useMemo(() => {
    if (!token0 || !token1) {
      return undefined
    }

    return new Pair(CurrencyAmount.fromRawAmount(token0, '0'), CurrencyAmount.fromRawAmount(token1, '0'), chainId)
  }, [chainId, token0, token1])
  const { data: mints, loading: mintsLoading } = useAmmMintDataByPair(pair, 0)
  const { data: burns, loading: burnsLoading } = useAmmBurnDataByPair(pair, 0)
  const { data: tradeData, loading: tradesLoading } = useAmmTradeDataByPair(pair)

  const averageIndex = useMemo(() => {
    if (!pair) {
      return undefined
    }
    const index0 = indexMap[pair.token0.address]?.supplyIndex
    const index1 = indexMap[pair.token1.address]?.supplyIndex
    if (!index0 || !index1) {
      return undefined
    }

    return index0.add(index1).divide(2)
  }, [indexMap, pair])

  const [selectedTab, setSelectedTab] = useState<number>(MINTS)
  const options = [t('trades'), t('mints'), t('burns')]

  useEffect(() => {
    loading && !isLoading && pairDataAllYear.length && setLoading(false)
  }, [loading, isLoading, pairDataAllYear])

  const history = useHistory()
  useEffect(() => {
    if (Object.keys(pairDataMap).length > 0 && (!token0 || !token1)) {
      history.replace('/stats')
    }
  })

  const amount0 = useMemo(() => {
    return token0
      ? CurrencyAmount.fromRawAmount(token0, JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(token0.decimals)))
      : undefined
  }, [token0])
  const [indexPrice0] = useFiatValueWithLoadingIndicator(amount0, token0)

  const amount1 = useMemo(() => {
    return token1
      ? CurrencyAmount.fromRawAmount(token1, JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(token1.decimals)))
      : undefined
  }, [token1])
  const [indexPrice1] = useFiatValueWithLoadingIndicator(amount1, token1)

  const price0Formatted = useMemo(
    () =>
      indexPrice0 && indexPrice1?.greaterThan(ZERO_FRACTION) ? formatAmount(indexPrice0.divide(indexPrice1)) : '0',
    [indexPrice0, indexPrice1],
  )
  const price1Formatted = useMemo(
    () =>
      indexPrice1 && indexPrice0?.greaterThan(ZERO_FRACTION) ? formatAmount(indexPrice1.divide(indexPrice0)) : '0',
    [indexPrice0, indexPrice1],
  )

  const volumeCurrentDay = useMemo(() => {
    return sumFractions(currentDayPairHourlyData ?? [], data => data.hourlyVolumeUSD)
  }, [currentDayPairHourlyData])

  const volumePreviousDay = useMemo(() => {
    return sumFractions(previousDayPairHourlyData ?? [], data => data.hourlyVolumeUSD)
  }, [previousDayPairHourlyData])

  const percentageChangeDay = useMemo(() => {
    return volumePreviousDay && volumeCurrentDay
      ? calculatePercentageChange(volumePreviousDay, volumeCurrentDay)
      : ZERO_FRACTION
  }, [volumePreviousDay, volumeCurrentDay])

  const volumeWeek = useMemo(() => {
    const oneWeekAgo = Math.floor(new Date().getTime() - 86_400_000 * 7) / 1_000
    const weekPairs = pairDataAllYear.filter(p => p.dayStartUnix > oneWeekAgo)
    return sumFractions(weekPairs, data => data.dailyVolumeUSD)
  }, [pairDataAllYear])

  const latestReserve0 = useMemo(() => {
    return pairDataMap[pair?.liquidityToken.address ?? '']?.reserve0
  }, [pairDataMap, pair?.liquidityToken.address])

  const latestReserve1 = useMemo(() => {
    return pairDataMap[pair?.liquidityToken.address ?? '']?.reserve1
  }, [pairDataMap, pair?.liquidityToken.address])

  const volumeData = useMemo(() => {
    if (pairDataAllYear) {
      return pairDataAllYear.map(day => {
        return {
          time: unixToDate(day.dayStartUnix),
          value: parseFloat(day.dailyVolumeUSD.toFixed(2)),
        }
      })
    } else {
      return []
    }
  }, [pairDataAllYear])

  const reserveData = useMemo(() => {
    if (pairDataAllYear && averageIndex) {
      return pairDataAllYear.map(day => {
        return {
          time: unixToDate(day.dayStartUnix),
          value: parseFloat(day.reserveUSD.multiply(averageIndex).toFixed(2)),
        }
      })
    } else {
      return []
    }
  }, [pairDataAllYear, averageIndex])
  const tvl = useMemo(() => pairDataMap[pair?.liquidityToken.address ?? '']?.reserveUSD ?? ZERO_FRACTION, [
    pairDataMap,
    pair?.liquidityToken.address,
  ])
  const prevTVL = useMemo(() => {
    const pair = pairDataAllYear[pairDataAllYear.length - 2]
    if (!pair || !averageIndex) {
      return ZERO_FRACTION
    }
    const previousTvl = pair?.reserveUSD ?? ZERO_FRACTION
    return previousTvl.multiply(averageIndex)
  }, [averageIndex, pairDataAllYear])

  const percentageChangeTVL = useMemo(() => {
    return prevTVL && tvl ? calculatePercentageChange(prevTVL, tvl) : ZERO_FRACTION
  }, [prevTVL, tvl])

  const changeChart = (type: number) => {
    setChartValue(undefined)
    setChartDataType(type)
  }

  useEffect(() => {
    const value = chartDataType === ChartTypes.volume ? volumeCurrentDay : tvl
    if (chartValue === undefined && value.greaterThan(ZERO_FRACTION)) {
      setChartValue(parseFloat(value.toFixed(2)))
      if (chartDataType === ChartTypes.volume) {
        setLabel(t('volume'))
      } else {
        setLabel('Current TVL')
      }
    }
  }, [chartDataType, volumeCurrentDay, tvl, chartValue, t])

  const symbol0 = cleanCurrencySymbol(token0)
  const symbol1 = cleanCurrencySymbol(token1)

  const linksWidth = document.getElementById('links')?.offsetWidth ?? 1400
  const reserveWidth = document.getElementById('reserve')?.offsetWidth ?? 1400
  const linkWidths = {
    widths: ['175px', '175px', '70px', '60px'],
    starts: ['0px', '185px', `${linksWidth - 140}px`, `${linksWidth - 60}px`],
  }
  const reserveWidths = {
    widths: ['70px', '70px'],
    starts: ['0px', `${reserveWidth - 100}px`],
  }

  return (
    <PoolStatsWrapper>
      <PageTitle
        title={
          chainId === ChainId.BERACHAIN
            ? `Bolomite | ${symbol0}/${symbol1} Stats`
            : `Dolomite | ${symbol0}/${symbol1} Stats`
        }
      />
      <BackButtonWrapper>
        <BackButton onClick={() => history.push(`/`)}>
          <div>➔</div>
          Back
        </BackButton>
      </BackButtonWrapper>
      <StatLoader
        data={
          <Title>
            <TokenLogo symbol={symbol0} />
            <TokenLogo symbol={symbol1} />
            {` ${symbol0}-${symbol1}`}
            <NavMobile to={`/pool/${symbol0}/${symbol1}`}>{t('pool')}</NavMobile>
            <NavMobile to={`/swap/${symbol0}/${symbol1}`}>{t('trade')}</NavMobile>
          </Title>
        }
        loading={loading}
        x={150}
        y={28}
        top={2}
      />
      <LinksWrapper id='links'>
        {loading ? (
          <TableLoader rows={1} height={40} spacing={0} wrapperHeight={60} marginTop={10} widths={linkWidths} />
        ) : (
          <Links>
            <TokenLink to={`/stats/token/${token0?.address}`}>
              <TokenLogo symbol={symbol0} />
              {`1 ${symbol0} = ${price0Formatted} ${symbol1}`}
            </TokenLink>
            <TokenLink to={`/stats/token/${token1?.address}`}>
              <TokenLogo symbol={symbol1} />
              {`1 ${symbol1} = ${price1Formatted} ${symbol0}`}
            </TokenLink>
            <NavLink to={`/pool/${symbol0}/${symbol1}`}>{t('pool')}</NavLink>
            <NavLink to={`/swap/${symbol0}/${symbol1}`}>{t('trade')}</NavLink>
          </Links>
        )}
      </LinksWrapper>
      <PoolDataRow>
        <PoolData>
          <TotalReserve id='reserve'>
            <ReserveTitle>Total Tokens Locked</ReserveTitle>
            {loading ? (
              <TableLoader rows={2} height={16} spacing={21} wrapperHeight={42} marginTop={9} widths={reserveWidths} />
            ) : (
              <>
                <ReserveRow>
                  <TokenLogo symbol={symbol0} />
                  {symbol0}
                  <ReserveValue>{formatAmount(latestReserve0, undefined, true, '0.00')}</ReserveValue>
                </ReserveRow>
                <ReserveRow>
                  <TokenLogo symbol={symbol1} />
                  {symbol1}
                  <ReserveValue>{formatAmount(latestReserve1, undefined, true, '0.00')}</ReserveValue>
                </ReserveRow>
              </>
            )}
          </TotalReserve>
          <PoolTitle>{t('tvl')}</PoolTitle>
          <StatLoader
            data={
              <>
                <PoolValue>{formatAmount(tvl, 2, true, '$0.00', true)}</PoolValue>
                <Change value={percentageChangeTVL} />
              </>
            }
            loading={loading}
            x={100}
            y={22}
            top={6}
            height={62}
          />
          <PoolTitle>{t('volume')}</PoolTitle>
          <StatLoader
            data={
              <>
                <PoolValue>{formatAmount(volumeCurrentDay, 2, true, '$0.00', true)}</PoolValue>
                <Change value={percentageChangeDay} />
              </>
            }
            loading={loading}
            x={100}
            y={22}
            top={6}
            height={62}
          />
          <PoolTitle>{t('volumeWeek')}</PoolTitle>
          <StatLoader
            data={<PoolValue>{formatAmount(volumeWeek, 2, true, '$0.00', true)}</PoolValue>}
            loading={loading}
            x={100}
            y={22}
            top={6}
          />
        </PoolData>
        <ChartWrapper>
          {chartDataType === ChartTypes.volume ? (
            <BarChart
              data={volumeData}
              loading={loading}
              height={367}
              minHeight={367}
              color={theme.blue1}
              setValue={setChartValue}
              setLabel={setLabel}
              value={chartValue}
              label={label}
              topRight={
                <RowFixed
                  style={{
                    marginLeft: '-40px',
                    marginTop: '8px',
                  }}
                >
                  <Button
                    active={chartDataType === ChartTypes.volume}
                    style={{ marginLeft: '8px' }}
                    onClick={() => changeChart(ChartTypes.volume)}
                  >
                    Volume
                  </Button>
                  <Button
                    active={(chartDataType as ChartTypes) === ChartTypes.tvl}
                    style={{ marginLeft: '8px' }}
                    onClick={() => changeChart(ChartTypes.tvl)}
                  >
                    TVL
                  </Button>
                </RowFixed>
              }
              topLeft={
                <ChartLabel>
                  <ChartTextLarge>
                    <StatLoader
                      data={
                        <ChartValue>
                          {formatAmount(tryParseAmountWithNoCurrency(chartValue?.toString()), 2, true, '$0.00', true)}
                        </ChartValue>
                      }
                      loading={loading}
                      x={100}
                    />
                  </ChartTextLarge>
                  <ChartText>{label ? <ChartValue>{label}</ChartValue> : null}</ChartText>
                </ChartLabel>
              }
            />
          ) : (
            <LineChart
              data={reserveData}
              loading={loading}
              height={352}
              minHeight={352}
              color={theme.blue1}
              value={chartValue}
              label={label}
              setValue={setChartValue}
              setLabel={setLabel}
              topRight={
                <RowFixed
                  style={{
                    marginLeft: '-40px',
                    marginTop: '8px',
                  }}
                >
                  <Button
                    active={(chartDataType as ChartTypes) === ChartTypes.volume}
                    style={{ marginLeft: '8px' }}
                    onClick={() => changeChart(ChartTypes.volume)}
                  >
                    Volume
                  </Button>
                  <Button
                    active={chartDataType === ChartTypes.tvl}
                    style={{ marginLeft: '8px' }}
                    onClick={() => changeChart(ChartTypes.tvl)}
                  >
                    TVL
                  </Button>
                </RowFixed>
              }
              topLeft={
                <ChartLabel>
                  <ChartTextLarge>
                    <StatLoader
                      data={
                        <ChartValue>
                          {formatAmount(tryParseAmountWithNoCurrency(chartValue?.toString()), 2, true, '$0.00', true)}
                        </ChartValue>
                      }
                      loading={loading}
                      x={100}
                    />
                  </ChartTextLarge>
                  <ChartText>{label ? <ChartValue>{label}</ChartValue> : null}</ChartText>
                </ChartLabel>
              }
            />
          )}
        </ChartWrapper>
      </PoolDataRow>
      <StyledTabs
        value={selectedTab}
        onChange={(_: any, index: number) => setSelectedTab(index)}
        indicatorColor={'primary'}
        textColor={'primary'}
      >
        {(options ?? []).map((option: string, index: number) => (
          <StyledTab key={`historyHeader-${index}`} disableRipple label={option} />
        ))}
      </StyledTabs>
      <Transactions>
        {selectedTab === TRADING ? (
          <TradeTable trades={tradeData} isLoading={tradesLoading} />
        ) : selectedTab === MINTS ? (
          <MintOrBurnTable mintsOrBurns={mints} isLoading={mintsLoading} isMints={true} />
        ) : selectedTab === BURNS ? (
          <MintOrBurnTable mintsOrBurns={burns} isLoading={burnsLoading} isMints={false} />
        ) : (
          <div>Error: unknown tab!</div>
        )}
      </Transactions>
    </PoolStatsWrapper>
  )
}
