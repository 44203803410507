import { Currency, Fraction } from '@dolomite-exchange/v2-sdk'
import React, { useCallback, useMemo } from 'react'
import TransactionConfirmationModal, {
  ConfirmationModalContent,
  TransactionErrorContent,
} from '../TransactionConfirmationModal'
import { CurrencyAmount } from '@dolomite-exchange/sdk-core'
import { FORMATTER } from '../../constants'
import cleanCurrencySymbol from '../../utils/cleanCurrencySymbol'
import { useActiveWeb3React } from '../../hooks'
import UnlockModalFooter from './UnlockModalFooter'
import UnlockModalHeader from './UnlockModalHeader'

export enum UnlockStage {
  UNLOCK = 0,
  DEPOSIT = 1,
  COMPLETE = 2,
}

export default function ConfirmUnlockModal({
  amount,
  fiatAmount,
  onConfirm,
  onDismiss,
  unlockStage,
  loading,
  errorMessage,
  isOpen,
  isUnlockModal,
  attemptingTxn,
  txHash,
}: {
  amount: CurrencyAmount<Currency> | undefined
  fiatAmount: Fraction | undefined
  attemptingTxn: boolean
  txHash: string | undefined
  unlockStage: UnlockStage
  loading: boolean
  isOpen: boolean
  isUnlockModal: boolean // false for a create vault modal
  onConfirm: () => void
  errorMessage: string | undefined
  onDismiss: () => void
}) {
  const { chainId } = useActiveWeb3React()

  const pendingText = useMemo(() => {
    if (!amount) {
      return ''
    }
    const symbol = cleanCurrencySymbol(amount.currency, false)
    if (unlockStage === UnlockStage.UNLOCK) {
      if (isUnlockModal) {
        return `Unlocking ${symbol}`
      } else {
        return 'Creating vault'
      }
    }
    return `Depositing ${amount.toSignificant(6, FORMATTER)} ${symbol}`
  }, [amount, unlockStage, isUnlockModal])

  const modalHeader = useCallback(() => {
    return amount ? (
      <UnlockModalHeader
        isUnlockModal={isUnlockModal}
        amount={amount}
        fiatAmount={fiatAmount}
        unlockStage={unlockStage}
      />
    ) : null
  }, [amount, unlockStage, fiatAmount, isUnlockModal])

  const modalBottom = useCallback(() => {
    return amount ? (
      <UnlockModalFooter
        amount={amount}
        unlockStage={unlockStage}
        isUnlockModal={isUnlockModal}
        onConfirm={onConfirm}
        chainId={chainId}
        attemptingTxn={attemptingTxn}
        isLoading={loading}
      />
    ) : null
  }, [amount, unlockStage, onConfirm, chainId, attemptingTxn, loading, isUnlockModal])

  const symbol = useMemo(() => cleanCurrencySymbol(amount?.currency, false), [amount])
  const confirmationContent = useCallback(
    () =>
      errorMessage ? (
        <TransactionErrorContent onDismiss={onDismiss} message={errorMessage} />
      ) : (
        <ConfirmationModalContent
          title={isUnlockModal ? `Unlock and Deposit ${symbol}` : `Create Vault and Deposit ${symbol}`}
          onDismiss={onDismiss}
          topContent={modalHeader}
          bottomContent={modalBottom}
        />
      ),
    [errorMessage, onDismiss, modalHeader, modalBottom, symbol, isUnlockModal],
  )

  return (
    <TransactionConfirmationModal
      isOpen={isOpen}
      memoizedOnDismiss={onDismiss}
      attemptingTxn={false}
      hash={txHash}
      memoizedContentCreator={confirmationContent}
      pendingText={pendingText}
      currencyToAdd={amount?.currency}
      disableSubmit={true}
      maxWidthPx={400}
    />
  )
}
