import { Currency, Trade, TradeType } from '@dolomite-exchange/v2-sdk'
import React, { useContext, useMemo } from 'react'
import { AlertTriangle, ArrowDown } from 'react-feather'
import { Text } from 'rebass'
import styled, { ThemeContext } from 'styled-components/macro'
import { Field } from '../../state/trade/actions'
import { TYPE } from '../../theme'
import { ButtonPrimary } from '../Button'
import { computeSlippageAdjustedAmounts, computeTradePriceBreakdown, warningSeverity } from '../../utils/prices'
import { AutoColumn } from '../Column'
import CurrencyLogo from '../CurrencyLogo'
import { RowBetween, RowFixed } from '../Row'
import { SwapShowAcceptChanges, TruncatedText } from './styleds'
import { MarginInformation } from './TradeModalFooter'
import cleanCurrencySymbol from '../../utils/cleanCurrencySymbol'
import { StyledTooltipWithIcon } from '../common/StyledTooltip'
import { ArrowForward } from '@material-ui/icons'
import calculateProfitPercentage from '../../utils/calculateProfitPercentage'

const Green = styled.span`
  color: ${({ theme }) => theme.green2};
  font-weight: 600;
`

const Red = styled.span`
  color: ${({ theme }) => theme.red2};
  font-weight: 600;
`

const MarginInformationWrapper = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.text2};
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 8px;
  margin-top: -8px;
`

const CloseInfoWrapper = styled.div`
  background-color: #2f2f40;
  border-radius: 5px;
  padding: 5px 11px 10px 11px;
  width: calc(100% + 22px);
  margin-left: -11px;
  margin-bottom: 9px;
  margin-top: 6px;
`

const CloseInfoLeft = styled.div`
  width: 50%;
  display: inline-block;
  vertical-align: top;
  text-align: left;
  padding-top: 4px;
`

const CloseInfoAsset = styled.div`
  width: 100%;
  font-size: 15px;
`

const Long = styled.span`
  color: ${({ theme }) => theme.green1};
  margin-right: 3px;
  font-weight: 600;
  line-height: 22px;
`

const Short = styled.span`
  color: ${({ theme }) => theme.red1};
  margin-right: 3px;
  font-weight: 600;
  line-height: 22px;
`

const CloseInfoRight = styled.div`
  width: 50%;
  display: inline-block;
  vertical-align: top;
`

const ProfitLossPercent = styled.div<{ isPositive: boolean | undefined }>`
  width: 100%;
  margin-top: 1px;
  margin-bottom: 1px;
  text-align: right;
  font-size: 26px;
  font-weight: 600;
  color: ${({ theme, isPositive }) => (isPositive ? theme.green1 : theme.red1)};
`

const ProfitLossAmount = styled.div`
  width: 100%;
  font-size: 13px;
  margin-top: -9px;
  text-align: right;
  color: ${({ theme }) => theme.text3};
  font-weight: 400;
`

const WithdrawAmountWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
`

const WithdrawTitle = styled.div`
  width: fit-content;
  font-weight: 300;

  svg {
    vertical-align: middle;
    margin-top: -3px;
  }
`

const WithdrawValue = styled.div`
  width: fit-content;
`

const WithdrawAmount = styled.span`
  color: ${({ theme }) => theme.text1};
`

const WithdrawSymbol = styled.span`
  color: ${({ theme }) => theme.text2};
`

const ClosingTradeWrapper = styled.div`
  width: 100%;
  margin-top: -3px;
  margin-bottom: -8px;
`

const ClosingTradeTitle = styled.div`
  width: 100%;
  text-align: left;
  font-size: 14px;
  color: ${({ theme }) => theme.text1};
  font-weight: 300;
  margin-bottom: 7px;

  svg {
    vertical-align: baseline;
    margin-bottom: -2px !important;
    margin-left: -1px;
  }
`

const ClosingTrade = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  svg {
    font-size: 20px;
  }
`

const ClosingAmount = styled.div`
  width: fit-content;
  color: ${({ theme }) => theme.text1};
  font-weight: 500;
  font-size: 17px;

  img {
    height: 21px;
    width: 21px;
    vertical-align: baseline;
    margin-bottom: -3px;
  }
`

const ClosingValue = styled.span`
  margin-right: 4px;
`

const ClosingSymbol = styled.span`
  font-weight: 300;
`

export default function TradeModalHeader({
  trade,
  allowedSlippage,
  showAcceptChanges,
  onAcceptChanges,
  marginInformation,
}: {
  trade: Trade<Currency, Currency, TradeType>
  allowedSlippage: number
  showAcceptChanges: boolean
  onAcceptChanges: () => void
  marginInformation?: MarginInformation
}) {
  const slippageAdjustedAmounts = useMemo(() => computeSlippageAdjustedAmounts(trade, allowedSlippage), [
    trade,
    allowedSlippage,
  ])
  const { priceImpactWithoutFee } = useMemo(() => computeTradePriceBreakdown(trade), [trade])
  const priceImpactSeverity = warningSeverity(priceImpactWithoutFee)

  const theme = useContext(ThemeContext)

  const wrappedInputAmount = trade.inputAmount.wrapped
  const profitLossPercentage =
    marginInformation?.marginDeposit && marginInformation.marginHeldAmount
      ? calculateProfitPercentage(
          marginInformation.marginDeposit,
          marginInformation.marginHeldAmount.subtract(wrappedInputAmount),
        )
      : undefined
  const profitLossAmount =
    marginInformation?.marginDeposit && marginInformation.marginHeldAmount
      ? marginInformation.marginHeldAmount.subtract(wrappedInputAmount).subtract(marginInformation.marginDeposit)
      : undefined
  const equityToWithdraw =
    marginInformation?.marginHeldAmount && marginInformation.marginHeldAmount.subtract(wrappedInputAmount)

  return (
    <AutoColumn gap={'md'} style={{ marginTop: '10px' }}>
      {marginInformation && !marginInformation.isClosing && (
        <RowBetween>
          <MarginInformationWrapper>
            This trade will open a margin position that will <Green>long</Green>{' '}
            {cleanCurrencySymbol(marginInformation.longAsset) ?? '-'} ({marginInformation.leverage.toFixed(2)}x
            leverage) and <Red>short</Red> {cleanCurrencySymbol(marginInformation.shortAsset) ?? '-'} (
            {marginInformation.leverage.subtract('1').toFixed(2)}x leverage).
          </MarginInformationWrapper>
        </RowBetween>
      )}
      {marginInformation?.isClosing && (
        <CloseInfoWrapper>
          <CloseInfoLeft>
            <CloseInfoAsset>
              <Long>LONG</Long> {cleanCurrencySymbol(trade.inputAmount.currency)} (
              {marginInformation.leverage.add('1').toFixed(2) || `-`}x)
            </CloseInfoAsset>
            <CloseInfoAsset>
              <Short>SHORT</Short> {cleanCurrencySymbol(trade.outputAmount.currency)} (
              {marginInformation.leverage.toFixed(2) || `-`}x)
            </CloseInfoAsset>
          </CloseInfoLeft>
          <CloseInfoRight>
            <ProfitLossPercent isPositive={profitLossPercentage?.greaterThanOrEqual('0')}>
              {profitLossPercentage?.greaterThanOrEqual('0') && '+'}
              {profitLossPercentage?.toFixed(2)}%
            </ProfitLossPercent>
            <ProfitLossAmount>
              {`${profitLossAmount?.greaterThanOrEqual('0') ? '+' : ''}${profitLossAmount?.toFixed(6)}`}{' '}
              {cleanCurrencySymbol(trade.inputAmount.currency)}
            </ProfitLossAmount>
          </CloseInfoRight>
          <WithdrawAmountWrapper>
            <WithdrawTitle>
              Equity to withdraw
              <StyledTooltipWithIcon
                tooltipText={
                  'The equity you hold in this margin position. This is the approximate amount you will receive when the position is closed.'
                }
                placement={'top'}
              />
            </WithdrawTitle>
            <WithdrawValue>
              <WithdrawAmount>{equityToWithdraw?.toSignificant(6) ?? '-'}</WithdrawAmount>{' '}
              <WithdrawSymbol>{cleanCurrencySymbol(trade.inputAmount.currency)}</WithdrawSymbol>
            </WithdrawValue>
          </WithdrawAmountWrapper>
        </CloseInfoWrapper>
      )}
      {marginInformation?.isClosing ? (
        <ClosingTradeWrapper>
          <ClosingTradeTitle>
            Closing trade{' '}
            <StyledTooltipWithIcon
              tooltipText={`This trade will close your position by selling the ${cleanCurrencySymbol(
                trade.inputAmount.currency,
              )} purchased with borrowed funds and reimburse lenders.`}
              placement={'top'}
            />
          </ClosingTradeTitle>
          <ClosingTrade>
            <ClosingAmount>
              <CurrencyLogo currency={trade.inputAmount.currency} size={'30px'} style={{ marginRight: '8px' }} />
              <ClosingValue>{trade.inputAmount.toSignificant(6)}</ClosingValue>
              <ClosingSymbol>{cleanCurrencySymbol(trade.inputAmount.currency)}</ClosingSymbol>
            </ClosingAmount>
            <ArrowForward />
            <ClosingAmount>
              <CurrencyLogo currency={trade.outputAmount.currency} size={'30px'} style={{ marginRight: '8px' }} />
              <ClosingValue>{trade.outputAmount.toSignificant(6)}</ClosingValue>
              <ClosingSymbol>{cleanCurrencySymbol(trade.outputAmount.currency)}</ClosingSymbol>
            </ClosingAmount>
          </ClosingTrade>
        </ClosingTradeWrapper>
      ) : (
        <>
          <RowBetween align='flex-end'>
            <RowFixed gap={'0px'}>
              <CurrencyLogo currency={trade.inputAmount.currency} size={'30px'} style={{ marginRight: '12px' }} />
              <TruncatedText
                fontSize={24}
                fontWeight={500}
                color={showAcceptChanges && trade.tradeType === TradeType.EXACT_OUTPUT ? theme.text1 : ''}
              >
                {trade.inputAmount.toSignificant(6)}
              </TruncatedText>
            </RowFixed>
            <RowFixed gap={'0px'}>
              <Text fontSize={24} fontWeight={500} style={{ marginLeft: '10px' }}>
                {cleanCurrencySymbol(trade.inputAmount.currency)}
              </Text>
            </RowFixed>
          </RowBetween>
          <RowFixed>
            <ArrowDown
              size='16'
              color={theme.text2}
              style={{
                marginLeft: '7px',
                minWidth: '16px',
              }}
            />
          </RowFixed>
          <RowBetween align='flex-end'>
            <RowFixed gap={'0px'}>
              <CurrencyLogo currency={trade.outputAmount.currency} size={'30px'} style={{ marginRight: '12px' }} />
              <TruncatedText
                fontSize={24}
                fontWeight={500}
                color={
                  priceImpactSeverity > 2
                    ? theme.red1
                    : showAcceptChanges && trade.tradeType === TradeType.EXACT_INPUT
                    ? theme.text1
                    : ''
                }
              >
                {trade.outputAmount.toSignificant(6)}
              </TruncatedText>
            </RowFixed>
            <RowFixed gap={'0px'}>
              <Text fontSize={24} fontWeight={500} style={{ marginLeft: '10px' }}>
                {cleanCurrencySymbol(trade.outputAmount.currency)}
              </Text>
            </RowFixed>
          </RowBetween>
        </>
      )}
      {showAcceptChanges ? (
        <SwapShowAcceptChanges justify='flex-start' gap={'0px'}>
          <RowBetween>
            <RowFixed>
              <AlertTriangle
                size={20}
                style={{
                  marginRight: '8px',
                  minWidth: 24,
                }}
              />
              <TYPE.main color={theme.text1}>&nbsp;Price Updated</TYPE.main>
            </RowFixed>
            <ButtonPrimary
              style={{
                padding: '.5rem',
                width: 'fit-content',
                fontSize: '0.825rem',
                borderRadius: '5px',
              }}
              onClick={onAcceptChanges}
            >
              Accept
            </ButtonPrimary>
          </RowBetween>
        </SwapShowAcceptChanges>
      ) : null}
      <AutoColumn justify='flex-start' gap='sm' style={{ padding: '12px 0 0 0px' }}>
        {trade.tradeType === TradeType.EXACT_INPUT ? (
          <TYPE.italic textAlign='left' style={{ width: '100%' }}>
            {`Output is estimated. You will receive at least `}
            <b>
              {slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(6)}
              &nbsp;
              {cleanCurrencySymbol(trade.outputAmount.currency)}
            </b>
            {' or the transaction will revert.'}
          </TYPE.italic>
        ) : (
          <TYPE.italic textAlign='left' style={{ width: '100%' }}>
            {`Input is estimated. You will sell at most `}
            <b>
              {slippageAdjustedAmounts[Field.INPUT]?.toSignificant(6)}
              &nbsp;
              {cleanCurrencySymbol(trade.inputAmount.currency)}
            </b>
            {' or the transaction will revert.'}
          </TYPE.italic>
        )}
      </AutoColumn>
    </AutoColumn>
  )
}
