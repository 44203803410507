import { Currency, Price } from '@dolomite-exchange/v2-sdk'
import { Market } from '../types/Market'
import { useMemo } from 'react'

export default function useFormattedPrice<T extends Currency>(
  price: Price<T, T> | undefined,
  market: Market | undefined,
): Price<T, T> | undefined {
  return useMemo(() => {
    if (!price || !market) {
      return undefined
    }

    if (market.primaryToken.equals(price.quoteCurrency)) {
      return price.invert()
    } else {
      return price
    }
  }, [price, market])
}
