import JSBI from 'jsbi'

import { createDate, MarginAccount as MarginAccountGql } from './gqlTypeHelpers'
import { useActiveWeb3React } from '../hooks'
import { useMemo } from 'react'
import { toChecksumAddress } from '../utils/toChecksumAddress'
import { ZERO_ADDRESS } from '../constants'
import { useBlockNumber } from '../state/chain/hooks'

export class MarginAccount {
  public account: string
  public accountNumber: JSBI
  public lastUpdatedTimestamp: Date
  public lastUpdatedBlockNumber: JSBI

  constructor(account: string, accountNumber: JSBI, lastUpdatedTimestamp: Date, lastUpdatedBlockNumber: JSBI) {
    this.account = toChecksumAddress(account)
    this.accountNumber = accountNumber
    this.lastUpdatedTimestamp = lastUpdatedTimestamp
    this.lastUpdatedBlockNumber = lastUpdatedBlockNumber
  }

  public toString(): string {
    return `${this.account.toLowerCase()}-${this.accountNumber.toString()}`
  }
}

export function useDefaultMarginAccount(): MarginAccount {
  const { account, chainId } = useActiveWeb3React()
  const blockNumber = useBlockNumber(chainId)
  return useMemo(() => {
    return {
      account: toChecksumAddress(account ?? ZERO_ADDRESS),
      accountNumber: JSBI.BigInt('0'),
      lastUpdatedTimestamp: new Date(),
      lastUpdatedBlockNumber: JSBI.BigInt(blockNumber ?? 0),
    }
  }, [account, blockNumber])
}

export function createMarginAccount(marginAccount: MarginAccountGql): MarginAccount {
  return new MarginAccount(
    toChecksumAddress(marginAccount.user.id),
    JSBI.BigInt(marginAccount.accountNumber),
    createDate(marginAccount.lastUpdatedTimestamp),
    JSBI.BigInt(marginAccount.lastUpdatedBlockNumber),
  )
}

export function createMarginAccountOpt(marginAccount?: MarginAccountGql): MarginAccount | undefined {
  return marginAccount ? createMarginAccount(marginAccount) : undefined
}
