import React from 'react'
import styled from 'styled-components/macro'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import ClaimYtGLPRewards from './ClaimYtGLPRewards'

export const AssetRewardsWrapper = styled.div`
  padding: 7px 35px 30px;
  width: 100%;
  overflow: auto;
`

const RewardsButtonsWrapper = styled.div`
  @media screen and (max-width: 532px) {
    text-align: center;
  }
`

const RewardsButton = styled.div<{ supplemental?: boolean }>`
  width: fit-content;
  padding: 8px 16px;
  border-radius: 5px;
  background: ${({ theme, supplemental }) => (supplemental ? theme.bg4 : theme.blue1)};
  cursor: pointer;
  color: white;
  font-weight: 500;
  font-size: 12px;
  ${({ supplemental }) => supplemental && 'margin-right: 8px;'}
  display: inline-block;

  :hover {
    background: ${({ theme, supplemental }) => (supplemental ? theme.bg5 : theme.blue2)};
  }

  svg {
    font-size: 15px;
    margin-bottom: -3px;
    margin-left: 2px;
  }

  @media screen and (max-width: 532px) {
    margin: 4px;
  }
`

export default function YtGLPBottomSection({
  setPopoverComponent,
  setCustomPopoverWidth,
}: {
  setPopoverComponent?: (component: JSX.Element) => void
  setCustomPopoverWidth?: (width: number) => void
}) {
  return (
    <RewardsButtonsWrapper>
      <RewardsButton
        onClick={e => {
          e.stopPropagation()
          setPopoverComponent?.(<ClaimYtGLPRewards />)
        }}
      >
        Claim Rewards <AutoAwesomeIcon />
      </RewardsButton>
    </RewardsButtonsWrapper>
  )
}
