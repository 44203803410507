import { WE_ETH } from '../../index'
import { SpecialAsset, SpecialAssetSymbol } from '../special-assets'
import mapChainIdMapValues from '../../../utils/mapChainIdMapValues'

export const WE_ETH_SPECIAL_ASSET: () => SpecialAsset = () => ({
  symbol: SpecialAssetSymbol.weETH,
  safeSymbol: SpecialAssetSymbol.weETH,
  cleanSymbol: 'weETH',
  cleanName: 'Wrapped eETH',
  hasExternalRewards: false,
  documentationUrl: undefined,
  isIsolationMode: false,
  isBorrowingEnabled: true,
  chainIdToAddressMap: mapChainIdMapValues(WE_ETH, token => token?.address),
  isolationModeInfo: undefined,
})
