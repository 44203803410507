import React, { useContext } from 'react'
import { AlertCircle, CheckCircle } from 'react-feather'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import styled, { ThemeContext } from 'styled-components/macro'
import { useActiveWeb3React } from '../../hooks'
import { ExternalLink, TYPE } from '../../theme'
import { getBlockExplorerName, getEtherscanLink } from '../../utils'
import { AutoColumn } from '../Column'
import { AutoRow } from '../Row'
import { useHistory } from 'react-router-dom'

const RowNoFlex = styled(AutoRow)`
  flex-wrap: nowrap;
`

const StyledLink = styled.div`
  text-decoration: none;
  cursor: pointer;
  color: ${({ theme }) => theme.text1};
  font-weight: 600;

  :hover {
    text-decoration: underline;
  }

  :focus {
    outline: none;
    text-decoration: underline;
  }

  :active {
    text-decoration: none;
  }
`

const StyledAutoAwesomeIcon = styled(AutoAwesomeIcon)`
  path {
    fill: ${({ theme }) => theme.blue2};
  }
`

export default function TransactionPopup({
  hash,
  success,
  summary,
}: {
  hash: string
  success?: boolean
  summary?: string
}) {
  const { chainId } = useActiveWeb3React()
  const history = useHistory()

  const theme = useContext(ThemeContext)

  return (
    <RowNoFlex>
      <div style={{ paddingRight: 16 }}>
        {hash === 'Claim' ? (
          <StyledAutoAwesomeIcon />
        ) : success ? (
          <CheckCircle color={theme.green1} size={24} />
        ) : (
          <AlertCircle color={theme.red1} size={24} />
        )}
      </div>
      <AutoColumn gap='8px'>
        <TYPE.body fontWeight={500}>{summary ?? 'Hash: ' + hash.slice(0, 8) + '...' + hash.slice(58, 65)}</TYPE.body>
        {chainId && hash !== 'Claim' && (
          <ExternalLink href={getEtherscanLink(chainId, hash, 'transaction')}>
            View on {getBlockExplorerName(chainId)}
          </ExternalLink>
        )}
        {hash === 'Claim' && (
          <StyledLink onClick={() => history.push(`/achievements/milestones`)}>Click to Claim XP</StyledLink>
        )}
      </AutoColumn>
    </RowNoFlex>
  )
}
