import { CurrencyAmount, Fraction, Pair, Rounding, Token } from '@dolomite-exchange/v2-sdk'
import { FORMATTER, ZERO_FRACTION, ZERO_PERCENT } from '../../constants'
import React, { useEffect, useMemo } from 'react'
import { AmmPoolData } from './ManagePool'
import { InterestRateProps, PoolDisplayRow, PoolDisplayRowWrapper } from './PoolBalances'
import { useCurrentDayPairHourlyData } from '../../types/ammPairHourData'
import useAmmApr from '../../hooks/useAmmApr'
import { Percent } from '@dolomite-exchange/sdk-core'
import cleanCurrencySymbol from '../../utils/cleanCurrencySymbol'
import { formatAmount } from '../../utils/formatAmount'
import { InterestRate } from '../../types/interestRateData'

interface PoolBalanceRowProps {
  pool: AmmPoolData
  index: number
  totalSupply: CurrencyAmount<Token> | undefined
  userLiquidityBalance: CurrencyAmount<Token> | undefined
  setTotalAprForPair: (pair: Pair, totalApr: Percent) => void
  onPairChange: (pair: Pair) => void
  interestRateMap: Record<string, InterestRate | undefined>
}

export default function PoolBalanceRow({
  pool,
  totalSupply,
  userLiquidityBalance,
  setTotalAprForPair,
  onPairChange,
  interestRateMap,
}: PoolBalanceRowProps) {
  const pair = pool.pair
  const supplyRate = useMemo(() => {
    const supplyRate0 = interestRateMap[pool.pair.token0.address]?.supplyInterestRate
    const supplyRate1 = interestRateMap[pool.pair.token1.address]?.supplyInterestRate

    if (supplyRate0 && supplyRate1) {
      const token0OutsideInterestRate = interestRateMap[
        pool.pair.token0.address
      ]?.outsideSupplyInterestRateParts?.reduce(
        (total, part) => total.add(part.interestRate ?? ZERO_PERCENT),
        ZERO_PERCENT,
      )
      const token1OutsideInterestRate = interestRateMap[
        pool.pair.token1.address
      ]?.outsideSupplyInterestRateParts?.reduce(
        (total, part) => total.add(part.interestRate ?? ZERO_PERCENT),
        ZERO_PERCENT,
      )
      const totalOutsideRate = token0OutsideInterestRate
        ?.divide(new Fraction(2))
        .add(token1OutsideInterestRate?.divide(new Fraction(2)) ?? ZERO_PERCENT)
      return Percent.fromFraction(supplyRate0.add(supplyRate1).divide('2')).add(totalOutsideRate ?? ZERO_PERCENT)
    } else {
      return ZERO_PERCENT
    }
  }, [interestRateMap, pool.pair])

  const { data: pair24HourData } = useCurrentDayPairHourlyData(pair)
  const ammApr = useAmmApr(pair, pair24HourData)

  const interestRateProps = useMemo<InterestRateProps>(() => {
    return {
      marginSupplyYield: formatAmount(supplyRate, 2, true, '0%'),
      ammYield: formatAmount(ammApr, 2, true, '0%'),
      totalYield: formatAmount(supplyRate.add(ammApr), 2, true, '0%'),
    }
  }, [ammApr, supplyRate])

  const token0Symbol = cleanCurrencySymbol(pair.token0) ?? ''
  const token1Symbol = cleanCurrencySymbol(pair.token1) ?? ''

  const [balance0, balance1] = useMemo(() => {
    const balance0 =
      pair.token0 && totalSupply?.greaterThan(ZERO_FRACTION)
        ? CurrencyAmount.fromRawAmount(
            pair.token0,
            userLiquidityBalance?.multiply(pair.reserve0 ?? ZERO_FRACTION)?.divide(totalSupply).quotient ?? '0',
          )
        : undefined
    const balance1 =
      pair.token1 && totalSupply?.greaterThan(ZERO_FRACTION)
        ? CurrencyAmount.fromRawAmount(
            pair.token1,
            userLiquidityBalance?.multiply(pair.reserve1 ?? ZERO_FRACTION)?.divide(totalSupply).quotient ?? '0',
          )
        : undefined
    return [balance0, balance1]
  }, [pair, totalSupply, userLiquidityBalance])

  useEffect(() => {
    setTotalAprForPair(pool.pair, supplyRate.add(ammApr))
  }, [ammApr, pool, setTotalAprForPair, supplyRate])

  const combinedInterestRateParts = useMemo(() => {
    return pool
      ? [
          ...(interestRateMap[pool.pair.token0.address]?.outsideSupplyInterestRateParts ?? []),
          ...(interestRateMap[pool.pair.token1.address]?.outsideSupplyInterestRateParts ?? []),
        ]
      : undefined
  }, [interestRateMap, pool])

  return (
    <PoolDisplayRowWrapper
      onClick={() => pool.pair && onPairChange(pool.pair)}
      selected={false}
      key={`pool-${token0Symbol}-${token1Symbol}`}
    >
      <PoolDisplayRow
        currencyA={token0Symbol}
        currencyB={token1Symbol}
        rate={interestRateProps}
        currencyABalance={(balance0 ?? ZERO_FRACTION).toFixed(4, FORMATTER, Rounding.ROUND_HALF_UP)}
        currencyBBalance={(balance1 ?? ZERO_FRACTION).toFixed(4, FORMATTER, Rounding.ROUND_HALF_UP)}
        outsideInterestRateParts={combinedInterestRateParts}
      />
    </PoolDisplayRowWrapper>
  )
}
