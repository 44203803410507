import React, { useMemo } from 'react'
import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'
import { Field } from '../../state/trade/actions'
import { useDerivedTradeInfo, useTradeState } from '../../state/trade/hooks'
import { useTranslation } from 'react-i18next'
import { useAllActiveTokensArray, useCurrency } from '../../hooks/Tokens'
import cleanCurrencySymbol from '../../utils/cleanCurrencySymbol'
import { ZERO_FRACTION } from '../../constants'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { useDolomiteBalancesWithLoadingIndicator } from '../../state/wallet/hooks'
import { useActiveWeb3React } from '../../hooks'
import { formatAmount } from '../../utils/formatAmount'
import { PlusCircle } from 'react-feather'
import { useModalOpen, useToggleModal } from '../../state/application/hooks'
import { ApplicationModal } from '../../state/application/actions'

const INF = '∞'

const BalancesWrapper = styled.div`
  width: 100%;
  display: inline-block;
  vertical-align: top;
  padding-bottom: 0;
  font-family: 'Open Sans', sans-serif;
`

const BalancesHeader = styled.div`
  width: 100%;
  margin-bottom: 5px;
`

const BalancesTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  display: inline-block;
`

const HeaderButton = styled.div`
  display: inline-block;
  float: right;
  cursor: pointer;
  margin-top: 3px;
`

const BalanceRows = styled.div`
  width: 100%;
`

const BalanceRow = styled.div`
  width: 100%;
  display: inline-block;
  height: 24px;
  font-size: 14px;
`

const TokenSymbol = styled.div`
  width: 18%;
  display: inline-block;
  font-weight: 100;
`

const BalanceWrapper = styled.div`
  width: 82%;
  text-align: right;
  display: inline-block;
`

const TokenBalance = styled.div<{ clickable?: boolean }>`
  display: inline-block;
  font-weight: 600;
  ${({ clickable }) => clickable && 'cursor: pointer;'}
`

const ManageButton = styled.div`
  text-align: center;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 500;
  color: #d5d6e1 !important;
  text-decoration: none !important;
  margin-top: -5px;
  margin-bottom: 10px;

  a {
    color: ${({ theme }) => theme.text3};
    text-decoration: none !important;

    :hover {
      color: ${({ theme }) => theme.text1};
    }
  }
`

const BalanceSupplement = styled.div`
  display: inline-block;
  vertical-align: top;
  width: fit-content;
`

const DecreaseArrow = styled.div`
  display: inline-block;
  vertical-align: top;
  width: fit-content;
  color: ${({ theme }) => theme.red1};
  margin: 0 5px;

  svg {
    font-size: 16px;
    margin-top: 2px;
  }
`

const IncreaseArrow = styled.div`
  display: inline-block;
  vertical-align: top;
  width: fit-content;
  color: ${({ theme }) => theme.green1};
  margin: 0 5px;

  svg {
    font-size: 16px;
    margin-top: 2px;
  }
`

export default function Balances() {
  const { t } = useTranslation()
  const { account } = useActiveWeb3React()
  const toggleShowDepositModal = useToggleModal(ApplicationModal.DEPOSIT_WITHDRAW)

  const {
    [Field.INPUT]: { currencyId: inputCurrencyId },
    [Field.OUTPUT]: { currencyId: outputCurrencyId },
    typedLeverage,
  } = useTradeState()

  const { v2Trade, borrowedUSD, suppliedUSD, marginDeposit } = useDerivedTradeInfo()

  const swapPanelInput = useCurrency(inputCurrencyId)
  const swapPanelOutput = useCurrency(outputCurrencyId)

  const tokens = useAllActiveTokensArray()
  const [tokenBalanceMap] = useDolomiteBalancesWithLoadingIndicator(account, tokens)
  const inputBalance = tokenBalanceMap[swapPanelInput?.wrapped.address ?? '']
  const outputBalance = tokenBalanceMap[swapPanelOutput?.wrapped.address ?? '']

  const newInputBalance = useMemo(() => {
    if (v2Trade && inputBalance) {
      return inputBalance.asFraction.subtract(v2Trade.inputAmount.asFraction)
    }

    return ZERO_FRACTION
  }, [v2Trade, inputBalance])

  const newOutputBalance = useMemo(() => {
    if (v2Trade) {
      if (typedLeverage === '1') {
        return outputBalance?.asFraction.add(v2Trade.outputAmount.asFraction) ?? ZERO_FRACTION
      } else {
        return (marginDeposit && outputBalance?.asFraction.subtract(marginDeposit.asFraction)) ?? ZERO_FRACTION
      }
    }

    return ZERO_FRACTION
  }, [v2Trade, outputBalance, typedLeverage, marginDeposit])

  const collateralization = useMemo(() => {
    if (suppliedUSD && borrowedUSD && borrowedUSD.greaterThan('0')) {
      const rawCollateralization = suppliedUSD.divide(borrowedUSD).multiply('100')
      if (rawCollateralization.greaterThan('1000')) {
        return '>1000%'
      } else {
        return `${rawCollateralization.toFixed(2)}%`
      }
    } else {
      return INF
    }
  }, [suppliedUSD, borrowedUSD])

  return (
    <BalancesWrapper>
      <BalancesHeader>
        <BalancesTitle>{t('balancesTitle')}</BalancesTitle>
        <HeaderButton onClick={toggleShowDepositModal}>
          <PlusCircle size={18} />
        </HeaderButton>
      </BalancesHeader>
      <BalanceRows>
        <BalanceRow>
          <TokenSymbol>{cleanCurrencySymbol(swapPanelInput) ?? ''}</TokenSymbol>
          <BalanceWrapper>
            <TokenBalance
              clickable={true}
              onClick={() => {
                if (inputBalance?.greaterThan('0')) {
                  const event = new Event('selectedInputBalance')
                  document.dispatchEvent(event)
                }
              }}
            >
              {formatAmount(inputBalance)}
            </TokenBalance>
            {v2Trade && typedLeverage === '1' && (
              <BalanceSupplement>
                <DecreaseArrow>
                  <ArrowForwardIcon />
                </DecreaseArrow>
                <TokenBalance>{formatAmount(newInputBalance)}</TokenBalance>
              </BalanceSupplement>
            )}
          </BalanceWrapper>
        </BalanceRow>
        <BalanceRow>
          <TokenSymbol>{cleanCurrencySymbol(swapPanelOutput) ?? ''}</TokenSymbol>
          <BalanceWrapper>
            <TokenBalance
              clickable={true}
              onClick={() => {
                if (outputBalance?.greaterThan('0')) {
                  const event = new Event('selectedOutputBalance')
                  document.dispatchEvent(event)
                }
              }}
            >
              {formatAmount(outputBalance)}
            </TokenBalance>
            {v2Trade && (
              <BalanceSupplement>
                {typedLeverage === '1' ? (
                  <IncreaseArrow>
                    <ArrowForwardIcon />
                  </IncreaseArrow>
                ) : (
                  <DecreaseArrow>
                    <ArrowForwardIcon />
                  </DecreaseArrow>
                )}
                <TokenBalance>{formatAmount(newOutputBalance)}</TokenBalance>
              </BalanceSupplement>
            )}
          </BalanceWrapper>
        </BalanceRow>
        {/*collateralization && (
          <BalanceRow>
            <TokenSymbol>{t('collateralization')}</TokenSymbol>
            <BalanceWrapper>
              <TokenBalance>{collateralization}</TokenBalance>
            </BalanceWrapper>
          </BalanceRow>
        )*/}
      </BalanceRows>
      <ManageButton>
        <Link to={'/balances'}>{t('depositWithdraw') /*Alt: "Manage Balances"*/}</Link>
      </ManageButton>
      {/*<BottomLine/>*/}
    </BalancesWrapper>
  )
}
