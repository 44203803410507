import { gql } from '@apollo/client'
import { createCurrencyAmount } from './gqlTypeHelpers'
import { useMemo } from 'react'
import { useOToken } from '../hooks/Tokens'
import { useGraphqlResult } from '../state/graphql/hooks'
import { GraphqlClientType } from '../state/graphql/actions'
import { CurrencyAmount, Token } from '@dolomite-exchange/v2-sdk'
import { Fraction, Percent } from '@dolomite-exchange/sdk-core'
import {
  LIQUIDITY_MINING_LEVEL_THRESHOLD,
  REWARDS_DEPOSIT_THRESHOLD,
  useGrandfatherIdCutoff,
} from '../hooks/useLiquidityMining'
import { useGalxeAddressData } from './galxeAddressData'
import { RefreshFrequency } from '../state/chain/hooks'

const VESTING_POSITIONS_BY_WALLET_DATA = gql`
  query vestingPositionsByWallet($blockNumber: Int!, $walletAddress: String!, $oTokenAddress: String!) {
    liquidityMiningVestingPositions(
      block: { number_gte: $blockNumber }
      where: { owner: $walletAddress, vester_: { oTokenAddress: $oTokenAddress } }
      first: 1000
    ) {
      id
      status
      duration
      startTimestamp
      oTokenAmount
    }
  }
`

interface VestingPositionGql {
  id: string
  status: string
  duration: string
  startTimestamp: string
  oTokenAmount: string
}

interface VestingPositionsResponse {
  liquidityMiningVestingPositions: VestingPositionGql[]
}

export enum VestingPositionStatus {
  ACTIVE = 'ACTIVE',
  CLOSED = 'CLOSED',
  FORCE_CLOSED = 'FORCE_CLOSED',
  EMERGENCY_CLOSED = 'EMERGENCY_CLOSED',
}

export interface LiquidityMiningVestingPosition {
  id: string
  nftId: string
  status: VestingPositionStatus
  durationSeconds: number
  durationWeeks: number
  discountPercentage: Percent
  startTimestamp: Date
  vestingCompleteTimestamp: Date
  oTokenAmount: CurrencyAmount<Token>
}

const ONE_WEEK_SECONDS = 86_400 * 7
const FOUR_WEEKS_SECONDS = ONE_WEEK_SECONDS * 4
const VESTING_DISCOUNTS = [new Percent(25, 1000), new Percent(50, 1000), new Percent(100, 1000), new Percent(200, 1000)]

export function useAllVestingPositions(
  walletAddress: string | undefined,
  totalDepositedValue: Fraction | undefined,
): {
  loading: boolean
  error: boolean
  data: LiquidityMiningVestingPosition[]
} {
  const oToken = useOToken()
  const variables = useMemo(() => {
    if (!walletAddress || !oToken) {
      return undefined
    }
    return {
      walletAddress: walletAddress.toLowerCase(),
      oTokenAddress: oToken.address.toLowerCase(),
    }
  }, [oToken, walletAddress])
  const allFinalizedClaimsQueryState = useGraphqlResult<VestingPositionsResponse>(
    GraphqlClientType.Dolomite,
    VESTING_POSITIONS_BY_WALLET_DATA.loc!.source.body,
    variables,
    RefreshFrequency.Fast,
  )
  const grandfatherId = useGrandfatherIdCutoff()
  const { levelData } = useGalxeAddressData(walletAddress)
  const { level } = levelData

  return useMemo(() => {
    if (!oToken) {
      return {
        data: [],
        error: true,
        loading: false,
      }
    }

    const anyLoading = Boolean(allFinalizedClaimsQueryState.loading)
    const anyError = Boolean(allFinalizedClaimsQueryState.error)

    const vestingPositions = (allFinalizedClaimsQueryState.result?.liquidityMiningVestingPositions ?? [])
      .map<LiquidityMiningVestingPosition>(vestingPosition => {
        const durationSeconds = parseInt(vestingPosition.duration)
        const isNotGrandfathered =
          parseInt(vestingPosition.id) <= grandfatherId && durationSeconds <= FOUR_WEEKS_SECONDS
        const effectiveDurationSeconds =
          !isNotGrandfathered &&
          (level >= LIQUIDITY_MINING_LEVEL_THRESHOLD ||
            totalDepositedValue?.greaterThanOrEqual(REWARDS_DEPOSIT_THRESHOLD))
            ? Math.floor((durationSeconds * 2) / 3)
            : durationSeconds
        const durationWeeks = Math.floor(durationSeconds / ONE_WEEK_SECONDS)
        const effectiveDurationWeeks = Math.floor(effectiveDurationSeconds / ONE_WEEK_SECONDS)
        const discount = isNotGrandfathered
          ? VESTING_DISCOUNTS[durationWeeks - 1]
          : new Percent(250 * durationWeeks, 10000)

        const startTimestamp = parseInt(vestingPosition.startTimestamp)
        return {
          id: vestingPosition.id,
          nftId: vestingPosition.id.split('-')[1],
          oTokenAmount: createCurrencyAmount(oToken!, vestingPosition.oTokenAmount),
          status: vestingPosition.status as VestingPositionStatus,
          durationSeconds: effectiveDurationSeconds,
          durationWeeks: effectiveDurationWeeks,
          discountPercentage: discount,
          startTimestamp: new Date(startTimestamp * 1000),
          vestingCompleteTimestamp: new Date((startTimestamp + effectiveDurationSeconds) * 1000),
        }
      })
      .sort((a, b) => a.vestingCompleteTimestamp.getTime() - b.vestingCompleteTimestamp.getTime())
    return {
      loading: anyLoading,
      error: anyError,
      data: vestingPositions,
    }
  }, [
    oToken,
    allFinalizedClaimsQueryState.loading,
    allFinalizedClaimsQueryState.error,
    allFinalizedClaimsQueryState.result?.liquidityMiningVestingPositions,
    grandfatherId,
    level,
    totalDepositedValue,
  ])
}
