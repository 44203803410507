import { address } from '@dolomite-exchange/dolomite-margin/dist/src/types'
import useGlpYield from '../../hooks/gmx/useGlpYield'
import usePlvGlpYield from '../../hooks/plutus/usePlvGlpYield'
import { SpecialAssetSymbol, useAllSpecialAssets } from '../../constants/isolation/special-assets'
import useMagicGlpYield from '../../hooks/abracadabra/useMagicGlpYield'
import { useActiveWeb3React } from '../../hooks'
import { useMemo } from 'react'
import { GraphqlClientType, parseCallKey } from './actions'
import { Percent } from '@dolomite-exchange/sdk-core'
import useGmxYield from '../../hooks/gmx/useGmxYield'
import { InterestRatePart } from '../../types/InterestRatePart'

export function deserializeInterestRatePartJson(json: any): InterestRatePart[] {
  return json.map((part: InterestRatePartJson) => {
    return {
      label: part.label,
      interestRate: part.interestRate ? Percent.fromSplitString(part.interestRate) : undefined,
    }
  })
}

export function deserializeInterestRatePartMapJson(json: any): Record<string, InterestRatePart[] | undefined> {
  return Object.keys(json).reduce<Record<string, InterestRatePart[] | undefined>>((memo, key) => {
    memo[key] = deserializeInterestRatePartJson(JSON.parse(json[key]))
    return memo
  }, {})
}

export function serializeInterestRatePartJson(parts: InterestRatePart[] | undefined): string | undefined {
  if (!parts) {
    return undefined
  }

  return JSON.stringify(
    parts.map(part => ({
      label: part.label,
      interestRate: part.interestRate?.toString(),
    })),
  )
}

function serializeInterestRatePartMapJson(partMap: Record<string, InterestRatePart[] | undefined>): string | undefined {
  if (!partMap) {
    return undefined
  }

  return JSON.stringify(
    Object.keys(partMap).reduce<Record<string, string | undefined>>((memo, key) => {
      memo[key] = serializeInterestRatePartJson(partMap[key])
      return memo
    }, {}),
  )
}

interface InterestRatePartJson {
  readonly label: string
  readonly interestRate: string | undefined
}

export function deserializeAllFunctionalInterestRatesMap(value: string): Record<string, string | undefined> {
  return JSON.parse(value)
}

export function useAllFunctionalInterestRates(serializedCallKeys: string): string {
  const { chainId } = useActiveWeb3React()
  const allSpecialAssets = useAllSpecialAssets()

  const glpInterestRateParts = useGlpYield()
  const glpInterestRatePartsSerialized = serializeInterestRatePartJson(glpInterestRateParts)
  const gmxInterestRatePartsSerialized = serializeInterestRatePartJson(useGmxYield())
  const magicGlpInterestRatePartsSerialized = serializeInterestRatePartJson(useMagicGlpYield(glpInterestRateParts))
  const plvGlpInterestRatePartsSerialized = serializeInterestRatePartJson(usePlvGlpYield(glpInterestRateParts))

  return useMemo(() => {
    const outdatedCallKeys: string[] = JSON.parse(serializedCallKeys)
    if (outdatedCallKeys.length === 0) {
      return JSON.stringify({})
    }
    const callsList = outdatedCallKeys
      .filter(key => {
        const calls = parseCallKey(key)
        return calls.every(c => c.clientType === GraphqlClientType.Functional && c.chainId === chainId)
      })
      .map(key => parseCallKey(key))
    if (callsList.length === 0 || callsList.some(calls => calls.length === 0)) {
      return JSON.stringify({})
    }

    const glpInterestRatePartsDeserialized = glpInterestRatePartsSerialized
      ? deserializeInterestRatePartJson(JSON.parse(glpInterestRatePartsSerialized))
      : []
    const gmxInterestRatePartsDeserialized = gmxInterestRatePartsSerialized
      ? deserializeInterestRatePartJson(JSON.parse(gmxInterestRatePartsSerialized))
      : []
    const magicGlpInterestRatePartsDeserialized = magicGlpInterestRatePartsSerialized
      ? deserializeInterestRatePartJson(JSON.parse(magicGlpInterestRatePartsSerialized))
      : []
    const plvGlpInterestRatePartsDeserialized = plvGlpInterestRatePartsSerialized
      ? deserializeInterestRatePartJson(JSON.parse(plvGlpInterestRatePartsSerialized))
      : []

    const specialAssetRewards = allSpecialAssets.reduce<Record<address, string | undefined>>((acc, asset) => {
      const rewardInfo = asset.rewardInfo?.getOutsideAprCallData(chainId)
      const extraRewardInfo = asset.rewardInfo?.getExtraOutsideAprCallData?.(chainId)
      if (
        rewardInfo?.clientType === GraphqlClientType.Functional ||
        extraRewardInfo?.clientType === GraphqlClientType.Functional
      ) {
        const tokenAddress = asset.chainIdToAddressMap[chainId] ?? ''
        switch (asset.symbol) {
          case SpecialAssetSymbol.dfsGLP:
            acc[tokenAddress] = serializeInterestRatePartJson(glpInterestRatePartsDeserialized)
            break
          case SpecialAssetSymbol.dGMX:
            acc[tokenAddress] = serializeInterestRatePartJson(gmxInterestRatePartsDeserialized)
            break
          case SpecialAssetSymbol.magicGLP:
            acc[tokenAddress] = serializeInterestRatePartJson(magicGlpInterestRatePartsDeserialized)
            break
          case SpecialAssetSymbol.dplvGLP:
            acc[tokenAddress] = serializeInterestRatePartJson(plvGlpInterestRatePartsDeserialized)
            break
          default:
            throw new Error(`Could not find outside APR for ${asset.symbol}`)
        }
      }
      return acc
    }, {})

    return JSON.stringify(specialAssetRewards)
  }, [
    serializedCallKeys,
    glpInterestRatePartsSerialized,
    gmxInterestRatePartsSerialized,
    magicGlpInterestRatePartsSerialized,
    plvGlpInterestRatePartsSerialized,
    allSpecialAssets,
    chainId,
  ])
}
