import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import Fills from './OrderTypes/Fills'
import Positions from './OrderTypes/Positions'
import { StyledTooltip } from '../../components/common/StyledTooltip'
import { Field } from '../../state/trade/actions'
import { useTradeState } from '../../state/trade/hooks'
import { useToken } from '../../hooks/Tokens'
import cleanCurrencySymbol from '../../utils/cleanCurrencySymbol'
import { MarginPositionStatus, useMarginPositionsByWalletAddressData } from '../../types/marginPositionData'
import { useActiveWeb3React } from '../../hooks'

const POSITIONS = 1
// const ORDERS = 2
const FILLS = 3

const OrdersPanelWrapper = styled.div`
  width: calc(100% - 40px);
  margin-left: 20px;
  height: 100%;
  position: relative;
  overflow: hidden;
  padding: 20px 0 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: ${({ theme }) => theme.primary5};
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12);

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    max-width: 400px;
    margin: 0 auto !important;
    height: fit-content;
    padding: 20px 0 10px;
  `};
`

const OrdersPanelInner = styled.div`
  position: relative;
  height: 100%;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    height: fit-content;
  `};
`

const TabsRow = styled.div`
  width: 100%;
  text-align: left;
  padding: 0 25px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 0 20px;
  `};
`

const TabTitle = styled.div<{ selected: boolean }>`
  width: fit-content;
  border-radius: 3px;
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  margin-right: 20px;
  font-size: 18px;
  ${({ selected }) => (selected ? `font-weight: 600;` : 'font-weight: 300;')}

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 16px;
  `};
`

const OpenPositionCount = styled.div`
  width: fit-content;
  display: inline-block;
  color: ${({ theme }) => theme.text1};
  background-color: ${({ theme }) => theme.bg4};
  font-size: 10px;
  font-weight: 700;
  line-height: 18px;
  border-radius: 5px;
  height: 18px;
  vertical-align: top;
  margin-left: -16px;
  margin-top: 3px;
  padding: 0 5px;
`

const CurrentMarket = styled.div`
  float: right;
  color: ${({ theme }) => theme.text2};
  font-size: 11px;
  font-weight: 300;
  text-align: right;
  line-height: 12px;
  display: inline-block;
  vertical-align: top;
  cursor: default;
`

export default function OrdersPanel() {
  const { t } = useTranslation()
  const { account } = useActiveWeb3React()

  const [selectedTab, setSelectedTab] = useState(FILLS)

  const {
    [Field.INPUT]: { currencyId: inputCurrencyId },
    [Field.OUTPUT]: { currencyId: outputCurrencyId },
  } = useTradeState()

  const inputCurrency = useToken(inputCurrencyId)
  const outputCurrency = useToken(outputCurrencyId)

  useEffect(() => {
    const listener = () => {
      setSelectedTab(POSITIONS)
    }
    document.addEventListener('switchToMarginTab', listener)
    return () => {
      document.removeEventListener('switchToMarginTab', listener)
    }
  }, [])

  const { data: positions, loading } = useMarginPositionsByWalletAddressData(account, undefined)

  const openPositionsCount = useMemo(() => {
    return positions.reduce((count, current) => (current.status === MarginPositionStatus.Open ? ++count : count), 0)
  }, [positions])

  // TODO - should it switch to the positions tab when they switch to margin?

  // TODO - Add that sliding underline (like on the navbar of the original Dolomite) under the selected tab to make it clearer which tab is selected

  // TODO - add a checkbox or toggle to show all pairs or just the current market
  return (
    <OrdersPanelWrapper>
      <OrdersPanelInner>
        <TabsRow>
          <TabTitle selected={selectedTab === FILLS} onClick={() => setSelectedTab(FILLS)}>
            {t('fills')}
          </TabTitle>
          {/*<TabTitle selected={selectedTab === ORDERS} onClick={() => setSelectedTab(ORDERS)}>{t('orders')}</TabTitle>*/}
          <TabTitle selected={selectedTab === POSITIONS} onClick={() => setSelectedTab(POSITIONS)}>
            {t('positions')}
          </TabTitle>
          {openPositionsCount > 0 && <OpenPositionCount>{openPositionsCount}</OpenPositionCount>}
          {selectedTab === FILLS && (
            <StyledTooltip
              title={
                'These trades pertain only to the currently selected market, change the selected tokens to view trades for other markets, or visit the history page for a comprehensive list.'
              }
              placement={'top'}
            >
              <CurrentMarket>
                Current Market:
                <br />
                {cleanCurrencySymbol(inputCurrency)}-{cleanCurrencySymbol(outputCurrency)}
              </CurrentMarket>
            </StyledTooltip>
          )}
        </TabsRow>
        {selectedTab === FILLS ? <Fills /> : <Positions positions={positions} loading={loading} />}
      </OrdersPanelInner>
    </OrdersPanelWrapper>
  )
}
