import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import NumericalInput from '../../../NumericalInput'
import { StyledTooltip, StyledTooltipWithIcon } from '../../../common/StyledTooltip'
import CircularProgress from '@material-ui/core/CircularProgress'
import { ZERO_FRACTION } from '../../../../constants'
import { tryParseAmount } from '../../../../state/trade/hooks'
import { Token } from '@dolomite-exchange/v2-sdk'
import {
  usePlvGlpStakedBalance,
  usePlvGlpToken,
  usePlvGlpUnstakedBalance,
} from '../../../../hooks/plutus/usePlutusProtocol'
import { useStakePlvGlp, useUnstakePlvGlp } from '../../../../hooks/plutus/usePlvGlpDolomiteProxyProtocol'
import { useIsTransactionPending } from '../../../../state/transactions/hooks'
import { formatAmount } from '../../../../utils/formatAmount'
import { useActiveWeb3React } from '../../../../hooks'

export const ExpandedContent = styled.div<{ expandedHeight: number }>`
  width: calc(100% + 70px);
  margin-left: -35px;
  background: ${({ theme }) => theme.bg2};
  padding: 15px 35px 30px;
  height: ${({ expandedHeight }) => expandedHeight}px;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  position: relative;
`

export const AssetRewardsWrapper = styled.div`
  padding: 7px 35px 30px;
  width: 100%;
  overflow: auto;
`

export const AssetRewardsBody = styled.div`
  font-size: 13px;
  font-weight: 500;
`

const RewardsWrapper = styled.div`
  width: 100%;
  margin-bottom: -30px;
`

const DataSection = styled.div`
  width: 100%;
  margin-bottom: 8px;
`

const DataRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  line-height: 20px;
`

const DataName = styled.div`
  font-weight: 300;

  svg {
    transform: translateY(1px);
  }
`

const DataValue = styled.div`
  font-weight: 600;
`

const ModifySection = styled.div<{ visible: boolean }>`
  width: calc(100% - 75px);
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  pointer-events: ${({ visible }) => (visible ? 'all' : 'none')};
  transition: opacity 0.2s ease-in-out;
  position: absolute;
  top: 10px;
`

const DepositSection = styled(ModifySection)`
  h3 {
    margin-top: 0;
    margin-bottom: 10px;

    svg {
      margin-bottom: -2px !important;
    }
  }
`

const WithdrawSection = styled(ModifySection)`
  h3 {
    margin-top: 0;
    margin-bottom: 10px;

    svg {
      margin-bottom: -2px !important;
    }
  }
`

const SubmitButton = styled.div<{ disabled?: boolean; blue?: boolean }>`
  width: 100%;
  padding: 12px 16px;
  border-radius: 5px;
  background-color: ${({ theme, blue }) => (blue ? theme.blue2 : theme.green2)};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.text1};
  opacity: ${({ disabled }) => (disabled ? 0.7 : 1)};
  text-align: center;
    /*${({ disabled }) => disabled && 'pointer-events: none;'}*/
  margin-top: 12px;

  :hover {
    background-color: ${({ theme, disabled, blue }) => (blue ? !disabled && theme.blue2 : !disabled && theme.green1)};
  }

  > div {
    width: 20px !important;
    height: 20px !important;
    font-size: 12px;
    margin-top: -1px;
    margin-bottom: -5px;
  }

  svg {
    color: ${({ theme }) => theme.text1};
  }
`

const ActionButton = styled.div<{ selected: boolean }>`
  width: 50%;
  display: inline-block;
  vertical-align: top;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 15px;
  cursor: pointer;
  background: ${({ theme, selected }) => (selected ? theme.bg2 : theme.bg1)};

  :hover {
    background: ${({ theme, selected }) => (selected ? theme.bg2 : theme.bg7)};
  }

  :nth-of-type(1) {
    border-top-right-radius: 8px;
  }

  :nth-of-type(2) {
    border-top-left-radius: 8px;
  }
`

const ActionButtonsWrapper = styled.div`
  width: calc(100% + 70px);
  margin-left: -35px;
  margin-top: 20px;
`

const BalanceWrapper = styled.div`
  color: #d5d6e1;
  display: inline-block;
  vertical-align: top;
`

const InputWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  font-size: 15px;
  padding: 0.5rem 0.75rem;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.bg6};
`

const LabelRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 13px;
  font-weight: 500;

  svg {
    margin-bottom: -2px !important;
  }
`

const MaxButton = styled.div`
  cursor: pointer;
`

const IndentLeft = styled.div`
  width: calc(100% - 10px);
  margin-left: 10px;
`

const IndentArrow = styled.div`
  transform: scaleX(-1) translateY(2px);
  display: inline-block;
  color: ${({ theme }) => theme.text3};
  font-weight: 200;
  font-size: 20px;
  margin-left: -5px;
  margin-right: 5px;
`

enum StakeOrUnstakeTab {
  STAKE_TAB = 0,
  UNSTAKE_TAB = 1,
}

export default function StakePLVGLP() {
  const { t } = useTranslation()
  const { account } = useActiveWeb3React()
  const [selectedTab, setSelectedTab] = useState(StakeOrUnstakeTab.STAKE_TAB)
  const [inputValue, setInputValue] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const [awaitingSignature, setAwaitingSignature] = useState(false)
  const [pendingHash, setPendingHash] = useState<string | undefined>(undefined)
  const plvGlpToken = usePlvGlpToken()

  const isHashPending = useIsTransactionPending(pendingHash)
  useEffect(() => {
    if (!isHashPending) {
      setPendingHash(undefined)
    }
    setSubmitting(isHashPending)
  }, [isHashPending])

  const parsedInputValue = useMemo(() => {
    if (!plvGlpToken) {
      return undefined
    }
    return tryParseAmount(inputValue, plvGlpToken)
  }, [inputValue, plvGlpToken])

  const unstakedPlvGlpBalance = usePlvGlpUnstakedBalance()
  const stakedPlvGlpBalance = usePlvGlpStakedBalance()
  const totalPlvGlpBalance = useMemo(() => {
    if (!stakedPlvGlpBalance || !unstakedPlvGlpBalance) {
      return undefined
    }
    return stakedPlvGlpBalance.add(unstakedPlvGlpBalance)
  }, [stakedPlvGlpBalance, unstakedPlvGlpBalance])

  const isDataLoaded = useMemo(() => !account || (!!stakedPlvGlpBalance && !!unstakedPlvGlpBalance), [
    account,
    stakedPlvGlpBalance,
    unstakedPlvGlpBalance,
  ])

  const maxAmount = useMemo(() => {
    if (selectedTab === StakeOrUnstakeTab.STAKE_TAB) {
      return unstakedPlvGlpBalance
    } else {
      return stakedPlvGlpBalance
    }
  }, [selectedTab, stakedPlvGlpBalance, unstakedPlvGlpBalance])

  const insufficientBalance = useMemo(() => maxAmount && parsedInputValue?.asFraction.greaterThan(maxAmount), [
    maxAmount,
    parsedInputValue,
  ])
  const isButtonActive = useMemo(
    () => !submitting && parsedInputValue?.greaterThan(ZERO_FRACTION) && !insufficientBalance,
    [insufficientBalance, parsedInputValue, submitting],
  )

  const updateSelectedTab = useCallback(
    (newSelectedTab: StakeOrUnstakeTab) => {
      if (newSelectedTab !== selectedTab) {
        setSelectedTab(newSelectedTab)
        setInputValue('')
      }
    },
    [selectedTab],
  )

  const updateInput = useCallback((inputString: string) => {
    const re = /^\d*(\.\d*)?$/ //Only allow numbers and a single decimal point

    if (inputString === '' || re.test(inputString)) {
      setInputValue(inputString)
    }
  }, [])

  const { callback: stakePlvGlpCallback } = useStakePlvGlp(parsedInputValue)
  const { callback: unstakePlvGlpCallback } = useUnstakePlvGlp(parsedInputValue)

  const submitTransaction = useCallback(() => {
    if (!stakePlvGlpCallback || !unstakePlvGlpCallback || awaitingSignature) {
      return
    }

    setSubmitting(true)
    setAwaitingSignature(true)

    let callback: () => Promise<string>
    if (selectedTab === StakeOrUnstakeTab.STAKE_TAB) {
      callback = stakePlvGlpCallback
    } else if (selectedTab === StakeOrUnstakeTab.UNSTAKE_TAB) {
      callback = unstakePlvGlpCallback
    } else {
      console.warn('Invalid selected tab for plvGLP', selectedTab)
      return
    }

    callback()
      .then((hash: any) => {
        if (typeof hash === 'string') {
          setPendingHash(hash)
        }
        setAwaitingSignature(false)
      })
      .catch(() => {
        setAwaitingSignature(false)
        setSubmitting(false)
      })
  }, [awaitingSignature, selectedTab, stakePlvGlpCallback, unstakePlvGlpCallback])

  const setMax = useCallback(() => {
    if (plvGlpToken && maxAmount) {
      setInputValue(maxAmount.toFixed((plvGlpToken as Token).decimals))
    }
  }, [maxAmount, plvGlpToken])

  return (
    <AssetRewardsWrapper>
      <h2>Stake plvGLP</h2>
      <AssetRewardsBody>
        <RewardsWrapper>
          <DataSection>
            <DataRow>
              <DataName>Total plvGLP Balance</DataName>
              <DataValue>{formatAmount(totalPlvGlpBalance, 4, true)} plvGLP</DataValue>
            </DataRow>
            <IndentLeft>
              <DataRow>
                <DataName>
                  <IndentArrow>↵</IndentArrow>Unstaked
                </DataName>
                <DataValue>{formatAmount(unstakedPlvGlpBalance, 4, true)} plvGLP</DataValue>
              </DataRow>
              <DataRow>
                <DataName>
                  <IndentArrow>↵</IndentArrow>Staked
                </DataName>
                <DataValue>{formatAmount(stakedPlvGlpBalance, 4, true)} plvGLP</DataValue>
              </DataRow>
            </IndentLeft>
          </DataSection>
          <ActionButtonsWrapper>
            <ActionButton
              selected={selectedTab === StakeOrUnstakeTab.STAKE_TAB}
              onClick={() => {
                updateSelectedTab(StakeOrUnstakeTab.STAKE_TAB)
              }}
            >
              Stake
            </ActionButton>
            <ActionButton
              selected={selectedTab === StakeOrUnstakeTab.UNSTAKE_TAB}
              onClick={() => {
                updateSelectedTab(StakeOrUnstakeTab.UNSTAKE_TAB)
              }}
            >
              Unstake
            </ActionButton>
          </ActionButtonsWrapper>
          <ExpandedContent expandedHeight={180}>
            <DepositSection visible={selectedTab === StakeOrUnstakeTab.STAKE_TAB}>
              <h3>
                Stake plvGLP
                <StyledTooltipWithIcon tooltipText={'Stake plvGLP in order to earn rewards.'} />
              </h3>
              <LabelRow>
                <div>
                  <BalanceWrapper>
                    {`plvGLP ${t('balance', {
                      balanceInput: formatAmount(unstakedPlvGlpBalance, 4, true),
                    })}`}
                  </BalanceWrapper>
                  <StyledTooltipWithIcon
                    tooltipText={
                      'The balance of plvGLP you currently have deposited to your Dolomite Vault and have available for staking. You can stake plvGLP even if it is being used in a borrow position.'
                    }
                  />
                </div>
                <MaxButton onClick={() => setMax()}>Max</MaxButton>
              </LabelRow>
              <InputWrapper>
                <NumericalInput
                  className={'token-amount-input'}
                  value={inputValue}
                  onUserInput={val => {
                    updateInput(val)
                  }}
                  fontSize={'inherit'}
                  unit={'plvGLP'}
                  maxDecimals={18}
                />
              </InputWrapper>
              <StyledTooltip
                title={
                  submitting
                    ? awaitingSignature
                      ? 'Awaiting signature...'
                      : 'Submitting...'
                    : !isDataLoaded
                    ? 'Loading data, please wait...'
                    : insufficientBalance
                    ? 'Entered amount is greater than your balance.'
                    : parsedInputValue?.greaterThan(ZERO_FRACTION)
                    ? ''
                    : 'Enter a valid amount'
                }
                showTooltip={submitting || !parsedInputValue}
                position={'top'}
              >
                <SubmitButton
                  disabled={!isButtonActive || !isDataLoaded}
                  blue={false}
                  onClick={() => submitTransaction()}
                >
                  {submitting ? (
                    <CircularProgress />
                  ) : !account ? (
                    'No wallet connected'
                  ) : !isDataLoaded ? (
                    'Loading data...'
                  ) : (
                    'Stake'
                  )}
                </SubmitButton>
              </StyledTooltip>
            </DepositSection>
            <WithdrawSection visible={selectedTab === StakeOrUnstakeTab.UNSTAKE_TAB}>
              <h3>Unstake plvGLP</h3>
              <LabelRow>
                <div>
                  <BalanceWrapper>
                    {t('stakedBalance', {
                      balanceInput: formatAmount(stakedPlvGlpBalance, 6, true),
                    })}
                  </BalanceWrapper>
                  <StyledTooltipWithIcon
                    tooltipText={`This represents the amount of plvGLP you currently have staked. If you unstake, the plvGLP will withdraw to your Dolomite Vault.`}
                  />
                </div>
                <MaxButton onClick={() => setMax()}>Max</MaxButton>
              </LabelRow>
              <InputWrapper>
                <NumericalInput
                  className={'token-amount-input'}
                  value={inputValue}
                  onUserInput={val => {
                    updateInput(val)
                  }}
                  fontSize={'inherit'}
                  unit={'plvGLP'}
                  maxDecimals={18}
                />
              </InputWrapper>
              <StyledTooltip
                title={
                  submitting
                    ? awaitingSignature
                      ? 'Awaiting signature...'
                      : 'Submitting...'
                    : !isDataLoaded
                    ? 'Loading data, please wait...'
                    : insufficientBalance
                    ? 'Entered amount is greater than the amount staked.'
                    : parsedInputValue?.greaterThan(ZERO_FRACTION)
                    ? ''
                    : 'Enter a valid amount'
                }
                showTooltip={submitting || !parsedInputValue}
                position={'top'}
              >
                <SubmitButton
                  disabled={!isButtonActive || !isDataLoaded}
                  onClick={() => isButtonActive && isDataLoaded && submitTransaction()}
                >
                  {submitting ? (
                    <CircularProgress />
                  ) : !account ? (
                    'No wallet connected'
                  ) : !isDataLoaded ? (
                    'Loading data...'
                  ) : (
                    'Unstake'
                  )}
                </SubmitButton>
              </StyledTooltip>
            </WithdrawSection>
          </ExpandedContent>
        </RewardsWrapper>
      </AssetRewardsBody>
    </AssetRewardsWrapper>
  )
}
