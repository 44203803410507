import { Fraction } from '@dolomite-exchange/sdk-core'

export default function checkFractionOptEquality(
  fractionOpt1: Fraction | undefined,
  fractionOpt2: Fraction | undefined,
): boolean {
  if (fractionOpt1 === undefined && fractionOpt2 === undefined) {
    return true
  }
  if (fractionOpt1 === undefined || fractionOpt2 === undefined) {
    return false
  }
  return fractionOpt1.equalTo(fractionOpt2)
}
