import { USDY } from '../../index'
import { SpecialAsset, SpecialAssetSymbol } from '../special-assets'
import mapChainIdMapValues from '../../../utils/mapChainIdMapValues'

export const USDY_SPECIAL_ASSET: () => SpecialAsset = () => ({
  symbol: SpecialAssetSymbol.USDY,
  safeSymbol: SpecialAssetSymbol.USDY,
  cleanSymbol: 'USDY',
  cleanName: 'Ondo U.S. Dollar Yield',
  hasExternalRewards: false,
  documentationUrl: undefined,
  isIsolationMode: false,
  isBorrowingEnabled: true,
  chainIdToAddressMap: mapChainIdMapValues(USDY, t => t?.address),
  isolationModeInfo: undefined,
})
