import { Token } from '@dolomite-exchange/sdk-core'
import { ChainId, ChainIdMap } from '../chainId'

const decimals = 18
const symbol = 'LINK'
const name = 'ChainLink Token'

function getToken(chainId: ChainId, address: string) {
  return new Token(chainId, address, decimals, symbol, name)
}

export const LINK: ChainIdMap<Token | undefined> = {
  [ChainId.MAINNET]: getToken(ChainId.MAINNET, '0x514910771AF9Ca656af840dff83E8264EcF986CA'),
  [ChainId.ARBITRUM_ONE]: getToken(ChainId.ARBITRUM_ONE, '0xf97f4df75117a78c1A5a0DBb814Af92458539FB4'),
  [ChainId.BASE]: getToken(ChainId.BASE, '0x88Fb150BDc53A65fe94Dea0c9BA0a6dAf8C6e196'),
  [ChainId.BERACHAIN]: undefined,
  [ChainId.MANTLE]: undefined,
  [ChainId.POLYGON_ZKEVM]: getToken(ChainId.POLYGON_ZKEVM, '0x4B16e4752711A7ABEc32799C976F3CeFc0111f2B'),
  [ChainId.X_LAYER]: undefined,
}
