import { createReducer, nanoid } from '@reduxjs/toolkit'
import {
  addPopup,
  ApplicationModal,
  PopupContent,
  removePopup,
  setOpenModal,
  updateClientToChainToUrlIndex,
} from './actions'
import { GraphqlClientType } from '../graphql/actions'
import { ChainIdMap, initializeNumberChainIdMap } from '../../constants/chainId'

type PopupList = { key: string; show: boolean; content: PopupContent; removeAfterMs: number | null }[]

export interface ApplicationState {
  readonly clientToChainToUrlIndex: Readonly<Record<GraphqlClientType, Readonly<ChainIdMap<number>>>>
  readonly popupList: PopupList
  readonly openModal: ApplicationModal | null
}

const initialState: ApplicationState = {
  clientToChainToUrlIndex: {
    [GraphqlClientType.Blocks]: initializeNumberChainIdMap(),
    [GraphqlClientType.Dolomite]: initializeNumberChainIdMap(),
    [GraphqlClientType.Galxe]: initializeNumberChainIdMap(),
    [GraphqlClientType.Fetch]: initializeNumberChainIdMap(),
    [GraphqlClientType.Functional]: initializeNumberChainIdMap(),
    [GraphqlClientType.NoOp]: initializeNumberChainIdMap(),
    [GraphqlClientType.Post]: initializeNumberChainIdMap(),
    [GraphqlClientType.Web3]: initializeNumberChainIdMap(),
  },
  popupList: [],
  openModal: null,
}

export default createReducer(initialState, builder =>
  builder
    .addCase(updateClientToChainToUrlIndex, (state, action) => {
      const { clientType, chainId, urlIndex } = action.payload
      state.clientToChainToUrlIndex[clientType][chainId] = urlIndex
    })
    .addCase(setOpenModal, (state, action) => {
      state.openModal = action.payload
    })
    .addCase(addPopup, (state, { payload: { content, key, removeAfterMs = 15000 } }) => {
      state.popupList = (key ? state.popupList.filter(popup => popup.key !== key) : state.popupList).concat([
        {
          key: key ?? nanoid(),
          show: true,
          content,
          removeAfterMs,
        },
      ])
    })
    .addCase(removePopup, (state, { payload: { key } }) => {
      state.popupList.forEach(p => {
        if (p.key === key) {
          p.show = false
        }
      })
    }),
)
