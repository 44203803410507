import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import NumericalInput from '../../../NumericalInput'
import { StyledTooltip, StyledTooltipWithIcon } from '../../../common/StyledTooltip'
import CircularProgress from '@material-ui/core/CircularProgress'
import { ZERO_FRACTION } from '../../../../constants'
import { tryParseAmount } from '../../../../state/trade/hooks'
import { AssetRewardsBody, AssetRewardsWrapper } from './GLPBottomSection'
import { processGmxAndGlpData } from '../../../../utils/processGmxAndGlpData'
import { ExpandedContent } from './GLPTotalRewards'
import {
  useEsGmxToken,
  useGlpAum,
  useGmxBalanceWithSupplies,
  useGmxDepositInfo,
  useGmxPriceUSD,
  useGmxStakingInfos,
  useGmxSupply,
  useGmxVestingInfo,
  useStakedGmxSupply,
} from '../../../../hooks/gmx/useGmxProtocol'
import { useDefaultFiatValuesWithLoadingIndicator } from '../../../../hooks/useFiatValue'
import useMidAum from '../../../../hooks/gmx/useMidAum'
import { Fraction } from '@dolomite-exchange/sdk-core'
import {
  useUnvestEsGmxWithGlp,
  useUnvestEsGmxWithGmx,
  useVestEsGmxWithGlp,
  useVestEsGmxWithGmx,
} from '../../../../hooks/gmx/useGmxDolomiteProxyProtocol'
import useGlpProxyVaultAccount from '../../../../hooks/gmx/useGlpProxyVaultAccount'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { useWethToken } from '../../../../hooks/Tokens'
import { formatAmount } from '../../../../utils/formatAmount'

const RewardsWrapper = styled.div`
  width: 100%;
  margin-bottom: -30px;
`

const Subtitle = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: ${({ theme }) => theme.text2};

  a {
    color: ${({ theme }) => theme.text1};
    font-weight: 700;
    text-decoration: none;
    outline: none !important;

    :hover {
      color: ${({ theme }) => theme.text2};
    }
  }
`

const StyledTabs = styled(({ ...rest }) => <Tabs classes={{ indicator: 'indicator' }} {...rest} />)`
  font-family: 'Open Sans', sans-serif !important;
  justify-content: normal !important;
  min-height: 0 !important;
  margin-top: 10px;
  margin-bottom: 15px;

  .indicator {
    background-color: #f9f9f9 !important;
    bottom: 0 !important;
    display: block !important;
    height: 1.4px !important;
    transform: scale(0.5, 1) !important;
  }

  > div > div {
    justify-content: space-evenly;
  }
`

const StyledTab = styled(({ ...rest }) => (
  <Tab
    classes={{
      root: 'root',
      selected: 'selected',
    }}
    {...rest}
  />
))`
  font-family: 'Open Sans', sans-serif !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  margin-left: 0 !important;
  margin-right: 13px !important;
  text-transform: capitalize !important;
  padding: 0 !important;
  padding-bottom: 3.5px !important;
  padding-left: 4px !important;
  padding-right: 4px !important;
  max-width: 264px;
  min-width: 0 !important;
  color: #606375 !important;
  min-height: 0 !important;

  ${({ selected }) =>
    selected &&
    `
    color: #f9f9f9 !important;
  `}
  .selected {
    color: #f9f9f9 !important;
  }

  .root span {
    font-size: 18px !important;
  }
`

const StakedTokensTooltipWrapper = styled.div`
  width: 240px;
  height: auto;
  padding: 10px 12px;
`

const TooltipStatRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: ${({ theme }) => theme.text1};
  font-weight: 600;

  div:nth-of-type(1) {
    font-weight: 500;
  }
`

const StakedTokensTooltip = ({
  gmxAmount,
  esGmxAmount,
  multiplierPoints,
}: {
  gmxAmount: string
  esGmxAmount: string
  multiplierPoints: string
}) => {
  return (
    <StakedTokensTooltipWrapper>
      <TooltipStatRow>
        <div>GMX:</div>
        <div>{gmxAmount}</div>
      </TooltipStatRow>
      <TooltipStatRow>
        <div>esGMX:</div>
        <div>{esGmxAmount}</div>
      </TooltipStatRow>
      <TooltipStatRow>
        <div>Multiplier Points:</div>
        <div>{multiplierPoints}</div>
      </TooltipStatRow>
    </StakedTokensTooltipWrapper>
  )
}

const DataSection = styled.div`
  width: 100%;
  margin-bottom: 8px;
  margin-top: 15px;
`

const DataRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  line-height: 20px;
`

const DataName = styled.div`
  font-weight: 300;

  svg {
    margin-bottom: -2px !important;
  }
`

const DataValue = styled.div`
  font-weight: 600;
`

const ModifySection = styled.div<{ visible: boolean }>`
  width: calc(100% - 75px);
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  pointer-events: ${({ visible }) => (visible ? 'all' : 'none')};
  transition: opacity 0.2s ease-in-out;
  position: absolute;
  top: 10px;
`

const DepositSection = styled(ModifySection)`
  h3 {
    margin-bottom: -18px;
    margin-top: 14px;

    svg {
      margin-bottom: -2px !important;
    }
  }
`

const WithdrawSection = styled(ModifySection)``

const SubmitButton = styled.div<{ disabled?: boolean }>`
  width: 100%;
  padding: 12px 16px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.green2};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.text1};
  opacity: ${({ disabled }) => (disabled ? 0.7 : 1)};
  text-align: center;
  margin-top: 12px;

  :hover {
    background-color: ${({ theme, disabled }) => !disabled && theme.green1};
  }

  > div {
    width: 20px !important;
    height: 20px !important;
    font-size: 12px;
    margin-top: -1px;
    margin-bottom: -5px;
  }

  svg {
    color: ${({ theme }) => theme.text1};
  }
`

const ActionButton = styled.div<{ selected: boolean }>`
  width: 50%;
  display: inline-block;
  vertical-align: top;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 15px;
  cursor: pointer;
  background: ${({ theme, selected }) => (selected ? theme.bg2 : theme.bg1)};

  :hover {
    background: ${({ theme, selected }) => (selected ? theme.bg2 : theme.bg7)};
  }

  :nth-of-type(1) {
    border-top-right-radius: 8px;
  }

  :nth-of-type(2) {
    border-top-left-radius: 8px;
  }
`

const ActionButtonsWrapper = styled.div`
  width: calc(100% + 70px);
  margin-left: -35px;
  margin-top: 20px;
`

const InputWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  font-size: 15px;
  padding: 0.5rem 0.75rem;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.bg6};
  margin-bottom: 5px;
`

const LabelRow = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  font-size: 13px;
  font-weight: 500;

  svg {
    margin-bottom: -2px !important;
  }
`

const MaxButton = styled.div`
  cursor: pointer;
`

enum SelectedVault {
  GMX_VAULT = 0,
  GLP_VAULT = 1,
}

enum DepositOrWithdrawTabType {
  Deposit = 0,
  Withdraw = 1,
}

const options = ['GMX Vault', 'GLP Vault']

export default function VestEsGmx() {
  const vaultAccount = useGlpProxyVaultAccount()
  const [selectedVaultType, setSelectedVaultType] = useState(SelectedVault.GMX_VAULT)
  const [selectedTab, setSelectedTab] = useState(DepositOrWithdrawTabType.Deposit)
  const [inputValue, setInputValue] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const [awaitingSignature, setAwaitingSignature] = useState(false)
  const esGmxToken = useEsGmxToken()
  const parsedInputValueCurrencyAmount = useMemo(() => tryParseAmount(inputValue, esGmxToken), [esGmxToken, inputValue])
  const parsedInputValue = useMemo(() => parsedInputValueCurrencyAmount?.asFraction, [parsedInputValueCurrencyAmount])

  const wethToken = useWethToken()
  const [fiatPriceMap] = useDefaultFiatValuesWithLoadingIndicator(useMemo(() => [wethToken], [wethToken]))
  const wethPrice = fiatPriceMap[wethToken.address]
  const [stakingData] = useGmxStakingInfos(vaultAccount)
  const [vestingData] = useGmxVestingInfo(vaultAccount)
  const [gmxPrice] = useGmxPriceUSD()
  const [aums] = useGlpAum()
  const aum = useMidAum(aums)
  const [balanceAndSupplyData] = useGmxBalanceWithSupplies(vaultAccount)
  const [depositBalanceData] = useGmxDepositInfo(vaultAccount)
  const stakedGmxSupply = useStakedGmxSupply()
  const gmxSupply = useGmxSupply()

  const processedData = useMemo(() => {
    return processGmxAndGlpData({
      balanceData: balanceAndSupplyData?.balanceData,
      supplyData: balanceAndSupplyData?.supplyData,
      depositBalanceData,
      stakingData,
      vestingData,
      aum,
      nativeTokenPrice: wethPrice,
      stakedGmxSupply,
      gmxPrice,
      gmxSupply,
    })
  }, [
    aum,
    balanceAndSupplyData,
    depositBalanceData,
    gmxPrice,
    gmxSupply,
    stakedGmxSupply,
    stakingData,
    vestingData,
    wethPrice,
  ])

  const totalRewardTokens = useMemo(() => {
    if (processedData?.bnGmxInFeeGmx && processedData.bonusGmxInFeeGmx) {
      return processedData.bnGmxInFeeGmx.add(processedData.bonusGmxInFeeGmx)
    } else {
      return undefined
    }
  }, [processedData])

  const stakedTokenBalance = useMemo(() => {
    if (selectedVaultType === SelectedVault.GMX_VAULT) {
      return totalRewardTokens
    } else {
      return processedData?.glpBalance
    }
  }, [processedData, totalRewardTokens, selectedVaultType])

  const remainingVestableEsGmxAmount = useMemo(() => {
    let amount: Fraction | undefined
    if (selectedVaultType === SelectedVault.GMX_VAULT) {
      amount = vestingData?.gmxVester?.maxVestableAmount.subtract(vestingData.gmxVester.vestedAmount)
    } else {
      amount = vestingData?.glpVester?.maxVestableAmount.subtract(vestingData.glpVester.vestedAmount)
    }
    return amount
  }, [selectedVaultType, vestingData])

  const hasSufficientEsGmxBalance = useMemo(() => {
    return processedData && parsedInputValue?.lessThanOrEqual(processedData.esGmxBalance)
  }, [parsedInputValue, processedData])

  const maxVestableAmount = useMemo(() => {
    if (selectedVaultType === SelectedVault.GMX_VAULT) {
      return vestingData?.gmxVester?.maxVestableAmount
    } else {
      return vestingData?.glpVester?.maxVestableAmount
    }
  }, [selectedVaultType, vestingData])

  const vestedAmount = useMemo(() => {
    if (selectedVaultType === SelectedVault.GMX_VAULT) {
      return vestingData?.gmxVester?.vestedAmount
    } else {
      return vestingData?.glpVester?.vestedAmount
    }
  }, [selectedVaultType, vestingData])

  const claimSum = useMemo(() => {
    if (selectedVaultType === SelectedVault.GMX_VAULT) {
      return vestingData?.gmxVester?.claimSum
    } else {
      return vestingData?.glpVester?.claimSum
    }
  }, [selectedVaultType, vestingData])

  const claimable = useMemo(() => {
    if (selectedVaultType === SelectedVault.GMX_VAULT) {
      return vestingData?.gmxVester?.claimable
    } else {
      return vestingData?.glpVester?.claimable
    }
  }, [selectedVaultType, vestingData])

  const reserveAmount = useMemo(() => {
    if (selectedVaultType === SelectedVault.GMX_VAULT) {
      return vestingData?.gmxVester?.pairAmount
    } else {
      return vestingData?.glpVester?.pairAmount
    }
  }, [selectedVaultType, vestingData])

  const averageStakedAmount = useMemo(() => {
    if (selectedVaultType === SelectedVault.GMX_VAULT) {
      return vestingData?.gmxVester?.averageStakedAmount
    } else {
      return vestingData?.glpVester?.averageStakedAmount
    }
  }, [selectedVaultType, vestingData])

  const nextDepositAmount = useMemo(() => {
    if (parsedInputValue) {
      return vestedAmount?.add(parsedInputValue)
    } else {
      return vestedAmount
    }
  }, [parsedInputValue, vestedAmount])

  const nextReserveAmount = useMemo(() => {
    if (nextDepositAmount && averageStakedAmount && maxVestableAmount?.greaterThan(ZERO_FRACTION)) {
      return nextDepositAmount.multiply(averageStakedAmount).divide(maxVestableAmount)
    }
    return reserveAmount
  }, [averageStakedAmount, maxVestableAmount, nextDepositAmount, reserveAmount])

  const additionalReserveAmount = useMemo(() => {
    if (reserveAmount && nextReserveAmount?.greaterThan(reserveAmount)) {
      return nextReserveAmount.subtract(reserveAmount)
    } else {
      return ZERO_FRACTION
    }
  }, [nextReserveAmount, reserveAmount])

  const updateSelectedVaultType = useCallback(
    (newSelectedVaultType: SelectedVault) => {
      if (newSelectedVaultType !== selectedVaultType) {
        setSelectedVaultType(newSelectedVaultType)
        setInputValue('')
      }
    },
    [selectedVaultType],
  )

  const updateSelectedTab = useCallback(
    (newSelectedTab: DepositOrWithdrawTabType) => {
      if (newSelectedTab !== selectedTab) {
        setSelectedTab(newSelectedTab)
        setInputValue('')
      }
    },
    [selectedTab],
  )

  const updateInput = useCallback((inputString: string) => {
    const re = /^\d*(\.\d*)?$/ // Only allow numbers and a single decimal point
    if (inputString === '' || re.test(inputString)) {
      setInputValue(inputString)
    }
  }, [])

  const setMax = useCallback((maxAmount?: Fraction) => {
    setInputValue(maxAmount?.toFixed(18) ?? '')
  }, [])

  const { callback: vestGmxCallback } = useVestEsGmxWithGmx(parsedInputValueCurrencyAmount)
  const { callback: vestGlpCallback } = useVestEsGmxWithGlp(parsedInputValueCurrencyAmount)
  const { callback: unvestGmxCallback } = useUnvestEsGmxWithGmx()
  const { callback: unvestGlpCallback } = useUnvestEsGmxWithGlp()

  const handleTransaction = useCallback(() => {
    if (selectedTab === DepositOrWithdrawTabType.Deposit && (!vestGmxCallback || !vestGlpCallback)) {
      return
    } else if (selectedTab === DepositOrWithdrawTabType.Withdraw && (!unvestGmxCallback || !unvestGlpCallback)) {
      return
    }

    let callback: (() => Promise<string>) | null = null
    if (selectedVaultType === SelectedVault.GMX_VAULT) {
      if (selectedTab === DepositOrWithdrawTabType.Deposit) {
        callback = vestGmxCallback
      } else if (selectedTab === DepositOrWithdrawTabType.Withdraw) {
        callback = unvestGmxCallback
      }
    } else {
      if (selectedTab === DepositOrWithdrawTabType.Deposit) {
        callback = vestGlpCallback
      } else if (selectedTab === DepositOrWithdrawTabType.Withdraw) {
        callback = unvestGlpCallback
      }
    }

    if (!callback) {
      console.error('No callback found for selected tab')
      return
    }

    setSubmitting(true)
    setAwaitingSignature(true)
    callback()
      .then(() => {
        setAwaitingSignature(false)
        setSubmitting(false)
      })
      .catch(() => {
        setAwaitingSignature(false)
        setSubmitting(false)
      })
  }, [selectedTab, selectedVaultType, unvestGlpCallback, unvestGmxCallback, vestGlpCallback, vestGmxCallback])

  const hasSufficientEsGmxForVesting = useMemo(
    () => parsedInputValue?.lessThanOrEqual(remainingVestableEsGmxAmount ?? ZERO_FRACTION),
    [parsedInputValue, remainingVestableEsGmxAmount],
  )

  const depositButtonTooltipText = useMemo(() => {
    if (submitting) {
      if (awaitingSignature) {
        return 'Awaiting signature...'
      }
      return 'Submitting...'
    } else if (parsedInputValue?.greaterThan(ZERO_FRACTION)) {
      if (!hasSufficientEsGmxForVesting) {
        return 'Entered amount is greater than the amount that is available to be vested'
      } else if (!hasSufficientEsGmxBalance) {
        return 'Insufficient esGMX balance. You may need to remove some esGMX from staking'
      } else if (nextReserveAmount?.greaterThan(stakedTokenBalance ?? ZERO_FRACTION)) {
        if (selectedVaultType === SelectedVault.GMX_VAULT) {
          return 'Insufficient staked GMX tokens'
        } else {
          return 'Insufficient GLP tokens'
        }
      }
      return undefined
    }
    return 'Please enter a valid amount'
  }, [
    submitting,
    parsedInputValue,
    awaitingSignature,
    hasSufficientEsGmxForVesting,
    hasSufficientEsGmxBalance,
    nextReserveAmount,
    stakedTokenBalance,
    selectedVaultType,
  ])

  const withdrawButtonTooltipText = useMemo(() => {
    if (submitting) {
      if (awaitingSignature) {
        return 'Awaiting signature...'
      }
      return 'Submitting...'
    } else if (
      selectedVaultType === SelectedVault.GMX_VAULT &&
      (!vestingData?.gmxVester?.vestedAmount || vestingData.gmxVester.vestedAmount.equalTo(ZERO_FRACTION))
    ) {
      return 'No assets to withdraw'
    } else if (
      selectedVaultType === SelectedVault.GLP_VAULT &&
      (!vestingData?.glpVester?.vestedAmount || vestingData.glpVester.vestedAmount.equalTo(ZERO_FRACTION))
    ) {
      return 'No assets to withdraw'
    }
    return undefined
  }, [submitting, selectedVaultType, vestingData, awaitingSignature])

  return (
    <AssetRewardsWrapper>
      <h2 style={{ marginBottom: '2px' }}>Vest esGMX</h2>
      <Subtitle>Convert esGMX tokens to GMX tokens.</Subtitle>
      <Subtitle>
        Please read the{' '}
        <a href={'https://gmxio.gitbook.io/gmx/rewards#vesting'} target={'_blank'} rel={'noreferrer'}>
          vesting details
        </a>{' '}
        before using the vaults.
      </Subtitle>
      <StyledTabs
        value={selectedVaultType}
        onChange={(_: any, index: number) => updateSelectedVaultType(index)}
        indicatorColor={'primary'}
        textColor={'primary'}
      >
        {(options ?? []).map((option: string, index: number) => (
          <StyledTab key={`tradeHeader-${index}`} disableRipple label={option} />
        ))}
      </StyledTabs>
      <AssetRewardsBody>
        <RewardsWrapper>
          <DataSection>
            <DataRow>
              <DataName>Staked Tokens</DataName>
              <DataValue>
                {selectedVaultType === SelectedVault.GMX_VAULT ? (
                  <StyledTooltip
                    title={
                      <StakedTokensTooltip
                        gmxAmount={formatAmount(processedData?.gmxInStakedGmx, 2, true)}
                        esGmxAmount={formatAmount(processedData?.esGmxInStakedGmx, 2, true)}
                        multiplierPoints={formatAmount(processedData?.bnGmxInFeeGmx, 2, true)}
                      />
                    }
                    position={'top'}
                  >
                    <div>{formatAmount(stakedTokenBalance, 2, true)}</div>
                  </StyledTooltip>
                ) : (
                  <div>{formatAmount(stakedTokenBalance, 2, true)} GLP</div>
                )}
              </DataValue>
            </DataRow>
            <DataRow>
              <DataName>Reserved for Vesting</DataName>
              <DataValue>
                {formatAmount(reserveAmount, 2, true)} / {formatAmount(stakedTokenBalance, 2, true)}
              </DataValue>
            </DataRow>
            <DataRow>
              <DataName>Vesting Status</DataName>
              <DataValue>
                <StyledTooltip
                  title={`${formatAmount(claimSum, 4, true)} tokens have been converted to GMX from the ${formatAmount(
                    vestedAmount,
                    4,
                    true,
                  )} esGMX deposited for vesting.`}
                >
                  <div style={{ cursor: 'default' }}>
                    {formatAmount(claimSum, 4, true)} /{` `}
                    {formatAmount(vestedAmount, 4, true)}
                  </div>
                </StyledTooltip>
              </DataValue>
            </DataRow>
            <DataRow>
              <DataName>Claimable</DataName>
              <DataValue>
                <StyledTooltip
                  title={`${formatAmount(
                    claimable,
                    4,
                    true,
                  )} GMX tokens can be claimed, close this panel and then click the Asset Rewards button`}
                >
                  <div style={{ cursor: 'default' }}>{formatAmount(claimable, 4, true)} GMX</div>
                </StyledTooltip>
              </DataValue>
            </DataRow>
          </DataSection>
          <ActionButtonsWrapper>
            <ActionButton
              selected={selectedTab === DepositOrWithdrawTabType.Deposit}
              onClick={() => {
                updateSelectedTab(DepositOrWithdrawTabType.Deposit)
              }}
            >
              Deposit
            </ActionButton>
            <ActionButton
              selected={selectedTab === DepositOrWithdrawTabType.Withdraw}
              onClick={() => {
                updateSelectedTab(DepositOrWithdrawTabType.Withdraw)
              }}
            >
              Withdraw
            </ActionButton>
          </ActionButtonsWrapper>
          <ExpandedContent expandedHeight={selectedTab === DepositOrWithdrawTabType.Deposit ? 232 : 240}>
            <DepositSection visible={selectedTab === DepositOrWithdrawTabType.Deposit}>
              <h3>
                {selectedVaultType === SelectedVault.GLP_VAULT ? 'GLP Vault' : 'GMX Vault'}
                <StyledTooltipWithIcon
                  tooltipText={
                    'Deposit esGMX from your wallet (not your Dolomite balance) into the GLP vault in order to earn rewards.'
                  }
                />
              </h3>
              <LabelRow>
                <MaxButton onClick={() => setMax(remainingVestableEsGmxAmount)}>
                  Max: {formatAmount(remainingVestableEsGmxAmount, 6, true)}
                </MaxButton>
              </LabelRow>
              <InputWrapper>
                <NumericalInput
                  className={'token-amount-input'}
                  value={inputValue}
                  onUserInput={val => {
                    updateInput(val)
                  }}
                  fontSize={'inherit'}
                  unit={'esGMX'}
                  maxDecimals={18}
                />
              </InputWrapper>
              <DataRow>
                <DataName>
                  Vault Balance
                  <StyledTooltipWithIcon
                    tooltipText={
                      'This represents the balance in your Vault account, not what is deposited onto Dolomite nor in your wallet. Currently Dolomite does not support esGMX deposits.'
                    }
                  />
                </DataName>
                <DataValue>{formatAmount(processedData?.esGmxBalance, 6, true)} esGMX</DataValue>
              </DataRow>
              <DataRow>
                <DataName>
                  Vault Capacity
                  <StyledTooltipWithIcon tooltipText={'Your amount deposited and the max capacity'} />
                </DataName>
                <DataValue>
                  {formatAmount(vestedAmount, 6, true)} / {formatAmount(maxVestableAmount, 6, true)}
                </DataValue>
              </DataRow>
              <DataRow>
                <DataName>
                  Reserve Amount
                  <StyledTooltipWithIcon tooltipText={'Your current reserved and the additional reserve required'} />
                </DataName>
                <DataValue>
                  {formatAmount(
                    reserveAmount?.greaterThanOrEqual(additionalReserveAmount)
                      ? reserveAmount
                      : additionalReserveAmount,
                    2,
                    true,
                  )}{' '}
                  / {formatAmount(stakedTokenBalance, 2, true)}
                </DataValue>
              </DataRow>
              <StyledTooltip title={depositButtonTooltipText} showTooltip={!!depositButtonTooltipText} position={'top'}>
                <SubmitButton
                  disabled={!!depositButtonTooltipText}
                  onClick={() => !depositButtonTooltipText && handleTransaction()}
                >
                  {submitting ? <CircularProgress /> : 'Deposit'}
                </SubmitButton>
              </StyledTooltip>
            </DepositSection>
            <WithdrawSection visible={selectedTab === DepositOrWithdrawTabType.Withdraw}>
              <p>{`This will withdraw and unreserve all tokens as well as pause vesting.`}</p>
              <p>{`esGMX tokens that have been converted to GMX will remain as GMX tokens.`}</p>
              <p>{`To claim GMX tokens without withdrawing, use the "Claim" button on the Asset Rewards panel.`}</p>
              <StyledTooltip
                title={withdrawButtonTooltipText}
                showTooltip={!!withdrawButtonTooltipText}
                position={'top'}
              >
                <SubmitButton
                  disabled={!!withdrawButtonTooltipText}
                  onClick={() => !withdrawButtonTooltipText && handleTransaction()}
                >
                  {submitting ? <CircularProgress /> : 'Withdraw'}
                </SubmitButton>
              </StyledTooltip>
            </WithdrawSection>
          </ExpandedContent>
        </RewardsWrapper>
      </AssetRewardsBody>
    </AssetRewardsWrapper>
  )
}
