import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import CircularProgress from '@material-ui/core/CircularProgress'
import { StyledTooltip, StyledTooltipWithIcon } from '../../../common/StyledTooltip'
import { useIsTransactionPending } from '../../../../state/transactions/hooks'
import { formatAmount } from '../../../../utils/formatAmount'
import { useInterestRateData } from '../../../../types/interestRateData'
import {
  useDjUSDCToken,
  useJUsdcPendingRewards,
  useJUsdcStakedBalance,
  useTotalStakedJUsdcSupplyUSD,
} from '../../../../hooks/jusdc/useJonesProtocol'
import { useHarvestJUsdcRewards } from '../../../../hooks/jusdc/useJUsdcDolomiteProxyProtocol'
import { useShowYieldAsApr } from '../../../../state/user/hooks'
import { useTranslation } from 'react-i18next'

const RewardsWrapper = styled.div`
  width: 100%;
`

export const AssetRewardsWrapper = styled.div`
  padding: 7px 35px 30px;
  width: 100%;
  overflow: auto;
`

export const AssetRewardsBody = styled.div`
  font-size: 13px;
  font-weight: 500;
`

const DataSection = styled.div`
  width: 100%;
  margin-bottom: 8px;
`

const DataRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  line-height: 20px;
`

const DataName = styled.div`
  font-weight: 300;

  svg {
    transform: translateY(1px);
  }
`

const DataValue = styled.div`
  font-weight: 600;
`

const SubmitButton = styled.div<{ disabled: boolean }>`
  width: calc(100%);
  padding: 12px 16px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.green2};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.text1};
  opacity: ${({ disabled }) => (disabled ? 0.7 : 1)};
  text-align: center;
  margin-top: 12px;

  :hover {
    background-color: ${({ theme, disabled }) => !disabled && theme.green1};
  }

  > div {
    width: 20px !important;
    height: 20px !important;
    font-size: 12px;
    margin-top: -1px;
    margin-bottom: -5px;
  }

  svg {
    color: ${({ theme }) => theme.text1};
  }
`

export default function ClaimJUSDCRewards() {
  const [submitting, setSubmitting] = useState(false)
  const [awaitingSignature, setAwaitingSignature] = useState(false)
  const { callback: claimCallback } = useHarvestJUsdcRewards()
  const { data: interestRateMap } = useInterestRateData()
  const djUsdcToken = useDjUSDCToken()
  const jUsdcInterestRate = interestRateMap[djUsdcToken?.address ?? '']
  const jUsdcTvlUSD = useTotalStakedJUsdcSupplyUSD()
  const currentlyStaked = useJUsdcStakedBalance()
  const [pendingRewards] = useJUsdcPendingRewards()
  const [pendingHash, setPendingHash] = useState<string | undefined>(undefined)
  const hasRewards = useMemo(() => pendingRewards?.greaterThan(0), [pendingRewards])
  const isDataLoaded = !!jUsdcTvlUSD && !!currentlyStaked && !!pendingRewards && !!jUsdcInterestRate
  const isButtonActive = useMemo(() => !submitting && isDataLoaded && hasRewards, [
    hasRewards,
    isDataLoaded,
    submitting,
  ])

  const isHashPending = useIsTransactionPending(pendingHash)
  useEffect(() => {
    if (!isHashPending) {
      setPendingHash(undefined)
    }
    setSubmitting(isHashPending)
  }, [isHashPending])

  const submitClaim = useCallback(() => {
    if (!claimCallback || awaitingSignature) {
      return
    }

    setSubmitting(true)
    setAwaitingSignature(true)
    claimCallback()
      .then((hash: any) => {
        setPendingHash(hash)
        setAwaitingSignature(false)
      })
      .catch(() => {
        setAwaitingSignature(false)
        setSubmitting(false)
      })
  }, [awaitingSignature, claimCallback])

  const [showYieldAsApr] = useShowYieldAsApr()
  const [t] = useTranslation()
  const yieldText = showYieldAsApr ? t('APR') : t('APY')

  return (
    <AssetRewardsWrapper>
      <h2>jUSDC ARB Rewards</h2>
      <AssetRewardsBody>
        <RewardsWrapper>
          <DataSection>
            <DataRow>
              <DataName>jUSDC {yieldText}</DataName>
              <DataValue>{formatAmount(jUsdcInterestRate?.totalSupplyInterestRate)}</DataValue>
            </DataRow>
            <DataRow>
              <DataName>
                jUSDC TVL
                <StyledTooltipWithIcon tooltipText={`The total value of all jUSDC in JonesDAO's staking contract.`} />
              </DataName>
              <DataValue>{formatAmount(jUsdcTvlUSD, 2, true, '-', true)}</DataValue>
            </DataRow>
            <DataRow>
              <DataName>
                Your jUSDC Stake
                <StyledTooltipWithIcon tooltipText={'The amount of jUSDC you currently have staked.'} />
              </DataName>
              <DataValue>{formatAmount(currentlyStaked, 4, true)} jUSDC</DataValue>
            </DataRow>
            {/*<HorizontalLineWithVerticalMargin />*/}
            <DataRow
              style={{
                marginTop: '15px',
                fontSize: '19px',
              }}
            >
              <DataName>Pending Rewards</DataName>
              <DataValue>{formatAmount(pendingRewards, 4, true)} ARB</DataValue>
            </DataRow>
            {/*<DataRow
              style={{
                fontSize: '14px',
                marginBottom: '-7px',
              }}
            >
              <DataName></DataName>
              <DollarValue>$0.45</DollarValue>
            </DataRow>*/}
          </DataSection>
          <StyledTooltip
            title={
              submitting
                ? awaitingSignature
                  ? 'Awaiting signature...'
                  : 'Submitting...'
                : !isDataLoaded
                ? 'Loading data, please wait...'
                : ''
            }
            position={'top'}
          >
            <SubmitButton disabled={!isButtonActive} onClick={() => isButtonActive && submitClaim()}>
              {submitting ? <CircularProgress /> : hasRewards ? 'Claim Rewards' : 'No Rewards to Claim'}
            </SubmitButton>
          </StyledTooltip>
        </RewardsWrapper>
      </AssetRewardsBody>
    </AssetRewardsWrapper>
  )
}
