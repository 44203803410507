import { useMemo } from 'react'

import { InterestRatePart } from '../../types/InterestRatePart'
import { ZERO_PERCENT } from '../../constants'

export default function usePlvGlpYield(originalGlpYield: InterestRatePart[]): InterestRatePart[] {
  return useMemo(() => {
    const glpYieldWithFees = originalGlpYield[0].interestRate?.multiply(9).divide(10)
    return [
      {
        interestRate: glpYieldWithFees,
        label: 'Auto-compounding yield from GLP',
        isBorrowRateImpacted: false,
        metadata: null,
      },
      {
        label: 'PLS Rewards',
        interestRate: ZERO_PERCENT,
        isBorrowRateImpacted: false,
        metadata: null,
      },
    ] as InterestRatePart[]
  }, [originalGlpYield])
}
