import { useMemo } from 'react'
import { BorrowPosition, BorrowPositionAmount } from '../types/borrowPositionData'
import { ZERO_FRACTION } from '../constants'
import { CurrencyAmount, Token } from '@dolomite-exchange/v2-sdk'
import calculateBorrowPositionHealth from '../utils/calculateBorrowPositionHealth'
import { CollateralActionType, LoanActionType, ManageType } from 'pages/Borrow/types'
import { MarketRiskInfo } from '../types/marketRiskInfoData'
import { Fraction } from '@dolomite-exchange/sdk-core'

export default function useNewBorrowPositionHealth(
  position: BorrowPosition,
  inputValue: CurrencyAmount<Token> | undefined,
  zapOutputValue: CurrencyAmount<Token> | undefined,
  manageType: ManageType,
  actionType: CollateralActionType | LoanActionType,
  fiatValueMap: Record<string, Fraction | undefined>,
  marketRiskInfoMap: Record<string, MarketRiskInfo | undefined>,
  minCollateralization: Fraction,
  isZapActivated: boolean,
): Fraction | undefined {
  return useMemo(() => {
    const supplyValue = !isZapActivated
      ? inputValue
      : manageType === ManageType.COLLATERAL
      ? actionType === CollateralActionType.DEPOSIT
        ? zapOutputValue
        : inputValue
      : actionType === LoanActionType.BORROW || actionType === LoanActionType.SWAP
      ? zapOutputValue
      : inputValue
    const swapValue = actionType === LoanActionType.SWAP ? zapOutputValue : undefined
    const supplyFiatValue = fiatValueMap[supplyValue?.currency.address ?? '']
    const supplySwapFiatValue = fiatValueMap[swapValue?.currency.address ?? '']
    const supplyMultiplier = actionType === CollateralActionType.DEPOSIT ? 1 : -1
    const swapMultiplier = manageType === ManageType.LOAN && actionType === LoanActionType.SWAP ? -1 : 1
    let supplyFound = false
    let supplySwapFound = false
    const supplyAmounts = position.supplyAmounts.map<BorrowPositionAmount>(amount => {
      if (amount.token.address === supplyValue?.currency.address) {
        supplyFound = true
        const price = amount.amountUSD.divide(amount.amountTokenWei.asFraction)
        const inputAmountWithMultiplier = supplyValue.multiply(supplyMultiplier)
        return inputAmountWithMultiplier.asFraction.abs.greaterThanOrEqual(amount.amountTokenWei.asFraction) &&
          inputAmountWithMultiplier.asFraction.lessThanOrEqual(ZERO_FRACTION)
          ? {
              ...amount,
              amountUSD: ZERO_FRACTION,
              amountTokenWei: CurrencyAmount.fromRawAmount(supplyValue.currency, '0'),
            }
          : {
              ...amount,
              amountUSD: amount.amountUSD.add(price.multiply(inputAmountWithMultiplier.asFraction)),
              amountTokenWei: amount.amountTokenWei.add(inputAmountWithMultiplier),
            }
      } else if (amount.token.address === swapValue?.currency.address) {
        supplySwapFound = true
        const price = amount.amountUSD.divide(amount.amountTokenWei.asFraction)
        const inputAmountWithMultiplier = swapValue.multiply(swapMultiplier)
        return {
          ...amount,
          amountUSD: amount.amountUSD.add(price.multiply(inputAmountWithMultiplier.asFraction)),
          amountTokenWei: amount.amountTokenWei.add(inputAmountWithMultiplier),
        }
      } else {
        return amount
      }
    })
    if (
      !supplyFound &&
      supplyValue &&
      supplyFiatValue &&
      (manageType === ManageType.COLLATERAL || (isZapActivated && actionType !== LoanActionType.SWAP))
    ) {
      supplyAmounts.push({
        isPositive: true,
        token: supplyValue.currency,
        amountTokenPar: CurrencyAmount.fromRawAmount(supplyValue.currency, '0'),
        amountTokenWei: supplyValue,
        amountUSD: supplyValue.asFraction.multiply(supplyFiatValue),
        interestAccruedToken: CurrencyAmount.fromRawAmount(supplyValue.currency, '0'),
        interestAccruedUSD: ZERO_FRACTION,
      } as BorrowPositionAmount)
    }
    if (
      !supplySwapFound &&
      swapValue &&
      supplySwapFiatValue &&
      manageType === ManageType.COLLATERAL &&
      actionType === LoanActionType.SWAP
    ) {
      supplyAmounts.push({
        isPositive: true,
        token: swapValue.currency,
        amountTokenPar: CurrencyAmount.fromRawAmount(swapValue.currency, '0'),
        amountTokenWei: zapOutputValue,
        amountUSD: swapValue.asFraction.multiply(supplySwapFiatValue),
        interestAccruedToken: CurrencyAmount.fromRawAmount(swapValue.currency, '0'),
        interestAccruedUSD: ZERO_FRACTION,
      } as BorrowPositionAmount)
    }

    const borrowValue =
      isZapActivated && manageType === ManageType.COLLATERAL
        ? undefined
        : !isZapActivated || manageType === ManageType.COLLATERAL
        ? inputValue
        : actionType === LoanActionType.BORROW || actionType === LoanActionType.SWAP
        ? inputValue
        : zapOutputValue
    const borrowFiatValue = fiatValueMap[borrowValue?.currency.address ?? '']
    const borrowMultiplier =
      actionType === LoanActionType.BORROW || (manageType === ManageType.LOAN && actionType === LoanActionType.SWAP)
        ? 1
        : -1
    let borrowFound = false
    let swapBorrowDifference = undefined as CurrencyAmount<Token> | undefined
    let swapBorrowAmount = undefined as BorrowPositionAmount | undefined
    let swapPrice = undefined as Fraction | undefined
    const borrowAmounts = position.borrowAmounts.map<BorrowPositionAmount>(amount => {
      if (amount.token.address === borrowValue?.currency.address) {
        borrowFound = true
        const price = amount.amountUSD.divide(amount.amountTokenWei.asFraction)
        const inputAmountWithMultiplier = borrowValue.multiply(borrowMultiplier)
        return inputAmountWithMultiplier.asFraction.abs.greaterThanOrEqual(amount.amountTokenWei.asFraction) &&
          inputAmountWithMultiplier.asFraction.lessThanOrEqual(ZERO_FRACTION)
          ? {
              ...amount,
              amountUSD: ZERO_FRACTION,
              amountTokenWei: CurrencyAmount.fromRawAmount(borrowValue.currency, '0'),
            }
          : {
              ...amount,
              amountUSD: amount.amountUSD.add(price.multiply(inputAmountWithMultiplier.asFraction)),
              amountTokenWei: amount.amountTokenWei.add(inputAmountWithMultiplier),
            }
      } else if (amount.token.address === swapValue?.currency.address) {
        const price = amount.amountUSD.divide(amount.amountTokenWei.asFraction)
        if (manageType === ManageType.LOAN && actionType === LoanActionType.SWAP && supplyValue) {
          swapBorrowDifference = amount.amountTokenWei.subtract(supplyValue)
          swapBorrowAmount = amount
          swapPrice = price
        }
        const inputAmountWithMultiplier = swapValue.multiply(swapMultiplier)
        return inputAmountWithMultiplier.asFraction.abs.greaterThanOrEqual(amount.amountTokenWei.asFraction) &&
          inputAmountWithMultiplier.asFraction.lessThanOrEqual(ZERO_FRACTION)
          ? {
              ...amount,
              amountUSD: ZERO_FRACTION,
              amountTokenWei: CurrencyAmount.fromRawAmount(swapValue.currency, '0'),
            }
          : {
              ...amount,
              amountUSD: amount.amountUSD.add(price.multiply(inputAmountWithMultiplier.asFraction)),
              amountTokenWei: amount.amountTokenWei.add(inputAmountWithMultiplier),
            }
      } else {
        return amount
      }
    })

    if (swapBorrowDifference?.lessThan(ZERO_FRACTION) && swapBorrowAmount && swapPrice) {
      supplyAmounts.push({
        isPositive: true,
        token: swapBorrowAmount.token,
        amountTokenPar: CurrencyAmount.fromRawAmount(swapBorrowAmount.token, '0'),
        amountTokenWei: swapBorrowDifference.multiply(-1),
        amountUSD: swapPrice.multiply(swapBorrowDifference.asFraction.abs),
        interestAccruedToken: CurrencyAmount.fromRawAmount(swapBorrowAmount.token, '0'),
        interestAccruedUSD: ZERO_FRACTION,
        expirationTimestamp: swapBorrowAmount.expirationTimestamp,
      })
    }
    if (!borrowFound && manageType === ManageType.LOAN) {
      if (borrowValue && borrowFiatValue) {
        borrowAmounts.push({
          isPositive: false,
          token: borrowValue.currency,
          amountTokenPar: CurrencyAmount.fromRawAmount(borrowValue.currency, '0'),
          amountTokenWei: borrowValue,
          amountUSD: borrowValue.asFraction.multiply(borrowFiatValue),
          interestAccruedToken: CurrencyAmount.fromRawAmount(borrowValue.currency, '0'),
          interestAccruedUSD: ZERO_FRACTION,
        } as BorrowPositionAmount)
      }
    }

    return calculateBorrowPositionHealth(supplyAmounts, borrowAmounts, marketRiskInfoMap, minCollateralization)
  }, [
    fiatValueMap,
    inputValue,
    isZapActivated,
    manageType,
    actionType,
    zapOutputValue,
    position,
    marketRiskInfoMap,
    minCollateralization,
  ])
}
