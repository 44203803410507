import { Currency, Fraction, Pair } from '@dolomite-exchange/v2-sdk'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import TransactionConfirmationModal, {
  ConfirmationModalContent,
  TransactionErrorContent,
} from '../TransactionConfirmationModal'
import { CurrencyAmount } from '@dolomite-exchange/sdk-core'
import { FORMATTER } from '../../constants'
import { ButtonError } from '../Button'
import { AutoRow, RowBetween, RowFixed } from '../Row'
import { Text } from 'rebass'
import styled, { ThemeContext } from 'styled-components/macro'
import QuestionHelper from '../QuestionHelper'
import { TYPE } from '../../theme'
import { AutoColumn } from '../Column'
import CurrencyLogo from '../CurrencyLogo'
import { LockOpen } from '@material-ui/icons'
import cleanCurrencySymbol from '../../utils/cleanCurrencySymbol'
import cleanCurrencyName from '../../utils/cleanCurrencyName'
import { formatAmount } from '../../utils/formatAmount'

const TopWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 5px;
`

const TruncatedText = styled(Text)`
  text-overflow: ellipsis;
  width: 220px;
  overflow: hidden;
  font-size: 24px;
  font-weight: 500;
  color: ${({ theme }) => theme.text1};
`

const SubmitButton = styled(ButtonError)`
  background-color: #494a60;

  :hover {
    background-color: #4b4c64;
  }

  :focus {
    background-color: #494a60;
  }

  :active {
    background-color: #232330;
  }
`

const UnlockIcon = styled.div`
  font-size: 80px;
  margin: 10px auto -20px;
`

const MarketPrice = styled.div`
  cursor: pointer;
  color: ${({ theme }) => theme.text1};
  font-size: 13px;
`

const addMinDecimals = (value: string | undefined, decimals: number) => {
  if (!value) {
    return value
  }
  if (!value.includes('.')) {
    return value + '.' + '0'.repeat(decimals)
  } else if (value.split('.')[1].length < decimals) {
    return value + '0'.repeat(decimals - value.split('.')[1].length)
  }
  return value
}

function PoolModalHeader({
  amount0,
  amount1,
  fiatAmount0,
  fiatAmount1,
  deposit,
  unlock,
  allowedSlippage,
}: {
  amount0: CurrencyAmount<Currency> | undefined
  amount1: CurrencyAmount<Currency> | undefined
  fiatAmount0: Fraction | undefined
  fiatAmount1: Fraction | undefined
  deposit: boolean
  unlock: boolean
  allowedSlippage: number
}) {
  const symbol0 = useMemo(() => cleanCurrencySymbol(amount0?.currency), [amount0])
  const symbol1 = useMemo(() => cleanCurrencySymbol(amount1?.currency), [amount1])
  const name0 = useMemo(() => cleanCurrencyName(amount0?.currency), [amount0])
  const name1 = useMemo(() => cleanCurrencyName(amount1?.currency), [amount1])
  const theme = useContext(ThemeContext)
  if (unlock) {
    return (
      <TopWrapper>
        <RowBetween align='flex-end'>
          <RowFixed gap={'0px'} style={{ width: '100%' }}>
            <UnlockIcon>
              <LockOpen fontSize='inherit' style={{ color: theme.text1 }} />
            </UnlockIcon>
          </RowFixed>
        </RowBetween>
      </TopWrapper>
    )
  }
  return (
    <TopWrapper>
      <AutoColumn gap={'md'} style={{ marginTop: '10px' }}>
        <>
          <RowBetween align='flex-end'>
            <RowFixed gap={'0px'}>
              <CurrencyLogo currency={amount0?.currency} size={'36px'} style={{ marginRight: '12px' }} />
              <TruncatedText style={{ marginTop: '-14px' }}>
                {addMinDecimals(amount0?.toSignificant(6), 2)}
              </TruncatedText>
            </RowFixed>
            <RowFixed gap={'0px'}>
              <Text
                fontSize={24}
                fontWeight={500}
                style={{
                  marginLeft: '10px',
                  marginTop: '-9px',
                  marginBottom: '9px',
                }}
              >
                {symbol0}
              </Text>
            </RowFixed>
          </RowBetween>
          <RowBetween
            align='flex-end'
            style={{
              width: 'calc(100% - 50px)',
              marginLeft: '50px',
              marginTop: '-17px',
              marginBottom: '17px',
            }}
          >
            <RowFixed gap={'0px'}>
              <Text
                fontSize={12}
                fontWeight={500}
                style={{
                  color: theme.text2,
                  marginTop: '-10px',
                  marginBottom: '-7px',
                }}
              >
                {formatAmount(fiatAmount0, 2, true, '$0.00', true)}
              </Text>
            </RowFixed>
            <RowFixed gap={'0px'}>
              <Text
                fontSize={12}
                fontWeight={500}
                style={{
                  color: theme.text2,
                  marginTop: '-10px',
                  marginBottom: '-7px',
                }}
              >
                {name0}
              </Text>
            </RowFixed>
          </RowBetween>
          <RowBetween align='flex-end' style={{ marginTop: '6px' }}>
            <RowFixed gap={'0px'}>
              <CurrencyLogo currency={amount1?.currency} size={'36px'} style={{ marginRight: '12px' }} />
              <TruncatedText style={{ marginTop: '-14px' }}>
                {addMinDecimals(amount1?.toSignificant(6), 2)}
              </TruncatedText>
            </RowFixed>
            <RowFixed gap={'0px'}>
              <Text
                fontSize={24}
                fontWeight={500}
                style={{
                  marginLeft: '10px',
                  marginTop: '-9px',
                  marginBottom: '9px',
                }}
              >
                {symbol1}
              </Text>
            </RowFixed>
          </RowBetween>
          <RowBetween
            align='flex-end'
            style={{
              width: 'calc(100% - 50px)',
              marginLeft: '50px',
              marginTop: '-17px',
              marginBottom: '17px',
            }}
          >
            <RowFixed gap={'0px'}>
              <Text
                fontSize={14}
                fontWeight={500}
                style={{
                  color: theme.text2,
                  marginTop: '-10px',
                  marginBottom: '-7px',
                }}
              >
                {formatAmount(fiatAmount1, 2, true, '$0.00', true)}
              </Text>
            </RowFixed>
            <RowFixed gap={'0px'}>
              <Text
                fontSize={14}
                fontWeight={500}
                style={{
                  color: theme.text2,
                  marginTop: '-10px',
                  marginBottom: '-7px',
                }}
              >
                {name1}
              </Text>
            </RowFixed>
          </RowBetween>
        </>
      </AutoColumn>
    </TopWrapper>
  )
}

function PoolModalFooter({
  pair,
  amount0,
  amount1,
  fiatAmount,
  deposit,
  unlock,
  allowedSlippage,
  onConfirm,
}: {
  pair: Pair | undefined
  amount0: CurrencyAmount<Currency> | undefined
  amount1: CurrencyAmount<Currency> | undefined
  fiatAmount: Fraction | undefined
  deposit: boolean
  unlock: boolean
  allowedSlippage: number
  onConfirm: () => void
}) {
  const theme = useContext(ThemeContext)
  const [toggled, setToggled] = useState(false)
  const symbol0 = useMemo(() => cleanCurrencySymbol(pair?.token0), [pair])
  const symbol1 = useMemo(() => cleanCurrencySymbol(pair?.token1), [pair])
  const marketPrice = useMemo(() => {
    if (!pair) return undefined
    return toggled
      ? pair.reserve0.asFraction.divide(pair.reserve1.asFraction)
      : pair.reserve1.asFraction.divide(pair.reserve0.asFraction)
  }, [pair, toggled])

  return (
    <>
      {!unlock && (
        <AutoColumn gap='0px'>
          <RowBetween>
            <RowFixed>
              <TYPE.black color={theme.text2} fontSize={13} fontWeight={400}>
                Market Price
              </TYPE.black>
              <QuestionHelper text='The market price of the assets in the pool. Click or tap to toggle.' />
            </RowFixed>
            <MarketPrice onClick={() => setToggled(!toggled)}>
              {`${formatAmount(marketPrice)} ${toggled ? symbol0 : symbol1} per ${toggled ? symbol1 : symbol0}`}
            </MarketPrice>
          </RowBetween>
          {/*<RowBetween>
           <RowFixed>
           <TYPE.black color={theme.text2} fontSize={13} fontWeight={400}>
           Allowed slippage
           </TYPE.black>
           <QuestionHelper text='add slippage description' />
           </RowFixed>
           <TYPE.black fontSize={13}>{allowedSlippage}</TYPE.black>
           </RowBetween>*/}
          <RowBetween>
            <RowFixed>
              <TYPE.black color={theme.text2} fontSize={13} fontWeight={400}>
                Total Amount
              </TYPE.black>
              <QuestionHelper text='Total fiat value of all assets in the transaction.' />
            </RowFixed>
            <TYPE.black fontSize={13}>{formatAmount(fiatAmount, 2, true, '$0.00', true)}</TYPE.black>
          </RowBetween>
        </AutoColumn>
      )}
      <AutoRow>
        <SubmitButton onClick={onConfirm} style={{ margin: '10px 0 0 0' }}>
          <Text fontSize={20} fontWeight={500}>
            {`Confirm ${unlock ? 'Unlock' : deposit ? 'Deposit' : 'Withdrawal'}`}
          </Text>
        </SubmitButton>
      </AutoRow>
    </>
  )
}

export default function ConfirmPoolModal({
  pair,
  amount0,
  amount1,
  fiatAmount0,
  fiatAmount1,
  deposit,
  unlock,
  allowedSlippage,
  onConfirm,
  onDismiss,
  errorMessage,
  isOpen,
  attemptingTxn,
  txHash,
}: {
  pair: Pair | undefined
  isOpen: boolean
  amount0: CurrencyAmount<Currency> | undefined
  amount1: CurrencyAmount<Currency> | undefined
  fiatAmount0: Fraction | undefined
  fiatAmount1: Fraction | undefined
  attemptingTxn: boolean
  txHash: string | undefined
  deposit: boolean
  unlock: boolean
  allowedSlippage: number
  onConfirm: () => void
  errorMessage: string | undefined
  onDismiss: () => void
}) {
  const pendingText = useMemo(() => {
    if (!amount0 || !amount1) {
      return ''
    }
    const symbol0 = cleanCurrencySymbol(amount0.currency)
    const symbol1 = cleanCurrencySymbol(amount1.currency)
    if (unlock) return `Unlocking ${symbol0} and ${symbol1}`
    return `${deposit ? 'Depositing' : 'Withdrawing'} ${amount0.toSignificant(
      6,
      FORMATTER,
    )} ${symbol0} and ${amount1.toSignificant(6, FORMATTER)} ${symbol1} ${deposit ? 'to' : 'from'} Pool`
  }, [amount0, amount1, unlock, deposit])
  const modalHeader = useCallback(() => {
    return amount0 && amount1 ? (
      <PoolModalHeader
        amount0={amount0}
        amount1={amount1}
        fiatAmount0={fiatAmount0}
        fiatAmount1={fiatAmount1}
        deposit={deposit}
        unlock={unlock}
        allowedSlippage={allowedSlippage}
      />
    ) : null
  }, [amount0, amount1, allowedSlippage, deposit, unlock, fiatAmount0, fiatAmount1])

  const modalBottom = useCallback(() => {
    return amount0 && amount1 ? (
      <PoolModalFooter
        pair={pair}
        amount0={amount0}
        amount1={amount1}
        fiatAmount={fiatAmount0?.add(fiatAmount1 ?? '0')}
        deposit={deposit}
        unlock={unlock}
        allowedSlippage={allowedSlippage}
        onConfirm={onConfirm}
      />
    ) : null
  }, [pair, amount0, amount1, deposit, unlock, onConfirm, allowedSlippage, fiatAmount0, fiatAmount1])

  const symbol0 = useMemo(() => cleanCurrencySymbol(amount0?.currency), [amount0])
  const symbol1 = useMemo(() => cleanCurrencySymbol(amount1?.currency), [amount1])
  const confirmationContent = useCallback(
    () =>
      errorMessage ? (
        <TransactionErrorContent onDismiss={onDismiss} message={errorMessage} />
      ) : (
        <ConfirmationModalContent
          title={`${unlock ? 'Unlock ' : deposit ? 'Deposit to ' : 'Withdraw from '}${symbol0}-${symbol1} Pool`}
          onDismiss={onDismiss}
          topContent={modalHeader}
          bottomContent={modalBottom}
        />
      ),
    [errorMessage, onDismiss, unlock, deposit, symbol0, symbol1, modalHeader, modalBottom],
  )

  return (
    <TransactionConfirmationModal
      isOpen={isOpen}
      memoizedOnDismiss={onDismiss}
      attemptingTxn={attemptingTxn}
      hash={txHash}
      memoizedContentCreator={confirmationContent}
      pendingText={pendingText}
      currencyToAdd={amount0?.currency}
    />
  )
}
