import moment from 'moment'

/**
 * Given a millisecond timestamp, return it in a string form that has an appropriate date/time description based on how recent it was
 * @param timestamp to return formatted ddatestring of
 */
export default function toTime(timestamp: number | Date): string {
  const jsDate = timestamp instanceof Date ? timestamp : new Date(timestamp)
  const date = moment.utc(jsDate)

  if (Date.now() < jsDate.getTime()) {
    return moment(date)
      .fromNow()
      .replace('in ', '')
  }

  const today = moment().startOf('day')
  const isToday = date.isSame(today, 'd')
  const isThisYear = date.isSame(today, 'y')

  if (date.isAfter(moment().subtract(1.5, 'hours'))) {
    if (date.isAfter(moment().subtract(1, 'hours'))) {
      return moment(date)
        .fromNow()
        .replace('minutes', 'min')
    } else return '1 hour ago'
  }
  if (isThisYear) {
    const format = isToday ? 'h:mm a' : 'MMM D'
    return date.format(format)
  } else {
    return date.format('MMM D YYYY')
  }
}
