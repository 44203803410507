import { useCallback, useEffect, useState } from 'react'

export default function useApprovedToS(): [boolean, () => void] {
  const [hasApprovedToS, setHasApprovedToS] = useState(true)

  useEffect(() => {
    const localStorageContentsToS = window.localStorage.getItem('dolomite_has_approved_tos')
    if (!localStorageContentsToS || localStorageContentsToS !== 'true') {
      setHasApprovedToS(false)
    }
  }, [])

  const setApprovedToS = useCallback(() => {
    window.localStorage.setItem('dolomite_has_approved_tos', 'true')
    setHasApprovedToS(true)
  }, [])

  return [hasApprovedToS, setApprovedToS]
}
