import { CurrencyAmount, Token } from '@dolomite-exchange/v2-sdk'
import { Web3CallbackState } from '../useTradeCallback'
import { estimateGasAsync, SuccessfulContractCall, useActiveWeb3React } from '../index'
import useIsolationModeUserVaultAddressIfCreated from '../useIsolationModeUserVaultAddressIfCreated'
import { useDjUSDCUserVaultContract } from '../useContract'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { useMemo } from 'react'
import { USER_ERROR_CODES, ZERO_FRACTION } from '../../constants'
import { calculateGasMargin } from '../../utils'
import { useDjUSDCToken } from './useJonesProtocol'

enum StakeOrUnstakeJUsdcMethodName {
  STAKE_JUSDC = 'stake',
  UNSTAKE_JUSDC = 'unstake',
}

export function useStakeJUsdc(
  parsedInputAmount: CurrencyAmount<Token> | undefined,
): { state: Web3CallbackState; callback: null | (() => Promise<string>); error: string | null } {
  return useStakeOrUnstakeJUsdc(parsedInputAmount, StakeOrUnstakeJUsdcMethodName.STAKE_JUSDC)
}

export function useUnstakeJUsdc(
  parsedInputAmount: CurrencyAmount<Token> | undefined,
): { state: Web3CallbackState; callback: null | (() => Promise<string>); error: string | null } {
  return useStakeOrUnstakeJUsdc(parsedInputAmount, StakeOrUnstakeJUsdcMethodName.UNSTAKE_JUSDC)
}

export function useHarvestJUsdcRewards(): {
  state: Web3CallbackState
  callback: null | (() => Promise<string>)
  error: string | null
} {
  const { account } = useActiveWeb3React()
  const djUsdc = useDjUSDCToken()
  const vaultAddress = useIsolationModeUserVaultAddressIfCreated(djUsdc)
  const vaultContract = useDjUSDCUserVaultContract(vaultAddress)
  const addTransaction = useTransactionAdder()

  return useMemo(() => {
    if (!vaultContract || !djUsdc || !account) {
      return {
        state: Web3CallbackState.INVALID,
        callback: null,
        error: 'Missing dependencies',
      }
    }

    const contract = vaultContract
    const methodName = 'harvestRewards'
    const params: string[] = []

    return {
      state: Web3CallbackState.VALID,
      callback: async function onCallFunctions(): Promise<string> {
        const estimatedCall = await estimateGasAsync(contract, methodName, params)
        let successfulCall: SuccessfulContractCall
        if ('gasEstimate' in estimatedCall) {
          successfulCall = estimatedCall
        } else {
          throw estimatedCall.error
        }

        return contract[methodName](...params, {
          gasLimit: calculateGasMargin(successfulCall.gasEstimate),
          from: account,
        })
          .then((response: any) => {
            const summary = 'Harvest ARB rewards'
            addTransaction(response, { summary })
            return response.hash
          })
          .catch((error: any) => {
            // if the user rejected the tx, pass this along
            if (error?.code === USER_ERROR_CODES.REJECTED) {
              throw new Error('transaction-rejected')
            } else {
              // otherwise, the error was unexpected, and we need to convey that
              console.error('Call function failed:', error, methodName, params)
              throw new Error(`Call function failed: ${error.message}`)
            }
          })
      },
      error: null,
    }
  }, [vaultContract, djUsdc, account, addTransaction])
}

function useStakeOrUnstakeJUsdc(
  parsedInputAmount: CurrencyAmount<Token> | undefined,
  methodName: StakeOrUnstakeJUsdcMethodName,
): { state: Web3CallbackState; callback: null | (() => Promise<string>); error: string | null } {
  const { account } = useActiveWeb3React()
  const djUsdc = useDjUSDCToken()
  const vaultAddress = useIsolationModeUserVaultAddressIfCreated(djUsdc)
  const vaultContract = useDjUSDCUserVaultContract(vaultAddress)
  const addTransaction = useTransactionAdder()

  return useMemo(() => {
    if (!vaultContract || !djUsdc || !account || !parsedInputAmount || parsedInputAmount.equalTo(ZERO_FRACTION)) {
      return {
        state: Web3CallbackState.INVALID,
        callback: null,
        error: 'Missing dependencies',
      }
    }

    const contract = vaultContract
    const params = [parsedInputAmount.quotient.toString()]

    return {
      state: Web3CallbackState.VALID,
      callback: async function onCallFunctions(): Promise<string> {
        const estimatedCall = await estimateGasAsync(contract, methodName, params)
        let successfulCall: SuccessfulContractCall
        if ('gasEstimate' in estimatedCall) {
          successfulCall = estimatedCall
        } else {
          throw estimatedCall.error
        }

        return contract[methodName](...params, {
          gasLimit: calculateGasMargin(successfulCall.gasEstimate),
          from: account,
        })
          .then((response: any) => {
            let summary = ''
            if (methodName === StakeOrUnstakeJUsdcMethodName.STAKE_JUSDC) {
              summary = 'Stake jUSDC'
            } else if (methodName === StakeOrUnstakeJUsdcMethodName.UNSTAKE_JUSDC) {
              summary = 'Unstake jUSDC'
            }
            addTransaction(response, { summary })
            return response.hash
          })
          .catch((error: any) => {
            // if the user rejected the tx, pass this along
            if (error?.code === USER_ERROR_CODES.REJECTED) {
              throw new Error('transaction-rejected')
            } else {
              // otherwise, the error was unexpected, and we need to convey that
              console.error('Call function failed:', error, methodName, params)
              throw new Error(`Call function failed: ${error.message}`)
            }
          })
      },
      error: null,
    }
  }, [vaultContract, djUsdc, account, parsedInputAmount, methodName, addTransaction])
}
