import { createAction } from '@reduxjs/toolkit'
import { ChainId } from '../../constants'

export const updateBlockNumber = createAction<{
  chainId: ChainId
  blockNumber: number
}>('chain/updateBlockNumber')

export const updateBlockTimestamp = createAction<{ chainId: ChainId; blockTimestamp: number }>(
  'chain/updateBlockTimestamp',
)

export const updateSubgraphBlockNumber = createAction<{ chainId: ChainId; subgraphBlockNumber: number }>(
  'chain/updateSubgraphBlockNumber',
)

export const updateIsSettingChainId = createAction<{ isSettingChainId: boolean }>('chain/updateIsSettingChainId')

export const updateChainId = createAction<{ chainId: ChainId }>('chain/updateChainId')
