import { gql } from '@apollo/client'
import { AmmMint, createCurrencyAmount, createFraction, createFractionUSD, createTransaction } from './gqlTypeHelpers'
import { ammMintGql } from './queryObjects'
import { useAllTokens } from '../hooks/Tokens'
import { useMemo } from 'react'
import { AmmMintOrBurn } from './apiTypeHelpers'
import { toChecksumAddress } from '../utils/toChecksumAddress'
import { Pair } from '@dolomite-exchange/v2-sdk'
import { useGraphqlResult } from '../state/graphql/hooks'
import { GraphqlClientType } from '../state/graphql/actions'
import { RefreshFrequency } from '../state/chain/hooks'

const MINTS_BY_WALLET_GQL = gql`
    query mintsByWallet($blockNumber: Int!, $walletAddress: String!, $skip: Int!) {
        ammMints(
            block: { number_gte: $blockNumber },
            where: { to: $walletAddress },
            orderBy: serialId,
            orderDirection: desc,
            first: 100,
            skip: $skip,
        ) {
            ${ammMintGql()}
        }
    }
`

const MINTS_BY_PAIR_GQL = gql`
    query mintsByWallet($blockNumber: Int!, $pairAddress: String!, $skip: Int!) {
        ammMints(
            block: { number_gte: $blockNumber },
            where: { pair: $pairAddress },
            orderBy: serialId,
            orderDirection: desc,
            first: 100,
            skip: $skip,
        ) {
            ${ammMintGql()}
        }
    }
`

const MINTS_BY_BOTH_GQL = gql`
    query mintsByBoth($blockNumber: Int!, $pairAddress: String!, $walletAddress: String!) {
        ammMints(
            block: { number_gte: $blockNumber },
            where: { pair: $pairAddress, to: $walletAddress },
            orderBy: serialId,
            orderDirection: desc,
            first: 100,
        ) {
            ${ammMintGql()}
        }
    }
`

interface AmmMintResponse {
  ammMints: AmmMint[]
}

/**
 * @param walletAddress The user's wallet address
 * @param pageIndex 0-index page of data to load
 */
export function useAmmMintDataByWallet(
  walletAddress: string | undefined,
  pageIndex: number,
): {
  loading: boolean
  error: boolean
  data: AmmMintOrBurn[]
} {
  const variables = useMemo(() => {
    if (!walletAddress) {
      return undefined
    }

    return {
      walletAddress: walletAddress.toLowerCase(),
      skip: pageIndex * 100,
    }
  }, [pageIndex, walletAddress])

  const queryState = useGraphqlResult<AmmMintResponse>(
    GraphqlClientType.Dolomite,
    MINTS_BY_WALLET_GQL.loc!.source.body,
    variables,
    RefreshFrequency.Medium,
  )
  const tokenMap = useAllTokens()

  const anyLoading = Boolean(queryState.loading)
  const anyError = Boolean(queryState.error)

  return useMemo(() => {
    const mints = (queryState.result?.ammMints ?? [])
      .map<AmmMintOrBurn | undefined>(mint => {
        const token0 = tokenMap[toChecksumAddress(mint.pair.token0.id)]
        const token1 = tokenMap[toChecksumAddress(mint.pair.token1.id)]
        if (!token0 || !token1) {
          return undefined
        }

        return {
          isMint: true,
          transaction: createTransaction(mint.transaction),
          logIndex: Number.parseInt(mint.logIndex),
          account: mint.to,
          token0: token0,
          token1: token1,
          amount0: createCurrencyAmount(token0, mint.amount0),
          amount1: createCurrencyAmount(token1, mint.amount1),
          liquidity: createFraction(mint.liquidity),
          pairAddress: toChecksumAddress(mint.pair.id),
          amountUSD: createFractionUSD(mint.amountUSD),
        }
      })
      .filter(burn => !!burn)
      .map<AmmMintOrBurn>(burn => burn!)

    return {
      loading: anyLoading,
      error: anyError,
      data: mints,
    }
  }, [anyError, anyLoading, queryState, tokenMap])
}

export function useAmmMintDataByPair(
  pair: Pair | undefined,
  pageIndex: number,
): {
  loading: boolean
  error: boolean
  data: AmmMintOrBurn[]
} {
  const variables = useMemo(() => {
    if (!pair) {
      return undefined
    }
    return {
      pairAddress: pair.liquidityToken.address.toLowerCase(),
      skip: pageIndex * 100,
    }
  }, [pageIndex, pair])
  const queryState = useGraphqlResult<AmmMintResponse>(
    GraphqlClientType.Dolomite,
    MINTS_BY_PAIR_GQL.loc!.source.body,
    variables,
    RefreshFrequency.Medium,
  )

  return useMemo(() => {
    const anyLoading = Boolean(queryState.loading)
    const anyError = Boolean(queryState.error)
    const mints = (queryState.result?.ammMints ?? [])
      .map<AmmMintOrBurn | undefined>(mint => {
        if (!pair) {
          return undefined
        }

        return {
          isMint: true,
          transaction: createTransaction(mint.transaction),
          logIndex: Number.parseInt(mint.logIndex),
          account: mint.to,
          token0: pair.token0,
          token1: pair.token1,
          amount0: createCurrencyAmount(pair.token0, mint.amount0),
          amount1: createCurrencyAmount(pair.token1, mint.amount1),
          liquidity: createFraction(mint.liquidity),
          pairAddress: toChecksumAddress(mint.pair.id),
          amountUSD: createFractionUSD(mint.amountUSD),
        }
      })
      .filter(mint => !!mint)
      .map(mint => mint!)

    return {
      loading: anyLoading,
      error: anyError,
      data: mints,
    }
  }, [queryState, pair])
}

export function useAmmMintDataByWalletPair(
  pair: Pair | undefined,
  walletAddress: string | undefined,
): {
  loading: boolean
  error: boolean
  data: AmmMintOrBurn[]
} {
  const variables = useMemo(() => {
    if (!walletAddress || !pair) {
      return undefined
    }
    return {
      pairAddress: pair.liquidityToken.address.toLowerCase(),
      walletAddress: walletAddress.toLowerCase(),
    }
  }, [pair, walletAddress])

  const queryState = useGraphqlResult<AmmMintResponse>(
    GraphqlClientType.Dolomite,
    MINTS_BY_BOTH_GQL.loc!.source.body,
    variables,
    RefreshFrequency.Fast,
  )

  return useMemo(() => {
    const anyLoading = Boolean(queryState.loading)
    const anyError = Boolean(queryState.error)
    const mints = (queryState.result?.ammMints ?? [])
      .map<AmmMintOrBurn | undefined>(mint => {
        if (!pair) {
          return undefined
        }

        return {
          isMint: true,
          transaction: createTransaction(mint.transaction),
          logIndex: Number.parseInt(mint.logIndex),
          account: mint.to,
          token0: pair.token0,
          token1: pair.token1,
          amount0: createCurrencyAmount(pair.token0, mint.amount0),
          amount1: createCurrencyAmount(pair.token1, mint.amount1),
          liquidity: createFraction(mint.liquidity),
          pairAddress: toChecksumAddress(mint.pair.id),
          amountUSD: createFractionUSD(mint.amountUSD),
        }
      })
      .filter(mint => !!mint)
      .map(mint => mint!)

    return {
      loading: anyLoading,
      error: anyError,
      data: mints,
    }
  }, [queryState, pair])
}
