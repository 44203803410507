import { Fraction } from '@dolomite-exchange/sdk-core'
import { useActiveWeb3React } from '../index'
import { usePendleYtToken } from '../useContract'
import { useDytGLPSpecialAsset } from '../../constants/isolation/special-assets'
import { useMemo } from 'react'
import { useSingleCallResult } from '../../state/multicall/hooks'

export function useYtGlpPendingRewards(): [Fraction | undefined, boolean] {
  const { account, chainId } = useActiveWeb3React()
  const dytGlpToken = useDytGLPSpecialAsset()
  const ytGlpProtocol = usePendleYtToken(dytGlpToken.isolationModeInfo?.getUnderlyingToken(chainId)?.address)
  const proxyVault = dytGlpToken.isolationModeInfo?.remapAccountAddress?.(account, chainId)
  const inputs = useMemo(() => (proxyVault ? [proxyVault, 'true', 'true'] : undefined), [proxyVault])
  const callState = useSingleCallResult(ytGlpProtocol, 'redeemDueInterestAndRewards', inputs)

  return useMemo(() => {
    const pendingRewards = callState.result?.[1][0]
    if (!pendingRewards) {
      return [undefined, callState.loading]
    }

    return [new Fraction(pendingRewards, '1000000000000000000'), callState.loading]
  }, [callState.loading, callState.result])
}
