import { useMemo, useRef } from 'react'
import { BorrowPosition } from '../types/borrowPositionData'
import JSBI from 'jsbi'
import { useActiveWeb3React } from './index'
import STRATEGIES_DATA, {
  STRATEGY_ID_THRESHOLD,
  STRATEGY_INDEX_DEPRECATED_LOWER,
  STRATEGY_INDEX_DEPRECATED_UPPER,
  STRATEGY_INDEX_LOWER,
  STRATEGY_INDEX_UPPER,
  STRATEGY_POSITION_ID_THRESHOLD,
} from '../pages/Strategies/StrategiesList'
import { Transfer, useTransfersByMarginAccountArray } from '../types/transferData'
import { Trade, useTradeDataByMarginAccountArray } from '../types/tradeData'

export interface ActivePosition {
  position: BorrowPosition
  strategy: number
  transfers: Transfer[]
  unfilteredTransfers?: Transfer[]
  trades?: Trade[]
}

export interface ActivePositionWithTransfersAndTrades {
  position: BorrowPosition
  strategy: number
  transfers: Transfer[]
  unfilteredTransfers: Transfer[]
  trades: Trade[]
}

export const isActiveDeprecatedStrategy = (position: BorrowPosition, isAll: boolean) => {
  const positionId = JSBI.toNumber(position.marginAccount.accountNumber)
  return (
    positionId >= STRATEGY_INDEX_DEPRECATED_LOWER &&
    positionId <= STRATEGY_INDEX_DEPRECATED_UPPER &&
    (position.status === 'OPEN' || isAll) &&
    (position.supplyAmounts.length > 0 || isAll)
  )
}

export const isActiveStrategy = (position: BorrowPosition, isAll: boolean) => {
  const positionId = JSBI.toNumber(position.marginAccount.accountNumber)
  return (
    positionId >= STRATEGY_INDEX_LOWER &&
    positionId <= STRATEGY_INDEX_UPPER &&
    (position.status === 'OPEN' || isAll) &&
    (position.supplyAmounts.length > 0 || isAll)
  )
}

// returns true if they match
const filteredPositionsComparator = (
  prevFilteredPositions: BorrowPosition[],
  nextFilteredPositions: BorrowPosition[],
  prevTransfersLength: number,
  nextTransfersLength: number,
  prevTradesLength: number,
  nextTradesLength?: number,
  prevTotalSuppliedAndBorrowed?: number,
  nextTotalSuppliedAndBorrowed?: number,
): boolean => {
  if (prevFilteredPositions.length !== nextFilteredPositions.length) {
    return false
  }
  if (prevTransfersLength !== nextTransfersLength) {
    return false
  }
  if (nextTradesLength && prevTradesLength !== nextTradesLength) {
    return false
  }
  if (prevTotalSuppliedAndBorrowed !== nextTotalSuppliedAndBorrowed) {
    return false
  }
  return true
}

const parseStrategyAndPositionId = (position: BorrowPosition) => {
  const fullPositionId = JSBI.toNumber(position.marginAccount.accountNumber)
  const positionId = fullPositionId % STRATEGY_POSITION_ID_THRESHOLD
  const remainingValue = (fullPositionId - positionId) / STRATEGY_POSITION_ID_THRESHOLD
  const strategyId = remainingValue - STRATEGY_ID_THRESHOLD
  return {
    strategyId,
    positionId,
  }
}

const useParseActiveStrategies = (
  filteredPositions: BorrowPosition[],
  allTransfers: Record<string, { transfersIn: Transfer[]; transfersOut: Transfer[] }>,
  allTrades?: Record<string, Trade[]>,
) => {
  const filteredPositionsRef = useRef<BorrowPosition[]>([])
  const transfersLengthRef = useRef<number>(0)
  const tradesLengthRef = useRef<number>(0)
  const activePositionsRef = useRef<ActivePosition[]>([])
  const modifiedPositionsRef = useRef<string[]>([])
  const totalSuppliedAndBorrowedRef = useRef<number>(0)
  const totalTransfersCount = Object.values(allTransfers).reduce((acc, record) => {
    return acc + record.transfersIn.length + record.transfersOut.length
  }, 0)
  const totalSuppliedAndBorrowed = filteredPositions.reduce(
    // Done to separate out the two numbers, so a simultaneous increase in one and decrease in the other will still trigger a recalculation
    (acc, position) => position.supplyAmounts.length * 100 + position.borrowAmounts.length + acc,
    0,
  )
  const totalTradeCount = allTrades
    ? Object.values(allTrades).reduce((acc, record) => {
        return acc + record.length
      }, 0)
    : 0
  if (
    filteredPositionsComparator(
      filteredPositionsRef.current,
      filteredPositions,
      transfersLengthRef.current,
      totalTransfersCount,
      tradesLengthRef.current,
      totalTradeCount,
      totalSuppliedAndBorrowedRef.current,
      totalSuppliedAndBorrowed,
    )
  ) {
    return activePositionsRef.current
  }
  transfersLengthRef.current = totalTransfersCount
  totalSuppliedAndBorrowedRef.current = totalSuppliedAndBorrowed
  if (allTrades) tradesLengthRef.current = totalTradeCount
  /*if (!allTransfers || totalTransfersCount === 0) {
    return activePositionsRef.current
  }*/
  const calculatedFilteredPositions = filteredPositions
    ?.filter(position => !modifiedPositionsRef.current.includes(position.id))
    .reduce((filteredPositions: ActivePosition[], position: BorrowPosition) => {
      const combinedTransfers = allTransfers[position.marginAccount.toString()]?.transfersIn.concat(
        allTransfers[position.marginAccount.toString()].transfersOut,
      )
      const positionTransfers =
        combinedTransfers?.filter(transfer => transfer.toMarginAccount.toString() === position.id) ?? []
      const unfilteredPositionTransfers = allTransfers
        ? combinedTransfers?.filter(
            transfer =>
              position.id === transfer.toMarginAccount.toString() ||
              position.id === transfer.fromMarginAccount.toString(),
          )
        : undefined
      const positionStrategy = parseStrategyAndPositionId(position).strategyId
      const steps = STRATEGIES_DATA[positionStrategy - 1]?.steps
      if (allTrades) {
        if (positionTransfers.length > 3) {
          if (!(positionTransfers.length === 4 && steps && position.supplyAmounts.length === 2)) {
            modifiedPositionsRef.current.push(position.id)
            return filteredPositions
          }
        }
      } else {
        if (positionTransfers.length > 2) {
          if (!(positionTransfers.length === 3 && steps && position.supplyAmounts.length <= 2)) {
            modifiedPositionsRef.current.push(position.id)
            return filteredPositions
          }
        }
      }
      if (positionTransfers.length === 0) {
        return filteredPositions
      }
      const sortedTransfers = positionTransfers.sort((a, b) =>
        position.specialInfo.isolationModeVaultAddress
          ? JSBI.toNumber(a.serialId) - JSBI.toNumber(b.serialId)
          : JSBI.toNumber(b.serialId) - JSBI.toNumber(a.serialId),
      )
      const sortedUnfilteredTransfers = allTrades
        ? unfilteredPositionTransfers?.sort((a, b) =>
            position.specialInfo.isolationModeVaultAddress
              ? JSBI.toNumber(a.serialId) - JSBI.toNumber(b.serialId)
              : JSBI.toNumber(b.serialId) - JSBI.toNumber(a.serialId),
          )
        : undefined
      const positionTrades = allTrades ? allTrades[position.marginAccount.toString()] : undefined
      const filteredTrades = positionTrades?.filter(
        trade => trade.makerAccount && trade.makerAccount.toString() === position.id,
      )
      filteredPositions.push({
        position: position,
        strategy: positionStrategy,
        transfers: sortedTransfers,
        unfilteredTransfers: sortedUnfilteredTransfers,
        trades: filteredTrades,
      })
      return filteredPositions
    }, [])
  filteredPositionsRef.current = filteredPositions
  activePositionsRef.current = calculatedFilteredPositions
  return calculatedFilteredPositions
}

const useParseActiveStrategiesDeprecated = (
  filteredPositions: BorrowPosition[],
  allTransfers: Record<string, { transfersIn: Transfer[]; transfersOut: Transfer[] }>,
  allTrades?: Record<string, Trade[]>,
) => {
  const { chainId } = useActiveWeb3React()
  const filteredPositionsDeprecatedRef = useRef<BorrowPosition[]>([])
  const transfersLengthDeprecatedRef = useRef<number>(0)
  const tradesLengthDeprecatedRef = useRef<number>(0)
  const activePositionsDeprecatedRef = useRef<ActivePosition[]>([])
  const modifiedPositionsRef = useRef<string[]>([])
  const totalSuppliedAndBorrowedRef = useRef<number>(0)
  const totalTransfersCount = Object.values(allTransfers).reduce((acc, record) => {
    return acc + record.transfersIn.length + record.transfersOut.length
  }, 0)
  const totalSuppliedAndBorrowed = filteredPositions.reduce(
    (acc, position) => position.supplyAmounts.length * 100 + position.borrowAmounts.length + acc,
    0,
  )
  const totalTradeCount = allTrades
    ? Object.values(allTrades).reduce((acc, record) => {
        return acc + record.length
      }, 0)
    : 0
  if (
    filteredPositionsComparator(
      filteredPositionsDeprecatedRef.current,
      filteredPositions,
      transfersLengthDeprecatedRef.current,
      totalTransfersCount,
      tradesLengthDeprecatedRef.current,
      totalTradeCount,
      totalSuppliedAndBorrowedRef.current,
      totalSuppliedAndBorrowed,
    )
  ) {
    return activePositionsDeprecatedRef.current
  }
  transfersLengthDeprecatedRef.current = totalTransfersCount
  totalSuppliedAndBorrowedRef.current = totalSuppliedAndBorrowed
  if (allTrades) tradesLengthDeprecatedRef.current = totalTradeCount
  /*if (!allTransfers || totalTransfersCount === 0) {
    return activePositionsDeprecatedRef.current
  }*/
  const calculatedFilteredPositions = filteredPositions
    ?.filter(position => !modifiedPositionsRef.current.includes(position.id))
    .reduce((filteredPositions: ActivePosition[], position: BorrowPosition) => {
      const combinedTransfers = allTransfers[position.marginAccount.toString()]?.transfersIn.concat(
        allTransfers[position.marginAccount.toString()].transfersOut,
      )
      const positionTransfers =
        combinedTransfers?.filter(transfer => transfer.toMarginAccount.toString() === position.id) ?? []
      const unfilteredPositionTransfers = allTransfers
        ? combinedTransfers?.filter(
            transfer =>
              position.id === transfer.toMarginAccount.toString() ||
              position.id === transfer.fromMarginAccount.toString(),
          )
        : undefined
      const sortedTransfers = positionTransfers.sort((a, b) =>
        position.specialInfo.isolationModeVaultAddress
          ? JSBI.toNumber(a.serialId) - JSBI.toNumber(b.serialId)
          : JSBI.toNumber(b.serialId) - JSBI.toNumber(a.serialId),
      )
      const sortedUnfilteredTransfers = allTrades
        ? unfilteredPositionTransfers?.sort((a, b) =>
            position.specialInfo.isolationModeVaultAddress
              ? JSBI.toNumber(a.serialId) - JSBI.toNumber(b.serialId)
              : JSBI.toNumber(b.serialId) - JSBI.toNumber(a.serialId),
          )
        : undefined
      const positionTrades = allTrades ? allTrades[position.marginAccount.toString()] : undefined
      const filteredTrades = positionTrades?.filter(
        trade => trade.makerAccount && trade.makerAccount.toString() === position.id,
      )
      if (
        sortedTransfers.length < 2 ||
        (!allTrades && sortedTransfers[0].token.symbol !== sortedTransfers[1].token.symbol)
      ) {
        return filteredPositions
      }
      const initialDeposit = sortedTransfers[0]
      const loop = sortedTransfers[1]
      // Divides the initial deposit + looped amount by initial deposit to get a leverage ratio, then rounds to the
      // nearest whole number to determine which strategy it matches
      const calculatedLeverage = Math.round(
        parseFloat(
          initialDeposit.amount.asFraction
            .add(loop.amount.asFraction)
            .divide(initialDeposit.amount.asFraction)
            .toFixed(2),
        ),
      )
      const leverage = calculatedLeverage === 1 ? 2 : calculatedLeverage // used to be calculatedLeverage === 1 ? 4 and I have no idea why. Changing it to 2
      const matchedStrategy = STRATEGIES_DATA.find(
        strategy =>
          strategy.leverage === leverage &&
          strategy.chain === chainId &&
          (allTrades
            ? strategy.collateralAssets[0].address.toUpperCase() ===
                position.effectiveSupplyTokens[0]?.address.toUpperCase() &&
              strategy.debtAssets[0].address.toUpperCase() === position.effectiveBorrowTokens[0]?.address.toUpperCase()
            : strategy.collateralAssets[0].address === initialDeposit.token.address &&
              strategy.debtAssets[0].address === position.borrowAmounts[0]?.token.address),
      )
      const steps = matchedStrategy ? parseInt(matchedStrategy.id) - 1 : undefined
      if (allTrades) {
        if (positionTransfers.length > 3) {
          if (!(positionTransfers.length === 4 && steps && position.supplyAmounts.length === 2)) {
            modifiedPositionsRef.current.push(position.id)
            return filteredPositions
          }
        }
      } else {
        if (positionTransfers.length > 2) {
          if (!(positionTransfers.length === 3 && steps && position.supplyAmounts.length === 2)) {
            modifiedPositionsRef.current.push(position.id)
            return filteredPositions
          }
        }
      }
      if (matchedStrategy) {
        filteredPositions.push({
          position: position,
          strategy: parseInt(matchedStrategy.id),
          transfers: sortedTransfers,
          unfilteredTransfers: sortedUnfilteredTransfers,
          trades: filteredTrades,
        })
      }
      return filteredPositions
    }, [])
  filteredPositionsDeprecatedRef.current = filteredPositions
  activePositionsDeprecatedRef.current = calculatedFilteredPositions
  return calculatedFilteredPositions
}

export function useActiveStrategies(positions: BorrowPosition[]): ActivePosition[] {
  const filteredPositionsDeprecated = useMemo(
    () => positions?.filter(position => isActiveDeprecatedStrategy(position, false)),
    [positions],
  )
  const filteredPositions = useMemo(() => positions?.filter(position => isActiveStrategy(position, false)), [positions])
  const strategiesDeprecatedTransfers = useTransfersByMarginAccountArray(
    filteredPositionsDeprecated.map(position => position.marginAccount),
  )
  const strategiesTransfers = useTransfersByMarginAccountArray(
    filteredPositions.map(position => position.marginAccount),
  )
  const activeStrategies = useParseActiveStrategies(filteredPositions, strategiesTransfers.transfers)
  const activeStrategiesDeprecated = useParseActiveStrategiesDeprecated(
    filteredPositionsDeprecated,
    strategiesDeprecatedTransfers.transfers,
  )
  return activeStrategies.concat(activeStrategiesDeprecated)
}

export function useAllStrategiesWithTradesAndUnfilteredTransfers(
  positions: BorrowPosition[],
): ActivePositionWithTransfersAndTrades[] {
  const filteredPositionsDeprecated = useMemo(
    () => positions?.filter(position => isActiveDeprecatedStrategy(position, true)),
    [positions],
  )
  const filteredPositions = useMemo(() => positions?.filter(position => isActiveStrategy(position, true)), [positions])
  const marginAccountsDeprecated = filteredPositionsDeprecated.map(position => position.marginAccount)
  const marginAccounts = filteredPositions.map(position => position.marginAccount)
  const strategiesDeprecatedTransfers = useTransfersByMarginAccountArray(marginAccountsDeprecated)
  const strategiesTransfers = useTransfersByMarginAccountArray(marginAccounts)
  const { data: allTrades } = useTradeDataByMarginAccountArray(marginAccounts)
  const { data: allTradesDeprecated } = useTradeDataByMarginAccountArray(marginAccountsDeprecated)
  const activeStrategies = useParseActiveStrategies(filteredPositions, strategiesTransfers.transfers, allTrades)
  const activeStrategiesDeprecated = useParseActiveStrategiesDeprecated(
    filteredPositionsDeprecated,
    strategiesDeprecatedTransfers.transfers,
    allTradesDeprecated,
  )
  return activeStrategies.concat(activeStrategiesDeprecated) as ActivePositionWithTransfersAndTrades[]
}
