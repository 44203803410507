import { SpecialAsset, SpecialAssetSymbol } from '../special-assets'
import { ChainId, PLV_GLP_ADDRESSES, PLV_GLP_ISOLATION_MODE_ADDRESSES } from '../../index'
import { Token } from '@dolomite-exchange/v2-sdk'
import { calculatePlvGLPProxyVaultAddress } from '../../../utils/isolation/calculateProxyVaultAddress'
import { getFunctionalOutsideAprCallData, getOptionally } from '../special-assets-util-functions'

export const PLV_GLP_SPECIAL_ASSET: () => SpecialAsset = () => ({
  symbol: SpecialAssetSymbol.dplvGLP,
  safeSymbol: SpecialAssetSymbol.dplvGLP,
  cleanSymbol: 'plvGLP',
  cleanName: 'Plutus Vault GLP',
  hasExternalRewards: true,
  documentationUrl: 'https://docs.dolomite.io/integrations/plutusdao-plvglp',
  isIsolationMode: true,
  isBorrowingEnabled: false,
  chainIdToAddressMap: PLV_GLP_ISOLATION_MODE_ADDRESSES,
  isolationModeInfo: {
    getWrappedToken: (chainId: ChainId) =>
      getOptionally(
        PLV_GLP_ISOLATION_MODE_ADDRESSES[chainId],
        address => new Token(chainId, address, 18, SpecialAssetSymbol.dplvGLP, 'Dolomite Isolation: Plutus Vault GLP'),
      ),
    getUnderlyingToken: getPlutusVaultUnderlyingToken,
    getUnderlyingSpenderToken: getPlutusVaultUnderlyingToken,
    allowableCollateralTokens: () => [],
    allowableDebtTokens: () => [],
    remapAccountAddress: calculatePlvGLPProxyVaultAddress,
    isAsync: false,
  },
  rewardInfo: {
    getOutsideAprCallData: chainId => getFunctionalOutsideAprCallData(chainId, SpecialAssetSymbol.dplvGLP),
  },
})

function getPlutusVaultUnderlyingToken(chainId: ChainId): Token | undefined {
  return getOptionally(
    PLV_GLP_ADDRESSES[chainId],
    address => new Token(chainId, address, 18, 'plvGLP', 'Plutus Vault GLP'),
  )
}
