import { createReducer } from '@reduxjs/toolkit'
import {
  Field,
  replaceSwapState,
  selectCurrency,
  setLeverage,
  setRecipient,
  setSubmittedMarginPosition,
  SubmittedMarginPosition,
  switchCurrencies,
  typeInput,
} from './actions'

export interface TradeState {
  readonly independentField: Field
  readonly typedValue: string
  readonly [Field.INPUT]: {
    readonly currencyId: string | undefined
  }
  readonly [Field.OUTPUT]: {
    readonly currencyId: string | undefined
  }
  // the typed recipient address or ENS name, or null if Trade should go to sender
  readonly recipient: string | null
  readonly typedLeverage: string
  readonly submittedMarginPosition: SubmittedMarginPosition | null
}

const initialState: TradeState = {
  independentField: Field.INPUT,
  typedValue: '',
  [Field.INPUT]: {
    currencyId: '',
  },
  [Field.OUTPUT]: {
    currencyId: '',
  },
  recipient: null,
  typedLeverage: '1',
  submittedMarginPosition: null,
}

export default createReducer<TradeState>(initialState, builder =>
  builder
    .addCase(
      replaceSwapState,
      (
        state,
        {
          payload: {
            typedValue,
            recipient,
            typedLeverage,
            field,
            inputCurrencyId,
            outputCurrencyId,
            submittedMarginPosition,
          },
        },
      ) => {
        return {
          [Field.INPUT]: {
            currencyId: inputCurrencyId,
          },
          [Field.OUTPUT]: {
            currencyId: outputCurrencyId,
          },
          independentField: field,
          typedValue: typedValue,
          recipient,
          typedLeverage,
          submittedMarginPosition,
        }
      },
    )
    .addCase(selectCurrency, (state, { payload: { currencyId, field } }) => {
      const otherField = field === Field.INPUT ? Field.OUTPUT : Field.INPUT
      if (currencyId === state[otherField].currencyId) {
        // the case where we have to Trade the order
        return {
          ...state,
          independentField: state.independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT,
          [field]: { currencyId: currencyId },
          [otherField]: { currencyId: state[field].currencyId },
          typedValue: '',
        }
      } else {
        // the normal case
        return {
          ...state,
          [field]: { currencyId: currencyId },
          typedValue: '',
        }
      }
    })
    .addCase(switchCurrencies, state => {
      return {
        ...state,
        independentField: state.independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT,
        [Field.INPUT]: { currencyId: state[Field.OUTPUT].currencyId },
        [Field.OUTPUT]: { currencyId: state[Field.INPUT].currencyId },
        typedValue: state.typedLeverage === '1' ? state.typedValue : '',
      }
    })
    .addCase(typeInput, (state, { payload: { field, typedValue } }) => {
      return {
        ...state,
        independentField: field,
        typedValue: typedValue,
      }
    })
    .addCase(setRecipient, (state, { payload: { recipient } }) => {
      state.recipient = recipient
    })
    .addCase(setLeverage, (state, { payload: { typedLeverage } }) => {
      if (
        (typedLeverage === '1' && state.typedLeverage !== '1') ||
        (typedLeverage !== '1' && state.typedLeverage === '1')
      ) {
        state.typedValue = ''
      }
      state.typedLeverage = typedLeverage
    })
    .addCase(setSubmittedMarginPosition, (state, { payload: { submittedMarginPosition } }) => {
      state.submittedMarginPosition = submittedMarginPosition
    }),
)
