import { Token } from '@dolomite-exchange/sdk-core'
import { ChainId, ChainIdMap, initializeSingleValueChainIdMap } from '../chainId'

const decimals = 18
const symbol = 'MIM'
const name = 'Magic Internet Money'

function mim(chainId: ChainId, address: string) {
  return new Token(chainId, address, decimals, symbol, name)
}

export const MIM: ChainIdMap<Token | undefined> = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  mim(ChainId.ARBITRUM_ONE, '0xFEa7a6a0B346362BF88A9e4A88416B77a57D6c2A'),
)
