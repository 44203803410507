import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import { StyledTooltip, StyledTooltipWithIcon } from '../../../common/StyledTooltip'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useActiveWeb3React } from '../../../../hooks'
import useIsolationModeUserVaultAddressIfCreated from '../../../../hooks/useIsolationModeUserVaultAddressIfCreated'
import { useCreateVaultIsolationModeVault } from '../../../../hooks/useDolomiteIsolationModeProtoocol'
import { useCurrentArbDelegate, useDArbToken, useDelegateArb } from '../../../../hooks/arb/useArbDolomiteProxyProtocol'
import { useIsTransactionPending } from '../../../../state/transactions/hooks'
import { isAddress } from '@ethersproject/address'
import { useTokenBalance } from '../../../../state/wallet/hooks'
import useArbProxyVaultAccount from '../../../../hooks/arb/useArbProxyVaultAccount'
import { useArbToken } from '../../../../hooks/Tokens'
import { formatAmount } from '../../../../utils/formatAmount'
import useENS from '../../../../hooks/useENS'
import useENSNameList from '../../../../hooks/useENSNameList'
import { ZERO_ADDRESS } from '../../../../constants'
import cleanCurrencyName from '../../../../utils/cleanCurrencyName'
import useDebounce from '../../../../hooks/useDebounce'

export const DelegateWrapper = styled.div`
  padding: 7px 35px 30px;
  width: 100%;
  overflow: auto;
`

export const DelegateBody = styled.div`
  font-size: 13px;
  font-weight: 500;
`

const ArbitrumInfo = styled.div`
  width: 100%;
  font-size: 12px;
`

const DataSection = styled.div`
  width: 100%;
  margin-bottom: 8px;
  margin-top: 15px;
`

const DataRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  line-height: 20px;
  padding-top: 4px;
`

const DataName = styled.div`
  font-weight: 300;

  svg {
    margin-bottom: -3px !important;
    margin-left: -1px !important;
  }
`

const DataValue = styled.div`
  font-weight: 600;
`

const SelfDelegating = styled.div`
  display: inline-block;
  vertical-align: top;
  margin-left: 3px;
  font-weight: 400;
  color: ${({ theme }) => theme.text3};
`

const InputWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  font-size: 15px;
  padding: 0.5rem 0.75rem;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  background-color: ${({ theme }) => theme.bg6};
  margin-top: -10px;
`

const StyledInput = styled.input<{ error?: boolean; fontSize?: string; align?: string }>`
  color: ${({ error, theme }) => (error ? theme.red1 : theme.text1)};
  width: 0;
  position: relative;
  font-weight: 500;
  outline: none;
  border: none;
  flex: 1 1 auto;
  background-color: ${({ theme }) => theme.bg6};
  font-size: inherit;
  text-align: ${({ align }) => align && align};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0;
  -webkit-appearance: textfield;

  ::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  [type='number'] {
    -moz-appearance: textfield;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  ::placeholder {
    color: ${({ theme }) => theme.text3};
  }

  :invalid {
    box-shadow: none;
  }
`

const QuickSelectWrapper = styled.div`
  width: 100%;
  margin-top: 0;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.bg2};
  padding: 0 8px;
`

const QuickSelectRow = styled.div`
  width: calc(100% + 16px);
  margin-left: -8px;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => theme.text2};
  cursor: pointer;

  :hover {
    color: ${({ theme }) => theme.text1};
    background-color: ${({ theme }) => theme.bg3};
  }
`

const QuickSelectName = styled.div`
  font-weight: 500;
  font-size: 12px;
  color: inherit;
`

const QuickSelectValue = styled.div`
  font-weight: 500;
  font-size: 12px;
  color: inherit;
`

const SubmitButton = styled.div<{ disabled?: boolean; blue?: boolean }>`
  width: 100%;
  padding: 12px 16px;
  border-radius: 5px;
  background-color: ${({ theme, blue }) => (blue ? theme.blue2 : theme.green2)};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.text1};
  opacity: ${({ disabled }) => (disabled ? 0.7 : 1)};
  text-align: center;
    /*${({ disabled }) => disabled && 'pointer-events: none;'}*/
  margin-top: 12px;

  :hover {
    background-color: ${({ theme, disabled, blue }) => (blue ? !disabled && theme.blue2 : !disabled && theme.green1)};
  }

  > div {
    width: 20px !important;
    height: 20px !important;
    font-size: 12px;
    margin-top: -1px;
    margin-bottom: -5px;
  }

  svg {
    color: ${({ theme }) => theme.text1};
  }
`

const CircularProgressWrapper = styled.div`
  > div {
    width: 20px !important;
    height: 20px !important;
    font-size: 12px;
    margin-top: -1px;
    margin-bottom: -5px;
  }

  svg {
    color: ${({ theme }) => theme.text1};
  }
`

interface Delegate {
  name: string
  address: string
}

const DELEGATE_LIST: Delegate[] = [
  {
    name: 'Dolomite',
    address: '0x52256ef863a713Ef349ae6E97A7E8f35785145dE',
  },
]

const truncateAddress = (address: string) => {
  return `${address.slice(0, 6)}...${address.slice(-4)}`
}

export default function DelegateVotes({ onDismiss }: { onDismiss: () => void }) {
  const { account } = useActiveWeb3React()
  const [inputValue, setInputValue] = useState<string>('')
  const [submitting, setSubmitting] = useState(false)
  const [awaitingSignature, setAwaitingSignature] = useState(false)
  const [pendingTx, setPendingTx] = useState<string | undefined>(undefined)
  const currentDelegate = useCurrentArbDelegate()
  const isTransactionPending = useIsTransactionPending(pendingTx)
  useEffect(() => {
    if (!isTransactionPending) {
      setPendingTx(undefined)
    }
    setSubmitting(isTransactionPending)
  }, [isTransactionPending])

  const { loading: isEnsLoading, address: ensAddress } = useENS(inputValue)
  const debouncedIsEnsLoading = useDebounce(isEnsLoading, 100)
  const isValidAddress = isAddress(ensAddress ?? '') && ensAddress !== ZERO_ADDRESS

  const dArbToken = useDArbToken()
  const arbToken = useArbToken()
  const vaultAddress = useIsolationModeUserVaultAddressIfCreated(dArbToken)

  const isButtonActive = isValidAddress && !submitting && !awaitingSignature && !isTransactionPending
  const isSelfDelegating = currentDelegate && currentDelegate === account

  const handleInput = useCallback(event => {
    setInputValue(event.target.value)
  }, [])

  const setAddress = useCallback((address: string) => {
    setInputValue(address)
  }, [])

  const { callback: delegateVotesCallback } = useDelegateArb(isValidAddress ? ensAddress ?? undefined : undefined)
  const { callback: createVaultCallback } = useCreateVaultIsolationModeVault(dArbToken)
  const vArbBalance = useTokenBalance(useArbProxyVaultAccount(), arbToken)
  const { name: ensName } = useENS(currentDelegate)

  const [delegateList, delegateAddresses] = useMemo(() => {
    const delegates = DELEGATE_LIST.concat(
      !account
        ? []
        : {
            name: 'Your Wallet',
            address: account,
          },
    )
    return [delegates, delegates.map(d => d.address)]
  }, [account])

  const delegateNames = useENSNameList(delegateAddresses)

  const submitTransaction = useCallback(() => {
    if (!createVaultCallback) {
      return
    }

    if (!delegateVotesCallback) {
      setSubmitting(true)
      setAwaitingSignature(true)
      createVaultCallback()
        .then(hash => {
          setPendingTx(hash)
          setAwaitingSignature(false)
        })
        .catch(() => {
          setSubmitting(false)
          setAwaitingSignature(false)
        })
      return
    }

    setSubmitting(true)
    setAwaitingSignature(true)

    delegateVotesCallback()
      .then(hash => {
        setAwaitingSignature(false)
        setPendingTx(hash)
        onDismiss()
      })
      .catch(() => {
        setAwaitingSignature(false)
        setSubmitting(false)
      })
  }, [onDismiss, createVaultCallback, delegateVotesCallback])

  return (
    <DelegateWrapper>
      <h2>Delegate Votes</h2>
      <DelegateBody>
        <ArbitrumInfo>
          <p>
            Delegating allows you to utilize your ARB for voting in Arbitrum DAO governance votes. You can choose to
            delegate your votes to yourself or to another address.
          </p>
          <p>
            On Dolomite, only {cleanCurrencyName(dArbToken)} (vARB) is eligible for delegation. You can freely convert
            between ARB and vARB using the convert button on the vARB asset row on the Balances page. You can also
            convert between ARB and vARB using Zap on the Borrow page.
          </p>
        </ArbitrumInfo>
        <DataSection>
          <DataRow>
            <DataName>
              {cleanCurrencyName(dArbToken)} Balance{' '}
              <StyledTooltipWithIcon
                tooltipText={
                  'vARB from across Dolomite qualifies for voting, whether it is in a borrow position or in your Dolomite Balance'
                }
              />
            </DataName>
            <DataValue>{formatAmount(vArbBalance)}</DataValue>
          </DataRow>
          <DataRow>
            <DataName>Currently Delegating To</DataName>
            <DataValue>
              <StyledTooltip title={currentDelegate ?? ''} placement={'top'}>
                <span>
                  {ensName ? ensName : currentDelegate ? truncateAddress(currentDelegate) : '-'}
                  {isSelfDelegating && <SelfDelegating>(self)</SelfDelegating>}
                </span>
              </StyledTooltip>
            </DataValue>
          </DataRow>
        </DataSection>
        <h3>Change Delegate</h3>
        <InputWrapper>
          <StyledInput
            value={inputValue}
            onChange={val => {
              handleInput(val)
            }}
            type={'text'}
            autoComplete={'off'}
            autoCorrect={'off'}
            autoCapitalize={'off'}
            spellCheck={'false'}
            placeholder={'0x or ENS'}
          />
          {debouncedIsEnsLoading && (
            <CircularProgressWrapper>
              <CircularProgress />
            </CircularProgressWrapper>
          )}
        </InputWrapper>
        <QuickSelectWrapper>
          {delegateList.map((delegate, i) => {
            return (
              <QuickSelectRow key={`${delegate.address}-${i}`} onClick={() => setAddress(delegate.address)}>
                <QuickSelectName>{delegate.name}</QuickSelectName>
                <QuickSelectValue>{delegateNames.ensNames?.[i] ?? truncateAddress(delegate.address)}</QuickSelectValue>
              </QuickSelectRow>
            )
          })}
        </QuickSelectWrapper>
        <StyledTooltip
          title={
            submitting || isTransactionPending
              ? awaitingSignature
                ? 'Awaiting signature...'
                : 'Submitting...'
              : isValidAddress
              ? ''
              : 'Enter a valid address'
          }
          showTooltip={submitting || !isValidAddress}
          position={'top'}
        >
          <SubmitButton
            disabled={!isButtonActive}
            blue={!vaultAddress}
            onClick={() => isButtonActive && submitTransaction()}
          >
            {submitting || isTransactionPending ? (
              <CircularProgress />
            ) : !vaultAddress ? (
              'Create Dolomite Vault'
            ) : (
              'Delegate'
            )}
          </SubmitButton>
        </StyledTooltip>
      </DelegateBody>
    </DelegateWrapper>
  )
}
