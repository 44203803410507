import styled from 'styled-components/macro'

export const ColumnTitles = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.text3};
`

export const StatTable = styled.div`
  background-color: ${({ theme }) => theme.bg1};
  border-radius: 8px;
  display: inline-block;
  vertical-align: top;
  width: 100%;
  font-family: 'Open Sans', sans-serif;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
  text-align: left;
  padding: 25px 35px 10px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 20px 20px 10px;
  `};
`

export const StatRows = styled.div`
  font-size: 17px;
  margin-bottom: 5px;

  @media (max-width: 960px) {
    font-size: 15px;
  }

  @media (max-width: 660px) {
    font-size: 13px;
  }
`

export const HideOnSmall = styled.span`
  @media (max-width: 500px) {
    display: none;
  }
`
