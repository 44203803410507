import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Modal from '../Modal'
import { Currency, CurrencyAmount, Token } from '@dolomite-exchange/v2-sdk'
import getLogoOrDefault from '../common/TokenLogos'
import cleanCurrencySymbol from '../../utils/cleanCurrencySymbol'
import cleanCurrencyName from '../../utils/cleanCurrencyName'
import Input from '@material-ui/core/Input'
import Column from '../../components/Orders/Column'
import { ZERO_FRACTION } from '../../constants'
import { getSpecialAsset } from '../../constants/isolation/special-assets'
import { useActiveWeb3React } from '../../hooks'
import { ReactComponent as ShieldIcon } from '../../assets/images/shieldIcon.svg'
import { StyledTooltip } from '../common/StyledTooltip'
import { X } from 'react-feather'
import { Ether, Fraction } from '@dolomite-exchange/sdk-core'
import { filterTokens, FilterType, useFilterTypes } from '../../constants/tokenLists/FilterTokens'

import { formatAmount } from '../../utils/formatAmount'
import { mapExternalTokenToListedAddress } from '../../utils'

const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  padding: 1.5rem 2rem;
`

const InputWrapper = styled.div`
  position: relative;
  width: calc(100% - 110px);

  input {
    height: 20px !important;
    color: #f9f9f9 !important;
    display: inline-flex !important;
    position: relative !important;
    font-size: 1rem !important;
    background: #1e1c29 !important;
    font-family: Open Sans, serif !important;
    line-height: 1.1875em !important;
    font-weight: 300 !important;
    border-radius: 4px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  > div {
    margin-top: 3px;
    padding-top: 0;
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }

  > div > div {
    padding-top: 0 !important;
  }
`

const InputOverflowFix = styled.div`
  height: 33px;
  overflow: hidden;
  position: relative;
`

const ColumnTitles = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 14px;
  margin-top: 13px;
  margin-bottom: 5px;
  color: ${({ theme }) => theme.text3};
`

const ColumnTitle = styled(Column)`
  cursor: pointer;
`

const StyledInput = styled(({ ...props }) => <Input {...props} />)<{ multiline: boolean }>`
  overflow: hidden;
  margin-bottom: 0 !important;
  height: 33px !important;

  input {
    margin-bottom: 0 !important;
  }

  ${({ multiline }) =>
    multiline &&
    `
    margin-top: 2px;
    width: 100% !important;

    textarea {
      overflow: hidden !important;
      padding: 0 8px !important;
      width: calc(100% - 8px) !important;
    }
  `};
  @media (max-width: 1400px) {
    input {
      font-size: 0.9rem;
    }

    p {
      font-size: 0.8rem;
    }
  }
`

const Clear = styled.div<{ disabled: boolean }>`
  color: ${({ theme }) => theme.text3};
  font-weight: 500;
  position: absolute;
  right: 3px;
  top: 4px;
  display: inline-block;
  ${({ disabled }) => disabled && `display: none`};
`

const Close = styled(X)`
  cursor: pointer;
  height: 18px;
`

const CurrencyRows = styled.div`
  width: calc(100% + 30px);
  margin-left: -10px;
  margin-top: 5px;
  max-height: 310px;
  height: 310px;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-color: ${({ theme }) => `${theme.bg4} ${theme.bg2}`};
  scrollbar-width: thin;

  ::-webkit-scrollbar {
    width: 10px;
    margin-left: 2px;
    background: ${({ theme }) => theme.bg1};
    left: 50px;
  }

  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.bg1};
    width: 10px;
    box-shadow: inset 0 0 10px 10px ${({ theme }) => theme.bg1};
    border: solid 3px ${({ theme }) => theme.bg1};
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.bg4};
    width: 4px;
    border-radius: 10px;
    box-shadow: inset 0 0 10px 10px ${({ theme }) => theme.bg4};
    border: solid 3px ${({ theme }) => theme.bg1};
  }

  ::-webkit-scrollbar-button {
    display: none;
  }
`

const CurrencyRow = styled.div`
  width: 100%;
  height: 45px;
  font-size: 17px;
  cursor: pointer;
  border-radius: 5px;
  padding: 5px 10px;

  :hover {
    background-color: ${({ theme }) => theme.bg2};
  }
`

const TokenWrapper = styled.div`
  display: inline-block;
`

const TokenLogo = styled.div`
  display: inline-block;
  margin-right: 7px;
  margin-top: 5px;
  vertical-align: top;
  width: 18px;

  img {
    width: 100%;
  }
`

const TickerWrapper = styled.div`
  display: inline-block;
`

const Ticker = styled.div`
  font-size: 15px;
  font-weight: 400;
  position: relative;
`

const Name = styled.div`
  color: #d5d6e1;
  font-size: 10px;
  font-weight: 100;
`

const Balance = styled.div`
  display: inline;
  float: right;
  text-align: right;
`

const TokenBalance = styled.div`
  font-size: 15px;
  font-weight: 500;
`

const DollarBalance = styled.div`
  color: ${({ theme }) => theme.text2};
  font-size: 10px;
  font-weight: 100;
`

const NoTokens = styled.div`
  color: ${({ theme }) => theme.text3};
  margin: 10px 0;
  text-align: center;
  width: 100%;
  font-size: 15px;
`

const WarningIconWrapper = styled.div`
  display: inline-block;
  margin-left: 3px;
  position: relative;
  top: 2px;

  svg {
    color: ${({ theme }) => theme.text2};
    font-size: 15px;
  }
`

const FilterSection = styled.div`
  position: relative;
  width: 100%;
`

const FilterSelect = styled.div<{ expanded: boolean }>`
  background-color: #3a3a4f;
  border-radius: 4px;
  cursor: pointer;
  height: 33px;
  overflow: hidden;
  width: 90px;
  left: calc(100% - 90px);
  top: 0;
  position: absolute;
  z-index: 1;

  ${({ expanded }) =>
    expanded &&
    `
    border-top-left-radius: 4px;
    height: fit-content;
  `}
`

const FilterSelectRow = styled.div`
  font-size: 16px;
  font-weight: 300;
  padding: 5px 10px;
  height: 33px;

  &:hover {
    background-color: #474956;
  }
`

const ArrowDown = styled.div<{ flipped: boolean }>`
  width: 0;
  height: 0;
  position: absolute;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 6px solid #606375;
  top: 14px;
  right: 7px;

  ${({ flipped }) =>
    flipped &&
    `
    transform: rotate(180deg);
  `}
`

const currencyKey = (currency: Currency) => {
  return currency.isToken ? currency.wrapped.address : currency.symbol ?? ''
}

function isToken(currency: Currency | Token): currency is Token {
  if (!currency) {
    return false
  }
  return 'address' in currency
}

enum SortField {
  VALUE = 'value',
  SYMBOL = 'name',
}

interface CurrencyModalProps {
  isOpen: boolean
  tokens: Currency[]
  balances: Record<string, CurrencyAmount<Currency> | undefined>
  fiatBalances: Record<string, Fraction | undefined>
  balanceTitle: string
  showWETH?: boolean
  filterOutExtendedTokens?: boolean
  borrow?: boolean
  onDismiss: () => void
  currencySelect: (currency: Currency) => void
}

function CurrencyModalComparator(prevProps: CurrencyModalProps, nextProps: CurrencyModalProps) {
  return (
    prevProps.isOpen === nextProps.isOpen &&
    prevProps.tokens === nextProps.tokens &&
    prevProps.balances === nextProps.balances &&
    prevProps.fiatBalances === nextProps.fiatBalances &&
    prevProps.balanceTitle === nextProps.balanceTitle &&
    prevProps.showWETH === nextProps.showWETH &&
    prevProps.filterOutExtendedTokens === nextProps.filterOutExtendedTokens &&
    prevProps.borrow === nextProps.borrow
  )
}

function CurrencyModal({
  tokens: rawTokens,
  balances,
  fiatBalances,
  isOpen,
  onDismiss,
  currencySelect,
  balanceTitle,
  showWETH = false,
  filterOutExtendedTokens = false,
  borrow = false,
}: CurrencyModalProps) {
  const { t } = useTranslation()
  const [inputValue, setInputValue] = useState<string>('')
  const [sortField, setSortField] = useState(SortField.VALUE)
  const [isSortDesc, setIsSortDesc] = useState<boolean>(true)
  const [filterSelectOpen, setFilterSelectOpen] = useState<boolean>(false)
  const filterTypes = useFilterTypes()
  const [filterType, setFilterType] = useState<FilterType>(FilterType.NONE)
  const wrapperRef = React.useRef<HTMLDivElement>(null)
  const { chainId } = useActiveWeb3React()
  const nativeCurrency = useMemo(() => Ether.onChain(chainId), [chainId])
  const wrappedToken = useMemo(() => nativeCurrency.wrapped, [nativeCurrency])
  const wrappedSymbol = wrappedToken.symbol ?? ''
  const tokens = useMemo(() => {
    if (filterOutExtendedTokens) {
      return rawTokens.filter(t => t.wrapped.address === mapExternalTokenToListedAddress(t.wrapped))
    }

    return rawTokens
  }, [rawTokens, filterOutExtendedTokens])

  const handleSort = useCallback(
    (newField: SortField) => {
      setSortField(newField)
      setIsSortDesc(sortField !== newField ? true : !isSortDesc)
    },
    [isSortDesc, sortField],
  )

  const arrow = useCallback(
    (field: SortField) => {
      return sortField === field ? (!isSortDesc ? '↑' : '↓') : ' '
    },
    [isSortDesc, sortField],
  )

  useEffect(() => {
    setFilterType(FilterType.NONE)
  }, [chainId])

  const filteredTokens = useMemo(() => {
    return filterTokens(tokens, filterType, inputValue, chainId, showWETH)
  }, [inputValue, tokens, filterType, chainId, showWETH])

  const sortedTokens = useMemo(() => {
    const sortNamesFunction = (a: Currency, b: Currency, isDesc: boolean) => {
      const aValue = cleanCurrencyName(a, !showWETH)?.toUpperCase() ?? ''
      const bValue = cleanCurrencyName(b, !showWETH)?.toUpperCase() ?? ''
      const truthy = aValue > bValue
      return truthy ? (isDesc ? -1 : 1) * 1 : (isDesc ? -1 : 1) * -1
    }

    const sortSymbolsFunction = (a: Currency, b: Currency, isDesc: boolean) => {
      const aValue = cleanCurrencySymbol(a, !showWETH)?.toUpperCase() ?? ''
      const bValue = cleanCurrencySymbol(b, !showWETH)?.toUpperCase() ?? ''
      if (aValue === bValue) {
        return sortNamesFunction(a, b, isDesc)
      }
      const truthy = aValue > bValue
      return truthy ? (isDesc ? -1 : 1) * 1 : (isDesc ? -1 : 1) * -1
    }

    return filteredTokens.sort((a, b) => {
      if (a && b) {
        if (sortField === SortField.SYMBOL) {
          return sortSymbolsFunction(a, b, !isSortDesc)
        } else if (sortField === SortField.VALUE) {
          const fiatBalanceA = fiatBalances[currencyKey(a)] ?? ZERO_FRACTION
          const fiatBalanceB = fiatBalances[currencyKey(b)] ?? ZERO_FRACTION
          if (fiatBalanceA.equalTo(fiatBalanceB)) {
            return sortSymbolsFunction(a, b, false)
          } else {
            return fiatBalanceA.abs.greaterThan(fiatBalanceB.abs)
              ? (isSortDesc ? -1 : 1) * 1
              : (isSortDesc ? -1 : 1) * -1
          }
        } else {
          console.warn('Invalid sort field, found:', sortField)
          return -1
        }
      } else {
        return -1
      }
    })
  }, [filteredTokens, showWETH, sortField, isSortDesc, fiatBalances])

  const selectFilter = (field: FilterType) => {
    setFilterType(field)
    setFilterSelectOpen(false)
  }

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (wrapperRef?.current && !wrapperRef?.current.contains(event.target as Node)) {
      setFilterSelectOpen(false)
    }
  }, [])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [handleClickOutside])

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      <ModalContent>
        <FilterSection>
          <InputWrapper>
            <InputOverflowFix>
              <StyledInput
                onChange={(e: any) => setInputValue(e.target.value)}
                multiline={false}
                fullWidth
                spellCheck={false}
                placeholder={'Search for Token'}
                value={inputValue}
                variant=''
                disableUnderline={true}
                endAdornment={''}
              />
            </InputOverflowFix>
            <Clear onClick={() => setInputValue('')} disabled={inputValue === ''}>
              <Close />
            </Clear>
          </InputWrapper>
          <FilterSelect
            expanded={filterSelectOpen}
            ref={wrapperRef}
            onClick={() => !filterSelectOpen && setFilterSelectOpen(true)}
          >
            {!filterSelectOpen && (
              <FilterSelectRow onClick={() => setFilterSelectOpen(false)}>
                {filterType === FilterType.NONE ? 'Filter' : filterType}
              </FilterSelectRow>
            )}
            {filterSelectOpen && (
              <FilterSelectRow onClick={() => setFilterSelectOpen(false)}>{filterType}</FilterSelectRow>
            )}
            {filterTypes
              .filter(filter => filter !== filterType)
              .map((filter, index) => {
                return (
                  <FilterSelectRow key={`filter-dropdown-${index}`} onClick={() => selectFilter(filter as FilterType)}>
                    {filter}
                  </FilterSelectRow>
                )
              })}
            <ArrowDown flipped={filterSelectOpen} />
          </FilterSelect>
        </FilterSection>
        <ColumnTitles>
          <ColumnTitle
            width={50}
            textAlign={'left'}
            onClick={(e: any) => {
              e.preventDefault()
              handleSort(SortField.SYMBOL)
            }}
          >
            {t('token')} {arrow(SortField.SYMBOL)}
          </ColumnTitle>
          <ColumnTitle width={50} textAlign={'right'} onClick={() => handleSort(SortField.VALUE)}>
            {balanceTitle} {arrow(SortField.VALUE)}
          </ColumnTitle>
        </ColumnTitles>
        <CurrencyRows>
          {sortedTokens.length === 0 ? (
            <NoTokens>No Tokens</NoTokens>
          ) : (
            sortedTokens.map((currency: Currency) => {
              if (!(!showWETH && currency.isNative)) {
                return (
                  <CurrencyRow key={`${currency.symbol}-row`} onClick={() => currencySelect(currency)}>
                    <TokenWrapper>
                      <TokenLogo>
                        <img
                          src={getLogoOrDefault(cleanCurrencySymbol(currency, !showWETH) ?? '')}
                          alt={`${currency.symbol} logo`}
                        />
                      </TokenLogo>
                      <TickerWrapper>
                        <Ticker>
                          {showWETH && currency.symbol === wrappedSymbol
                            ? wrappedSymbol
                            : cleanCurrencySymbol(currency, !showWETH)}
                          {borrow && isToken(currency) && !!getSpecialAsset(chainId, currency)?.isIsolationMode && (
                            <StyledTooltip
                              title={
                                'Isolation Mode asset - This asset can only act as collateral and can only be selected when opening a new borrow position.'
                              }
                              placement={'right'}
                            >
                              <WarningIconWrapper>
                                <ShieldIcon />
                              </WarningIconWrapper>
                            </StyledTooltip>
                          )}
                        </Ticker>
                        <Name>{cleanCurrencyName(currency, !showWETH)}</Name>
                      </TickerWrapper>
                    </TokenWrapper>
                    <Balance>
                      <TokenBalance>{formatAmount(balances[currencyKey(currency)], 6, true, '0.00')}</TokenBalance>
                      <DollarBalance>
                        {formatAmount(fiatBalances[currencyKey(currency)], 2, true, '$0.00', true)}
                      </DollarBalance>
                    </Balance>
                  </CurrencyRow>
                )
              } else {
                return <></>
              }
            })
          )}
        </CurrencyRows>
      </ModalContent>
    </Modal>
  )
}

export default React.memo(CurrencyModal, CurrencyModalComparator)
