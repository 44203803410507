import React from 'react'
import styled from 'styled-components/macro'
import { useActivePopups } from '../../state/application/hooks'
import { AutoColumn } from '../Column'
import PopupItem from './PopupItem'
import { useURLWarningVisible } from '../../state/user/hooks'

const MobilePopupWrapper = styled.div<{ height: string | number }>`
  position: relative;
  max-width: calc(100% - 20px);
  height: ${({ height }) => height};
  margin: ${({ height }) => (height ? '0 auto;' : 0)};
  margin-bottom: ${({ height }) => (height ? '20px' : 0)}
    ${({ theme }) => theme.mediaWidth.upToSmall`
    background: none;
    position: absolute;
    z-index: 9999;
    padding-left: 50%;
    top: 60px;
  `};
  display: none;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: block;
  `};
`

const MobilePopupInner = styled.div`
  height: 99%;
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  flex-direction: row;
  -webkit-overflow-scrolling: touch;

  ::-webkit-scrollbar {
    display: none;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-left: -100%;
    overflow: auto;
    border-radius: 5px;
    box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
  `};
`

const FixedPopupColumn = styled(AutoColumn)<{ extraPadding: boolean }>`
  position: fixed;
  top: ${({ extraPadding }) => (extraPadding ? '108px' : '65px')};
  right: 1rem;
  max-width: 355px !important;
  width: 100%;
  z-index: 999;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `};
`

export default function Popups() {
  // get all popups
  const activePopups = useActivePopups()

  const urlWarningActive = useURLWarningVisible()

  return (
    <>
      <FixedPopupColumn gap='20px' extraPadding={urlWarningActive}>
        {activePopups.map(item => (
          <PopupItem key={item.key} content={item.content} popKey={item.key} removeAfterMs={item.removeAfterMs} />
        ))}
      </FixedPopupColumn>
      <MobilePopupWrapper height={activePopups.length > 0 ? 'fit-content' : 0}>
        <MobilePopupInner>
          {activePopups // reverse so new items up front
            .slice(0)
            .reverse()
            .map(item => (
              <PopupItem key={item.key} content={item.content} popKey={item.key} removeAfterMs={item.removeAfterMs} />
            ))}
        </MobilePopupInner>
      </MobilePopupWrapper>
    </>
  )
}
