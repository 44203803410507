import styled from 'styled-components/macro'
import { MEDIA_WIDTHS } from '../../../theme'

const Column = styled.div<{
  width: number
  tabletWidth?: number
  mobileWidth?: number
  customTabletWidth?: number
  customMobileWidth?: number
  textAlign: string
  smallTextAlign?: string | null
  hideOnMobile?: boolean | null
  hideOnTablet?: boolean | null
  hideOnDesktop?: boolean | null
}>`
  width: ${({ width }) => `${width}%`};
  display: ${({ hideOnDesktop }) => (hideOnDesktop ? 'none' : 'inline-block')};
  vertical-align: top;
  text-align: ${({ textAlign }) => textAlign};

  ${({ theme, hideOnTablet, tabletWidth, customTabletWidth }) =>
    `@media (max-width: ${customTabletWidth ?? MEDIA_WIDTHS.upToMedium}px) {
      ${tabletWidth ? `width: ${tabletWidth}%;` : ''}
      display: ${hideOnTablet ? 'none' : 'inline-block'};
    }
  `};

  ${({ theme, hideOnMobile, mobileWidth, customMobileWidth }) =>
    `@media (max-width: ${customMobileWidth ?? MEDIA_WIDTHS.upToSmall}px) {
      ${mobileWidth ? `width: ${mobileWidth}%;` : ''}
      display: ${hideOnMobile ? 'none' : 'inline-block'};
    }
  `};
`

export default Column
