import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import AddCircleIcon from '@material-ui/icons/AddCircleOutline'
import Input from '@material-ui/core/Input'
import { BalanceCheckFlag, Currency, CurrencyAmount, Token } from '@dolomite-exchange/v2-sdk'
import CircularProgress from '@material-ui/core/CircularProgress'
import { tryParseAmount } from '../../state/trade/hooks'
import { useFiatValuesWithLoadingIndicator, useFiatValueWithLoadingIndicator } from '../../hooks/useFiatValue'
import { useTranslation } from 'react-i18next'
import { useMarketRiskInfoData } from '../../types/marketRiskInfoData'
import { useDolomiteMarginContract } from '../../hooks/useContract'
import { useOpenBorrowPosition } from '../../hooks/useBorrowPositionProtocol'
import { FORMATTER, ZERO_ADDRESS, ZERO_FRACTION } from '../../constants'
import JSBI from 'jsbi'
import { ethers } from 'ethers'
import { useActiveWeb3React } from '../../hooks'
import ReactGA from 'react-ga'
import cleanCurrencySymbol from '../../utils/cleanCurrencySymbol'
import ConfirmBorrowModal from '../../components/ConfirmModal/BorrowModal'
import { StyledTooltip } from '../../components/common/StyledTooltip'
import { useDefaultMarginAccount } from '../../types/marginAccount'
import { ReactComponent as ShieldIcon } from '../../assets/images/shieldIcon.svg'
import VpnLock from '@material-ui/icons/VpnLock'
import WarningRoundedIcon from '@material-ui/icons/WarningRounded'
import { getSpecialAsset, useIsTokenGeoRestricted, useSpecialAsset } from '../../constants/isolation/special-assets'
import CurrencyModal from '../../components/CurrencyModal'
import { useAllActiveTokensArray } from '../../hooks/Tokens'
import { useMarketsTotalWeiData } from '../../hooks/useDolomiteMarginProtocol'
import { useDolomiteBalancesWithLoadingIndicator, useETHBalances, useTokenBalances } from '../../state/wallet/hooks'
import toDate from '../../utils/toDate'
import { useUserLifetimeBorrowPositionCount, useUserLifetimeDataOnAllNetworks } from '../../types/userLifetimeData'
import BoltIcon from '@mui/icons-material/Bolt'
import { TopRow } from './BorrowPosition/BorrowExpandedContent'
import AdvancedDescription from './BorrowPosition/AdvancedDescription'
import { ExtraZapInfo, useZapExactTokensForTokensOLD, ZapEventType } from '../../hooks/useExecuteZap'
import BorrowTradeDetails from './BorrowPosition/BorrowTradeDetails'
import { CollateralActionType, ManageType } from './types'
import { formatAmount } from '../../utils/formatAmount'
import { useCreateVaultIsolationModeVault } from '../../hooks/useDolomiteIsolationModeProtoocol'
import useIsolationModeUserVaultAddressIfCreated from '../../hooks/useIsolationModeUserVaultAddressIfCreated'
import { useIsTransactionSuccessful } from '../../state/transactions/hooks'
import { ZapOutputParam } from '../../hooks/useGetZapParams'
import { Fraction } from '@dolomite-exchange/sdk-core'

export enum ZapStage {
  VAULT = 0,
  ZAP = 1,
  COMPLETE = 2,
}

const NewBorrowWrapper = styled.div<{
  expanded: boolean
  isZapActivated: boolean
  showTradeDetails: boolean
  tall: boolean
  extraTall: boolean
  showDisclaimer: boolean
  displayingTokenSelection: boolean
  isIsolationToIsolationError: boolean
}>`
  width: 100%;
  height: ${({ expanded, tall, extraTall, isZapActivated, showTradeDetails, isIsolationToIsolationError }) =>
    expanded
      ? tall
        ? isIsolationToIsolationError
          ? extraTall
            ? isZapActivated
              ? showTradeDetails
                ? '539px'
                : '439px'
              : '350px'
            : isZapActivated
            ? showTradeDetails
              ? '489px'
              : '389px'
            : '300px'
          : extraTall
          ? isZapActivated
            ? showTradeDetails
              ? '509px'
              : '409px'
            : '320px'
          : isZapActivated
          ? showTradeDetails
            ? '459px'
            : '350px'
          : '270px'
        : isZapActivated
        ? showTradeDetails
          ? '415px'
          : '305px'
        : '220px'
      : '80px'};
  background: ${({ theme }) => theme.bg1};
  border-radius: 8px;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
  padding: 24px 32px;
  position: relative;
  cursor: ${({ expanded }) => (expanded ? 'default' : 'pointer')};
  transition: ${({ expanded, tall }) =>
    expanded ? (tall ? 'all 0.3s ease-in-out' : 'all 0.3s ease-in-out 0.2s') : 'all 0.3s ease-in-out 0.2s'};
  text-align: left;
  overflow: ${({ expanded, displayingTokenSelection }) =>
    expanded ? (displayingTokenSelection ? 'initial' : 'hidden') : 'hidden'};

  ${({ expanded }) =>
    !expanded &&
    `:hover {
    filter: brightness(1.2);
  }`};

  @media screen and (max-width: 660px) {
    height: ${({ expanded, extraTall, tall, isZapActivated, showTradeDetails, showDisclaimer }) =>
      expanded
        ? showDisclaimer
          ? tall
            ? extraTall
              ? isZapActivated
                ? showTradeDetails
                  ? '580px'
                  : '474px'
                : '384px'
              : isZapActivated
              ? showTradeDetails
                ? '530px'
                : '430px'
              : '335px'
            : isZapActivated
            ? showTradeDetails
              ? '474px'
              : '364px'
            : '272px'
          : tall
          ? extraTall
            ? isZapActivated
              ? showTradeDetails
                ? '530px'
                : '424px'
              : '334px'
            : isZapActivated
            ? showTradeDetails
              ? '480px'
              : '380px'
            : '285px'
          : isZapActivated
          ? showTradeDetails
            ? '424px'
            : '314px'
          : '222px'
        : '80px'};
  }

  @media screen and (max-width: 550px) {
    height: ${({ extraTall, tall, showTradeDetails }) =>
      showTradeDetails ? (tall ? (extraTall ? '580px' : '530px') : '470px') : ''};
  }

  @media screen and (max-width: 480px) {
    height: ${({ expanded, extraTall, tall, isZapActivated, showTradeDetails, showDisclaimer }) =>
      expanded
        ? showDisclaimer
          ? tall
            ? extraTall
              ? isZapActivated
                ? showTradeDetails
                  ? '719px'
                  : '543px'
                : '424px'
              : isZapActivated
              ? showTradeDetails
                ? '641px'
                : '480px'
              : '361px'
            : isZapActivated
            ? showTradeDetails
              ? '564px'
              : '413px'
            : '294px'
          : tall
          ? extraTall
            ? isZapActivated
              ? showTradeDetails
                ? '659px'
                : '483px'
              : '364px'
            : isZapActivated
            ? showTradeDetails
              ? '581px'
              : '420px'
            : '301px'
          : isZapActivated
          ? showTradeDetails
            ? '474px'
            : '353px'
          : '234px'
        : '80px'};
  }
`

const AddIconWrapper = styled.div<{ expanded: boolean }>`
  position: absolute;
  top: 18px;
  left: calc(50% - 165px);
  opacity: ${({ expanded }) => (expanded ? 0 : 1)};
  transition: ${({ expanded }) => (expanded ? 'opacity 0.2s ease-in-out' : 'opacity 0.2s ease-in-out 0.4s')};

  svg {
    font-size: 45px;
  }

  @media screen and (max-width: 480px) {
    top: 18px;
    left: calc(50% - 145px);

    svg {
      font-size: 38px;
    }
  }
`

const OpenText = styled.div<{ expanded: boolean }>`
  font-size: ${({ expanded }) => (expanded ? '20px' : '22px')};
  font-weight: 100;
  position: absolute;
  top: ${({ expanded }) => (expanded ? '24px' : '25px')};
  left: ${({ expanded }) => (expanded ? '32px' : 'calc(50% - 110px)')};
  transition: ${({ expanded }) => (expanded ? 'all 0.3s ease-in-out' : 'all 0.3s ease-in-out 0.2s')};

  @media screen and (max-width: 480px) {
    font-size: ${({ expanded }) => (expanded ? '20px' : '20px')};
    left: ${({ expanded }) => (expanded ? '32px' : 'calc(50% - 100px)')};
    top: ${({ expanded }) => (expanded ? '24px' : '25px')};
  }
`

export const DescriptionText = styled.div<{
  expanded: boolean
  visible?: boolean
  bottom?: boolean
  extraTall?: boolean
  extraMargin?: boolean
  extraSpace?: boolean
}>`
  font-size: 12px;
  font-weight: 100;
  opacity: ${({ expanded, visible }) => (expanded ? (visible ? 1 : 0) : 0)};
  pointer-events: ${({ visible }) => (visible ? 'auto' : 'none')};
  transition: ${({ expanded }) => (expanded ? 'all 0.3s ease-in-out 0.2s' : 'all 0.3s ease-in-out')};
  position: absolute;
  top: ${({ bottom, extraTall, extraSpace }) =>
    extraSpace ? (bottom ? (extraTall ? 173 : 123) : 101) : bottom ? (extraTall ? 123 : 73) : 51}px;
  line-height: 14px;
  left: 32px;
  color: ${({ theme }) => theme.text2};
  width: calc(100% - 64px);
  transition: ${({ visible }) => (visible ? 'opacity 0.3s ease-in-out 0.2s' : 'opacity 0.3s ease-in-out')};

  ${({ bottom }) =>
    bottom &&
    `
      width: calc(100% - 64px);
      background: #2f2f40;
      padding: 6px;
      border-radius: 5px;
      padding-left: 35px;
  `}
  svg {
    margin-right: 2px;
    margin-bottom: -1px;
    font-size: 24px;
    color: ${({ theme }) => theme.text3};
    position: absolute;
    left: 6px;
    top: 8px;
  }

  a {
    color: ${({ theme }) => theme.text2};
    font-weight: 600;
  }

  @media screen and (max-width: 660px) {
    top: ${({ bottom, extraTall, extraSpace }) =>
      extraSpace ? (bottom ? (extraTall ? 180 : 120) : 90) : bottom ? (extraTall ? 130 : 80) : 55}px;
  }

  @media screen and (max-width: 480px) {
    left: 32px;
    font-size: 10px;
    line-height: 12px;
    margin-top: ${({ extraMargin }) => (extraMargin ? 6 : 2)}px;
    top: ${({ bottom, extraTall }) => (bottom ? (extraTall ? 145 : 85) : 55)}px;
  }
`

const DescriptionTextTall = styled(DescriptionText)`
  line-height: 28px;

  @media screen and (max-width: 480px) {
    line-height: 24px;
  }
`

const BalanceRow = styled.div`
  width: 100%;
  font-size: 13px;
  margin-bottom: 2px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
`

const BalanceWrapper = styled.div`
  display: block;
  cursor: auto;
`

const MaxButton = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.text3};
  cursor: pointer;

  :hover {
    color: ${({ theme }) => theme.text2};
  }
`

const Balance = styled.span`
  font-weight: 200;
`

const InputWrapper = styled.div<{ expanded: boolean; tall: boolean; extraTall: boolean; isoToIsoError: boolean }>`
  display: inline-block;
  position: relative;
  width: 100%;
  vertical-align: top;
  margin-top: ${({ extraTall, tall, isoToIsoError }) =>
    isoToIsoError ? (extraTall ? '185px' : tall ? '135px' : '90px') : extraTall ? '145px' : tall ? '95px' : '50px'};
  opacity: ${({ expanded }) => (expanded ? 1 : 0)};
  transition: ${({ expanded, tall }) =>
    expanded ? (tall ? 'all 0.3s ease-in-out' : 'all 0.3s ease-in-out 0.2s') : 'all 0.3s ease-in-out 0.35s'};
  pointer-events: ${({ expanded }) => (expanded ? 'all' : 'none')};

  input {
    height: 20px !important;
    color: #f9f9f9 !important;
    display: inline-flex !important;
    position: relative !important;
    font-size: 1rem !important;
    background: #1e1c29 !important;
    font-family: Open Sans, serif !important;
    line-height: 1.1875em !important;
    font-weight: 300 !important;
    border-radius: 4px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  > div:nth-child(2) {
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }

  > div:nth-child(2) > div {
    padding-top: 0 !important;
  }

  @media screen and (max-width: 660px) {
    margin-top: ${({ extraTall, tall }) => (extraTall ? '190px' : tall ? '127px' : '60px')};
  }
`

const ZapInputWrapper = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;

  input {
    height: 20px !important;
    color: #f9f9f9 !important;
    display: inline-flex !important;
    position: relative !important;
    font-size: 1rem !important;
    background: #1e1c29 !important;
    font-family: Open Sans, serif !important;
    line-height: 1.1875em !important;
    font-weight: 300 !important;
    border-radius: 4px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  > div:first-child {
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }

  > div:first-child > div {
    padding-top: 0 !important;
  }

  @media screen and (max-width: 550px) {
    width: 100%;
  }
`

const TokenSelector = styled.div<{ expanded: boolean }>`
  background-color: #3a3a4f;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  cursor: pointer;
  height: 33px;
  right: 0;
  bottom: 0;
  overflow: hidden;
  position: absolute;
  width: fit-content;
  z-index: 1;
  text-align: left;

  ${({ expanded }) =>
    expanded &&
    `
    /*border-top-left-radius: 4px;*/
    height: fit-content;
  `}
`

const TokenSelectRow = styled.div`
  font-size: 16px;
  font-weight: 300;
  padding: 5px 25px 5px 10px;
  height: 33px;

  &:hover {
    background-color: ${({ theme }) => theme.bg4};
  }
`

const InputOverflowFix = styled.div`
  height: 33px;
  overflow: hidden;
  position: relative;
`

const StyledInput = styled(({ ...props }) => <Input {...props} />)<{ multiline: boolean }>`
  overflow: hidden;
  margin-bottom: 0 !important;
  height: 33px !important;

  input {
    margin-bottom: 0 !important;
  }

  ${({ disabled }) =>
    disabled &&
    `
    input {
      background: #262535 !important;
    }
  `}

  ${({ multiline }) =>
    multiline &&
    `
    margin-top: 2px;
    width: 100% !important;

    textarea {
      overflow: hidden !important;
      padding: 0 8px !important;
      width: calc(100% - 8px) !important;
    }
  `};

  @media (max-width: 1400px) {
    input {
      font-size: 0.9rem;
    }

    p {
      font-size: 0.8rem;
    }
  }
`

const ArrowDown = styled.div<{ flipped: boolean }>`
  width: 0;
  height: 0;
  position: absolute;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 6px solid #606375;
  bottom: 14px;
  right: 7px;

  ${({ flipped }) =>
    flipped &&
    `
    transform: rotate(180deg);
  `}
`

export const AdvancedSection = styled.div<{
  expanded: boolean
  displayingTokenSelection: boolean
  showTradeDetails: boolean
  open?: boolean
}>`
  width: 100%;
  overflow: ${({ displayingTokenSelection }) => (displayingTokenSelection ? 'visible' : 'hidden')};
  height: ${({ expanded, open, showTradeDetails }) =>
    expanded ? (open ? (showTradeDetails ? '192px' : '85px') : '225px') : '0'};
  transition: ${({ showTradeDetails, expanded }) =>
    showTradeDetails || expanded ? 'height 0.3s ease-in-out' : 'height 0.3s ease-in-out 0.2s'};

  @media screen and (max-width: 550px) {
    transition: ${({ showTradeDetails }) =>
      showTradeDetails ? 'max-height 0.3s ease-in-out' : 'max-height 0.3s ease-in-out 0.2s'};
    height: auto;
    max-height: ${({ expanded }) => (expanded ? '290px' : '0')};
  }
`

const BottomRowWrapper = styled.div<{ expanded: boolean; showDisclaimer: boolean }>`
  text-align: right;
  margin: 16px 0;
  opacity: ${({ expanded }) => (expanded ? 1 : 0)};
  transition: ${({ expanded }) => (expanded ? 'all 0.2s ease-in-out 0.35s' : 'all 0.2s ease-in-out')};

  @media screen and (max-width: 660px) {
    bottom: ${({ showDisclaimer }) => (showDisclaimer ? '74px' : '24px')};
  }

  @media screen and (max-width: 480px) {
    right: 32px;
  }
`

const DisclaimerWrapper = styled.div<{ expanded: boolean }>`
    display: inline-block;
    position: absolute;
    left: 32px;
    bottom: 24px;
    color: ${({ theme }) => theme.text3};
    font-size: 9px;
    line-height: 11px;
    max-width: 51%;
    opacity: ${({ expanded }) => (expanded ? 1 : 0)};
    transition: ${({ expanded }) => (expanded ? 'all 0.2s ease-in-out 0.35s' : 'all 0.2s ease-in-out')};
    pointer-events: ${({ expanded }) => (expanded ? 'all' : 'none')};

    a {
        color: ${({ theme }) => theme.text3};
        text-decoration: none;
        font-weight: 700;
    }

    @media screen and (max-width: 660px) {
        max-width: calc(100% - 64px);
        text-align: center;
`

const DepositedButtonWrapper = styled.div<{ activated: boolean }>`
  @media screen and (max-width: 550px) {
    margin-top: ${({ activated }) => (activated ? '10px' : '0')};
  }
`

const IsoToIsoErrorWrapper = styled.div<{ visible: boolean }>`
  display: inline-block;
  vertical-align: top;
  float: left;
  text-align: left;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 0.2s ease-in-out;
  color: ${({ theme }) => theme.red1};
  font-size: 12px;
  width: 45%;
  margin: 2px 5px 0 0;

  @media screen and (max-width: 550px) {
    font-size: 10px;
  }
`

const DepositedValuesWrapper = styled.div`
  width: fit-content;
  display: inline-block;
  margin-right: 8px;
`

const DepositedCrypto = styled.div`
  font-size: 15px;
  color: ${({ theme }) => theme.text1};
  font-weight: 100;
`

const DepositedUSD = styled.div`
  font-size: 13px;
  color: ${({ theme }) => theme.text3};
  font-weight: 100;
`

const SubmitButtonWrapper = styled.div<{ expanded: boolean }>`
  width: 90px;
  margin: 1px 0 0 0;
  display: inline-block;
  vertical-align: top;
  pointer-events: ${({ expanded }) => (expanded ? 'all' : 'none')};
  cursor: ${({ expanded }) => (expanded ? 'auto' : 'default')};
`

const SubmitButton = styled.div<{ disabled: boolean }>`
  width: 100%;
  height: 36px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.text1};
  color: ${({ theme }) => theme.text1};
  background-color: ${({ theme }) => theme.green2};
  text-align: center;
  line-height: 36px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};

  :hover {
    background-color: ${({ theme }) => theme.green1};
  }

  > div {
    height: 19px !important;
    width: 19px !important;
    margin-top: 7px;
  }

  svg {
    color: white !important;
  }
`

const ZapButtonWrapper = styled.span`
  display: contents;
  vertical-align: top;
  font-weight: 700;

  svg {
    height: 20px;
    width: 16px;
    margin-right: -3px;
    margin-bottom: -5px;
    margin-left: -5px;
  }
`

const TooltipResizer = styled.div`
  display: inline-block;
  vertical-align: top;

  svg {
    height: 17px !important;
    margin-top: 1px;
  }

  @media screen and (max-width: 550px) {
    svg {
      margin-top: 0;
    }
  }
`

const DetailsWrapper = styled.div`
  margin-top: 18px;
`

const ErrorMessage = styled.div<{ isVisible: boolean; tall: boolean }>`
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: opacity 0.2s ease-in-out;
  font-size: 12px;
  color: ${({ theme }) => theme.red1};
  height: ${({ tall }) => (tall ? 34 : 17)};
  padding-top: ${({ tall }) => (tall ? 0 : 6)}px;
  width: 100%;
`

const ErrorText = styled.div`
  font-size: 12px;
`

export const IsolationModeExplainer = ({
  expanded,
  visible,
  openText,
}: {
  expanded: boolean
  visible: boolean
  openText?: boolean
}) => {
  return (
    <DescriptionText expanded={expanded} visible={visible} bottom>
      <ShieldIcon />
      {openText
        ? `If you intend to use an isolation mode asset (such as a GM or PT asset) in your borrow position, to be supported it must be the initial collateral used in the borrow position. `
        : `Isolation Mode assets cannot be borrowed and can only serve as collateral in borrow positions where they were the initial collateral asset. `}
      <a href={'https://docs.dolomite.io/risk-mitigation#isolation-mode'} target={'_blank'} rel={'noreferrer'}>
        Learn more
      </a>
    </DescriptionText>
  )
}

export const GeoRestrictionExplainer = ({ expanded, visible }: { expanded: boolean; visible: boolean }) => {
  return (
    <DescriptionTextTall expanded={expanded} visible={visible} bottom>
      <VpnLock />
      {`This asset is not available in your region.`}
    </DescriptionTextTall>
  )
}

export const ExpirationExplainer = ({
  expanded,
  visible,
  expiration,
}: {
  expanded: boolean
  visible: boolean
  expiration: Date | undefined
}) => {
  if (!expiration) {
    return <div />
  }
  return (
    <DescriptionText expanded={expanded} visible={visible} bottom extraTall extraMargin>
      <WarningRoundedIcon />
      {`A position using this asset as collateral will expire in ${toDate(
        expiration,
      )}. If it is not closed before expiration, it will be liquidated and incur a liquidation penalty.`}
      &nbsp;
      <a href={'https://docs.dolomite.io/risk-mitigation#expirations'} target={'_blank'} rel={'noreferrer'}>
        Learn more
      </a>
    </DescriptionText>
  )
}

export const AssetExpiredExplainer = ({
  expanded,
  visible,
  asset,
}: {
  expanded: boolean
  visible: boolean
  asset: Token | undefined
}) => {
  if (!asset) {
    return <div />
  }
  return (
    <DescriptionText expanded={expanded} visible={visible} bottom extraTall>
      <WarningRoundedIcon />
      {`Cannot open a position using ${cleanCurrencySymbol(
        asset,
      )} as collateral because the asset is close to expiration or has expired.`}
      &nbsp;
      <a href={'https://docs.dolomite.io/risk-mitigation#expirations'} target={'_blank'} rel={'noreferrer'}>
        Learn more
      </a>
    </DescriptionText>
  )
}

export const GmFeeExplainer = ({ expanded, visible }: { expanded: boolean; visible: boolean }) => {
  return (
    <DescriptionText expanded={expanded} visible={visible} bottom extraTall>
      <WarningRoundedIcon />
      {`GM assets require a 0.001 ETH deposit to cover liquidation costs. This deposit is paid from your wallet and returned to you upon closure of the borrow position. `}
      &nbsp;
      <a href={'https://docs.dolomite.io/integrations/gmx/gm#fees'} target={'_blank'} rel={'noreferrer'}>
        Learn more
      </a>
    </DescriptionText>
  )
}

const WarningText = styled.div`
  color: ${({ theme }) => theme.yellow1};
  font-weight: 500;
`

export const IsoToIsoError = ({
  expanded,
  visible,
  extraSpace,
}: {
  expanded: boolean
  visible: boolean
  extraSpace: boolean
}) => {
  return (
    <DescriptionText expanded={expanded} visible={visible} bottom extraTall extraSpace={extraSpace}>
      <WarningRoundedIcon style={{ top: '3px' }} />
      <WarningText
        style={{ padding: '3px' }}
      >{`Cannot zap from one isolation mode asset to another isolation mode asset.`}</WarningText>
    </DescriptionText>
  )
}

interface OpenNewBorrowProps {
  isAttemptingTx: boolean
  isTxPending: boolean
  txHash: string | undefined
  setIsAttemptingTx: (isAttemptingTxn: boolean) => void
  setIsDialogOpen: (isDialogOpen: boolean) => void
  setSelectedToken: (selectedToken: Token) => void
  setTransactionPendingText: (pendingText: string) => void
  setTxHash: (txHash: string | undefined) => void
  addOpeningPosition: (positionId: string) => void
}

function OpenNewBorrowComparator(prevProps: OpenNewBorrowProps, nextProps: OpenNewBorrowProps) {
  return prevProps.isAttemptingTx === nextProps.isAttemptingTx && prevProps.isTxPending === nextProps.isTxPending
}

const RANDOM_NUMBER = Math.floor(Math.random() * 10000000000) // used on a "per session" basis

function OpenNewBorrow({
  isAttemptingTx,
  setIsAttemptingTx,
  isTxPending,
  txHash,
  setIsDialogOpen,
  setSelectedToken: setSelectedTokenForTransactionConfirmation,
  setTransactionPendingText,
  setTxHash,
  addOpeningPosition,
}: OpenNewBorrowProps) {
  const { t } = useTranslation()
  const { account, chainId } = useActiveWeb3React()
  const tokens = useAllActiveTokensArray()
  const userEthBalance = useETHBalances(account ? [account] : [])[account ?? '']
  const [dolomiteBalanceData] = useDolomiteBalancesWithLoadingIndicator(account, tokens)
  const [expanded, setExpanded] = useState(false)
  const [inputValue, setInputValue] = useState<string>('')
  const [zapInputValue, setZapInputValue] = useState('')
  const [selectedToken, setSelectedToken] = useState<Token>(tokens[0])
  const [tokenSelectOpen, setTokenSelectOpen] = useState(false)
  const wrapperRef = React.useRef<HTMLDivElement>(null)
  const [dolomiteFiatBalanceMap] = useFiatValuesWithLoadingIndicator(dolomiteBalanceData, tokens)
  const [isZapActivated, setIsZapActivated] = useState(false)
  const [zapTokenSelectOpen, setZapTokenSelectOpen] = useState(false)
  const [selectedZapToken, setSelectedZapToken] = useState<Token>(tokens[1])
  const [zapRefreshIncrementor, setZapRefreshIncrementor] = useState(0)
  const [borrowModalOpen, setBorrowModalOpen] = useState<boolean>(false)
  const [pendingHash, setPendingHash] = useState<string | undefined>(undefined)
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
  const [zapErrorMessage, setZapErrorMessage] = useState<string | undefined>(undefined)
  const [newPositionId, setPositionId] = useState<string | undefined>(undefined)
  const selectedSpecialAsset = useSpecialAsset(selectedToken)
  const selectedZapSpecialAsset = useSpecialAsset(selectedZapToken)
  const { callback: createVaultCallback, error: createVaultError } = useCreateVaultIsolationModeVault(selectedZapToken)
  const isolationModeUserVaultAddress = useIsolationModeUserVaultAddressIfCreated(selectedZapToken)
  const selectedZapTokenIsolationMode = !!selectedZapSpecialAsset?.isIsolationMode ?? false
  const firstStage = useMemo(() => {
    return selectedZapTokenIsolationMode && !isolationModeUserVaultAddress ? ZapStage.VAULT : ZapStage.ZAP
  }, [selectedZapTokenIsolationMode, isolationModeUserVaultAddress])
  const [zapStage, setZapStage] = useState<ZapStage>(firstStage)
  const modalTokens = useMemo(() => {
    return /*tokenSelectOpen && isZapActivated && restrictedList && restrictedList?.length > 0 ? restrictedList :*/ tokens
  }, [tokens])
  const dolomiteMarginContract = useDolomiteMarginContract()
  const protocolBalanceInfoMap = useTokenBalances(dolomiteMarginContract?.address, modalTokens)
  const { data: marketRiskInfoMap } = useMarketRiskInfoData()
  const [marketTotalWeiMap] = useMarketsTotalWeiData()
  const isGeoRestricted = useIsTokenGeoRestricted(selectedToken.wrapped.address)

  useEffect(() => {
    setZapStage(firstStage)
  }, [firstStage, selectedZapToken])
  const closeModal = useCallback(() => {
    setTokenSelectOpen(false)
  }, [])
  const closeZapModal = useCallback(() => {
    setZapTokenSelectOpen(false)
  }, [])
  const selectToken = useCallback(
    (currency: Currency) => {
      const token = currency.wrapped
      if (selectedZapToken?.address === token.address) {
        setSelectedZapToken(selectedToken)
      }
      setSelectedToken(token)
      setTokenSelectOpen(false)
      setSelectedTokenForTransactionConfirmation(token)
    },
    [setSelectedTokenForTransactionConfirmation, selectedToken, selectedZapToken],
  )

  const selectZapToken = useCallback(
    (currency: Currency) => {
      const token = currency.wrapped
      if (selectedToken.address === token.address) {
        setSelectedToken(selectedZapToken)
      }
      setSelectedZapToken(token)
      setZapTokenSelectOpen(false)
      setZapInputValue('')
    },
    [setZapTokenSelectOpen, selectedToken, selectedZapToken],
  )

  const showGmExplainer = useMemo(() => {
    return (
      (selectedToken.name?.substring(0, 2) === 'GM' && cleanCurrencySymbol(selectedToken) !== 'GMX') ||
      (isZapActivated &&
        selectedZapToken.name?.substring(0, 2) === 'GM' &&
        cleanCurrencySymbol(selectedZapToken) !== 'GMX')
    )
  }, [isZapActivated, selectedToken, selectedZapToken])

  const gmFeeError = useMemo(() => {
    return showGmExplainer && userEthBalance && userEthBalance.lessThan(new Fraction(1, 1000))
  }, [showGmExplainer, userEthBalance])

  /*useEffect(() => {
    if (restrictedList && restrictedList?.length > 0 && isZapActivated) {
      if (!restrictedList.some(token => token.address === selectedToken.address)) {
        setSelectedToken(restrictedList[0])
      }
    }
  }, [restrictedList, isZapActivated, selectedToken])*/

  useEffect(() => {
    setSelectedToken(tokens[0])
    setSelectedZapToken(tokens[1])
  }, [chainId, tokens])

  const updateAdvancedInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const re = /^\d*(\.\d*)?$/ // Only allow numbers and a single decimal point

      if (e.target.value === '' || re.test(e.target.value)) {
        setZapInputValue(e.target.value)
        setZapStage(firstStage)
      }
    },
    [setZapInputValue, firstStage],
  )

  const updateInput = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const re = /^\d*(\.\d*)?$/ //Only allow numbers and a single decimal point

    if (e.target.value === '' || re.test(e.target.value)) {
      setInputValue(e.target.value)
    }
  }, [])

  const inputAmount = useMemo(() => {
    return tryParseAmount(inputValue, selectedToken)
  }, [inputValue, selectedToken])

  const selectedTokenIsCollateralOnly = !!selectedSpecialAsset?.isIsolationMode
  const zapZeroAmount = useMemo(() => {
    return tryParseAmount(
      '0',
      !selectedZapTokenIsolationMode && selectedTokenIsCollateralOnly ? selectedToken : selectedZapToken,
    )
  }, [selectedZapToken, selectedToken, selectedZapTokenIsolationMode, selectedTokenIsCollateralOnly])

  const bothIso = useMemo(() => {
    return selectedZapTokenIsolationMode && selectedTokenIsCollateralOnly
  }, [selectedZapTokenIsolationMode, selectedTokenIsCollateralOnly])

  const vaultLoading = useMemo(() => {
    return selectedZapTokenIsolationMode && zapStage === ZapStage.ZAP && !isolationModeUserVaultAddress
  }, [selectedZapTokenIsolationMode, zapStage, isolationModeUserVaultAddress])

  useEffect(() => {
    if (!inputAmount) {
      return
    }
    setTransactionPendingText(
      `Opening new borrow position with ${inputAmount.toSignificant(6, FORMATTER)} ${cleanCurrencySymbol(
        inputAmount.currency,
      )} as collateral`,
    )
  }, [inputAmount, setTransactionPendingText])

  const isInsufficientBalance = useMemo(() => {
    if (!inputAmount) {
      return true
    }

    const balance = dolomiteBalanceData[inputAmount.currency.address]
    return !balance || inputAmount.greaterThan(balance)
  }, [dolomiteBalanceData, inputAmount])

  const { data: totalBorrowPositionCount } = useUserLifetimeBorrowPositionCount(account)
  const nonce = totalBorrowPositionCount?.toFixed(0) ?? '0'
  const positionId = useMemo(() => {
    return JSBI.BigInt(
      ethers.utils.solidityKeccak256(
        ['address', 'uint256', 'uint256'],
        [account ?? ZERO_ADDRESS, nonce, RANDOM_NUMBER],
      ),
    )
  }, [account, nonce])

  const isTransactionSuccessful = useIsTransactionSuccessful(txHash)
  useEffect(() => {
    if (isTransactionSuccessful === false && zapStage === ZapStage.ZAP) {
      setZapStage(prev => prev - 1)
      setTxHash(undefined)
    }
  }, [isTransactionSuccessful, zapStage, setTxHash])

  const defaultMarginAccount = useDefaultMarginAccount()
  const { callback: openPositionCallback, error: openPositionCallbackError } = useOpenBorrowPosition(
    defaultMarginAccount.accountNumber,
    positionId,
    inputAmount,
    BalanceCheckFlag.FromAccount,
  )
  const addCollateralToNewPosition = useCallback(() => {
    if (!openPositionCallback) {
      console.error('No open position callback due to error:', openPositionCallbackError)
      return
    }

    setIsAttemptingTx(true)
    setIsDialogOpen(true)
    setSelectedTokenForTransactionConfirmation(selectedToken)

    openPositionCallback()
      .then(({ positionId, transactionHash }) => {
        setTxHash(transactionHash)
        setIsAttemptingTx(false)
        setInputValue('')
        ReactGA.event({
          category: 'Borrow Position',
          action: 'Open borrow position',
          label: [cleanCurrencySymbol(inputAmount?.currency)].join('/'),
        })
        addOpeningPosition(positionId)
      })
      .catch(e => {
        if (e.message.includes('insufficient funds for gas')) {
          setErrorMessage('Insufficient funds to pay gas fee to open transaction.')
          setBorrowModalOpen(true)
        } else {
          console.error(e.message)
        }
        setIsDialogOpen(false)
        setIsAttemptingTx(false)
      })
  }, [
    openPositionCallback,
    openPositionCallbackError,
    setIsAttemptingTx,
    setIsDialogOpen,
    setSelectedTokenForTransactionConfirmation,
    selectedToken,
    setTxHash,
    addOpeningPosition,
    inputAmount,
  ])

  const { callback: openZapPositionCallback } = useOpenBorrowPosition(
    defaultMarginAccount.accountNumber,
    positionId,
    zapZeroAmount,
    BalanceCheckFlag.FromAccount,
  )

  /*const openZapPosition = useCallback(() => {
    if (!openZapPositionCallback) {
      console.error('No open position callback')
      return
    }

    setNonce(prev => prev + 1)
    setIsAttemptingTx(true)
    setSelectedTokenForTransactionConfirmation(selectedToken)

    openZapPositionCallback()
      .then(({ positionId, transactionHash }) => {
        setPositionId(positionId)
        setTxHash(transactionHash)
        setIsAttemptingTx(false)
        ReactGA.event({
          category: 'Borrow Position',
          action: 'Open borrow position',
          label: [cleanCurrencySymbol(zapZeroAmount?.currency)].join('/'),
        })
        addOpeningPosition(positionId)
        setZapStage(ZapStage.ZAP)
      })
      .catch(e => {
        if (e.message === 'insufficient-gas' || e.message.includes('insufficient funds for gas')) {
          setErrorMessage('Insufficient ETH balance for gas')
          // TODO display error in the interface's dialogue box and don't close it
        } else if (e.message !== 'transaction-rejected') {
          console.error('Caught error in web3 callback:', e)
          setErrorMessage('Zap failed')
          // TODO display error in the interface's dialogue box and don't close it
        }
        setIsAttemptingTx(false)
      })
  }, [
    openZapPositionCallback,
    setIsAttemptingTx,
    setSelectedTokenForTransactionConfirmation,
    selectedToken,
    setTxHash,
    addOpeningPosition,
    zapZeroAmount,
  ])*/

  const selectedTokenDolomiteBalance = useMemo(() => {
    return dolomiteBalanceData[selectedToken.address]
  }, [dolomiteBalanceData, selectedToken])

  const parsedInputValue = useMemo(() => tryParseAmount(inputValue, selectedToken), [inputValue, selectedToken])
  const [inputFiatValueAmount] = useFiatValueWithLoadingIndicator(parsedInputValue, selectedToken)

  const result = selectedSpecialAsset?.isolationModeInfo?.calculateExpirationTimestamp?.()
  const isValidExpirationTimestamp = result?.isValid
  const expirationTimestamp = result?.expirationTimestamp
  const { data: lifetimeData } = useUserLifetimeDataOnAllNetworks(account)
  const numberOfPositions = lifetimeData?.totalBorrowPositionCount
  const showDisclaimer = !(numberOfPositions?.greaterThan(3) ?? false)
  const zapResult = selectedZapSpecialAsset?.isolationModeInfo?.calculateExpirationTimestamp?.()
  const isValidZapExpirationTimestamp = zapResult?.isValid
  const zapExpirationTimestamp = zapResult?.expirationTimestamp
  const expirationExplainerVisible = isZapActivated
    ? !!zapExpirationTimestamp && isValidZapExpirationTimestamp !== undefined && isValidZapExpirationTimestamp
    : !!expirationTimestamp && isValidExpirationTimestamp !== undefined && isValidExpirationTimestamp
  const assetExpiredVisible = isZapActivated
    ? !!zapExpirationTimestamp && isValidZapExpirationTimestamp !== undefined && !isValidZapExpirationTimestamp
    : !!expirationTimestamp && isValidExpirationTimestamp !== undefined && !isValidExpirationTimestamp
  const tall = true /*isZapActivated
    ? selectedZapTokenIsolationMode || !!zapExpirationTimestamp
    : isGeoRestricted || selectedTokenIsCollateralOnly || !!expirationTimestamp*/
  const extraTall = isZapActivated
    ? selectedZapTokenIsolationMode && !!zapExpirationTimestamp && isValidZapExpirationTimestamp !== undefined
    : (selectedTokenIsCollateralOnly && !!expirationTimestamp && isValidExpirationTimestamp !== undefined) ||
      showGmExplainer
  // const { outputs: zaps, error: zapError } = useGetZapExactTokensForTokensParams(
  //   parsedInputValue,
  //   selectedZapToken,
  //   isZapActivated,
  //   zapRefreshIncrementor,
  // )
  // let zaps: ZapOutputParam[] | undefined
  // const zapError: string | undefined = ''

  // const bestZap = useMemo(() => zaps?.[0], [zaps])
  /*const isLoadingZapTradeData =
    isZapActivated &&
    !!selectedZapToken &&
    !!inputValue &&
    !bestZap &&
    !!parsedInputValue &&
    parsedInputValue.greaterThan(0)*/
  /*useEffect(() => {
    if (bestZap && selectedZapToken) {
      const expectedAmountOut = CurrencyAmount.fromRawAmount(selectedZapToken, bestZap.expectedAmountOut.toFixed(0))
      setZapInputValue(expectedAmountOut.toFixed(selectedZapToken.decimals))
    } else {
      setZapInputValue('')
    }
  }, [bestZap, selectedZapToken])*/
  const refreshZapData = useCallback(() => {
    setZapRefreshIncrementor(zapRefreshIncrementor + 1)
  }, [zapRefreshIncrementor])

  const parsedZapInputValue = useMemo(() => {
    const rawParsedZapAmount = tryParseAmount(zapInputValue, selectedZapToken)
    if (!rawParsedZapAmount) {
      return undefined
    }
    return rawParsedZapAmount
  }, [zapInputValue, selectedZapToken])
  /*const borrowId = useMemo(() => (newPositionId ? JSBI.BigInt(newPositionId.split('-')[1]) : undefined), [
    newPositionId,
  ])*/
  // const defaultAccountNumber = useDefaultMarginAccount().accountNumber
  // TODO: remove for new zap hook
  /*const { callback: submitZap, error: submitZapError } = useZapExactTokensForTokensOLD(
    selectedSpecialAsset ? defaultAccountNumber : borrowId,
    selectedSpecialAsset ? borrowId : defaultAccountNumber,
    zaps,
    selectedSpecialAsset ? selectedSpecialAsset : selectedZapSpecialAsset,
    selectedSpecialAsset ? ExtraZapInfo.ZapAndRemoveZapOutput : ExtraZapInfo.AddCollateralAndZapInput,
    false, // isMaxSelected
    ZapEventType.OpenBorrowPosition,
    false,
  )

  const handleZap = useCallback(async () => {
    if (!submitZap || !parsedInputValue || !parsedZapInputValue) {
      console.error('Callback is not defined for submitting borrow action:', submitZapError)
      return
    }

    const amount = formatAmount(parsedInputValue)
    const unwrappedSymbol = cleanCurrencySymbol(parsedInputValue.currency)
    const unwrappedZapSymbol = cleanCurrencySymbol(parsedZapInputValue.currency)
    const zapAmount = formatAmount(parsedZapInputValue)
    const actionText = `Zap ${amount} ${unwrappedSymbol} to ~${zapAmount} ${unwrappedZapSymbol} and deposit into borrow position`
    setSelectedTokenForTransactionConfirmation(parsedInputValue.currency)
    setIsAttemptingTx(true)
    setTransactionPendingText(actionText)

    submitZap()
      .then(txHash => {
        setTxHash(txHash)
        setPendingHash(txHash)
        setIsAttemptingTx(false)
        ReactGA.event({
          category: 'Borrow Position',
          action: actionText,
        })
        setZapStage(ZapStage.COMPLETE)
      })
      .catch(e => {
        if (e.message === 'insufficient-gas' || e.message.includes('insufficient funds for gas')) {
          setErrorMessage('Insufficient ETH balance for gas')
          // TODO display error in the interface's dialogue box and don't close it
        } else if (
          e.message.includes('Cannot execute when paused') ||
          e.message.includes('Cannot lever up when paused')
        ) {
          setErrorMessage('Pause sentinel active')
        } else if (e.message !== 'transaction-rejected') {
          console.error('Caught error in web3 callback:', e)
          setErrorMessage('Zap failed')
          // TODO display error in the interface's dialogue box and don't close it
        }
        setIsAttemptingTx(false)
      })
  }, [
    parsedInputValue,
    parsedZapInputValue,
    setSelectedTokenForTransactionConfirmation,
    submitZap,
    submitZapError,
    setIsAttemptingTx,
    setTransactionPendingText,
    setTxHash,
  ])*/

  const createVault = useCallback(async () => {
    if (!createVaultCallback || !parsedZapInputValue) {
      console.error('Callback is not defined for submitting borrow action:', createVaultError)
      return
    }
    const symbol = cleanCurrencySymbol(selectedZapToken)
    const actionText = `Creating ${symbol} vault`
    setIsAttemptingTx(true)
    createVaultCallback()
      .then(txHash => {
        setTxHash(txHash)
        setPendingHash(txHash)
        setIsAttemptingTx(false)
        ReactGA.event({
          category: 'Borrow Position',
          action: actionText,
        })
        setZapStage(ZapStage.ZAP)
      })
      .catch(e => {
        if (e.message === 'insufficient-gas' || e.message.includes('insufficient funds for gas')) {
          setErrorMessage('Insufficient ETH balance for gas')
          // TODO display error in the interface's dialogue box and don't close it
        } else if (e.message !== 'transaction-rejected') {
          console.error('Caught error in web3 callback:', e)
          setErrorMessage('Zap failed')
          // TODO display error in the interface's dialogue box and don't close it
        }
        setIsAttemptingTx(false)
      })
  }, [
    createVaultCallback,
    createVaultError,
    setTxHash,
    setPendingHash,
    setIsAttemptingTx,
    setErrorMessage,
    setZapStage,
    parsedZapInputValue,
    selectedZapToken,
  ])

  const openBorrowModal = useCallback(() => {
    setErrorMessage(undefined)
    setBorrowModalOpen(true)
    if (zapStage === ZapStage.COMPLETE) {
      setZapStage(ZapStage.ZAP)
    }
  }, [zapStage, setZapStage])

  const handleSubmit = useCallback(() => {
    if (isZapActivated) {
      openBorrowModal()
    } else {
      addCollateralToNewPosition()
    }
  }, [isZapActivated, addCollateralToNewPosition, openBorrowModal])

  const onConfirm = useCallback(() => {
    if (zapStage === ZapStage.VAULT) {
      createVault()
      /*} else if (zapStage === ZapStage.ZAP) {
      handleZap()*/
    } else {
      setBorrowModalOpen(false)
      setExpanded(false)
      setInputValue('')
      setIsZapActivated(false)
    }
  }, [/*handleZap,*/ zapStage, createVault])

  const onDismiss = useCallback(() => {
    if (zapStage === ZapStage.COMPLETE) {
      setExpanded(false)
      setInputValue('')
      setIsZapActivated(false)
    }
    setBorrowModalOpen(false)
  }, [zapStage])

  const isIsolationToIsolationError = useMemo(() => {
    return (
      isZapActivated &&
      !!getSpecialAsset(chainId, selectedToken)?.isIsolationMode &&
      !!getSpecialAsset(chainId, selectedZapToken)?.isIsolationMode
    )
  }, [chainId, isZapActivated, selectedToken, selectedZapToken])

  const insufficientLiquidityError = useMemo(() => {
    if (isZapActivated) {
      const protocolBalance = protocolBalanceInfoMap[selectedToken.address ?? '']
      if (!parsedInputValue || !protocolBalance || selectedSpecialAsset?.isIsolationMode) {
        return false
      }
      return protocolBalance.lessThan(parsedInputValue)
    }
    return false
  }, [isZapActivated, parsedInputValue, protocolBalanceInfoMap, selectedToken, selectedSpecialAsset?.isIsolationMode])

  const showSupplyCapError = useMemo(() => {
    if (isZapActivated) {
      const totalSupplyWei = marketTotalWeiMap[selectedZapToken.address ?? '']
      const supplyMaxWei = marketRiskInfoMap[selectedZapToken.address ?? '']?.supplyMaxWei
      if (!supplyMaxWei || !totalSupplyWei || supplyMaxWei.equalTo(ZERO_FRACTION) || !parsedZapInputValue) {
        return false
      }
      return totalSupplyWei.supplyWei.add(parsedZapInputValue).greaterThan(supplyMaxWei)
    }

    return false
  }, [parsedZapInputValue, marketRiskInfoMap, marketTotalWeiMap, selectedZapToken, isZapActivated])

  const decimalsExceeded = useMemo(() => {
    return (inputValue.split('.')[1]?.length ?? 0) > (selectedToken.decimals ?? 0)
  }, [inputValue, selectedToken])

  /*useEffect(() => {
    if (zapError?.includes('slippageTolerance')) {
      setZapErrorMessage(t('zapSlippageError'))
    }
  }, [zapError, t])

  const displayErrorMessage = !isLoadingZapTradeData && !!parsedInputValue && !!errorMessage*/

  return (
    <NewBorrowWrapper
      expanded={expanded}
      isZapActivated={isZapActivated}
      showTradeDetails={false /*!isIsolationToIsolationError && (!!bestZap || isLoadingZapTradeData)*/}
      tall={tall}
      extraTall={extraTall}
      showDisclaimer={showDisclaimer}
      displayingTokenSelection={tokenSelectOpen}
      isIsolationToIsolationError={isIsolationToIsolationError}
      onClick={() => !expanded && setExpanded(true)}
      ref={wrapperRef}
    >
      <AddIconWrapper expanded={expanded}>
        <AddCircleIcon />
      </AddIconWrapper>
      <OpenText expanded={expanded}>{t('openNewBorrowPosition')}</OpenText>
      {expanded && (
        <>
          <DescriptionText expanded={expanded} visible={true}>
            {t('startByAddingCollateral')}
          </DescriptionText>
          <IsolationModeExplainer expanded={expanded} visible={true} openText />
          <GeoRestrictionExplainer expanded={expanded} visible={!isZapActivated && isGeoRestricted} />
          <ExpirationExplainer
            expanded={expanded}
            visible={expirationExplainerVisible}
            expiration={isZapActivated ? zapExpirationTimestamp : expirationTimestamp}
          />
          <AssetExpiredExplainer
            expanded={expanded}
            visible={assetExpiredVisible}
            asset={isZapActivated ? selectedZapToken : selectedToken}
          />
          <GmFeeExplainer expanded={expanded} visible={showGmExplainer} />
          <IsoToIsoError
            visible={isIsolationToIsolationError}
            expanded={expanded}
            extraSpace={expirationExplainerVisible}
          />
          <InputWrapper
            expanded={expanded}
            tall={tall}
            extraTall={extraTall}
            isoToIsoError={isIsolationToIsolationError}
          >
            <BalanceRow>
              <BalanceWrapper>
                {t('dolomiteBalanceText')}{' '}
                <Balance>
                  {formatAmount(selectedTokenDolomiteBalance)} {` `}
                  {cleanCurrencySymbol(selectedToken) ?? '-'}
                </Balance>
              </BalanceWrapper>
              <MaxButton
                onClick={() =>
                  setInputValue(
                    selectedTokenDolomiteBalance?.toFixed(selectedTokenDolomiteBalance.currency.decimals ?? 6) ?? '0',
                  )
                }
              >
                Max
              </MaxButton>
            </BalanceRow>
            <InputOverflowFix>
              <StyledInput
                onChange={updateInput}
                multiline={false}
                fullWidth
                spellCheck={false}
                placeholder={'0.00'}
                value={inputValue}
                variant='amountInput'
                disableUnderline={true}
                endAdornment={''}
                disabled={isGeoRestricted || isAttemptingTx || isTxPending}
              />
            </InputOverflowFix>
            <TokenSelector
              expanded={tokenSelectOpen}
              onClick={() => !tokenSelectOpen && !isAttemptingTx && setTokenSelectOpen(true)}
              ref={wrapperRef}
            >
              <TokenSelectRow onClick={() => selectToken(selectedToken)}>
                {cleanCurrencySymbol(selectedToken)}
              </TokenSelectRow>
              <ArrowDown flipped={tokenSelectOpen} />
            </TokenSelector>
          </InputWrapper>
          {/*<AdvancedToggle>*/}
          {/*  <Checkbox*/}
          {/*    onClick={() => {*/}
          {/*      setInputValue('')*/}
          {/*      setZapInputValue('')*/}
          {/*      setIsZapActivated(!isZapActivated)*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    <Checked isChecked={isZapActivated} />*/}
          {/*  </Checkbox>*/}
          {/*  <ZapWrapper>*/}
          {/*    <BoltIcon /> ZAP*/}
          {/*  </ZapWrapper>*/}
          {/*  {`- ${t('tradeAndAdd')}`}*/}
          {/*  <TooltipResizer>*/}
          {/*    <StyledTooltipWithIcon tooltipText={t('tradeAndAddTooltip')} />*/}
          {/*  </TooltipResizer>*/}
          {/*</AdvancedToggle>*/}
          <BottomRowWrapper expanded={expanded} showDisclaimer={showDisclaimer}>
            {/*<AdvancedSection
              expanded={isZapActivated}
              displayingTokenSelection={zapTokenSelectOpen}
              showTradeDetails={!isIsolationToIsolationError && (!!bestZap || isLoadingZapTradeData)}
              open={true}
            >
              <ZapInputWrapper>
                <InputOverflowFix>
                  <StyledInput
                    onChange={updateAdvancedInput}
                    multiline={false}
                    fullWidth
                    spellCheck={false}
                    placeholder={'0.00'}
                    value={zapInputValue}
                    variant={'amountInput'}
                    disableUnderline={true}
                    endAdornment={''}
                    disabled={true /*!selectedAdvancedToken || isTxPending || isAttemptingTx*/}
            {/*/>
                </InputOverflowFix>
                <TokenSelector
                  expanded={zapTokenSelectOpen}
                  onClick={() => !zapTokenSelectOpen && selectedZapToken && setZapTokenSelectOpen(true)}
                >
                  <TokenSelectRow>{cleanCurrencySymbol(selectedZapToken)}</TokenSelectRow>
                  <ArrowDown flipped={false} />
                </TokenSelector>
              </ZapInputWrapper>
              <AdvancedDescription
                manageType={ManageType.COLLATERAL}
                selectedTab={CollateralActionType.DEPOSIT}
                asset1={cleanCurrencySymbol(selectedToken)}
                amount1={formatAmount(parsedInputValue)}
                asset2={cleanCurrencySymbol(selectedZapToken)}
                amount2={formatAmount(parsedZapInputValue)}
              />
              {!isIsolationToIsolationError && (bestZap || isLoadingZapTradeData) && (
                <DetailsWrapper>
                  <BorrowTradeDetails
                    bestZap={bestZap}
                    isLoading={!bestZap && isLoadingZapTradeData}
                    refreshZapData={refreshZapData}
                    showError={!!zapError}
                    priceImpact={bestZap?.priceImpact}
                    open={true}
                  />
                </DetailsWrapper>
              )}
              <ErrorMessage isVisible={displayErrorMessage || !!zapErrorMessage} tall={!!zapErrorMessage}>
                <ErrorText>{!!errorMessage || !!zapErrorMessage || ' '}</ErrorText>
              </ErrorMessage>
            </AdvancedSection>*/}
            <DepositedButtonWrapper activated={isZapActivated}>
              <DepositedValuesWrapper>
                <DepositedCrypto>
                  {formatAmount(parsedInputValue)}
                  {` `}
                  {cleanCurrencySymbol(selectedToken) ?? '-'}
                </DepositedCrypto>
                <DepositedUSD>{formatAmount(inputFiatValueAmount, 2, true, '$0.00', true)}</DepositedUSD>
              </DepositedValuesWrapper>
              <StyledTooltip
                placement='top'
                title={
                  isGeoRestricted
                    ? 'Asset not available in your region'
                    : isAttemptingTx
                    ? 'Awaiting signature...'
                    : bothIso
                    ? 'Cannot zap an isolation mode asset to another isolation mode asset'
                    : isTxPending
                    ? 'Awaiting confirmation...'
                    : !inputValue
                    ? 'No amount entered'
                    : decimalsExceeded
                    ? 'Too many numbers after decimal'
                    : isInsufficientBalance
                    ? 'Insufficient balance'
                    : showSupplyCapError
                    ? 'Supply cap exceeded'
                    : insufficientLiquidityError
                    ? 'Insufficient supply liquidity for token'
                    : // : isLoadingZapTradeData
                    // ? 'Trade Loading'
                    gmFeeError
                    ? 'Insufficient ETH in wallet for GM fee'
                    : 'Open borrow'
                }
                hideTooltip={
                  !isAttemptingTx &&
                  !isTxPending &&
                  !!inputValue &&
                  !isInsufficientBalance &&
                  // !isLoadingZapTradeData &&
                  !insufficientLiquidityError &&
                  !gmFeeError &&
                  !showSupplyCapError &&
                  !decimalsExceeded &&
                  !bothIso
                }
              >
                <SubmitButtonWrapper expanded={expanded}>
                  <SubmitButton
                    disabled={
                      isGeoRestricted ||
                      isAttemptingTx ||
                      isTxPending ||
                      !inputValue ||
                      isInsufficientBalance ||
                      // isLoadingZapTradeData ||
                      isIsolationToIsolationError ||
                      insufficientLiquidityError ||
                      !!gmFeeError ||
                      showSupplyCapError ||
                      decimalsExceeded ||
                      bothIso
                    }
                    onClick={handleSubmit}
                  >
                    {isAttemptingTx || isTxPending ? (
                      <CircularProgress />
                    ) : isZapActivated ? (
                      <ZapButtonWrapper>
                        <BoltIcon /> ZAP
                      </ZapButtonWrapper>
                    ) : (
                      t('add')
                    )}
                  </SubmitButton>
                </SubmitButtonWrapper>
              </StyledTooltip>
            </DepositedButtonWrapper>
          </BottomRowWrapper>
          {showDisclaimer && (
            <DisclaimerWrapper expanded={expanded}>
              Please note that this loan is supplied by one or more third-parties through an open protocol, and is not
              provided or endorsed by Dolomite Ltd. or any of its or the Dolomite protocol&apos;s affiliates, as
              acknowledged and agreed in the{' '}
              <a href={'https://dolomite.io/Dolomite-Terms-of-Service.pdf'} target={'_blank'} rel={'noreferrer'}>
                TOS
              </a>
              .
            </DisclaimerWrapper>
          )}
          {!(zapTokenSelectOpen || tokenSelectOpen) ? null : (
            <CurrencyModal
              tokens={modalTokens}
              balances={dolomiteBalanceData}
              fiatBalances={dolomiteFiatBalanceMap}
              isOpen={zapTokenSelectOpen || tokenSelectOpen}
              onDismiss={zapTokenSelectOpen ? closeZapModal : closeModal}
              currencySelect={zapTokenSelectOpen ? selectZapToken : selectToken}
              balanceTitle={t('dolomiteBalance')}
              borrow
            />
          )}
          <ConfirmBorrowModal
            token0={selectedToken}
            token1={selectedZapToken}
            vaultStep={selectedZapTokenIsolationMode}
            amount0={parsedInputValue}
            amount1={parsedZapInputValue}
            fiatAmount={inputFiatValueAmount}
            isOpen={borrowModalOpen}
            onConfirm={onConfirm}
            modalStage={zapStage}
            loading={false /*isLoadingZapTradeData*/}
            onDismiss={onDismiss}
            attemptingTxn={isAttemptingTx || vaultLoading}
            txHash={pendingHash}
            errorMessage={errorMessage}
          />
        </>
      )}
    </NewBorrowWrapper>
  )
}

export default React.memo(OpenNewBorrow, OpenNewBorrowComparator)
