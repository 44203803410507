// noinspection PointlessBooleanExpressionJS

import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import {
  Bottom,
  ColumnText,
  ColumnTitles,
  ConnectBtn,
  ConnectWalletButton,
  DataRows,
  EtherscanLink,
  ExpandedTitle,
  ExpandTrade,
  MarginRowWrapper,
  NoTrades,
} from './styled'
import Web3Status from '../../components/Web3Status'
import { getEtherscanLink } from '../../utils'
import cleanCurrencySymbol from '../../utils/cleanCurrencySymbol'
import toDate from '../../utils/toDate'
import { CurrencyAmount, Token } from '@dolomite-exchange/v2-sdk'
import { Trade, useTradeDataByWallet } from '../../types/tradeData'
import { Transfer, useCombinedTransfersByMarginAccount } from '../../types/transferData'
import {
  MarginPosition,
  MarginPositionStatus,
  MarginPositionStatusUtil,
  MarginPositionType,
  useMarginPositionsByWalletAddressData,
} from '../../types/marginPositionData'
import Column from '../../components/Orders/Column'
import DataRow from '../../components/Orders/DataRow'
import { useActiveWeb3React } from '../../hooks'
import { TableLoader } from '../Loader'
import { ZERO_ADDRESS } from '../../constants'
import { formatAmount } from '../../utils/formatAmount'
import { PageSizeContext } from '../../pages/App'

const MobileTradeWrapper = styled.div`
  height: fit-content;
  overflow: hidden;
  margin-top: -5px;
`

const TradeWrapper = styled.div`
  width: 100%;
  height: fit-content;
  overflow: hidden;
`

const TradePrice = styled.div`
  color: ${({ theme }) => theme.text1};
  display: inline-block;
  float: left;
`

const TransactionWrapper = styled.div`
  display: inline-block;
  /*float: right;*/
  margin-left: 20px;
`

const MobileMarginRowWrapper = styled.div<{ expanded: boolean; isFaded?: boolean }>`
  width: 100%;
  height: fit-content;
  overflow: hidden;
  /*cursor: pointer;*/
  ${({ theme, expanded }) => (expanded ? `background-color: ${theme.bg2};` : '')}
  padding-top: 10px;
  padding: 8px 20px 8px;

  ${({ isFaded }) => isFaded && 'opacity: 0.6;'}
  > div {
    height: ${({ expanded }) => (expanded ? '72px;' : '34px;')};
    transition: height 0.2s ease-in-out;

    > div:nth-child(2) {
      ${({ expanded }) => (expanded ? 'opacity: 1;' : 'opacity: 0;')}
      transition: opacity 0.2s ease-in-out;
    }
  }

  :hover {
    background-color: ${({ theme }) => theme.bg2};
  }
`

const MarginRowInner = styled.div`
  overflow: hidden;
`

const Top = styled.div`
  width: 100%;
  height: 36px;
  display: flex;
  justify-content: space-between;
`

const MarketSection = styled.div`
  width: 45%;
  font-size: 10px;
`

const Market = styled.div`
  width: 100%;
  font-weight: 200;
  height: 16px;
  line-height: 16px;
  font-size: 12px;
  margin-top: 2px;

  @media (max-width: 345px) {
    font-size: 11px;
  }
`

const Type = styled.span<{ type: MarginPositionType }>`
  font-weight: 700;
  color: ${({ theme, type }) => {
    if (type === MarginPositionType.LONG) {
      return theme.green1
    } else if (type === MarginPositionType.SHORT) {
      return theme.red1
    } else {
      return theme.yellow1
    }
  }};
`

const ProfitLossWrapper = styled.div`
  width: 23%;
`

const ProfitLoss = styled.div<{ isPositive: boolean | null }>`
  width: fit-content;
  background-color: ${({ theme, isPositive }) => {
    return isPositive === null ? theme.text2 : isPositive ? theme.green1 : theme.red1
  }};
  border-radius: 5px;
  color: white;
  font-weight: 700;
  padding: 4px 8px;
  font-size: 12px;
  margin-top: 5px;
  /*background: linear-gradient(340deg, rgba(115, 157, 60, 1) 0%, rgba(143, 201, 66, 1) 45%, rgba(170, 236, 83, 1) 100%);*/

  @media (max-width: 345px) {
    padding: 4px 5px;
    font-size: 11px;
  }
`

const LiquidationWrapper = styled.div`
  width: 33%;
  height: 36px;
  line-height: 36px;
  text-align: right;
`

const Liquidation = styled.div`
  width: fit-content;
  height: 100%;
  font-weight: 600;
  font-size: 12px;
  display: inline-block;
  vertical-align: top;

  @media (max-width: 345px) {
    font-size: 11px;
  }
`

const MobileBottom = styled.div`
  width: 100%;
  height: 40px;
  font-size: 11px;
  display: flex;
  justify-content: space-between;
  margin-top: 3px;
`

const BottomSizeEquity = styled.div`
  width: 50%;
`

const BottomOpenExpiry = styled.div`
  width: 50%;
`

const BottomLeft = styled.div`
  width: 100%;
  text-align: left;
`

const BottomRight = styled.div`
  width: 100%;
  text-align: right;
`

const BottomLabel = styled.span`
  color: ${({ theme }) => theme.text2};
  margin-right: 3px;
`

const BottomValue = styled.span`
  color: ${({ theme }) => theme.text1};
`

const Unit = styled.span`
  font-weight: 200;
`

const MobileUnit = styled.span`
  color: ${({ theme }) => theme.text3};
`

const openInNewTab = (url: string) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}

const Transaction = ({ hash, t, chainId }: { hash: string; t: (arg0: string) => any; chainId: number }) => (
  <Bottom>
    <ExpandedTitle>{t('txHash')}</ExpandedTitle>
    <EtherscanLink onClick={() => openInNewTab(getEtherscanLink(chainId, hash, 'transaction'))}>
      {`${hash.substring(0, 6)}...${hash.substring(hash.length - 4)}`}
    </EtherscanLink>
  </Bottom>
)

interface TradeRowProps {
  trade: Trade
  index: number
  isMobile: boolean
  isTablet: boolean
  t: (arg0: string) => any
  chainId: number
  altTitle?: string
  enableExpansion?: boolean
  isMargin?: boolean
}

const tradeRowComparator = (prevProps: TradeRowProps, nextProps: TradeRowProps) => {
  return (
    //TODO - have it compare the exact object
    prevProps.trade === nextProps.trade &&
    prevProps.isMobile === nextProps.isMobile &&
    prevProps.isTablet === nextProps.isTablet
  )
}

const TradeRow = React.memo<TradeRowProps>(function WrappedComponent({
  trade,
  index,
  isMobile,
  isTablet,
  t,
  chainId,
  altTitle,
  enableExpansion,
  isMargin,
}: TradeRowProps) {
  const [isPriceToggled, setIsPriceToggled] = useState(false)
  const price = isPriceToggled ? trade.primaryPriceWei.invert() : trade.primaryPriceWei
  const toggledPrice = (price as CurrencyAmount<Token>).toSignificant(8)
  const priceToken = isPriceToggled ? trade.primary : trade.secondary

  return (
    <TradeWrapper>
      <DataRow
        key={`trade-${index}`}
        columns={[
          {
            width: 16,
            tabletWidth: 17,
            textAlign: 'left',
            type: 'action',
            data: altTitle ?? 'Trade',
            date: isMobile ? trade.transaction.timestamp : undefined,
            hideOnMobile: true,
            transactionHash: trade.transaction.transactionHash,
          },
          {
            width: 34,
            tabletWidth: 42,
            mobileWidth: 73,
            textAlign: 'left',
            type: 'trade',
            data: trade.takerDeltaWei,
            token: trade.takerDeltaWei.currency,
            data2: trade.makerDeltaWei,
            token2: trade.makerDeltaWei.currency,
          },
          {
            width: 23,
            tabletWidth: 20,
            textAlign: isMobile && !isTablet ? 'right' : 'left',
            type: 'amountToggleable',
            data: trade.primaryPriceWei,
            data2: trade.primaryPriceWei.invert(),
            token: trade.takerDeltaWei.currency,
            token2: trade.makerDeltaWei.currency,
            hideOnMobile: true,
          },
          {
            width: 10,
            tabletWidth: 20,
            mobileWidth: 27,
            textAlign: 'right',
            type: 'time',
            data: undefined,
            date: trade.transaction.timestamp,
            hideOnMobile: false,
            hideOnTablet: false,
          },
        ]}
        contentHeight={isMargin ? 25 : 36}
        expandedHeight={60}
        expandedContent={
          <MobileTradeWrapper>
            <TradePrice
              onClick={(e: any) => {
                e.stopPropagation()
                setIsPriceToggled(!isPriceToggled)
              }}
            >
              <ExpandedTitle>Unit price:</ExpandedTitle>
              {toggledPrice} <MobileUnit>{cleanCurrencySymbol(priceToken)}</MobileUnit>
            </TradePrice>
            <TransactionWrapper>
              <Transaction hash={trade.transaction.transactionHash.toString()} t={t} chainId={chainId} />
            </TransactionWrapper>
          </MobileTradeWrapper>
        }
        history
        enableExpanding={enableExpansion ?? true}
        isMargin={!!isMargin}
      />
    </TradeWrapper>
  )
},
tradeRowComparator)

function mostRecentMarginTransaction(position: MarginPosition) {
  if (position.status === MarginPositionStatus.Open) {
    return position.openTransaction
  } else {
    return position.closeTransaction
  }
}

interface MarginRowProps {
  position: MarginPosition
  openTrade: Trade | undefined
  closeTrade?: Trade | undefined
  index: number
  isMobile: boolean
  isTablet: boolean
  t: (arg0: string) => any
  chainId: number
}

const marginRowComparator = (prevProps: MarginRowProps, nextProps: MarginRowProps) => {
  return (
    //TODO - have it compare the exact object
    prevProps.position === nextProps.position &&
    prevProps.isMobile === nextProps.isMobile &&
    prevProps.isTablet === nextProps.isTablet
  )
}

const MarginRow = React.memo<MarginRowProps>(function WrappedComponent({
  position,
  openTrade,
  closeTrade,
  index,
  isMobile,
  isTablet,
  t,
  chainId,
}: MarginRowProps) {
  const [expanded, setExpanded] = useState(false)
  const [showTrade, setShowTrade] = useState(false)
  const { data: transfers } = useCombinedTransfersByMarginAccount(position.marginAccount)
  const transaction = mostRecentMarginTransaction(position)
  const wrapperRef = useRef(null)
  const longLeverage =
    position.positionType === MarginPositionType.LONG
      ? position.leverage.toFixed(2)
      : position.leverage.add('1').toFixed(2)
  const shortLeverage =
    position.positionType === MarginPositionType.SHORT
      ? position.leverage.toFixed(2)
      : position.leverage.subtract('1').toFixed(2)

  if (isMobile) {
    return (
      <MobileMarginRowWrapper
        expanded={expanded}
        ref={wrapperRef}
        isFaded={false}
        onClick={() => setExpanded(!expanded)}
      >
        <MarginRowInner>
          <Top>
            <MarketSection>
              <Market>
                <Type type={MarginPositionType.LONG}>LONG</Type> {cleanCurrencySymbol(position.heldToken)} (
                {longLeverage}
                x)
              </Market>
              <Market>
                <Type type={MarginPositionType.SHORT}>SHORT</Type>{' '}
                {position.heldToken.symbol === position.market.primaryToken.symbol
                  ? cleanCurrencySymbol(position.market.secondaryToken)
                  : cleanCurrencySymbol(position.market.primaryToken)}{' '}
                ({shortLeverage}x)
              </Market>
            </MarketSection>
            <ProfitLossWrapper>
              <ProfitLoss isPositive={position.profitPercentage.greaterThanOrEqual('0')}>
                {position.profitPercentage.greaterThanOrEqual('0') ? '+' : ''}
                {position.profitPercentage.toFixed(2)}%
              </ProfitLoss>
            </ProfitLossWrapper>
            <LiquidationWrapper>
              <Liquidation>
                {position.status === MarginPositionStatus.Closed ||
                position.status === MarginPositionStatus.Expired ||
                position.status === MarginPositionStatus.Liquidated ? (
                  position.status
                ) : (
                  <>
                    {formatAmount(position.liquidationPrice)} <Unit>{position.market.secondary}</Unit>
                  </>
                )}
              </Liquidation>
            </LiquidationWrapper>
          </Top>
          <MobileBottom>
            <BottomSizeEquity>
              <BottomLeft>
                <BottomLabel>Size:</BottomLabel>
                <BottomValue>
                  {formatAmount(position.heldAmount)} {cleanCurrencySymbol(position.heldToken)}
                </BottomValue>
              </BottomLeft>
              <BottomLeft>
                <BottomLabel>Equity:</BottomLabel>
                <BottomValue>
                  {formatAmount(position.equityHeldAmount)} {cleanCurrencySymbol(position.heldToken)}
                </BottomValue>
              </BottomLeft>
            </BottomSizeEquity>
            <BottomOpenExpiry>
              <BottomRight>
                <BottomLabel>Open:</BottomLabel>
                <BottomValue>
                  {formatAmount(position.openPrice)} {cleanCurrencySymbol(position.market.primaryToken)}
                </BottomValue>
              </BottomRight>
              <BottomRight>
                <BottomLabel>Expires:</BottomLabel>
                <BottomValue>
                  {position.status === MarginPositionStatus.Open
                    ? position.expirationTimestamp
                      ? toDate(position.expirationTimestamp)
                      : '-'
                    : MarginPositionStatusUtil.toUiString(position.status)}
                </BottomValue>
              </BottomRight>
              <BottomValue>
                {formatAmount(position.openPriceUSD)} {position.market.secondary}
              </BottomValue>
            </BottomOpenExpiry>
          </MobileBottom>
        </MarginRowInner>
      </MobileMarginRowWrapper>
    )
  } else {
    return (
      <MarginRowWrapper
        showTrade={showTrade}
        hasCloseTransaction={!!closeTrade || !!position.closeTransaction}
        onClick={() => setShowTrade(!showTrade)}
      >
        <DataRow
          key={index}
          columns={[
            {
              width: 16,
              tabletWidth: 17,
              mobileWidth: 18,
              textAlign: 'left',
              type: 'action',
              data: 'Margin',
              date: isMobile ? transaction?.timestamp : undefined,
              hideOnMobile: true,
            },
            {
              width: 34,
              tabletWidth: 42,
              mobileWidth: 55,
              textAlign: 'left',
              type: 'marginAmount',
              data: position.marginDeposit,
              token: position.heldToken,
              data2: position.leverage,
              otherData: position.positionType,
            },
            {
              width: 23,
              tabletWidth: 20,
              mobileWidth: 27,
              textAlign: isMobile && !isTablet ? 'right' : 'left',
              type: 'pnlWithAmount',
              data: position.profitPercentage,
              data2: position.equityHeldAmount.greaterThan('0')
                ? position.equityHeldAmount.asFraction.subtract(position.marginDeposit.asFraction)
                : position.equityHeldAmount.asFraction,
              token: position.heldToken,
              hideOnMobile: true,
            },
            {
              width: 10,
              tabletWidth: 20,
              mobileWidth: 27,
              textAlign: 'right',
              type: 'time',
              data: undefined,
              date: transaction?.timestamp,
              hideOnMobile: false,
              hideOnTablet: false,
            },
          ]}
          contentHeight={36}
          history
        />
        {openTrade ? (
          <ExpandTrade>
            <TradeRow
              key={`trade-inner-open-${index.toString()}-${openTrade.serialId.toString()}`}
              trade={openTrade}
              index={index}
              isMobile={isMobile}
              isTablet={isTablet}
              t={t}
              chainId={chainId}
              altTitle={'Opened'}
              enableExpansion={false}
              isMargin
            />
            {closeTrade && (
              <TradeRow
                key={`trade-inner-close-${index.toString()}-${closeTrade.serialId.toString()}`}
                trade={closeTrade}
                index={index}
                isMobile={isMobile}
                isTablet={isTablet}
                t={t}
                chainId={chainId}
                altTitle={'Closed'}
                enableExpansion={false}
                isMargin
              />
            )}
            {transfers.map((transfer: Transfer) => (
              <DataRow
                key={`transfer-${index.toString()}-${transfer.serialId}`}
                columns={[
                  {
                    width: 22,
                    mobileWidth: 24,
                    textAlign: 'left',
                    type: /*isMobile ? 'action' : */ 'text',
                    data: transfer.fromAccountAddress === openTrade.makerAccount?.account ? 'Deposit' : 'Withdrawal',
                    //date: isMobile && !isTablet ? transfer.transaction.timestamp : undefined,
                  },
                  {
                    width: 20,
                    mobileWidth: 40,
                    textAlign: 'left',
                    type: 'amount',
                    data: transfer.amount,
                    token: transfer.token,
                  },
                  {
                    width: 20,
                    textAlign: isMobile && !isTablet ? 'right' : 'left',
                    type: 'transaction',
                    data: transfer.transaction.transactionHash,
                    hideOnMobile: true,
                  },
                  {
                    width: 15,
                    mobileWidth: 24,
                    textAlign: 'right',
                    type: 'time',
                    data: undefined,
                    date: transfer.transaction.timestamp,
                  },
                ]}
                enableExpanding={true}
                history
                contentHeight={36}
                expandedHeight={36}
              />
            ))}
            {position.status === MarginPositionStatus.Liquidated && (
              <TradeWrapper>
                <DataRow
                  key={`liquidation-${index.toString()}`}
                  columns={[
                    {
                      width: 16,
                      tabletWidth: 17,
                      textAlign: 'left',
                      type: 'action', //TODO - confirm that I was ok to remove check for mobile (on mobile it would be 'action', else 'text')
                      data: 'Liquidated',
                      date: isMobile ? position.closeTimestamp : undefined,
                      hideOnMobile: false,
                      transactionHash: position.closeTransaction?.transactionHash ?? '-',
                    },
                    {
                      width: 34,
                      tabletWidth: 42,
                      mobileWidth: 73,
                      textAlign: 'left',
                      type: 'trade',
                      data: position.closeHeldAmountWei,
                      token: position.closeHeldAmountWei?.currency,
                      data2: position.closeOwedAmountWei,
                      token2: position.closeOwedAmountWei?.currency,
                    },
                    {
                      width: 23,
                      tabletWidth: 20,
                      textAlign: isMobile && !isTablet ? 'right' : 'left',
                      type: 'amountToggleable',
                      data: position.closePrice,
                      data2: position.closePrice?.invert(),
                      hideOnMobile: true,
                    },
                    {
                      width: 10,
                      tabletWidth: 20,
                      mobileWidth: 27,
                      textAlign: 'right',
                      type: 'time',
                      data: undefined,
                      date: position.closeTimestamp,
                      hideOnMobile: false,
                      hideOnTablet: false,
                    },
                  ]}
                  contentHeight={25}
                  expandedHeight={60} //TODO - expanded content, copy from the original place this code comes from above
                  history
                  isMargin
                />
              </TradeWrapper>
            )}
          </ExpandTrade>
        ) : null}
      </MarginRowWrapper>
    )
  }
},
marginRowComparator)

function isPosition(object: any): object is MarginPosition {
  if (!object) {
    return false
  }

  return 'marginAccount' in object
}

function getTime(trade: Trade | MarginPosition): number {
  let transaction
  if (isPosition(trade)) {
    transaction = mostRecentMarginTransaction(trade)
  } else transaction = trade.transaction
  return transaction?.timestamp.getTime() ?? 0
}

export default function Trades() {
  const { t } = useTranslation()
  const { account, chainId } = useActiveWeb3React()
  const { data: trades, loading: isTradeLoading } = useTradeDataByWallet(account)
  const { data: positions, loading: isPositionLoading } = useMarginPositionsByWalletAddressData(account ?? ZERO_ADDRESS)

  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    isLoading && !isTradeLoading && !isPositionLoading && setIsLoading(false)
  }, [isTradeLoading, isPositionLoading, isLoading])

  const positionHashes = useMemo(
    () =>
      positions.reduce<string[]>((memo, position) => {
        if (position.openTransaction?.transactionHash) {
          memo.push(position.openTransaction.transactionHash)
        }
        if (position.closeTransaction?.transactionHash) {
          memo.push(position.closeTransaction.transactionHash)
        }
        return memo
      }, []),
    [positions],
  )
  const [positionTrades, filteredTrades] = useMemo(() => {
    const positionTrades: Trade[] = []
    const filteredTrades = trades.filter(trade => {
      if (positionHashes.includes(trade.transaction.transactionHash)) {
        positionTrades.push(trade)
        return false
      } else {
        return true
      }
    })
    return [positionTrades, filteredTrades]
  }, [positionHashes, trades])
  const data = useMemo(() => {
    return [...positions, ...filteredTrades].sort((a, b) => getTime(b) - getTime(a))
  }, [positions, filteredTrades])

  const widths = {
    widths: [10, 20, 15, 10],
    starts: [3, 23, 61, 87],
    mobileWidths: [25, 18, 25],
    mobileStarts: [3, 41, 72],
  }

  const { x, y } = useContext(PageSizeContext)
  const isMobile = x < 960
  const isTablet = 660 < x && x < 960

  return (
    <>
      <ColumnTitles>
        <Column width={16} tabletWidth={17} mobileWidth={18} textAlign={'left'}>
          {t('action')}
        </Column>
        <Column width={34} tabletWidth={42} mobileWidth={45} textAlign={'left'} hideOnMobile={true} hideOnTablet={true}>
          {isMobile ? t('market') : t('amount')}
        </Column>
        <Column
          width={23}
          tabletWidth={20}
          textAlign={isMobile && !isTablet ? 'right' : 'left'}
          hideOnMobile={true}
          hideOnTablet={true}
        >
          <ColumnText>{`${t('unitPrice')} / ${t('pnl')}`}</ColumnText>
        </Column>
        {/*<Column width={17} tabletWidth={21} hideOnMobile={true} textAlign={isTablet ? 'right' : 'left'}>
                {t('transaction')}
              </Column>*/}
        <Column width={10} mobileWidth={20} hideOnMobile={false} hideOnTablet={false} textAlign={'right'}>
          {isMobile ? 'Liq/Date' : t('date')}
        </Column>
      </ColumnTitles>
      <DataRows>
        {account ? (
          isLoading ? (
            <TableLoader
              rows={isMobile ? 5 : isTablet ? 4 : 3}
              height={30}
              spacing={55}
              marginTop={20}
              isMobile={isMobile}
              isTablet={isTablet}
              widths={widths}
            />
          ) : data.length === 0 ? (
            <NoTrades>{t('noTrades')}</NoTrades>
          ) : (
            data.map((positionOrTrade, index) => {
              if (isPosition(positionOrTrade)) {
                const positionOpenTrade = positionTrades.find(
                  trade => trade.transaction.transactionHash === positionOrTrade.openTransaction?.transactionHash,
                )
                const positionCloseTrade = positionTrades.find(
                  trade => trade.transaction.transactionHash === positionOrTrade.closeTransaction?.transactionHash,
                )
                return (
                  <div key={`position-${index.toString()}`}>
                    <MarginRow
                      position={positionOrTrade}
                      openTrade={positionOpenTrade}
                      closeTrade={positionCloseTrade}
                      index={index}
                      isMobile={isMobile}
                      isTablet={isTablet}
                      t={t}
                      chainId={chainId}
                    />
                  </div>
                )
              } else {
                return (
                  <div key={`trade-${index.toString()}`}>
                    <TradeRow
                      trade={positionOrTrade}
                      index={index}
                      isMobile={isMobile}
                      isTablet={isTablet}
                      t={t}
                      chainId={chainId}
                    />
                  </div>
                )
              }
            })
          )
        ) : (
          <ConnectWalletButton>
            <ConnectBtn>
              <Web3Status />
            </ConnectBtn>
          </ConnectWalletButton>
        )}
      </DataRows>
    </>
  )
}
