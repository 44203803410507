import { useEffect, useState } from 'react'

/**
 * Modified from https://usehooks.com/useDebounce/
 *
 * This hook allows you to debounce any fast changing value. The debounced value will only reflect the latest value when
 * the useDebounce hook has not been called for the specified time period.
 */
export default function useDebounce<T>(value: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value)

  useEffect(() => {
    if (delay > 0) {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler)
      }
    } else {
      setDebouncedValue(value)
      return undefined
    }
  }, [value, delay])

  return delay === 0 ? value : debouncedValue
}
