import { MaxUint256 } from '@ethersproject/constants'
import { TransactionResponse } from '@ethersproject/providers'
import { Currency, CurrencyAmount } from '@dolomite-exchange/v2-sdk'
import { useCallback, useMemo } from 'react'
import { useTokenAllowance } from '../data/Allowances'
import { useHasPendingApproval, useTransactionAdder } from '../state/transactions/hooks'
import { calculateGasMargin } from '../utils'
import { useTokenContract } from './useContract'
import { useActiveWeb3React } from './index'
import remapTokenAddressForAllowance from '../utils/isolation/remapTokenAddressForAllowance'
import { useSerializedTokenOpt } from './Tokens'
import cleanCurrencySymbol from '../utils/cleanCurrencySymbol'

export enum ApprovalState {
  UNKNOWN = 'unknown',
  NOT_APPROVED = 'not_approved',
  PENDING = 'pending',
  APPROVED = 'approved',
}

// returns a variable indicating the state of the approval and a function which approves if necessary or early returns
export function useApproveCallback(
  amountToApprove?: CurrencyAmount<Currency>,
  spender?: string,
): [ApprovalState, () => Promise<string>] {
  const { account } = useActiveWeb3React()
  const token = useSerializedTokenOpt(amountToApprove?.currency.isToken ? amountToApprove.currency : undefined)
  const currentAllowance = useTokenAllowance(token, account, spender)
  const pendingApproval = useHasPendingApproval(token?.address, spender)

  // check the current approval status
  const approvalState = useMemo(() => {
    if (!amountToApprove || !spender) {
      return ApprovalState.UNKNOWN
    } else if (amountToApprove.currency.isNative) {
      return ApprovalState.APPROVED
    } else if (!currentAllowance) {
      // we might not have enough data to know whether we need to approve
      return ApprovalState.UNKNOWN
    }

    // amountToApprove will be defined if currentAllowance is
    return currentAllowance.lessThan(amountToApprove)
      ? pendingApproval
        ? ApprovalState.PENDING
        : ApprovalState.NOT_APPROVED
      : ApprovalState.APPROVED
  }, [amountToApprove, currentAllowance, pendingApproval, spender])

  const remappedTokenAddress = useMemo(() => (token ? remapTokenAddressForAllowance(token.wrapped) : undefined), [
    token,
  ])
  const tokenContract = useTokenContract(remappedTokenAddress)
  const addTransaction = useTransactionAdder()

  const approve = useCallback(async (): Promise<string> => {
    if (approvalState !== ApprovalState.NOT_APPROVED) {
      return Promise.reject(new Error(`Approve was called unnecessarily ${approvalState}`))
    }
    if (!token) {
      return Promise.reject(new Error('No token'))
    }
    if (!tokenContract) {
      return Promise.reject(new Error('No token contract'))
    }
    if (!amountToApprove) {
      return Promise.reject(new Error('No amount to approve'))
    }
    if (!spender) {
      return Promise.reject(new Error('No spender'))
    }

    let useExact = false
    const estimatedGas = await tokenContract.estimateGas.approve(spender, MaxUint256).catch(() => {
      // general fallback for tokens who restrict approval amounts
      useExact = true
      return tokenContract.estimateGas.approve(spender, amountToApprove.quotient.toString())
    })

    return tokenContract
      .approve(spender, useExact ? amountToApprove.quotient.toString() : MaxUint256, {
        gasLimit: calculateGasMargin(estimatedGas),
      })
      .then((response: TransactionResponse) => {
        addTransaction(response, {
          summary: `Approve ${cleanCurrencySymbol(amountToApprove.currency, false)}`,
          approval: {
            tokenAddress: token.address,
            spender: spender,
          },
        })
        return response.hash
      })
      .catch((error: Error) => {
        console.debug('Failed to approve token', error)
        throw error
      })
  }, [approvalState, token, tokenContract, amountToApprove, spender, addTransaction])

  return [approvalState, approve]
}
