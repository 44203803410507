import { Token } from '@dolomite-exchange/sdk-core'
import { ChainId, ChainIdMap, initializeSingleValueChainIdMap } from '../chainId'

const decimals = 18
const symbol = 'cmETH'
const name = 'cmETH'

function cmEth(chainId: ChainId, address: string) {
  return new Token(chainId, address, decimals, symbol, name)
}

export const CM_ETH: ChainIdMap<Token | undefined> = initializeSingleValueChainIdMap(
  ChainId.MANTLE,
  cmEth(ChainId.MANTLE, '0xE6829d9a7eE3040e1276Fa75293Bde931859e8fA'),
)
