import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PoolDepositWithdrawPanel, {
  PoolTransaction,
  PoolTransactionType,
} from '../../../components/Pool/PoolDepositWithdrawPanel'
import styled from 'styled-components/macro'
import { Fraction, Price, Rounding, Token } from '@dolomite-exchange/v2-sdk'
import { CurrencyAmount } from '@dolomite-exchange/sdk-core'
import Button from '@material-ui/core/Button'
import { useTokenBalancesWithLoadingIndicator } from '../../../state/wallet/hooks'
import { useActiveWeb3React } from '../../../hooks'
import { useTotalSupply } from '../../../data/TotalSupply'
import { ChainId, FORMATTER, ZERO_FRACTION, ZERO_PERCENT } from '../../../constants'
import cleanCurrencySymbol from '../../../utils/cleanCurrencySymbol'
import { useCurrentDayPairHourlyData } from '../../../types/ammPairHourData'
import useAmmApr from '../../../hooks/useAmmApr'
import { getEtherscanLink } from '../../../utils'
import { PairState } from '../../../data/Reserves'
import { AmmPoolData } from '../ManagePool'
import { TooltipTitle } from '../PoolBalances'
import { StyledTooltip } from '../../../components/common/StyledTooltip'
import { useIsTransactionPending } from '../../../state/transactions/hooks'
import sumPairHourData from '../../../utils/pairHourDataOperations'
import CircularProgress from '@material-ui/core/CircularProgress'
import Checkmark from '@material-ui/icons/Check'
import { deriveMarketFromTokensReq } from '../../../utils/marketUtils'
import { getFormattedPriceForUIFromMarket } from '../../../utils/prices'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { formatAmount } from '../../../utils/formatAmount'
import { useInterestRateData } from '../../../types/interestRateData'
import { useShowYieldAsApr } from '../../../state/user/hooks'

const PoolDataWrapper = styled.div`
  width: 100%;
  margin: 10px 0 0;
  position: relative;
`

const Unit = styled.span`
  font-weight: 100 !important;
`

const RateWrapper = styled.div`
  height: 40px;
  margin-top: 0;
  margin-bottom: 12px;
  font-size: 16px;

  br {
    display: none;
  }

  @media (max-width: 580px) {
    height: 20px;
    font-size: 14px;
    margin-top: -4px;
    margin-bottom: 49px;

    br {
      display: block;
    }
  }
`

const Rate = styled.div`
  color: #8fc942;
  font-weight: 600;
  font-size: 30px;
  display: inline-block;
  margin-right: 2px;
  margin-bottom: 0;
  cursor: pointer;

  @media (max-width: 580px) {
    font-size: 30px;
    margin-top: -9px;
  }
`

const APR = styled.div<{ isMobile: boolean }>`
  margin-left: 3px;
  display: ${({ isMobile }) => (isMobile ? 'none' : 'inline-block')};

  @media (max-width: 580px) {
    display: ${({ isMobile }) => (isMobile ? 'inline-block' : 'none')};
    font-size: 16px;
    margin-top: 8px;
  }
`

const TopSection = styled.div`
  margin-bottom: 5px;
  /*background-color: #1d1d29;
  border-radius: 5px;
  padding: 10px 20px 15px;
  width: calc(100% + 40px);
  margin-left: -20px;*/

  @media (max-width: 580px) {
    margin-bottom: 5px;
  }
`

const DataWrapper = styled.div<{ mobileHide: boolean; desktopHide: boolean }>`
  /*margin-top: 10px;*/
  ${({ desktopHide }) => (desktopHide ? 'display: none;' : 'display: block;')};
  @media (max-width: 580px) {
    ${({ mobileHide }) => (mobileHide ? 'display: none;' : 'display: block;')}
  }
`

const Data = styled.div<{ toggleable: boolean; inline?: boolean }>`
  color: #f9f9f9;
  font-size: 14px;
  font-weight: 400;
  position: relative;

  ${({ inline }) =>
    inline &&
    `
    display: inline-block;
    vertical-align: top;
    margin-left: 5px;
    width: fit-content;
  `}
  ${props => props.toggleable && 'cursor: pointer;'}
  > span {
    font-size: 14px;
    font-weight: 100;
    color: #d5d6e1;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 12px !important;
  `};

  @media (max-width: 580px) {
    font-size: 12px;

    > span {
      font-size: 12px;
    }
  }
`

const RightSide = styled.div`
  text-align: right;
  display: inline-block;
  vertical-align: top;
  width: 50%;

  /*> div > div:nth-of-type(1) {
    margin-top: 5px;
  }*/

  > ${DataWrapper} {
    > ${Data} {
      @media (max-width: 580px) {
        /*width: 200%;
      margin-left: -100%;
      text-align: left;
      margin-top: 20px;
      margin-bottom: 10px;*/
      }
    }
  }
`

const LeftSide = styled.div<{ mainPanel?: boolean }>`
  text-align: left;
  display: inline-block;
  width: 50%;
  vertical-align: top;

  ${({ theme, mainPanel }) =>
    mainPanel &&
    `
    > div > div:nth-of-type(1) {
      /*color: ${theme.text3};*/
      margin-top: 6px;
      font-size: 14px;
    }
  `}
`

const LoadingIndicator = styled.div`
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-weight: 300;
  color: ${({ theme }) => theme.text2};
`

const DepositWithdrawButtonWrapper = styled.div<{
  balance: Fraction
  hasRecentTransactions: boolean
  expanded: boolean
}>`
  width: 100%;
  text-align: right;
  margin-top: -5px;
  transition: margin-bottom 0.2s ease-in-out;

  ${({ expanded }) =>
    expanded &&
    `
    visibility: hidden;
    margin-bottom: -40px;
  `}
  ${({ balance, hasRecentTransactions }) =>
    (balance.greaterThan(ZERO_FRACTION) || hasRecentTransactions) &&
    `
    position: absolute;
    top: 182px;
    @media (max-width: 580px) {
      top: 162px;
    }
    @media (max-width: 400px) {
      top: 214px;
    }
  `}
  span {
    font-family: 'Open Sans', sans-serif;
  }

  @media (max-width: 580px) {
    text-align: right;
    margin-top: 4px;
  }
`

const DepositWithdrawButton = styled(Button)`
  background: #3d3e54 !important;
  color: #f9f9f9 !important;
  font-weight: 300 !important;
  font-size: 14px !important;
  height: 36px !important;
  margin: 0 auto !important;
  padding-left: 10px !important;
  padding-right: 10px !important;

  &:hover {
    background: #3f4056 !important;
  }

  @media (max-width: 580px) {
    font-size: 12px !important;
  }
`

const PoolPriceWrapper = styled.div`
  width: 150%;
  margin-top: 5px;
`

const DataTitle = styled.div<{ inline?: boolean }>`
  font-size: 14px;
  font-weight: 100;
  ${({ inline }) => inline && `display: inline-block; vertical-align: top; width: fit-content;`};
  @media (max-width: 580px) {
    font-size: 12px;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 14px;
  `};
`

const BottomSection = styled.div`
  background-color: ${({ theme }) => theme.bg6};
  border-radius: 5px;
  padding: 15px 20px 20px;
  width: calc(100% + 40px);
  margin-left: -20px;
  margin-bottom: -11px;

  > ${RightSide} > ${DataWrapper} {
    > ${DataTitle} {
      display: inline-block;
      vertical-align: top;
      margin-right: 4px;
      @media (max-width: 390px) {
        font-size: 12px !important;
      }

      :after {
        content: ': ';
      }
    }

    > ${Data} {
      @media (max-width: 580px) {
        text-align: right;
        display: inline-block;
        width: fit-content;
        vertical-align: top;
        margin: 0;
        font-size: 14px !important;
      }

      @media (max-width: 390px) {
        font-size: 12px !important;
      }
    }
  }
`

const Break = styled.br<{ isMobile: boolean }>`
  display: ${({ isMobile }) => (isMobile ? 'none' : 'block')};

  @media (max-width: 580px) {
    display: ${({ isMobile }) => (isMobile ? 'block' : 'none')};
  }
`

const RecentTransactionsWrapper = styled.div`
  width: 100%;
  border-top: 0.5px solid ${({ theme }) => theme.text3};
  margin-top: 15px;
  padding-top: 5px;
  font-size: 12px;
  color: ${({ theme }) => theme.text2};
`

const RecentTransactionsTitle = styled.div`
  color: ${({ theme }) => theme.text3};
  background-color: ${({ theme }) => theme.bg6};
  width: fit-content;
  margin-top: -15px;
  padding-right: 5px;
`

const RecentTransactionWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
`

const TransactionType = styled.div`
  display: inline-block;
  vertical-align: top;
  margin-right: 4px;
  text-transform: capitalize;

  a {
    color: ${({ theme }) => theme.text1};
    text-decoration: none;
  }
`

const TransactionAmount = styled.div`
  display: inline-block;
  vertical-align: top;
  margin-right: 4px;
`

const TransactionCurrency = styled.div`
  display: inline-block;
  vertical-align: top;
`

const LoadingWrapper = styled.div`
  display: inline-block;
  vertical-align: top;

  > svg,
  > div {
    color: ${({ theme }) => theme.text1} !important;
    font-size: 15px;
    height: 15px !important;
    width: 15px !important;
  }
`

const NewData = styled.div<{ isIncreasing: boolean | undefined }>`
  display: inline-block;
  vertical-align: top;
  position: absolute;
  white-space: nowrap;
  margin-left: 3px;

  svg {
    font-size: 15px;
    vertical-align: top;
    margin-top: 2px;
    color: ${({ isIncreasing, theme }) => (isIncreasing ? theme.green1 : theme.red1)};
  }
`

interface PoolValueProps {
  first: string
  firstTrailing: string
  second?: string
  secondTrailing?: string
  title: string
  toggle?: boolean
  defaultStart?: boolean
  hideTitle?: boolean
  hideOnMobile?: boolean
  hideOnDesktop?: boolean
  inline?: boolean
  isLoading: boolean
  newFirst?: string | undefined
  newSecond?: string | undefined
  isIncreasing?: boolean | undefined
}

const poolValueComparator = (prevProps: PoolValueProps, nextProps: PoolValueProps) => {
  return (
    prevProps.first === nextProps.first &&
    prevProps.second === nextProps.second &&
    prevProps.firstTrailing === nextProps.firstTrailing &&
    prevProps.secondTrailing === nextProps.secondTrailing &&
    prevProps.isLoading === nextProps.isLoading &&
    prevProps.newFirst === nextProps.newFirst &&
    prevProps.newSecond === nextProps.newSecond
  )
}

const PoolValue = React.memo<PoolValueProps>(
  ({
    first,
    firstTrailing,
    second,
    secondTrailing,
    title,
    toggle,
    defaultStart,
    hideTitle,
    hideOnMobile,
    hideOnDesktop,
    inline,
    isLoading,
    newFirst,
    newSecond,
    isIncreasing,
  }: PoolValueProps) => {
    const [showYieldAsApr] = useShowYieldAsApr()
    const [toggled, setToggled] = useState<boolean>(false)
    const show1 = !toggle || (!toggled && defaultStart) || (toggled && !defaultStart)
    const show2 = !toggle || (toggled && defaultStart) || (!toggled && !defaultStart)

    return (
      <DataWrapper mobileHide={!!hideOnMobile} desktopHide={!!hideOnDesktop}>
        {!hideTitle && <DataTitle inline={inline}>{title}</DataTitle>}
        {show1 && (
          <Data toggleable={!!toggle} onClick={() => setToggled(!toggled)} inline={inline}>
            {isLoading ? '-' : first}
            &nbsp;
            <Unit>{firstTrailing}</Unit>
            {newFirst && (
              <NewData isIncreasing={isIncreasing}>
                <ArrowForwardIcon /> {newFirst} <Unit>{firstTrailing}</Unit>
              </NewData>
            )}
          </Data>
        )}
        {show2 && !inline && (
          <Data toggleable={!!toggle} onClick={() => setToggled(!toggled)} inline={inline}>
            {isLoading && second !== '' ? '-' : second}
            &nbsp;
            <Unit>{secondTrailing}</Unit>
            {newSecond && (
              <NewData isIncreasing={isIncreasing}>
                <ArrowForwardIcon /> {newSecond} <Unit>{secondTrailing}</Unit>
              </NewData>
            )}
          </Data>
        )}
      </DataWrapper>
    )
  },
  poolValueComparator,
)
PoolValue.displayName = 'PoolValue'

interface PairPriceData {
  pairPrice: string
  pairPriceInverse: string
}

interface UserBalanceData {
  userBalance0: Fraction
  userBalance1: Fraction
  poolShare: Fraction
}

const RecentTransaction = ({
  transaction,
  isLoading,
  chainId,
  hash,
}: {
  transaction: PoolTransaction
  isLoading: boolean
  chainId: ChainId
  hash?: string
}) => (
  <RecentTransactionWrapper>
    <div>
      <TransactionType>
        {hash ? (
          <a href={getEtherscanLink(chainId, hash, 'transaction')} target={'_blank'} rel={'noreferrer'}>
            {transaction.type.toLowerCase()}
          </a>
        ) : (
          transaction.type.toLowerCase()
        )}
      </TransactionType>
      {transaction.type !== PoolTransactionType.UNLOCK && (
        <TransactionAmount>{formatAmount(transaction.amount0)}</TransactionAmount>
      )}
      <TransactionCurrency>{cleanCurrencySymbol(transaction.amount0?.currency)}</TransactionCurrency>
      {` and `}
      {transaction.type !== PoolTransactionType.UNLOCK && (
        <TransactionAmount>{formatAmount(transaction.amount1)}</TransactionAmount>
      )}
      <TransactionCurrency>{cleanCurrencySymbol(transaction.amount1?.currency)}</TransactionCurrency>
    </div>
    <LoadingWrapper>{isLoading ? <CircularProgress /> : <Checkmark />}</LoadingWrapper>
  </RecentTransactionWrapper>
)

interface changedData {
  asset1: Token
  amount1: Fraction
  asset2: Token
  amount2: Fraction
}

export default function PoolData({
  pool,
  pairState,
  isLoading,
  balanceInfo,
}: {
  pool: AmmPoolData | undefined
  pairState: PairState
  isLoading: boolean
  balanceInfo: any
}) {
  const { t } = useTranslation()
  const [showYieldAsApr] = useShowYieldAsApr()
  const { account, chainId } = useActiveWeb3React()
  const [expanded, setExpanded] = useState<boolean>(false)
  const [recentTransactions, setRecentTransactions] = useState<PoolTransaction[]>([])
  const [isTxLoading, setIsTxLoading] = useState<boolean>(false)
  const [pendingHash, setPendingHash] = useState<string | undefined>(undefined)
  const isTransactionPending = useIsTransactionPending(pendingHash)
  const [depositAmounts, setDepositAmounts] = useState<changedData | undefined>(undefined)
  const [withdrawAmounts, setWithdrawAmounts] = useState<changedData | undefined>(undefined)
  const onCollapse = useCallback(() => setExpanded(false), [])
  const { loading: isInterestRateMapLoading, data: interestRateMap } = useInterestRateData()

  const filteredRecentTransactions = useMemo(() => {
    return [...recentTransactions].filter(
      transaction => transaction.pair?.liquidityToken.address === pool?.pair.liquidityToken.address,
    )
  }, [recentTransactions, pool])

  useEffect(() => {
    const localStorageContents = localStorage.getItem('recent_pool_transactions')
    if (localStorageContents) {
      const loadedLocalStorageData = JSON.parse(localStorageContents)
      if (loadedLocalStorageData.account && loadedLocalStorageData.account === account) {
        const loadedRecentTransactions = loadedLocalStorageData.data
        const filteredTransactions = loadedRecentTransactions.filter(
          (transaction: PoolTransaction) => transaction.amount0 && transaction.date > Date.now() - 1000 * 60 * 15,
        )
        if (filteredTransactions && filteredTransactions.length > 0) {
          const assembledTransactions = filteredTransactions.map((transaction: any) => {
            return {
              pair: transaction.pair,
              amount0:
                transaction.type === PoolTransactionType.UNLOCK || !transaction.amount0.currency
                  ? undefined
                  : CurrencyAmount.fromFractionalAmount(
                      transaction.amount0.currency,
                      transaction.numerator0,
                      transaction.denominator0,
                    ),
              amount1:
                transaction.type === PoolTransactionType.UNLOCK || !transaction.amount1.currency
                  ? undefined
                  : CurrencyAmount.fromFractionalAmount(
                      transaction.amount1.currency,
                      transaction.numerator1,
                      transaction.denominator1,
                    ),
              type: transaction.type,
              date: transaction.date,
              isLoaded: true,
              hash: transaction.hash,
            }
          })
          setRecentTransactions(assembledTransactions)
        }
      } else {
        setRecentTransactions([])
      }
    }
  }, [account])

  useEffect(() => {
    setIsTxLoading(isTransactionPending)
  }, [isTransactionPending])

  const pair = useMemo(() => pool?.pair, [pool])
  const totalLendingYield = useMemo(() => {
    const interestRate0 = interestRateMap[pool?.pair.token0.address ?? '']
    const interestRate1 = interestRateMap[pool?.pair.token1.address ?? '']
    if (interestRate0 && interestRate1) {
      return interestRate0.supplyInterestRate.add(interestRate1.supplyInterestRate).divide('2')
    } else {
      return ZERO_PERCENT
    }
  }, [interestRateMap, pool?.pair])

  const totalOutsideYield = useMemo(() => {
    const interestRate0 = interestRateMap[pool?.pair.token0.address ?? '']
    const interestRate1 = interestRateMap[pool?.pair.token1.address ?? '']
    if (interestRate0 && interestRate1) {
      return (interestRate0.outsideSupplyInterestRateParts ?? [])
        .reduce((total, part) => total.add(part.interestRate ?? ZERO_PERCENT), ZERO_PERCENT)
        .add(
          (interestRate1.outsideSupplyInterestRateParts ?? []).reduce(
            (total, part) => total.add(part.interestRate ?? ZERO_PERCENT),
            ZERO_PERCENT,
          ),
        )
        .divide('2')
    } else {
      return ZERO_PERCENT
    }
  }, [interestRateMap, pool?.pair])

  const setTransactionsCallback = useCallback(transactions => {
    setRecentTransactions(transactions)
  }, [])

  const setDepositAmountsCallback = useCallback((newAmounts: changedData | undefined) => {
    newAmounts ? setDepositAmounts(newAmounts) : setDepositAmounts(undefined)
  }, [])

  const setWithdrawAmountsCallback = useCallback((newAmounts: changedData | undefined) => {
    newAmounts ? setWithdrawAmounts(newAmounts) : setWithdrawAmounts(undefined)
  }, [])

  const tokensForBalances = useMemo(() => [pair?.liquidityToken], [pair])
  const [userLiquidityBalanceData, isLoadingUserBalance] = useTokenBalancesWithLoadingIndicator(
    account,
    tokensForBalances,
  )
  const userLiquidityBalance = useMemo(() => {
    return Object.values(userLiquidityBalanceData)[0]
  }, [userLiquidityBalanceData])
  const liquidityTokenTotalSupply = useTotalSupply(pair?.liquidityToken)
  const [pairPriceData, setPairPriceData] = useState<PairPriceData>({
    pairPrice: '',
    pairPriceInverse: '',
  })
  const [userBalanceData, setUserBalanceData] = useState<UserBalanceData>({
    userBalance0: ZERO_FRACTION,
    userBalance1: ZERO_FRACTION,
    poolShare: ZERO_FRACTION,
  })
  const token0Symbol = cleanCurrencySymbol(pair?.token0) ?? ''
  const token1Symbol = cleanCurrencySymbol(pair?.token1) ?? ''

  useEffect(() => {
    if (!pair || pair.reserve0.equalTo(ZERO_FRACTION) || pair.reserve1.equalTo(ZERO_FRACTION)) {
      setPairPriceData({
        pairPrice: 'Pair not created yet',
        pairPriceInverse: 'Pair not created yet',
      })
      return
    }

    const market = deriveMarketFromTokensReq(pair.token0, pair.token1)
    const price = new Price({
      baseAmount: market.primaryToken.equals(pair.token0) ? pair.reserve0 : pair.reserve1,
      quoteAmount: market.primaryToken.equals(pair.token0) ? pair.reserve1 : pair.reserve0,
    })

    setPairPriceData({
      pairPrice: getFormattedPriceForUIFromMarket(price, market, false),
      pairPriceInverse: getFormattedPriceForUIFromMarket(price, market, true),
    })
  }, [pair, pairState])

  useEffect(() => {
    if (pair && liquidityTokenTotalSupply) {
      const userPoolShareLocal = liquidityTokenTotalSupply.equalTo('0')
        ? ZERO_FRACTION
        : userLiquidityBalance
        ? userLiquidityBalance.asFraction.divide(liquidityTokenTotalSupply.asFraction)
        : ZERO_FRACTION
      setUserBalanceData({
        userBalance0: pair.reserve0.multiply(userPoolShareLocal),
        userBalance1: pair.reserve1.multiply(userPoolShareLocal),
        poolShare: userPoolShareLocal.multiply('100'),
      })
    } else {
      setUserBalanceData({
        userBalance0: ZERO_FRACTION,
        userBalance1: ZERO_FRACTION,
        poolShare: ZERO_FRACTION,
      })
    }
  }, [pair, userLiquidityBalance, liquidityTokenTotalSupply])

  const { data: pair24HourData } = useCurrentDayPairHourlyData(pair)
  const summedPair = useMemo(() => {
    if (!pair) {
      return undefined
    }
    if (Object.values(pair24HourData).length === 0) {
      return { volumeUSD: ZERO_FRACTION }
    }
    return sumPairHourData(pair, pair24HourData)
  }, [pair, pair24HourData])

  const ammApr = useAmmApr(pair, pair24HourData)
  const totalApr = useMemo(() => ammApr.add(totalLendingYield).add(totalOutsideYield), [
    ammApr,
    totalLendingYield,
    totalOutsideYield,
  ])

  const combinedInterestRateParts = useMemo(() => {
    return pool
      ? [
          ...(interestRateMap[pool.pair.token0.address]?.outsideSupplyInterestRateParts ?? []),
          ...(interestRateMap[pool.pair.token1.address]?.outsideSupplyInterestRateParts ?? []),
        ]
      : undefined
  }, [interestRateMap, pool])

  if (!pair || !summedPair) {
    return <div />
  }

  // TODO - design thought - maybe move the Pool Price section to be to the right of the Pool Liquidity section, and
  //        then have the Pool Share section below Pool Liquidity and in line with Pool User Balance. This way the use
  //        related stuff that requires a deposit can be below everything else and just not be shown, or at least even
  //        if its shown with zero balances it can be easily distinguished and given a sub heading that says something
  //        like "your pool contribution" or something like that

  // TODO - idea re: the above comment - Assuming normal operation, all pools should have a balance, so just check for a balance. If none, show as loading.

  return (
    <PoolDataWrapper>
      <TopSection>
        <LeftSide mainPanel={true}>
          <PoolValue
            title={t('poolLiquidity')}
            first={pair.reserve0.toFixed(4, FORMATTER)}
            firstTrailing={cleanCurrencySymbol(pair.token0) ?? ''}
            second={pair.reserve1.toFixed(4, FORMATTER)}
            secondTrailing={cleanCurrencySymbol(pair.token1) ?? ''}
            isLoading={isLoading}
          />
          <PoolValue
            title={t('pool24HrVolume')}
            first={`$${summedPair.volumeUSD.toFixed(2, FORMATTER)}`}
            firstTrailing={''}
            second={''}
            secondTrailing={''}
            isLoading={isLoading}
          />
          <PoolPriceWrapper>
            <PoolValue
              title={t('poolPrice')}
              first={pairPriceData.pairPrice}
              firstTrailing={`${token1Symbol} ${t('per')} ${token0Symbol}`}
              second={pairPriceData.pairPriceInverse}
              secondTrailing={`${token0Symbol} ${t('per')} ${token1Symbol}`}
              toggle={true}
              defaultStart={true /*first > second*/}
              hideTitle={true}
              hideOnDesktop={true}
              isLoading={isLoading}
            />
          </PoolPriceWrapper>
        </LeftSide>
        <RightSide>
          <RateWrapper>
            <APR isMobile={true}>{showYieldAsApr ? t('apr') : t('apy')}</APR>
            <Break isMobile={true} />
            <StyledTooltip
              title={
                <TooltipTitle
                  marginSupplyYield={formatAmount(totalLendingYield, 2, true, '0%')}
                  ammYield={formatAmount(ammApr, 2, true, '0%')}
                  interestRateParts={combinedInterestRateParts}
                />
              }
              placement='top'
              arrow={true}
            >
              <Rate>{formatAmount(totalApr, 2, true, '0%')}</Rate>
            </StyledTooltip>
            <Break isMobile={false} />
            <APR isMobile={false}>{showYieldAsApr ? t('APR') : t('APY')}</APR>
            {/*<StyledTooltip
             title={t('apr')} placement="right">
             <StyledInfoIcon>
             </StyledInfoIcon>
             </StyledTooltip>*/}
          </RateWrapper>
          <PoolValue
            title={t('poolPrice')}
            first={pairPriceData.pairPrice}
            firstTrailing={''}
            second={pairPriceData.pairPriceInverse}
            secondTrailing={''}
            toggle={true}
            defaultStart={true}
            hideTitle={false}
            hideOnMobile={true}
            isLoading={isLoading}
          />
        </RightSide>
      </TopSection>
      {(userBalanceData.poolShare.toFixed(4) !== '0.0000' || filteredRecentTransactions.length > 0) && (
        <BottomSection>
          <LeftSide>
            <PoolValue
              title={t('poolUserBalance')}
              first={userBalanceData.userBalance0.toFixed(4, FORMATTER, Rounding.ROUND_HALF_UP)}
              firstTrailing={token0Symbol}
              second={userBalanceData.userBalance1.toFixed(4, FORMATTER, Rounding.ROUND_HALF_UP)}
              secondTrailing={token1Symbol}
              isLoading={isLoading}
              newFirst={
                depositAmounts
                  ? userBalanceData.userBalance0.asFraction.add(depositAmounts.amount1).toFixed(4)
                  : withdrawAmounts
                  ? userBalanceData.userBalance0.asFraction.subtract(withdrawAmounts.amount1).toFixed(4)
                  : undefined
              }
              newSecond={
                depositAmounts
                  ? userBalanceData.userBalance1.asFraction.add(depositAmounts.amount2).toFixed(4)
                  : withdrawAmounts
                  ? userBalanceData.userBalance1.asFraction.subtract(withdrawAmounts.amount2).toFixed(4)
                  : undefined
              }
              isIncreasing={depositAmounts ? true : withdrawAmounts ? false : undefined}
            />
          </LeftSide>
          <RightSide>
            <PoolValue
              title={t('poolShare')}
              first={userBalanceData.poolShare.toFixed(4, FORMATTER) ?? '0'}
              firstTrailing={'%'}
              inline={true}
              isLoading={isLoading}
            />
          </RightSide>
          <>
            {filteredRecentTransactions.length > 0 && (
              <RecentTransactionsWrapper>
                <RecentTransactionsTitle>Recent actions</RecentTransactionsTitle>
                {filteredRecentTransactions
                  .slice()
                  .reverse()
                  .map((transaction, index) => (
                    <RecentTransaction
                      key={index}
                      transaction={transaction}
                      isLoading={isTxLoading && index === 0}
                      chainId={chainId}
                      hash={transaction.hash}
                    />
                  ))}
              </RecentTransactionsWrapper>
            )}
          </>
        </BottomSection>
      )}
      {isLoading || isLoadingUserBalance ? (
        <LoadingIndicator>Loading data...</LoadingIndicator>
      ) : (
        account && (
          <DepositWithdrawButtonWrapper
            balance={userBalanceData.poolShare}
            hasRecentTransactions={filteredRecentTransactions.length > 0}
            expanded={expanded}
            style={
              (pair.reserve0.equalTo(ZERO_FRACTION) || userBalanceData.poolShare.toFixed(4) === '0.0000') &&
              filteredRecentTransactions.length === 0
                ? { textAlign: 'center' }
                : {}
            }
          >
            <DepositWithdrawButton
              onClick={() => setExpanded(true)}
              style={
                (pair.reserve0.equalTo(ZERO_FRACTION) || userBalanceData.poolShare.toFixed(4) === '0.0000') &&
                filteredRecentTransactions.length === 0
                  ? { width: '100%' }
                  : {}
              }
            >
              {pair.reserve0.equalTo(ZERO_FRACTION)
                ? `${t('createPool')}`
                : `${t('deposit')}${userBalanceData.poolShare.toFixed(4) !== '0.0000' ? '/' + t('withdraw') : ''}`}
            </DepositWithdrawButton>
          </DepositWithdrawButtonWrapper>
        )
      )}
      <PoolDepositWithdrawPanel
        isOpen={expanded}
        pair={pool?.pair}
        pairState={pairState}
        onCollapse={onCollapse}
        balanceInfo={balanceInfo}
        setIsTxLoading={setIsTxLoading}
        setPendingHash={setPendingHash}
        recentTransactions={recentTransactions}
        setTransactions={setTransactionsCallback}
        setDepositAmounts={setDepositAmountsCallback}
        setWithdrawAmounts={setWithdrawAmountsCallback}
      />
    </PoolDataWrapper>
  )
}
