import React from 'react'
import styled from 'styled-components/macro'

export const ConnectWalletButton = styled.div`
  margin-top: 15px;
  color: white !important;
  width: 165px;
  text-align: center;
  margin: 30px auto;
`

export const ConnectBtn = styled.div`
  @media (max-width: 580px) {
    font-size: 14px;
  }
`

export const ColumnTitles = styled.div<{ modal?: boolean }>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 ${({ modal }) => (modal ? 0 : '25px')};
  font-weight: 600;
  font-size: ${({ modal }) => (modal ? '13px' : '14px')};
  margin-top: 13px;
  margin-bottom: ${({ modal }) => (modal ? '5px' : '13px')};
  color: ${({ theme }) => theme.text3};
  ${({ theme, modal }) => theme.mediaWidth.upToSmall`
    margin-top: 20px;
    padding: 0 ${modal ? 0 : '20px'};
    font-size: 12px;
  `};
`

export const ColumnText = styled.span`
  width: fit-content;
`

export const DataRows = styled.div`
  height: calc(100% - 53px);
  width: 100%;
  display: inline-block;
  vertical-align: top;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 0;
  }

  ::-webkit-scrollbar-track {
    background: none;
  }

  ::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 5px;
    border: none;
  }
`

export const MarginRowWrapper = styled.div<{ showTrade: boolean; hasCloseTransaction?: boolean }>`
  height: ${({ showTrade, hasCloseTransaction }) => (showTrade ? (hasCloseTransaction ? `125px` : `85px;`) : `54px;`)};
  transition: height 0.2s ease-in-out;
  overflow: hidden;
  background-color: ${({ showTrade, theme }) => showTrade && `${theme.bg2}`};
  margin-bottom: 4px;

  :hover {
    background-color: ${({ theme }) => `${theme.bg2}`};
  }

  cursor: pointer;

  > div {
    cursor: pointer !important;
  }
`

export const ExpandTrade = styled.div`
  margin-top: -10px;

  > div {
    cursor: pointer !important;
  }
`

export const ExpandedRow = styled.div`
  height: 24px;
  width: 100%;

  > span {
    margin: 0 5px;
  }
`

export const ExpandedTitle = styled.span`
  font-weight: 500;
  margin-right: 5px;
  color: ${({ theme }) => theme.text3};
`

export const EtherscanLink = styled.span`
  cursor: pointer;
`

export const Bottom = styled.div`
  width: 100%;
  text-align: right;
`

export const BottomDataItem = styled.div`
  width: fit-content;
  line-height: 18px;
`

export const BottomLabel = styled.span`
  color: ${({ theme }) => theme.text2};
  margin-right: 3px;
`

export const BottomValue = styled.span`
  color: ${({ theme }) => theme.text1};
`

export const Loading = styled.div`
  text-align: center;
  font-size: 14px;
  color: ${({ theme }) => theme.text2};
  margin: 40px 0;
`

export const NoTrades = styled.div`
  text-align: center;
  width: 100%;
  color: ${({ theme }) => theme.text3} !important;
  margin: 40px 0;
`
