import { Token } from '@dolomite-exchange/sdk-core'
import { ChainId, ChainIdMap } from '../chainId'

const decimals = 18
const symbol = 'DAI'
const name = 'Dai Stablecoin'

function getDai(chainId: ChainId, address: string) {
  return new Token(chainId, address, decimals, symbol, name)
}

export const DAI: ChainIdMap<Token | undefined> = {
  [ChainId.MAINNET]: getDai(ChainId.MAINNET, '0x6B175474E89094C44Da98b954EedeAC495271d0F'),
  [ChainId.ARBITRUM_ONE]: getDai(ChainId.ARBITRUM_ONE, '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1'),
  [ChainId.BASE]: getDai(ChainId.BASE, '0x50c5725949A6F0c72E6C4a641F24049A917DB0Cb'),
  [ChainId.BERACHAIN]: undefined,
  [ChainId.MANTLE]: undefined,
  [ChainId.POLYGON_ZKEVM]: getDai(ChainId.POLYGON_ZKEVM, '0xC5015b9d9161Dca7e18e32f6f25C4aD850731Fd4'),
  [ChainId.X_LAYER]: getDai(ChainId.X_LAYER, '0xc5015b9d9161dca7e18e32f6f25c4ad850731fd4'),
}
