import React, { useContext } from 'react'
import { Fraction } from '@dolomite-exchange/v2-sdk'
import { Text } from 'rebass'
import styled, { ThemeContext } from 'styled-components/macro'
import { StyledTooltip } from '../../components/common/StyledTooltip'
import { formatAmount } from '../../utils/formatAmount'

const TextBody = styled.div`
  width: fit-content;
  cursor: pointer;
`

const TooltipWrapper = styled.div`
  padding: 8px;
  width: fit-content;
  height: fit-content;
`

const TooltipRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => theme.text1};

  > div:nth-of-type(1) {
    margin-right: 10px;
  }
`

const TradeAmountTooltip = ({
  marginDeposit,
  tradeAmount,
  symbol,
}: {
  marginDeposit: Fraction | undefined
  tradeAmount: Fraction | undefined
  symbol: string | undefined
}) => {
  return (
    <TooltipWrapper>
      <TooltipRow>
        <div>Margin Deposit:</div>
        <div>
          {formatAmount(marginDeposit, undefined, true)} {symbol}
        </div>
      </TooltipRow>
      <TooltipRow>
        <div>Trade Size:</div>
        <div>
          {formatAmount(tradeAmount, undefined, true)} {symbol}
        </div>
      </TooltipRow>
    </TooltipWrapper>
  )
}

interface TokenAmountProps {
  token0Amount: Fraction | undefined
  token1Amount: Fraction | undefined
  token0Symbol: string | undefined
  token1Symbol: string | undefined
  marginDeposit: Fraction | undefined
  tradeAmount: Fraction | undefined
  token0IsEstimate?: boolean
  token1IsEstimate?: boolean
  showInverted: boolean
  setShowInverted: (showInverted: boolean) => void
}

export default function TradeAmount({
  token0Amount,
  token0Symbol,
  token1Amount,
  token1Symbol,
  marginDeposit,
  tradeAmount,
  token0IsEstimate,
  token1IsEstimate,
  showInverted,
  setShowInverted,
}: TokenAmountProps): JSX.Element {
  const theme = useContext(ThemeContext)

  const formattedPrice = showInverted ? formatAmount(token1Amount) : formatAmount(token0Amount)

  const show = Boolean(token0Amount && token1Amount)

  return (
    <Text
      fontWeight={500}
      fontSize={12}
      color={theme.text1}
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
      }}
    >
      {show ? (
        <StyledTooltip
          title={<TradeAmountTooltip marginDeposit={marginDeposit} tradeAmount={tradeAmount} symbol={token0Symbol} />}
          placement='top'
          arrow={true}
        >
          <TextBody onClick={() => setShowInverted(!showInverted)}>
            {showInverted
              ? `${token1IsEstimate ? '≈ ' : ''}${formattedPrice} ${token1Symbol}`
              : `${token0IsEstimate ? '≈ ' : ''}${formattedPrice} ${token0Symbol}`}
          </TextBody>
        </StyledTooltip>
      ) : (
        '-'
      )}
    </Text>
  )
}
