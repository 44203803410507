import { createCurrencyAmount, TotalPar as TotalParGql } from './gqlTypeHelpers'
import { useMemo } from 'react'
import { gql } from '@apollo/client'
import { CurrencyAmount, Token } from '@dolomite-exchange/v2-sdk'
import { useAllTokens } from '../hooks/Tokens'
import { toChecksumAddress } from '../utils/toChecksumAddress'
import { NO_VARIABLES, useGraphqlResult } from '../state/graphql/hooks'
import { GraphqlClientType } from '../state/graphql/actions'
import { RefreshFrequency } from '../state/chain/hooks'

const TOTAL_PARS_GQL = gql`
  query allTotalPars($blockNumber: Int!) {
    totalPars(block: { number_gte: $blockNumber }) {
      id
      borrowPar
      supplyPar
    }
  }
`

interface TotalParsResponse {
  totalPars: TotalParGql[]
}

export interface TotalParInfo {
  token: Token
  borrowPar: CurrencyAmount<Token>
  supplyPar: CurrencyAmount<Token>
}

export function useMarketsTotalParData(): {
  loading: boolean
  error: boolean
  data: Record<string, TotalParInfo | undefined>
} {
  const queryState = useGraphqlResult<TotalParsResponse>(
    GraphqlClientType.Dolomite,
    TOTAL_PARS_GQL.loc!.source.body,
    NO_VARIABLES,
    RefreshFrequency.Medium,
  )
  const tokenMap = useAllTokens()

  return useMemo(() => {
    const { loading, error, result } = queryState
    const anyLoading = Boolean(loading)
    const anyError = Boolean(error)

    const totalParInfos = (result?.totalPars ?? []).reduce<Record<string, TotalParInfo>>((memo, totalPar) => {
      const token = tokenMap[toChecksumAddress(totalPar.id)]
      if (!token) {
        return memo
      }

      memo[token.address] = {
        token: token,
        borrowPar: createCurrencyAmount(token, totalPar.borrowPar),
        supplyPar: createCurrencyAmount(token, totalPar.supplyPar),
      }
      return memo
    }, {})

    return {
      loading: anyLoading,
      error: anyError,
      data: totalParInfos,
    }
  }, [queryState, tokenMap])
}
