import { ChainId } from '../constants'

const MAINNET = ChainId.MAINNET
const ARBITRUM_ONE = ChainId.ARBITRUM_ONE

const BACKEND_URLS: Record<string, string | undefined> = {
  default: 'https://gmx-server-mainnet.uw.r.appspot.com',
  [MAINNET]: 'https://gambit-server-staging.uc.r.appspot.com',
  [ARBITRUM_ONE]: 'https://gmx-server-mainnet.uw.r.appspot.com',
}

export function getServerBaseUrl(chainId: ChainId) {
  if (!chainId) {
    throw new Error('chainId is not provided')
  }

  if (document.location.hostname.includes('deploy-preview')) {
    const fromLocalStorage = localStorage.getItem('SERVER_BASE_URL')
    if (fromLocalStorage) {
      return fromLocalStorage
    }
  }

  return BACKEND_URLS[chainId] ?? BACKEND_URLS.default
}

export function getServerUrl(chainId: number, path: string) {
  return `${getServerBaseUrl(chainId)}${path}`
}
