import { WO_ETH } from '../../index'
import { SpecialAsset, SpecialAssetSymbol } from '../special-assets'
import mapChainIdMapValues from '../../../utils/mapChainIdMapValues'

export const WOETH_SPECIAL_ASSET: () => SpecialAsset = () => ({
  symbol: SpecialAssetSymbol.WOETH,
  safeSymbol: SpecialAssetSymbol.WOETH,
  cleanSymbol: 'wOETH',
  cleanName: 'Wrapped OETH',
  hasExternalRewards: true,
  documentationUrl: undefined,
  isIsolationMode: false,
  isBorrowingEnabled: false,
  chainIdToAddressMap: mapChainIdMapValues(WO_ETH, t => t?.address),
  isolationModeInfo: undefined,
})
