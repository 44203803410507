import { Percent } from '@dolomite-exchange/sdk-core'
import { Decimal } from 'decimal.js'
import { reqParseFraction } from '../state/trade/hooks'
import { ONE_BIPS, ZERO_FRACTION, ZERO_PERCENT } from '../constants'
import JSBI from 'jsbi'

const HALF_BIPS = ONE_BIPS.divide(2)

// Avoid making this too large since it slows down rendering
const DECIMALS = 10
export default function calculateAprToApy(aprPercent: Percent): Percent {
  if (aprPercent.equalTo(ZERO_FRACTION)) {
    return ZERO_PERCENT
  }

  const apr = new Decimal(aprPercent.toFixed(DECIMALS)).abs().div(100)
  const apy = reqParseFraction(
    new Decimal(
      apr
        .div(365)
        .plus(1)
        .toFixed(DECIMALS),
    )
      .pow(365)
      .minus(1)
      .toFixed(DECIMALS),
  ).add(HALF_BIPS)
  return aprPercent.lessThan(ZERO_FRACTION)
    ? new Percent(JSBI.multiply(apy.numerator, JSBI.BigInt('-1')), apy.denominator)
    : new Percent(apy.numerator, apy.denominator)
}
