import React from 'react'
import styled from 'styled-components/macro'
import Tooltip from '@material-ui/core/Tooltip'
import HelpOutlinedIcon from '@material-ui/icons/HelpOutline'

const QuestionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.2rem;
  border: none;
  background: none;
  outline: none;
  cursor: default;
  border-radius: 36px;
  background-color: ${({ theme }) => theme.bg2};
  color: ${({ theme }) => theme.text2};

  :hover,
  :focus {
    opacity: 0.7;
  }
`

const LightQuestionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.2rem;
  border: none;
  background: none;
  outline: none;
  cursor: default;
  border-radius: 36px;
  width: 24px;
  height: 24px;
  background-color: rgba(255, 255, 255, 0.1);
  color: ${({ theme }) => theme.white};

  :hover,
  :focus {
    opacity: 0.7;
  }
`

const QuestionMark = styled.span`
  font-size: 1rem;
`

const BalanceTooltip = styled.span`
  margin-left: 3px;
  margin-top: 0;
`

const StyledTooltip = styled(props => (
  <Tooltip classes={{ popper: props.className }} {...props}>
    {props.children}
  </Tooltip>
))`
  & .MuiTooltip-tooltip {
    font-family: 'Open Sans', sans-serif !important;
    font-size: 11px !important;
    background-color: #3d3e54 !important;
    box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
  }
`

const StyledInfoIcon = styled(HelpOutlinedIcon)`
  font-family: 'Open Sans', sans-serif !important;
  font-size: 15px !important;
  font-weight: 300 !important;
  color: #606375 !important;
  cursor: pointer !important;
  margin-bottom: -2px !important;
`

export default function QuestionHelper({ text }: { text?: string }) {
  return text ? (
    <BalanceTooltip>
      <StyledTooltip title={text} placement='right'>
        <StyledInfoIcon />
      </StyledTooltip>
    </BalanceTooltip>
  ) : (
    <div />
  )
}

export function LightQuestionHelper({ text }: { text: string }) {
  return text ? (
    <BalanceTooltip>
      <StyledTooltip title={text} placement='right'>
        <StyledInfoIcon />
      </StyledTooltip>
    </BalanceTooltip>
  ) : (
    <div />
  )
}
