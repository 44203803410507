import { WalletState } from '@web3-onboard/core'
import React from 'react'
import styled from 'styled-components/macro'

const IconWrapper = styled.div<{ size?: number }>`
  ${({ theme }) => theme.flexColumnNoWrap};
  align-items: center;
  justify-content: center;
  margin-right: 4px;

  & > img,
  span {
    height: ${({ size }) => (size ? size + 'px' : '32px')};
    width: ${({ size }) => (size ? size + 'px' : '32px')};
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
    align-items: flex-end;
  `};
`

interface Props {
  wallet: WalletState | null
}

function WalletIconComparator(prevProps: Props, nextProps: Props) {
  return prevProps.wallet === nextProps.wallet
}

function WalletIcon({ wallet }: Props) {
  const icon = wallet?.icon.trim()
  if (wallet && icon && !icon.startsWith('<svg')) {
    return (
      <IconWrapper size={20}>
        {!icon.startsWith('<svg') ? (
          <img src={icon} alt={wallet.label} />
        ) : (
          <span dangerouslySetInnerHTML={{ __html: icon }} />
        )}
      </IconWrapper>
    )
  } else if (wallet) {
    return <div />
  }
  return null
}

export default React.memo(WalletIcon, WalletIconComparator)
