import { CollateralActionType, LoanActionType, ManageType } from '../types'
import styled from 'styled-components/macro'
import React from 'react'
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight'

const AdvancedDescriptionWrapper = styled.div`
  font-size: 12px;
  width: 100%;
  color: ${({ theme }) => theme.text2};
  margin-top: 5px;
`

const ArrowWrapper = styled.div`
  width: 15px;
  display: inline-block;
  vertical-align: top;

  svg {
    width: 100%;
    height: auto;
  }
`

const AdvancedDescriptionContent = styled.div`
  width: calc(100% - 18px);
  margin-left: 3px;
  display: inline-block;
  vertical-align: top;
  text-align: left;
`

interface AdvancedDescriptionProps {
  manageType: ManageType
  selectedTab: CollateralActionType | LoanActionType
  asset1: string | undefined
  amount1: string | undefined
  asset2: string | undefined
  amount2: string | undefined
}

function AdvancedDescriptionComparator(prevProps: AdvancedDescriptionProps, nextProps: AdvancedDescriptionProps) {
  return (
    prevProps.manageType === nextProps.manageType &&
    prevProps.selectedTab === nextProps.selectedTab &&
    prevProps.asset1 === nextProps.asset1 &&
    prevProps.amount1 === nextProps.amount1 &&
    prevProps.asset2 === nextProps.asset2 &&
    prevProps.amount2 === nextProps.amount2
  )
}

function AdvancedDescription({ manageType, selectedTab, asset1, amount1, asset2, amount2 }: AdvancedDescriptionProps) {
  if (!asset1 || !asset2) {
    return (
      <AdvancedDescriptionWrapper>
        <ArrowWrapper>
          <SubdirectoryArrowRightIcon />
        </ArrowWrapper>
        <AdvancedDescriptionContent>
          There is no valid{!asset1 ? ' input ' : ' output '}asset for this Zap
        </AdvancedDescriptionContent>
      </AdvancedDescriptionWrapper>
    )
  }

  return (
    <AdvancedDescriptionWrapper>
      <ArrowWrapper>
        <SubdirectoryArrowRightIcon />
      </ArrowWrapper>
      <AdvancedDescriptionContent>
        {manageType === ManageType.LOAN
          ? selectedTab === LoanActionType.BORROW
            ? `Will borrow ${amount1 ? `${amount1} ` : ''}${asset1}, trade that ${asset1} into ${
                amount2 ? `${amount2} ` : ''
              }${asset2}, and then deposit that ${asset2} into the position as collateral.`
            : !asset1
            ? `No assets to be repaid`
            : selectedTab === LoanActionType.REPAY
            ? `Will withdraw ${
                amount1 ? `${amount1} ` : ''
              }${asset1} from this position's collateral, trade that ${asset1} into ${
                amount2 ? `${amount2} ` : ''
              }${asset2}, and then repay ${
                amount2 ? `${amount2}` : ''
              } ${asset2} debt. Any excess will be added to collateral.`
            : `Will borrow ${amount1 ? `${amount1} ` : ''}${asset1}, trade that ${asset1} into ~${
                amount2 ? `${amount2} ` : ''
              }${asset2}, and then use this to repay ~${
                amount2 ? `${amount2}` : ''
              } ${asset2} of the position's debt. Any excess will be added to collateral.`
          : selectedTab === CollateralActionType.DEPOSIT
          ? `Will withdraw ${
              amount1 ? `${amount1} ` : ''
            }${asset1} from your Dolomite Balance, trade that ${asset1} into ${
              amount2 ? `${amount2} ` : ''
            }${asset2}, and then add that ${asset2} to your borrow position's collateral.`
          : selectedTab === CollateralActionType.WITHDRAW
          ? `Will withdraw ${
              amount1 ? `${amount1} ` : ''
            }${asset1} from this position's collateral, trade that ${asset1} into ${
              amount2 ? `${amount2} ` : ''
            }${asset2}, and then add that ${asset2} to your Dolomite Balance.`
          : `Will withdraw ${
              amount1 ? `${amount1} ` : ''
            }${asset1} from this position's collateral,  trade that ${asset1} into ${
              amount2 ? `${amount2} ` : ''
            }${asset2}, and then add that ${asset2} to your borrow position's collateral.`}
      </AdvancedDescriptionContent>
    </AdvancedDescriptionWrapper>
  )
}

export default React.memo(AdvancedDescription, AdvancedDescriptionComparator)
