import { Currency, CurrencyAmount, Token } from '@dolomite-exchange/v2-sdk'
import { USDM } from '../constants/tokens/USDM'
import { ChainId, W_USDM } from '../constants'
import { useMemo } from 'react'
import { useEther } from '../hooks/Tokens'

export function isExternalToken(token: Token | undefined): boolean {
  if (!token) {
    return false
  }
  const usdm = USDM[token.chainId as ChainId]
  return usdm?.equals(token) ?? false
}

export function isListedTokenForExternalToken(token: Token | undefined): boolean {
  if (!token) {
    return false
  }
  const wusdm = W_USDM[token.chainId as ChainId]
  return wusdm?.equals(token) ?? false
}

export function mapExternalTokenToListedAddress(token: Token): string {
  return mapExternalTokenToListedToken(token).address
}

export function mapExternalTokenToListedToken(token: Token): Token {
  const usdm = USDM[token.chainId as ChainId]
  const wusdm = W_USDM[token.chainId as ChainId]
  if (wusdm && usdm && token.equals(usdm)) {
    return wusdm
  }

  return token
}

export function mapListedTokenToExternalToken(token: Token): Token {
  const usdm = USDM[token.chainId as ChainId]
  const wusdm = W_USDM[token.chainId as ChainId]
  if (wusdm && usdm && token.equals(wusdm)) {
    return usdm
  }

  return token
}

export function mapExternalCurrencyOptToListedToken(currency: Currency | undefined): Currency | undefined {
  if (!currency || currency.isNative) {
    return currency
  }
  const token = currency.wrapped
  const usdm = USDM[token.chainId as ChainId]
  const wusdm = W_USDM[token.chainId as ChainId]
  if (wusdm && usdm && token.equals(usdm)) {
    return wusdm
  }

  return token
}

export function getExternalTokens(chainId: ChainId): Token[] {
  return [USDM[chainId]].reduce((memo, t) => (t ? memo.concat(t) : memo), [] as Token[])
}

export function useAddExternalTokensToDolomiteData<T>(dolomiteDataMap: Record<string, T>): Record<string, T> {
  const ether = useEther()
  return useMemo(() => {
    return {
      ...dolomiteDataMap,
      [ether.symbol ?? '']: dolomiteDataMap[ether.wrapped.address],
    }
  }, [dolomiteDataMap, ether])
}

export function useAddEtherBalanceToWalletBalances(
  etherBalance: CurrencyAmount<Currency> | undefined,
  walletDataMap: Record<string, CurrencyAmount<Token> | undefined>,
): Record<string, CurrencyAmount<Currency> | undefined> {
  return useMemo(() => {
    const newMap = Object.keys(walletDataMap).reduce<Record<string, CurrencyAmount<Currency> | undefined>>(
      (map, token) => {
        map[token] = walletDataMap[token]
        return map
      },
      {},
    )
    if (etherBalance) {
      newMap[etherBalance.currency.symbol ?? ''] = etherBalance
    }
    return newMap
  }, [etherBalance, walletDataMap])
}

export function useAddMappedDolomiteDataToExternalData(
  chainId: ChainId,
  etherBalance: CurrencyAmount<Currency> | undefined,
  externalDataMap: Record<string, CurrencyAmount<Token> | undefined>,
): Record<string, CurrencyAmount<Token> | undefined> {
  const ether = useEther()
  return useMemo(() => {
    const usdm = USDM[chainId]
    const wusdm = W_USDM[chainId]
    const result = {
      ...externalDataMap,
      [ether.wrapped.address]: etherBalance?.wrapped,
    }
    if (usdm && wusdm) {
      result[wusdm.address] = externalDataMap[usdm.address]
    }

    return result
  }, [etherBalance, externalDataMap, chainId, ether])
}
