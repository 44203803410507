import { useMemo } from 'react'
import { createCurrencyAmount, MarginAccountTokenValue as MarginAccountTokenValueGql } from './gqlTypeHelpers'
import { gql } from '@apollo/client'
import { useGraphqlResult, useGraphqlResultList } from '../state/graphql/hooks'
import { GraphqlCall, GraphqlClientType } from '../state/graphql/actions'
import { tokenGql } from './queryObjects'
import { CurrencyAmount, Token, ZERO } from '@dolomite-exchange/v2-sdk'
import { address } from '@dolomite-exchange/dolomite-margin/dist/src/types'
import { useMarketIndices } from '../hooks/useDolomiteMarginProtocol'
import { useActiveWeb3React } from '../hooks'
import { useArbToken, useBridgedUsedToken, useWethToken } from '../hooks/Tokens'
import { usePair } from '../data/Reserves'
import { useTokenBalance } from '../state/wallet/hooks'
import { useTotalSupply } from '../data/TotalSupply'
import { BIG_INT_ZERO } from '../constants'
import { RefreshFrequency } from '../state/chain/hooks'

const ALL_USER_SUPPLY_BY_TOKEN_DATA = gql`
    query allSupplyValues($account: String!, $tokenAddress: String!, $blockNumber: Int!) {
        marginAccountTokenValues(
            where: { effectiveUser: $account, token: $tokenAddress, valuePar_gt: "0" },
            block: { number_gte: $blockNumber }
        ) {
            token {
                ${tokenGql(false, false)}
            }
            valuePar
        }
    }
`

const ALL_VESTING_POSITIONS_DATA = gql`
  query allVestingPositions($account: String!, $blockNumber: Int!) {
    liquidityMiningVestingPositions(where: { owner: $account, status: "ACTIVE" }, block: { number_gte: $blockNumber }) {
      oTokenAmount
    }
  }
`

interface AllUserSupplyData {
  marginAccountTokenValues: MarginAccountTokenValueGql[]
}

interface VestingPositionsData {
  liquidityMiningVestingPositions: {
    oTokenAmount: string
  }[]
}

export function useAggregateBalanceData(
  account: string | undefined,
  tokens: Token[],
): {
  loading: boolean
  error: boolean
  data: Record<address, CurrencyAmount<Token> | undefined> | undefined
} {
  const { chainId } = useActiveWeb3React()
  const [marketIndices] = useMarketIndices()
  const variablesList = useMemo(() => {
    return tokens.map(token => {
      if (!account || tokens.length === 0) {
        return undefined
      }
      return {
        account: account.toLowerCase(),
        tokenAddress: token.address.toLowerCase(),
      }
    })
  }, [account, tokens])

  const calls = useMemo<GraphqlCall[]>(() => {
    return variablesList.map(variables => {
      return {
        chainId,
        clientType: GraphqlClientType.Dolomite,
        query: ALL_USER_SUPPLY_BY_TOKEN_DATA.loc!.source.body,
        variables: variables ? JSON.stringify(variables) : null,
      }
    })
  }, [chainId, variablesList])

  const allSupplyDataStates = useGraphqlResultList<AllUserSupplyData>(calls, RefreshFrequency.Medium)
  const allVestingPositionStates = useGraphqlResult<VestingPositionsData>(
    GraphqlClientType.Dolomite,
    ALL_VESTING_POSITIONS_DATA.loc!.source.body,
    variablesList[0],
    RefreshFrequency.Medium,
  )
  const arbToken = useArbToken()
  const wethToken = useWethToken()
  const bridgedUsdcToken = useBridgedUsedToken()
  const [, pair] = usePair(wethToken, bridgedUsdcToken)
  const lpBalance = useTokenBalance(account, pair?.liquidityToken)
  const totalSupply = useTotalSupply(pair?.liquidityToken)

  return useMemo(() => {
    const anyLoading = Boolean(allSupplyDataStates.some(s => s.loading) || allVestingPositionStates.loading)
    const anyError = Boolean(allSupplyDataStates.some(s => s.error) || allVestingPositionStates.error)

    const tokenToAmountMap = allSupplyDataStates.reduce<Record<string, CurrencyAmount<Token> | undefined>>(
      (memo, state, i) => {
        const token = tokens[i]
        const initialValue = CurrencyAmount.fromRawAmount(token, ZERO)
        const index = marketIndices[token.address]
        if (!index) {
          memo[token.address] = initialValue
          return memo
        }
        const aggregateAmount = state.result?.marginAccountTokenValues.reduce((acc, value) => {
          const amount = createCurrencyAmount(token, value.valuePar)
          return acc.add(amount.multiply(index.supplyIndex))
        }, initialValue)

        memo[token.address] = aggregateAmount ?? initialValue

        return memo
      },
      {},
    )

    if (arbToken) {
      if (!tokenToAmountMap[arbToken.address]) {
        tokenToAmountMap[arbToken.address] = CurrencyAmount.fromRawAmount(arbToken, BIG_INT_ZERO)
      }

      allVestingPositionStates.result?.liquidityMiningVestingPositions.forEach(position => {
        // The oTokenAmount is the same as the ARB amount (because of the pairing)
        tokenToAmountMap[arbToken.address] = tokenToAmountMap[arbToken.address]!.add(
          createCurrencyAmount(arbToken, position.oTokenAmount),
        )
      })
    }

    if (wethToken && pair && lpBalance && totalSupply) {
      tokenToAmountMap[wethToken.address] =
        tokenToAmountMap[wethToken.address] ?? CurrencyAmount.fromRawAmount(wethToken, BIG_INT_ZERO)
      tokenToAmountMap[wethToken.address] = tokenToAmountMap[wethToken.address]!.add(
        pair
          .reserveOf(wethToken)
          .multiply(lpBalance)
          .divide(totalSupply),
      )
    }

    return {
      loading: anyLoading,
      error: anyError,
      data: tokenToAmountMap,
    }
  }, [
    allSupplyDataStates,
    allVestingPositionStates,
    wethToken,
    pair,
    lpBalance,
    totalSupply,
    tokens,
    marketIndices,
    arbToken,
  ])
}
