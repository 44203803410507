import { ethers } from 'ethers'
import {
  ARB_PROXY_VAULT_INIT_HASHES,
  GLP_PROXY_VAULT_INIT_HASHES,
  GM_AAVE_USD_PROXY_VAULT_INIT_HASHES,
  GM_ARB_USD_PROXY_VAULT_INIT_HASHES,
  GM_BTC_PROXY_VAULT_INIT_HASHES,
  GM_BTC_USD_PROXY_VAULT_INIT_HASHES,
  GM_DOGE_USD_PROXY_VAULT_INIT_HASHES,
  GM_ETH_PROXY_VAULT_INIT_HASHES,
  GM_ETH_USD_PROXY_VAULT_INIT_HASHES,
  GM_GMX_PROXY_VAULT_INIT_HASHES,
  GM_GMX_USD_PROXY_VAULT_INIT_HASHES,
  GM_LINK_USD_PROXY_VAULT_INIT_HASHES,
  GM_PENDLE_USD_PROXY_VAULT_INIT_HASHES,
  GM_PEPE_USD_PROXY_VAULT_INIT_HASHES,
  GM_SOL_USD_PROXY_VAULT_INIT_HASHES,
  GM_UNI_USD_PROXY_VAULT_INIT_HASHES,
  GM_WIF_USD_PROXY_VAULT_INIT_HASHES,
  GM_WST_ETH_USD_PROXY_VAULT_INIT_HASHES,
  GMX_PROXY_VAULT_INIT_HASHES,
  JONES_USDC_PROXY_VAULT_INIT_HASHES,
  PENDLE_PT_CM_ETH_FEB_2025_PROXY_VAULT_INIT_HASHES,
  PENDLE_PT_EZ_ETH_JUN_2024_PROXY_VAULT_INIT_HASHES,
  PENDLE_PT_EZ_ETH_SEP_2024_PROXY_VAULT_INIT_HASHES,
  PENDLE_PT_GLP_MAR_2024_PROXY_VAULT_INIT_HASHES,
  PENDLE_PT_GLP_SEP_2024_PROXY_VAULT_INIT_HASHES,
  PENDLE_PT_METH_DEC_2024_PROXY_VAULT_INIT_HASHES,
  PENDLE_PT_MNT_OCT_2024_PROXY_VAULT_INIT_HASHES,
  PENDLE_PT_R_ETH_JUN_2025_PROXY_VAULT_INIT_HASHES,
  PENDLE_PT_RS_ETH_DEC_2024_PROXY_VAULT_INIT_HASHES,
  PENDLE_PT_RS_ETH_SEP_2024_PROXY_VAULT_INIT_HASHES,
  PENDLE_PT_USDE_DEC_2024_PROXY_VAULT_INIT_HASHES,
  PENDLE_PT_USDE_JUL_2024_PROXY_VAULT_INIT_HASHES,
  PENDLE_PT_WE_ETH_APR_2024_PROXY_VAULT_INIT_HASHES,
  PENDLE_PT_WE_ETH_DEC_2024_PROXY_VAULT_INIT_HASHES,
  PENDLE_PT_WE_ETH_JUN_2024_PROXY_VAULT_INIT_HASHES,
  PENDLE_PT_WE_ETH_SEP_2024_PROXY_VAULT_INIT_HASHES,
  PENDLE_PT_WST_ETH_JUN_2024_PROXY_VAULT_INIT_HASHES,
  PENDLE_PT_WST_ETH_JUN_2025_PROXY_VAULT_INIT_HASHES,
  PENDLE_YT_GLP_PROXY_VAULT_INIT_HASHES,
  PLV_GLP_PROXY_VAULT_INIT_HASHES,
  WMNT_PROXY_VAULT_INIT_HASHES,
} from '../../constants/isolation/proxy-vault-init-hashes'
import {
  ARB_ISOLATION_MODE_ADDRESSES,
  ChainId,
  FS_GLP_ISOLATION_MODE_ADDRESSES,
  GLV_BTC_ISOLATION_MODE_ADDRESSES,
  GLV_BTC_PROXY_VAULT_INIT_HASHES,
  GLV_ETH_ISOLATION_MODE_ADDRESSES,
  GLV_ETH_PROXY_VAULT_INIT_HASHES,
  GM_AAVE_USD_ISOLATION_MODE_ADDRESSES,
  GM_ARB_USD_ISOLATION_MODE_ADDRESSES,
  GM_BTC_ISOLATION_MODE_ADDRESSES,
  GM_BTC_USD_ISOLATION_MODE_ADDRESSES,
  GM_DOGE_USD_ISOLATION_MODE_ADDRESSES,
  GM_ETH_ISOLATION_MODE_ADDRESSES,
  GM_ETH_USD_ISOLATION_MODE_ADDRESSES,
  GM_GMX_ISOLATION_MODE_ADDRESSES,
  GM_GMX_USD_ISOLATION_MODE_ADDRESSES,
  GM_LINK_USD_ISOLATION_MODE_ADDRESSES,
  GM_PENDLE_USD_ISOLATION_MODE_ADDRESSES,
  GM_PEPE_USD_ISOLATION_MODE_ADDRESSES,
  GM_SOL_USD_ISOLATION_MODE_ADDRESSES,
  GM_UNI_USD_ISOLATION_MODE_ADDRESSES,
  GM_WIF_USD_ISOLATION_MODE_ADDRESSES,
  GM_WST_ETH_USD_ISOLATION_MODE_ADDRESSES,
  GMX_ISOLATION_MODE_ADDRESSES,
  JONES_USDC_ISOLATION_MODE_ADDRESSES,
  PENDLE_PT_CM_ETH_FEB_2025_ISOLATION_MODE_ADDRESSES,
  PENDLE_PT_EZ_ETH_JUN_2024_ISOLATION_MODE_ADDRESSES,
  PENDLE_PT_EZ_ETH_SEP_2024_ISOLATION_MODE_ADDRESSES,
  PENDLE_PT_GLP_MAR_2024_ISOLATION_MODE_ADDRESSES,
  PENDLE_PT_GLP_SEP_2024_ISOLATION_MODE_ADDRESSES,
  PENDLE_PT_METH_DEC_2024_ISOLATION_MODE_ADDRESSES,
  PENDLE_PT_MNT_OCT_2024_ISOLATION_MODE_ADDRESSES,
  PENDLE_PT_R_ETH_JUN_2025_ISOLATION_MODE_ADDRESSES,
  PENDLE_PT_RS_ETH_DEC_2024_ISOLATION_MODE_ADDRESSES,
  PENDLE_PT_RS_ETH_SEP_2024_ISOLATION_MODE_ADDRESSES,
  PENDLE_PT_USDE_DEC_2024_ISOLATION_MODE_ADDRESSES,
  PENDLE_PT_USDE_JUL_2024_ISOLATION_MODE_ADDRESSES,
  PENDLE_PT_WE_ETH_APR_2024_ISOLATION_MODE_ADDRESSES,
  PENDLE_PT_WE_ETH_DEC_2024_ISOLATION_MODE_ADDRESSES,
  PENDLE_PT_WE_ETH_JUN_2024_ISOLATION_MODE_ADDRESSES,
  PENDLE_PT_WE_ETH_SEP_2024_ISOLATION_MODE_ADDRESSES,
  PENDLE_PT_WST_ETH_JUN_2024_ISOLATION_MODE_ADDRESSES,
  PENDLE_PT_WST_ETH_JUN_2025_ISOLATION_MODE_ADDRESSES,
  PENDLE_YT_GLP_MAR_2024_ISOLATION_MODE_ADDRESSES,
  PLV_GLP_ISOLATION_MODE_ADDRESSES,
  WMNT_ISOLATION_MODE_ADDRESSES,
} from '../../constants'

export function calculateARBProxyVaultAddress(account: string | undefined, chainId: ChainId): string | undefined {
  const factoryAddress = ARB_ISOLATION_MODE_ADDRESSES[chainId]
  const initHash = ARB_PROXY_VAULT_INIT_HASHES[chainId]
  return calculateProxyVaultAddress(account, factoryAddress, initHash)
}

export function calculateGLPProxyVaultAddress(account: string | undefined, chainId: ChainId): string | undefined {
  const factoryAddress = FS_GLP_ISOLATION_MODE_ADDRESSES[chainId]
  const initHash = GLP_PROXY_VAULT_INIT_HASHES[chainId]
  return calculateProxyVaultAddress(account, factoryAddress, initHash)
}

export function calculateGlvBTCUSDProxyVaultAddress(account: string | undefined, chainId: ChainId): string | undefined {
  const factoryAddress = GLV_BTC_ISOLATION_MODE_ADDRESSES[chainId]
  const initHash = GLV_BTC_PROXY_VAULT_INIT_HASHES[chainId]
  return calculateProxyVaultAddress(account, factoryAddress, initHash)
}

export function calculateGlvETHUSDProxyVaultAddress(account: string | undefined, chainId: ChainId): string | undefined {
  const factoryAddress = GLV_ETH_ISOLATION_MODE_ADDRESSES[chainId]
  const initHash = GLV_ETH_PROXY_VAULT_INIT_HASHES[chainId]
  return calculateProxyVaultAddress(account, factoryAddress, initHash)
}

export function calculateGmAAVEUSDProxyVaultAddress(account: string | undefined, chainId: ChainId): string | undefined {
  const factoryAddress = GM_AAVE_USD_ISOLATION_MODE_ADDRESSES[chainId]
  const initHash = GM_AAVE_USD_PROXY_VAULT_INIT_HASHES[chainId]
  return calculateProxyVaultAddress(account, factoryAddress, initHash)
}

export function calculateGmARBUSDProxyVaultAddress(account: string | undefined, chainId: ChainId): string | undefined {
  const factoryAddress = GM_ARB_USD_ISOLATION_MODE_ADDRESSES[chainId]
  const initHash = GM_ARB_USD_PROXY_VAULT_INIT_HASHES[chainId]
  return calculateProxyVaultAddress(account, factoryAddress, initHash)
}

export function calculateGmBTCProxyVaultAddress(account: string | undefined, chainId: ChainId): string | undefined {
  const factoryAddress = GM_BTC_ISOLATION_MODE_ADDRESSES[chainId]
  const initHash = GM_BTC_PROXY_VAULT_INIT_HASHES[chainId]
  return calculateProxyVaultAddress(account, factoryAddress, initHash)
}

export function calculateGmBTCUSDProxyVaultAddress(account: string | undefined, chainId: ChainId): string | undefined {
  const factoryAddress = GM_BTC_USD_ISOLATION_MODE_ADDRESSES[chainId]
  const initHash = GM_BTC_USD_PROXY_VAULT_INIT_HASHES[chainId]
  return calculateProxyVaultAddress(account, factoryAddress, initHash)
}

export function calculateGmDOGEUSDProxyVaultAddress(account: string | undefined, chainId: ChainId): string | undefined {
  const factoryAddress = GM_DOGE_USD_ISOLATION_MODE_ADDRESSES[chainId]
  const initHash = GM_DOGE_USD_PROXY_VAULT_INIT_HASHES[chainId]
  return calculateProxyVaultAddress(account, factoryAddress, initHash)
}

export function calculateGmETHProxyVaultAddress(account: string | undefined, chainId: ChainId): string | undefined {
  const factoryAddress = GM_ETH_ISOLATION_MODE_ADDRESSES[chainId]
  const initHash = GM_ETH_PROXY_VAULT_INIT_HASHES[chainId]
  return calculateProxyVaultAddress(account, factoryAddress, initHash)
}

export function calculateGmETHUSDProxyVaultAddress(account: string | undefined, chainId: ChainId): string | undefined {
  const factoryAddress = GM_ETH_USD_ISOLATION_MODE_ADDRESSES[chainId]
  const initHash = GM_ETH_USD_PROXY_VAULT_INIT_HASHES[chainId]
  return calculateProxyVaultAddress(account, factoryAddress, initHash)
}

export function calculateGmGMXProxyVaultAddress(account: string | undefined, chainId: ChainId): string | undefined {
  const factoryAddress = GM_GMX_ISOLATION_MODE_ADDRESSES[chainId]
  const initHash = GM_GMX_PROXY_VAULT_INIT_HASHES[chainId]
  return calculateProxyVaultAddress(account, factoryAddress, initHash)
}

export function calculateGmGMXUSDProxyVaultAddress(account: string | undefined, chainId: ChainId): string | undefined {
  const factoryAddress = GM_GMX_USD_ISOLATION_MODE_ADDRESSES[chainId]
  const initHash = GM_GMX_USD_PROXY_VAULT_INIT_HASHES[chainId]
  return calculateProxyVaultAddress(account, factoryAddress, initHash)
}

export function calculateGmLINKUSDProxyVaultAddress(account: string | undefined, chainId: ChainId): string | undefined {
  const factoryAddress = GM_LINK_USD_ISOLATION_MODE_ADDRESSES[chainId]
  const initHash = GM_LINK_USD_PROXY_VAULT_INIT_HASHES[chainId]
  return calculateProxyVaultAddress(account, factoryAddress, initHash)
}

export function calculateGmPENDLEUSDProxyVaultAddress(
  account: string | undefined,
  chainId: ChainId,
): string | undefined {
  const factoryAddress = GM_PENDLE_USD_ISOLATION_MODE_ADDRESSES[chainId]
  const initHash = GM_PENDLE_USD_PROXY_VAULT_INIT_HASHES[chainId]
  return calculateProxyVaultAddress(account, factoryAddress, initHash)
}

export function calculateGmPEPEUSDProxyVaultAddress(account: string | undefined, chainId: ChainId): string | undefined {
  const factoryAddress = GM_PEPE_USD_ISOLATION_MODE_ADDRESSES[chainId]
  const initHash = GM_PEPE_USD_PROXY_VAULT_INIT_HASHES[chainId]
  return calculateProxyVaultAddress(account, factoryAddress, initHash)
}

export function calculateGmSOLUSDProxyVaultAddress(account: string | undefined, chainId: ChainId): string | undefined {
  const factoryAddress = GM_SOL_USD_ISOLATION_MODE_ADDRESSES[chainId]
  const initHash = GM_SOL_USD_PROXY_VAULT_INIT_HASHES[chainId]
  return calculateProxyVaultAddress(account, factoryAddress, initHash)
}

export function calculateGmUNIUSDProxyVaultAddress(account: string | undefined, chainId: ChainId): string | undefined {
  const factoryAddress = GM_UNI_USD_ISOLATION_MODE_ADDRESSES[chainId]
  const initHash = GM_UNI_USD_PROXY_VAULT_INIT_HASHES[chainId]
  return calculateProxyVaultAddress(account, factoryAddress, initHash)
}

export function calculateGmWIFUSDProxyVaultAddress(account: string | undefined, chainId: ChainId): string | undefined {
  const factoryAddress = GM_WIF_USD_ISOLATION_MODE_ADDRESSES[chainId]
  const initHash = GM_WIF_USD_PROXY_VAULT_INIT_HASHES[chainId]
  return calculateProxyVaultAddress(account, factoryAddress, initHash)
}

export function calculateGmWstETHUSDProxyVaultAddress(
  account: string | undefined,
  chainId: ChainId,
): string | undefined {
  const factoryAddress = GM_WST_ETH_USD_ISOLATION_MODE_ADDRESSES[chainId]
  const initHash = GM_WST_ETH_USD_PROXY_VAULT_INIT_HASHES[chainId]
  return calculateProxyVaultAddress(account, factoryAddress, initHash)
}

export function calculateGMXProxyVaultAddress(account: string | undefined, chainId: ChainId): string | undefined {
  const factoryAddress = GMX_ISOLATION_MODE_ADDRESSES[chainId]
  const initHash = GMX_PROXY_VAULT_INIT_HASHES[chainId]
  return calculateProxyVaultAddress(account, factoryAddress, initHash)
}

export function calculateJonesUSDCProxyVaultAddress(account: string | undefined, chainId: ChainId): string | undefined {
  const factoryAddress = JONES_USDC_ISOLATION_MODE_ADDRESSES[chainId]
  const initHash = JONES_USDC_PROXY_VAULT_INIT_HASHES[chainId]
  return calculateProxyVaultAddress(account, factoryAddress, initHash)
}

export function calculatePendlePtCmEthFeb2025ProxyVaultAddress(
  account: string | undefined,
  chainId: ChainId,
): string | undefined {
  const factoryAddress = PENDLE_PT_CM_ETH_FEB_2025_ISOLATION_MODE_ADDRESSES[chainId]
  const initHash = PENDLE_PT_CM_ETH_FEB_2025_PROXY_VAULT_INIT_HASHES[chainId]
  return calculateProxyVaultAddress(account, factoryAddress, initHash)
}

export function calculatePendlePtEzEthJun2024ProxyVaultAddress(
  account: string | undefined,
  chainId: ChainId,
): string | undefined {
  const factoryAddress = PENDLE_PT_EZ_ETH_JUN_2024_ISOLATION_MODE_ADDRESSES[chainId]
  const initHash = PENDLE_PT_EZ_ETH_JUN_2024_PROXY_VAULT_INIT_HASHES[chainId]
  return calculateProxyVaultAddress(account, factoryAddress, initHash)
}

export function calculatePendlePtEzEthSep2024ProxyVaultAddress(
  account: string | undefined,
  chainId: ChainId,
): string | undefined {
  const factoryAddress = PENDLE_PT_EZ_ETH_SEP_2024_ISOLATION_MODE_ADDRESSES[chainId]
  const initHash = PENDLE_PT_EZ_ETH_SEP_2024_PROXY_VAULT_INIT_HASHES[chainId]
  return calculateProxyVaultAddress(account, factoryAddress, initHash)
}

export function calculatePendlePtGLPMar2024ProxyVaultAddress(
  account: string | undefined,
  chainId: ChainId,
): string | undefined {
  const factoryAddress = PENDLE_PT_GLP_MAR_2024_ISOLATION_MODE_ADDRESSES[chainId]
  const initHash = PENDLE_PT_GLP_MAR_2024_PROXY_VAULT_INIT_HASHES[chainId]
  return calculateProxyVaultAddress(account, factoryAddress, initHash)
}

export function calculatePendlePtGLPSep2024ProxyVaultAddress(
  account: string | undefined,
  chainId: ChainId,
): string | undefined {
  const factoryAddress = PENDLE_PT_GLP_SEP_2024_ISOLATION_MODE_ADDRESSES[chainId]
  const initHash = PENDLE_PT_GLP_SEP_2024_PROXY_VAULT_INIT_HASHES[chainId]
  return calculateProxyVaultAddress(account, factoryAddress, initHash)
}

export function calculatePendlePtMethDec2024ProxyVaultAddress(
  account: string | undefined,
  chainId: ChainId,
): string | undefined {
  const factoryAddress = PENDLE_PT_METH_DEC_2024_ISOLATION_MODE_ADDRESSES[chainId]
  const initHash = PENDLE_PT_METH_DEC_2024_PROXY_VAULT_INIT_HASHES[chainId]
  return calculateProxyVaultAddress(account, factoryAddress, initHash)
}

export function calculatePendlePtMntOct2024ProxyVaultAddress(
  account: string | undefined,
  chainId: ChainId,
): string | undefined {
  const factoryAddress = PENDLE_PT_MNT_OCT_2024_ISOLATION_MODE_ADDRESSES[chainId]
  const initHash = PENDLE_PT_MNT_OCT_2024_PROXY_VAULT_INIT_HASHES[chainId]
  return calculateProxyVaultAddress(account, factoryAddress, initHash)
}

export function calculatePendlePtREthJun2025ProxyVaultAddress(
  account: string | undefined,
  chainId: ChainId,
): string | undefined {
  const factoryAddress = PENDLE_PT_R_ETH_JUN_2025_ISOLATION_MODE_ADDRESSES[chainId]
  const initHash = PENDLE_PT_R_ETH_JUN_2025_PROXY_VAULT_INIT_HASHES[chainId]
  return calculateProxyVaultAddress(account, factoryAddress, initHash)
}

export function calculatePendlePtRsEthDec2024ProxyVaultAddress(
  account: string | undefined,
  chainId: ChainId,
): string | undefined {
  const factoryAddress = PENDLE_PT_RS_ETH_DEC_2024_ISOLATION_MODE_ADDRESSES[chainId]
  const initHash = PENDLE_PT_RS_ETH_DEC_2024_PROXY_VAULT_INIT_HASHES[chainId]
  return calculateProxyVaultAddress(account, factoryAddress, initHash)
}

export function calculatePendlePtRsEthSep2024ProxyVaultAddress(
  account: string | undefined,
  chainId: ChainId,
): string | undefined {
  const factoryAddress = PENDLE_PT_RS_ETH_SEP_2024_ISOLATION_MODE_ADDRESSES[chainId]
  const initHash = PENDLE_PT_RS_ETH_SEP_2024_PROXY_VAULT_INIT_HASHES[chainId]
  return calculateProxyVaultAddress(account, factoryAddress, initHash)
}

export function calculatePendlePtUSDeDec2024ProxyVaultAddress(
  account: string | undefined,
  chainId: ChainId,
): string | undefined {
  const factoryAddress = PENDLE_PT_USDE_DEC_2024_ISOLATION_MODE_ADDRESSES[chainId]
  const initHash = PENDLE_PT_USDE_DEC_2024_PROXY_VAULT_INIT_HASHES[chainId]
  return calculateProxyVaultAddress(account, factoryAddress, initHash)
}

export function calculatePendlePtUSDeJul2024ProxyVaultAddress(
  account: string | undefined,
  chainId: ChainId,
): string | undefined {
  const factoryAddress = PENDLE_PT_USDE_JUL_2024_ISOLATION_MODE_ADDRESSES[chainId]
  const initHash = PENDLE_PT_USDE_JUL_2024_PROXY_VAULT_INIT_HASHES[chainId]
  return calculateProxyVaultAddress(account, factoryAddress, initHash)
}

export function calculatePendlePtWeEthApr2024ProxyVaultAddress(
  account: string | undefined,
  chainId: ChainId,
): string | undefined {
  const factoryAddress = PENDLE_PT_WE_ETH_APR_2024_ISOLATION_MODE_ADDRESSES[chainId]
  const initHash = PENDLE_PT_WE_ETH_APR_2024_PROXY_VAULT_INIT_HASHES[chainId]
  return calculateProxyVaultAddress(account, factoryAddress, initHash)
}

export function calculatePendlePtWeEthDec2024ProxyVaultAddress(
  account: string | undefined,
  chainId: ChainId,
): string | undefined {
  const factoryAddress = PENDLE_PT_WE_ETH_DEC_2024_ISOLATION_MODE_ADDRESSES[chainId]
  const initHash = PENDLE_PT_WE_ETH_DEC_2024_PROXY_VAULT_INIT_HASHES[chainId]
  return calculateProxyVaultAddress(account, factoryAddress, initHash)
}

export function calculatePendlePtWeEthJun2024ProxyVaultAddress(
  account: string | undefined,
  chainId: ChainId,
): string | undefined {
  const factoryAddress = PENDLE_PT_WE_ETH_JUN_2024_ISOLATION_MODE_ADDRESSES[chainId]
  const initHash = PENDLE_PT_WE_ETH_JUN_2024_PROXY_VAULT_INIT_HASHES[chainId]
  return calculateProxyVaultAddress(account, factoryAddress, initHash)
}

export function calculatePendlePtWeEthSep2024ProxyVaultAddress(
  account: string | undefined,
  chainId: ChainId,
): string | undefined {
  const factoryAddress = PENDLE_PT_WE_ETH_SEP_2024_ISOLATION_MODE_ADDRESSES[chainId]
  const initHash = PENDLE_PT_WE_ETH_SEP_2024_PROXY_VAULT_INIT_HASHES[chainId]
  return calculateProxyVaultAddress(account, factoryAddress, initHash)
}

export function calculatePendlePtWstEthJun2024ProxyVaultAddress(
  account: string | undefined,
  chainId: ChainId,
): string | undefined {
  const factoryAddress = PENDLE_PT_WST_ETH_JUN_2024_ISOLATION_MODE_ADDRESSES[chainId]
  const initHash = PENDLE_PT_WST_ETH_JUN_2024_PROXY_VAULT_INIT_HASHES[chainId]
  return calculateProxyVaultAddress(account, factoryAddress, initHash)
}

export function calculatePendlePtWstEthJun2025ProxyVaultAddress(
  account: string | undefined,
  chainId: ChainId,
): string | undefined {
  const factoryAddress = PENDLE_PT_WST_ETH_JUN_2025_ISOLATION_MODE_ADDRESSES[chainId]
  const initHash = PENDLE_PT_WST_ETH_JUN_2025_PROXY_VAULT_INIT_HASHES[chainId]
  return calculateProxyVaultAddress(account, factoryAddress, initHash)
}

export function calculatePendleYtGLPProxyVaultAddress(
  account: string | undefined,
  chainId: ChainId,
): string | undefined {
  const factoryAddress = PENDLE_YT_GLP_MAR_2024_ISOLATION_MODE_ADDRESSES[chainId]
  const initHash = PENDLE_YT_GLP_PROXY_VAULT_INIT_HASHES[chainId]
  return calculateProxyVaultAddress(account, factoryAddress, initHash)
}

export function calculatePlvGLPProxyVaultAddress(account: string | undefined, chainId: ChainId): string | undefined {
  const factoryAddress = PLV_GLP_ISOLATION_MODE_ADDRESSES[chainId]
  const initHash = PLV_GLP_PROXY_VAULT_INIT_HASHES[chainId]
  return calculateProxyVaultAddress(account, factoryAddress, initHash)
}

export function calculateWMNTProxyVaultAddress(account: string | undefined, chainId: ChainId): string | undefined {
  const factoryAddress = WMNT_ISOLATION_MODE_ADDRESSES[chainId]
  const initHash = WMNT_PROXY_VAULT_INIT_HASHES[chainId]
  return calculateProxyVaultAddress(account, factoryAddress, initHash)
}

function calculateProxyVaultAddress(
  account: string | undefined,
  factoryAddress: string | undefined,
  initHash: string | undefined,
): string | undefined {
  if (!factoryAddress || !initHash || !account) {
    return undefined
  } else {
    return ethers.utils.getCreate2Address(factoryAddress, ethers.utils.keccak256(account), initHash)
  }
}
