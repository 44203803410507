import { createAction } from '@reduxjs/toolkit'
import { ChainId } from '../../constants'

export interface Call {
  chainId: ChainId
  address: string
  callData: string
  gasRequired?: number
}

const ADDRESS_REGEX = /^0x[a-fA-F0-9]{40}$/
const LOWER_HEX_REGEX = /^0x[a-f0-9]*$/

export function toMultiCallKey(call: Call): string {
  if (!ADDRESS_REGEX.test(call.address)) {
    throw new Error(`Invalid address: ${call.address}`)
  }
  if (!LOWER_HEX_REGEX.test(call.callData)) {
    throw new Error(`Invalid hex: ${call.callData}`)
  }
  return `${call.chainId}-${call.address}-${call.callData}`
}

export function parseCallKey(callKey: string): Call {
  const pcs = callKey.split('-')
  if (pcs.length !== 3) {
    throw new Error(`Invalid call key: ${callKey}`)
  }
  return {
    chainId: Number(pcs[0]),
    address: pcs[1],
    callData: pcs[2],
  }
}

export interface ListenerOptions {
  // how often this data should be fetched, by default 1
  readonly blocksPerFetch?: number
}

export const addMulticallListeners = createAction<{ chainId: ChainId; calls: Call[]; options?: ListenerOptions }>(
  'multicall/addMulticallListeners',
)
export const removeMulticallListeners = createAction<{ chainId: ChainId; calls: Call[]; options?: ListenerOptions }>(
  'multicall/removeMulticallListeners',
)
export const fetchingMulticallResults = createAction<{ chainId: ChainId; calls: Call[]; fetchingBlockNumber: number }>(
  'multicall/fetchingMulticallResults',
)
export const errorFetchingMulticallResults = createAction<{
  chainId: ChainId
  calls: Call[]
  fetchingBlockNumber: number
}>('multicall/errorFetchingMulticallResults')
export const updateMulticallResults = createAction<{
  chainId: ChainId
  blockNumber: number
  results: Record<string, string | null>
}>('multicall/updateMulticallResults')
