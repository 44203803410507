import { Currency } from '@dolomite-exchange/sdk-core'
import { getSpecialAsset } from '../constants/isolation/special-assets'
import { ChainId, ChainIdMap } from '../constants/chainId'

const keysToReplacements: ChainIdMap<Record<string, string | undefined>> = {
  [ChainId.MAINNET]: { WETH: 'Ethereum' },
  [ChainId.ARBITRUM_ONE]: { WETH: 'Ethereum' },
  [ChainId.BASE]: { WETH: 'Ethereum' },
  [ChainId.BERACHAIN]: { WBERA: 'Bera' },
  [ChainId.MANTLE]: { WMNT: 'Mantle' },
  [ChainId.POLYGON_ZKEVM]: { WETH: 'Ethereum' },
  [ChainId.X_LAYER]: { WOKB: 'OKB' },
}

export default function cleanCurrencyName(
  currency: Currency | undefined,
  cleanWrappedCurrency: boolean = true,
): string | undefined {
  if (currency) {
    if (keysToReplacements[currency.chainId as ChainId][currency.symbol ?? ''] && !cleanWrappedCurrency) {
      return currency.name
    }
  }
  if (currency?.isToken && getSpecialAsset(currency.chainId, currency)) {
    return getSpecialAsset(currency.chainId, currency)?.cleanName
  }
  return currency ? keysToReplacements[currency.chainId as ChainId][currency.symbol ?? ''] ?? currency?.name : undefined
}
