import {
  Currency,
  CurrencyAmount,
  Fraction,
  NativeCurrency,
  Price,
  Token,
  Trade,
  TradeType,
} from '@dolomite-exchange/v2-sdk'
import React, { useContext, useMemo, useState } from 'react'
import { Text } from 'rebass'
import styled, { ThemeContext } from 'styled-components/macro'
import { Field } from '../../state/trade/actions'
import { TYPE } from '../../theme'
import {
  computeSlippageAdjustedAmounts,
  computeTradePriceBreakdown,
  getFormattedPriceForUIFromTrade,
  warningSeverity,
} from '../../utils/prices'
import { ButtonError } from '../Button'
import { AutoColumn } from '../Column'
import QuestionHelper from '../QuestionHelper'
import { AutoRow, RowBetween, RowFixed } from '../Row'
import FormattedPriceImpact from './FormattedPriceImpact'
import { TradeCallbackError } from './styleds'
import { useMarketRiskInfoData } from '../../types/marketRiskInfoData'
import calculateLiquidationPrice from '../../utils/calculateLiquidationPrice'
import cleanCurrencySymbol from '../../utils/cleanCurrencySymbol'
import { StyledTooltipWithIcon } from '../common/StyledTooltip'
import { useDolomiteMarginData } from '../../types/dolomiteMarginData'
import { reqParseAmount } from '../../state/trade/hooks'
import useFormattedPrice from '../../hooks/useFormattedPrice'
import { useDeriveMarketFromTokens } from '../../utils/marketUtils'
import { formatAmount } from '../../utils/formatAmount'

const SwapWarning = styled.div`
  width: 100%;
  text-align: center;
  color: ${({ theme }) => theme.red1};
  font-size: 12px;
  font-weight: 500;
  margin-bottom: -5px;
`

const SubmitButton = styled(ButtonError)`
  background-color: #494a60;

  :hover {
    background-color: #4b4c64;
  }

  :focus {
    background-color: #494a60;
  }

  :active {
    background-color: #232330;
  }
`

export interface MarginInformation {
  longAsset: NativeCurrency | Token | undefined
  shortAsset: NativeCurrency | Token | undefined
  leverage: Fraction
  marginDeposit: CurrencyAmount<Token> | undefined
  marginHeldAmount: CurrencyAmount<Token> | undefined
  isClosing: boolean
}

export default function TradeModalFooter({
  trade,
  onConfirm,
  allowedSlippage,
  swapErrorMessage,
  disabledConfirm,
  leverage,
  marginInformation,
}: {
  trade: Trade<Currency, Currency, TradeType>
  allowedSlippage: number
  onConfirm: () => void
  swapErrorMessage: string | undefined
  disabledConfirm: boolean
  leverage: Fraction | undefined
  marginInformation?: MarginInformation
}) {
  const [showInverted, setShowInverted] = useState<boolean>(false)
  const theme = useContext(ThemeContext)
  const slippageAdjustedAmounts = useMemo(() => computeSlippageAdjustedAmounts(trade, allowedSlippage), [
    allowedSlippage,
    trade,
  ])
  const { priceImpactWithoutFee, realizedLPFee } = useMemo(() => computeTradePriceBreakdown(trade), [trade])
  const severity = warningSeverity(priceImpactWithoutFee)

  const { data: riskParams } = useDolomiteMarginData()
  const { data: marketRiskInfoMap } = useMarketRiskInfoData()

  const liquidationPrice = useMemo(() => {
    if (!marginInformation || leverage?.lessThanOrEqual('1')) {
      return undefined
    }

    const totalOutputAmount =
      marginInformation.marginDeposit && !marginInformation.isClosing
        ? trade.outputAmount.add(marginInformation.marginDeposit)
        : trade.outputAmount

    const inputToken = trade.inputAmount.currency.wrapped
    const outputToken = trade.outputAmount.currency.wrapped

    const liquidationPriceFraction = calculateLiquidationPrice(
      totalOutputAmount,
      trade.inputAmount,
      riskParams?.minCollateralization,
      marketRiskInfoMap[inputToken.address],
      marketRiskInfoMap[outputToken.address],
    )

    return liquidationPriceFraction.greaterThan('0')
      ? new Price({
          baseAmount: reqParseAmount(liquidationPriceFraction.toFixed(inputToken.decimals), inputToken),
          quoteAmount: reqParseAmount('1', outputToken),
        })
      : undefined
  }, [marginInformation, leverage, trade, riskParams, marketRiskInfoMap])

  const market = useDeriveMarketFromTokens(trade.inputAmount.currency.wrapped, trade.outputAmount.currency.wrapped)
  const formattedPrice = useFormattedPrice(trade.executionPrice, market)
  const formattedLiquidationPrice = useFormattedPrice(liquidationPrice, market)

  const adjustedPriceImpact = leverage?.greaterThan('1')
    ? priceImpactWithoutFee?.multiply(leverage).multiply(new Fraction('3', '2'))
    : undefined

  return (
    <>
      <AutoColumn gap='0px'>
        {leverage?.greaterThan('1') && (
          <RowBetween align='center' style={{ height: '22px' }}>
            <Text fontWeight={400} fontSize={13} color={theme.text2}>
              Collateral Deposited{' '}
              <StyledTooltipWithIcon
                tooltipText={`The assets deposited from your wallet in order to open this position. It will be combined with the ${cleanCurrencySymbol(
                  marginInformation?.marginDeposit?.currency,
                )} purchased with borrowed ${cleanCurrencySymbol(
                  trade.inputAmount.currency,
                )} to represent the full value of your margin position. In the case that the index price drops to the liquidation price, your collateral will be sold in order to reimburse lenders who funded your position.`}
              />
            </Text>
            <Text
              fontWeight={500}
              fontSize={13}
              color={theme.text1}
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                textAlign: 'right',
                paddingLeft: '10px',
              }}
            >
              <div>
                {formatAmount(marginInformation?.marginDeposit)}{' '}
                {cleanCurrencySymbol(marginInformation?.marginDeposit?.currency)}
              </div>
            </Text>
          </RowBetween>
        )}
        {leverage?.greaterThan('1') && (
          <RowBetween align='center' style={{ height: '22px' }}>
            <Text fontWeight={400} fontSize={13} color={theme.text2}>
              Liquidation Price{' '}
              <StyledTooltipWithIcon
                tooltipText={
                  'The price at which your position will be liquidated, selling your collateral in order to reimburse lenders who funded your margin position.'
                }
              />
            </Text>
            <Text
              fontWeight={500}
              fontSize={13}
              color={theme.text1}
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                textAlign: 'right',
                paddingLeft: '10px',
                cursor: 'pointer',
              }}
              onClick={() => setShowInverted(!showInverted)}
            >
              <div>{getFormattedPriceForUIFromTrade(formattedLiquidationPrice, trade, showInverted)}</div>
            </Text>
          </RowBetween>
        )}
        <RowBetween align='center' style={{ height: '22px' }}>
          <Text fontWeight={400} fontSize={13} color={theme.text2}>
            {leverage?.greaterThan('1') && marginInformation?.isClosing === false
              ? 'Open Price'
              : marginInformation?.isClosing
              ? 'Close Price'
              : 'Price'}
          </Text>
          <Text
            fontWeight={500}
            fontSize={13}
            color={theme.text1}
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              textAlign: 'right',
              paddingLeft: '10px',
            }}
          >
            <div
              onClick={() => setShowInverted(!showInverted)}
              style={{
                cursor: 'pointer',
                userSelect: 'none',
              }}
            >
              {getFormattedPriceForUIFromTrade(formattedPrice, trade, showInverted)}
            </div>
          </Text>
        </RowBetween>

        <RowBetween>
          <RowFixed>
            <TYPE.black fontSize={13} fontWeight={400} color={theme.text2}>
              {trade.tradeType === TradeType.EXACT_INPUT ? 'Minimum received' : 'Maximum sold'}
            </TYPE.black>
            <QuestionHelper text='Your transaction will revert if there is a large, unfavorable price movement before it is confirmed.' />
          </RowFixed>
          <RowFixed>
            <TYPE.black fontSize={13}>
              {trade.tradeType === TradeType.EXACT_INPUT
                ? slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(4) ?? '-'
                : slippageAdjustedAmounts[Field.INPUT]?.toSignificant(4) ?? '-'}
            </TYPE.black>
            <TYPE.black fontSize={13} marginLeft={'4px'}>
              {trade.tradeType === TradeType.EXACT_INPUT
                ? cleanCurrencySymbol(trade.outputAmount.currency)
                : cleanCurrencySymbol(trade.inputAmount.currency)}
            </TYPE.black>
          </RowFixed>
        </RowBetween>
        <RowBetween>
          <RowFixed>
            <TYPE.black color={theme.text2} fontSize={13} fontWeight={400}>
              Price Impact
            </TYPE.black>
            <QuestionHelper text='The difference between the market price and your price due to trade size.' />
          </RowFixed>
          <FormattedPriceImpact priceImpact={priceImpactWithoutFee} adjustedPriceImpact={adjustedPriceImpact} />
        </RowBetween>
        <RowBetween>
          <RowFixed>
            <TYPE.black fontSize={13} fontWeight={400} color={theme.text2}>
              Liquidity Provider Fee
            </TYPE.black>
            <QuestionHelper text='A portion of each trade (0.30%) goes to liquidity providers as a protocol incentive.' />
          </RowFixed>
          <TYPE.black fontSize={13}>
            {realizedLPFee
              ? realizedLPFee.toSignificant(6) + ' ' + cleanCurrencySymbol(trade.inputAmount.currency)
              : '-'}
          </TYPE.black>
        </RowBetween>
      </AutoColumn>

      <AutoRow>
        {leverage?.greaterThan(1)
          ? severity > 2 && <SwapWarning>Opening this position will have high price impact</SwapWarning>
          : severity > 2 && <SwapWarning>Trade will have high price impact</SwapWarning>}
        <SubmitButton
          onClick={onConfirm}
          disabled={disabledConfirm}
          error={severity > 2}
          style={{ margin: '10px 0 0 0' }}
          id='confirm-swap-or-send'
        >
          <Text fontSize={20} fontWeight={500}>
            {leverage?.greaterThan(1)
              ? severity > 2
                ? 'Open Anyway'
                : 'Open Position'
              : severity > 2
              ? 'Trade Anyway'
              : marginInformation?.isClosing
              ? 'Confirm Close'
              : 'Confirm Trade'}
          </Text>
        </SubmitButton>

        {swapErrorMessage ? <TradeCallbackError error={swapErrorMessage} /> : null}
      </AutoRow>
    </>
  )
}
