import React, { useCallback, useContext, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import styled, { ThemeContext } from 'styled-components/macro'
import { INVALID_CHAIN, useActiveWeb3React } from '../../hooks'
import { AppDispatch } from '../../state'
import { clearAllTransactions } from '../../state/transactions/actions'
import { getBlockExplorerName, getEtherscanLink, shortenAddress } from '../../utils'
import { AutoRow } from '../Row'
import Copy from './Copy'
import Transaction from './Transaction'
import { ReactComponent as Close } from '../../assets/images/x.svg'
import { ExternalLink as LinkIcon } from 'react-feather'
import { ExternalLink, StyledInternalLink, TYPE } from '../../theme'
import WalletIcon from '../WalletIcon'
import { useTranslation } from 'react-i18next'
import { useSetChain } from '@web3-onboard/react'
import { getAddress } from '@ethersproject/address'
import { NETWORK_LABELS } from '../../constants/chainId'
import { useModalOpen } from '../../state/application/hooks'
import { ApplicationModal } from '../../state/application/actions'

const HeaderRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap};
  font-size: 20px;
  font-weight: 600;
  color: ${props => (props.color === 'blue' ? ({ theme }) => theme.primary1 : 'inherit')};
`

const UpperSection = styled.div`
  position: relative;
  padding: 20px 20px 8px 35px;

  h5 {
    margin: 0;
    margin-bottom: 1rem;
    font-size: 1rem;
    font-weight: 400;
  }

  h5:last-child {
    margin-bottom: 0;
  }

  h4 {
    margin-top: 0;
    font-weight: 500;
  }
`

const InfoCard = styled.div`
  position: relative;
  display: grid;
  margin-bottom: 20px;
`

const AccountGroupingRow = styled.div<{ large?: boolean }>`
  ${({ theme }) => theme.flexRowNoWrap};
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  color: ${({ theme }) => theme.text1};

  div {
    ${({ theme }) => theme.flexRowNoWrap}
    align-items: center;
  }

  :nth-of-type(3) {
    width: 100%;
    margin-left: -35px;
  }
`

const AccountSection = styled.div`
  background-color: ${({ theme }) => theme.bg1};
`

const YourAccount = styled.div`
  h5 {
    margin: 0 0 1rem 0;
    font-weight: 400;
  }

  h4 {
    margin: 0;
    font-weight: 500;
  }
`

const LowerSection = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  padding: 20px 35px 20px;
  flex-grow: 1;
  overflow: auto;
  background-color: ${({ theme }) => theme.bg2};
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  h5 {
    margin: 0;
    font-weight: 400;
    color: ${({ theme }) => theme.text3};
  }
`

const ClearButton = styled.div`
  color: ${({ theme }) => theme.text3};
  cursor: pointer;
  font-size: 14px;

  :hover {
    color: ${({ theme }) => theme.text2};
  }
`

const FullHistoryLink = styled.div`
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-weight: 300;
  margin-top: 8px;
  margin-bottom: 2px;

  > a {
    color: ${({ theme }) => theme.text3};

    :hover {
      color: ${({ theme }) => theme.text2};
      text-decoration: none;
    }
  }
`

const AccountControl = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 0;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 8px;

  font-weight: 300;
  font-size: 1.5rem;

  a:hover {
    text-decoration: none;
  }

  p {
    min-width: 0;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

const AddressLink = styled(ExternalLink)<{ hasENS: boolean; isENS: boolean }>`
  font-size: 0.825rem;
  color: ${({ theme }) => theme.text3};
  margin-left: 1rem;
  display: flex;

  :hover {
    color: ${({ theme }) => theme.text2};
  }
`

const CloseIcon = styled.div`
  position: absolute;
  right: 25px;
  top: 20px;

  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`

const CloseColor = styled(Close)`
  path {
    stroke: ${({ theme }) => theme.text4};
  }
`

const TransactionListWrapper = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap};
`

const WalletAction = styled.div`
  width: fit-content;
  font-weight: 400;
  margin-left: 8px;
  font-size: 0.825rem;
  padding: 4px 6px;
  background: none;
  color: ${({ theme }) => theme.text2};

  :hover {
    cursor: pointer;
    color: ${({ theme }) => theme.text1};
    background: none;
  }
`

const Network = styled.div`
  margin-left: -15px;
  padding-top: 1rem;
  text-align: center;
`

const ChangeNetworkWrapper = styled.div`
  margin-left: -15px;
  padding: 1rem;

  h5 {
    text-align: center !important;
    font-size: 16px !important;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`padding: 1rem`};
`

const ChangeNetworkButton = styled.div`
  background-color: ${({ theme }) => theme.green2};
  color: ${({ theme }) => theme.text1};
  width: fit-content;
  cursor: pointer;
  border-radius: 5px;
  padding: 8px 16px;
  margin: 25px auto 0;

  :hover {
    background-color: ${({ theme }) => theme.green1};
  }
`

const EnsIconWrapper = styled.div<{ size?: number }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: text-bottom;
  padding: 4px;

  & > img {
    height: ${({ size }) => (size ? size + 'px' : '32px')};
    width: ${({ size }) => (size ? size + 'px' : '32px')};
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
    align-items: flex-end;
  `};
`

const EnsIcon = styled.img`
  border-radius: 50%;
`

const NetworkIconWrapper = styled.div<{ size?: number }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 2px;
  margin-left: 8px;
  vertical-align: text-bottom;

  & > img,
  span {
    height: ${({ size }) => (size ? size + 'px' : '32px')};
    width: ${({ size }) => (size ? size + 'px' : '32px')};
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
    align-items: flex-end;
  `};
`

function renderTransactions(transactions: string[]) {
  return (
    <TransactionListWrapper>
      {transactions.map(hash => {
        return <Transaction key={hash} hash={hash} />
      })}
    </TransactionListWrapper>
  )
}

interface AccountDetailsProps {
  toggleWalletModal: () => void
  pendingTransactions: string[]
  confirmedTransactions: string[]
  openOptions: () => void
}

function AccountDetailsComparator(prevProps: AccountDetailsProps, nextProps: AccountDetailsProps) {
  return (
    prevProps.toggleWalletModal === nextProps.toggleWalletModal &&
    prevProps.pendingTransactions === nextProps.pendingTransactions &&
    prevProps.confirmedTransactions === nextProps.confirmedTransactions &&
    prevProps.openOptions === nextProps.openOptions
  )
}

function AccountDetails({
  toggleWalletModal,
  pendingTransactions,
  confirmedTransactions,
  openOptions,
}: AccountDetailsProps) {
  const walletModalOpen = useModalOpen(ApplicationModal.WALLET)
  const { chainId, wallet, disconnect, setChain, error: web3Error } = useActiveWeb3React()
  const account = useMemo(() => {
    return wallet?.accounts[0] ? getAddress(wallet.accounts[0]?.address) : undefined
  }, [wallet])
  const ensName = wallet?.accounts[0]?.ens?.name
  const avatarUrl = wallet?.accounts[0]?.ens?.avatar
  const [{ chains, connectedChain }] = useSetChain(wallet?.label)
  const theme = useContext(ThemeContext)
  const dispatch = useDispatch<AppDispatch>()
  const { t } = useTranslation()

  const clearAllTransactionsCallback = useCallback(() => {
    if (chainId) {
      dispatch(clearAllTransactions({ chainId }))
    }
  }, [dispatch, chainId])

  const { connectedChainLabel, connectedChainIcon } = useMemo(() => {
    if (!connectedChain) {
      return {
        connectedChainLabel: undefined,
        connectedChainIcon: undefined,
      }
    }
    const chain = chains.find(chain => chain.id === connectedChain.id)
    return {
      connectedChainLabel: chain?.label,
      connectedChainIcon: chain?.icon,
    }
  }, [chains, connectedChain])

  return (
    <>
      <UpperSection>
        <CloseIcon onClick={toggleWalletModal}>
          <CloseColor />
        </CloseIcon>
        <HeaderRow>Account</HeaderRow>
        <AccountSection>
          <YourAccount>
            <InfoCard>
              <AccountGroupingRow id='web3-account-identifier-row' large={true}>
                <AccountControl>
                  {ensName ? (
                    <>
                      <div>
                        {avatarUrl ? (
                          <EnsIconWrapper size={32}>
                            <EnsIcon src={avatarUrl} alt={ensName} />
                          </EnsIconWrapper>
                        ) : null}
                        <p> {ensName}</p>
                      </div>
                    </>
                  ) : account ? (
                    <>
                      <div>
                        <p> {account && shortenAddress(account)}</p>
                      </div>
                    </>
                  ) : null}
                </AccountControl>
              </AccountGroupingRow>
              {/*TODO re-enable when site is fixed more*/}
              {/*<Stats onClick={viewAccount}>View Account Stats</Stats>*/}
              <AccountGroupingRow>
                <div>
                  <WalletIcon wallet={wallet} />
                  {wallet?.label}
                  <div>
                    {wallet && (
                      <WalletAction
                        style={{
                          fontSize: '.825rem',
                          fontWeight: 600,
                          marginRight: '8px',
                        }}
                        onClick={() => {
                          if (wallet) {
                            toggleWalletModal()
                            disconnect(wallet).catch(e => {
                              console.error('Could not disconnect wallet due to error:', e)
                            })
                          }
                        }}
                      >
                        Disconnect
                      </WalletAction>
                    )}
                    <WalletAction
                      style={{
                        fontSize: '.825rem',
                        fontWeight: 600,
                      }}
                      onClick={() => {
                        openOptions()
                      }}
                    >
                      Change
                    </WalletAction>
                  </div>
                </div>
              </AccountGroupingRow>
              <AccountGroupingRow
                style={{
                  marginLeft: '-10px',
                  marginTop: '-6px',
                }}
              >
                {ensName ? (
                  <>
                    <AccountControl>
                      <div>
                        {account && (
                          <Copy toCopy={account} style={{ marginLeft: '-4px' }}>
                            <span style={{ marginLeft: '4px' }}>Copy Address</span>
                          </Copy>
                        )}
                        {chainId && account && (
                          <AddressLink
                            hasENS={!!ensName}
                            isENS={true}
                            href={chainId && getEtherscanLink(chainId, ensName, 'address')}
                          >
                            <LinkIcon size={16} />
                            <span style={{ marginLeft: '4px' }}>View on {getBlockExplorerName(chainId)}</span>
                          </AddressLink>
                        )}
                      </div>
                    </AccountControl>
                  </>
                ) : account ? (
                  <>
                    <AccountControl>
                      <div>
                        {account && (
                          <Copy toCopy={account}>
                            <span style={{ marginLeft: '4px' }}>Copy Address</span>
                          </Copy>
                        )}
                        {chainId && account && (
                          <AddressLink
                            hasENS={!!ensName}
                            isENS={false}
                            href={getEtherscanLink(chainId, account, 'address')}
                          >
                            <LinkIcon size={16} />
                            <span style={{ marginLeft: '4px' }}>View on {getBlockExplorerName(chainId)}</span>
                          </AddressLink>
                        )}
                      </div>
                    </AccountControl>
                  </>
                ) : null}
              </AccountGroupingRow>
              {web3Error?.message === INVALID_CHAIN ? (
                <ChangeNetworkWrapper>
                  <h5>{t('wrongNetwork')}</h5>
                  <ChangeNetworkButton
                    onClick={() => {
                      setChain(chainId).then(successful => {
                        if (successful && walletModalOpen) {
                          toggleWalletModal()
                        }
                      })
                    }}
                  >
                    Change to {NETWORK_LABELS[chainId]}
                  </ChangeNetworkButton>
                </ChangeNetworkWrapper>
              ) : null}
              {connectedChainLabel && !web3Error ? (
                <Network>
                  Connected to
                  {connectedChainIcon ? (
                    <NetworkIconWrapper size={24}>
                      <img src={connectedChainIcon} alt={connectedChainLabel} />
                    </NetworkIconWrapper>
                  ) : null}
                  {connectedChainLabel}
                </Network>
              ) : null}
            </InfoCard>
          </YourAccount>
        </AccountSection>
      </UpperSection>
      {account && (!!pendingTransactions.length || !!confirmedTransactions.length) ? (
        <LowerSection>
          <AutoRow mb={'1rem'} style={{ justifyContent: 'space-between' }}>
            <TYPE.body>Recent Transactions</TYPE.body>
            <ClearButton onClick={clearAllTransactionsCallback}>clear all</ClearButton>
          </AutoRow>
          {renderTransactions(pendingTransactions)}
          {renderTransactions(confirmedTransactions)}
          <FullHistoryLink>
            <StyledInternalLink to={`/history`} style={{ width: '100%' }}>
              View All Transactions
            </StyledInternalLink>
          </FullHistoryLink>
        </LowerSection>
      ) : account ? (
        <LowerSection>
          <TYPE.body fontSize={14} color={theme.text1}>
            Your recent transactions will appear here...
          </TYPE.body>
        </LowerSection>
      ) : null}
    </>
  )
}

export default React.memo(AccountDetails, AccountDetailsComparator)
