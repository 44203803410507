import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import NumericalInput from '../../../NumericalInput'
import { StyledTooltip, StyledTooltipWithIcon } from '../../../common/StyledTooltip'
import CircularProgress from '@material-ui/core/CircularProgress'
import { ZERO_FRACTION } from '../../../../constants'
import { tryParseAmount } from '../../../../state/trade/hooks'
import { Fraction, Token } from '@dolomite-exchange/v2-sdk'
import { useIsTransactionPending } from '../../../../state/transactions/hooks'
import { formatAmount } from '../../../../utils/formatAmount'
import { useStakeJUsdc, useUnstakeJUsdc } from '../../../../hooks/jusdc/useJUsdcDolomiteProxyProtocol'
import {
  useDjUSDCToken,
  useJUsdcStakedBalance,
  useJUsdcToken,
  useJUsdcUnstakedBalance,
} from '../../../../hooks/jusdc/useJonesProtocol'
import { useDolomiteBalance } from '../../../../state/wallet/hooks'
import { useActiveWeb3React } from '../../../../hooks'
import ArrowRight from '@material-ui/icons/ArrowForward'

export const ExpandedContent = styled.div<{ expandedHeight: number }>`
  width: calc(100% + 70px);
  margin-left: -35px;
  background: ${({ theme }) => theme.bg2};
  padding: 15px 35px 30px;
  height: ${({ expandedHeight }) => expandedHeight}px;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  position: relative;
`

export const AssetRewardsWrapper = styled.div`
  padding: 7px 35px 30px;
  width: 100%;
  overflow: auto;
`

export const AssetRewardsBody = styled.div`
  font-size: 13px;
  font-weight: 500;
`

const RewardsWrapper = styled.div`
  width: 100%;
  margin-bottom: -30px;
`

const DescriptionText = styled.div`
  font-size: 12px;
  font-weight: 100;
  line-height: 14px;
  color: ${({ theme }) => theme.text2};
  width: 100%;
  position: relative;
  margin: -10px auto 10px;
  padding: 12px 16px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.bg2};

  svg {
    margin-right: 5px;
    margin-bottom: -3px;
    font-size: 24px;
    color: ${({ theme }) => theme.text3};
    display: inline-block;
    vertical-align: top;
  }

  a {
    color: ${({ theme }) => theme.text2};
    font-weight: 600;
    outline: none !important;
    border: none !important;
  }

  @media screen and (max-width: 650px) {
  }
`

const DescriptionContent = styled.div`
  width: calc(100% - 30px);
  display: inline-block;
  vertical-align: top;
`

const StakingFeeWarning = styled.div<{ isVisible: boolean }>`
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  pointer-events: ${({ isVisible }) => (isVisible ? 'all' : 'none')};
  transition: opacity 0.2s ease-in-out;
  font-size: 11px;
  width: 100%;
  text-align: center;
  margin-top: -1px;
  color: ${({ theme }) => theme.text3};
`

const DataSection = styled.div`
  width: 100%;
  margin-bottom: 8px;
`

const DataRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  line-height: 20px;
  align-items: center;
`

const DataName = styled.div`
  font-weight: 300;

  svg {
    transform: translateY(1px);
  }
`

const DataValue = styled.div<{ fontSizeShrink: number }>`
  font-size: ${({ fontSizeShrink }) => 13 - fontSizeShrink}px;
  font-weight: 600;
`

const NewValueWrapper = styled.span<{ isLess: boolean | undefined; isNegative: boolean }>`
  width: fit-content;
  color: ${({ theme, isNegative }) => (isNegative ? theme.red1 : 'inherit')};

  svg {
    height: 14px;
    vertical-align: top;
    margin-top: 1.6px;
    color: ${({ theme, isLess }) => (isLess !== undefined ? (isLess ? theme.red1 : theme.green1) : 'inherit')};
  }
`

const ModifySection = styled.div<{ visible: boolean }>`
  width: calc(100% - 75px);
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  pointer-events: ${({ visible }) => (visible ? 'all' : 'none')};
  transition: opacity 0.2s ease-in-out;
  position: absolute;
  top: 20px;
`

const DepositSection = styled(ModifySection)`
  h3 {
    margin-top: 0;
    margin-bottom: 10px;

    svg {
      margin-bottom: -2px !important;
    }
  }
`

const WithdrawSection = styled(ModifySection)`
  h3 {
    margin-top: 0;
    margin-bottom: 10px;

    svg {
      margin-bottom: -2px !important;
    }
  }
`

const SubmitButton = styled.div<{ disabled?: boolean; blue?: boolean }>`
    width: 100%;
    padding: 12px 16px;
    border-radius: 5px;
    background-color: ${({ theme, blue }) => (blue ? theme.blue2 : theme.green2)};
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
    font-size: 14px;
    font-weight: 500;
    color: ${({ theme }) => theme.text1};
    opacity: ${({ disabled }) => (disabled ? 0.7 : 1)};
    text-align: center;
        /*${({ disabled }) => disabled && 'pointer-events: none;'}*/
    margin-top: 12px;

    :hover {
        background-color: ${({ theme, disabled, blue }) =>
          blue ? !disabled && theme.blue2 : !disabled && theme.green1};
    }

    > div {
        width: 20px !important;
        height: 20px !important;
        font-size: 12px;
        margin-top: -1px;
        margin-bottom: -5px;
    }

    svg {
        color: ${({ theme }) => theme.text1};
    }
`

const ActionButton = styled.div<{ selected: boolean }>`
  width: 50%;
  display: inline-block;
  vertical-align: top;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 15px;
  cursor: pointer;
  background: ${({ theme, selected }) => (selected ? theme.bg2 : theme.bg1)};

  :hover {
    background: ${({ theme, selected }) => (selected ? theme.bg2 : theme.bg7)};
  }

  :nth-of-type(1) {
    border-top-right-radius: 8px;
  }

  :nth-of-type(2) {
    border-top-left-radius: 8px;
  }
`

const ActionButtonsWrapper = styled.div`
  width: calc(100% + 70px);
  margin-left: -35px;
  margin-top: 20px;
`

const BalanceWrapper = styled.div`
  color: #d5d6e1;
  display: inline-block;
  vertical-align: top;
`

const InputWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  font-size: 15px;
  padding: 0.5rem 0.75rem;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.bg6};
`

const LabelRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 13px;
  font-weight: 500;

  svg {
    margin-bottom: -2px !important;
  }
`

const MaxButton = styled.div`
  cursor: pointer;
`

const IndentLeft = styled.div`
  width: calc(100% - 10px);
  margin-left: 10px;
`

const IndentArrow = styled.div`
  transform: scaleX(-1) translateY(2px);
  display: inline-block;
  color: ${({ theme }) => theme.text3};
  font-weight: 200;
  font-size: 20px;
  margin-left: -5px;
  margin-right: 5px;
`

const JonesFee = styled.div`
  width: 100%;
  text-align: left;
  font-size: 11px;
  color: ${({ theme }) => theme.text2};
`

enum StakeOrUnstakeTab {
  STAKE_TAB = 0,
  UNSTAKE_TAB = 1,
}

const maxLenFraction = (val1: Fraction | undefined, val2: Fraction | undefined) => {
  if (!val1 || !val2) return undefined
  return val1.abs.greaterThan(val2.abs) ? val1 : val2
}

const fontSizeShrinkAmount = (value: Fraction | undefined) => {
  return value ? Math.max(formatAmount(value.abs, 4, true).length - 10, 0) * 0.8 : 0
}

export default function StakeJUSDC() {
  const { t } = useTranslation()
  const { account } = useActiveWeb3React()
  const [selectedTab, setSelectedTab] = useState(StakeOrUnstakeTab.STAKE_TAB)
  const [inputValue, setInputValue] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const [awaitingSignature, setAwaitingSignature] = useState(false)
  const [pendingHash, setPendingHash] = useState<string | undefined>(undefined)
  const jUsdcToken = useJUsdcToken()
  const djUsdcToken = useDjUSDCToken()

  const isHashPending = useIsTransactionPending(pendingHash)
  useEffect(() => {
    if (!isHashPending) {
      setPendingHash(undefined)
    }
    setSubmitting(isHashPending)
  }, [isHashPending])

  const parsedInputValue = useMemo(() => {
    if (!jUsdcToken) {
      return undefined
    }
    return tryParseAmount(inputValue, jUsdcToken)
  }, [inputValue, jUsdcToken])

  const jUsdcDolomiteBalance = useDolomiteBalance(account, djUsdcToken)
  const unstakedJUsdcBalance = useJUsdcUnstakedBalance()
  const stakedJUsdcBalance = useJUsdcStakedBalance()
  const totalJUsdcBalance = useMemo(() => {
    if (!stakedJUsdcBalance || !unstakedJUsdcBalance) {
      return undefined
    }
    return stakedJUsdcBalance.add(unstakedJUsdcBalance)
  }, [stakedJUsdcBalance, unstakedJUsdcBalance])
  const newUnstakedJUsdcBalance = useMemo(() => {
    if (!unstakedJUsdcBalance || !parsedInputValue) {
      return undefined
    }
    return selectedTab === StakeOrUnstakeTab.STAKE_TAB
      ? unstakedJUsdcBalance.subtract(parsedInputValue.asFraction)
      : unstakedJUsdcBalance.add(parsedInputValue.asFraction)
  }, [unstakedJUsdcBalance, parsedInputValue, selectedTab])
  const newStakedJUsdcBalance = useMemo(() => {
    if (!stakedJUsdcBalance || !parsedInputValue) {
      return undefined
    }
    return selectedTab === StakeOrUnstakeTab.STAKE_TAB
      ? stakedJUsdcBalance.add(parsedInputValue.asFraction)
      : stakedJUsdcBalance.subtract(parsedInputValue.asFraction)
  }, [stakedJUsdcBalance, parsedInputValue, selectedTab])
  const newJUsdcBalance = useMemo(() => {
    if (!totalJUsdcBalance || !parsedInputValue || selectedTab === StakeOrUnstakeTab.UNSTAKE_TAB) {
      return undefined
    }
    return totalJUsdcBalance.asFraction
  }, [parsedInputValue, selectedTab, totalJUsdcBalance])
  const newJUsdcDolomiteBalance = useMemo(() => {
    if (!jUsdcDolomiteBalance || !parsedInputValue || selectedTab === StakeOrUnstakeTab.UNSTAKE_TAB) {
      return undefined
    }
    return jUsdcDolomiteBalance.asFraction
  }, [jUsdcDolomiteBalance, parsedInputValue, selectedTab])

  const isDataLoaded = useMemo(() => !account || (!!stakedJUsdcBalance && !!unstakedJUsdcBalance), [
    account,
    stakedJUsdcBalance,
    unstakedJUsdcBalance,
  ])

  const maxAmount = useMemo(() => {
    if (selectedTab === StakeOrUnstakeTab.STAKE_TAB) {
      if (!unstakedJUsdcBalance || !jUsdcDolomiteBalance) {
        return undefined
      }
      return unstakedJUsdcBalance /*.divide(100).lessThanOrEqual(jUsdcDolomiteBalance.asFraction)
        ? unstakedJUsdcBalance
        : jUsdcDolomiteBalance.asFraction.multiply(100)*/
    } else {
      return stakedJUsdcBalance
    }
  }, [jUsdcDolomiteBalance, selectedTab, stakedJUsdcBalance, unstakedJUsdcBalance])

  const insufficientUnstakedBalance = useMemo(
    () => unstakedJUsdcBalance && parsedInputValue?.asFraction.greaterThan(unstakedJUsdcBalance),
    [unstakedJUsdcBalance, parsedInputValue],
  )
  const insufficientStakedBalance = useMemo(
    () => stakedJUsdcBalance && parsedInputValue?.asFraction.greaterThan(stakedJUsdcBalance),
    [stakedJUsdcBalance, parsedInputValue],
  )
  const isButtonActive = useMemo(() => {
    if (selectedTab === StakeOrUnstakeTab.UNSTAKE_TAB) {
      return !submitting && parsedInputValue?.greaterThan(ZERO_FRACTION) && !insufficientStakedBalance
    } else {
      return !submitting && parsedInputValue?.greaterThan(ZERO_FRACTION) && !insufficientUnstakedBalance
    }
  }, [insufficientStakedBalance, insufficientUnstakedBalance, parsedInputValue, selectedTab, submitting])

  const updateSelectedTab = useCallback(
    (newSelectedTab: StakeOrUnstakeTab) => {
      if (newSelectedTab !== selectedTab) {
        setSelectedTab(newSelectedTab)
        setInputValue('')
      }
    },
    [selectedTab],
  )

  const updateInput = useCallback((inputString: string) => {
    const re = /^\d*(\.\d*)?$/ //Only allow numbers and a single decimal point

    if (inputString === '' || re.test(inputString)) {
      setInputValue(inputString)
    }
  }, [])

  const { callback: stakeJUsdcCallback } = useStakeJUsdc(parsedInputValue)
  const { callback: unstakeJUsdcCallback } = useUnstakeJUsdc(parsedInputValue)

  const submitTransaction = useCallback(() => {
    if (!stakeJUsdcCallback || !unstakeJUsdcCallback || awaitingSignature || !isButtonActive) {
      return
    }

    setSubmitting(true)
    setAwaitingSignature(true)

    let callback: () => Promise<string>
    if (selectedTab === StakeOrUnstakeTab.STAKE_TAB) {
      callback = stakeJUsdcCallback
    } else if (selectedTab === StakeOrUnstakeTab.UNSTAKE_TAB) {
      callback = unstakeJUsdcCallback
    } else {
      console.warn('Invalid selected tab for jUSDC', selectedTab)
      return
    }

    callback()
      .then((hash: any) => {
        setPendingHash(hash)
        setAwaitingSignature(false)
        setInputValue('')
      })
      .catch(e => {
        console.log(e)
        setAwaitingSignature(false)
        setSubmitting(false)
      })
  }, [awaitingSignature, isButtonActive, selectedTab, stakeJUsdcCallback, unstakeJUsdcCallback])

  const setMax = useCallback(() => {
    if (jUsdcToken && maxAmount) {
      setInputValue(maxAmount.toFixed((jUsdcToken as Token).decimals))
    }
  }, [maxAmount, jUsdcToken])

  return (
    <AssetRewardsWrapper>
      <h2>Stake jUSDC</h2>
      <AssetRewardsBody>
        <RewardsWrapper>
          <DataSection>
            <DataRow>
              <DataName>Total jUSDC Balance</DataName>
              <DataValue
                fontSizeShrink={
                  totalJUsdcBalance?.greaterThan(ZERO_FRACTION) && parsedInputValue?.greaterThan(ZERO_FRACTION)
                    ? fontSizeShrinkAmount(maxLenFraction(totalJUsdcBalance, newJUsdcBalance))
                    : 0
                }
              >
                {formatAmount(totalJUsdcBalance, 4, true)} jUSDC{' '}
                {newJUsdcBalance &&
                  totalJUsdcBalance?.greaterThan(ZERO_FRACTION) &&
                  parsedInputValue?.greaterThan(ZERO_FRACTION) && (
                    <NewValueWrapper
                      isLess={newJUsdcBalance.lessThan(totalJUsdcBalance || ZERO_FRACTION)}
                      isNegative={newJUsdcBalance.lessThan(ZERO_FRACTION)}
                    >
                      <ArrowRight />
                      {formatAmount(newJUsdcBalance, 4, true)}
                    </NewValueWrapper>
                  )}
              </DataValue>
            </DataRow>
            <IndentLeft>
              <DataRow>
                <DataName>
                  <IndentArrow>↵</IndentArrow>Unstaked
                </DataName>
                <DataValue
                  fontSizeShrink={
                    totalJUsdcBalance?.greaterThan(ZERO_FRACTION) && parsedInputValue?.greaterThan(ZERO_FRACTION)
                      ? fontSizeShrinkAmount(maxLenFraction(unstakedJUsdcBalance, newUnstakedJUsdcBalance))
                      : 0
                  }
                >
                  {formatAmount(unstakedJUsdcBalance, 4, true)} jUSDC{' '}
                  {newUnstakedJUsdcBalance &&
                    totalJUsdcBalance?.greaterThan(ZERO_FRACTION) &&
                    parsedInputValue?.greaterThan(ZERO_FRACTION) && (
                      <NewValueWrapper
                        isLess={newUnstakedJUsdcBalance.lessThan(unstakedJUsdcBalance ?? ZERO_FRACTION)}
                        isNegative={newUnstakedJUsdcBalance.lessThan(ZERO_FRACTION)}
                      >
                        <ArrowRight />
                        {formatAmount(newUnstakedJUsdcBalance, 4, true)}
                      </NewValueWrapper>
                    )}
                </DataValue>
              </DataRow>
              <DataRow>
                <DataName>
                  <IndentArrow>↵</IndentArrow>Staked
                </DataName>
                <DataValue
                  fontSizeShrink={
                    totalJUsdcBalance?.greaterThan(ZERO_FRACTION) && parsedInputValue?.greaterThan(ZERO_FRACTION)
                      ? fontSizeShrinkAmount(maxLenFraction(stakedJUsdcBalance, newStakedJUsdcBalance))
                      : 0
                  }
                >
                  {formatAmount(stakedJUsdcBalance, 4, true)} jUSDC{' '}
                  {newStakedJUsdcBalance &&
                    totalJUsdcBalance?.greaterThan(ZERO_FRACTION) &&
                    parsedInputValue?.greaterThan(ZERO_FRACTION) && (
                      <NewValueWrapper
                        isLess={newStakedJUsdcBalance.lessThan(stakedJUsdcBalance ?? ZERO_FRACTION)}
                        isNegative={newStakedJUsdcBalance.lessThan(ZERO_FRACTION)}
                      >
                        <ArrowRight />
                        {formatAmount(newStakedJUsdcBalance, 4, true)}
                      </NewValueWrapper>
                    )}
                </DataValue>
              </DataRow>
            </IndentLeft>
          </DataSection>
          <ActionButtonsWrapper>
            <ActionButton
              selected={selectedTab === StakeOrUnstakeTab.STAKE_TAB}
              onClick={() => {
                updateSelectedTab(StakeOrUnstakeTab.STAKE_TAB)
              }}
            >
              Stake
            </ActionButton>
            <ActionButton
              selected={selectedTab === StakeOrUnstakeTab.UNSTAKE_TAB}
              onClick={() => {
                updateSelectedTab(StakeOrUnstakeTab.UNSTAKE_TAB)
              }}
            >
              Unstake
            </ActionButton>
          </ActionButtonsWrapper>
          <ExpandedContent expandedHeight={190}>
            <DepositSection visible={selectedTab === StakeOrUnstakeTab.STAKE_TAB}>
              <h3>
                Stake jUSDC
                <StyledTooltipWithIcon tooltipText={'Stake jUSDC in order to earn ARB rewards.'} />
              </h3>
              <LabelRow>
                <div>
                  <BalanceWrapper>
                    {`Unstaked ${t('balance', {
                      balanceInput: formatAmount(unstakedJUsdcBalance, 4, true),
                    })}`}
                  </BalanceWrapper>
                  <StyledTooltipWithIcon
                    tooltipText={
                      'The balance of jUSDC you currently have deposited to your Dolomite Vault and have available for staking. You can stake jUSDC even if it is being used in a borrow position.'
                    }
                  />
                  {/*<BalanceWrapper>
                    {`jUSDC ${t('balance', {
                      balanceInput: formatAmount(jUsdcDolomiteBalance, 4, true),
                    })}`}
                  </BalanceWrapper>
                  <StyledTooltipWithIcon
                    tooltipText={
                      'The balance of jUSDC you currently have deposited to your Dolomite Vault and have available for staking. You can stake jUSDC even if it is being used in a borrow position.'
                    }
                  />*/}
                </div>
                <MaxButton onClick={() => setMax()}>Max</MaxButton>
              </LabelRow>
              <InputWrapper>
                <NumericalInput
                  className={'token-amount-input'}
                  value={inputValue}
                  onUserInput={val => {
                    updateInput(val)
                  }}
                  fontSize={'inherit'}
                  unit={'jUSDC'}
                  maxDecimals={18}
                />
              </InputWrapper>
              <StyledTooltip
                title={
                  submitting
                    ? awaitingSignature
                      ? 'Awaiting signature...'
                      : 'Submitting...'
                    : !isDataLoaded
                    ? 'Loading data, please wait...'
                    : insufficientUnstakedBalance
                    ? 'Entered amount is greater than your unstaked balance.'
                    : parsedInputValue?.greaterThan(ZERO_FRACTION)
                    ? ''
                    : 'Enter a valid amount'
                }
                showTooltip={submitting || !parsedInputValue}
                position={'top'}
              >
                <SubmitButton
                  disabled={!isButtonActive || !isDataLoaded}
                  blue={false}
                  onClick={() => submitTransaction()}
                >
                  {submitting ? (
                    <CircularProgress />
                  ) : !account ? (
                    'No wallet connected'
                  ) : !isDataLoaded ? (
                    'Loading data...'
                  ) : (
                    'Stake'
                  )}
                </SubmitButton>
              </StyledTooltip>
            </DepositSection>
            <WithdrawSection visible={selectedTab === StakeOrUnstakeTab.UNSTAKE_TAB}>
              <h3>Unstake jUSDC</h3>
              <LabelRow>
                <div>
                  <BalanceWrapper>
                    {t('stakedBalance', {
                      balanceInput: formatAmount(stakedJUsdcBalance, 6, true),
                    })}
                  </BalanceWrapper>
                  <StyledTooltipWithIcon
                    tooltipText={`This represents the amount of jUSDC you currently have staked. If you unstake, the jUSDC will withdraw to your Dolomite Balance.`}
                  />
                </div>
                <MaxButton onClick={() => setMax()}>Max</MaxButton>
              </LabelRow>
              <InputWrapper>
                <NumericalInput
                  className={'token-amount-input'}
                  value={inputValue}
                  onUserInput={val => {
                    updateInput(val)
                  }}
                  fontSize={'inherit'}
                  unit={'jUSDC'}
                  maxDecimals={18}
                />
              </InputWrapper>
              <StyledTooltip
                title={
                  submitting
                    ? awaitingSignature
                      ? 'Awaiting signature...'
                      : 'Submitting...'
                    : !isDataLoaded
                    ? 'Loading data, please wait...'
                    : insufficientStakedBalance
                    ? 'Entered amount is greater than the amount staked.'
                    : parsedInputValue?.greaterThan(ZERO_FRACTION)
                    ? ''
                    : 'Enter a valid amount'
                }
                showTooltip={submitting || !parsedInputValue || !isButtonActive}
                position={'top'}
              >
                <SubmitButton
                  disabled={!isButtonActive || !isDataLoaded}
                  onClick={() => isButtonActive && isDataLoaded && submitTransaction()}
                >
                  {submitting ? (
                    <CircularProgress />
                  ) : !account ? (
                    'No wallet connected'
                  ) : !isDataLoaded ? (
                    'Loading data...'
                  ) : (
                    'Unstake'
                  )}
                </SubmitButton>
              </StyledTooltip>
            </WithdrawSection>
          </ExpandedContent>
        </RewardsWrapper>
      </AssetRewardsBody>
    </AssetRewardsWrapper>
  )
}
