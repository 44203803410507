import { Currency, CurrencyAmount } from '@dolomite-exchange/v2-sdk'
import { ONE_BIPS } from '../constants'

export default function isInputValueCloseToBalance<T extends Currency>(
  inputValue: CurrencyAmount<T>,
  balance: CurrencyAmount<T>,
): boolean {
  return (
    inputValue.equalTo(balance) ||
    (inputValue.greaterThanOrEqual(balance.subtract(balance.multiply(ONE_BIPS))) && inputValue.lessThanOrEqual(balance))
  )
}
