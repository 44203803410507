import { gql } from '@apollo/client'
import { createCurrencyAmount, createFractionUSD, TokenHourData as TokenHourDataGql } from './gqlTypeHelpers'
import { tokenHourDataGql } from './queryObjects'
import { useMemo } from 'react'
import { CurrencyAmount, Fraction, Token } from '@dolomite-exchange/sdk-core'
import JSBI from 'jsbi'
import { toChecksumAddress } from '../utils/toChecksumAddress'
import { useGraphqlResult } from '../state/graphql/hooks'
import { GraphqlClientType } from '../state/graphql/actions'
import { RefreshFrequency, useBlockTimestamp } from '../state/chain/hooks'

const TOKEN_HOURS_FOR_PAST_DAY_GQL = gql`
    query tokenHourDataForPastDay(
        $blockNumber: Int!,
        $startTimestamp: Int!,
        $endTimestamp: Int!,
        $tokenAddress: String!
    ) {
        tokenHourDatas(
            orderBy: hourStartUnix,
            orderDirection: desc,
            block: { number_gte: $blockNumber }
            where: { hourStartUnix_gt: $startTimestamp, hourStartUnix_lte: $endTimestamp, token: $tokenAddress }
        ) {
            ${tokenHourDataGql(false)}
        }
    }
`

interface TokenHourDataResponse {
  tokenHourDatas: TokenHourDataGql[] | undefined
}

interface TokenHourData {
  hourStartUnix: Date
  token: Token
  hourlyAmmTradeVolumeUSD: Fraction
  hourlyBorrowVolumeUSD: Fraction
  hourlyLiquidationVolumeUSD: Fraction
  hourlyTradeVolumeUSD: Fraction
  hourlyVaporizationVolumeUSD: Fraction
  hourlyAmmTradeVolumeToken: CurrencyAmount<Token>
  hourlyBorrowVolumeToken: CurrencyAmount<Token>
  hourlyLiquidationVolumeToken: CurrencyAmount<Token>
  hourlyTradeVolumeToken: CurrencyAmount<Token>
  hourlyVaporizationVolumeToken: CurrencyAmount<Token>
  ammLiquidityUSD: Fraction
  borrowLiquidityUSD: Fraction
  supplyLiquidityUSD: Fraction
  ammLiquidityToken: CurrencyAmount<Token>
  borrowLiquidityToken: CurrencyAmount<Token>
  supplyLiquidityToken: CurrencyAmount<Token>
  hourlyAllTransactionCount: JSBI
  hourlyAmmTradeCount: JSBI
  hourlyLiquidationCount: JSBI
  hourlyTradeCount: JSBI
  hourlyVaporizationCount: JSBI
  openPriceUSD: Fraction
  highPriceUSD: Fraction
  lowPriceUSD: Fraction
  closePriceUSD: Fraction
}

export function useCurrentDayTokenHourlyData(
  token: Token | undefined,
): {
  loading: boolean
  error: boolean
  data: TokenHourData[] | undefined
} {
  const timestamp = useBlockTimestamp()

  const yesterdayTimestamp = useMemo(() => {
    return timestamp
      .div('3600')
      .sub('24')
      .mul('3600')
      .toNumber()
  }, [timestamp])

  const currentTimestamp = useMemo(() => {
    return timestamp
      .div('3600')
      .mul('3600')
      .toNumber()
  }, [timestamp])

  return useTokenHourlyData(token, yesterdayTimestamp, currentTimestamp)
}

export function useCurrentWeekTokenHourlyData(
  token: Token | undefined,
): {
  loading: boolean
  error: boolean
  data: TokenHourData[] | undefined
} {
  const timestamp = useBlockTimestamp()

  const yesterdayTimestamp = useMemo(() => {
    return timestamp
      .div('3600')
      .sub('168')
      .mul('3600')
      .toNumber()
  }, [timestamp])

  const currentTimestamp = useMemo(() => {
    return timestamp
      .div('3600')
      .mul('3600')
      .toNumber()
  }, [timestamp])

  return useTokenHourlyData(token, yesterdayTimestamp, currentTimestamp)
}

export function usePreviousDayTokenHourlyData(
  token: Token | undefined,
): {
  loading: boolean
  error: boolean
  data: TokenHourData[] | undefined
} {
  const timestamp = useBlockTimestamp()

  const twoDayTimestamp = useMemo(() => {
    return timestamp
      .div('3600')
      .sub('48')
      .mul('3600')
      .toNumber()
  }, [timestamp])

  const yesterdayTimestamp = useMemo(() => {
    return timestamp
      .div('3600')
      .sub('24')
      .mul('3600')
      .toNumber()
  }, [timestamp])

  return useTokenHourlyData(token, twoDayTimestamp, yesterdayTimestamp)
}

export function usePreviousWeekTokenHourlyData(
  token: Token | undefined,
): {
  loading: boolean
  error: boolean
  data: TokenHourData[] | undefined
} {
  const timestamp = useBlockTimestamp()

  const startTimestamp = useMemo(() => {
    return timestamp
      .div('3600')
      .sub('336')
      .mul('3600')
      .toNumber()
  }, [timestamp])

  const endTimestamp = useMemo(() => {
    return timestamp
      .div('3600')
      .sub('168')
      .mul('3600')
      .toNumber()
  }, [timestamp])

  return useTokenHourlyData(token, startTimestamp, endTimestamp)
}

export function useTokenHourlyData(
  token: Token | undefined,
  startTimestamp: number,
  endTimestamp: number,
): {
  loading: boolean
  error: boolean
  data: TokenHourData[] | undefined
} {
  const allRecords = useMemo(() => {
    if (!token) {
      return undefined
    }
    return {
      startTimestamp,
      endTimestamp,
      tokenAddress: token.address.toLowerCase(),
    }
  }, [endTimestamp, startTimestamp, token])
  const queryState = useGraphqlResult<TokenHourDataResponse>(
    GraphqlClientType.Dolomite,
    TOKEN_HOURS_FOR_PAST_DAY_GQL.loc!.source.body,
    allRecords,
    RefreshFrequency.Medium,
  )

  return useMemo(() => {
    const { loading, error, result } = queryState
    const anyLoading = Boolean(loading)
    const anyError = Boolean(error)

    if (!token) {
      return {
        loading: false,
        error: true,
        data: undefined,
      }
    }

    const tokenId = result?.tokenHourDatas?.[0]?.token.id
    if (!tokenId || toChecksumAddress(tokenId) !== token.address) {
      return {
        loading: anyLoading,
        error: anyError,
        data: undefined,
      }
    }

    const tokenHourDatas = result?.tokenHourDatas?.map<TokenHourData>(data => {
      return {
        hourStartUnix: new Date(data.hourStartUnix * 1000),
        token: token,
        hourlyAmmTradeVolumeUSD: createFractionUSD(data.hourlyAmmTradeVolumeUSD),
        hourlyBorrowVolumeUSD: createFractionUSD(data.hourlyBorrowVolumeUSD),
        hourlyLiquidationVolumeUSD: createFractionUSD(data.hourlyLiquidationVolumeUSD),
        hourlyTradeVolumeUSD: createFractionUSD(data.hourlyTradeVolumeUSD),
        hourlyVaporizationVolumeUSD: createFractionUSD(data.hourlyVaporizationVolumeUSD),
        hourlyAmmTradeVolumeToken: createCurrencyAmount(token, data.hourlyAmmTradeVolumeToken),
        hourlyBorrowVolumeToken: createCurrencyAmount(token, data.hourlyBorrowVolumeToken),
        hourlyLiquidationVolumeToken: createCurrencyAmount(token, data.hourlyLiquidationVolumeToken),
        hourlyTradeVolumeToken: createCurrencyAmount(token, data.hourlyTradeVolumeToken),
        hourlyVaporizationVolumeToken: createCurrencyAmount(token, data.hourlyVaporizationVolumeToken),
        ammLiquidityUSD: createFractionUSD(data.ammLiquidityUSD),
        borrowLiquidityUSD: createFractionUSD(data.borrowLiquidityUSD),
        supplyLiquidityUSD: createFractionUSD(data.supplyLiquidityUSD),
        ammLiquidityToken: createCurrencyAmount(token, data.ammLiquidityToken),
        borrowLiquidityToken: createCurrencyAmount(token, data.borrowLiquidityToken),
        supplyLiquidityToken: createCurrencyAmount(token, data.supplyLiquidityToken),
        hourlyAllTransactionCount: JSBI.BigInt(data.hourlyAllTransactionCount),
        hourlyAmmTradeCount: JSBI.BigInt(data.hourlyAmmTradeCount),
        hourlyLiquidationCount: JSBI.BigInt(data.hourlyLiquidationCount),
        hourlyTradeCount: JSBI.BigInt(data.hourlyTradeCount),
        hourlyVaporizationCount: JSBI.BigInt(data.hourlyVaporizationCount),
        openPriceUSD: createFractionUSD(data.openPriceUSD),
        highPriceUSD: createFractionUSD(data.highPriceUSD),
        lowPriceUSD: createFractionUSD(data.lowPriceUSD),
        closePriceUSD: createFractionUSD(data.closePriceUSD),
      }
    })

    return {
      loading: anyLoading,
      error: anyError,
      data: tokenHourDatas,
    }
  }, [queryState, token])
}
