import React, { useContext } from 'react'
import { Currency, Price } from '@dolomite-exchange/v2-sdk'
import { Text } from 'rebass'
import styled, { ThemeContext } from 'styled-components/macro'
import cleanCurrencySymbol from '../../utils/cleanCurrencySymbol'
import { useTranslation } from 'react-i18next'
import { formatAmount } from '../../utils/formatAmount'
import { ZERO_FRACTION } from '../../constants'

const Label = styled.span`
  color: ${({ theme }) => theme.text2};
`

const TextBody = styled.div`
  width: fit-content;
  cursor: pointer;
`

interface TradePriceProps {
  price?: Price<Currency, Currency>
  showInverted: boolean
  setShowInverted: (showInverted: boolean) => void
}

export default function TradePrice({ price, showInverted, setShowInverted }: TradePriceProps) {
  const theme = useContext(ThemeContext)
  const { t } = useTranslation()

  const priceFraction = price ? price.asFraction.multiply(price.scalar) : undefined
  const priceFractionInverted = priceFraction ? priceFraction.invert() : undefined
  const formattedPrice = priceFraction?.equalTo(ZERO_FRACTION)
    ? '0.00'
    : formatAmount(showInverted ? priceFractionInverted : priceFraction)

  const show = Boolean(price?.baseCurrency && price.quoteCurrency)
  const label = showInverted
    ? `${cleanCurrencySymbol(price?.baseCurrency)} ${t('per')} ${cleanCurrencySymbol(price?.quoteCurrency)}`
    : `${cleanCurrencySymbol(price?.quoteCurrency)} ${t('per')} ${cleanCurrencySymbol(price?.baseCurrency)}`

  return (
    <Text
      fontWeight={500}
      fontSize={12}
      color={theme.text1}
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
      }}
    >
      {show ? (
        <TextBody onClick={() => setShowInverted(!showInverted)}>
          {/*<StyledBalanceMaxMini onClick={() => setShowInverted(!showInverted)}>
            <Repeat size={14} />
          </StyledBalanceMaxMini>*/}
          {formattedPrice ?? '-'} <Label>{label}</Label>
        </TextBody>
      ) : (
        '-'
      )}
    </Text>
  )
}
