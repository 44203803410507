import { AmmPairData } from '../../../types/topPairData'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { useToken } from '../../../hooks/Tokens'
import { useHistory } from 'react-router-dom'
import { useCurrentWeekPairDayData } from '../../../types/ammPairDayData'
import cleanCurrencySymbol from '../../../utils/cleanCurrencySymbol'
import { sumFractions } from '../../../utils/numberOperations'
import getLogoOrDefault from '../../common/TokenLogos'
import { formatAmount } from '../../../utils/formatAmount'
import { useTranslation } from 'react-i18next'
import Column from '../../Orders/Column'
import { TableLoader } from '../../Loader'
import { Arrow, Backward, Forward, PageSelect } from '../styled'
import { ColumnTitles, HideOnSmall, StatRows, StatTable } from '../Overview'
import styled from 'styled-components/macro'
import { PageSizeContext } from '../../../pages/App'

const Index = styled.div`
  width: 5%;
  display: inline-block;
`

const TokenLogo = styled.div`
  display: inline-block;
  margin-right: 2px;
  margin-top: 0;
  vertical-align: top;
  width: 19px;

  @media (max-width: 960px) {
    width: 17px;
  }

  @media (max-width: 660px) {
    width: 15px;
  }

  img {
    width: 100%;
  }
`

const LogoHelper = styled.span`
  display: inline-block;
  height: 100%;
  vertical-align: middle;
`

const Ticker = styled.span<{ secondary?: boolean | null }>`
  color: ${({ theme, secondary }) => (secondary ? theme.text3 : theme.text1)};
  font-weight: 400;
  margin-top: 0;
  margin-left: 2px;
`

const PoolRowWrapper = styled.div`
  height: 40px;
  cursor: pointer;
  border-radius: 5px;
  padding: 10px 20px;
  width: calc(100% + 40px);
  margin-left: -20px;
  font-size: 15px;

  :hover {
    background-color: ${({ theme }) => theme.bg2};
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 14px;
    padding: 8px 20px;
  `};

  @media screen and (max-width: 420px) {
    font-size: 12px;
  }
`

const PoolName = styled.div`
  width: 50%;
  display: inline-block;

  @media (max-width: 960px) {
    width: 41%;
  }

  @media (max-width: 660px) {
    width: 35%;
  }
`

const PoolMetric = styled.div<{ hideOnMobile?: boolean | null }>`
  width: 15%;
  text-align: right;
  display: inline-block;

  @media (max-width: 960px) {
    width: 18%;
  }

  @media (max-width: 660px) {
    width: 30%;
    display: ${({ hideOnMobile }) => (hideOnMobile ? 'none' : 'inline-block')};
  }
`

const ColumnWrapper = styled(Column)`
  cursor: pointer;
`

const NoPools = styled.div`
  color: ${({ theme }) => theme.text3};
  margin: 20px auto;
  width: 265px;
  text-align: center;
`

interface PoolRowProps {
  pool: AmmPairData
  index: number
}

const poolRowComparator = (prevProps: PoolRowProps, nextProps: PoolRowProps) => {
  return (
    //TODO - have it compare the exact object
    prevProps.pool === nextProps.pool
  )
}

const PoolRow = React.memo<PoolRowProps>(function WrappedComponent({ pool, index }: PoolRowProps) {
  const pairAddress = pool.id.toLowerCase()
  const token0 = useToken(pool.token0)
  const token1 = useToken(pool.token1)
  const history = useHistory()
  const { data: pairDataWeek } = useCurrentWeekPairDayData(pairAddress)
  const token0Symbol = cleanCurrencySymbol(token0) ?? ''
  const token1Symbol = cleanCurrencySymbol(token1) ?? ''

  const volumeWeek = useMemo(() => {
    return sumFractions(pairDataWeek ?? [], data => data.dailyVolumeUSD)
  }, [pairDataWeek])

  return (
    <PoolRowWrapper onClick={() => history.push(`/stats/pool/${pairAddress}`)}>
      <Index>{index + 1}</Index>
      <PoolName>
        <TokenLogo>
          <LogoHelper>
            <img src={getLogoOrDefault(token0Symbol || '')} alt={`${token0Symbol} logo`} />
          </LogoHelper>
        </TokenLogo>
        <TokenLogo>
          <LogoHelper>
            <img src={getLogoOrDefault(token1Symbol || '')} alt={`${token1Symbol} logo`} />
          </LogoHelper>
        </TokenLogo>
        <Ticker>{`${token0Symbol}-${token1Symbol}`}</Ticker>
      </PoolName>
      <PoolMetric hideOnMobile={true}>{formatAmount(pool.reserveUSD, 2, true, '$0.00', true)}</PoolMetric>
      <PoolMetric>{formatAmount(pool.volumeUSD, 2, true, '$0.00', true)}</PoolMetric>
      <PoolMetric>{formatAmount(volumeWeek, 2, true, '$0.00', true)}</PoolMetric>
    </PoolRowWrapper>
  )
}, poolRowComparator)

const POOLS_SORT = {
  reserveUSD: 'reserveUSD',
  volumeUSD: 'volumeUSD',
}

const poolWidths = {
  widths: [2, 16, 10, 10, 10],
  starts: [0, 5, 60, 75, 90],
  tabletWidths: [3, 25, 12, 12, 12],
  tabletStarts: [0, 5, 52, 70, 88],
  mobileWidths: [3, 22, 20, 20],
  mobileStarts: [0, 5, 48, 80],
}

export default function PoolTable({ pools, isLoading }: { pools: AmmPairData[]; isLoading: boolean }) {
  const { t } = useTranslation()
  const [sortField, setSortField] = useState(POOLS_SORT.reserveUSD)
  const [sortDirection, setSortDirection] = useState<boolean>(true)
  const [page, setPage] = useState(1)

  const lastPage = Math.max(1, Math.ceil(pools.length / 10))

  const handleSort = useCallback(
    (newField: string) => {
      setSortField(newField)
      setSortDirection(sortField !== newField ? true : !sortDirection)
    },
    [sortDirection, sortField],
  )

  const arrow = useCallback(
    (field: string) => {
      return sortField === field ? (!sortDirection ? '↑' : '↓') : ''
    },
    [sortDirection, sortField],
  )

  const { x, y } = useContext(PageSizeContext)
  const isMobile = x < 960
  const isTablet = 660 < x && x < 960

  return (
    <StatTable>
      <ColumnTitles>
        <Column width={5} textAlign={'left'}>
          {t('#')}
        </Column>
        <Column width={50} tabletWidth={41} mobileWidth={35} textAlign={'left'}>
          {t('pool')}
        </Column>
        <ColumnWrapper
          width={15}
          tabletWidth={18}
          hideOnMobile={true}
          textAlign={'right'}
          onClick={() => handleSort(POOLS_SORT.reserveUSD)}
        >
          {t('reserve')} {arrow(POOLS_SORT.reserveUSD)}
        </ColumnWrapper>
        <ColumnWrapper
          width={15}
          tabletWidth={18}
          mobileWidth={30}
          textAlign={'right'}
          onClick={() => handleSort(POOLS_SORT.volumeUSD)}
        >
          24H Vol<HideOnSmall>ume</HideOnSmall> {arrow(POOLS_SORT.volumeUSD)}
        </ColumnWrapper>
        <Column width={15} tabletWidth={18} mobileWidth={30} textAlign={'right'}>
          7D Vol<HideOnSmall>ume</HideOnSmall>
        </Column>
      </ColumnTitles>
      <StatRows>
        {isLoading && pools.length === 0 ? (
          <TableLoader
            rows={5}
            height={20}
            spacing={40}
            wrapperHeight={190}
            marginTop={20}
            isMobile={isMobile}
            isTablet={isTablet}
            widths={poolWidths}
          />
        ) : pools.length === 0 ? (
          <NoPools>There are no pools for this token</NoPools>
        ) : (
          pools.map((pool, index) => <PoolRow key={`topPool-${pool.id}`} pool={pool} index={index + (page - 1) * 10} />)
        )}
      </StatRows>
      <PageSelect>
        <Arrow onClick={() => setPage(page === 1 ? page : page - 1)} faded={page === 1}>
          <Backward />
        </Arrow>
        {page}
        <Arrow onClick={() => setPage(page === lastPage ? page : page + 1)} faded={page === lastPage}>
          <Forward />
        </Arrow>
      </PageSelect>
    </StatTable>
  )
}
