import {
  ChainId,
  FS_GLP_ISOLATION_MODE_ADDRESSES,
  S_GLP_ADDRESSES,
  STAKED_GLP_REWARD_TRACKER_ADDRESSES,
} from '../../index'
import { Token } from '@dolomite-exchange/v2-sdk'
import { calculateGLPProxyVaultAddress } from '../../../utils/isolation/calculateProxyVaultAddress'
import { getFunctionalOutsideAprCallData, getOptionally } from '../special-assets-util-functions'
import { SpecialAsset, SpecialAssetSymbol } from '../special-assets'

export const GLP_SPECIAL_ASSET: () => SpecialAsset = () => ({
  symbol: SpecialAssetSymbol.dfsGLP,
  safeSymbol: SpecialAssetSymbol.dfsGLP,
  cleanSymbol: 'GLP',
  cleanName: 'GMX LP',
  hasExternalRewards: true,
  documentationUrl: 'https://docs.dolomite.io/integrations/gmx-glp',
  isIsolationMode: true,
  isBorrowingEnabled: false,
  chainIdToAddressMap: FS_GLP_ISOLATION_MODE_ADDRESSES,
  isolationModeInfo: {
    getWrappedToken: (chainId: ChainId) =>
      getOptionally(
        FS_GLP_ISOLATION_MODE_ADDRESSES[chainId],
        address => new Token(chainId, address, 18, 'dfsGLP', 'Dolomite Isolation: Fee + Staked GLP'),
      ),
    getUnderlyingToken: (chainId: ChainId) =>
      getOptionally(
        STAKED_GLP_REWARD_TRACKER_ADDRESSES[chainId],
        address => new Token(chainId, address, 18, 'GLP', 'GMX LP'),
      ),
    getUnderlyingSpenderToken: (chainId: ChainId) =>
      getOptionally(S_GLP_ADDRESSES[chainId], address => new Token(chainId, address, 18, 'sGLP', 'StakedGlp')),
    allowableCollateralTokens: () => [],
    allowableDebtTokens: () => [],
    remapAccountAddress: calculateGLPProxyVaultAddress,
    isAsync: false,
  },
  rewardInfo: {
    getOutsideAprCallData: chainId => getFunctionalOutsideAprCallData(chainId, SpecialAssetSymbol.dfsGLP),
  },
})
