import { Fraction, Token } from '@dolomite-exchange/sdk-core'
import { useDWmntUserVaultContract } from '../useContract'
import { useMemo } from 'react'
import { useSingleCallResult } from '../../state/multicall/hooks'
import { useDWmntSpecialAsset } from '../../constants/isolation/special-assets'
import { useActiveWeb3React } from '../index'
import { useETHBalances, useTokenBalance } from '../../state/wallet/hooks'
import { useDefaultFiatValuesWithLoadingIndicator } from '../useFiatValue'
import { ChainId, ZERO_FRACTION } from '../../constants'
import useIsolationModeUserVaultAddressIfCreated from '../useIsolationModeUserVaultAddressIfCreated'

const NO_ARGS: any[] = []

export function useWmntStakedBalance(): Fraction | undefined {
  const { chainId } = useActiveWeb3React()

  const dWmntSpecialAsset = useDWmntSpecialAsset()
  const proxyVaultAddress = useIsolationModeUserVaultAddressIfCreated(
    dWmntSpecialAsset.isolationModeInfo?.getWrappedToken(chainId),
  )
  const proxyVault = useDWmntUserVaultContract(proxyVaultAddress)
  const callState = useSingleCallResult(proxyVault, 'stakedBalance', NO_ARGS)

  return useMemo(() => {
    const balance = callState.result?.[0].toString()
    if (!balance) {
      return undefined
    }

    return new Fraction(balance, '1000000000000000000')
  }, [callState.result])
}

export function useWmntUnstakedBalance(): Fraction | undefined {
  const { account, chainId } = useActiveWeb3React()
  const dWmnt = useDWmntSpecialAsset()
  const wmntToken = useWmntToken()
  const proxyVault = dWmnt.isolationModeInfo?.remapAccountAddress?.(account, chainId)
  const balance = useTokenBalance(proxyVault, wmntToken)
  return useMemo(() => balance?.asFraction, [balance])
}

export function useTotalStakedWmntSupply(): Fraction | undefined {
  const { chainId } = useActiveWeb3React()
  const rewardStation = useMemo(() => {
    if (chainId !== ChainId.MANTLE) {
      return undefined
    }

    return ['0xeD884f0460A634C69dbb7def54858465808AACEf']
  }, [chainId])
  const stakedBalance = useETHBalances(rewardStation)
  return stakedBalance[rewardStation?.[0] ?? '']
}

export function useTotalStakedWmntSupplyUSD(): Fraction | undefined {
  const wmntToken = useWmntToken()
  const wmntSupply = useTotalStakedWmntSupply()
  const dWmntToken = useDwmntToken()
  const [fiatMap] = useDefaultFiatValuesWithLoadingIndicator(
    useMemo(() => (dWmntToken ? [dWmntToken] : []), [dWmntToken]),
  )
  return useMemo(() => {
    const wmntPrice = fiatMap[dWmntToken?.address ?? '']
    if (!wmntPrice) {
      return ZERO_FRACTION
    }
    return wmntSupply?.asFraction.multiply(wmntPrice)
  }, [dWmntToken, fiatMap, wmntSupply])
}

export function useDwmntToken(): Token | undefined {
  const { chainId } = useActiveWeb3React()
  const dWmnt = useDWmntSpecialAsset()
  return useMemo(() => dWmnt.isolationModeInfo?.getWrappedToken(chainId), [chainId, dWmnt])
}

export function useWmntToken(): Token | undefined {
  const { chainId } = useActiveWeb3React()
  const dWmntSpecialAsset = useDWmntSpecialAsset()
  return useMemo(() => dWmntSpecialAsset.isolationModeInfo?.getUnderlyingToken(chainId), [dWmntSpecialAsset, chainId])
}
