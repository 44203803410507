import { Token } from '@dolomite-exchange/v2-sdk'

export default function tokenEqualsOpt(tokenA: Token | undefined, tokenB: Token | undefined): boolean {
  if (!tokenA && !tokenB) {
    return true
  } else if (!tokenA || !tokenB) {
    return false
  }

  return tokenA.equals(tokenB)
}
