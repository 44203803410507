import React, { Dispatch, SetStateAction, ReactNode, useState, useEffect } from 'react'
import { BarChart, ResponsiveContainer, XAxis, Tooltip, Bar } from 'recharts'
import { DEFAULT_HEIGHT, RowBetween, Wrapper } from './styled'
import { ChartData, loadingData } from './LineChart'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import useTheme from '../../../hooks/useTheme'
dayjs.extend(utc)

export enum VolumeWindow {
  daily,
  weekly,
  monthly,
}

export type LineChartProps = {
  data: any[]
  loading: boolean
  color?: string | undefined
  height?: number | undefined
  minHeight?: number
  setValue?: Dispatch<SetStateAction<number | undefined>> // used for value on hover
  setLabel?: Dispatch<SetStateAction<string | undefined>> // used for label of valye
  defaultValue?: number
  value?: number
  defaultLabel?: string
  label?: string
  activeWindow?: VolumeWindow
  topLeft?: ReactNode | undefined
  topRight?: ReactNode | undefined
  bottomLeft?: ReactNode | undefined
  bottomRight?: ReactNode | undefined
} & React.HTMLAttributes<HTMLDivElement>

const CustomBar = ({
  x,
  y,
  width,
  height,
  fill,
}: {
  x: number
  y: number
  width: number
  height: number
  fill: string
}) => {
  return (
    <g>
      <rect x={x} y={y} fill={fill} width={width} height={height} rx='2' />
    </g>
  )
}

const Chart = ({
  data,
  loading,
  color = '#56B2A4',
  setValue,
  setLabel,
  defaultValue,
  value,
  defaultLabel,
  label,
  activeWindow,
  topLeft,
  topRight,
  bottomLeft,
  bottomRight,
  minHeight = DEFAULT_HEIGHT,
  ...rest
}: LineChartProps) => {
  const theme = useTheme()
  const [chartData, setChartData] = useState<ChartData[] | []>([])
  const parsedValue = value
  color = chartData.length ? color : theme.text3

  useEffect(() => {
    data.length && setChartData(data)
  }, [chartData, data])

  const now = dayjs()

  return (
    <Wrapper minHeight={minHeight} {...rest}>
      <RowBetween style={{ alignItems: 'flex-start' }}>
        {topLeft ?? null}
        {topRight ?? null}
      </RowBetween>
      <ResponsiveContainer width='100%' height='100%'>
        <BarChart
          width={500}
          height={300}
          data={chartData.length ? chartData : loadingData}
          margin={{
            top: 0,
            right: 15,
            left: 15,
            bottom: 0,
          }}
          onMouseMove={(chart: any) => {
            if (!chart.activePayload || chart.activePayload.length === 0) {
              return
            }

            const payload = chart.activePayload[0].payload
            if (setValue && parsedValue !== payload.value && chartData.length) {
              setValue(payload.value)
            }
            const formattedTime = dayjs(payload.time).format('MMM D')
            const formattedTimeDaily = dayjs(payload.time).format('MMM D YYYY')
            const formattedTimePlusWeek = dayjs(payload.time).add(1, 'week')
            const formattedTimePlusMonth = dayjs(payload.time).add(1, 'month')

            if (setLabel && label !== formattedTime && chartData.length) {
              if (activeWindow === VolumeWindow.weekly) {
                const isCurrent = formattedTimePlusWeek.isAfter(now)
                setLabel(formattedTime + '-' + (isCurrent ? 'current' : formattedTimePlusWeek.format('MMM D, YYYY')))
              } else if (activeWindow === VolumeWindow.monthly) {
                const isCurrent = formattedTimePlusMonth.isAfter(now)
                setLabel(formattedTime + '-' + (isCurrent ? 'current' : formattedTimePlusMonth.format('MMM D, YYYY')))
              } else {
                setLabel(formattedTimeDaily)
              }
            }
          }}
          onMouseLeave={() => {
            setLabel?.(defaultLabel)
            setValue?.(defaultValue)
          }}
        >
          <XAxis
            dataKey='time'
            tick={{ fontSize: 14, fill: theme.text3 }}
            axisLine={false}
            tickLine={false}
            tickFormatter={time => {
              const day = dayjs(time)
              if (day.isValid() && time !== 0 && chartData.length) {
                return day.format(activeWindow === VolumeWindow.monthly ? 'MMM' : 'DD')
              } else {
                return ''
              }
            }}
            minTickGap={10}
          />
          <Tooltip
            cursor={{ fill: theme.bg2 }}
            contentStyle={{ display: 'none' }}
            formatter={(value: number, name: string, props: { payload: { time: string; value: number } }) => {
              return ''
            }}
          />
          <Bar
            dataKey='value'
            fill={color}
            shape={props => {
              return <CustomBar height={props.height} width={props.width} x={props.x} y={props.y} fill={color} />
            }}
          />
        </BarChart>
      </ResponsiveContainer>
      <RowBetween>
        {bottomLeft ?? null}
        {bottomRight ?? null}
      </RowBetween>
    </Wrapper>
  )
}

export default Chart
