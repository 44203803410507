import { Currency } from '@dolomite-exchange/v2-sdk'
import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components/macro'
import Modal from '../Modal'
import { CloseIcon, ExternalLink } from '../../theme'
import { Text } from 'rebass'
import { RowBetween, RowFixed } from '../Row'
import { AlertTriangle, ArrowUpCircle, CheckCircle } from 'react-feather'
import { ButtonLight, ButtonPrimary } from '../Button'
import { AutoColumn, ColumnCenter } from '../Column'
import MetaMaskLogo from '../../assets/images/metamask.png'
import { getBlockExplorerName, getEtherscanLink } from '../../utils'
import { useActiveWeb3React } from '../../hooks'
import useAddTokenToMetamask from 'hooks/useAddTokenToMetamask'
import { ChainId } from '../../constants'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useIsTransactionPending } from '../../state/transactions/hooks'
import { useTranslation } from 'react-i18next'
import cleanCurrencySymbol from '../../utils/cleanCurrencySymbol'

const Wrapper = styled.div`
  width: 100%;
`
const Section = styled(AutoColumn)`
  padding: 24px 32px;
`

const BottomSection = styled(Section)`
  background-color: ${({ theme }) => theme.bg2};
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`

const ConfirmedIcon = styled(ColumnCenter)`
  padding: 60px 0;

  > div {
    color: ${({ theme }) => theme.text1} !important;
    height: 90px !important;
    width: 90px !important;
  }
`

const StyledLogo = styled.img`
  height: 16px;
  width: 16px;
  margin-left: 6px;
`

function ConfirmationPendingContent({ onDismiss, pendingText }: { onDismiss: () => void; pendingText: string }) {
  return (
    <Wrapper>
      <Section>
        <RowBetween>
          <div />
          <CloseIcon onClick={onDismiss} />
        </RowBetween>
        <ConfirmedIcon>
          <CircularProgress />
        </ConfirmedIcon>
        <AutoColumn gap='12px' justify={'center'}>
          <Text fontWeight={500} fontSize={20}>
            Waiting For Confirmation
          </Text>
          <AutoColumn gap='12px' justify={'center'}>
            <Text fontWeight={600} fontSize={14} color='' textAlign='center'>
              {pendingText}
            </Text>
          </AutoColumn>
          <Text fontSize={12} color='#565A69' textAlign='center'>
            Confirm this transaction in your wallet. It may take a few moments to appear.
          </Text>
        </AutoColumn>
      </Section>
    </Wrapper>
  )
}

function TransactionSubmittedContent({
  onDismiss,
  chainId,
  hash,
  currencyToAdd,
}: {
  onDismiss: () => void
  hash: string | undefined
  chainId: ChainId
  currencyToAdd?: Currency | undefined
}) {
  const theme = useContext(ThemeContext)
  const { t } = useTranslation()

  const { library } = useActiveWeb3React()

  const { addToken, success } = useAddTokenToMetamask(currencyToAdd)

  const symbol = cleanCurrencySymbol(currencyToAdd)

  const isTransactionPending = useIsTransactionPending(hash)

  return (
    <Wrapper>
      <Section>
        <RowBetween>
          <div />
          <CloseIcon onClick={onDismiss} />
        </RowBetween>
        <ConfirmedIcon>
          {isTransactionPending ? (
            <ArrowUpCircle strokeWidth={0.5} size={90} color={theme.text1} />
          ) : (
            <CheckCircle strokeWidth={0.5} size={90} color={theme.text1} />
          )}
        </ConfirmedIcon>
        <AutoColumn gap='5px' justify={'center'}>
          <Text fontWeight={500} fontSize={20}>
            {isTransactionPending ? t('transactionSubmitted') : t('transactionConfirmed')}
          </Text>
          {chainId && hash && (
            <ExternalLink href={getEtherscanLink(chainId, hash, 'transaction')}>
              <Text fontWeight={500} fontSize={14} color={theme.text3}>
                View on {getBlockExplorerName(chainId)}
              </Text>
            </ExternalLink>
          )}
          {currencyToAdd && 'provider' in library && library.provider?.isMetaMask && (
            <ButtonLight mt='12px' padding='6px 12px' width='fit-content' onClick={addToken}>
              {!success ? (
                <RowFixed>
                  Add {symbol} to Metamask <StyledLogo src={MetaMaskLogo} />
                </RowFixed>
              ) : (
                <RowFixed>
                  Added {symbol} <CheckCircle size={'16px'} stroke={theme.green1} style={{ marginLeft: '6px' }} />
                </RowFixed>
              )}
            </ButtonLight>
          )}
          <ButtonPrimary onClick={onDismiss} style={{ margin: '20px 0 0 0' }}>
            <Text fontWeight={500} fontSize={20}>
              Close
            </Text>
          </ButtonPrimary>
        </AutoColumn>
      </Section>
    </Wrapper>
  )
}

export function ConfirmationModalContent({
  title,
  onDismiss,
  topContent,
  bottomContent,
}: {
  title: string
  onDismiss: () => void
  topContent: () => React.ReactNode
  bottomContent: () => React.ReactNode
}) {
  return (
    <Wrapper>
      <Section>
        <RowBetween>
          <Text fontWeight={500} fontSize={24}>
            {title}
          </Text>
          <CloseIcon onClick={onDismiss} />
        </RowBetween>
        {topContent()}
      </Section>
      <BottomSection gap='12px'>{bottomContent()}</BottomSection>
    </Wrapper>
  )
}

export function TransactionErrorContent({ message, onDismiss }: { message: string; onDismiss: () => void }) {
  const theme = useContext(ThemeContext)
  return (
    <Wrapper>
      <Section>
        <RowBetween>
          <Text fontWeight={500} fontSize={20}>
            Error
          </Text>
          <CloseIcon onClick={onDismiss} />
        </RowBetween>
        <AutoColumn style={{ marginTop: 20, padding: '2rem 0' }} gap='24px' justify='center'>
          <AlertTriangle color={theme.red1} style={{ strokeWidth: 1.5 }} size={64} />
          <Text fontWeight={500} fontSize={16} color={theme.red1} style={{ textAlign: 'center', width: '85%' }}>
            {message}
          </Text>
        </AutoColumn>
      </Section>
      <BottomSection gap='12px'>
        <ButtonPrimary onClick={onDismiss}>Dismiss</ButtonPrimary>
      </BottomSection>
    </Wrapper>
  )
}

interface ConfirmationModalProps {
  isOpen: boolean
  memoizedOnDismiss: () => void
  hash: string | undefined
  memoizedContentCreator: () => React.ReactNode
  attemptingTxn: boolean
  pendingText: string
  currencyToAdd?: Currency | undefined
  disableSubmit?: boolean
  maxWidthPx?: number
}

function TransactionConfirmationModalComparator(prevProps: ConfirmationModalProps, nextProps: ConfirmationModalProps) {
  return (
    prevProps.isOpen === nextProps.isOpen &&
    prevProps.memoizedOnDismiss === nextProps.memoizedOnDismiss &&
    prevProps.hash === nextProps.hash &&
    prevProps.memoizedContentCreator === nextProps.memoizedContentCreator &&
    prevProps.attemptingTxn === nextProps.attemptingTxn &&
    prevProps.pendingText === nextProps.pendingText &&
    prevProps.currencyToAdd === nextProps.currencyToAdd &&
    prevProps.disableSubmit === nextProps.disableSubmit &&
    prevProps.maxWidthPx === nextProps.maxWidthPx
  )
}

function TransactionConfirmationModal({
  isOpen,
  memoizedOnDismiss,
  attemptingTxn,
  hash,
  pendingText,
  memoizedContentCreator,
  currencyToAdd,
  disableSubmit,
  maxWidthPx,
}: ConfirmationModalProps) {
  const { chainId } = useActiveWeb3React()

  if (!chainId) {
    return null
  }

  // confirmation screen
  return (
    <Modal isOpen={isOpen} onDismiss={memoizedOnDismiss} maxHeight={90} maxWidthPx={maxWidthPx}>
      {attemptingTxn ? (
        <ConfirmationPendingContent onDismiss={memoizedOnDismiss} pendingText={pendingText} />
      ) : hash && !disableSubmit ? (
        <TransactionSubmittedContent
          chainId={chainId}
          hash={hash}
          onDismiss={memoizedOnDismiss}
          currencyToAdd={currencyToAdd}
        />
      ) : (
        memoizedContentCreator()
      )}
    </Modal>
  )
}

export default React.memo(TransactionConfirmationModal, TransactionConfirmationModalComparator)
