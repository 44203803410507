import React, { useCallback } from 'react'
import styled from 'styled-components/macro'
import { useActiveWeb3React } from '../../hooks'
import { ButtonSecondary } from '../Button'
import { useTranslation } from 'react-i18next'

const Web3StatusGeneric = styled(ButtonSecondary)`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  align-items: center;
  padding: 0.5rem;
  border-radius: 5px;
  cursor: pointer;
  user-select: none;

  :focus {
    outline: none;
  }
`

const Web3StatusConnect = styled(Web3StatusGeneric)<{ faded?: boolean }>`
  background: #68b04d !important;
  color: white !important;
  font-weight: 600 !important;
  height: 40px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  border: none !important;

  &:hover {
    background: #8fc942 !important;
  }

  @media (max-width: 580px) {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
`

const Text = styled.p`
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 0.5rem 0 0.25rem;
  font-size: 1rem;
  width: fit-content;
  font-weight: 500;
`

export default function Web3StatusConnectButton() {
  const { t } = useTranslation()
  const { connect } = useActiveWeb3React()
  const doConnect = useCallback(() => {
    connect()
      .then(() => console.debug('Connected successfully!'))
      .catch(e => console.error('Error connecting:', e))
  }, [connect])

  return (
    <Web3StatusConnect id={'connect-wallet'} onClick={doConnect}>
      <Text>{t('connectWallet')}</Text>
    </Web3StatusConnect>
  )
}
