import { Contract } from '@ethersproject/contracts'
import {
  AssetDenomination,
  BalanceCheckFlag,
  ContractCallParameters,
  Percent,
  Router,
  Token,
  Trade,
  TradeType,
} from '@dolomite-exchange/v2-sdk'
import { useMemo } from 'react'
import { BIPS_BASE, USER_ERROR_CODES, MAX_UINT_256 } from '../constants'
import { useTransactionAdder } from '../state/transactions/hooks'
import { calculateGasMargin, getRouterContract } from '../utils'
import isZero from '../utils/isZero'
import {
  ContractCall,
  EstimatedContractCall,
  estimateGasAsync,
  FailedContractCall,
  SuccessfulContractCall,
  useActiveWeb3React,
} from './index'
import useTransactionDeadline from './useTransactionDeadline'
import { getTradeVersion, Version } from './useToggledVersion'
import JSBI from 'jsbi'
import { Currency, CurrencyAmount, Fraction } from '@dolomite-exchange/sdk-core'
import { UNITED_STATE_EXPIRATION_SECONDS } from './useIpGeolocation'
import { useTradeExactOut } from './Trades'
import { useUserSlippageTolerance } from '../state/user/hooks'
import { useMarketIndices } from './useDolomiteMarginProtocol'
import { MarginAccount } from '../types/marginAccount'
import { MarginPosition } from '../types/marginPositionData'
import cleanCurrencySymbol from '../utils/cleanCurrencySymbol'
import { useSerializedToken, useSerializedTokenOpt } from './Tokens'

export enum Web3CallbackState {
  INVALID,
  LOADING,
  VALID,
}

function useTradeCallArguments(
  trade: Trade<Currency, Currency, TradeType> | undefined,
  allowedSlippage: JSBI,
  tradeAccountNumber: JSBI,
  otherAccountNumber: JSBI,
  denomination: AssetDenomination,
  isAmountInPositive: boolean,
  isAmountOutPositive: boolean,
  balanceCheckFlag: BalanceCheckFlag,
  isDepositIntoTradeAccount?: boolean,
  marginTransferWei?: CurrencyAmount<Token>,
  isUnitedStatesIp?: boolean,
): ContractCall[] {
  const { account, chainId, library } = useActiveWeb3React()

  const deadline = useTransactionDeadline()

  return useMemo(() => {
    const tradeVersion = getTradeVersion(trade)
    if (!trade || !library || !account || !tradeVersion || !chainId || !deadline) return []

    const contract: Contract | null = getRouterContract(chainId, library, account)
    if (tradeVersion !== Version.v2) {
      console.error('Invalid trade version, found ', tradeVersion)
      return []
    }
    if (!contract) {
      return []
    }

    const tradeMethods: ContractCallParameters[] = []

    switch (tradeVersion) {
      case Version.v2:
        tradeMethods.push(
          Router.tradeCallParameters(
            trade,
            {
              allowedSlippage: new Percent(allowedSlippage, BIPS_BASE),
              deadline: deadline.toNumber(),
              balanceCheckFlag: balanceCheckFlag,
            },
            {
              tradeAccountNumber: tradeAccountNumber,
              otherAccountNumber: otherAccountNumber,
              denomination: denomination,
              isAmountInPositive: isAmountInPositive,
              isAmountOutPositive: isAmountOutPositive,
              marginTransferToken: marginTransferWei?.currency.address,
              isDepositIntoTradeAccount: isDepositIntoTradeAccount,
              marginTransferWei: marginTransferWei,
              expiryTimeDelta: isUnitedStatesIp ? UNITED_STATE_EXPIRATION_SECONDS : 0,
            },
          ),
        )

        if (trade.tradeType === TradeType.EXACT_INPUT) {
          tradeMethods.push(
            Router.tradeCallParameters(
              trade,
              {
                allowedSlippage: new Percent(JSBI.BigInt(allowedSlippage), BIPS_BASE),
                deadline: deadline.toNumber(),
                balanceCheckFlag: balanceCheckFlag,
              },
              {
                tradeAccountNumber: tradeAccountNumber,
                otherAccountNumber: otherAccountNumber,
                denomination: denomination,
                isAmountInPositive: isAmountInPositive,
                isAmountOutPositive: isAmountOutPositive,
                marginTransferWei: marginTransferWei,
                isDepositIntoTradeAccount: isDepositIntoTradeAccount,
                marginTransferToken: marginTransferWei?.currency.address,
                expiryTimeDelta: isUnitedStatesIp ? UNITED_STATE_EXPIRATION_SECONDS : 0,
              },
            ),
          )
        }
        break
      default:
        console.error('Invalid version, found ', tradeVersion)
        break
    }
    return tradeMethods.map(parameters => ({
      parameters,
      contract,
    }))
  }, [
    account,
    tradeAccountNumber,
    otherAccountNumber,
    denomination,
    isAmountInPositive,
    isAmountOutPositive,
    allowedSlippage,
    chainId,
    deadline,
    library,
    isDepositIntoTradeAccount,
    marginTransferWei,
    trade,
    isUnitedStatesIp,
    balanceCheckFlag,
  ])
}

/**
 * Returns the Trade calls that can be used to make the trade
 * @param trade                       The trade to execute
 * @param allowedSlippage             user allowed slippage, in bips
 * @param tradeAccountNumber          The account number on which the trade should be executed
 * @param otherAccountNumber          The account number from/to which the funds should be transferred
 * @param denomination                The denomination of the values of the `trade`
 * @param isAmountInPositive          True if the currency represented by amountIn for the `trade` has a positive
 *                                    balance in the user's margin account. False if the balance is negative.
 * @param isAmountOutPositive         True if the currency represented by amountOut for the `trade` has a positive
 *                                    balance in the user's margin account. False if the balance is negative
 * @param balanceCheckFlag            Whether to check certain (or all/none) of the balances for negative values once
 *                                    the trade settles. `From` checks `tradeAccountNumber`, `To` checks
 *                                    `otherAccountNumber`.
 * @param isDepositIntoTradeAccount   True if the `marginDeposit` is being deposited into `tradeAccountNumber` or false
 *                                    if it's being withdrawn from `tradeAccountNumber`
 * @param marginTransferWei           The amount to deposit or withdraw from or to tradeAccountNumber
 * @param isUnitedStatesIp            True if the user is opening a margin position from within the US, requiring an
 *                                    expiry within 28 days
 */
export function useTradeCallback(
  trade: Trade<Currency, Currency, TradeType> | undefined,
  allowedSlippage: JSBI,
  tradeAccountNumber: JSBI,
  otherAccountNumber: JSBI,
  denomination: AssetDenomination,
  isAmountInPositive: boolean,
  isAmountOutPositive: boolean,
  balanceCheckFlag: BalanceCheckFlag,
  isDepositIntoTradeAccount?: boolean,
  marginTransferWei?: CurrencyAmount<Token>,
  isUnitedStatesIp?: boolean,
): { state: Web3CallbackState; callback: null | (() => Promise<string>); error: string | null } {
  const { account, chainId, library } = useActiveWeb3React()

  const tradeCalls = useTradeCallArguments(
    trade,
    allowedSlippage,
    tradeAccountNumber,
    otherAccountNumber,
    denomination,
    isAmountInPositive,
    isAmountOutPositive,
    balanceCheckFlag,
    isDepositIntoTradeAccount,
    marginTransferWei,
    isUnitedStatesIp,
  )

  const addTransaction = useTransactionAdder()

  return useMemo(() => {
    if (!trade || !library || !account || !chainId) {
      return {
        state: Web3CallbackState.INVALID,
        callback: null,
        error: 'Missing dependencies',
      }
    }
    const tradeVersion = getTradeVersion(trade)

    return {
      state: Web3CallbackState.VALID,
      callback: async function onTrade(): Promise<string> {
        const estimatedCalls: EstimatedContractCall[] = await Promise.all(
          tradeCalls.map(call => {
            const {
              parameters: { methodName, args, value },
              contract,
            } = call
            const options = !value || isZero(value) ? {} : { value }

            return estimateGasAsync(contract, methodName, args, options)
          }),
        )

        // a successful estimation is a bignumber gas estimate and the next call is also a bignumber gas estimate
        const successfulEstimation = estimatedCalls.find(
          (el, ix, list): el is SuccessfulContractCall =>
            'gasEstimate' in el && (ix === list.length - 1 || 'gasEstimate' in list[ix + 1]),
        )

        if (!successfulEstimation) {
          const errorCalls = estimatedCalls.filter((call): call is FailedContractCall => 'error' in call)
          if (errorCalls.length > 0) {
            throw errorCalls[errorCalls.length - 1].error
          }
          throw new Error('Unexpected error. Please contact support: none of the calls threw an error')
        }

        const {
          call: {
            contract,
            parameters: { methodName, args, value },
          },
          gasEstimate,
        } = successfulEstimation

        return contract[methodName](...args, {
          gasLimit: calculateGasMargin(gasEstimate),
          ...(value && !isZero(value)
            ? {
                value,
                from: account,
              }
            : { from: account }),
        })
          .then((response: any) => {
            const inputSymbol = cleanCurrencySymbol(trade.inputAmount.currency)
            const outputSymbol = cleanCurrencySymbol(trade.outputAmount.currency)
            const inputAmount = trade.inputAmount.toSignificant(3)
            const outputAmount = trade.outputAmount.toSignificant(3)

            const base = `Trade ${inputAmount} ${inputSymbol} for ${outputAmount} ${outputSymbol}`

            const withVersion = tradeVersion === Version.v2 ? base : `${base} on ${(tradeVersion as any).toUpperCase()}`

            addTransaction(response, {
              summary: withVersion,
            })

            return response.hash
          })
          .catch((error: any) => {
            // if the user rejected the tx, pass this along
            if (error?.code === USER_ERROR_CODES.REJECTED) {
              throw new Error('Transaction rejected.')
            } else if (error?.code === -32603 && error?.message?.includes('429')) {
              throw new Error('Your RPC provider is too congested. Try again in a few seconds or switch providers.')
            } else {
              // otherwise, the error was unexpected, and we need to convey that
              console.error('Swap failed', error, methodName, args, value)
              throw new Error(`Swap failed: ${error.message}`)
            }
          })
      },
      error: null,
    }
  }, [trade, library, account, chainId, tradeCalls, addTransaction])
}

export function useClosePositionCallback(
  position: MarginPosition,
  defaultMarginAccount: MarginAccount,
): {
  state: Web3CallbackState
  callback: null | (() => Promise<string>)
  trade: Trade<Currency, Currency, TradeType> | undefined
  error: string | null
} {
  const denomination = AssetDenomination.Par
  const heldToken = useSerializedToken(position.heldToken)
  const owedToken = useSerializedTokenOpt(position.owedToken)
  const tradeToConfirm = useTradeExactOut(heldToken, position.owedAmountPar as CurrencyAmount<Token>, denomination)
  const [allowedSlippage] = useUserSlippageTolerance()
  const [indexMap] = useMarketIndices()
  const owedTokenIndex = useMemo(() => indexMap[owedToken?.address ?? ''], [owedToken, indexMap])
  const slippageWithIndexDifference = useMemo(() => {
    if (!owedTokenIndex || !tradeToConfirm) {
      return JSBI.BigInt(allowedSlippage)
    }

    // We need to adjust the slippage tolerance by the difference between the negative and positive number (since they use different indices)
    const positiveOwedAmountWei = tradeToConfirm.outputAmount.multiply(owedTokenIndex.supplyIndex).asFraction
    if (positiveOwedAmountWei.equalTo('0')) {
      return JSBI.BigInt(allowedSlippage)
    }

    const oneWei = CurrencyAmount.fromRawAmount(tradeToConfirm.outputAmount.currency, '1').asFraction
    const negativeOwedAmountWei = tradeToConfirm.outputAmount
      .multiply(owedTokenIndex.borrowIndex)
      .asFraction.add(oneWei)

    return new Fraction(allowedSlippage, '10000')
      .add(negativeOwedAmountWei.subtract(positiveOwedAmountWei).divide(positiveOwedAmountWei))
      .multiply('10000').quotient
  }, [allowedSlippage, owedTokenIndex, tradeToConfirm])

  const marginTransferWei = useMemo(
    () => (heldToken ? CurrencyAmount.fromRawAmount(heldToken, MAX_UINT_256) : undefined),
    [heldToken],
  )

  const result = useTradeCallback(
    tradeToConfirm,
    slippageWithIndexDifference,
    position.marginAccount.accountNumber,
    defaultMarginAccount.accountNumber,
    denomination,
    true,
    false,
    BalanceCheckFlag.Both,
    false,
    marginTransferWei,
  )

  return useMemo(
    () => ({
      ...result,
      trade: tradeToConfirm,
    }),
    [result, tradeToConfirm],
  )
}
