import { LiquidityMiningVestingPosition } from '../../types/liquidityMiningVestingData'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Percent } from '@dolomite-exchange/v2-sdk'
import { LIQUIDITY_MINING_LEVEL_THRESHOLD, useExtendVestForGrandfatheredPosition } from '../../hooks/useLiquidityMining'
import { useIsTransactionPending } from '../../state/transactions/hooks'
import Modal from '../../components/Modal'
import styled from 'styled-components/macro'
import InfoRoundedIcon from '@material-ui/icons/InfoRounded'
import Slider from '@mui/material/Slider'
import CircularProgress from '@material-ui/core/CircularProgress'
import { formatAmount } from '../../utils/formatAmount'
import { tryParseAmountWithNoCurrency } from '../../state/trade/hooks'

const ModalInner = styled.div`
  padding: 24px 32px;
  width: 100%;
`

const ModalTitle = styled.div`
  font-size: 24px;
  font-weight: 600;
  color: ${({ theme }) => theme.text1};
  margin-bottom: 15px;
`

const ModalSubtitle = styled.div`
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.text3};
  margin-bottom: 12px;
  margin-top: -12px;
`

const DescriptionText = styled.div`
  font-size: 12px;
  font-weight: 100;
  line-height: 14px;
  color: ${({ theme }) => theme.text2};
  width: 100%;
  position: relative;
  margin: -10px auto 10px;
  padding: 12px 16px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.bg2};

  svg {
    margin-right: 5px;
    margin-bottom: -3px;
    font-size: 24px;
    color: ${({ theme }) => theme.text3};
    display: inline-block;
    vertical-align: top;
  }

  a {
    color: ${({ theme }) => theme.text2};
    font-weight: 600;
  }

  @media screen and (max-width: 650px) {
  }
`

const DescriptionContent = styled.div`
  width: calc(100% - 30px);
  display: inline-block;
  vertical-align: top;

  a {
    outline: none !important;
    border: none !important;
  }
`

const YourLevelWrapper = styled.div`
  width: 100%;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  margin-top: -10px;
`

const DiscountWrapper = styled.div<{ disabled: boolean }>`
  border: 1px solid ${({ theme, disabled }) => (disabled ? theme.bg3 : theme.blue1)};
  background: ${({ disabled }) => (disabled ? 'none' : '#22344e')};
  box-shadow: ${({ disabled }) => (disabled ? 'none' : '#2172E5 0px 0px 9px -4px')};
  border-radius: 5px;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
`

const LevelWrapper = styled.div`
  font-size: 15px;
  color: ${({ theme }) => theme.text1};
  font-weight: 600;
`

const DiscountAmount = styled.div`
  font-size: 13px;
  color: ${({ theme }) => theme.text1};
  font-weight: 500;
`

const EarnMoreLevels = styled.div`
  margin-top: 4px;
  font-size: 13px;
  font-weight: 300;
  text-align: center;
  color: ${({ theme }) => theme.text1};

  a {
    color: ${({ theme }) => theme.text1};
    text-decoration: none !important;
    font-weight: 700;
  }
`

const NewLockupPeriod = styled.div`
  width: 100%;
  font-size: 13px;
  color: ${({ theme }) => theme.text1};
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`

const NewDiscount = styled.div`
  width: 100%;
  font-size: 13px;
  color: ${({ theme }) => theme.text1};
  display: flex;
  justify-content: space-between;
`

const LockupPeriodSlider = styled.div`
  width: 100%;
`

const VestingDescription = styled.div`
  font-size: 13px;
  font-weight: 400;
  color: ${({ theme }) => theme.text1};
  margin-top: 5px;
`

const ExecuteButton = styled.div<{ isActive: boolean }>`
  width: 100%;
  height: 38px;
  border-radius: 5px;
  background-color: ${({ theme, isActive }) => (isActive ? theme.green2 : theme.bg3)};
  color: ${({ theme }) => theme.text1};
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
  padding: 8px 20px;
  cursor: ${({ isActive }) => (isActive ? 'pointer' : 'default')};
  margin-top: 15px;
  text-align: center;

  :hover {
    background-color: ${({ theme, isActive }) => (isActive ? theme.green1 : theme.bg3)};
  }

  > svg,
  > div {
    color: ${({ theme }) => theme.text1} !important;
    font-size: 20px;
    height: 20px !important;
    width: 20px !important;
  }

  @media screen and (max-width: 650px) {
    margin-bottom: 20px;
  }
`

interface VestingTransitionModalProps {
  vestingPosition: LiquidityMiningVestingPosition | undefined
  userLevel: number
  setShowTransitionModal: React.Dispatch<React.SetStateAction<LiquidityMiningVestingPosition | undefined>>
}

function VestingTransitionModalComparator(
  prevProps: VestingTransitionModalProps,
  nextProps: VestingTransitionModalProps,
) {
  return prevProps.vestingPosition?.id === nextProps.vestingPosition?.id && prevProps.userLevel === nextProps.userLevel
}

const ONE_WEEK = 86_400 * 7
const SLIDER_START = 20
const SLIDER_END = 100
const SLIDE_STEP = 2.5

const VestingTransitionModal = ({
  vestingPosition,
  userLevel,
  setShowTransitionModal,
}: VestingTransitionModalProps) => {
  const [isExecuting, setIsExecuting] = React.useState(false)
  const [awaitingSignature, setAwaitingSignature] = useState(false)
  const [pendingHash, setPendingHash] = useState<string | undefined>(undefined)
  const [discountPercentInput, setDiscountPercentInput] = useState<number>(SLIDER_START)

  const vestDurationSeconds = useMemo(() => {
    return (discountPercentInput / 2.5) * ONE_WEEK
  }, [discountPercentInput])

  const effectiveVestDurationSeconds = useMemo(() => {
    return userLevel >= LIQUIDITY_MINING_LEVEL_THRESHOLD
      ? Math.floor((vestDurationSeconds * 2) / 3)
      : vestDurationSeconds
  }, [vestDurationSeconds, userLevel])

  const vestDurationWeeks = useMemo(() => {
    return tryParseAmountWithNoCurrency((vestDurationSeconds / ONE_WEEK).toString())
  }, [vestDurationSeconds])

  const effectiveVestDurationWeeks = useMemo(() => {
    return tryParseAmountWithNoCurrency((effectiveVestDurationSeconds / ONE_WEEK).toString())
  }, [effectiveVestDurationSeconds])

  const vestingRate = useMemo(() => {
    return new Percent(discountPercentInput * 4, 400)
  }, [discountPercentInput])

  const executionDate = useMemo(() => {
    if (!vestingPosition) {
      return undefined
    }
    return new Date(vestingPosition.startTimestamp.getTime() + effectiveVestDurationSeconds * 1000)
  }, [vestingPosition, effectiveVestDurationSeconds])

  const { callback: extendVest } = useExtendVestForGrandfatheredPosition(vestingPosition?.nftId, vestDurationSeconds)

  const isHashPending = useIsTransactionPending(pendingHash)
  useEffect(() => {
    if (!isHashPending) {
      setPendingHash(undefined)
      setIsExecuting(prevState => {
        if (prevState) {
          setShowTransitionModal(undefined)
        }
        return false
      })
    }
  }, [isHashPending, setShowTransitionModal])

  const onExtend = useCallback(() => {
    if (!extendVest || awaitingSignature) {
      return
    }

    setIsExecuting(true)
    setAwaitingSignature(true)
    extendVest()
      .then(hash => {
        setPendingHash(hash)
        setAwaitingSignature(false)
      })
      .catch(() => {
        setAwaitingSignature(false)
        setIsExecuting(false)
      })
  }, [awaitingSignature, extendVest])

  const onDismiss = useCallback(() => {
    setShowTransitionModal(undefined)
  }, [setShowTransitionModal])

  return (
    <Modal isOpen={vestingPosition !== undefined} onDismiss={onDismiss}>
      {vestingPosition && (
        <ModalInner>
          <ModalTitle>Transition Vest</ModalTitle>
          <ModalSubtitle>
            This vest was opened before Dolomite Rewards began offering longer vesting durations and greater discounts.
            As a result, you may choose to transition this vest to use one of the new vesting durations and receive the
            corresponding discount when vesting completes.
          </ModalSubtitle>
          <DescriptionText
            style={{
              marginBottom: '23px',
              marginTop: '5px',
            }}
          >
            <InfoRoundedIcon />
            <DescriptionContent>
              Users that are level 4 or higher receive accelerated vesting, receiving a 3.75% discount per week rather
              than 2.5%.{' '}
              <a href={'https://docs.dolomite.io/community-xp'} target={'_blank'} rel={'noreferrer'}>
                Learn more about the Dolomite XP Program
              </a>
              .
            </DescriptionContent>
          </DescriptionText>
          <YourLevelWrapper>
            <span>Your Level:</span>
            <span style={{ marginLeft: '4px' }}>{userLevel}</span>
          </YourLevelWrapper>
          <DiscountWrapper disabled={userLevel >= LIQUIDITY_MINING_LEVEL_THRESHOLD}>
            <LevelWrapper>Level 0-3</LevelWrapper>
            <DiscountAmount>Discount: 2.5% per week</DiscountAmount>
          </DiscountWrapper>
          <DiscountWrapper disabled={userLevel < LIQUIDITY_MINING_LEVEL_THRESHOLD}>
            <LevelWrapper>Level 4+</LevelWrapper>
            <DiscountAmount>Discount: 3.75% per week</DiscountAmount>
          </DiscountWrapper>
          <EarnMoreLevels>
            Earn XP and level up on the <a href={'https://app.dolomite.io/achievements'}>Achievements Page</a>
          </EarnMoreLevels>
          <NewLockupPeriod>
            <div>New Lockup Period</div>
            <div>
              {userLevel >= LIQUIDITY_MINING_LEVEL_THRESHOLD && (
                <span
                  style={{
                    textDecoration: 'line-through',
                    color: '#606375',
                  }}
                >
                  {formatAmount(vestDurationWeeks, 0)} {vestDurationWeeks?.equalTo(1) ? 'week' : 'weeks'}
                </span>
              )}{' '}
              {formatAmount(effectiveVestDurationWeeks, 2)} {effectiveVestDurationWeeks?.equalTo(1) ? 'week' : 'weeks'}
            </div>
          </NewLockupPeriod>
          <NewDiscount>
            <div>New Discount</div>
            <div>{discountPercentInput}%</div>
          </NewDiscount>
          <LockupPeriodSlider>
            <Slider
              aria-label='Discount'
              min={SLIDER_START}
              max={SLIDER_END}
              step={SLIDE_STEP}
              value={discountPercentInput}
              onChange={(event: Event, newValue: number | number[]) => setDiscountPercentInput(newValue as number)}
            />
          </LockupPeriodSlider>
          <VestingDescription>
            {vestingRate.equalTo(1)
              ? `Can receive ARB for free in ${formatAmount(
                  effectiveVestDurationWeeks,
                  2,
                )} weeks. Can execute on ${executionDate?.toDateString()}.`
              : `Can purchase ${
                  vestingPosition.oTokenAmount ? formatAmount(vestingPosition.oTokenAmount, 2) : ''
                } ARB in 
        ${formatAmount(effectiveVestDurationWeeks, 2)} weeks at a ${formatAmount(
                  vestingRate,
                )} discount from market price. Can execute on ${executionDate?.toDateString()}. Market price is calculated when executing.`}
          </VestingDescription>
          <ExecuteButton onClick={() => !isExecuting && onExtend()} isActive={true}>
            {isExecuting ? <CircularProgress /> : 'Transition Vest'}
          </ExecuteButton>
        </ModalInner>
      )}
    </Modal>
  )
}

export default React.memo(VestingTransitionModal, VestingTransitionModalComparator)
