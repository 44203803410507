import { Web3CallbackState } from '../useTradeCallback'
import { estimateGasAsync, SuccessfulContractCall, useActiveWeb3React } from '../index'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { useMemo } from 'react'
import { calculateGasMargin } from '../../utils'
import { USER_ERROR_CODES, MAX_UINT_256 } from '../../constants'
import useIsolationModeUserVaultAddressIfCreated from '../useIsolationModeUserVaultAddressIfCreated'
import { useAllTokens, useArbToken } from '../Tokens'
import { useDARBSpecialAsset } from '../../constants/isolation/special-assets'
import { ethers } from 'ethers'
import { BalanceCheckFlag, CurrencyAmount, Token } from '@dolomite-exchange/v2-sdk'
import { useDArbUserVaultContract } from '../useContract'
import { useSingleCallResult } from '../../state/multicall/hooks'
import { useDefaultMarginAccount } from '../../types/marginAccount'
import useTransactionDeadline from '../useTransactionDeadline'
import { GenericTraderType } from '@dolomite-exchange/zap-sdk'
import Deployments from '@dolomite-exchange/modules-deployments/src/deploy/deployments.json'
import isInputValueCloseToBalance from '../../utils/isInputValueCloseToBalance'
import { ZapEventType } from '../useExecuteZap'

export function useDelegateArb(
  delegatee: string | undefined,
): { state: Web3CallbackState; callback: null | (() => Promise<string>); error: string | null } {
  const { chainId, account } = useActiveWeb3React()
  const dArb = useDArbToken()
  const vaultAddress = useIsolationModeUserVaultAddressIfCreated(dArb)
  const vaultContract = useDArbUserVaultContract(vaultAddress)
  const addTransaction = useTransactionAdder()

  return useMemo(() => {
    if (!dArb || !account || !chainId || !vaultContract || !delegatee || !ethers.utils.isAddress(delegatee)) {
      return {
        state: Web3CallbackState.INVALID,
        callback: null,
        error: 'Missing dependencies',
      }
    }

    const contract = vaultContract
    const methodName = 'delegate'
    const params = [delegatee]

    return {
      state: Web3CallbackState.VALID,
      callback: async function onAcceptFullAccountTransfer(): Promise<string> {
        const estimatedCall = await estimateGasAsync(contract, methodName, params)
        let successfulCall: SuccessfulContractCall
        if ('gasEstimate' in estimatedCall) {
          successfulCall = estimatedCall
        } else {
          throw estimatedCall.error
        }

        return contract[methodName](...params, {
          gasLimit: calculateGasMargin(successfulCall.gasEstimate),
          from: account,
        })
          .then((response: any) => {
            const summary = 'Delegate vARB'
            addTransaction(response, { summary })
            return response.hash
          })
          .catch((error: any) => {
            // if the user rejected the tx, pass this along
            if (error?.code === USER_ERROR_CODES.REJECTED) {
              throw new Error('transaction-rejected')
            } else {
              // otherwise, the error was unexpected, and we need to convey that
              console.error('Delegate ARB failed', error, methodName, params)
              throw new Error(`Delegate ARB failed: ${error.message}`)
            }
          })
      },
      error: null,
    }
  }, [dArb, account, chainId, delegatee, vaultContract, addTransaction])
}

export function useWrapOrUnwrapVoteEnabledArb(
  inputAmount: CurrencyAmount<Token> | undefined,
  balance: CurrencyAmount<Token> | undefined,
  isWrapping: boolean,
): { state: Web3CallbackState; callback: null | (() => Promise<string>); error: string | null } {
  const { chainId, account } = useActiveWeb3React()
  const dArb = useDArbToken()
  const arb = useArbToken()
  const vaultAddress = useIsolationModeUserVaultAddressIfCreated(dArb)
  const vaultContract = useDArbUserVaultContract(vaultAddress)
  const addTransaction = useTransactionAdder()
  const defaultAccount = useDefaultMarginAccount()
  const deadline = useTransactionDeadline()

  return useMemo(() => {
    const wrapperTrader = (Deployments.ARBIsolationModeWrapperTraderV4 as any)[chainId.toString()]?.address
    const unwrapperTrader = (Deployments.ARBIsolationModeUnwrapperTraderV4 as any)[chainId.toString()]?.address

    if (
      !dArb ||
      !arb ||
      !account ||
      !chainId ||
      !vaultContract ||
      !inputAmount ||
      !wrapperTrader ||
      !unwrapperTrader ||
      !balance
    ) {
      return {
        state: Web3CallbackState.INVALID,
        callback: null,
        error: 'Missing dependencies',
      }
    } else if (
      (isWrapping && !inputAmount.currency.equals(arb)) ||
      (!isWrapping && !inputAmount.currency.equals(dArb))
    ) {
      return {
        state: Web3CallbackState.INVALID,
        callback: null,
        error: 'Invalid token for inputAmount',
      }
    }

    const contract = vaultContract
    const methodName = isWrapping
      ? 'addCollateralAndSwapExactInputForOutput'
      : 'swapExactInputForOutputAndRemoveCollateral'

    const params = [
      isWrapping ? defaultAccount.accountNumber.toString() : '0',
      isWrapping ? '0' : defaultAccount.accountNumber.toString(),
      isWrapping ? ['7', '28'] : ['28', '7'],
      isInputValueCloseToBalance(inputAmount, balance) ? MAX_UINT_256.toString() : inputAmount.quotient.toString(),
      inputAmount.quotient.toString(),
      [
        {
          traderType: isWrapping ? GenericTraderType.IsolationModeWrapper : GenericTraderType.IsolationModeUnwrapper,
          makerAccountIndex: 0,
          trader: isWrapping ? wrapperTrader : unwrapperTrader,
          tradeData: [],
        },
      ],
      [],
      {
        deadline: deadline?.toString() ?? '0',
        balanceCheckFlag: BalanceCheckFlag.Both,
        eventType: ZapEventType.None,
      },
    ]

    return {
      state: Web3CallbackState.VALID,
      callback: async function useWrapOrUnwrapVoteEnabledArb(): Promise<string> {
        const estimatedCall = await estimateGasAsync(contract, methodName, params)
        let successfulCall: SuccessfulContractCall
        if ('gasEstimate' in estimatedCall) {
          successfulCall = estimatedCall
        } else {
          throw estimatedCall.error
        }

        return contract[methodName](...params, {
          gasLimit: calculateGasMargin(successfulCall.gasEstimate),
          from: account,
        })
          .then((response: any) => {
            const summary = isWrapping ? 'Swap ARB to vARB' : 'Swap vARB to ARB'
            addTransaction(response, { summary })
            return response.hash
          })
          .catch((error: any) => {
            // if the user rejected the tx, pass this along
            if (error?.code === USER_ERROR_CODES.REJECTED) {
              throw new Error('transaction-rejected')
            } else {
              // otherwise, the error was unexpected, and we need to convey that
              console.error('Convert vARB failed', error, methodName, params)
              throw new Error(`Convert vARB failed: ${error.message}`)
            }
          })
      },
      error: null,
    }
  }, [
    chainId,
    dArb,
    arb,
    account,
    vaultContract,
    inputAmount,
    isWrapping,
    defaultAccount.accountNumber,
    balance,
    deadline,
    addTransaction,
  ])
}

export function useCurrentArbDelegate(): string | undefined {
  const dArb = useDArbToken()
  const vaultAddress = useIsolationModeUserVaultAddressIfCreated(dArb)
  const vaultContract = useDArbUserVaultContract(vaultAddress)
  const state = useSingleCallResult(vaultContract, 'delegates', [])
  return useMemo(() => {
    return state.result?.[0]
  }, [state])
}

export function useDArbToken(): Token | undefined {
  const { chainId } = useActiveWeb3React()
  const tokenMap = useAllTokens()
  const dArbSpecialAsset = useDARBSpecialAsset()
  return useMemo(() => tokenMap[dArbSpecialAsset.chainIdToAddressMap[chainId] ?? ''], [
    chainId,
    dArbSpecialAsset,
    tokenMap,
  ])
}
