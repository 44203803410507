import { useTokenContract } from '../hooks/useContract'
import { useMultipleContractSingleData, useSingleCallResult } from '../state/multicall/hooks'
import { CurrencyAmount, Token } from '@dolomite-exchange/sdk-core'
import ERC20_ABI from '../constants/abis/erc20.json'
import { Interface } from '@ethersproject/abi'
import { useMemo } from 'react'

const ERC20_INTERFACE = new Interface(ERC20_ABI)

// returns undefined if input token is undefined, or fails to get token contract,
// or contract total supply cannot be fetched
export function useTotalSupply(token?: Token): CurrencyAmount<Token> | undefined {
  const contract = useTokenContract(token?.address, false)

  const totalSupply = useSingleCallResult(contract, 'totalSupply').result?.[0]

  return useMemo(() => {
    return token && totalSupply ? CurrencyAmount.fromRawAmount(token, totalSupply.toString()) : undefined
  }, [token, totalSupply])
}

export function useTotalSupplies(tokens: (Token | undefined)[]): (CurrencyAmount<Token> | undefined)[] {
  const tokenAddresses = useMemo(() => tokens.map(token => token?.address), [tokens])

  const callStates = useMultipleContractSingleData(tokenAddresses, ERC20_INTERFACE, 'totalSupply')

  // return token && totalSupply ? CurrencyAmount.fromRawAmount(token, totalSupply.toString()) : undefined
  return useMemo(() => {
    return callStates.map((callState, index) => {
      const token = tokens[index]
      if (callState.result?.[0]?.toString() && token) {
        return CurrencyAmount.fromRawAmount(token, callState.result[0]?.toString())
      }

      return undefined
    })
  }, [callStates, tokens])
}
