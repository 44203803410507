import { useMemo } from 'react'
import { DOLOMITE_API_SERVER_URL } from '@dolomite-exchange/zap-sdk'
import { NO_VARIABLES, useGraphqlResult } from '../state/graphql/hooks'
import { GraphqlClientType } from '../state/graphql/actions'
import { RefreshFrequency } from '../state/chain/hooks'

export const UNITED_STATE_EXPIRATION_DAYS = 28

export const UNITED_STATE_EXPIRATION_SECONDS = UNITED_STATE_EXPIRATION_DAYS * 60 * 60 * 24

export enum IpErrorType {
  BLOCKED,
  UNKNOWN,
}

interface Result {
  error: IpErrorType | undefined
  isUnitedStatesIp: boolean
}

export default function useIpGeolocation(): Result {
  const ipState = useGraphqlResult<{ countryCode: string }>(
    GraphqlClientType.Fetch,
    `${DOLOMITE_API_SERVER_URL}/ip-api`,
    NO_VARIABLES,
    RefreshFrequency.Slowest,
  )
  return useMemo(() => {
    if (ipState.error) {
      return {
        error: IpErrorType.BLOCKED,
        isUnitedStatesIp: false,
      }
    }

    return {
      error: undefined,
      isUnitedStatesIp: ipState.result?.countryCode === 'US' ?? false,
    }
  }, [ipState])
}
