import { Currency, Fraction, Trade, TradeType } from '@dolomite-exchange/v2-sdk'
import React, { useCallback, useMemo } from 'react'
import TransactionConfirmationModal, {
  ConfirmationModalContent,
  TransactionErrorContent,
} from '../TransactionConfirmationModal'
import TradeModalFooter, { MarginInformation } from './TradeModalFooter'
import TradeModalHeader from './TradeModalHeader'
import { CurrencyAmount } from '@dolomite-exchange/sdk-core'
import { FORMATTER, ONE_BIPS } from '../../constants'
import cleanCurrencySymbol from '../../utils/cleanCurrencySymbol'

/**
 * Returns true if the trade requires a confirmation of details before we can submit it
 * @param newTrade trade A
 * @param oldTrade trade B
 */
function tradeMeaningfullyDiffers(
  newTrade: Trade<Currency, Currency, TradeType>,
  oldTrade: Trade<Currency, Currency, TradeType>,
): boolean {
  return (
    newTrade.tradeType !== oldTrade.tradeType ||
    !newTrade.inputAmount.currency.equals(oldTrade.inputAmount.currency) ||
    !isWithinSmallDifference(newTrade.inputAmount, oldTrade.inputAmount) ||
    !newTrade.outputAmount.currency.equals(oldTrade.outputAmount.currency) ||
    !isWithinSmallDifference(newTrade.outputAmount, oldTrade.outputAmount)
  )
}

/**
 * @param newAmount The new trade's amount for comparison
 * @param oldAmount The old trade's amount for comparison
 * @return True if they are within 1 basis point (0.01% or 0.0001) of each other. False otherwise
 */
function isWithinSmallDifference(newAmount: CurrencyAmount<Currency>, oldAmount: CurrencyAmount<Currency>): boolean {
  const diff = newAmount.greaterThan(oldAmount) ? newAmount.subtract(oldAmount) : oldAmount.subtract(newAmount)
  return diff.divide(oldAmount).asFraction.lessThanOrEqual(ONE_BIPS)
}

export default function ConfirmTradeModal({
  title,
  trade,
  originalTrade,
  onAcceptChanges,
  allowedSlippage,
  onConfirm,
  onDismiss,
  tradeErrorMessage,
  isOpen,
  attemptingTxn,
  txHash,
  leverage,
  marginInformation,
  isWithdrawCollateral,
}: {
  title: string
  isOpen: boolean
  trade: Trade<Currency, Currency, TradeType> | undefined
  originalTrade: Trade<Currency, Currency, TradeType> | undefined
  attemptingTxn: boolean
  txHash: string | undefined
  allowedSlippage: number
  onAcceptChanges: () => void
  onConfirm: () => void
  tradeErrorMessage: string | undefined
  onDismiss: () => void
  leverage: Fraction | undefined
  isWithdrawCollateral: boolean
  marginInformation?: MarginInformation
}) {
  const showAcceptChanges = useMemo(
    () => Boolean(trade && originalTrade && tradeMeaningfullyDiffers(trade, originalTrade)),
    [originalTrade, trade],
  )

  const modalHeader = useCallback(() => {
    return trade ? (
      <TradeModalHeader
        trade={trade}
        allowedSlippage={allowedSlippage}
        showAcceptChanges={showAcceptChanges}
        onAcceptChanges={onAcceptChanges}
        marginInformation={marginInformation}
      />
    ) : null
  }, [allowedSlippage, onAcceptChanges, showAcceptChanges, trade, marginInformation])

  const modalBottom = useCallback(() => {
    return trade ? (
      <TradeModalFooter
        onConfirm={onConfirm}
        trade={trade}
        marginInformation={marginInformation}
        disabledConfirm={showAcceptChanges}
        swapErrorMessage={tradeErrorMessage}
        allowedSlippage={allowedSlippage}
        leverage={leverage}
      />
    ) : null
  }, [trade, onConfirm, marginInformation, showAcceptChanges, tradeErrorMessage, allowedSlippage, leverage])

  // text to show while loading
  const inputAmount = trade?.inputAmount.toSignificant(6, FORMATTER)
  const inputSymbol = cleanCurrencySymbol(trade?.inputAmount.currency)
  const outputAmount = trade?.outputAmount.toSignificant(6, FORMATTER)
  const outputSymbol = cleanCurrencySymbol(trade?.outputAmount.currency)
  const pendingText = isWithdrawCollateral
    ? `Withdrawing collateral from position`
    : `Swapping ${inputAmount} ${inputSymbol} for ${outputAmount} ${outputSymbol}`

  const confirmationContent = useCallback(
    () =>
      tradeErrorMessage ? (
        <TransactionErrorContent onDismiss={onDismiss} message={tradeErrorMessage} />
      ) : (
        <ConfirmationModalContent
          title={title}
          onDismiss={onDismiss}
          topContent={modalHeader}
          bottomContent={modalBottom}
        />
      ),
    [title, onDismiss, modalBottom, modalHeader, tradeErrorMessage],
  )

  return (
    <TransactionConfirmationModal
      isOpen={isOpen}
      memoizedOnDismiss={onDismiss}
      attemptingTxn={attemptingTxn}
      hash={txHash}
      memoizedContentCreator={confirmationContent}
      pendingText={pendingText}
      currencyToAdd={trade?.outputAmount.currency}
    />
  )
}
