import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { CollateralActionType, LoanActionType, ManageType } from '../types'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { useActiveWeb3React } from '../../../hooks'
import { ChainId } from '../../../constants'

const BorrowActionButtonsWrapper = styled.div<{ visible: boolean; expanded: boolean }>`
  width: 100%;
  height: ${({ visible }) => (visible ? '34px' : '0')};

  @media screen and (max-width: 615px) {
    height: ${({ visible }) => (visible ? '34x' : '0')};
  }

  @media screen and (max-width: 480px) {
    height: ${({ visible }) => (visible ? '36px' : '0')};
    width: calc(100% + 30px);
    margin-left: -15px;
  }
`

export const WarningBubble = styled.div<{ visible: boolean }>`
  border-radius: 50%;
  color: #f9f9f9;
  font-weight: 800;
  font-size: 10px;
  background-color: ${({ theme }) => theme.yellow2};
  width: 13px;
  height: 13px !important;
  line-height: 13px;
  text-align: center;
  padding-left: 0;
  display: inline-block;
  margin-left: 5px;
  margin-top: 3px;
  vertical-align: top;
  position: absolute;
  opacity: ${({ visible }) => (visible ? '1' : '0')};
  transition: opacity 0.3s ease-in-out;
`

const TabColumn = styled.div`
  width: 50%;
  display: inline-block;
`

const StyledTabs = styled(({ ...rest }) => (
  <Tabs classes={{ indicator: rest.indicator ? 'indicator' : 'none' }} {...rest} />
))`
  font-family: 'Open Sans', sans-serif !important;
  justify-content: normal !important;
  min-height: 0 !important;
  float: ${({ right }) => (right ? 'right' : 'left')};

  .indicator {
    background-color: #f9f9f9 !important;
    bottom: 0 !important;
    display: block !important;
    height: 1.4px !important;
    transform: scale(0.5, 1) !important;
  }

  .none {
    background-color: transparent;
  }
`

const StyledTab = styled(({ ...rest }) => (
  <Tab
    classes={{
      root: 'root',
      selected: rest.isSelected ? 'selected' : undefined,
    }}
    {...rest}
    selected={!!rest.isSelected}
  />
))`
  font-family: 'Open Sans', sans-serif !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  margin-left: 0 !important;
  margin-right: ${({ right }) => (right ? '0' : '13')}px !important;
  text-transform: capitalize !important;
  padding: 0 !important;
  padding-bottom: 3.5px !important;
  padding-left: 4px !important;
  padding-right: 4px !important;
  max-width: 264px;
  min-width: 0 !important;
  color: #606375 !important;
  min-height: 0 !important;

  @media screen and (max-width: 480px) {
    margin-right: ${({ right }) => (right ? '0' : '6')}px !important;
    font-size: 13px !important;
  }

  @media screen and (max-width: 400px) {
    margin-right: 0 !important;
  }

  ${({ isSelected }) => {
    return (
      isSelected &&
      `
      color: #f9f9f9 !important;
    `
    )
  }}
  .selected {
    color: #f9f9f9 !important;
  }

  .root span {
    font-size: 18px !important;
  }
`

function BorrowActionButtonsComparator(
  prevProps: BorrowActionButtonsInterface,
  nextProps: BorrowActionButtonsInterface,
) {
  return (
    prevProps.expanded === nextProps.expanded &&
    prevProps.expandedInputArea === nextProps.expandedInputArea &&
    prevProps.positionHealthWarning === nextProps.positionHealthWarning &&
    prevProps.manageType === nextProps.manageType
  )
}

interface BorrowActionButtonsInterface {
  expanded: boolean
  expandedInputArea: boolean
  positionHealthWarning: boolean
  manageType: ManageType
  setExpandedInputArea: (expanded: boolean) => void
  updateManageTypeAndSelectedInnerTab: (
    manageType: ManageType,
    selectedInnerTab: CollateralActionType | LoanActionType,
  ) => void
}

function BorrowActionButtons({
  expanded,
  expandedInputArea,
  positionHealthWarning,
  manageType,
  setExpandedInputArea,
  updateManageTypeAndSelectedInnerTab,
}: BorrowActionButtonsInterface) {
  const { t } = useTranslation()
  const { chainId } = useActiveWeb3React()
  const isInvalidChain = chainId === ChainId.X_LAYER
  const collateralOptions = useMemo(
    () => (isInvalidChain ? [t('add'), t('remove')] : [t('add'), t('remove'), t('swap')]),
    [isInvalidChain, t],
  )
  const loanOptions = useMemo(
    () => (isInvalidChain ? [t('borrow'), t('repay')] : [t('borrow'), t('repay'), t('swap')]),
    [isInvalidChain, t],
  )
  const [selectedTab, setSelectedTab] = useState<CollateralActionType | LoanActionType>(CollateralActionType.DEPOSIT)
  const updateTabs = useCallback(
    (newManageType: ManageType, newSelectedTab: number) => {
      updateManageTypeAndSelectedInnerTab(newManageType, newSelectedTab)
      setSelectedTab(newSelectedTab)
    },
    [updateManageTypeAndSelectedInnerTab],
  )

  return (
    <BorrowActionButtonsWrapper visible={expanded} expanded={expandedInputArea}>
      <TabColumn>
        <StyledTabs
          value={selectedTab}
          onChange={(_: any, index: number) => updateTabs(ManageType.COLLATERAL, index)}
          indicator={manageType === ManageType.COLLATERAL ? 'true' : undefined}
          indicatorColor={'primary'}
          textColor={'primary'}
          expanded={expanded}
          onClick={(e: any) => {
            e.stopPropagation()
            setExpandedInputArea(true)
          }}
        >
          {collateralOptions.map((option: string, index: CollateralActionType) => (
            <StyledTab
              key={`collateral_options-${index}`}
              isSelected={manageType === ManageType.COLLATERAL && selectedTab === index ? 'true' : undefined}
              disableRipple
              label={option}
            />
          ))}
        </StyledTabs>
      </TabColumn>
      <TabColumn>
        <StyledTabs
          value={selectedTab}
          onChange={(_: any, index: number) => updateTabs(ManageType.LOAN, index)}
          indicator={manageType === ManageType.LOAN ? 'true' : undefined}
          indicatorColor={'primary'}
          textColor={'primary'}
          expanded={expanded}
          right
          onClick={(e: any) => {
            e.stopPropagation()
            setExpandedInputArea(true)
          }}
        >
          {loanOptions.map((option: string, index: LoanActionType) => (
            <StyledTab
              key={`collateral_options-${index}`}
              isSelected={manageType === ManageType.LOAN && selectedTab === index ? 'true' : undefined}
              disableRipple
              label={option}
              right={index === 2}
            />
          ))}
        </StyledTabs>
      </TabColumn>
    </BorrowActionButtonsWrapper>
  )
}

export default React.memo(BorrowActionButtons, BorrowActionButtonsComparator)
