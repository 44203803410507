import { SpecialAsset, SpecialAssetSymbol } from '../special-assets'
import {
  ChainId,
  PENDLE_PT_WE_ETH_APR_2024_ADDRESSES,
  PENDLE_PT_WE_ETH_APR_2024_ISOLATION_MODE_ADDRESSES,
} from '../../index'
import { Token } from '@dolomite-exchange/v2-sdk'
import { getOptionally } from '../special-assets-util-functions'
import { calculatePendlePtWeEthApr2024ProxyVaultAddress } from '../../../utils/isolation/calculateProxyVaultAddress'
import { PENDLE_PT_DOCS_LINK } from './constants'

const decimals = 18

export const PT_WE_ETH_APR_2024_SPECIAL_ASSET: () => SpecialAsset = () => ({
  symbol: SpecialAssetSymbol.dPtWeEthApril2024,
  safeSymbol: SpecialAssetSymbol.dPtWeEthApril2024.replace('-', '_'),
  cleanSymbol: 'PT-eETH',
  cleanName: 'PT eETH 2024-04-24',
  hasExternalRewards: false,
  documentationUrl: PENDLE_PT_DOCS_LINK,
  isIsolationMode: true,
  isBorrowingEnabled: false,
  chainIdToAddressMap: PENDLE_PT_WE_ETH_APR_2024_ISOLATION_MODE_ADDRESSES,
  isolationModeInfo: {
    getWrappedToken: (chainId: ChainId) =>
      getOptionally(
        PENDLE_PT_WE_ETH_APR_2024_ISOLATION_MODE_ADDRESSES[chainId],
        address =>
          new Token(
            chainId,
            address,
            decimals,
            SpecialAssetSymbol.dPtWeEthApril2024,
            'Dolomite Isolation: PT eETH 25APR2024',
          ),
      ),
    getUnderlyingToken: getPendlePtWeEthUnderlyingToken,
    getUnderlyingSpenderToken: getPendlePtWeEthUnderlyingToken,
    allowableDebtTokens: () => [],
    allowableCollateralTokens: () => [],
    remapAccountAddress: calculatePendlePtWeEthApr2024ProxyVaultAddress,
    isAsync: false,
  },
})

function getPendlePtWeEthUnderlyingToken(chainId: ChainId): Token | undefined {
  return getOptionally(
    PENDLE_PT_WE_ETH_APR_2024_ADDRESSES[chainId],
    address => new Token(chainId, address, decimals, 'PT-eETH', 'PT eETH 25APR2024'),
  )
}
