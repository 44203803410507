import { Fraction, Token } from '@dolomite-exchange/sdk-core'
import { useJusdcChefContract } from '../useContract'
import { useMemo } from 'react'
import { useSingleCallResult } from '../../state/multicall/hooks'
import { useDjUSDCSpecialAsset } from '../../constants/isolation/special-assets'
import { useActiveWeb3React } from '../index'
import { useTokenBalance } from '../../state/wallet/hooks'
import { useDefaultFiatValuesWithLoadingIndicator } from '../useFiatValue'
import { ZERO_FRACTION } from '../../constants'

const JUSDC_POOL_ID = 1

export function useJUsdcStakedBalance(): Fraction | undefined {
  const { account, chainId } = useActiveWeb3React()
  const jUsdcFarm = useJusdcChefContract()
  const djUsdcSpecialAsset = useDjUSDCSpecialAsset()
  const proxyVault = djUsdcSpecialAsset.isolationModeInfo?.remapAccountAddress?.(account, chainId)
  const inputs = useMemo(() => [JUSDC_POOL_ID, proxyVault], [proxyVault])
  const callState = useSingleCallResult(jUsdcFarm, 'userInfo', inputs)

  return useMemo(() => {
    const balance = callState.result?.[0]?.[0]
    if (!balance) {
      return undefined
    }

    return new Fraction(balance, '1000000000000000000')
  }, [callState.result])
}

export function useJUsdcUnstakedBalance(): Fraction | undefined {
  const { account, chainId } = useActiveWeb3React()
  const djUsdc = useDjUSDCSpecialAsset()
  const jUsdc = useJUsdcToken()
  const proxyVault = djUsdc.isolationModeInfo?.remapAccountAddress?.(account, chainId)
  const balance = useTokenBalance(proxyVault, jUsdc)
  return useMemo(() => balance?.asFraction, [balance])
}

export function useJUsdcPendingRewards(): [Fraction | undefined, boolean] {
  const { account, chainId } = useActiveWeb3React()
  const jUsdcFarm = useJusdcChefContract()
  const djUsdcSpecialAsset = useDjUSDCSpecialAsset()
  const proxyVault = djUsdcSpecialAsset.isolationModeInfo?.remapAccountAddress?.(account, chainId)
  const inputs = useMemo(() => [JUSDC_POOL_ID, proxyVault], [proxyVault])
  const callState = useSingleCallResult(jUsdcFarm, 'pendingSushi', inputs)

  return useMemo(() => {
    const pendingRewards = callState.result?.[0]
    if (!pendingRewards) {
      return [undefined, callState.loading]
    }

    return [new Fraction(pendingRewards, '1000000000000000000'), callState.loading]
  }, [callState.loading, callState.result])
}

export function useTotalStakedJUsdcSupply(): Fraction | undefined {
  const chefContract = useJusdcChefContract()
  const jUsdcToken = useJUsdcToken()
  const stakedBalance = useTokenBalance(chefContract?.address, jUsdcToken)
  return useMemo(() => stakedBalance?.asFraction, [stakedBalance])
}

export function useTotalStakedJUsdcSupplyUSD(): Fraction | undefined {
  const chefContract = useJusdcChefContract()
  const jUsdcToken = useJUsdcToken()
  const stakedBalance = useTokenBalance(chefContract?.address, jUsdcToken)
  const djUsdcToken = useDjUSDCToken()
  const [fiatMap] = useDefaultFiatValuesWithLoadingIndicator(
    useMemo(() => (djUsdcToken ? [djUsdcToken] : []), [djUsdcToken]),
  )
  return useMemo(() => {
    const jUsdcPrice = fiatMap[djUsdcToken?.address ?? '']
    if (!jUsdcPrice) {
      return ZERO_FRACTION
    }
    return stakedBalance?.asFraction.multiply(jUsdcPrice)
  }, [djUsdcToken, fiatMap, stakedBalance])
}

export function useDjUSDCToken(): Token | undefined {
  const { chainId } = useActiveWeb3React()
  const djUSDC = useDjUSDCSpecialAsset()
  return useMemo(() => djUSDC.isolationModeInfo?.getWrappedToken(chainId), [chainId, djUSDC])
}

export function useJUsdcToken(): Token | undefined {
  const { chainId } = useActiveWeb3React()
  const djUsdcSpecialAsset = useDjUSDCSpecialAsset()
  return useMemo(() => djUsdcSpecialAsset.isolationModeInfo?.getUnderlyingToken(chainId), [djUsdcSpecialAsset, chainId])
}
