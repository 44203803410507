import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { ApplicationModal } from '../../state/application/actions'
import { useModalOpen, useWalletModalToggle } from '../../state/application/hooks'
import AccountDetails from '../AccountDetails'

import Modal from '../Modal'
import { useActiveWeb3React } from '../../hooks'

const Wrapper = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  margin: 0;
  padding: 0;
  width: 100%;
`

const WALLET_VIEWS = {
  OPTIONS: 'options',
  OPTIONS_SECONDARY: 'options_secondary',
  ACCOUNT: 'account',
  PENDING: 'pending',
}

export default function WalletModal({
  pendingTransactions,
  confirmedTransactions,
}: {
  pendingTransactions: string[] // hashes of pending
  confirmedTransactions: string[] // hashes of confirmed
}) {
  // important that these are destructed from the account-specific web3-react context
  const { connect } = useActiveWeb3React()

  const walletModalOpen = useModalOpen(ApplicationModal.WALLET)
  const toggleWalletModal = useWalletModalToggle()

  const openOptions = useCallback(async () => {
    toggleWalletModal()
    return connect()
      .then(() => {
        console.debug('Connected successfully!')
      })
      .catch(e => {
        console.error('Could not connect to wallet:', e)
      })
  }, [toggleWalletModal, connect])

  return (
    <Modal isOpen={walletModalOpen} onDismiss={toggleWalletModal} minHeight={false} maxHeight={90}>
      <Wrapper>
        <AccountDetails
          toggleWalletModal={toggleWalletModal}
          pendingTransactions={pendingTransactions}
          confirmedTransactions={confirmedTransactions}
          openOptions={openOptions}
        />
      </Wrapper>
    </Modal>
  )
}
