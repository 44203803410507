import { gql } from '@apollo/client'
import {
  AmmPairHourData as AmmPairHourDataGql,
  createFraction,
  createFractionUSD,
  Token,
  TokenWithId,
} from './gqlTypeHelpers'
import { ammPairHourData } from './queryObjects'
import { useMemo } from 'react'
import { Fraction } from '@dolomite-exchange/sdk-core'
import JSBI from 'jsbi'
import { Pair } from '@dolomite-exchange/v2-sdk'
import { ZERO_ADDRESS } from '../constants'
import { useGraphqlResult } from '../state/graphql/hooks'
import { GraphqlClientType } from '../state/graphql/actions'
import { RefreshFrequency, useBlockTimestamp } from '../state/chain/hooks'

const LAST_24_HOUR_PAIR_DATA_GQL = gql`
    query last24HoursOfAmmPairData($blockNumber: Int!, $pairAddress: String!, $endTimestamp: Int!, $startTimestamp: Int!) {
        ammPairHourDatas(
            orderBy: hourStartUnix
            orderDirection: desc
            block: {
                number_gte: $blockNumber
            }
            where: {
                pairAddress: $pairAddress,
                hourStartUnix_lte: $endTimestamp,
                hourStartUnix_gt: $startTimestamp
            }
        ) {
            ${ammPairHourData()}
        }
    }
`

interface AmmPairHourDataResponse {
  ammPairHourDatas: AmmPairHourDataGql[]
}

export interface AmmPairHourData {
  hourStartUnix: number
  pairAddress: string
  token0: Token | TokenWithId
  token1: Token | TokenWithId
  reserve0: Fraction
  reserve1: Fraction
  reserveUSD: Fraction
  hourlyVolumeToken0: Fraction
  hourlyVolumeToken1: Fraction
  hourlyVolumeUSD: Fraction
  hourlyTransactionCount: JSBI
}

/**
 * Returns the yesterday's (starting from the yesterday at the same as hour now) 24 hours of hourly data for the given
 * pair. For example, if the current time is 9:00 AM, the data returned will be for the 2 days ago, starting at 9:00 AM
 * and the dataset will end at 1 day ago, at 9:00 AM.
 */
export function usePreviousDayPairHourlyData(
  pair: Pair | string | undefined,
): {
  loading: boolean
  error: boolean
  data: AmmPairHourData[]
} {
  const timestamp = useBlockTimestamp()

  const startTimestamp = useMemo(() => {
    return timestamp
      .div('3600')
      .sub('48')
      .mul('3600')
      .toNumber()
  }, [timestamp])

  const endTimestamp = useMemo(() => {
    return timestamp
      .div('3600')
      .sub('24')
      .mul('3600')
      .toNumber()
  }, [timestamp])

  return usePairHourlyData(pair, startTimestamp, endTimestamp)
}

/**
 * Returns the current 24 hours of hourly data for the given pair.
 */
export function useCurrentDayPairHourlyData(
  pair: Pair | string | undefined,
): {
  loading: boolean
  error: boolean
  data: AmmPairHourData[]
} {
  const timestamp = useBlockTimestamp()

  const startTimestamp = useMemo(() => {
    return timestamp
      .div('3600')
      .sub('24')
      .mul('3600')
      .toNumber()
  }, [timestamp])

  const endTimestamp = useMemo(() => {
    return timestamp
      .div('3600')
      .mul('3600')
      .toNumber()
  }, [timestamp])

  return usePairHourlyData(pair, startTimestamp, endTimestamp)
}

/**
 * Returns the current week of hourly data for the given pair. Resulting array can be at most 168 hours long.
 */
export function usePreviousWeekPairHourlyData(
  pair: Pair | string | undefined,
): {
  loading: boolean
  error: boolean
  data: AmmPairHourData[]
} {
  const timestamp = useBlockTimestamp()

  const startTimestamp = useMemo(() => {
    return timestamp
      .div('3600')
      .sub('336')
      .mul('3600')
      .toNumber()
  }, [timestamp])

  const endTimestamp = useMemo(() => {
    return timestamp
      .div('3600')
      .sub('168')
      .mul('3600')
      .toNumber()
  }, [timestamp])

  return usePairHourlyData(pair, startTimestamp, endTimestamp)
}

/**
 * Returns the current week of hourly data for the given pair. Resulting array can be at most 168 hours long.
 */
export function useCurrentWeekPairHourlyData(
  pair: Pair | string | undefined,
): {
  loading: boolean
  error: boolean
  data: AmmPairHourData[]
} {
  const timestamp = useBlockTimestamp()

  const startTimestamp = useMemo(() => {
    return timestamp
      .div('3600')
      .sub('168')
      .mul('3600')
      .toNumber()
  }, [timestamp])

  const endTimestamp = useMemo(() => {
    return timestamp
      .div('3600')
      .mul('3600')
      .toNumber()
  }, [timestamp])

  return usePairHourlyData(pair, startTimestamp, endTimestamp)
}

function usePairHourlyData(
  pair: Pair | string | undefined,
  startTimestamp: number,
  endTimestamp: number,
): {
  loading: boolean
  error: boolean
  data: AmmPairHourData[]
} {
  const pairAddress = pair
    ? typeof pair === 'string'
      ? pair.toLowerCase()
      : pair.liquidityToken.address.toLowerCase()
    : ZERO_ADDRESS
  const variables = useMemo(() => {
    return {
      pairAddress,
      startTimestamp,
      endTimestamp,
    }
  }, [endTimestamp, pairAddress, startTimestamp])

  const queryState = useGraphqlResult<AmmPairHourDataResponse>(
    GraphqlClientType.Dolomite,
    LAST_24_HOUR_PAIR_DATA_GQL.loc!.source.body,
    variables,
    RefreshFrequency.Medium,
  )

  return useMemo(() => {
    const { loading, error, result } = queryState

    const anyLoading = Boolean(loading)
    const anyError = Boolean(error)

    const pairHourData = (result?.ammPairHourDatas ?? []).map<AmmPairHourData>(value => {
      return {
        hourStartUnix: value.hourStartUnix,
        pairAddress: value.pairAddress,
        token0: value.token0,
        token1: value.token1,
        reserve0: createFraction(value.reserve0),
        reserve1: createFraction(value.reserve1),
        reserveUSD: createFractionUSD(value.reserveUSD),
        hourlyVolumeToken0: createFraction(value.hourlyVolumeToken0),
        hourlyVolumeToken1: createFraction(value.hourlyVolumeToken1),
        hourlyVolumeUSD: createFractionUSD(value.hourlyVolumeUSD),
        hourlyTransactionCount: JSBI.BigInt(value.hourlyTransactionCount),
      }
    })

    return {
      loading: anyLoading,
      error: anyError,
      data: pairHourData,
    }
  }, [queryState])
}
