import { MarketRiskInfo as MarketRiskInfoGql } from './gqlTypeHelpers'
import { CurrencyAmount, Fraction } from '@dolomite-exchange/sdk-core'
import { parseUnits } from '@ethersproject/units'
import { useMemo } from 'react'
import { ONE_ETH_IN_WEI } from '../constants'
import JSBI from 'jsbi'
import { gql } from '@apollo/client'
import { toChecksumAddress } from '../utils/toChecksumAddress'
import { address } from '@dolomite-exchange/dolomite-margin/dist/src/types'
import { Token } from '@dolomite-exchange/v2-sdk'
import { useAllTokens } from '../hooks/Tokens'
import { reqParseAmount, tryParseAmount } from '../state/trade/hooks'
import { NO_VARIABLES, useGraphqlResult } from '../state/graphql/hooks'
import { GraphqlClientType } from '../state/graphql/actions'
import { RefreshFrequency } from '../state/chain/hooks'
import { useStoredMarketRiskData } from '../state/data/hooks'

const MARKET_RISK_INFOS_DATA_GQL = gql`
  query allMarketInfos($blockNumber: Int!) {
    marketRiskInfos(block: { number_gte: $blockNumber }) {
      id
      token {
        id
        marketId
      }
      isBorrowingDisabled
      marginPremium
      liquidationRewardPremium
      oracle
      supplyMaxWei
    }
  }
`

interface MarketRiskInfoResponse {
  marketRiskInfos: MarketRiskInfoGql[]
}

export interface MarketRiskInfo {
  marketId: JSBI
  tokenAddress: string
  isBorrowingDisabled: boolean
  marginPremium: Fraction
  liquidationRewardPremium: Fraction
  oracleAddress: string
  supplyMaxWei: CurrencyAmount<Token> | undefined
}

export function useLoadMarketRiskInfoData(): {
  loading: boolean
  error: boolean
  data: Record<address, MarketRiskInfo | undefined>
} {
  const queryState = useGraphqlResult<MarketRiskInfoResponse>(
    GraphqlClientType.Dolomite,
    MARKET_RISK_INFOS_DATA_GQL.loc!.source.body,
    NO_VARIABLES,
    RefreshFrequency.Slow,
  )
  const tokenMap = useAllTokens()

  return useMemo(() => {
    const { loading, error, result } = queryState
    const anyLoading = Boolean(loading)
    const anyError = Boolean(error)

    const toFraction = (field: string): Fraction => {
      return new Fraction(parseUnits(field, 18).toString(), ONE_ETH_IN_WEI)
    }

    const marketRiskInfos = (result?.marketRiskInfos ?? []).reduce<Record<string, MarketRiskInfo>>((memo, gql) => {
      const tokenAddress = toChecksumAddress(gql.token.id)
      const token = tokenMap[tokenAddress]
      if (token) {
        memo[tokenAddress] = {
          marketId: JSBI.BigInt(gql.token.marketId),
          tokenAddress: tokenAddress,
          isBorrowingDisabled: gql.isBorrowingDisabled,
          marginPremium: toFraction(gql.marginPremium),
          liquidationRewardPremium: toFraction(gql.liquidationRewardPremium),
          oracleAddress: toChecksumAddress(gql.oracle),
          supplyMaxWei: tryParseAmount(gql.supplyMaxWei, token),
        }
      }
      return memo
    }, {})

    return {
      loading: anyLoading,
      error: anyError,
      data: marketRiskInfos,
    }
  }, [queryState, tokenMap])
}

export function useMarketRiskInfoData(): {
  loading: boolean
  error: boolean
  data: Record<address, MarketRiskInfo | undefined>
} {
  return useStoredMarketRiskData()
}
