import { AssetDenomination, Currency, Price, Token } from '@dolomite-exchange/v2-sdk'
import { useMemo } from 'react'
import { USDC } from '../constants'
import { PairState, usePairs } from '../data/Reserves'
import { useActiveWeb3React } from '../hooks'
import JSBI from 'jsbi'
import { WETH } from '../constants'
import { BRIDGED_USDC } from '../constants/tokens/USDC'

export function useUSDCPrice(token?: Token): Price<Currency, Token> | undefined {
  const tokens = useMemo(() => (token ? [token] : undefined), [token])
  return useUSDCPrices(tokens)[token?.address ?? '']
}

/**
 * Returns the price in USDC of the input currency
 * @param tokens the tokens  to compute the USDC price of
 */
export function useUSDCPrices(tokens?: Token[]): Record<string, Price<Currency, Token> | undefined> {
  const { chainId } = useActiveWeb3React()
  const weth = useMemo(() => WETH[chainId], [chainId])
  const usdc = useMemo(() => BRIDGED_USDC[chainId] ?? USDC[chainId], [chainId])
  const tokenPairs: [Token | undefined, Token | undefined][] = useMemo(() => {
    const pairs: [Token | undefined, Token | undefined][] | undefined = tokens?.reduce<
      [Token | undefined, Token | undefined][]
    >((memo, token) => {
      if (!token.equals(weth)) {
        memo.push([token, weth])
      } else {
        memo.push([undefined, undefined])
      }
      if (!token.equals(usdc)) {
        memo.push([token, usdc])
      } else {
        memo.push([undefined, undefined])
      }
      return memo
    }, [])
    pairs?.push([weth, usdc])
    return pairs ?? []
  }, [tokens, usdc, weth])
  const pairs = usePairs(tokenPairs, AssetDenomination.Wei)

  return useMemo(() => {
    if (!tokens) {
      return {}
    }

    const [usdcEthPairState, usdcEthPair] = pairs[pairs.length - 1]

    return tokens.reduce<Record<string, Price<Currency, Token>>>((memo, token, index) => {
      const [ethPairState, ethPair] = pairs[index * 2]
      const [usdcPairState, usdcPair] = pairs[index * 2 + 1]

      // handle weth/eth
      if (token.equals(weth)) {
        if (usdcPair?.reserve0.greaterThan('0') && usdcPair.reserve1.greaterThan('0')) {
          const price = usdcPair.priceOf(weth)
          memo[token.address] = new Price(token, usdc, price.denominator, price.numerator)
        }

        return memo
      }

      // handle usdc
      if (token.equals(usdc)) {
        memo[token.address] = new Price(usdc, usdc, '1', '1')
        return memo
      }

      const ethPairETHAmount = ethPair?.reserveOf(weth)
      const ethPairETHUSDCValue: JSBI =
        ethPairETHAmount &&
        usdcEthPair &&
        usdcEthPair.reserve0.greaterThan('0') &&
        usdcEthPair.reserve1.greaterThan('0')
          ? usdcEthPair.priceOf(weth).quote(ethPairETHAmount).quotient
          : JSBI.BigInt(0)

      // all other tokens
      // first try the usdc pair
      if (usdcPairState === PairState.EXISTS && usdcPair && usdcPair.reserveOf(usdc).greaterThan(ethPairETHUSDCValue)) {
        const price = usdcPair.priceOf(token)
        memo[token.address] = new Price(token, usdc, price.denominator, price.numerator)
        return memo
      }

      if (ethPairState === PairState.EXISTS && ethPair && usdcEthPairState === PairState.EXISTS && usdcEthPair) {
        if (usdcEthPair.reserveOf(usdc).greaterThan('0') && ethPair.reserveOf(weth).greaterThan('0')) {
          const ethUsdcPrice = usdcEthPair.priceOf(usdc)
          const currencyEthPrice = ethPair.priceOf(weth)
          const usdcPrice = ethUsdcPrice.multiply(currencyEthPrice).invert()
          memo[token.address] = new Price(token, usdc, usdcPrice.denominator, usdcPrice.numerator)
          return memo
        }
      }

      return memo
    }, {})
  }, [tokens, pairs, usdc, weth])
}
