import { useMemo } from 'react'
import { useGraphqlResult } from '../state/graphql/hooks'
import { GraphqlClientType, Web3CallType } from '../state/graphql/actions'
import { RefreshFrequency } from '../state/chain/hooks'

/**
 * Does a lookup for an ENS name to find its content hash.
 */
export default function useENSAvatarList(
  addresses: (string | null)[] | undefined,
): {
  loading: boolean
  avatars: (string | null)[]
} {
  const variables = useMemo(() => ({ data: addresses ?? [] }), [addresses])
  const ensAvatarsState = useGraphqlResult<(string | null)[]>(
    GraphqlClientType.Web3,
    Web3CallType.GetEnsAvatars,
    variables,
    RefreshFrequency.Never,
  )
  return useMemo(() => {
    return {
      loading: ensAvatarsState.loading,
      avatars: ensAvatarsState.result ?? [],
    }
  }, [ensAvatarsState.loading, ensAvatarsState.result])
}
