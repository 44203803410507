import React, { useMemo } from 'react'
import { ChainId, FORMATTER } from '../../../constants'
import { StyledTooltip, StyledTooltipWithIcon } from '../../../components/common/StyledTooltip'
import { BorrowPosition as BorrowPositionData } from '../../../types/borrowPositionData'
import { ReactComponent as ShieldIcon } from '../../../assets/images/shieldIcon.svg'
import { Rounding } from '@dolomite-exchange/v2-sdk'
import AllInclusiveIcon from '@material-ui/icons/AllInclusive'
import { PositionHealthTooltip } from '../BorrowPositionRow'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { SpecialAsset } from '../../../constants/isolation/special-assets'
import { WarningBubble } from '../../Trade/OrderTypes/Positions'
import cleanCurrencySymbol from '../../../utils/cleanCurrencySymbol'
import toDate from '../../../utils/toDate'
import { formatAmount } from '../../../utils/formatAmount'
import { useShowYieldAsApr } from '../../../state/user/hooks'

const BorrowTopRowWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 42px;
`

const CollateralTitle = styled.div`
  font-size: 11px;
  font-weight: 600;
  width: fit-content;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  color: ${({ theme }) => theme.text3};
  transition: opacity 0.3s ease-in-out;

  @media screen and (max-width: 615px) {
    font-size: 10px;
  }
`

const CollateralAmount = styled.div`
  font-size: 30px;
  font-weight: 300;
  position: absolute;
  top: 10px;
  left: 0;
  transition: opacity 0.3s ease-in-out;

  @media screen and (max-width: 615px) {
    font-size: 24px;
    top: 9px;
  }
`

const Top = styled.div`
  svg {
    font-size: 13px !important;
    transform: translateY(1.5px);
  }
`

const Bottom = styled.div`
  width: fit-content;
`

const IsolationModeIndicatorLower = styled.div<{ expanded: boolean }>`
  font-size: 12px;
  font-weight: 300;
  position: absolute;
  top: 63px;
  color: ${({ theme }) => theme.text2};
  opacity: ${({ expanded }) => (expanded ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;

  svg {
    height: 12px;
    width: auto;
    margin-right: 3px;
    margin-bottom: -2px;
  }

  svg:nth-of-type(2) {
    vertical-align: top;
    margin-top: 3px;
    margin-left: 0;
  }

  @media screen and (max-width: 615px) {
    font-size: 11px;
    top: 52px;
    width: 110%;

    svg {
      height: 11px;
      width: auto;
      margin-right: 3px;
      margin-bottom: -2px;
    }
  }
`

/* Health: 3 for green (healthy), 2 for orange (risky), 1 for red (critical) */
const BorrowCollateralizationRate = styled.div<{ health: number }>`
  font-size: 24px;
  position: absolute;
  padding-top: 0;
  top: 0;
  transition: all 0.3s ease-in-out;

  ${Top} {
    width: 100%;
    color: ${({ theme }) => theme.text3};
    font-size: 11px;
    font-weight: 600;
    transition: all 0.3s ease-in-out;
  }

  ${Bottom} {
    width: 100%;
    font-size: 18px;
    transition: all 0.3s ease-in-out;
    color: ${({ theme, health }) => (health === 3 ? theme.green1 : health == 2 ? theme.yellow1 : theme.red1)};
  }
`

const TopRowLeftSide = styled.div<{ expanded?: boolean; isInIsolationMode?: boolean }>`
  width: 50%;
  text-align: left;
  position: relative;

  ${CollateralTitle} {
    opacity: 1;
  }

  ${CollateralAmount} {
    opacity: 1;
  }

  ${BorrowCollateralizationRate} {
    transform: translateY(${({ expanded }) => (expanded ? '48px' : '48px')});
    left: ${({ expanded, isInIsolationMode }) => isInIsolationMode && (expanded ? '0' : /*'32px'*/ 0)};

    ${Top} {
      color: ${({ theme }) => theme.text2};
      font-size: 12px;
      font-weight: 200;

      :after {
        content: ':';
      }
    }

    ${Bottom} {
      font-size: 12px;
      transform: translate(90px, -17px);
      font-weight: 200;
    }

    @media screen and (max-width: 615px) {
      transform: translateY(${({ expanded }) => (expanded ? '38px' : '38px')});
    }
  }
`

const BorrowedTitle = styled.div`
  font-size: 11px;
  font-weight: 600;
  width: fit-content;
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  color: ${({ theme }) => theme.text3};
  transition: opacity 0.3s ease-in-out;
  pointer-events: none;

  @media screen and (max-width: 615px) {
    font-size: 10px;
  }
`

const BorrowedAmount = styled.div`
  font-size: 30px;
  font-weight: 300;
  position: absolute;
  top: 0;
  right: 0;
  transition: top 0.3s ease-in-out;

  @media screen and (max-width: 615px) {
    font-size: 24px;
  }
`

const NetInterest = styled.div`
  font-size: 12px;
  display: inline-block;
  position: absolute;
  right: 0;
  top: 48px;
  color: ${({ theme }) => theme.text2};
  transition: opacity 0.3s ease-in-out;
  font-weight: 300;

  @media screen and (max-width: 615px) {
    font-size: 11px;
    top: 38px;
  }
`

const Expiration = styled.div<{ expanded: boolean; closeToExpiry?: boolean }>`
  font-size: 12px;
  display: inline-block;
  position: absolute;
  right: 0;
  top: ${({ expanded }) => (expanded ? 62 : 48)}px;
  color: ${({ theme, closeToExpiry }) => (closeToExpiry ? theme.red1 : theme.text2)};
  transition: top 0.3s ease-in-out;
  font-weight: 300;

  @media screen and (max-width: 615px) {
    font-size: 11px;
    top: ${({ expanded }) => (expanded ? 52 : 38)}px;
  }
`

const FormattedWarningBubble = styled(WarningBubble)`
  right: 7px;
  top: 6px;
  left: auto;
`

const TopRowRightSide = styled.div<{ expanded?: boolean }>`
  width: 50%;
  text-align: right;
  position: relative;

  ${BorrowedTitle} {
    opacity: 1;
    pointer-events: all;
  }

  ${BorrowedAmount} {
      /*transform: translateY(${({ expanded }) => (expanded ? '10px' : '0')});*/
    top: 10px;
  }

  ${NetInterest} {
    opacity: ${({ expanded }) => (expanded ? '1' : '0')};
  }

  @media screen and (max-width: 615px) {
    ${BorrowedAmount} {
      transform: translateY(${({ expanded }) => (expanded ? '-1px' : '0')});
    }
  }
`

const InfinityWrapper = styled.div<{ expanded: boolean }>`
  width: 14px;

  svg {
    transition: all 0.3s ease-in-out;
    width: 0.8em;
    height: 0.8em;
  }
`

function BorrowTopRowComparator(prevProps: BorrowTopRowInterface, nextProps: BorrowTopRowInterface) {
  return (
    prevProps.expanded === nextProps.expanded &&
    prevProps.isInIsolationMode === nextProps.isInIsolationMode &&
    prevProps.position === nextProps.position &&
    prevProps.chainId === nextProps.chainId &&
    prevProps.specialAsset?.symbol === nextProps.specialAsset?.symbol &&
    prevProps.positionHealthFactor === nextProps.positionHealthFactor
  )
}

interface BorrowTopRowInterface {
  expanded: boolean
  isInIsolationMode?: boolean
  position: BorrowPositionData
  chainId: ChainId
  specialAsset?: SpecialAsset
  positionHealthFactor: 1 | 2 | 3
}

const THREE_DAYS_MILLIS = 1_000 * 86_400 * 3

function BorrowTopRow({
  expanded,
  isInIsolationMode,
  position,
  chainId,
  specialAsset,
  positionHealthFactor,
}: BorrowTopRowInterface) {
  const { t } = useTranslation()
  const [showYieldAsApr] = useShowYieldAsApr()

  const showExpirationWarning = position.expirationTimestamp
    ? position.expirationTimestamp.getTime() - Date.now() < THREE_DAYS_MILLIS
    : false
  const specialAssetSymbol = useMemo(() => {
    return specialAsset?.isolationModeInfo?.getUnderlyingToken
      ? cleanCurrencySymbol(specialAsset.isolationModeInfo.getUnderlyingToken(chainId))
      : undefined
  }, [specialAsset, chainId])

  return (
    <BorrowTopRowWrapper>
      {showExpirationWarning && (
        <StyledTooltip title={'Position is close to expiration'} placement={'top'}>
          <FormattedWarningBubble>!</FormattedWarningBubble>
        </StyledTooltip>
      )}
      <TopRowLeftSide expanded={expanded} isInIsolationMode={isInIsolationMode}>
        <CollateralTitle>{t('collateral')}</CollateralTitle>
        <CollateralAmount>${position.totalSupplyUSD.toFixed(2, FORMATTER)}</CollateralAmount>
        {/*isInIsolationMode &&
         specialAsset?.isolationModeInfo?.getUnderlyingToken &&
         specialAsset?.isolationModeInfo?.getUnderlyingToken(chainId) && (
         <StyledTooltip
         title={`This borrow position is in isolation mode for the asset ${specialAssetSymbol}. This means that you can use ${specialAssetSymbol} as collateral for this borrow position. Other isolation mode assets cannot be used in this borrow position.`}
         placement={'top'}
         >
         <IsolationModeIndicator expanded={expanded}>
         <ShieldIcon />
         <img
         src={getLogoOrDefault(specialAssetSymbol || '')}
         alt={`Isolation mode for ${specialAssetSymbol}`}
         />
         </IsolationModeIndicator>
         </StyledTooltip>
         )*/}
        <BorrowCollateralizationRate health={positionHealthFactor}>
          <Top onClick={e => e.stopPropagation()}>
            {t('positionHealth')}
            {/*!expanded && <StyledTooltipWithIcon tooltipText={t('positionHealthTooltip')} placement={'top'} />*/}
          </Top>
          <Bottom onClick={e => e.stopPropagation()}>
            <StyledTooltip
              title={
                <PositionHealthTooltip
                  positionHealth={
                    position.positionHealth
                      ? parseFloat(position.positionHealth.toFixed(2, undefined, Rounding.ROUND_DOWN))
                      : undefined
                  }
                  positionHealthFactor={positionHealthFactor}
                  t={t}
                  isNew={false}
                />
              }
              placement='top'
              arrow={true}
            >
              <span>
                {!position.positionHealth ? (
                  <InfinityWrapper expanded={expanded}>
                    <AllInclusiveIcon />
                  </InfinityWrapper>
                ) : position.positionHealth.greaterThan('10') ? (
                  '>10'
                ) : (
                  position.positionHealth.toFixed(2, undefined, Rounding.ROUND_DOWN)
                )}
              </span>
            </StyledTooltip>
          </Bottom>
        </BorrowCollateralizationRate>
        {isInIsolationMode && (
          <IsolationModeIndicatorLower expanded={true}>
            Isolation Mode <ShieldIcon />
            {specialAssetSymbol}{' '}
            <StyledTooltipWithIcon
              tooltipText={`This borrow position is in isolation mode for the asset ${specialAssetSymbol}. This means that you can use ${specialAssetSymbol} as collateral for this borrow position. Other isolation mode assets cannot be used in this borrow position.`}
            />
          </IsolationModeIndicatorLower>
        )}
      </TopRowLeftSide>
      <TopRowRightSide expanded={expanded}>
        <BorrowedTitle>{t('borrowing')}</BorrowedTitle>
        <BorrowedAmount>${position.totalBorrowUSD.toFixed(2, FORMATTER)}</BorrowedAmount>
        <NetInterest>
          {t('netInterest')}
          {` `}
          {position.netInterestRate.greaterThanOrEqual('0') ? '+' : ''}
          {formatAmount(position.netInterestRate)} {showYieldAsApr ? t('APR') : t('APY')}
        </NetInterest>
        {!!position.expirationTimestamp && (
          <StyledTooltip title={`Expiration: ${position.expirationTimestamp.toString()}`} placement={'right'}>
            <Expiration expanded={expanded} closeToExpiry={showExpirationWarning}>
              {t('expiration', { expiration: toDate(position.expirationTimestamp) })}
            </Expiration>
          </StyledTooltip>
        )}
      </TopRowRightSide>
    </BorrowTopRowWrapper>
  )
}

export default React.memo(BorrowTopRow, BorrowTopRowComparator)
