import { wrappedCurrency } from 'utils/wrappedCurrency'
import { Currency } from '@dolomite-exchange/v2-sdk'
import { useCallback, useMemo, useState } from 'react'
import { useActiveWeb3React } from 'hooks'
import { getSpecialAsset } from '../constants/isolation/special-assets'
import { Web3Provider } from '@ethersproject/providers'
import getLogoOrDefault from '../components/common/TokenLogos'
import cleanCurrencySymbol from '../utils/cleanCurrencySymbol'

export default function useAddTokenToMetamask(
  currencyToAdd: Currency | undefined,
): { addToken: () => void; success: boolean | undefined } {
  const { library, chainId } = useActiveWeb3React()

  const tokenToAdd = useMemo(() => {
    const token = wrappedCurrency(currencyToAdd, chainId)
    const specialAssetUnderlying = getSpecialAsset(chainId, token)?.isolationModeInfo?.getUnderlyingToken(chainId)
    if (specialAssetUnderlying) {
      return specialAssetUnderlying
    } else {
      return token
    }
  }, [chainId, currencyToAdd])

  const [success, setSuccess] = useState<boolean | undefined>()

  const addToken = useCallback(() => {
    if (library instanceof Web3Provider && library.provider.isMetaMask && library.provider.request && tokenToAdd) {
      library.provider
        .request({
          method: 'wallet_watchAsset',
          params: {
            // @ts-expect-error
            type: 'ERC20',
            options: {
              address: tokenToAdd.address,
              symbol: tokenToAdd.symbol,
              decimals: tokenToAdd.decimals,
              image: getLogoOrDefault(cleanCurrencySymbol(tokenToAdd) ?? ''),
            },
          },
        })
        .then(success => {
          setSuccess(success)
        })
        .catch(() => setSuccess(false))
    } else {
      setSuccess(false)
    }
  }, [library, tokenToAdd])

  return {
    addToken,
    success,
  }
}
