import React from 'react'
import styled from 'styled-components/macro'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import StakePLVGLP from './StakePLVGLP'
import ClaimPLVGLPRewards from './ClaimPLVGLPRewards'
import { usePlvGlpPendingRewards } from '../../../../hooks/plutus/usePlutusProtocol'
import { ClaimRewardsIcon, RewardsButton } from '../GLP/GLPBottomSection'

// Removing this breaks the other styled components and the styles no longer apply. Couldn't figure out why, so I'm just leaving this in here
// const TestDiv = styled.div`
//   font-size: 20px;
// `

export const AssetRewardsWrapper = styled.div`
  padding: 7px 35px 30px;
  width: 100%;
  overflow: auto;
`

export const AssetRewardsBody = styled.div`
  font-size: 13px;
  font-weight: 500;
`

const RewardsButtonsWrapper = styled.div`
  @media screen and (max-width: 532px) {
    text-align: center;
  }
`

export default function PLVGLPBottomSection({
  setPopoverComponent,
  setCustomPopoverWidth,
}: {
  setPopoverComponent?: (component: JSX.Element) => void
  setCustomPopoverWidth?: (width: number) => void
}) {
  const [plvRewards] = usePlvGlpPendingRewards()
  return (
    <RewardsButtonsWrapper>
      <RewardsButton
        onClick={e => {
          e.stopPropagation()
          setPopoverComponent?.(<StakePLVGLP />)
        }}
        supplemental
      >
        Staking
      </RewardsButton>
      <RewardsButton
        onClick={e => {
          e.stopPropagation()
          setPopoverComponent?.(<ClaimPLVGLPRewards />)
        }}
      >
        Claim Rewards <AutoAwesomeIcon />
        {plvRewards?.greaterThan(0) && <ClaimRewardsIcon />}
      </RewardsButton>
    </RewardsButtonsWrapper>
  )
}
