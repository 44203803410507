import styled from 'styled-components/macro'
import { ButtonError } from '../Button'

export const TopWrapper = styled.div<{ isCompleted: boolean }>`
  margin-top: 20px;
  margin-bottom: ${({ isCompleted }) => (isCompleted ? 0 : -20)}px;
  transition: margin-bottom 0.3s ease-in-out;
`

export const TokenLogo = styled.div`
  display: inline-block;
  margin-right: 12px;
  vertical-align: top;
  width: 26px;
  padding-right: 8%;

  img {
    width: 100%;
  }
`

export const SubmitButton = styled(ButtonError)`
  background-color: #494a60;

  :hover {
    background-color: #4b4c64;
  }

  :focus {
    background-color: #494a60;
  }

  :active {
    background-color: #232330;
  }

  > div {
    line-height: 30px;
  }
`

export const UnlockIcon = styled.div`
  font-size: 80px;
  margin: 10px auto -20px;
`

export const MarketPrice = styled.div`
  cursor: pointer;
  color: ${({ theme }) => theme.text1};
  font-size: 13px;
`

export const IsolationDescription = styled.div`
  font-size: 12px;
  font-weight: 100;
  pointer-events: auto;
  color: ${({ theme }) => theme.text2};
  width: 100%;
  background: #2f2f40;
  border-radius: 5px;

  div > svg {
    margin-right: 4px;
    margin-bottom: -1px;
    font-size: 30px;
    color: ${({ theme }) => theme.text3};
  }

  a {
    color: ${({ theme }) => theme.text2};
    font-weight: 600;
    margin-left: 3px;
  }

  div {
    display: inline-block;
  }

  div:nth-child(1) {
    vertical-align: top;
    margin-top: 10px;
  }

  div:nth-child(2) {
    width: calc(100% - 34px);
  }
`

export const UnlockStep = styled.div<{ expanded: boolean }>`
  display: block;
  height: ${({ expanded }) => (expanded ? 75 : 42)}px;
  transition: height 0.3s ease-in-out;
`

export const UnlockProgress = styled.div`
  display: inline-block;
  vertical-align: top;
  position: relative;
  height: 100%;
`

export const Circle = styled.div<{ complete: boolean }>`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid ${({ complete, theme }) => (complete ? theme.green2 : theme.white)};
  background-color: ${({ complete, theme }) => (complete ? theme.green2 : 'none')};
  font-size: 16px;
  font-weight: 600;
  padding-left: ${({ complete }) => (complete ? 1 : 8.5)}px;
  padding-top: ${({ complete }) => (complete ? 1.4 : 2)}px;
`

export const Line = styled.div<{ expanded: boolean; complete: boolean }>`
  width: 2px;
  background-color: ${({ complete, theme }) => (complete ? theme.green2 : theme.white)};
  transition: background-color 0.3s ease-in-out;
  margin-left: 14px;
  height: calc(100% - 30px);
`

export const UnlockText = styled.div`
  display: inline-block;
  width: calc(100% - 50px);
  margin-left: 18px;
`

export const Title = styled.div`
  font-size: 21px;
`

export const Subtitle = styled.div<{ visible: boolean }>`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.text3};
  margin-top: 4px;
  padding-right: 8%;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
`

export const Complete = styled.div<{ visible: boolean }>`
  font-size: 15px;
  color: ${({ theme }) => theme.green2};
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
`

export const LoadingWrapper = styled.div`
  display: inline-block;
  vertical-align: top;
  height: 30px;

  > svg,
  > div {
    color: ${({ theme }) => theme.text1} !important;
    font-size: 30px;
    height: 30px !important;
    width: 30px !important;
  }
`
