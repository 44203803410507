import { Fraction, Token } from '@dolomite-exchange/sdk-core'
import { usePlutusChefContract } from '../useContract'
import { useMemo } from 'react'
import { useSingleCallResult } from '../../state/multicall/hooks'
import { useDplvGLPSpecialAsset } from '../../constants/isolation/special-assets'
import { useActiveWeb3React } from '../index'
import { useTokenBalance } from '../../state/wallet/hooks'
import { useDefaultFiatValuesWithLoadingIndicator } from '../useFiatValue'
import { ZERO_FRACTION } from '../../constants'

export function usePlsPerSecond(): [Fraction | undefined, boolean] {
  const plvGlpFarm = usePlutusChefContract()
  const callState = useSingleCallResult(plvGlpFarm, 'plsPerSecond', undefined)

  return useMemo(() => {
    const plsPerSecond = callState.result?.[0]
    if (!plsPerSecond) {
      return [undefined, callState.loading]
    }

    return [new Fraction(plsPerSecond, '1000000000000000000'), callState.loading]
  }, [callState.loading, callState.result])
}

export function usePlvGlpStakedBalance(): Fraction | undefined {
  const { account, chainId } = useActiveWeb3React()
  const plvGlpFarm = usePlutusChefContract()
  const dplvGlp = useDplvGLPSpecialAsset()
  const proxyVault = dplvGlp.isolationModeInfo?.remapAccountAddress?.(account, chainId)
  const inputs = useMemo(() => [proxyVault], [proxyVault])
  const callState = useSingleCallResult(plvGlpFarm, 'userInfo', inputs)

  return useMemo(() => {
    const balance = callState.result?.[0]
    if (!balance) {
      return undefined
    }

    return new Fraction(balance, '1000000000000000000')
  }, [callState.result])
}

export function usePlvGlpUnstakedBalance(): Fraction | undefined {
  const { account, chainId } = useActiveWeb3React()
  const dplvGlp = useDplvGLPSpecialAsset()
  const plvGlp = usePlvGlpToken()
  const proxyVault = dplvGlp.isolationModeInfo?.remapAccountAddress?.(account, chainId)
  const balance = useTokenBalance(proxyVault, plvGlp)
  return useMemo(() => balance?.asFraction, [balance])
}

export function usePlvGlpPendingRewards(): [Fraction | undefined, boolean] {
  const { account, chainId } = useActiveWeb3React()
  const plvGlpFarm = usePlutusChefContract()
  const dplvGlp = useDplvGLPSpecialAsset()
  const proxyVault = dplvGlp.isolationModeInfo?.remapAccountAddress?.(account, chainId)
  const inputs = useMemo(() => [proxyVault], [proxyVault])
  const callState = useSingleCallResult(plvGlpFarm, 'pendingRewards', inputs)

  return useMemo(() => {
    const pendingRewards = callState.result?.[0]
    if (!pendingRewards) {
      return [undefined, callState.loading]
    }

    return [new Fraction(pendingRewards, '1000000000000000000'), callState.loading]
  }, [callState.loading, callState.result])
}

export function useTotalStakedPlvGlpSupply(): Fraction | undefined {
  const chefContract = usePlutusChefContract()
  const plvGlpToken = usePlvGlpToken()
  const stakedBalance = useTokenBalance(chefContract?.address, plvGlpToken)
  return useMemo(() => stakedBalance?.asFraction, [stakedBalance])
}

export function useTotalStakedPlvGlpSupplyUSD(): Fraction | undefined {
  const chefContract = usePlutusChefContract()
  const plvGlpToken = usePlvGlpToken()
  const stakedBalance = useTokenBalance(chefContract?.address, plvGlpToken)
  const dplvGlpToken = useDplvGlpToken()
  const [fiatMap] = useDefaultFiatValuesWithLoadingIndicator(
    useMemo(() => (dplvGlpToken ? [dplvGlpToken] : []), [dplvGlpToken]),
  )
  return useMemo(() => {
    const plvGlpPrice = fiatMap[dplvGlpToken?.address ?? '']
    if (!plvGlpPrice) {
      return ZERO_FRACTION
    }
    return stakedBalance?.asFraction.multiply(plvGlpPrice)
  }, [dplvGlpToken, fiatMap, stakedBalance])
}

export function useDplvGlpToken(): Token | undefined {
  const { chainId } = useActiveWeb3React()
  const dplvGlp = useDplvGLPSpecialAsset()
  return useMemo(() => dplvGlp.isolationModeInfo?.getWrappedToken(chainId), [dplvGlp, chainId])
}

export function usePlvGlpToken(): Token | undefined {
  const { chainId } = useActiveWeb3React()
  const dplvGlp = useDplvGLPSpecialAsset()
  return useMemo(() => dplvGlp.isolationModeInfo?.getUnderlyingToken(chainId), [dplvGlp, chainId])
}
