import styled from 'styled-components/macro'
import Tooltip from '@material-ui/core/Tooltip'
import React, { useRef, useState } from 'react'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import HelpOutlinedIcon from '@material-ui/icons/HelpOutline'

export const StyledTooltip = styled(props => {
  const node = useRef<HTMLDivElement>()
  const [showTooltip, setShowTooltip] = useState(false)
  useOnClickOutside(node, () => setShowTooltip(false))
  if (props.title === undefined) {
    return <>{props.children}</>
  }
  return (
    <Tooltip
      open={!props.hideTooltip && showTooltip}
      onOpen={() => setShowTooltip(true)}
      onClose={() => setShowTooltip(false)}
      onClick={() => setShowTooltip(!showTooltip)}
      leaveTouchDelay={5000}
      classes={{ popper: props.className, arrow: props.className }}
      {...props}
      ref={node as any}
    >
      {props.children}
    </Tooltip>
  )
})`
  margin-bottom: -1px !important;

  & .MuiTooltip-tooltip {
    font-family: 'Open Sans', sans-serif !important;
    font-size: 11px;
    background-color: #3d3e54 !important;
    /*box-shadow: 0 0 1px rgba(0, 0, 0, 0.01), 0 4px 8px rgba(0, 0, 0, 0.04), 0 16px 24px rgba(0, 0, 0, 0.04),
    0 24px 32px rgba(0, 0, 0, 0.01);*/
    box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
  }

  & .MuiTooltip-arrow {
    &::before {
      border: 1px solid #3d3e54 !important;
    }

    color: #3d3e54 !important;
  }
`

const StyledInfoIcon = styled(HelpOutlinedIcon)`
  font-family: 'Open Sans', sans-serif !important;
  font-size: 15px !important;
  font-weight: 300 !important;
  color: #606375 !important;
  cursor: pointer !important;
  margin-left: 3px;
  margin-bottom: -1px !important;
`

export const StyledTooltipWithIcon = ({
  tooltipText,
  placement,
  verticalAlign,
}: {
  tooltipText: string | undefined
  placement?: string
  verticalAlign?: string
}) => {
  return (
    <StyledTooltip
      title={tooltipText}
      placement={placement ?? 'top'}
      style={verticalAlign && { verticalAlign: verticalAlign }}
    >
      <StyledInfoIcon />
    </StyledTooltip>
  )
}
