import { isTradeBetter } from 'utils/trades'
import { AssetDenomination, Currency, CurrencyAmount, Pair, Token, Trade } from '@dolomite-exchange/v2-sdk'
import flatMap from 'lodash.flatmap'
import { useMemo } from 'react'

import { BASES_TO_TRACK_LIQUIDITY_FOR, BETTER_TRADE_LESS_HOPS_THRESHOLD, CUSTOM_BASES } from '../constants'
import { PairState, usePairs } from '../data/Reserves'
import { wrappedCurrency } from '../utils/wrappedCurrency'

import { useActiveWeb3React } from './index'
import { useTrackedTokenPairs, useUserSingleHopOnly } from 'state/user/hooks'
import { TradeType } from '@dolomite-exchange/sdk-core'
import invariant from 'tiny-invariant'

function useAllCommonPairs(currencyA?: Currency, currencyB?: Currency, denomination?: AssetDenomination): Pair[] {
  const { chainId } = useActiveWeb3React()

  const bases = useMemo(() => BASES_TO_TRACK_LIQUIDITY_FOR[chainId] ?? [], [chainId])

  const [tokenA, tokenB] = useMemo(
    () =>
      chainId ? [wrappedCurrency(currencyA, chainId), wrappedCurrency(currencyB, chainId)] : [undefined, undefined],
    [chainId, currencyA, currencyB],
  )

  const basePairs: [Token, Token][] = useMemo(
    () =>
      flatMap(bases, (base): [Token, Token][] => bases.map(otherBase => [base, otherBase])).filter(
        ([t0, t1]) => t0.address !== t1.address,
      ),
    [bases],
  )

  const allPairCombinations: [Token, Token][] = useMemo(
    () =>
      tokenA && tokenB
        ? [
            // the direct pair
            [tokenA, tokenB],
            // token A against all bases
            ...bases.map((base): [Token, Token] => [tokenA, base]),
            // token B against all bases
            ...bases.map((base): [Token, Token] => [tokenB, base]),
            // each base against all bases
            ...basePairs,
          ]
            .filter((tokens): tokens is [Token, Token] => Boolean(tokens[0] && tokens[1]))
            .filter(([t0, t1]) => t0.address !== t1.address)
            .filter(([tokenA, tokenB]) => {
              if (!chainId) return true
              const customBases = CUSTOM_BASES[chainId]
              if (!customBases) return true

              const customBasesA: Token[] | undefined = customBases[tokenA.address]
              const customBasesB: Token[] | undefined = customBases[tokenB.address]

              if (!customBasesA && !customBasesB) return true

              if (customBasesA && !customBasesA.find(base => tokenB.equals(base))) return false
              // noinspection RedundantIfStatementJS
              if (customBasesB && !customBasesB.find(base => tokenA.equals(base))) return false

              return true
            })
        : [],
    [tokenA, tokenB, bases, basePairs, chainId],
  )

  const allPairs = usePairs(allPairCombinations, denomination)

  // only pass along valid pairs, non-duplicated pairs
  return useMemo(
    () =>
      Object.values(
        allPairs
          // filter out invalid pairs
          .filter((result): result is [PairState.EXISTS, Pair] => Boolean(result[0] === PairState.EXISTS && result[1]))
          // filter out duplicated pairs
          .reduce<Record<string, Pair>>((memo, [, curr]) => {
            memo[curr.liquidityToken.address] = memo[curr.liquidityToken.address] ?? curr
            return memo
          }, {}),
      ),
    [allPairs],
  )
}

const MAX_HOPS = 2

/**
 * Returns the best trade for the exact amount of tokens in to the given token out
 */
export function useTradeExactIn(
  currencyAmountIn?: CurrencyAmount<Currency>,
  currencyOut?: Currency,
  denomination?: AssetDenomination,
): Trade<Currency, Currency, TradeType> | null {
  const allowedPairs = useAllCommonPairs(currencyAmountIn?.currency, currencyOut, denomination)

  const [singleHopOnly] = useUserSingleHopOnly()

  return useMemo(() => {
    if (currencyAmountIn?.equalTo('0')) {
      return null
    }

    if (currencyAmountIn && currencyOut && allowedPairs.length > 0) {
      if (singleHopOnly) {
        return (
          Trade.bestTradeExactIn(allowedPairs, currencyAmountIn, currencyOut, {
            maxHops: 1,
            maxNumResults: 1,
          })[0] ?? null
        )
      }
      // search through trades with varying hops, find best trade out of them
      let bestTradeSoFar: Trade<Currency, Currency, TradeType> | null = null
      for (let i = 1; i <= MAX_HOPS; i++) {
        const currentTrade: Trade<Currency, Currency, TradeType> | null =
          Trade.bestTradeExactIn(allowedPairs, currencyAmountIn, currencyOut, {
            maxHops: i,
            maxNumResults: 1,
          })[0] ?? null
        // if current trade is best yet, save it
        if (isTradeBetter(bestTradeSoFar, currentTrade, BETTER_TRADE_LESS_HOPS_THRESHOLD)) {
          bestTradeSoFar = currentTrade
        }
      }
      return bestTradeSoFar
    }

    return null
  }, [allowedPairs, currencyAmountIn, currencyOut, singleHopOnly])
}

/**
 * Returns the best trade for the token in to the exact amount of token out
 */
export function useTradeExactOut(
  currencyIn?: Currency,
  currencyAmountOut?: CurrencyAmount<Currency>,
  denomination?: AssetDenomination,
): Trade<Currency, Currency, TradeType> | undefined {
  const allowedPairs = useAllCommonPairs(currencyIn, currencyAmountOut?.currency, denomination)

  const [singleHopOnly] = useUserSingleHopOnly()

  return useMemo(() => getBestTradeExactOut(currencyIn, currencyAmountOut, allowedPairs, singleHopOnly), [
    currencyIn,
    currencyAmountOut,
    allowedPairs,
    singleHopOnly,
  ])
}

export function useTradesExactOut(
  currenciesIn: (Currency | undefined)[],
  currencyAmountsOut: (CurrencyAmount<Currency> | undefined)[],
  denomination?: AssetDenomination,
): (Trade<Currency, Currency, TradeType> | undefined)[] {
  invariant(currenciesIn.length === currencyAmountsOut.length, 'LENGTH')

  const tokenPairs = useTrackedTokenPairs()
  const allPairs = usePairs(tokenPairs, denomination)
  const allowedPairs = useMemo(() => {
    return allPairs
      .filter(([state, pair]) => {
        return state === PairState.EXISTS && pair
      })
      .map(([, pair]) => pair!)
  }, [allPairs])

  const [singleHopOnly] = useUserSingleHopOnly()

  return useMemo(() => {
    return currenciesIn.map((currencyIn, i) =>
      getBestTradeExactOut(currencyIn, currencyAmountsOut[i], allowedPairs, singleHopOnly),
    )
  }, [currenciesIn, currencyAmountsOut, allowedPairs, singleHopOnly])
}

function getBestTradeExactOut(
  currencyIn: Currency | undefined,
  currencyAmountOut: CurrencyAmount<Currency> | undefined,
  allowedPairs: Pair[],
  singleHopOnly: boolean,
): Trade<Currency, Currency, TradeType> | undefined {
  if (currencyIn && currencyAmountOut?.greaterThan('0') && allowedPairs.length > 0) {
    if (singleHopOnly) {
      return (
        Trade.bestTradeExactOut(allowedPairs, currencyIn, currencyAmountOut, {
          maxHops: 1,
          maxNumResults: 1,
        })[0] ?? null
      )
    }
    // search through trades with varying hops, find best trade out of them
    let bestTradeSoFar: Trade<Currency, Currency, TradeType> | undefined = undefined
    for (let i = 1; i <= MAX_HOPS; i++) {
      const currentTrade =
        Trade.bestTradeExactOut(allowedPairs, currencyIn, currencyAmountOut, {
          maxHops: i,
          maxNumResults: 1,
        })[0] ?? null
      if (isTradeBetter(bestTradeSoFar, currentTrade, BETTER_TRADE_LESS_HOPS_THRESHOLD)) {
        bestTradeSoFar = currentTrade
      }
    }
    return bestTradeSoFar
  }
  return undefined
}
