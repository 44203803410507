import { SpecialAsset, SpecialAssetSymbol } from '../special-assets'
import mapChainIdMapValues from '../../../utils/mapChainIdMapValues'
import { USDM } from 'constants/tokens/USDM'

export const USDM_SPECIAL_ASSET: () => SpecialAsset = () => ({
  symbol: SpecialAssetSymbol.USDM,
  safeSymbol: SpecialAssetSymbol.USDM,
  cleanSymbol: 'USDM',
  cleanName: 'Mountain USD',
  hasExternalRewards: true,
  documentationUrl: undefined,
  isIsolationMode: false,
  isBorrowingEnabled: true,
  chainIdToAddressMap: mapChainIdMapValues(USDM, t => t?.address),
  isolationModeInfo: undefined,
})
