import { MAGIC_GLP_ADDRESSES } from '../../index'
import { getFunctionalOutsideAprCallData } from '../special-assets-util-functions'
import { SpecialAsset, SpecialAssetSymbol } from '../special-assets'

export const MAGIC_GLP_SPECIAL_ASSET: () => SpecialAsset = () => ({
  symbol: SpecialAssetSymbol.magicGLP,
  safeSymbol: SpecialAssetSymbol.magicGLP,
  cleanSymbol: 'mGLP',
  cleanName: 'magicGLP',
  hasExternalRewards: false,
  documentationUrl: 'https://docs.dolomite.io/integrations/abracadabra-magicglp',
  isIsolationMode: false,
  isBorrowingEnabled: false,
  chainIdToAddressMap: MAGIC_GLP_ADDRESSES,
  isolationModeInfo: undefined,
  rewardInfo: {
    getOutsideAprCallData: chainId => getFunctionalOutsideAprCallData(chainId, SpecialAssetSymbol.magicGLP),
  },
})
