import { RS_ETH_ADDRESSES } from '../../index'
import { SpecialAsset, SpecialAssetSymbol } from '../special-assets'

export const RS_ETH_SPECIAL_ASSET: () => SpecialAsset = () => ({
  symbol: SpecialAssetSymbol.rsETH,
  safeSymbol: SpecialAssetSymbol.rsETH,
  cleanSymbol: 'rsETH',
  cleanName: 'KelpDao Restaked ETH',
  hasExternalRewards: false,
  documentationUrl: undefined,
  isIsolationMode: false,
  isBorrowingEnabled: true,
  chainIdToAddressMap: RS_ETH_ADDRESSES,
  isolationModeInfo: undefined,
})
