import { CurrencyAmount, Token } from '@dolomite-exchange/v2-sdk'
import { Web3CallbackState } from '../useTradeCallback'
import { estimateGasAsync, SuccessfulContractCall, useActiveWeb3React } from '../index'
import useIsolationModeUserVaultAddressIfCreated from '../useIsolationModeUserVaultAddressIfCreated'
import { useDWmntUserVaultContract } from '../useContract'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { useMemo } from 'react'
import { USER_ERROR_CODES, ZERO_FRACTION } from '../../constants'
import { calculateGasMargin } from '../../utils'
import { useDwmntToken } from './useWmntProtocol'

enum StakeOrUnstakeWmntMethodName {
  STAKE_WMNT = 'stake',
  UNSTAKE_WMNT = 'unstake',
}

export function useStakeWmnt(
  parsedInputAmount: CurrencyAmount<Token> | undefined,
): { state: Web3CallbackState; callback: null | (() => Promise<string>); error: string | null } {
  return useStakeOrUnstakeWmnt(parsedInputAmount, StakeOrUnstakeWmntMethodName.STAKE_WMNT)
}

export function useUnstakeWmnt(
  parsedInputAmount: CurrencyAmount<Token> | undefined,
): { state: Web3CallbackState; callback: null | (() => Promise<string>); error: string | null } {
  return useStakeOrUnstakeWmnt(parsedInputAmount, StakeOrUnstakeWmntMethodName.UNSTAKE_WMNT)
}

function useStakeOrUnstakeWmnt(
  parsedInputAmount: CurrencyAmount<Token> | undefined,
  methodName: StakeOrUnstakeWmntMethodName,
): { state: Web3CallbackState; callback: null | (() => Promise<string>); error: string | null } {
  const { account } = useActiveWeb3React()
  const dWmnt = useDwmntToken()
  const vaultAddress = useIsolationModeUserVaultAddressIfCreated(dWmnt)
  const vaultContract = useDWmntUserVaultContract(vaultAddress)
  const addTransaction = useTransactionAdder()

  return useMemo(() => {
    if (!vaultContract || !dWmnt || !account || !parsedInputAmount || parsedInputAmount.equalTo(ZERO_FRACTION)) {
      return {
        state: Web3CallbackState.INVALID,
        callback: null,
        error: 'Missing dependencies',
      }
    }

    const contract = vaultContract
    const params = [parsedInputAmount.quotient.toString()]

    return {
      state: Web3CallbackState.VALID,
      callback: async function onCallFunctions(): Promise<string> {
        const estimatedCall = await estimateGasAsync(contract, methodName, params)
        let successfulCall: SuccessfulContractCall
        if ('gasEstimate' in estimatedCall) {
          successfulCall = estimatedCall
        } else {
          throw estimatedCall.error
        }

        return contract[methodName](...params, {
          gasLimit: calculateGasMargin(successfulCall.gasEstimate),
          from: account,
        })
          .then((response: any) => {
            let summary = ''
            if (methodName === StakeOrUnstakeWmntMethodName.STAKE_WMNT) {
              summary = 'Stake MNT'
            } else if (methodName === StakeOrUnstakeWmntMethodName.UNSTAKE_WMNT) {
              summary = 'Unstake MNT'
            }
            addTransaction(response, { summary })
            return response.hash
          })
          .catch((error: any) => {
            // if the user rejected the tx, pass this along
            if (error?.code === USER_ERROR_CODES.REJECTED) {
              throw new Error('transaction-rejected')
            } else {
              // otherwise, the error was unexpected, and we need to convey that
              console.error('Call function failed:', error, methodName, params)
              throw new Error(`Call function failed: ${error.message}`)
            }
          })
      },
      error: null,
    }
  }, [vaultContract, dWmnt, account, parsedInputAmount, methodName, addTransaction])
}
