import { Token } from '@dolomite-exchange/v2-sdk'
import { getSpecialAsset, SpecialAsset } from '../../constants/isolation/special-assets'
import { ChainId } from '../../constants'

export enum CollateralActionType {
  DEPOSIT,
  WITHDRAW,
  SWAP,
}

export enum LoanActionType {
  BORROW,
  REPAY,
  SWAP,
}

export enum ManageType {
  COLLATERAL,
  LOAN,
}

const EMPTY_LIST: Token[] = []

export function getTokenListFromType(
  manageType: ManageType | undefined,
  selectedTab: CollateralActionType | LoanActionType,
  specialAsset: SpecialAsset | undefined,
  tokenList: Token[],
  borrowTokens: Token[],
  supplyTokens: Token[],
  chainId: ChainId,
  isInputField: boolean,
  isZapActivated: boolean,
  selectedInputToken: Token | undefined, // only used if isZapActivated is true
  selectedOutputToken: Token | undefined, // only used if isZapActivated is true
): Token[] {
  if (!isZapActivated && !isInputField) {
    return EMPTY_LIST
  }

  const wrappedToken = specialAsset?.isolationModeInfo?.getWrappedToken(chainId)
  let allowableTokens: Token[] | undefined = undefined
  if (isZapActivated && wrappedToken) {
    if (manageType === ManageType.COLLATERAL && selectedTab === CollateralActionType.SWAP && !isInputField) {
      // We can only swap to the isolation mode asset IF the input token is allowable
      const list = specialAsset?.isolationModeInfo?.allowableCollateralTokens(chainId) ?? tokenList
      const isValidInputToken =
        specialAsset?.isolationModeInfo
          ?.allowableInputTokensForZap?.(chainId)
          ?.some(t => selectedInputToken && t.equals(selectedInputToken)) ?? true
      allowableTokens = list.filter(t => !t.equals(wrappedToken) || isValidInputToken)
    } else {
      if (isInputField) {
        allowableTokens = selectedOutputToken?.equals(wrappedToken)
          ? specialAsset?.isolationModeInfo?.allowableInputTokensForZap?.(chainId)
          : undefined
      } else {
        allowableTokens = selectedInputToken?.equals(wrappedToken)
          ? specialAsset?.isolationModeInfo?.allowableOutputTokensForZap?.(chainId)
          : undefined
      }
    }
  }

  if (allowableTokens && allowableTokens.length > 0) {
    tokenList = allowableTokens
  }

  if (manageType === ManageType.COLLATERAL) {
    if (selectedTab === CollateralActionType.DEPOSIT) {
      const isolationModeInfo = specialAsset?.isolationModeInfo
      if (isZapActivated) {
        const isolationModeToken = isolationModeInfo?.getWrappedToken(chainId)
        if (isInputField) {
          // User can zap anything from their Dolomite Balances that matches the isolation mode level because it's
          // being converted
          return tokenList.filter(token => {
            const specialToken = getSpecialAsset(chainId, token)
            if (isolationModeToken && specialToken?.isIsolationMode) {
              // If it's an isolation mode token, only allow the selected asset
              return token.equals(isolationModeToken)
            } else {
              return true //!specialToken?.isIsolationMode
            }
          })
        }
      }

      const allowableCollateralTokens = isolationModeInfo?.allowableCollateralTokens(chainId)
      if (allowableCollateralTokens && allowableCollateralTokens.length > 0 && allowableTokens !== tokenList) {
        tokenList = allowableCollateralTokens
      }

      return tokenList.filter(token => {
        // if the token is not being borrowed, it can be deposited
        return !borrowTokens.some(borrowToken => borrowToken.equals(token))
      })
    } else if (selectedTab === CollateralActionType.SWAP) {
      if (!isInputField) {
        // The user can swap to any asset that isn't in the allowed list, since it's being converted
        const allowableCollateralTokens = specialAsset?.isolationModeInfo?.allowableCollateralTokens(chainId)
        if (allowableCollateralTokens && allowableCollateralTokens.length > 0 && allowableTokens !== tokenList) {
          tokenList = allowableCollateralTokens
        }
        return tokenList.filter(token => {
          // if the token is not being borrowed, it can be deposited
          return !borrowTokens.some(borrowToken => borrowToken.equals(token)) && !selectedInputToken?.equals(token)
        })
      } else {
        // any supplied asset can be swapped
        return supplyTokens
      }
    } else {
      // CollateralActionType.WITHDRAW
      if (isZapActivated && !isInputField) {
        // The user can withdraw to any asset that isn't in the allowed list, since it's being converted
        const isolationModeInfo = specialAsset?.isolationModeInfo
        const isolationModeToken = isolationModeInfo?.getWrappedToken(chainId)
        if (isolationModeToken) {
          return tokenList.filter(token => {
            const specialToken = getSpecialAsset(chainId, token)
            if (isolationModeToken && specialToken?.isIsolationMode) {
              // If it's an isolation mode token, only allow the selected asset
              return token.equals(isolationModeToken)
            } else {
              return true // !specialToken?.isIsolationMode
            }
          })
        }
        return tokenList
      }
      // any supplied asset can be withdrawn
      return supplyTokens
    }
  } else {
    if (selectedTab === LoanActionType.BORROW) {
      if (isInputField) {
        const allowableDebtTokens = specialAsset?.isolationModeInfo?.allowableDebtTokens(chainId)
        if (allowableDebtTokens && allowableDebtTokens.length > 0 && allowableTokens !== tokenList) {
          return allowableDebtTokens.filter(token => {
            // if the token is not being supplied, it can be borrowed
            return !supplyTokens.some(supplyToken => supplyToken.equals(token))
          })
        } else if (allowableTokens && allowableTokens.length > 0) {
          return allowableTokens.filter(token => {
            // if the token is not being supplied, it can be borrowed
            return !supplyTokens.some(supplyToken => supplyToken.equals(token))
          })
        } else {
          return tokenList.filter(token => !supplyTokens.some(supplyToken => supplyToken.equals(token)))
        }
      } else {
        const isolationModeInfo = specialAsset?.isolationModeInfo
        const allowableCollateralTokens = isolationModeInfo?.allowableCollateralTokens(chainId)
        if (allowableCollateralTokens && allowableCollateralTokens.length > 0 && allowableTokens !== tokenList) {
          // there are no restrictions on what can be supplied
          return allowableCollateralTokens.filter(token => {
            // if the token is not being borrowed, it can be supplied
            return !borrowTokens.some(borrowToken => borrowToken.equals(token))
          })
        } else if (allowableCollateralTokens && allowableCollateralTokens.length > 0) {
          return allowableCollateralTokens
        } else {
          return tokenList.filter(token => {
            // if the token is not being borrowed, it can be supplied
            return !borrowTokens.some(borrowToken => borrowToken.equals(token))
          })
        }
      }
    } else if (selectedTab === LoanActionType.REPAY) {
      // repayment
      if (isZapActivated) {
        if (isInputField) {
          return supplyTokens
        } else {
          return borrowTokens.filter(b => !allowableTokens || allowableTokens.some(t => t.equals(b)))
        }
      } else {
        if (isInputField) {
          return borrowTokens
        } else {
          return supplyTokens
        }
      }
    } else {
      // SWAP
      if (isInputField) {
        const isolationModeInfo = specialAsset?.isolationModeInfo
        const allowableTokens = isolationModeInfo?.allowableDebtTokens(chainId)?.filter(token => {
          return !supplyTokens.some(supplyToken => supplyToken.equals(token))
        })
        if (!allowableTokens || allowableTokens.length === 0) {
          // there are no restrictions on what can be supplied
          return tokenList.filter(token => {
            // if the token is not being borrowed, it can be deposited
            return !supplyTokens.some(borrowToken => borrowToken.equals(token))
          })
        }
        return allowableTokens
      } else {
        return borrowTokens
      }
    }
  }
}
