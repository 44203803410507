import { Contract, ContractInterface } from '@ethersproject/contracts'
import { getAddress } from '@ethersproject/address'
import { AddressZero } from '@ethersproject/constants'
import { BaseProvider, JsonRpcSigner, Web3Provider } from '@ethersproject/providers'
import { BigNumber } from '@ethersproject/bignumber'
import { abi as DOLOMITE_AMM_ROUTER_PROXY_ABI } from '../constants/abis/dolomite-amm-router-proxy.json'
import { ChainId, DOLOMITE_AMM_ROUTER_ADDRESSES } from '../constants'
import JSBI from 'jsbi'
import { Currency, CurrencyAmount, Percent, Token } from '@dolomite-exchange/sdk-core'
import { isArbitrum, isBase, isBerachain, isMantle, isPolygon, isXLayer } from '../constants/chainId'

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value?: string | null): string | false {
  try {
    return value ? getAddress(value) : false
  } catch {
    return false
  }
}

const ETHERSCAN_PREFIXES: { [chainId in number]: string } = {
  1: '',
  3: 'ropsten.',
  4: 'rinkeby.',
  5: 'goerli.',
  42: 'kovan.',
  [ChainId.ARBITRUM_ONE]: '',
  [ChainId.BASE]: '',
  [ChainId.BERACHAIN]: 'bartio.',
  [ChainId.MANTLE]: '',
  [ChainId.POLYGON_ZKEVM]: 'zkevm.',
  [ChainId.X_LAYER]: '',
}

const ETHERSCAN_POSTFIXES: { [chainId in number]: string } = {
  1: 'etherscan.io',
  3: 'etherscan.io',
  4: 'etherscan.io',
  5: 'etherscan.io',
  42: 'etherscan.io',
  44: 'polygonscan.com',
  [ChainId.ARBITRUM_ONE]: 'arbiscan.io',
  [ChainId.BASE]: 'basescan.org',
  [ChainId.BERACHAIN]: 'beratrail.io',
  [ChainId.MANTLE]: 'mantlescan.xyz',
  [ChainId.POLYGON_ZKEVM]: 'polygonscan.com',
  [ChainId.X_LAYER]: 'www.oklink.com/xlayer',
}

export function getBlockExplorerName(chainId: number): string {
  if (isArbitrum(chainId)) {
    return 'Arbiscan'
  } else if (isBase(chainId)) {
    return 'Basescan'
  } else if (isBerachain(chainId)) {
    return 'Beratrails'
  } else if (isMantle(chainId)) {
    return 'Mantlescan'
  } else if (isPolygon(chainId)) {
    return 'Polygonscan'
  } else if (isXLayer(chainId)) {
    return 'OKLINK'
  } else {
    return 'Etherscan'
  }
}

export function getEtherscanLink(
  chainId: ChainId,
  data: string,
  type: 'transaction' | 'token' | 'address' | 'block',
): string {
  const prefix = ETHERSCAN_PREFIXES[chainId] || ETHERSCAN_PREFIXES[1]
  const link = `https://${prefix}${ETHERSCAN_POSTFIXES[chainId] ?? 'etherscan.io'}`

  switch (type) {
    case 'transaction': {
      return `${link}/tx/${data}`
    }
    case 'token': {
      return `${link}/token/${data}`
    }
    case 'block': {
      return `${link}/block/${data}`
    }
    case 'address':
    default: {
      return `${link}/address/${data}`
    }
  }
}

// shorten the checksummed version of the input address to have 0x + 4 characters at start and end
export function shortenAddress(address: string, chars = 4): string {
  const parsed = isAddress(address)
  if (!parsed) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }
  return `${parsed.substring(0, chars + 2)}...${parsed.substring(42 - chars)}`
}

export function shortenTextIfNecessary(text: string | undefined, chars = 4): string | undefined {
  if (!text) {
    return undefined
  }

  if (text.length <= chars * 2 + 5) {
    return text
  }
  return `${text.substring(0, chars + 2)}...${text.substring(text.length - chars)}`
}

// add 10%
export function calculateGasMargin(value: BigNumber): BigNumber {
  return value.mul(BigNumber.from(10000).add(BigNumber.from(1000))).div(BigNumber.from(10000))
}

// converts a basis points value to a sdk percent
export function basisPointsToPercent(num: number): Percent {
  return new Percent(JSBI.BigInt(num), JSBI.BigInt(10000))
}

export function calculateSlippageAmount(value: CurrencyAmount<Currency>, slippage: number): [JSBI, JSBI] {
  if (slippage < 0 || slippage > 10000) {
    throw Error(`Unexpected slippage value: ${slippage}`)
  }
  return [
    JSBI.divide(JSBI.multiply(value.quotient, JSBI.BigInt(10000 - slippage)), JSBI.BigInt(10000)),
    JSBI.divide(JSBI.multiply(value.quotient, JSBI.BigInt(10000 + slippage)), JSBI.BigInt(10000)),
  ]
}

// account is not optional
export function getSigner(library: Web3Provider, account: string): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked()
}

// account is optional
export function getProviderOrSigner(
  library: BaseProvider | Web3Provider,
  account?: string,
): BaseProvider | JsonRpcSigner {
  return account && library instanceof Web3Provider ? getSigner(library, account) : library
}

// account is optional
export function getContract(
  address: string,
  ABI: ContractInterface,
  library: BaseProvider | Web3Provider,
  account?: string,
): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }

  return new Contract(address, ABI, getProviderOrSigner(library, account) as any)
}

// account is optional
export function getRouterContract(
  chainId: ChainId,
  library: BaseProvider | Web3Provider,
  account?: string,
): Contract | null {
  const routerAddress = DOLOMITE_AMM_ROUTER_ADDRESSES[chainId]
  if (!routerAddress) {
    return null
  }

  return getContract(routerAddress, DOLOMITE_AMM_ROUTER_PROXY_ABI, library, account)
}

export function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

export function getPartial(
  amount: CurrencyAmount<Token>,
  numerator: CurrencyAmount<Token>,
  denominator: CurrencyAmount<Token>,
): CurrencyAmount<Token> {
  if (denominator.equalTo('0')) {
    return CurrencyAmount.fromRawAmount(amount.currency, '0')
  }

  const result = amount.multiply(numerator.asFraction.divide(denominator.asFraction))
  return CurrencyAmount.fromFractionalAmount(amount.currency, result.numerator, result.denominator)
}
export { mapExternalTokenToListedAddress } from './externalTokens'
