import { Fraction, Percent } from '@dolomite-exchange/sdk-core'
import { ZERO_PERCENT } from '../constants'

export default function calculatePercentageChange(original: Fraction, next: Fraction): Percent {
  if (original.equalTo('0')) {
    return ZERO_PERCENT
  }

  return Percent.fromFraction(next.subtract(original).divide(original))
}
