import { useActiveWeb3React } from '../index'
import { useMemo } from 'react'
import {
  ES_GMX_ADDRESSES,
  GLP_VESTER_ADDRESSES,
  GMX_ADDRESSES,
  GMX_VESTER_ADDRESSES,
  ONE_ETH_IN_WEI,
  ZERO_ADDRESS,
} from '../../constants'
import {
  useBnGmxContract,
  useBnGmxTrackerContract,
  useEsGmxContract,
  useExtendedGmxFeeTrackerContract,
  useFeeGlpTrackerContract,
  useFeeGmxTrackerContract,
  useGlpContract,
  useGlpManagerContract,
  useGlpVesterContract,
  useGmxContract,
  useGmxReaderV2Contract,
  useGmxRewardReaderContract,
  useGmxRewardRouterContract,
  useGmxVesterContract,
  useStakedGlpTrackerContract,
  useStakedGmxTrackerContract,
} from '../useContract'
import { useSingleCallResult } from '../../state/multicall/hooks'
import {
  getBalanceAndSupplyData,
  getDepositBalanceData,
  getStakingData,
  getVestingData,
  GmxBalanceAndSupplyData,
  GmxDepositDataKeys,
  GmxStakingInfoData,
  GmxStakingInfoDataKeys,
  GmxVestingData,
  GmxVestingDataKeys,
  PLACEHOLDER_ACCOUNT,
} from '../../utils/processGmxAndGlpData'
import { Fraction, Token } from '@dolomite-exchange/sdk-core'
import { useTokenBalances } from '../../state/wallet/hooks'
import useSWR from 'swr'
import { getServerUrl } from '../../utils/glpBackend'
import { Contract } from '@ethersproject/contracts'
import { toChecksumAddress } from '../../utils/toChecksumAddress'
import { useDefaultFiatValuesWithLoadingIndicator } from '../useFiatValue'
import fetchWithTimeout from '../../utils/fetchWithTimeout'

export interface GmxAums {
  bidAum: Fraction
  askAum: Fraction
}

export function useGmxToken(): Token | undefined {
  const { chainId } = useActiveWeb3React()
  return useMemo(() => {
    const gmxAddress = GMX_ADDRESSES[chainId]
    if (!gmxAddress) {
      return undefined
    }
    return new Token(chainId, gmxAddress, 18, 'GMX', 'GMX')
  }, [chainId])
}

export function useEsGmxToken(): Token | undefined {
  const { chainId } = useActiveWeb3React()
  return useMemo(() => {
    const esGmxAddress = ES_GMX_ADDRESSES[chainId]
    if (!esGmxAddress) {
      return undefined
    }
    return new Token(chainId, esGmxAddress, 18, 'esGMX', 'Escrowed GMX')
  }, [chainId])
}

export function useVGmxToken(): Token | undefined {
  const { chainId } = useActiveWeb3React()
  return useMemo(() => {
    const vGmxAddress = GMX_VESTER_ADDRESSES[chainId]
    if (!vGmxAddress) {
      return undefined
    }
    return new Token(chainId, vGmxAddress, 18, 'vGMX', 'Vested GMX')
  }, [chainId])
}

export function useVGlpToken(): Token | undefined {
  const { chainId } = useActiveWeb3React()
  return useMemo(() => {
    const vGlpAddress = GLP_VESTER_ADDRESSES[chainId]
    if (!vGlpAddress) {
      return undefined
    }
    return new Token(chainId, vGlpAddress, 18, 'vGLP', 'Vested GLP')
  }, [chainId])
}

export function useGlpAum(): [GmxAums | undefined, boolean] {
  const glpManagerContract = useGlpManagerContract()
  const callState = useSingleCallResult(glpManagerContract, 'getAums', undefined)

  return useMemo(() => {
    const tuple = callState.result?.[0]
    if (!tuple) {
      return [undefined, callState.loading]
    }

    return [
      {
        bidAum: new Fraction(tuple[1], '1000000000000000000000000000000'),
        askAum: new Fraction(tuple[0], '1000000000000000000000000000000'),
      },
      callState.loading,
    ]
  }, [callState.loading, callState.result])
}

export function useGmxStakingInfos(
  account?: string,
): [Record<GmxStakingInfoDataKeys, GmxStakingInfoData | undefined> | undefined, boolean] {
  const gmxRewardReader = useGmxRewardReaderContract()
  const stakedGmxTrackerContract = useStakedGmxTrackerContract()
  const bonusGmxTrackerContract = useBnGmxTrackerContract()
  const feeGmxTrackerContract = useFeeGmxTrackerContract()
  const stakedGlpTrackerContract = useStakedGlpTrackerContract()
  const feeGlpTrackerContract = useFeeGlpTrackerContract()
  const extendedGmxFeeTrackerContract = useExtendedGmxFeeTrackerContract()
  const [contract, inputs] = useMemo(() => {
    if (
      !stakedGmxTrackerContract ||
      !bonusGmxTrackerContract ||
      !feeGmxTrackerContract ||
      !stakedGlpTrackerContract ||
      !feeGlpTrackerContract ||
      !extendedGmxFeeTrackerContract
    ) {
      // don't want to do anything if the args will be malformed
      return [undefined, undefined]
    }
    const rewardTrackersForStakingInfo = [
      stakedGmxTrackerContract.address,
      bonusGmxTrackerContract.address,
      feeGmxTrackerContract.address,
      stakedGlpTrackerContract.address,
      feeGlpTrackerContract.address,
      extendedGmxFeeTrackerContract.address,
    ]
    return [gmxRewardReader, [account ?? PLACEHOLDER_ACCOUNT, rewardTrackersForStakingInfo]]
  }, [
    gmxRewardReader,
    account,
    bonusGmxTrackerContract,
    feeGlpTrackerContract,
    feeGmxTrackerContract,
    stakedGlpTrackerContract,
    stakedGmxTrackerContract,
    extendedGmxFeeTrackerContract,
  ])

  const callState = useSingleCallResult(contract, 'getStakingInfo', inputs)

  return useMemo(() => {
    const values = callState.result?.[0]
    if (!values) {
      return [undefined, callState.loading]
    }

    return [
      getStakingData(values.map((value: any) => new Fraction(value.toString(), ONE_ETH_IN_WEI))),
      callState.loading,
    ]
  }, [callState.loading, callState.result])
}

export function useGmxDepositedInStakedTracker(account?: string): [Fraction | undefined, boolean] {
  const stakedGmxTrackerContract = useStakedGmxTrackerContract()
  const gmxToken = useGmxToken()
  const [contract, inputs] = useMemo(() => {
    if (!stakedGmxTrackerContract || !account || !gmxToken) {
      // don't want to do anything if the args will be malformed
      return [undefined, undefined]
    }
    return [stakedGmxTrackerContract, [account, gmxToken.address]]
  }, [account, gmxToken, stakedGmxTrackerContract])

  const callState = useSingleCallResult(contract, 'depositBalances', inputs)

  return useMemo(() => {
    const value = callState.result?.[0]
    if (!value) {
      return [undefined, callState.loading]
    }

    return [new Fraction(value.toString(), ONE_ETH_IN_WEI), callState.loading]
  }, [callState.loading, callState.result])
}

export function useStakedGmxCumulativeRewards(account?: string): [Fraction | undefined, boolean] {
  const stakedGmxTrackerContract = useStakedGmxTrackerContract()
  return useStakedTrackerCumulativeRewards(stakedGmxTrackerContract, account)
}

export function useStakedGlpCumulativeRewards(account?: string): [Fraction | undefined, boolean] {
  const stakedGlpTrackerContract = useStakedGlpTrackerContract()
  return useStakedTrackerCumulativeRewards(stakedGlpTrackerContract, account)
}

function useStakedTrackerCumulativeRewards(
  trackerContract: Contract | null,
  account: string | undefined,
): [Fraction | undefined, boolean] {
  const [contract, inputs] = useMemo(() => {
    if (!trackerContract || !account) {
      // don't want to do anything if the args will be malformed
      return [undefined, undefined]
    }
    return [trackerContract, [account ?? PLACEHOLDER_ACCOUNT]]
  }, [account, trackerContract])

  const callState = useSingleCallResult(contract, 'cumulativeRewards', inputs)

  return useMemo(() => {
    const value = callState.result?.[0]
    if (!value) {
      return [undefined, callState.loading]
    }

    return [new Fraction(value.toString(), ONE_ETH_IN_WEI), callState.loading]
  }, [callState.loading, callState.result])
}

export function useStakedGmxAverageStakedAmount(account?: string): [Fraction | undefined, boolean] {
  const stakedGmxTrackerContract = useStakedGmxTrackerContract()
  return useStakedTrackerAverageStakedAmount(stakedGmxTrackerContract, account)
}

export function useStakedGlpAverageStakedAmount(account?: string): [Fraction | undefined, boolean] {
  const stakedGlpTrackerContract = useStakedGlpTrackerContract()
  return useStakedTrackerAverageStakedAmount(stakedGlpTrackerContract, account)
}

function useStakedTrackerAverageStakedAmount(
  trackerContract: Contract | null,
  account: string | undefined,
): [Fraction | undefined, boolean] {
  const [contract, inputs] = useMemo(() => {
    if (!trackerContract || !account) {
      // don't want to do anything if the args will be malformed
      return [undefined, undefined]
    }
    return [trackerContract, [account ?? PLACEHOLDER_ACCOUNT]]
  }, [account, trackerContract])

  const callState = useSingleCallResult(contract, 'averageStakedAmounts', inputs)

  return useMemo(() => {
    const value = callState.result?.[0]
    if (!value) {
      return [undefined, callState.loading]
    }

    return [new Fraction(value.toString(), ONE_ETH_IN_WEI), callState.loading]
  }, [callState.loading, callState.result])
}

export function useFeeGmxCumulativeRewards(account?: string): [Fraction | undefined, boolean] {
  const stakedGmxTrackerContract = useStakedGmxTrackerContract()
  return useFeeTrackerCumulativeRewards(stakedGmxTrackerContract, account)
}

export function useFeeGlpCumulativeRewards(account?: string): [Fraction | undefined, boolean] {
  const stakedGlpTrackerContract = useStakedGlpTrackerContract()
  return useFeeTrackerCumulativeRewards(stakedGlpTrackerContract, account)
}

function useFeeTrackerCumulativeRewards(
  trackerContract: Contract | null,
  account: string | undefined,
): [Fraction | undefined, boolean] {
  const [contract, inputs] = useMemo(() => {
    if (!trackerContract || !account) {
      // don't want to do anything if the args will be malformed
      return [undefined, undefined]
    }
    return [trackerContract, [account ?? PLACEHOLDER_ACCOUNT]]
  }, [account, trackerContract])

  const callState = useSingleCallResult(contract, 'cumulativeRewards', inputs)

  return useMemo(() => {
    const value = callState.result?.[0]
    if (!value) {
      return [undefined, callState.loading]
    }

    return [new Fraction(value.toString(), ONE_ETH_IN_WEI), callState.loading]
  }, [callState.loading, callState.result])
}

export function useFeeGmxAverageStakedAmount(account?: string): [Fraction | undefined, boolean] {
  const stakedGmxTrackerContract = useFeeGmxTrackerContract()
  return useFeeTrackerAverageStakedAmount(stakedGmxTrackerContract, account)
}

export function useFeeGlpAverageStakedAmount(account?: string): [Fraction | undefined, boolean] {
  const stakedGlpTrackerContract = useFeeGlpTrackerContract()
  return useFeeTrackerAverageStakedAmount(stakedGlpTrackerContract, account)
}

function useFeeTrackerAverageStakedAmount(
  trackerContract: Contract | null,
  account: string | undefined,
): [Fraction | undefined, boolean] {
  const [contract, inputs] = useMemo(() => {
    if (!trackerContract || !account) {
      // don't want to do anything if the args will be malformed
      return [undefined, undefined]
    }
    return [trackerContract, [account ?? PLACEHOLDER_ACCOUNT]]
  }, [account, trackerContract])

  const callState = useSingleCallResult(contract, 'averageStakedAmounts', inputs)

  return useMemo(() => {
    const value = callState.result?.[0]
    if (!value) {
      return [undefined, callState.loading]
    }

    return [new Fraction(value.toString(), ONE_ETH_IN_WEI), callState.loading]
  }, [callState.loading, callState.result])
}

export function useVestedGmxTransferredCumulativeRewards(account?: string): [Fraction | undefined, boolean] {
  const gmxVesterContract = useGmxVesterContract()
  return useTransferredCumulativeRewards(gmxVesterContract, account)
}

export function useVestedGlpTransferredCumulativeRewards(account?: string): [Fraction | undefined, boolean] {
  const glpVesterContract = useGlpVesterContract()
  return useTransferredCumulativeRewards(glpVesterContract, account)
}

function useTransferredCumulativeRewards(
  vesterContract: Contract | null,
  account: string | undefined,
): [Fraction | undefined, boolean] {
  const [contract, inputs] = useMemo(() => {
    if (!vesterContract || !account) {
      // don't want to do anything if the args will be malformed
      return [undefined, undefined]
    }
    return [vesterContract, [account ?? PLACEHOLDER_ACCOUNT]]
  }, [account, vesterContract])

  const callState = useSingleCallResult(contract, 'transferredCumulativeRewards', inputs)

  return useMemo(() => {
    const value = callState.result?.[0]
    if (!value) {
      return [undefined, callState.loading]
    }

    return [new Fraction(value.toString(), ONE_ETH_IN_WEI), callState.loading]
  }, [callState.loading, callState.result])
}

export function useTransferAccountPendingReceivers(account?: string): [string | undefined, boolean] {
  const rewardRouterContract = useGmxRewardRouterContract()
  const [contract, inputs] = useMemo(() => {
    if (!rewardRouterContract || !account) {
      // don't want to do anything if the args will be malformed
      return [undefined, undefined]
    }
    return [rewardRouterContract, [account]]
  }, [account, rewardRouterContract])

  const callState = useSingleCallResult(contract, 'pendingReceivers', inputs)

  return useMemo(() => {
    const value = callState.result?.[0]
    if (!value || value === ZERO_ADDRESS) {
      return [undefined, callState.loading]
    }

    return [toChecksumAddress(value), callState.loading]
  }, [callState.loading, callState.result])
}

export function useGmxBalanceWithSupplies(account?: string): [GmxBalanceAndSupplyData | undefined, boolean] {
  const readerContract = useGmxReaderV2Contract()
  const gmxContract = useGmxContract()
  const esGmxContract = useEsGmxContract()
  const glpContract = useGlpContract()
  const stakedGmxTrackerContract = useStakedGmxTrackerContract()
  const inputs = useMemo(() => {
    if (!gmxContract || !esGmxContract || !glpContract || !stakedGmxTrackerContract) {
      return [account ?? PLACEHOLDER_ACCOUNT, []]
    }
    return [
      account ?? PLACEHOLDER_ACCOUNT,
      [gmxContract.address, esGmxContract.address, glpContract.address, stakedGmxTrackerContract.address],
    ]
  }, [account, esGmxContract, glpContract, gmxContract, stakedGmxTrackerContract])

  const callState = useSingleCallResult(readerContract, 'getTokenBalancesWithSupplies', inputs)

  return useMemo(() => {
    const tuple = callState.result?.[0]
    if (!tuple) {
      return [undefined, callState.loading]
    }

    return [getBalanceAndSupplyData(tuple.map((value: any) => new Fraction(value, ONE_ETH_IN_WEI))), callState.loading]
  }, [callState.loading, callState.result])
}

export function useGmxVestingInfo(
  account?: string,
): [Record<GmxVestingDataKeys, GmxVestingData | undefined> | undefined, boolean] {
  const readerContract = useGmxReaderV2Contract()
  const gmxVesterContract = useGmxVesterContract()
  const glpVesterContract = useGlpVesterContract()
  const inputs = useMemo(() => {
    if (!gmxVesterContract || !glpVesterContract) {
      return [account ?? PLACEHOLDER_ACCOUNT, []]
    }
    return [account ?? PLACEHOLDER_ACCOUNT, [gmxVesterContract.address, glpVesterContract.address]]
  }, [account, gmxVesterContract, glpVesterContract])

  const callState = useSingleCallResult(readerContract, 'getVestingInfo', inputs)

  return useMemo(() => {
    const tuple = callState.result?.[0]
    if (!tuple) {
      return [undefined, callState.loading]
    }

    return [getVestingData(tuple.map((value: any) => new Fraction(value, ONE_ETH_IN_WEI))), callState.loading]
  }, [callState.loading, callState.result])
}

export function useGmxDepositInfo(
  account?: string,
): [Record<GmxDepositDataKeys, Fraction | undefined> | undefined, boolean] {
  const readerContract = useGmxRewardReaderContract()
  const gmxContract = useGmxContract()
  const esGmxContract = useEsGmxContract()
  const stakedGmxTrackerContract = useStakedGmxTrackerContract()
  const bnGmxTrackerContract = useBnGmxTrackerContract()
  const bnGmxContract = useBnGmxContract()
  const glpContract = useGlpContract()
  const feeGmxTrackerContract = useFeeGmxTrackerContract()
  const feeGlpTrackerContract = useFeeGlpTrackerContract()
  const inputs = useMemo(() => {
    if (
      !gmxContract ||
      !esGmxContract ||
      !stakedGmxTrackerContract ||
      !bnGmxTrackerContract ||
      !bnGmxContract ||
      !glpContract ||
      !feeGmxTrackerContract ||
      !feeGlpTrackerContract
    ) {
      return [account ?? PLACEHOLDER_ACCOUNT, [], []]
    }
    const depositTokens = [
      gmxContract.address,
      esGmxContract.address,
      stakedGmxTrackerContract.address,
      bnGmxTrackerContract.address,
      bnGmxContract.address,
      glpContract.address,
    ]
    const rewardTrackersForDepositBalances = [
      stakedGmxTrackerContract.address,
      stakedGmxTrackerContract.address,
      bnGmxTrackerContract.address,
      feeGmxTrackerContract.address,
      feeGmxTrackerContract.address,
      feeGlpTrackerContract.address,
    ]
    return [account ?? PLACEHOLDER_ACCOUNT, depositTokens, rewardTrackersForDepositBalances]
  }, [
    account,
    bnGmxContract,
    bnGmxTrackerContract,
    esGmxContract,
    feeGlpTrackerContract,
    feeGmxTrackerContract,
    glpContract,
    gmxContract,
    stakedGmxTrackerContract,
  ])

  const callState = useSingleCallResult(readerContract, 'getDepositBalances', inputs)

  return useMemo(() => {
    const tuple = callState.result?.[0]
    if (!tuple) {
      return [undefined, callState.loading]
    }

    return [getDepositBalanceData(tuple.map((value: any) => new Fraction(value, ONE_ETH_IN_WEI))), callState.loading]
  }, [callState.loading, callState.result])
}

export function useGmxSupply(): Fraction | undefined {
  const { chainId } = useActiveWeb3React()
  // noinspection JSUnusedGlobalSymbols
  const { data: gmxSupply } = useSWR([getServerUrl(chainId, '/gmx_supply')], {
    // @ts-expect-error spread args incorrect type
    fetcher: (...args) => fetchWithTimeout(...args).response.then(res => res.response.text()),
  })
  return useMemo(() => new Fraction(gmxSupply ?? '0', ONE_ETH_IN_WEI), [gmxSupply])
}

export function useStakedGmxSupply(): Fraction | undefined {
  const { chainId } = useActiveWeb3React()
  const gmxContract = useGmxContract()
  const stakedGmxContract = useStakedGmxTrackerContract()
  const tokens = useMemo(() => {
    if (!gmxContract) {
      return []
    }
    return [new Token(chainId, gmxContract.address, 18, 'GMX', 'GMX')]
  }, [chainId, gmxContract])
  const balanceMap = useTokenBalances(stakedGmxContract?.address, tokens)
  return useMemo(() => balanceMap[gmxContract?.address ?? '']?.asFraction, [balanceMap, gmxContract])
}

export function useGmxPriceUSD(): [Fraction | undefined, boolean] {
  const gmxToken = useGmxToken()
  const tokens = useMemo(() => (gmxToken ? [gmxToken] : []), [gmxToken])
  const [fiatMap, loading] = useDefaultFiatValuesWithLoadingIndicator(tokens)
  return useMemo(() => {
    return [fiatMap[gmxToken?.address ?? ''], loading]
  }, [fiatMap, loading, gmxToken])
}
