import { Token } from '@dolomite-exchange/sdk-core'
import { ChainId, ChainIdMap, initializeSingleValueChainIdMap } from '../chainId'

const decimals = 18
const symbol = 'USDY'
const name = 'Ondo U.S. Dollar Yield'

function getToken(chainId: ChainId, address: string) {
  return new Token(chainId, address, decimals, symbol, name)
}

export const USDY: ChainIdMap<Token | undefined> = initializeSingleValueChainIdMap(
  ChainId.MANTLE,
  getToken(ChainId.MANTLE, '0x5bE26527e817998A7206475496fDE1E68957c5A6'),
)
