import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { CurrencyAmount, Fraction, Pair } from '@dolomite-exchange/v2-sdk'
import Web3Status from '../../components/Web3Status'
import { AmmPoolData } from './ManagePool'
import { useTotalSupplies } from '../../data/TotalSupply'
import { useTokenBalancesWithLoadingIndicator } from '../../state/wallet/hooks'
import { useActiveWeb3React } from '../../hooks'
import PoolBalanceRow from './PoolBalanceRow'
import { Percent, Token } from '@dolomite-exchange/sdk-core'
import { StyledTooltip } from '../../components/common/StyledTooltip'
import HelpOutlinedIcon from '@material-ui/icons/HelpOutline'
import { TableLoader } from '../../components/Loader'
import invariant from 'tiny-invariant'
import { deriveMarketFromTokensReq } from '../../utils/marketUtils'
import { address } from '@dolomite-exchange/dolomite-margin/dist/src/types'
import { useDefaultFiatValuesWithLoadingIndicator } from '../../hooks/useFiatValue'
import { useInterestRateData } from '../../types/interestRateData'
import { formatAmount } from '../../utils/formatAmount'
import { useShowYieldAsApr } from '../../state/user/hooks'
import { InterestRatePart } from '../../types/InterestRatePart'

/* TODO - decide if you want to reduce padding on mobile */
const PoolBalancesWrapper = styled.div`
  background-color: #292938;
  border-radius: 8px;
  display: inline-block;
  padding: 25px 35px 17px;
  vertical-align: top;
  width: 100%;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
  margin-bottom: 30px;

  @media (max-width: 580px) {
    margin-bottom: 20px;
  }
`

const HeaderInner = styled.div`
  margin: 0 auto 0;
  text-align: left;
  font-weight: 100;
  font-size: 16px;

  h3 {
    margin-bottom: 5px !important;
    margin-top: 0 !important;
  }

  @media (max-width: 580px) {
    font-size: 15px;
  }
`

const HeaderContent = styled.div`
  font-size: 14px;
  margin-bottom: 15px;

  @media (max-width: 580px) {
    font-size: 12px;
  }
`

const ColumnHeaders = styled.div`
  color: #d5d6e1;
  width: 100%;
  font-size: 18px;

  @media (max-width: 580px) {
    font-size: 16px;
  }
`

const StyledInfoIcon = styled(HelpOutlinedIcon)`
  font-family: 'Open Sans', sans-serif !important;
  font-size: 15px !important;
  font-weight: 300 !important;
  color: #606375 !important;
  cursor: pointer !important;
  margin-left: 3px;
  margin-bottom: -1px !important;
`

const Column = styled.div`
  color: #d5d6e1;
  display: inline-block;
  font-weight: 100;
  vertical-align: top;
`

const PoolTable = styled.div`
  margin-bottom: 10px;
`

const PoolColumn = styled(Column)`
  text-align: left;
  width: 30%;
  cursor: pointer;

  > span {
    font-size: 16px;
  }
`

export const PoolDisplayRowWrapper = styled.div<{ selected: boolean }>`
  width: calc(100% + 70px);
  margin-left: -35px;
  padding: 5px 35px;

  :hover {
    background-color: ${({ theme }) => theme.bg2};
  }

  ${({ selected }) =>
    selected &&
    `
    background-color: #333344;
  `}
`

const RateColumn = styled(Column)`
  text-align: right;
  width: 20%;
  cursor: pointer;

  > span {
    font-size: 16px;
  }
`

const BalanceColumn = styled(Column)`
  text-align: right;
  width: 50%;
  cursor: pointer;

  > span {
    font-size: 16px;
  }

  @media (max-width: 580px) {
    margin: 2px 0;
  }
`

const ConnectWalletButton = styled.div`
  color: white !important;
  width: fit-content;
  text-align: center;
  margin: 15px auto 10px;
`

const ConnectBtn = styled.div`
  @media (max-width: 580px) {
    font-size: 14px;
  }
`

const Pool = styled.div`
  color: #f9f9f9;
  font-size: 18px;
  font-weight: 400;
  line-height: 38px;

  @media (max-width: 580px) {
    font-size: 14px;
  }
`

const RateWrapper = styled.div`
  color: #8fc942;
  font-weight: 600;
  font-size: 18px;
  display: inline-block;
  cursor: pointer;
  line-height: 38px;

  @media (max-width: 580px) {
    font-size: 14px;
  }
`

const BalanceWrapper = styled.div`
  font-size: 14px;
  font-weight: 400;

  @media (max-width: 580px) {
    font-size: 12px;
  }
`

const BalanceUnit = styled.span`
  font-weight: 100 !important;
`

const BeginEarningText = styled.div`
  color: ${({ theme }) => theme.text3};
  font-size: 14px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 15px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 11px;
  `}
`

const TooltipText = styled.div`
  font-family: 'Open Sans', sans-serif !important;
  font-size: 13px;
  color: #f9f9f9;
  padding: 12px 6px;
`
const TooltipHeader = styled.div`
  font-weight: 600;
  margin-bottom: 8px;
  font-size: 15px;
`

const TooltipLine = styled.div`
  margin-bottom: 4px;
  width: 100%;
`

const TooltipLineTitle = styled.span`
  font-weight: 400;
`

const TooltipLineValue = styled.span`
  font-weight: 600;
  color: #8fc942;
`

export const TooltipTitle = ({
  marginSupplyYield,
  ammYield,
  interestRateParts,
}: {
  marginSupplyYield: string
  ammYield: string
  interestRateParts: InterestRatePart[] | undefined
}) => {
  const [t] = useTranslation()
  const [showYieldAsApr] = useShowYieldAsApr()
  const yieldText = showYieldAsApr ? t('APR') : t('APY')
  return (
    <TooltipText>
      <TooltipHeader>{yieldText} Breakdown</TooltipHeader>
      <TooltipLine>
        <TooltipLineTitle>Lend {yieldText}: </TooltipLineTitle>
        <TooltipLineValue>{marginSupplyYield}</TooltipLineValue>
      </TooltipLine>
      <TooltipLine>
        <TooltipLineTitle>Pool {yieldText}: </TooltipLineTitle>
        <TooltipLineValue>{ammYield}</TooltipLineValue>
      </TooltipLine>
      {interestRateParts?.map((part, index) => (
        <TooltipLine key={index}>
          <TooltipLineTitle>{part.label}: </TooltipLineTitle>
          <TooltipLineValue>{formatAmount(part.interestRate?.divide(2))}</TooltipLineValue>
        </TooltipLine>
      ))}
    </TooltipText>
  )
}

interface PoolDisplayProps {
  currencyA: string
  currencyB: string
  currencyABalance?: string
  currencyBBalance?: string
  rate: InterestRateProps | null
  outsideInterestRateParts: InterestRatePart[] | undefined
}

export interface InterestRateProps {
  marginSupplyYield: string
  ammYield: string
  totalYield: string
}

const poolDisplayComparator = (prevProps: PoolDisplayProps, nextProps: PoolDisplayProps) => {
  return (
    prevProps.currencyA === nextProps.currencyA &&
    prevProps.currencyB === nextProps.currencyB &&
    prevProps.currencyABalance === nextProps.currencyABalance &&
    prevProps.currencyBBalance === nextProps.currencyBBalance &&
    prevProps.rate === nextProps.rate
  )
}

export const PoolDisplayRow = React.memo<PoolDisplayProps>(
  ({ currencyA, currencyB, currencyABalance, currencyBBalance, rate, outsideInterestRateParts }: PoolDisplayProps) => (
    <div>
      <PoolColumn>
        <Pool>{`${currencyA}-${currencyB}`}</Pool>
      </PoolColumn>
      <RateColumn>
        <StyledTooltip
          title={
            <TooltipTitle
              marginSupplyYield={rate?.marginSupplyYield ?? '0.00%'}
              ammYield={rate?.ammYield ?? '0.00%'}
              interestRateParts={outsideInterestRateParts}
            />
          }
          placement='top'
          arrow={true}
        >
          <RateWrapper>{rate?.totalYield ?? '0.00%'}</RateWrapper>
        </StyledTooltip>
      </RateColumn>
      <BalanceColumn>
        <BalanceWrapper>
          {currencyABalance ?? '0.000000'} <BalanceUnit>{currencyA}</BalanceUnit>
        </BalanceWrapper>
        <BalanceWrapper>
          {currencyBBalance ?? '0.000000'} <BalanceUnit>{currencyB}</BalanceUnit>
        </BalanceWrapper>
      </BalanceColumn>
    </div>
  ),
  poolDisplayComparator,
)
PoolDisplayRow.displayName = 'PoolDisplayDiv'

const widths = {
  widths: [20, 14, 25],
  starts: [0, 36, 75],
}

interface PoolComparatorProps {
  poolData: AmmPoolData
  totalApr: Percent
  balanceFiat: Fraction
}

const comparePools = (a: PoolComparatorProps, b: PoolComparatorProps, sortBy: PoolSortBy) => {
  const marketA = deriveMarketFromTokensReq(a.poolData.pair.token0, a.poolData.pair.token1)
  const marketB = deriveMarketFromTokensReq(b.poolData.pair.token0, b.poolData.pair.token1)
  if (sortBy === PoolSortBy.POOL) {
    return marketA.market.toUpperCase().localeCompare(marketB.market.toUpperCase()) ?? 0
  } else if (sortBy === PoolSortBy.RATE) {
    const aRate = a.totalApr.toFixed(6) ?? '0'
    const bRate = b.totalApr.toFixed(6) ?? '0'
    if (aRate && bRate) {
      return parseFloat(aRate) - parseFloat(bRate)
    }
  } else {
    invariant(sortBy === PoolSortBy.AMOUNT, 'Invalid sort by, expected AMOUNT')
    const aFiat = a.balanceFiat.toFixed(6) ?? '0'
    const bFiat = b.balanceFiat.toFixed(6) ?? '0'
    if (aFiat && bFiat) {
      return parseFloat(aFiat) - parseFloat(bFiat)
    }
  }
  return 0
}

export enum PoolSortBy {
  AMOUNT = 'amount',
  POOL = 'pool',
  RATE = 'rate',
}

export default function PoolBalances({
  ammPoolDatas,
  totalAprMap,
  setTotalAprForPair,
  onPairChange,
  walletConnected,
  isLoading,
}: {
  ammPoolDatas: AmmPoolData[]
  totalAprMap: Record<address, Percent | undefined>
  setTotalAprForPair: (pair: Pair, totalApr: Percent) => void
  onPairChange: (token0: Token, token1: Token) => void
  walletConnected: boolean
  isLoading: boolean
}) {
  const { t } = useTranslation()
  const [showYieldAsApr] = useShowYieldAsApr()
  const { account, chainId } = useActiveWeb3React()
  const [sortBy, setSortBy] = useState<PoolSortBy>(PoolSortBy.AMOUNT)
  const liquidityTokens = useMemo(() => ammPoolDatas.map(ammPoolData => ammPoolData.pair.liquidityToken), [
    ammPoolDatas,
  ])
  const [userLiquidityBalances, isLoadingBalances] = useTokenBalancesWithLoadingIndicator(account, liquidityTokens)
  const { loading: isInterestRateMapLoading, data: interestRateMap } = useInterestRateData()
  const totalSupplies = useTotalSupplies(liquidityTokens)
  const totalSuppliesMap = useMemo(() => {
    return totalSupplies.reduce<Record<address, CurrencyAmount<Token> | undefined>>((memo, totalSupply, index) => {
      memo[liquidityTokens[index].address] = totalSupply
      return memo
    }, {})
  }, [totalSupplies, liquidityTokens])

  const filteredAmmPoolDatas = useMemo(
    () =>
      ammPoolDatas.filter(ammPoolData => {
        return userLiquidityBalances[ammPoolData.pair.liquidityToken.address]?.greaterThan('0')
      }),
    [ammPoolDatas, userLiquidityBalances],
  )

  const tokensWithBalances = useMemo(() => {
    const tokenMap = filteredAmmPoolDatas.reduce<Record<address, Token>>((memo, ammPoolData) => {
      if (!memo[ammPoolData.pair.token0.address]) {
        memo[ammPoolData.pair.token0.address] = ammPoolData.pair.token0
      }
      if (!memo[ammPoolData.pair.token1.address]) {
        memo[ammPoolData.pair.token1.address] = ammPoolData.pair.token1
      }
      return memo
    }, {})
    return Object.values(tokenMap)
  }, [filteredAmmPoolDatas])

  const [fiatValueMap] = useDefaultFiatValuesWithLoadingIndicator(tokensWithBalances)

  const fiatBalanceMap = useMemo(() => {
    return filteredAmmPoolDatas.reduce<Record<address, Fraction | undefined>>((memo, ammPoolData) => {
      const liquidityTokenBalance = userLiquidityBalances[ammPoolData.pair.liquidityToken.address]
      const totalSupply = totalSuppliesMap[ammPoolData.pair.liquidityToken.address]
      const fiatValue0 = fiatValueMap[ammPoolData.pair.token0.address]
      const fiatValue1 = fiatValueMap[ammPoolData.pair.token0.address]
      if (liquidityTokenBalance && totalSupply && fiatValue0 && fiatValue1) {
        memo[ammPoolData.pair.liquidityToken.address] = liquidityTokenBalance.asFraction
          .multiply(ammPoolData.pair.reserve0.asFraction)
          .divide(totalSupply.asFraction)
          .multiply(fiatValue0)
          .multiply(2)
      }
      return memo
    }, {})
  }, [fiatValueMap, filteredAmmPoolDatas, totalSuppliesMap, userLiquidityBalances])

  // TODO - make sure translations have been added for all the text
  return (
    <PoolBalancesWrapper>
      <HeaderInner>
        <h3>{t('poolBalances')}</h3>
        <HeaderContent>
          Displayed are your tokens that are currently added to pools and earning bonus interest.
        </HeaderContent>
      </HeaderInner>
      <ColumnHeaders>
        <PoolColumn>
          <span onClick={() => setSortBy(PoolSortBy.POOL)}>{t('pool')}</span>
          <StyledTooltip title={t('poolDescription')} placement={'right'}>
            <StyledInfoIcon />
          </StyledTooltip>
        </PoolColumn>
        <RateColumn>
          <span onClick={() => setSortBy(PoolSortBy.RATE)}>{showYieldAsApr ? t('APR') : t('APY')}</span>
          <StyledTooltip title={t('aprDescription')} placement={'right'}>
            <StyledInfoIcon />
          </StyledTooltip>
        </RateColumn>
        <BalanceColumn>
          <span onClick={() => setSortBy(PoolSortBy.AMOUNT)}>{t('deposited')}</span>
          <StyledTooltip title={t('depositedDescription')} placement={'right'}>
            <StyledInfoIcon />
          </StyledTooltip>
        </BalanceColumn>
      </ColumnHeaders>
      {walletConnected ? (
        !isLoading && !isLoadingBalances ? (
          <PoolTable>
            {filteredAmmPoolDatas.length > 0 ? (
              filteredAmmPoolDatas
                .sort((ammPoolDataA: AmmPoolData, ammPoolDataB: AmmPoolData) => {
                  const totalAprA = totalAprMap[ammPoolDataA.pair.liquidityToken.address]
                  const balanceFiatA = fiatBalanceMap[ammPoolDataA.pair.liquidityToken.address]
                  const totalAprB = totalAprMap[ammPoolDataA.pair.liquidityToken.address]
                  const balanceFiatB = fiatBalanceMap[ammPoolDataA.pair.liquidityToken.address]
                  if (!totalAprA || !totalAprB || !balanceFiatA || !balanceFiatB) {
                    return 0
                  }

                  return comparePools(
                    {
                      poolData: ammPoolDataA,
                      totalApr: totalAprA,
                      balanceFiat: balanceFiatA,
                    },
                    {
                      poolData: ammPoolDataB,
                      totalApr: totalAprB,
                      balanceFiat: balanceFiatB,
                    },
                    sortBy,
                  )
                })
                .map((ammPoolData: AmmPoolData, index: number) => {
                  return (
                    <PoolBalanceRow
                      key={`balanceRow-${ammPoolData.pair.liquidityToken.address}`}
                      pool={ammPoolData}
                      setTotalAprForPair={setTotalAprForPair}
                      onPairChange={(pair: Pair) => onPairChange(pair.token0, pair.token1)}
                      userLiquidityBalance={userLiquidityBalances[ammPoolData.pair.liquidityToken.address]}
                      index={index}
                      totalSupply={totalSuppliesMap[ammPoolData.pair.liquidityToken.address]}
                      interestRateMap={interestRateMap}
                    />
                  )
                })
            ) : (
              <BeginEarningText>Select a pool below and deposit to start earning!</BeginEarningText>
            )}
          </PoolTable>
        ) : (
          <TableLoader rows={3} height={30} spacing={47} wrapperHeight={140} marginTop={15} widths={widths} />
        )
      ) : (
        <ConnectWalletButton>
          <ConnectBtn key={'accountItem-StartTrading'}>
            <Web3Status />
          </ConnectBtn>
        </ConnectWalletButton>
      )}
    </PoolBalancesWrapper>
  )
}
