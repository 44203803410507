import { SpecialAsset, SpecialAssetSymbol } from '../special-assets'
import {
  ChainId,
  PENDLE_PT_EZ_ETH_SEP_2024_ADDRESSES,
  PENDLE_PT_EZ_ETH_SEP_2024_ISOLATION_MODE_ADDRESSES,
} from '../../index'
import { Token } from '@dolomite-exchange/v2-sdk'
import { getOptionally } from '../special-assets-util-functions'
import { calculatePendlePtEzEthSep2024ProxyVaultAddress } from '../../../utils/isolation/calculateProxyVaultAddress'
import { PENDLE_PT_DOCS_LINK } from './constants'

const decimals = 18

export const PT_EZ_ETH_SEP_2024_SPECIAL_ASSET: () => SpecialAsset = () => ({
  symbol: SpecialAssetSymbol.dPtEzEthSeptember2024,
  safeSymbol: SpecialAssetSymbol.dPtEzEthSeptember2024.replace('-', '_'),
  cleanSymbol: 'PT-ezETH',
  cleanName: 'PT ezETH 2024-09-26',
  hasExternalRewards: false,
  documentationUrl: PENDLE_PT_DOCS_LINK,
  isIsolationMode: true,
  isBorrowingEnabled: false,
  chainIdToAddressMap: PENDLE_PT_EZ_ETH_SEP_2024_ISOLATION_MODE_ADDRESSES,
  isolationModeInfo: {
    getWrappedToken: (chainId: ChainId) =>
      getOptionally(
        PENDLE_PT_EZ_ETH_SEP_2024_ISOLATION_MODE_ADDRESSES[chainId],
        address =>
          new Token(
            chainId,
            address,
            decimals,
            SpecialAssetSymbol.dPtEzEthSeptember2024,
            'Dolomite Isolation: PT ezETH 26SEP2024',
          ),
      ),
    getUnderlyingToken: getPendlePtEzEthUnderlyingToken,
    getUnderlyingSpenderToken: getPendlePtEzEthUnderlyingToken,
    allowableDebtTokens: () => [],
    allowableCollateralTokens: () => [],
    remapAccountAddress: calculatePendlePtEzEthSep2024ProxyVaultAddress,
    isAsync: false,
  },
})

function getPendlePtEzEthUnderlyingToken(chainId: ChainId): Token | undefined {
  return getOptionally(
    PENDLE_PT_EZ_ETH_SEP_2024_ADDRESSES[chainId],
    address => new Token(chainId, address, decimals, 'PT-ezETH', 'PT ezETH 26SEP2024'),
  )
}
