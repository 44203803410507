import { useEffect, useRef } from 'react'

export default function useInterval(memoizedCallback: () => void, delayMs: null | number, leading = true): void {
  const savedCallback = useRef<() => void>()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = memoizedCallback
  }, [memoizedCallback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      const current = savedCallback.current
      current?.()
    }

    if (delayMs !== null) {
      if (leading) {
        tick()
      }
      const id = setInterval(tick, delayMs)
      return () => clearInterval(id)
    }
    return undefined
  }, [delayMs, leading])
}
