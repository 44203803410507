import React, { useMemo } from 'react'
import styled from 'styled-components/macro'
import { CurrencyAmount } from '@dolomite-exchange/v2-sdk'
import { useTranslation } from 'react-i18next'
import { StyledTooltip } from '../../components/common/StyledTooltip'
import { useTradeState } from '../../state/trade/hooks'
import { Field } from '../../state/trade/actions'
import { useToken } from '../../hooks/Tokens'
import cleanCurrencySymbol from '../../utils/cleanCurrencySymbol'
import { useFiatValueWithLoadingIndicator } from '../../hooks/useFiatValue'
import JSBI from 'jsbi'
import { FORMATTER, MAX_FRACTION, ZERO_FRACTION } from '../../constants'
import { maxBy, maxValue, minBy, minValue, sumFractions } from '../../utils/numberOperations'
import { useCurrentDayTokenHourlyData } from '../../types/tokenHourData'
import { Fraction } from '@dolomite-exchange/sdk-core'
import calculatePercentageChange from '../../utils/calculatePercentageChange'
import { deriveMarketFromTokens } from '../../utils/marketUtils'
import { SimpleLoader } from '../../components/Loader'

const StatsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  height: 40px;
  font-family: 'Open Sans', sans-serif;
  margin-top: -2px;
  margin-bottom: 7px;

  @media (max-width: 850px) {
    width: calc(100% - 500px);
    height: calc(65vh - 46px);
    flex-direction: column;
    position: absolute;
    right: 0;
    padding: 20px 50px;
    max-height: 500px;
    align-items: flex-end;

    > div {
      width: fit-content;
      float: right;

      > div {
        text-align: right;
        width: fit-content;
      }
    }
  }

  @media (max-width: 740px) {
    padding: 20px 25px;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    /*width: calc(100% - 10px);
    position: relative;
    display: block;
    height: fit-content;
    background: ${({ theme }) => theme.bg1};
    border-radius: 10px;
    margin: 5px;
    box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);*/
    width: calc(42% - 10px) !important;
    position: relative;
    display: inline-block;
    padding: 0;
    padding-right: 15px;
    text-align: right;
  `};
`

const StatsTitle = styled.div`
  width: 100% !important;
  text-align: left !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  margin-bottom: 5px !important;
  margin-top: -2px !important;
`

const StatWrapper = styled.div`
  width: 140px;
  text-align: center;

  @media (max-width: 1150px) {
    width: 120px;
  }

  @media (max-width: 1075px) {
    width: 110px;
  }

  @media (max-width: 1000px) {
    width: 100px;
  }

  @media (max-width: 850px) {
    width: fit-content;
    text-align: right;
    margin-top: 10px;
    display: inline-block;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    /*width: 50% !important;
    margin: 5px 0 !important;
    display: inline-block;
    float: inherit !important;
    text-align: center !important;*/
    width: 100% !important;
    margin: 36px 0 0 !important;
    text-align: right !important;
    
    > div {
      /*width: 100% !important;
      text-align: center !important;*/
      width: 100% !important;
      text-align: right !important;
    }
  `};
`

const StatTitle = styled.div`
  color: #606375;
  font-size: 12px;
  font-weight: 200;
  display: inline-block;
  text-align: center;
  width: 100%;

  @media (max-width: 1150px) {
    font-size: 10px;
    font-weight: 400;
  }

  @media (max-width: 850px) {
    text-align: right;
    font-size: 13px;
    width: 100%;
    display: block;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 13px;
  `}
`

const StatValueWrapper = styled.div`
  display: inline-block;
  text-align: center;
  color: #f9f9f9;
  font-weight: 700;
  font-size: 15px;
  transform: translateY(-4px);

  @media (max-width: 1150px) {
    font-size: 12px;
  }

  @media (max-width: 850px) {
    font-weight: 600;
    font-size: 16px;
    transform: translateY(-2px);
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 17px;
  `}
`

const Dollar = styled.span`
  margin-right: 1px;
  font-weight: 200;
`

const StatValue = styled.span``

const StatUnit = styled.span`
  margin-left: 5px;
  font-weight: 200;
`
interface StatProps {
  title: string
  value: string | undefined
  unit: string | null | undefined
  isUSD: boolean
  description: string
  loading: boolean
}

const statComparator = (prevProps: StatProps, nextProps: StatProps) => {
  return (
    prevProps.title === nextProps.title &&
    prevProps.value === nextProps.value &&
    prevProps.unit === nextProps.unit &&
    prevProps.description === nextProps.description &&
    prevProps.loading === nextProps.loading
  )
}

const Stat = React.memo<StatProps>(
  ({ title, value, unit, isUSD, description, loading }: StatProps) => (
    <StyledTooltip title={description}>
      <StatWrapper>
        <StatTitle>{title}</StatTitle>
        <StatValueWrapper>
          {loading && !value ? (
            <SimpleLoader width={60} height={16} spacing={23} marginTop={7} />
          ) : (
            <>
              {isUSD && <Dollar>$</Dollar>}
              <StatValue>{value}</StatValue>
              {!isUSD && <StatUnit>{unit ?? ''}</StatUnit>}
            </>
          )}
        </StatValueWrapper>
      </StatWrapper>
    </StyledTooltip>
  ),
  statComparator,
)
Stat.displayName = 'Stat'

export default function Stats() {
  const { t } = useTranslation()

  const {
    [Field.INPUT]: { currencyId: inputCurrencyId },
    [Field.OUTPUT]: { currencyId: outputCurrencyId },
  } = useTradeState()

  const inputToken = useToken(inputCurrencyId)
  const outputToken = useToken(outputCurrencyId)

  const market = useMemo(() => deriveMarketFromTokens(inputToken, outputToken), [inputToken, outputToken])

  const isPrimaryEqToInput = market?.primaryToken.symbol === inputToken?.symbol

  const inputAmount = inputToken
    ? CurrencyAmount.fromRawAmount(inputToken, JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(inputToken.decimals)))
    : undefined
  const [inputFiatValue, inputValueLoading] = useFiatValueWithLoadingIndicator(inputAmount, inputToken)

  const outputAmount = outputToken
    ? CurrencyAmount.fromRawAmount(outputToken, JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(outputToken.decimals)))
    : undefined
  const [outputFiatValue, outputValueLoading] = useFiatValueWithLoadingIndicator(outputAmount, outputToken)
  const { data: inputTokenData, loading: inputDataLoading } = useCurrentDayTokenHourlyData(inputToken)
  const { data: outputTokenData, loading: outputDataLoading } = useCurrentDayTokenHourlyData(outputToken)

  const volumeUSD = useMemo(() => {
    return sumFractions(inputTokenData ?? [], data => data.hourlyTradeVolumeUSD)
  }, [inputTokenData])

  const highPriceUSD = useMemo(() => {
    const data = market?.primaryToken.address === inputToken?.address ? inputTokenData : outputTokenData
    return maxValue(data ?? [], data => data.highPriceUSD)
  }, [market, inputToken, inputTokenData, outputTokenData])

  const lowPriceUSD = useMemo(() => {
    const data = market?.primaryToken.address === inputToken?.address ? inputTokenData : outputTokenData
    // There are some instances where lowPriceUSD is 0, when a market is initialized. In this case, ignore it.
    return minValue(data ?? [], data => (data.lowPriceUSD.equalTo(ZERO_FRACTION) ? MAX_FRACTION : data.lowPriceUSD))
  }, [market, inputToken, inputTokenData, outputTokenData])

  const openPriceUSD = useMemo(() => {
    const data = market?.primaryToken.address === inputToken?.address ? inputTokenData : outputTokenData
    return minBy(data ?? [], data => new Fraction(data.hourStartUnix.getTime()))?.openPriceUSD
  }, [market, inputToken, inputTokenData, outputTokenData])

  const closePriceUSD = useMemo(() => {
    const data = market?.primaryToken.address === inputToken?.address ? inputTokenData : outputTokenData
    return maxBy(data ?? [], data => new Fraction(data.hourStartUnix.getTime()))?.closePriceUSD
  }, [market, inputToken, inputTokenData, outputTokenData])

  const percentageChange = useMemo(() => {
    return openPriceUSD && closePriceUSD ? calculatePercentageChange(openPriceUSD, closePriceUSD) : undefined
  }, [openPriceUSD, closePriceUSD])

  const dataLoading = useMemo(() => {
    return market?.primaryToken.address === inputToken?.address ? inputDataLoading : outputDataLoading
  }, [market, inputToken, inputDataLoading, outputDataLoading])

  return (
    <StatsWrapper>
      {/*<StatsTitle>Stats</StatsTitle>*/}
      <Stat
        title={t('indexPrice', { asset: cleanCurrencySymbol(market?.primaryToken) })}
        value={(isPrimaryEqToInput ? inputFiatValue : outputFiatValue)?.toFixed(2, FORMATTER)}
        unit={null}
        isUSD={!!inputFiatValue}
        description={t('indexPriceDescription')}
        loading={isPrimaryEqToInput ? inputValueLoading : outputValueLoading}
      />
      <Stat
        title={t('indexPrice', { asset: cleanCurrencySymbol(market?.secondaryToken) })}
        value={(isPrimaryEqToInput ? outputFiatValue : inputFiatValue)?.toFixed(2, FORMATTER)}
        unit={null}
        isUSD={!!outputFiatValue}
        description={t('indexPriceDescription')}
        loading={isPrimaryEqToInput ? outputValueLoading : inputValueLoading}
      />
      <Stat
        title={t('volume')}
        value={volumeUSD.toFixed(2, FORMATTER)}
        unit={null}
        isUSD={true}
        description={t('volumeDescription', {
          symbol: cleanCurrencySymbol(inputToken),
        })}
        loading={inputDataLoading}
      />
      <Stat
        title={t('oneDayHigh')}
        value={highPriceUSD.equalTo('0') ? '-' : highPriceUSD.toFixed(2, FORMATTER)}
        unit={''}
        isUSD={!highPriceUSD.equalTo('0')}
        description={t('oneDayHighDescription', {
          secondary: cleanCurrencySymbol(outputToken),
          primary: cleanCurrencySymbol(inputToken),
        })}
        loading={dataLoading}
      />
      <Stat
        title={t('oneDayLow')}
        value={lowPriceUSD.equalTo('0') ? '-' : lowPriceUSD.toFixed(2, FORMATTER)}
        unit={''}
        isUSD={!lowPriceUSD.equalTo('0')}
        description={t('oneDayLowDescription', {
          secondary: cleanCurrencySymbol(outputToken),
          primary: cleanCurrencySymbol(inputToken),
        })}
        loading={dataLoading}
      />
      <Stat
        title={t('oneDayChange')}
        value={percentageChange?.toFixed(2, FORMATTER)}
        unit={
          '%' /* TODO - Add a formatPercent function that will give it a proper red or green color depending on positive/negative, as well as a plus sign in front for positive*/
        }
        isUSD={false}
        description={t('oneDayChangeDescription', {
          secondary: cleanCurrencySymbol(outputToken),
          primary: cleanCurrencySymbol(inputToken),
        })}
        loading={dataLoading}
      />
    </StatsWrapper>
  )
}
