import { useMemo } from 'react'
import { useGraphqlResult } from '../state/graphql/hooks'
import { GraphqlClientType, Web3CallType } from '../state/graphql/actions'
import { RefreshFrequency } from '../state/chain/hooks'

/**
 * Does a lookup for an ENS name to find its address.
 */
export default function useENSAddressList(
  names: string[] | undefined,
): {
  loading: boolean
  ensAddresses: (string | null)[] | undefined
} {
  const variables = useMemo(() => ({ data: names ?? [] }), [names])
  const addressState = useGraphqlResult<(string | null)[]>(
    GraphqlClientType.Web3,
    Web3CallType.ReverseEnsNames,
    variables,
    RefreshFrequency.Slowest,
  )
  return useMemo(() => {
    return {
      loading: addressState.loading,
      ensAddresses: addressState.result === undefined ? undefined : addressState.result ?? [],
    }
  }, [addressState.loading, addressState.result])
}
