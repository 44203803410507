import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import { StyledTooltip } from '../../components/common/StyledTooltip'
import { Currency, CurrencyAmount, Fraction, Token } from '@dolomite-exchange/v2-sdk'
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import {
  tryParseAmount,
  useCheckForCommons,
  useDepthChart,
  useDerivedTradeInfo,
  useFindBestCommon,
  useTradeState,
} from '../../state/trade/hooks'
import { Field } from '../../state/trade/actions'
import { usePair } from '../../data/Reserves'
import { useToken } from '../../hooks/Tokens'
import { useTranslation } from 'react-i18next'
import cleanCurrencySymbol from '../../utils/cleanCurrencySymbol'
import { ZERO_FRACTION } from '../../constants'
import { deriveMarketFromTokens } from '../../utils/marketUtils'
import { useTradeExactIn } from '../../hooks/Trades'
import { formatAmount } from '../../utils/formatAmount'
import { Ether } from '@dolomite-exchange/sdk-core'

const MIN_INTERVAL = 0.0001

const OrderBookWrapper = styled.div`
  width: 200px;
  height: calc(100% - 20px);
  display: inline-block;
  vertical-align: top;
  border-radius: 10px;
  padding: 5px 10px 10px 10px;
  position: relative;
  font-family: 'Open Sans', sans-serif;
  margin-top: 0;
  margin-bottom: 0;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    /*width: 100% !important;*/
    width: calc(58% - 10px);
    padding: 0;
    height: 500px !important;
    margin-bottom: 10px !important;
  `};
`

const DepthWrapper = styled.div`
  width: 100%;
  font-size: 13px;
  line-height: 20px;
  text-align: left;
  font-weight: 500;
  color: #d5d6e1;
  margin-top: -5px;
  margin-bottom: 0;
  margin-left: 5px;
`

const DepthValue = styled.div`
  display: inline-block;
  vertical-align: top;
`

const DepthManager = styled.div`
  display: inline-block;
  vertical-align: top;
  height: 20px;

  > div:first-of-type {
    font-size: 18px;
    line-height: 17px;
    font-weight: 400;
  }
`

const DepthButton = styled.div`
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  background: none;
  font-weight: 700;
  width: 20px;
  text-align: center;
  line-height: 20px;
`

interface IndicatorProps {
  amount: number
  hide: boolean
}

const PlacedBuyIndicator = styled.div<IndicatorProps>`
  position: absolute;
  width: calc(100% - 20px);
  bottom: calc(50% + 20px + (${p => p.amount}% / 2) - ${p => Math.round((p.amount * 48) / 100)}px);
  left: 0;
  text-align: left;
  font-family: 'Open Sans', sans-serif;
  z-index: 2;
  ${p => p.hide && 'display: none;'}
  transition: all 0.2s ease-in-out;
  pointer-events: none;

  hr {
    width: 100%;
    color: #606375;
    background-color: white;
    height: 1px;
  }

  > div:nth-of-type(2) {
    ${p => p.amount > 90 && `transform: translateY(${Math.round(((-90 + p.amount) / 10) * 20)}px);`}
    ${p => p.amount < 10 && `transform: translateY(-${Math.round(((10 - p.amount) / 10) * 8)}px);`}
  }
`

const PlacedSellIndicator = styled.div<IndicatorProps>`
  position: absolute;
  width: calc(100% - 20px);
  bottom: calc(50% - 45px - (${p => p.amount}% / 2) + ${p => Math.round((p.amount * 48) / 100)}px);
  left: 0;
  text-align: left;
  font-family: 'Open Sans', sans-serif;
  z-index: 2;
  ${p => p.hide && 'display: none;'}
  transition: all 0.2s ease-in-out;
  pointer-events: none;

  hr {
    width: 100%;
    color: #606375;
    background-color: white;
    height: 1px;
  }

  > div:nth-of-type(2) {
    ${p => p.amount > 90 && `transform: translateY(-${Math.round(((-90 + p.amount) / 10) * 8)}px);`}
    ${p => p.amount < 10 && `transform: translateY(${Math.round(((10 - p.amount) / 10) * 16)}px);`}
  }
`

const SellOrdersWrapper = styled.div`
  width: 180px;
  height: calc(50% - 25px);

  :hover + ${PlacedBuyIndicator} {
    opacity: 0 !important;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    min-width: 50%;
  `};
`

const IndicatorWrapper = styled.div`
  width: calc(32% - 15px);
  display: inline-block;
  vertical-align: top;
  margin-right: 5%;
  margin-left: 15px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-left: 5px;
  `};
`

const IndicatorValue = styled.div`
  display: inline-block;
  vertical-align: top;
  margin-top: -10px;
`

const IndicatorTitle = styled.div`
  width: 100%;
  font-weight: 200;
  font-size: 10px;
  line-height: 10px;
  text-align: left;
  letter-spacing: 0.8px;
`

const IndicatorPrice = styled.div`
  width: 100%;
`

const IndicatorCurrency = styled.span`
  font-weight: 100;
  font-size: 12px;
  color: #d5d6e1;
`

const MidMarketWrapper = styled.div`
  height: 45px;
  line-height: 50px;
  margin-left: 5px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 24px;
  margin-top: 5px;
  cursor: pointer;
  width: 280px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    text-align: left !important;
  `};
`

const MidMarketTitle = styled.div`
  width: 100%;
  font-weight: 200;
  font-size: 12px;
  line-height: 14px;
  pointer-events: none;
`

const MidMarketContent = styled.div`
  height: 30px;
  width: 100%;
  line-height: 30px;
  margin-top: -2px;
  pointer-events: none;
`

const MidMarketUnit = styled.span`
  font-weight: 200;
  font-size: 14px;
  color: #d5d6e1;
`

const BuyOrdersWrapper = styled.div<{ condensed?: boolean }>`
  width: 180px;
  ${({ condensed }) => condensed && 'margin-top: 10px;'}
  height: calc(50% - ${({ condensed }) => (condensed ? '35px' : '25px')});

  :hover + ${PlacedSellIndicator} {
    opacity: 0 !important;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    min-width: 50%;
  `};
`

const CustomTooltipWrapper = styled.div`
  width: fit-content;
  height: fit-content;
  padding: 10px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.primary3};
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  color: #d5d6e1;
  font-size: 13px;
  font-family: 'Open Sans', sans-serif;
  line-height: 18px;
  text-align: left;
`

const TooltipTitle = styled.div`
  width: 100%;
  font-weight: 200;
  font-size: 12px;
  line-height: 14px;
  text-align: left;
`

const TooltipBottom = styled.div`
  margin-top: 5px;
`

const TooltipPrice = styled.div`
  font-weight: 600;
  font-size: 16px;
`

const TooltipAmount = styled.span`
  width: fit-content;
`

const TooltipCurrency = styled.span`
  font-weight: 100;
  font-size: 12px;
  color: ${({ theme }) => theme.text2};
`

const OneHopInfo = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.text2};
  font-size: 10px;
  line-height: 12px;
`

interface CustomTooltipProps {
  active?: boolean
  payload?: any
  label?: number | Fraction | CurrencyAmount<Token> | string
  currencyA: string
  currencyB: string
  common: Currency | undefined
  marketPrice: Fraction | undefined
}

const DEFAULT_CURRENCY = Ether.onChain(1)

const CustomTooltip = ({ active, payload, label, currencyA, currencyB, common, marketPrice }: CustomTooltipProps) => {
  const payloadAmount = payload?.length ? payload[0].payload.Amount : undefined
  const { t } = useTranslation()
  const labelFraction = useMemo(() => tryParseAmount(label?.toString(), DEFAULT_CURRENCY), [label])
  const priceFloat = parseFloat(labelFraction?.toFixed(18) ?? '0')
  const marketFloat = useMemo(() => (marketPrice ? parseFloat(marketPrice.toFixed(6)) : undefined), [marketPrice])
  const priceImpact = useMemo(() => (marketFloat ? ((priceFloat - marketFloat) / marketFloat) * 100 : undefined), [
    marketFloat,
    priceFloat,
  ])
  const price = useMemo(() => formatAmount(tryParseAmount(payloadAmount?.toString(), DEFAULT_CURRENCY)), [
    payloadAmount,
  ])
  if (active && payload && payload.length) {
    return (
      <CustomTooltipWrapper>
        <TooltipTitle>{t('tooltipPriceLabel')}</TooltipTitle>
        <TooltipPrice>
          <TooltipAmount>{formatAmount(labelFraction)}</TooltipAmount>{' '}
          <TooltipCurrency>
            {currencyA}/{currencyB}
          </TooltipCurrency>
        </TooltipPrice>
        <TooltipBottom>
          <TooltipTitle>{t('tooltipAmountLabel')}</TooltipTitle>
          <TooltipPrice>
            <TooltipAmount>{price}</TooltipAmount> <TooltipCurrency>{currencyA}</TooltipCurrency>
          </TooltipPrice>
        </TooltipBottom>
        {!common && priceImpact && (
          <TooltipBottom>
            <TooltipTitle>{t('priceImpactLabel')}</TooltipTitle>
            <TooltipPrice>
              <TooltipAmount>{priceImpact.toFixed(2)}</TooltipAmount>%
            </TooltipPrice>
          </TooltipBottom>
        )}
        {!!common && (
          <OneHopInfo>
            The displayed price and depth reflects trades that would be routed through other pools to provide a better
            price.
          </OneHopInfo>
        )}
      </CustomTooltipWrapper>
    )
  }
  return null
}

interface GraphProps {
  Price: number
  Amount: number
}

interface ChartProps {
  graph: GraphProps[] | undefined
  primaryToken: Currency | undefined
  secondaryToken: Currency | undefined
  handleHideIndicator: () => void
  handleShowIndicator: () => void
  side: string
  commonToken?: Currency | undefined
  marketPrice: Fraction | undefined
}

const chartComparator = (prevProps: ChartProps, nextProps: ChartProps) => {
  if (nextProps.graph) {
    if (prevProps.graph) {
      if (prevProps.graph.length > 0) {
        if (nextProps.graph.length > 0) {
          return prevProps.graph[0].Amount === nextProps.graph[0].Amount
        }
        return false
      }
      return nextProps.graph.length <= 0
    }
    return false
  }
  return !!prevProps.graph
}

const ChartSection = React.memo<ChartProps>(
  ({
    graph,
    primaryToken,
    secondaryToken,
    handleHideIndicator,
    handleShowIndicator,
    side,
    commonToken,
    marketPrice,
  }: ChartProps) => {
    if (graph) {
      return (
        <>
          <ResponsiveContainer width='100%' height='100%'>
            <AreaChart
              data={graph}
              layout={'vertical'}
              onMouseEnter={() => handleHideIndicator()}
              onMouseLeave={() => handleShowIndicator()}
            >
              <XAxis
                type={'number'}
                //interval="preserveStart"
                axisLine={false}
                tickSize={5}
                hide={true}
              />
              <YAxis
                dataKey={'Price'}
                domain={[graph[graph.length - 1]?.Price ?? 0, graph[0]?.Price ?? 1]}
                axisLine={false}
                tickSize={0}
                hide={true}
                reversed={true}
              />
              <Tooltip
                active={true}
                content={
                  <CustomTooltip
                    currencyA={cleanCurrencySymbol(primaryToken) ?? ''}
                    currencyB={cleanCurrencySymbol(secondaryToken) ?? ''}
                    common={commonToken}
                    marketPrice={marketPrice}
                  />
                }
                allowEscapeViewBox={{
                  x: true,
                  y: true,
                }}
                animationDuration={250}
                wrapperStyle={{
                  zIndex: 999,
                  fontFamily: 'Open Sans, sans-serif',
                }}
              />
              <Area
                type='monotone'
                dataKey='Amount'
                stroke={side === 'SELL' ? '#d33d34' : '#68b04d'}
                fill={side === 'SELL' ? '#d33d34' : '#68b04d'}
                isAnimationActive={false}
                activeDot={false}
              />
            </AreaChart>
          </ResponsiveContainer>
        </>
      )
    } else {
      return <></>
    }
  },
  chartComparator,
)
ChartSection.displayName = 'ChartSection'

const LoadingChartSection = React.memo<ChartProps>(({ graph, handleHideIndicator }: ChartProps) => {
  if (graph) {
    return (
      <>
        <ResponsiveContainer width='100%' height='100%'>
          <AreaChart
            data={graph}
            layout={'vertical'}
            onMouseEnter={() => handleHideIndicator()}
            onMouseLeave={() => handleHideIndicator()}
          >
            <XAxis
              type={'number'}
              //interval="preserveStart"
              axisLine={false}
              tickSize={5}
              hide={true}
            />
            <YAxis
              dataKey={'Price'}
              domain={[graph[graph.length - 1]?.Price ?? 0, graph[0]?.Price ?? 1]}
              axisLine={false}
              tickSize={0}
              hide={true}
              reversed={true}
            />
            <Area
              type='monotone'
              dataKey='Amount'
              stroke={'#565A69'}
              fill={'#40444F'}
              isAnimationActive={false}
              activeDot={false}
            />
          </AreaChart>
        </ResponsiveContainer>
      </>
    )
  } else {
    return <></>
  }
}, chartComparator)
LoadingChartSection.displayName = 'LoadingChartSection'

const IntervalLoadingWrapper = styled.div`
  width: 25px;
  color: white;
  font-weight: 600;
`

interface IntervalSelectorProps {
  interval: number | undefined
  isLoading: boolean
  marketPrice: Fraction | null | undefined
  handleIncreaseInterval: any
  handleDecreaseInterval: any
}

const intervalSelectorComparator = (prevProps: IntervalSelectorProps, nextProps: IntervalSelectorProps) => {
  if (!!prevProps.marketPrice !== !!nextProps.marketPrice) return false
  if (prevProps.isLoading !== nextProps.isLoading) return false
  if (!prevProps.marketPrice && !nextProps.marketPrice) return true
  if (!nextProps.interval) return true
  if (prevProps.interval !== nextProps.interval) return false
  return (
    prevProps.interval * 20 <= parseFloat(prevProps.marketPrice?.toFixed(3) ?? '0') &&
    nextProps.interval * 20 <= parseFloat(nextProps.marketPrice?.toFixed(3) ?? '0')
  )
}

const IntervalSelector = React.memo<IntervalSelectorProps>(
  ({ interval, isLoading, marketPrice, handleIncreaseInterval, handleDecreaseInterval }: IntervalSelectorProps) => {
    if (interval)
      return (
        <>
          <DepthManager>
            {isLoading ? (
              <IntervalLoadingWrapper>SPN</IntervalLoadingWrapper>
            ) : (
              <>
                {interval > MIN_INTERVAL && <DepthButton onClick={handleDecreaseInterval}>-</DepthButton>}
                {(!marketPrice || interval * 20 <= parseFloat(marketPrice.toFixed(3) || '0')) && (
                  <DepthButton onClick={handleIncreaseInterval}>+</DepthButton>
                )}
              </>
            )}
          </DepthManager>
          <DepthValue>{formatAmount(tryParseAmount(interval.toString(), DEFAULT_CURRENCY))}</DepthValue>
        </>
      )
    return <></>
  },
  intervalSelectorComparator,
)
IntervalSelector.displayName = 'IntervalSelector'

interface MarketPriceProps {
  handleSwapTokens: any
  marketPrice: Fraction | null | undefined
  primaryTokenTicker: string | null | undefined
  secondaryTokenTicker: string | null | undefined
  t: any
}

const marketPriceComparator = (prevProps: MarketPriceProps, nextProps: MarketPriceProps) => {
  return (
    prevProps.marketPrice?.toFixed(5) === nextProps.marketPrice?.toFixed(5) &&
    prevProps.t === nextProps.t &&
    prevProps.primaryTokenTicker === nextProps.primaryTokenTicker &&
    prevProps.secondaryTokenTicker === nextProps.secondaryTokenTicker
  )
}

const MarketPrice = React.memo<MarketPriceProps>(
  ({ handleSwapTokens, marketPrice, primaryTokenTicker, secondaryTokenTicker, t }: MarketPriceProps) => (
    <MidMarketWrapper onClick={handleSwapTokens}>
      <MidMarketTitle>{t('midMarketPrice')}</MidMarketTitle>
      {/*<Skeleton style={{ width: '100%', height: '30px', display: 'block' }} />*/}
      <MidMarketContent>
        {formatAmount(marketPrice ?? undefined, 2)}{' '}
        <MidMarketUnit>
          {primaryTokenTicker ?? ''}/{secondaryTokenTicker ?? ''}
        </MidMarketUnit>
      </MidMarketContent>
    </MidMarketWrapper>
  ),
  marketPriceComparator,
)
MarketPrice.displayName = 'MarketPrice'

const OneHopIndicatorWrapper = styled.div`
  width: 110%;
  padding-left: 5px;
  font-size: 10px;
  display: inline-block;
  color: ${({ theme }) => theme.text2};
  position: absolute;
  margin-top: -5px;
  cursor: default;
}
`

const OneHopIndicator = ({
  primaryTicker,
  commonTicker,
  secondaryTicker,
}: {
  primaryTicker: string | undefined
  commonTicker: string | undefined
  secondaryTicker: string | undefined
}) => (
  <StyledTooltip
    title={`Your currently entered trade will be routed through the ${primaryTicker}-${commonTicker} and ${commonTicker}-${secondaryTicker} pools in order to get you a better price. As such, only the market price on those pools will be affected by this trade.`}
    placement={'top'}
  >
    <OneHopIndicatorWrapper>
      Routed to {`${primaryTicker}-${commonTicker}`} & {`${commonTicker}-${secondaryTicker}`} markets
    </OneHopIndicatorWrapper>
  </StyledTooltip>
)

function getNewInterval(newMarketPrice: Fraction): number {
  if (newMarketPrice.greaterThan(new Fraction('1000'))) return 1
  if (newMarketPrice.greaterThan(new Fraction('100'))) return 0.1
  if (newMarketPrice.greaterThan(new Fraction('10'))) return 0.01
  if (newMarketPrice.greaterThan(new Fraction('1'))) return 0.001
  return 0.0001
}

interface SwapIntervalInterface {
  swapTokens: boolean
  interval: number | undefined
  userInput: string | undefined //These are undefined until the user changes the interval. When it changes, these record the token
  userOutput: string | undefined //pair for which it was changed, so that on a change in token pair the interval can be recalculated anew, saving a depth chart generation
}

/*interface LoadingInterface {
  loading: boolean;
  startedGeneration: boolean;
  primary: string | null;
  secondary: string | null;
}*/

export default function OrderBook() {
  const { t } = useTranslation()

  const {
    [Field.INPUT]: { currencyId: inputCurrencyId },
    [Field.OUTPUT]: { currencyId: outputCurrencyId },
  } = useTradeState()

  const [swapInterval, setSwapInterval] = useState<SwapIntervalInterface>({
    swapTokens: false,
    interval: undefined,
    userInput: undefined,
    userOutput: undefined,
  })

  const swapTokens = swapInterval.swapTokens

  const [hideIndicator, setHideIndicator] = useState(false)

  const inputToken = useToken(inputCurrencyId)
  const outputToken = useToken(outputCurrencyId)

  const market = useMemo(() => deriveMarketFromTokens(inputToken, outputToken), [inputToken, outputToken])

  const primaryToken = useMemo(() => {
    return swapTokens ? market?.secondaryToken : market?.primaryToken
  }, [swapTokens, market])

  const secondaryToken = useMemo(() => {
    return swapTokens ? market?.primaryToken : market?.secondaryToken
  }, [swapTokens, market])

  const { v2Trade, currencies } = useDerivedTradeInfo()

  const interval = swapInterval.interval
  const intervalString = useMemo(() => interval?.toFixed(4), [interval])
  const depthChart = useDepthChart(interval, primaryToken, secondaryToken, intervalString)

  //TODO - when loading the chart, show a grayed out (well not gray, use the dim company color) version of the chart but with some "default" data that just draws a basic chart

  const sellGraph = depthChart.sell
  const buyGraph = depthChart.buy

  const [, pair] = usePair(primaryToken ?? undefined, secondaryToken ?? undefined)

  const primaryLiquidity = useMemo(() => {
    return cleanCurrencySymbol(primaryToken) === cleanCurrencySymbol(pair?.reserve0.currency)
      ? pair?.reserve0.asFraction
      : pair?.reserve1.asFraction
  }, [pair, primaryToken])

  const secondaryLiquidity = useMemo(() => {
    return cleanCurrencySymbol(secondaryToken) === cleanCurrencySymbol(pair?.reserve1.currency)
      ? pair?.reserve1.asFraction
      : pair?.reserve0.asFraction
  }, [pair, secondaryToken])

  const amountIn = useMemo(() => {
    if (!primaryToken || (primaryLiquidity && secondaryLiquidity)) {
      return undefined
    }

    return tryParseAmount('0.00001', primaryToken)
  }, [primaryToken, primaryLiquidity, secondaryLiquidity])

  const bestTradeExactIn = useTradeExactIn(amountIn, secondaryToken)

  const marketPrice = useMemo(() => {
    if (!secondaryLiquidity || !primaryLiquidity) {
      // price impact is estimated to be ~0.002%
      // const priceImpact = new Fraction('100002', '100000')
      const priceImpact = new Fraction('1')
      const oneHopFeesWithPriceImpact = new Fraction('1000000', '994009').multiply(priceImpact)
      if (!bestTradeExactIn) {
        return undefined
      }

      return bestTradeExactIn.outputAmount.asFraction
        .divide(bestTradeExactIn.inputAmount.asFraction)
        .multiply(oneHopFeesWithPriceImpact)
    }

    return primaryLiquidity.equalTo('0') ? ZERO_FRACTION : secondaryLiquidity.divide(primaryLiquidity)
  }, [bestTradeExactIn, primaryLiquidity, secondaryLiquidity])

  useEffect(() => {
    if (swapInterval.interval === undefined && marketPrice && !marketPrice.equalTo(ZERO_FRACTION)) {
      setSwapInterval({
        swapTokens: swapTokens,
        interval: marketPrice ? getNewInterval(marketPrice) : undefined,
        userInput: primaryToken?.symbol,
        userOutput: secondaryToken?.symbol,
      })
    }
  }, [marketPrice, primaryToken, secondaryToken, swapInterval, swapTokens])

  let indicatorPercent = 0
  let indicatorDisplayValue = 0

  let commonToken = useFindBestCommon(
    currencies[Field.INPUT],
    currencies[Field.OUTPUT],
    v2Trade?.inputAmount.asFraction,
  )
  if (!v2Trade) {
    commonToken = undefined
  }
  const intervalAmount = useMemo(() => {
    return tryParseAmount(swapInterval.interval?.toFixed(4), secondaryToken)?.asFraction
  }, [swapInterval.interval, secondaryToken])
  const [buySideCommonToken, sellSideCommonToken] = useCheckForCommons(
    primaryToken,
    secondaryToken,
    primaryLiquidity,
    secondaryLiquidity,
    intervalAmount,
  )

  if (interval && v2Trade && primaryLiquidity && secondaryLiquidity && !commonToken) {
    const market = parseFloat(secondaryLiquidity.divide(primaryLiquidity).toFixed(8))
    const newMarket =
      primaryToken?.symbol === v2Trade.inputAmount.currency.symbol
        ? (!swapTokens
            ? secondaryLiquidity
                .subtract(v2Trade.outputAmount.asFraction)
                .divide(primaryLiquidity.add(v2Trade.inputAmount.asFraction))
            : secondaryLiquidity
                .subtract(v2Trade.outputAmount.asFraction)
                .divide(primaryLiquidity.add(v2Trade.inputAmount.asFraction))
          ).toFixed(8)
        : (!swapTokens
            ? secondaryLiquidity
                .add(v2Trade.inputAmount.asFraction)
                .divide(primaryLiquidity.subtract(v2Trade.outputAmount.asFraction))
            : secondaryLiquidity
                .add(v2Trade.inputAmount.asFraction)
                .divide(primaryLiquidity.subtract(v2Trade.outputAmount.asFraction))
          ).toFixed(8)

    indicatorDisplayValue = parseFloat(newMarket)
    indicatorPercent = Math.max(
      Math.min(parseFloat(((Math.abs(market - parseFloat(newMarket)) * 4) / interval).toFixed(3)), 100),
      0,
    )
  }

  const handleIncreaseInterval = () => {
    if (interval && interval * 20 <= parseFloat(marketPrice?.toFixed(5) || '0')) {
      setSwapInterval({
        swapTokens: swapTokens,
        interval: interval * 10,
        userInput: swapTokens ? secondaryToken?.symbol : primaryToken?.symbol,
        userOutput: swapTokens ? primaryToken?.symbol : secondaryToken?.symbol,
      })
    }
  }

  const handleDecreaseInterval = () => {
    if (interval && interval > MIN_INTERVAL) {
      setSwapInterval({
        swapTokens: swapTokens,
        interval: interval / 10,
        userInput: swapTokens ? secondaryToken?.symbol : primaryToken?.symbol,
        userOutput: swapTokens ? primaryToken?.symbol : secondaryToken?.symbol,
      })
    }
  }

  const handleSwapTokens = () => {
    const newMarketPrice = marketPrice?.greaterThan(ZERO_FRACTION) ? marketPrice.invert() : ZERO_FRACTION
    setSwapInterval({
      swapTokens: !swapInterval.swapTokens,
      interval: newMarketPrice ? getNewInterval(newMarketPrice) : 1,
      userInput: swapInterval.userOutput,
      userOutput: swapInterval.userInput,
    })
  }

  useEffect(() => {
    if (
      swapInterval.interval !== undefined &&
      marketPrice &&
      !marketPrice.equalTo(ZERO_FRACTION) &&
      ((primaryToken?.symbol !== swapInterval.userInput && primaryToken?.symbol !== swapInterval.userOutput) ||
        (secondaryToken?.symbol !== swapInterval.userInput && secondaryToken?.symbol !== swapInterval.userOutput))
    ) {
      setSwapInterval({
        swapTokens: swapTokens,
        interval: marketPrice ? getNewInterval(marketPrice) : undefined,
        userInput: primaryToken?.symbol,
        userOutput: secondaryToken?.symbol,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primaryToken?.symbol, secondaryToken?.symbol])

  // TODO - when you have an order typed in
  // TODO - add loading animation. Trade out the chart components while loading and show an animation there instead. Also hide the interval and interval buttons
  // TODO - DO REACT REDUX STATE STUFF, that's what watches the inputs on the Trade panel, you can monitor those for the order book, as well as markets, and use the info to fill out the Trade object to fill the chart
  return (
    <OrderBookWrapper>
      <SellOrdersWrapper>
        {!sellGraph || !buyGraph || sellGraph.length === 0 || buyGraph.length === 0 ? (
          <LoadingChartSection
            graph={[
              {
                Price: 1,
                Amount: 0.05,
              },
              {
                Price: 2,
                Amount: 1,
              },
            ]}
            primaryToken={primaryToken}
            secondaryToken={secondaryToken}
            handleHideIndicator={() => setHideIndicator(true)}
            handleShowIndicator={() => setHideIndicator(false)}
            side={'SELL'}
            marketPrice={undefined}
          />
        ) : (
          <ChartSection
            graph={sellGraph}
            primaryToken={primaryToken}
            secondaryToken={secondaryToken}
            handleHideIndicator={() => setHideIndicator(true)}
            handleShowIndicator={() => setHideIndicator(false)}
            side={'SELL'}
            commonToken={buySideCommonToken}
            marketPrice={marketPrice}
          />
        )}
      </SellOrdersWrapper>
      <PlacedBuyIndicator
        amount={indicatorPercent}
        hide={
          !(
            v2Trade &&
            ((swapTokens && !(primaryToken?.symbol === v2Trade.inputAmount.currency.symbol)) ||
              (!swapTokens && !(primaryToken?.symbol === v2Trade.inputAmount.currency.symbol)))
          ) ||
          hideIndicator ||
          !!commonToken
        }
      >
        <IndicatorWrapper>
          <hr />
        </IndicatorWrapper>
        <IndicatorValue>
          <IndicatorTitle>{t('newPriceDepthChart')}</IndicatorTitle>
          <IndicatorPrice>
            {formatAmount(tryParseAmount(indicatorDisplayValue.toString(), DEFAULT_CURRENCY), 2)}{' '}
            <IndicatorCurrency>{cleanCurrencySymbol(secondaryToken) ?? ''}</IndicatorCurrency>
          </IndicatorPrice>
        </IndicatorValue>
      </PlacedBuyIndicator>
      <MarketPrice
        handleSwapTokens={handleSwapTokens}
        marketPrice={marketPrice}
        primaryTokenTicker={cleanCurrencySymbol(primaryToken)}
        secondaryTokenTicker={cleanCurrencySymbol(secondaryToken)}
        t={t}
      />
      {commonToken && (
        <OneHopIndicator
          primaryTicker={cleanCurrencySymbol(currencies[Field.INPUT])}
          commonTicker={cleanCurrencySymbol(commonToken)}
          secondaryTicker={cleanCurrencySymbol(currencies[Field.OUTPUT])}
        />
      )}
      <BuyOrdersWrapper condensed={!!commonToken}>
        {!sellGraph || !buyGraph || sellGraph.length === 0 || buyGraph.length === 0 ? (
          <LoadingChartSection
            graph={[
              {
                Price: 1,
                Amount: 1,
              },
              {
                Price: 2,
                Amount: 0.1,
              },
            ]}
            primaryToken={primaryToken}
            secondaryToken={secondaryToken}
            handleHideIndicator={() => setHideIndicator(true)}
            handleShowIndicator={() => setHideIndicator(false)}
            side={'SELL'}
            marketPrice={undefined}
          />
        ) : (
          <ChartSection
            graph={buyGraph}
            primaryToken={primaryToken}
            secondaryToken={secondaryToken}
            handleHideIndicator={() => setHideIndicator(true)}
            handleShowIndicator={() => setHideIndicator(false)}
            side={'BUY'}
            commonToken={sellSideCommonToken}
            marketPrice={marketPrice}
          />
        )}
      </BuyOrdersWrapper>
      <PlacedSellIndicator
        amount={indicatorPercent}
        hide={
          !(
            v2Trade &&
            !(
              (swapTokens && !(primaryToken?.symbol === v2Trade.inputAmount.currency.symbol)) ||
              (!swapTokens && !(primaryToken?.symbol === v2Trade.inputAmount.currency.symbol))
            )
          ) ||
          hideIndicator ||
          !!commonToken
        }
      >
        <IndicatorWrapper>
          <hr />
        </IndicatorWrapper>
        <IndicatorValue>
          <IndicatorTitle>{t('newPriceDepthChart')}</IndicatorTitle>
          <IndicatorPrice>
            {formatAmount(tryParseAmount(indicatorDisplayValue.toString(), DEFAULT_CURRENCY))}{' '}
            <IndicatorCurrency>{cleanCurrencySymbol(secondaryToken) ?? ''}</IndicatorCurrency>
          </IndicatorPrice>
        </IndicatorValue>
      </PlacedSellIndicator>
      <DepthWrapper>
        <IntervalSelector
          interval={interval}
          isLoading={false}
          marketPrice={marketPrice}
          handleIncreaseInterval={handleIncreaseInterval}
          handleDecreaseInterval={handleDecreaseInterval}
        />
      </DepthWrapper>
    </OrderBookWrapper>
  )
}
