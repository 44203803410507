import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import PageTitle from '../../components/common/PageTitle'
import Table from '../../components/Leaderboard/Table'
import { Ether, Fraction } from '@dolomite-exchange/sdk-core'
import { useParams } from 'react-router-dom'
import { MarginPosition, useMarginPositionsByWalletAddressData } from '../../types/marginPositionData'
import { ChartLabel, ChartText, ChartTextLarge, ChartValue } from '../../components/Stats/Charts/styled'
import { BigNumber } from 'ethers'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { sumFractions } from '../../utils/numberOperations'
import { ZERO_FRACTION } from '../../constants'
import { styled as muiStyled } from '@mui/material'
import LineChart from '../../components/Stats/Charts/LineChart'
import { unixToDate } from '../Stats'
import useTheme from '../../hooks/useTheme'
import { formatAmount } from '../../utils/formatAmount'
import { tryParseAmount } from '../../state/trade/hooks'
import { useBlockTimestamp } from '../../state/chain/hooks'

const DAY = 0
const WEEK = 1
const MONTH = 2

const AccountWrapper = styled.div`
  margin: 25px auto;
  width: 80vw;
  min-width: 340px;
  max-width: 600px;

  @media screen and (max-width: 1219px) {
    width: 90vw;
  }

  @media screen and (max-width: 1000px) {
    width: 100%;
    max-width: 600px;
    margin: 5px auto;
  }

  @media screen and (max-width: 919px) {
    margin-top: 15px;
  }

  @media screen and (max-width: 730px) {
    width: 90vw;
  }

  @media screen and (max-width: 580px) {
    margin: 0 auto 15px;
    width: calc(100vw - 32px);
  }
`

const AccountHeader = styled.div`
  margin-bottom: 10px;
  height: 30px;
`

const AddressWrapper = styled.div`
  display: inline-block;
  vertical-align: top;
  font-size: 26px;
  font-weight: 200;
  margin-left: 3px;
`

const WinLoss = styled.div`
  float: right;
  font-size: 17px;
  font-weight: 600;
  display: inline-block;
  margin-top: 3px;
`

const Subtitle = styled.span`
  color: ${({ theme }) => theme.text3};
  font-weight: 600;
  margin-left: 12px;
  margin-right: 6px;
`

const Win = styled.span`
  color: ${({ theme }) => theme.green1};
`

const Loss = styled.span`
  color: ${({ theme }) => theme.red1};
`

const TableWrapper = styled.div`
  margin: 0;
`

const TableHeader = styled.div`
  margin-top: 15px;
  margin-bottom: 5px;
  font-size: 20px;
  height: 30px;
`

const TableTitle = styled.div`
  display: inline-block;
  float: left;
`

const FooterSpacing = styled.div`
  height: 30px;
  @media (max-width: 660px) {
    height: 100px;
  }
`

const AccountIcon = muiStyled(AccountCircleIcon)(({ theme }) => ({
  fontSize: 22,
  color: '#d5d6e1',
  marginTop: '8px',
}))

interface ChartData {
  time: string
  value: number
}

interface FracData {
  time: string
  value: Fraction
}

export default function Account() {
  const { t } = useTranslation()
  const theme = useTheme()
  const { address } = useParams<{ address: string }>()
  const { data: positions, loading: positionLoading } = useMarginPositionsByWalletAddressData(address, undefined)
  const { data: open, loading: openLoading } = useMarginPositionsByWalletAddressData(address, true)
  const settled = useMemo(() => positions.filter(position => position.closeTransaction), [positions])
  const [selectedTab, setSelectedTab] = useState<number>(DAY)
  const [label, setLabel] = useState<string | undefined>()
  const [chartValue, setChartValue] = useState<number | undefined>()

  const options = ['day', 'week', 'month']

  const timeStamp = (position: MarginPosition) => {
    return (position.closeTimestamp ? position.closeTimestamp.getTime() : position.openTimestamp.getTime()) / 1000
  }

  let wins = 0
  let losses = 0
  const sorted = positions.sort((a, b) => {
    if (a && b) {
      return timeStamp(a) > timeStamp(b) ? 1 : -1
    } else {
      return -1
    }
  })
  const sortedSettled = settled.sort((a, b) => {
    if (a && b) {
      return timeStamp(a) > timeStamp(b) ? 1 : -1
    } else {
      return -1
    }
  })
  sortedSettled.map(pos => (pos.profitPercentage.greaterThanOrEqual('0') ? wins++ : losses++))

  const profitData: ChartData[] = useMemo(() => {
    if (sorted) {
      return sorted
        .map((position, i) => {
          return {
            time: unixToDate(timeStamp(position)),
            value: position.profit,
          }
        })
        .map(
          (sum => (value: FracData) => {
            sum = sum.add(value.value)
            return {
              time: value.time,
              value: sum,
            }
          })(ZERO_FRACTION),
        )
        .map((data: FracData) => {
          return {
            time: data.time,
            value: parseFloat(data.value.toFixed(2)),
          }
        })
    } else {
      return []
    }
  }, [sorted])

  const timestamp = useBlockTimestamp() ?? BigNumber.from('0')
  const period = selectedTab === DAY ? '24' : selectedTab === WEEK ? '7' : '30'
  const span = selectedTab === DAY ? '3600' : '86400'
  const start = useMemo(() => {
    return timestamp
      .div(span)
      .sub(period)
      .mul(span)
      .toNumber()
  }, [timestamp, period, span])
  const profit = useMemo(() => {
    return sumFractions(positions ?? [], data => data.profit)
  }, [positions])

  const value = parseFloat(profit.toFixed(2))
  useEffect(() => {
    if (chartValue === undefined && value > 0) {
      setChartValue(value)
    }
  }, [value, chartValue])
  const chartValueAsFraction = useMemo(() => {
    const value = tryParseAmount(chartValue?.toString(), Ether.onChain(1))
    return value?.asFraction
  }, [chartValue])

  return (
    <AccountWrapper>
      <PageTitle title={'Dolomite | Account'} />
      <AccountHeader>
        <AccountIcon />
        <AddressWrapper>
          {address.substring(0, 6)}...{address.substring(address.length - 5)}
        </AddressWrapper>
      </AccountHeader>
      <LineChart
        data={profitData}
        loading={positionLoading}
        height={160}
        minHeight={160}
        color={theme.blue1}
        value={chartValue}
        label={label}
        setValue={setChartValue}
        setLabel={setLabel}
        disableAxis={true}
        topLeft={
          <ChartLabel>
            <ChartTextLarge>
              <ChartValue>{`$${formatAmount(chartValueAsFraction) ?? 0}`}</ChartValue>
            </ChartTextLarge>
            <ChartText>{label ? <ChartValue>{label} (UTC)</ChartValue> : null}</ChartText>
          </ChartLabel>
        }
      />
      <TableWrapper>
        <TableHeader>
          <TableTitle>{t('opened')}</TableTitle>
        </TableHeader>
        <Table positions={open} loading={openLoading} open={true} rows={5} account />
      </TableWrapper>
      <TableWrapper>
        <TableHeader>
          <TableTitle>{t('settled')}</TableTitle>
          <WinLoss>
            <Subtitle>{t('won')}</Subtitle>
            <Win>{wins}</Win>
            <Subtitle>{t('lost')}</Subtitle>
            <Loss>{losses}</Loss>
          </WinLoss>
        </TableHeader>
        <Table positions={sortedSettled} loading={positionLoading} rows={5} account />
      </TableWrapper>
      <FooterSpacing />
    </AccountWrapper>
  )
}
