import { Web3CallbackState } from './useTradeCallback'
import { estimateGasAsync, SuccessfulContractCall, useActiveWeb3React } from './index'
import { useIsolationModeVaultFactoryContract } from './useContract'
import { useTransactionAdder } from '../state/transactions/hooks'
import { useMemo } from 'react'
import { calculateGasMargin } from '../utils'
import { USER_ERROR_CODES, USER_ERROR_MESSAGES } from '../constants'
import { Token } from '@dolomite-exchange/v2-sdk'
import cleanCurrencySymbol from '../utils/cleanCurrencySymbol'

export function useCreateVaultIsolationModeVault(
  isolationModeToken: Token | undefined,
): { state: Web3CallbackState; callback: null | (() => Promise<string>); error: string | null } {
  const { account } = useActiveWeb3React()
  const factoryContract = useIsolationModeVaultFactoryContract(isolationModeToken?.address)
  const addTransaction = useTransactionAdder()

  return useMemo(() => {
    if (!factoryContract || !account) {
      return {
        state: Web3CallbackState.INVALID,
        callback: null,
        error: 'Missing dependencies',
      }
    }

    const contract = factoryContract
    const methodName = 'createVault'
    const params = [account]

    return {
      state: Web3CallbackState.VALID,
      callback: async function onCreateVault(): Promise<string> {
        const estimatedCall = await estimateGasAsync(contract, methodName, params)
        let successfulCall: SuccessfulContractCall
        if ('gasEstimate' in estimatedCall) {
          successfulCall = estimatedCall
        } else {
          throw estimatedCall.error
        }

        return contract[methodName](...params, {
          gasLimit: calculateGasMargin(successfulCall.gasEstimate),
          from: account,
        })
          .then((response: any) => {
            const summary = `Create Dolomite Vault for ${cleanCurrencySymbol(isolationModeToken)}`
            addTransaction(response, { summary })
            return response.hash
          })
          .catch((error: any) => {
            // if the user rejected the tx, pass this along
            if (error?.code === USER_ERROR_CODES.REJECTED) {
              throw new Error(USER_ERROR_MESSAGES.REJECTED)
            } else {
              // otherwise, the error was unexpected, and we need to convey that
              console.error('Create Dolomite Vault failed', error, methodName, params)
              throw new Error(`Create Dolomite Vault failed: ${error.message}`)
            }
          })
      },
      error: null,
    }
  }, [factoryContract, account, isolationModeToken, addTransaction])
}
