import { Token } from '@dolomite-exchange/v2-sdk'
import { Web3CallbackState } from '../useTradeCallback'
import { estimateGasAsync, SuccessfulContractCall, useActiveWeb3React } from '../index'
import useIsolationModeUserVaultAddressIfCreated from '../useIsolationModeUserVaultAddressIfCreated'
import { useDytGlpUserVaultContract } from '../useContract'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { useMemo } from 'react'
import { USER_ERROR_CODES } from '../../constants'
import { calculateGasMargin } from '../../utils'
import { useDytGLPSpecialAsset } from '../../constants/isolation/special-assets'
import { Fraction } from '@dolomite-exchange/sdk-core'
import { useTokenBalance } from '../../state/wallet/hooks'

export function useYtGlpTotalBalance(): Fraction | undefined {
  const { account, chainId } = useActiveWeb3React()
  const dytGlp = useDytGLPSpecialAsset()
  const ytGlp = useYtGlp()
  const proxyVault = dytGlp.isolationModeInfo?.remapAccountAddress?.(account, chainId)
  const balance = useTokenBalance(proxyVault, ytGlp)
  return useMemo(() => balance?.asFraction, [balance])
}

export function useClaimYtGlpRewards(): {
  state: Web3CallbackState
  callback: null | (() => Promise<string>)
  error: string | null
} {
  const { account } = useActiveWeb3React()
  const dytGlp = useDytGlp()
  const vaultAddress = useIsolationModeUserVaultAddressIfCreated(dytGlp)
  const vaultContract = useDytGlpUserVaultContract(vaultAddress)
  const addTransaction = useTransactionAdder()

  return useMemo(() => {
    if (!vaultContract || !dytGlp || !account) {
      return {
        state: Web3CallbackState.INVALID,
        callback: null,
        error: 'Missing dependencies',
      }
    }

    const contract = vaultContract
    const methodName = 'redeemDueInterestAndRewards'
    const params: any[] = [
      true, // redeemInterest
      true, // redeemRewards
      [true], // depositRewardsIntoDolomite
      false, // depositInterestIntoDolomite
    ]

    return {
      state: Web3CallbackState.VALID,
      callback: async function onCallFunctions(): Promise<string> {
        const estimatedCall = await estimateGasAsync(contract, methodName, params)
        let successfulCall: SuccessfulContractCall
        if ('gasEstimate' in estimatedCall) {
          successfulCall = estimatedCall
        } else {
          throw estimatedCall.error
        }

        return contract[methodName](...params, {
          gasLimit: calculateGasMargin(successfulCall.gasEstimate),
          from: account,
        })
          .then((response: any) => {
            const summary = 'Claim YT-GLP rewards'
            addTransaction(response, { summary })
            return response.hash
          })
          .catch((error: any) => {
            // if the user rejected the tx, pass this along
            if (error?.code === USER_ERROR_CODES.REJECTED) {
              throw new Error('transaction-rejected')
            } else {
              // otherwise, the error was unexpected, and we need to convey that
              console.error('Call function failed:', error, methodName, params)
              throw new Error(`Call function failed: ${error.message}`)
            }
          })
      },
      error: null,
    }
  }, [vaultContract, dytGlp, account, addTransaction])
}

function useDytGlp(): Token | undefined {
  const { chainId } = useActiveWeb3React()
  const dytGlp = useDytGLPSpecialAsset()
  return useMemo(() => dytGlp.isolationModeInfo?.getWrappedToken(chainId), [chainId, dytGlp])
}

function useYtGlp(): Token | undefined {
  const { chainId } = useActiveWeb3React()
  const dytGlp = useDytGLPSpecialAsset()
  return useMemo(() => dytGlp.isolationModeInfo?.getUnderlyingToken(chainId), [chainId, dytGlp])
}
