import { Fraction } from '@dolomite-exchange/sdk-core'
import { useMemo } from 'react'
import { createFraction, createFractionOpt, UserLifetime as UserLifetimeGql } from './gqlTypeHelpers'
import { gql } from '@apollo/client'
import { NO_VARIABLES, useGraphqlResult } from '../state/graphql/hooks'
import { GraphqlClientType } from '../state/graphql/actions'
import { RefreshFrequency } from '../state/chain/hooks'
import { DOLOMITE_API_SERVER_URL } from '@dolomite-exchange/zap-sdk'

const USER_LIFETIME_BORROW_POSITION_COUNT_DATA_DATA = gql`
  query userLifetimeData($account: String!, $blockNumber: Int!) {
    users(where: { id: $account }, block: { number_gte: $blockNumber }) {
      totalBorrowPositionCount
    }
  }
`

interface UserLifetimeResponse {
  users: UserLifetimeGql[]
}

export interface UserLifetimeData {
  totalBorrowPositionCount: Fraction
  totalBorrowVolumeOriginatedUSD: Fraction
  totalCollateralLiquidatedUSD: Fraction
  totalLiquidationCount: Fraction
  totalMarginPositionCount: Fraction
  totalTradeCount: Fraction
  totalTradeVolumeUSD: Fraction
  totalZapCount: Fraction
  totalZapVolumeUSD: Fraction
  totalBorrowDurationSeconds: Fraction
}

export function useUserLifetimeBorrowPositionCount(
  account?: string,
): {
  loading: boolean
  error: boolean
  data: Fraction | undefined
} {
  const variables = useMemo(() => {
    if (!account) {
      return undefined
    }
    return {
      account: account.toLowerCase(),
    }
  }, [account])

  const queryState = useGraphqlResult<UserLifetimeResponse>(
    GraphqlClientType.Dolomite,
    USER_LIFETIME_BORROW_POSITION_COUNT_DATA_DATA.loc!.source.body,
    variables,
    RefreshFrequency.Fast,
  )

  return useMemo(() => {
    const { loading, error, result } = queryState
    const anyLoading = Boolean(loading)
    const anyError = Boolean(error)

    const users = (result?.users ?? []).map<Fraction>(gql => {
      return createFraction(gql.totalBorrowPositionCount)
    })

    return {
      loading: anyLoading,
      error: anyError,
      data: users[0],
    }
  }, [queryState])
}

export function useMineralDataOnAllNetworks(
  account?: string,
): {
  loading: boolean
  error: boolean
  data: Fraction | undefined
} {
  const url = useMemo(() => {
    if (!account) {
      return undefined
    }
    return `${DOLOMITE_API_SERVER_URL}/milestones/${account}/mineral`
  }, [account])

  const queryState = useGraphqlResult<{ amount: string | null }>(
    GraphqlClientType.Fetch,
    url,
    NO_VARIABLES,
    RefreshFrequency.Medium,
  )

  return useMemo(() => {
    const anyLoading = queryState.loading
    const anyError = queryState.error

    const amount = createFractionOpt(queryState.result?.amount ?? undefined)
    return {
      loading: anyLoading,
      error: anyError,
      data: amount,
    }
  }, [queryState])
}

export function useUserLifetimeDataOnAllNetworks(
  account?: string,
): {
  loading: boolean
  error: boolean
  data: UserLifetimeData | undefined
} {
  const url = useMemo(() => {
    if (!account) {
      return undefined
    }
    return `${DOLOMITE_API_SERVER_URL}/milestones/${account}/lifetime-data`
  }, [account])

  const queryState = useGraphqlResult<UserLifetimeGql>(
    GraphqlClientType.Fetch,
    url,
    NO_VARIABLES,
    RefreshFrequency.Medium,
  )

  return useMemo(() => {
    const anyLoading = queryState.loading
    const anyError = queryState.error

    const user = queryState.result
    let lifetimeData: UserLifetimeData | undefined
    if (user) {
      lifetimeData = {
        totalBorrowPositionCount: createFraction(user.totalBorrowPositionCount),
        totalBorrowVolumeOriginatedUSD: createFraction(user.totalBorrowVolumeOriginatedUSD),
        totalCollateralLiquidatedUSD: createFraction(user.totalCollateralLiquidatedUSD),
        totalLiquidationCount: createFraction(user.totalLiquidationCount),
        totalMarginPositionCount: createFraction(user.totalMarginPositionCount),
        totalTradeCount: createFraction(user.totalTradeCount),
        totalTradeVolumeUSD: new Fraction(0) /*createFraction(user.totalTradeVolumeUSD)*/,
        totalZapCount: createFraction(user.totalZapCount),
        totalZapVolumeUSD: createFraction(user.totalZapVolumeUSD),
        totalBorrowDurationSeconds: createFraction(user.totalBorrowDurationSeconds),
      }
    }

    return {
      loading: anyLoading,
      error: anyError,
      data: lifetimeData,
    }
  }, [queryState])
}
