import React, { useEffect, useState } from 'react'
import { useActiveWeb3React } from '../../hooks'
import { useTranslation } from 'react-i18next'
import { useInterestRateData } from '../../types/interestRateData'
import { useAllActiveTokensArray } from '../../hooks/Tokens'
import { useDolomiteBalancesWithLoadingIndicator } from '../../state/wallet/hooks'
import { useFiatValuesWithLoadingIndicator } from '../../hooks/useFiatValue'
import styled from 'styled-components/macro'
import { FORMATTER, ZERO_FRACTION, ZERO_PERCENT } from '../../constants'
import { Percent } from '@dolomite-exchange/sdk-core'
import { formatAmount } from '../../utils/formatAmount'
import { useShowYieldAsApr } from '../../state/user/hooks'
import Web3StatusConnectButton from '../Web3StatusConnectButton'

const TotalWrapper = styled.div<{ noWallet: boolean }>`
  width: 100%;
  display: inline-block;
  vertical-align: top;
  text-align: left;
  ${({ noWallet }) =>
    noWallet &&
    `
    height: 220px;
    margin-top: 40px;
  `};
  @media screen and (max-width: 919px) {
    margin-bottom: 20px;
  }

  @media screen and (max-width: 500px) {
    margin-bottom: 15px;
  }

  @media screen and (max-width: 719px) {
    width: 40%;
  }

  @media screen and (max-width: 700px) {
    text-align: center;
    width: 100%;
  }
`

const TotalInner = styled.div<{ hasAccount?: boolean }>`
  margin: 20px auto 0;
  ${({ hasAccount }) =>
    hasAccount &&
    `
    pointer-events: none;
    display: none;
  `}
`

const TotalText = styled.div`
  font-size: 18px;
  font-weight: 100;
`

const ConnectWalletButton = styled.div`
  margin-top: 15px;
  color: white !important;
`

const ConnectBtn = styled.div`
  display: inline-block;
`

const TotalDeposited = styled.div`
  font-size: 42px;
`

const InterestRateWrapper = styled.div`
  font-size: 18px;
  font-weight: 100;
`

const InterestAmount = styled.span<{ interestAmount: boolean }>`
  ${({ interestAmount, theme }) =>
    interestAmount &&
    `
    color: ${theme.green1};
    font-weight: 600;
    span {
      color: #8fc942;
      font-weight: 600;
    }
  `}
`

const HelperText = styled.div`
  color: #d5d6e1;
  font-size: 15px;
  font-weight: 100;
  margin: 20px 0 0;
  width: 80%;

  @media screen and (max-width: 700px) {
    margin: 20px auto 0;
  }

  a {
    font-weight: 600;
    line-height: 30px;
    text-decoration: none !important;
    color: #606375 !important;
  }
`

export default function BalancesTotal() {
  const { t } = useTranslation()
  const { account } = useActiveWeb3React()
  const [totalDeposited, setTotalDeposited] = useState(ZERO_FRACTION)
  const [netInterest, setNetInterest] = useState(ZERO_PERCENT)
  const { data: interestRateMap } = useInterestRateData()
  const tokenList = useAllActiveTokensArray()
  const [balanceData] = useDolomiteBalancesWithLoadingIndicator(account, tokenList)
  const [fiatData] = useFiatValuesWithLoadingIndicator(balanceData, tokenList)
  const [showYieldAsApr] = useShowYieldAsApr()
  const yieldText = showYieldAsApr ? t('APR') : t('APY')

  useEffect(() => {
    let totalDeposited = ZERO_FRACTION
    let netInterest = ZERO_FRACTION
    Object.keys(fiatData).forEach(tokenAddress => {
      const apr = interestRateMap[tokenAddress]?.totalSupplyInterestRate ?? ZERO_PERCENT
      const fiatValueDef = fiatData[tokenAddress] ?? ZERO_FRACTION
      if (fiatValueDef.greaterThan(ZERO_FRACTION)) {
        // exclude any debt (which is negative)
        netInterest = netInterest.add(fiatValueDef.multiply(apr))
      }
      totalDeposited = totalDeposited.add(fiatValueDef)
    })
    setTotalDeposited(totalDeposited)
    setNetInterest(
      totalDeposited.greaterThan('0')
        ? Percent.fromFraction(netInterest.asFraction.divide(totalDeposited.asFraction))
        : ZERO_PERCENT,
    )
  }, [fiatData, interestRateMap])
  return (
    <TotalWrapper noWallet={!account}>
      {account ? (
        <TotalInner>
          <TotalText>{t('youHaveTitle')}</TotalText>
          <TotalDeposited>${totalDeposited.toFixed(2, FORMATTER)}</TotalDeposited>
          <InterestRateWrapper>
            {t('depositedEarning')}{' '}
            <InterestAmount interestAmount={netInterest.greaterThan(ZERO_PERCENT)}>
              {formatAmount(netInterest, 2, true, '0%')}
            </InterestAmount>
            {` ${yieldText}`}
          </InterestRateWrapper>
          <HelperText>
            {t('earnInterest')}
            <br />
            <a href='https://docs.dolomite.io/dolomite-balances' target={'_blank'} rel={'noopener noreferrer'}>
              {t('learnMore')}
            </a>
          </HelperText>
        </TotalInner>
      ) : (
        <TotalInner>
          <TotalText>{t('connectWalletText')}</TotalText>
          <ConnectWalletButton>
            <ConnectBtn key={'accountItem-StartTrading'}>
              <Web3StatusConnectButton />
            </ConnectBtn>
          </ConnectWalletButton>
          <HelperText>
            {t('earnInterest')}
            <br />
            <a href='https://docs.dolomite.io/dolomite-balances' target={'_blank'} rel={'noopener noreferrer'}>
              {t('learnMore')}
            </a>
          </HelperText>
        </TotalInner>
      )}
    </TotalWrapper>
  )
}
