import { CurrencyAmount, Token } from '@dolomite-exchange/v2-sdk'
import { useMemo } from 'react'

import { useTokenContract } from '../hooks/useContract'
import { useMultipleContractSingleData, useSingleCallResult } from '../state/multicall/hooks'
import ERC20_INTERFACE from '../constants/abis/erc20'
import remapTokenAddressForAllowance from '../utils/isolation/remapTokenAddressForAllowance'
import { useSerializedTokenOpt, useSerializedTokensOpt } from '../hooks/Tokens'
import { ChainId, MAX_UINT_256, WMNT_ISOLATION_MODE_ADDRESSES } from '../constants'

export function useTokenAllowance(token?: Token, owner?: string, spender?: string): CurrencyAmount<Token> | undefined {
  const cachedToken = useSerializedTokenOpt(token)
  const remappedTokenAddress = useMemo(
    () => (cachedToken ? remapTokenAddressForAllowance(cachedToken.wrapped) : undefined),
    [cachedToken],
  )
  const contract = useTokenContract(remappedTokenAddress, false)

  const inputs = useMemo(() => [owner, spender], [owner, spender])
  const allowance = useSingleCallResult(contract, 'allowance', inputs).result?.toString()

  return useMemo(() => (cachedToken && allowance ? CurrencyAmount.fromRawAmount(cachedToken, allowance) : undefined), [
    cachedToken,
    allowance,
  ])
}

export function useTokenAllowances(
  tokens?: Token[],
  owner?: string,
  spender?: string,
): [Record<string, CurrencyAmount<Token> | undefined>, boolean] {
  const cachedTokens = useSerializedTokensOpt(tokens)
  const contracts = useMemo(() => cachedTokens?.map(token => remapTokenAddressForAllowance(token)) ?? [], [
    cachedTokens,
  ])
  const inputs = useMemo(() => [owner, spender], [owner, spender])
  const callStates = useMultipleContractSingleData(contracts, ERC20_INTERFACE, 'allowance', inputs)

  return useMemo(() => {
    const allowanceMap = cachedTokens?.reduce<Record<string, CurrencyAmount<Token> | undefined>>(
      (memo, token, index) => {
        const allowance = callStates[index]?.result
        if (token?.chainId === ChainId.MANTLE && token.address === WMNT_ISOLATION_MODE_ADDRESSES[ChainId.MANTLE]) {
          memo[token.address] = CurrencyAmount.fromRawAmount(token, MAX_UINT_256.toString())
        } else if (allowance) {
          memo[token.address] = CurrencyAmount.fromRawAmount(token, allowance.toString())
        }
        return memo
      },
      {},
    )
    return [allowanceMap ?? {}, callStates.some(state => state.loading)]
  }, [callStates, cachedTokens])
}
