import { Percent, Token } from '@dolomite-exchange/sdk-core'

interface InterestRatePartMetadata {
  units: number
  unitsLabel: string
}

interface InterestRatePartToken {
  id: string
  marketId: string
  name: string
  symbol: string
}

export enum InterestRatePartCategory {
  REWARDS,
  POINTS,
  NATIVE_YIELD,
  MINERALS,
  NONE,
}

export enum InterestRatePartSubcategory {
  CLAIM,
}

export interface InterestRatePart {
  interestRate: Percent | undefined
  label: string
  isBorrowRateImpacted: boolean | undefined
  metadata: InterestRatePartMetadata | null
  rewardToken: Token | undefined
  category: InterestRatePartCategory
  subcategory: InterestRatePartSubcategory | undefined
  rewardClaimUrl: string | undefined
}

export interface InterestRatePartApi {
  interestRate: string | null
  label: string
  metadata: InterestRatePartMetadata | null
  rewardToken: InterestRatePartToken | null
  category: string
  subcategory: string | null
  rewardClaimUrl: string | null
}
