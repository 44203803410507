import { Percent } from '@dolomite-exchange/sdk-core'
import { ONE_FRACTION } from '../constants'
import { Fraction } from '@dolomite-exchange/v2-sdk'

export default function calculateLiquidationReward(
  liquidationReward: Fraction | undefined,
  liquidationRewardPremium: Fraction | undefined,
): Percent | undefined {
  if (!liquidationReward || !liquidationRewardPremium) {
    return undefined
  }

  const liquidationRewardFraction = liquidationReward
    .subtract(ONE_FRACTION)
    .multiply(liquidationRewardPremium.add(ONE_FRACTION))

  return Percent.fromFraction(liquidationRewardFraction)
}
