import { useMemo } from 'react'
import { CollateralActionType, getTokenListFromType, LoanActionType, ManageType } from '../pages/Borrow/types'
import { useActiveWeb3React } from './index'
import { Token } from '@dolomite-exchange/v2-sdk'
import { BorrowPosition } from '../types/borrowPositionData'
import { deserializeTokens, serializeTokens, useSerializedTokenOpt, useSerializedTokens } from './Tokens'
import useIpGeolocation from './useIpGeolocation'
import { GEOFENCED_TOKENS_MAP } from '../constants/isolation/special-assets'

export default function useBorrowRowDisplayTokenList(
  manageType: ManageType,
  selectedTab: CollateralActionType | LoanActionType,
  position: BorrowPosition,
  tokens: Token[],
  isInputField: boolean,
  isZapActivated: boolean,
  selectedInputToken: Token | undefined,
  selectedOutputToken: Token | undefined,
) {
  const { chainId } = useActiveWeb3React()
  const { error: ipError, isUnitedStatesIp } = useIpGeolocation()
  let serializedTokens = useSerializedTokens(tokens)
  if (
    isUnitedStatesIp &&
    !(
      (manageType === ManageType.COLLATERAL && selectedTab === CollateralActionType.WITHDRAW) ||
      (manageType === ManageType.LOAN && selectedTab === LoanActionType.REPAY)
    )
  ) {
    serializedTokens = serializedTokens.filter(token => !GEOFENCED_TOKENS_MAP[chainId][token.address])
  }
  const serializedSupplyTokens = useMemo(() => serializeTokens(position.supplyAmounts.map(value => value.token)), [
    position,
  ])
  const serializedBorrowTokens = useMemo(() => serializeTokens(position.borrowAmounts.map(value => value.token)), [
    position,
  ])
  const serializedSelectedInputToken = useSerializedTokenOpt(selectedInputToken)
  const serializedSelectedOutputToken = useSerializedTokenOpt(selectedOutputToken)
  return useMemo(() => {
    const deserializedSupplyTokens = deserializeTokens(serializedSupplyTokens)
    const deserializedBorrowTokens = deserializeTokens(serializedBorrowTokens)
    return getTokenListFromType(
      manageType,
      selectedTab,
      position.specialInfo.specialAsset,
      serializedTokens,
      deserializedBorrowTokens,
      deserializedSupplyTokens,
      chainId,
      isInputField,
      isZapActivated,
      serializedSelectedInputToken,
      serializedSelectedOutputToken,
    )
  }, [
    chainId,
    serializedSelectedInputToken,
    serializedSelectedOutputToken,
    isInputField,
    isZapActivated,
    manageType,
    position.specialInfo.specialAsset,
    serializedBorrowTokens,
    serializedSupplyTokens,
    serializedTokens,
    selectedTab,
  ])
}
