import { useMemo } from 'react'
import { AssetDenomination, Currency, CurrencyAmount, Pair } from '@dolomite-exchange/v2-sdk'
import { abi as DOLOMITE_AMM_PAIR_ABI } from '../constants/abis/dolomite-amm-pair.json'
import { Interface } from '@ethersproject/abi'
import { useActiveWeb3React } from '../hooks'

import { useMultipleContractSingleData } from '../state/multicall/hooks'
import { wrappedCurrency } from '../utils/wrappedCurrency'
import { ChainId } from '../constants'

const PAIR_INTERFACE = new Interface(DOLOMITE_AMM_PAIR_ABI)

export enum PairState {
  LOADING,
  NOT_EXISTS,
  EXISTS,
  INVALID,
}

export function usePairs(
  currencies: [Currency | undefined, Currency | undefined][],
  denomination: AssetDenomination | undefined,
): [PairState, Pair | undefined][] {
  const { chainId } = useActiveWeb3React()

  const tokens = useMemo(
    () =>
      currencies.map(([currencyA, currencyB]) => [
        wrappedCurrency(currencyA, chainId),
        wrappedCurrency(currencyB, chainId),
      ]),
    [chainId, currencies],
  )

  const pairAddresses = useMemo(
    () =>
      tokens.map(([tokenA, tokenB]) => {
        return tokenA && tokenB && !tokenA.equals(tokenB) && chainId === ChainId.ARBITRUM_ONE
          ? Pair.getAddress(tokenA, tokenB, chainId)
          : undefined
      }),
    [tokens, chainId],
  )

  const methodName = useMemo(() => {
    return denomination === AssetDenomination.Par ? 'getReservesPar' : 'getReservesWei'
  }, [denomination])

  const results = useMultipleContractSingleData(pairAddresses, PAIR_INTERFACE, methodName)

  return useMemo(() => {
    return results.map((result, i) => {
      const { result: reserves, loading, error } = result
      const tokenA = tokens[i][0]
      const tokenB = tokens[i][1]

      if (loading) return [PairState.LOADING, undefined]
      if (!tokenA || !tokenB || tokenA.equals(tokenB)) return [PairState.INVALID, undefined]
      if (!reserves || error) return [PairState.NOT_EXISTS, undefined]
      const { reserve0, reserve1 } = reserves
      const [token0, token1] = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA]
      return [
        PairState.EXISTS,
        new Pair(
          CurrencyAmount.fromRawAmount(token0, reserve0.toString()),
          CurrencyAmount.fromRawAmount(token1, reserve1.toString()),
          chainId,
        ),
      ]
    })
  }, [results, tokens, chainId])
}

export function usePair(
  tokenA?: Currency,
  tokenB?: Currency,
  denomination?: AssetDenomination,
): [PairState, Pair | undefined] {
  const currencies: [Currency | undefined, Currency | undefined][] = useMemo(() => [[tokenA, tokenB]], [tokenA, tokenB])
  return usePairs(currencies, denomination)[0]
}
