import { WST_ETH } from '../../index'
import { SpecialAsset, SpecialAssetSymbol } from '../special-assets'
import mapChainIdMapValues from '../../../utils/mapChainIdMapValues'

export const WST_ETH_SPECIAL_ASSET: () => SpecialAsset = () => ({
  symbol: SpecialAssetSymbol.wstETH,
  safeSymbol: SpecialAssetSymbol.wstETH,
  cleanSymbol: 'wstETH',
  cleanName: 'Wrapped Staked ETH',
  hasExternalRewards: false,
  documentationUrl: undefined,
  isIsolationMode: false,
  isBorrowingEnabled: true,
  chainIdToAddressMap: mapChainIdMapValues(WST_ETH, token => token?.address),
  isolationModeInfo: undefined,
})
