import { AmmMintOrBurn } from '../../../types/apiTypeHelpers'
import { useTranslation } from 'react-i18next'
import React, { useContext, useState } from 'react'
import { useActiveWeb3React } from '../../../hooks'
import useDebounce from '../../../hooks/useDebounce'
import Column from '../../Orders/Column'
import { TableLoader } from '../../Loader'
import { ExternalLink } from '../../../theme'
import { getEtherscanLink } from '../../../utils'
import cleanCurrencySymbol from '../../../utils/cleanCurrencySymbol'
import { formatAmount } from '../../../utils/formatAmount'
import toDate from '../../../utils/toDate'
import { Arrow, Backward, Forward, PageSelect } from '../styled'
import { ColumnTitles, StatRows, StatTable } from '../Overview'
import styled from 'styled-components/macro'
import { PageSizeContext } from '../../../pages/App'

const EmptySetWrapper = styled.div`
  color: ${({ theme }) => theme.text3};
  margin: 20px auto;
  width: 275px;
  text-align: center;
`

const TransactionRow = styled.div`
  width: 100%;
  height: 36px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;

  a {
    text-decoration: none !important;

    :hover {
      color: ${({ theme }) => theme.text2} !important;
    }
  }

  @media screen and (max-width: 550px) {
    height: 45px;
  }
`

const mintOrBurnWidths = {
  widths: [10, 10, 10, 10, 10],
  starts: [0, 32, 53, 74, 90],
  mobileWidths: [25, 25, 15],
  mobileStarts: [0, 45, 85],
}

export default function MintOrBurnTable({
  mintsOrBurns,
  isLoading,
  isMints,
}: {
  mintsOrBurns: AmmMintOrBurn[]
  isLoading: boolean
  isMints: boolean
}) {
  const { t } = useTranslation()
  const [page, setPage] = useState(1)
  const lastPage = Math.max(1, Math.ceil(mintsOrBurns.length / 10))
  const { chainId } = useActiveWeb3React()
  const debouncedIsLoading = useDebounce(isLoading, 300)

  const { x, y } = useContext(PageSizeContext)
  const isMobile = x < 660
  const isTablet = 660 < x && x < 960

  return (
    <StatTable>
      <ColumnTitles>
        <Column width={22} mobileWidth={20} textAlign={'left'}>
          {t('action')}
        </Column>
        <Column width={20} hideOnMobile={true} textAlign={'right'}>
          {t('totalValue')}
        </Column>
        <Column width={21} hideOnMobile={true} textAlign={'right'}>
          {t('amount')}
        </Column>
        <Column width={21} mobileWidth={50} textAlign={'right'}>
          {isMobile ? t('amounts') : t('amount')}
        </Column>
        <Column width={16} mobileWidth={30} textAlign={'right'}>
          {t('date')}
        </Column>
      </ColumnTitles>
      <StatRows>
        {debouncedIsLoading && mintsOrBurns.length === 0 ? (
          <TableLoader
            rows={isMobile ? 5 : isTablet ? 4 : 3}
            height={30}
            spacing={55}
            marginTop={20}
            isMobile={isMobile}
            isTablet={isTablet}
            widths={mintOrBurnWidths}
          />
        ) : mintsOrBurns.length === 0 ? (
          isMints ? (
            <EmptySetWrapper>No mints have occurred yet</EmptySetWrapper>
          ) : (
            <EmptySetWrapper>No burns have occurred yet</EmptySetWrapper>
          )
        ) : (
          mintsOrBurns.slice(10 * (page - 1), 10 * page).map((action, index) => (
            <TransactionRow key={index}>
              <Column width={22} mobileWidth={20} textAlign={'left'}>
                <ExternalLink href={getEtherscanLink(chainId, action.transaction.transactionHash, 'transaction')}>
                  {`${action.isMint ? 'Add' : 'Withdraw'} ${
                    isMobile ? '' : `${cleanCurrencySymbol(action.token0)} and ${cleanCurrencySymbol(action.token1)}`
                  }`}
                </ExternalLink>
              </Column>
              <Column width={20} hideOnMobile={true} textAlign={'right'}>
                {formatAmount(action.amountUSD, undefined, true, '$0.00', true)}
              </Column>
              <Column width={21} mobileWidth={50} textAlign={'right'}>
                <div>{`${formatAmount(action.amount0, undefined, true, '0.00')} ${cleanCurrencySymbol(
                  action.token0,
                )}`}</div>
                <div>
                  {isMobile &&
                    `${formatAmount(action.amount1, undefined, true, '0.00')} ${cleanCurrencySymbol(action.token1)}`}
                </div>
              </Column>
              <Column width={21} hideOnMobile={true} textAlign={'right'}>
                {formatAmount(action.amount1, undefined, true, '0.00')} {cleanCurrencySymbol(action.token1)}
              </Column>
              <Column width={16} mobileWidth={30} textAlign={'right'}>
                {toDate(action.transaction.timestamp)}
              </Column>
            </TransactionRow>
          ))
        )}
        <PageSelect>
          <Arrow onClick={() => setPage(page === 1 ? page : page - 1)} faded={page === 1}>
            <Backward />
          </Arrow>
          {page}
          <Arrow onClick={() => setPage(page === lastPage ? page : page + 1)} faded={page === lastPage}>
            <Forward />
          </Arrow>
        </PageSelect>
      </StatRows>
    </StatTable>
  )
}
