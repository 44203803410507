import { useMemo } from 'react'
import useENSNameList from './useENSNameList'

/**
 * Does a lookup for an ENS name to find its content hash.
 */
export default function useENSName(
  address: string | undefined,
): {
  loading: boolean
  ensName: string | null | undefined
} {
  const addresses = useMemo(() => (address ? [address] : undefined), [address])
  const { loading, ensNames } = useENSNameList(addresses)
  const ensName = ensNames?.[0]
  return useMemo(
    () => ({
      loading,
      ensName,
    }),
    [ensName, loading],
  )
}
