import { GraphqlCall, GraphqlClientType } from '../../state/graphql/actions'
import { ChainId } from '../chainId'
import { SPECIAL_ASSET_MAP, SpecialAssetSymbol } from './special-assets'

export const NO_OP = (chainId: ChainId): GraphqlCall => {
  return {
    chainId,
    clientType: GraphqlClientType.NoOp,
    query: null,
    variables: null,
  }
}

export function getOptionally<T, U>(valueOrUndefined: T | undefined, transformer: (value: T) => U): U | undefined {
  if (valueOrUndefined) {
    return transformer(valueOrUndefined)
  }
  return undefined
}

export function getFunctionalOutsideAprCallData(chainId: ChainId, symbol: SpecialAssetSymbol): GraphqlCall {
  return {
    chainId,
    clientType: GraphqlClientType.Functional,
    query: null,
    variables: SPECIAL_ASSET_MAP[symbol]!.chainIdToAddressMap[chainId]!,
  }
}
