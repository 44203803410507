import { Percent } from '@dolomite-exchange/sdk-core'
import { useMemo } from 'react'
import sumPairHourData from '../utils/pairHourDataOperations'
import { DAYS_IN_YEAR, LP_FEES, ZERO_PERCENT } from '../constants'
import { Pair } from '@dolomite-exchange/v2-sdk'
import { AmmPairHourData } from '../types/ammPairHourData'

export default function useAmmApr(pair: Pair | undefined, pair24HourData: AmmPairHourData[]): Percent {
  return useMemo(() => {
    if (!pair || pair24HourData.length == 0) {
      return ZERO_PERCENT
    }

    const summed = sumPairHourData(pair, pair24HourData)
    if (summed.reserveUSD.equalTo('0')) {
      return ZERO_PERCENT
    }

    return Percent.fromFraction(
      summed.volumeUSD
        .multiply(LP_FEES)
        .multiply(DAYS_IN_YEAR)
        .divide(summed.reserveUSD),
    )
  }, [pair, pair24HourData])
}
