import React, { useState } from 'react'
import styled from 'styled-components/macro'

const TOSPopoverWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
`

const TOSPopoverInner = styled.div`
  width: 600px;
  max-width: 90%;
  height: fit-content;
  border-radius: 8px;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
  background: ${({ theme }) => theme.bg2};
  margin: 25vh auto 0;
  overflow: hidden;
  padding: 20px 30px;
`

const TOSPopoverInnerMobile = styled(TOSPopoverInner)`
  width: 80%;
  max-width: 400px;
  min-width: 280px;
  padding: 15px 20px;

  > div > button {
    width: 100%;
    cursor: pointer;
    display: inline-block;
    vertical-align: top;
    border: none;
    height: 49px;
    font-size: 18px;
    line-height: 22px;
    padding: 12px 28px;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.green1} !important;
    color: ${({ theme }) => theme.text1} !important;
    margin: 0 auto 10px !important;
  }
`

const Left = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 300px;
  height: 350px;
  overflow: hidden;
  background: black;

  img {
    margin-left: -70px;
    opacity: 0.85;
    height: 100%;
    width: auto;
  }
`

const Right = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 450px;
  height: 350px;
  padding: 20px 30px;
`

const Title = styled.div`
  font-size: 32px;
  font-weight: 500;
  margin-bottom: 20px;
`

const Subtitle = styled.div`
  font-size: 15px;
  font-weight: 200;
  margin-bottom: 15px;
  line-height: 21px;

  a {
    color: ${({ theme }) => theme.text1};
    font-weight: 700;
    text-decoration: none;

    :hover {
      color: ${({ theme }) => theme.text2};
    }
  }
`

const Subheader = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 6px;
`

const InfoPoint = styled.div`
  font-size: 15px;
`

const StartButtonsWrapper = styled.div<{ disabled: boolean }>`
  width: 100%;
  text-align: right;
  margin-top: 20px;
  margin-bottom: 10px;
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'auto')};

  button {
    cursor: pointer;
    display: inline-block;
    vertical-align: top;
    border: none;
    height: 34px;
    font-size: 15px;
    line-height: 22px;
  }

  button {
    padding: 6px 12px;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.green1};
    color: ${({ theme }) => theme.text1};
  }
`

const GuideWrapper = styled.div`
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  pointer-events: none;
`

const Guide = styled.div`
  transition: all 0.4s ease-in-out;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 99999;
  pointer-events: all;
`

const GuideTop = styled(Guide)`
  top: 0;
  left: 0;
`

const GuideRight = styled(Guide)`
  right: 0;
`

const GuideBottom = styled(Guide)`
  bottom: 0;
  left: 0;
`

const GuideLeft = styled(Guide)`
  left: 0;
`

const GuideBox = styled.div`
  width: 310px;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.01), 0 4px 8px rgba(0, 0, 0, 0.04), 0 16px 24px rgba(0, 0, 0, 0.04),
    0 24px 32px rgba(0, 0, 0, 0.01);
  background: ${({ theme }) => theme.bg2};
  padding: 20px 25px;
  color: ${({ theme }) => theme.text1};
  position: fixed;
  z-index: 999999;
  transition: all 0.4s ease-in-out;
  pointer-events: all;
`

const GuideContent = styled.div`
  font-size: 14px;
  font-weight: 300;
  width: 100%;
  height: fit-content;
`

const GuideButton = styled.div`
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;

  button:nth-of-type(1) {
    color: ${({ theme }) => theme.text3};
    font-size: 14px;
    background: none;
    border: none;
    cursor: pointer;
  }

  button:nth-of-type(2) {
    padding: 6px 12px;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.green1};
    color: ${({ theme }) => theme.text1};
    border: none;
    cursor: pointer;
  }
`

const TOS = styled.div<{ hidden: boolean }>`
  width: 100%;
  display: block;
  visibility: ${({ hidden }) => (hidden ? 'hidden' : 'visible')};

  a {
    font-weight: 700;
  }
`

const Checkbox = styled.div`
  display: inline-block;
  vertical-align: top;
  height: 17px;
  width: 17px;
  border: 2px solid #f9f9f9;
  background: none;
  border-radius: 3px;
  cursor: pointer;
  margin-right: 5px;
  margin-top: 1px;
`

const Checked = styled.div<{ isChecked: boolean }>`
  height: 7px;
  width: 7px;
  background: ${({ theme, isChecked }) => (isChecked ? '#f9f9f9' : 'none')};
  border-radius: 1px;
  margin-left: 3px;
  margin-top: 3px;
`

const TOSText = styled.div`
  display: inline-block;
  vertical-align: top;
  font-size: 14px;

  a {
    color: ${({ theme }) => theme.text1};
    text-decoration: none;
    font-weight: 700;
  }

  @media screen and (max-width: 420px) {
    font-size: 12px;
  }
`

const Cookies = styled.div`
  width: 100%;
  font-size: 14px;
  font-weight: 200;
  float: left;
  width: 80%;
  margin-top: 18px;
`

export default function TOSPopover({ setApprovedToS }: { setApprovedToS: () => void }) {
  const [hasApprovedToS, setHasApprovedToS] = useState(false)

  return (
    <TOSPopoverWrapper>
      <TOSPopoverInner>
        <Title>Welcome to Dolomite!</Title>
        <Subtitle>
          {`If you're new to the platform, you can check out our `}
          <a href={'https://docs.dolomite.io/'} target={'_blank'} rel={'noreferrer'}>
            documentation
          </a>{' '}
          and follow our{' '}
          <a href={'https://docs.dolomite.io/QuickStart'} target={'_blank'} rel={'noreferrer'}>
            QuickStart Guide
          </a>
          .
        </Subtitle>
        <TOS hidden={false}>
          <Checkbox onClick={() => setHasApprovedToS(!hasApprovedToS)}>
            <Checked isChecked={hasApprovedToS} />
          </Checkbox>
          <TOSText>
            I agree to the Dolomite{' '}
            <a target={'_blank'} rel={'noreferrer'} href={'https://dolomite.io/Dolomite-Terms-of-Service.pdf'}>
              Terms of Service
            </a>
          </TOSText>
        </TOS>
        <Cookies>
          By proceeding, you agree to the use of a small number of strictly necessary cookies that Dolomite uses to run
          properly.
        </Cookies>
        <StartButtonsWrapper disabled={!hasApprovedToS}>
          <button onClick={() => hasApprovedToS && setApprovedToS()}>Jump In</button>
        </StartButtonsWrapper>
      </TOSPopoverInner>
    </TOSPopoverWrapper>
  )
}
