import React, { useRef, useState, useEffect } from 'react'
import { BookOpen, Code, GitHub, Info, Map, MessageCircle } from 'react-feather'
import styled from 'styled-components/macro'
import { Settings as SettingsIcon, X } from 'react-feather'
import { ReactComponent as MenuIcon } from '../../assets/images/menu.svg'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { ApplicationModal } from '../../state/application/actions'
import { useModalOpen, useToggleModal } from '../../state/application/hooks'
import FirstVisitPopover from '../FirstVisitPopover/FirstVisitPopover'
import ChainalysisIcon from '../../assets/images/chainalysis-icon.png'

import { ExternalLink } from '../../theme'
import { AutoColumn } from '../Column'
import { Text } from 'rebass'
import TransactionSettings from '../TransactionSettings'
import { useUserSlippageTolerance, useUserTransactionTTL } from '../../state/user/hooks'

const StyledMenuIcon = styled(SettingsIcon)`
  height: 20px;
  width: 20px;

  > * {
    stroke: ${({ theme }) => theme.text2};
  }
`

const StyledMenuButton = styled.button`
  width: 100%;
  border: none;
  margin: 0;
  height: 40px;
  background-color: ${({ theme }) => theme.bg2};
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);

  padding: 0.15rem 0.6rem;
  border-radius: 5px;

  :hover,
  :focus {
    cursor: pointer;
    outline: none;
    background-color: #3a3a4f;
  }

  svg {
    margin-top: 2px;
  }
`

const StyledMenu = styled.div`
  margin-left: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  text-align: left;
  position: absolute;
  right: 64px;

  @media screen and (max-width: 1000px) {
    right: 112px;
  }

  @media (max-width: 660px) {
    position: absolute;
    right: 64px;
    top: 8px;
    width: 48px;
  }
`

const FlyoutWrapper = styled.div<{ isOpen: boolean }>`
  > span {
    top: 50px;
    transition: opacity 0.3s ease-in-out;
    opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  }
  pointer-events: ${({ isOpen }) => (isOpen ? 'auto' : 'none')};

  ${({ theme, isOpen }) => theme.mediaWidth.upToSmall`
    overflow: hidden;
    position: absolute;
    width: 320px;
    height: 330px;
    right: -55px;
    top: 47px;
    
    > span {
      top: 0;
    }
  `};
`

const MenuFlyout = styled.span`
  min-width: 320px;
  background-color: ${({ theme }) => theme.bg2};
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
  border-radius: 5px;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  position: absolute;
  top: 4rem;
  right: 0;
  z-index: 100;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    top: -160px;
  `};
`

const MenuItem = styled(ExternalLink)`
  flex: 1;
  padding: 0.5rem 0.5rem;
  color: ${({ theme }) => theme.text2};

  :hover {
    color: ${({ theme }) => theme.text1};
    cursor: pointer;
    text-decoration: none;
  }

  > svg {
    margin-right: 8px;
  }
`

const MenuDiv = styled.div`
  flex: 1;
  padding: 0.5rem 0.5rem;
  color: ${({ theme }) => theme.text2};

  :hover {
    color: ${({ theme }) => theme.text1};
    cursor: pointer;
    text-decoration: none;
  }

  > svg {
    margin-right: 8px;
  }
`

export default function Settings() {
  const node = useRef<HTMLDivElement>()
  const open = useModalOpen(ApplicationModal.SETTINGS)
  const toggle = useToggleModal(ApplicationModal.SETTINGS)
  const [tour, setTour] = useState(false)
  const [size, setSize] = useState({
    x: window.innerWidth,
    y: window.innerHeight,
  })
  const updateSize = () =>
    setSize({
      x: window.innerWidth,
      y: window.innerHeight,
    })
  useEffect(() => {
    window.onresize = updateSize
    return () => {
      window.onresize = null
    }
  }, [])
  const setHasVisited = () => {
    setTour(false)
  }

  const [userSlippageTolerance, setUserslippageTolerance] = useUserSlippageTolerance()
  const [ttl, setTtl] = useUserTransactionTTL()

  const smartContractsLink = 'https://docs.dolomite.io/smart-contract-addresses'
  useOnClickOutside(node, open ? toggle : undefined)
  // const openClaimModal = useToggleModal(ApplicationModal.ADDRESS_CLAIM)

  return (
    // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/30451
    <StyledMenu ref={node as any}>
      <StyledMenuButton onClick={toggle} id='open-settings-dialog-button'>
        <StyledMenuIcon />
      </StyledMenuButton>
      <FlyoutWrapper isOpen={open}>
        <MenuFlyout>
          <AutoColumn gap='md' style={{ padding: '1rem' }}>
            <Text fontWeight={600} fontSize={14}>
              Transaction Settings
            </Text>
            <TransactionSettings
              rawSlippage={userSlippageTolerance}
              setRawSlippage={setUserslippageTolerance}
              deadline={ttl}
              setDeadline={setTtl}
            />
          </AutoColumn>
        </MenuFlyout>
      </FlyoutWrapper>
    </StyledMenu>
  )
}
