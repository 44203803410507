import { Fraction, Percent } from '@dolomite-exchange/sdk-core'

export default function calculateProfitPercentage(initialDeposit: Fraction, equityHeldAmount: Fraction): Percent {
  const initialEquity = initialDeposit.asFraction
  const currentEquity = equityHeldAmount.asFraction
  const delta = currentEquity.lessThan(initialEquity)
    ? initialEquity.subtract(currentEquity).multiply('-1')
    : currentEquity.subtract(initialEquity)
  return Percent.fromFraction(delta.divide(initialEquity))
}
