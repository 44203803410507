import { createReducer } from '@reduxjs/toolkit'
import {
  addGraphqlListeners,
  errorFetchingGraphqlResults,
  fetchingGraphqlResults,
  GraphqlClientType,
  removeGraphqlListeners,
  toGraphqlCallKey,
  updateGraphqlResults,
} from './actions'
import { ChainId, ChainIdMap, initializeObjectChainIdMap } from '../../constants/chainId'

export interface GraphqlStateResult {
  resultOrErrors: {
    error?: string
    data?: string
  }[]
  fetchData: {
    clientType: GraphqlClientType
    chainId: ChainId
    subgraphUrlIndex?: number
  }[]
  timestamp?: number
  fetchingTimestamp?: number
}

export interface GraphqlState {
  // on a per-chain basis
  // stores for each call key the listeners' preferences
  // stores how many listeners there are per each poll interval preference
  callListeners: ChainIdMap<Record<string, Record<number, { listenerCount: number }>>>

  callResults: ChainIdMap<Record<string, GraphqlStateResult>>
}

const initialState: GraphqlState = {
  callListeners: initializeObjectChainIdMap(),
  callResults: initializeObjectChainIdMap(),
}

export default createReducer(initialState, builder =>
  builder
    .addCase(
      addGraphqlListeners,
      (
        state,
        {
          payload: {
            calls,
            chainId,
            options: { refreshFrequency },
          },
        },
      ) => {
        const listeners: GraphqlState['callListeners'] = state.callListeners
        listeners[chainId] = listeners[chainId] ?? {}
        const callKey = toGraphqlCallKey(calls)
        listeners[chainId][callKey] = listeners[chainId][callKey] ?? {}
        listeners[chainId][callKey][refreshFrequency] = listeners[chainId][callKey][refreshFrequency] ?? {
          listenerCount: 0,
        }
        listeners[chainId][callKey][refreshFrequency].listenerCount++
      },
    )
    .addCase(
      removeGraphqlListeners,
      (
        state,
        {
          payload: {
            chainId,
            calls,
            options: { refreshFrequency },
          },
        },
      ) => {
        const listeners: GraphqlState['callListeners'] = state.callListeners
        if (!listeners[chainId]) {
          return
        }

        const callKey = toGraphqlCallKey(calls)
        if (!listeners[chainId][callKey]) return
        if (!listeners[chainId][callKey][refreshFrequency]) return

        if (listeners[chainId][callKey][refreshFrequency].listenerCount === 1) {
          delete listeners[chainId][callKey][refreshFrequency]
        } else {
          listeners[chainId][callKey][refreshFrequency].listenerCount--
        }
      },
    )
    .addCase(fetchingGraphqlResults, (state, { payload: { chainId, fetchingTimestamp, callsList } }) => {
      state.callResults[chainId] = state.callResults[chainId] ?? {}
      callsList.forEach(calls => {
        const callKey = toGraphqlCallKey(calls)
        const current = state.callResults[chainId][callKey]
        if (!current && calls.length > 0) {
          state.callResults[chainId][callKey] = {
            fetchingTimestamp,
            resultOrErrors: calls.map(() => ({})),
            fetchData: calls.map(call => ({
              clientType: call.clientType,
              chainId: call.chainId,
            })),
          }
        } else {
          if ((current.fetchingTimestamp ?? 0) >= fetchingTimestamp) {
            return
          }
          state.callResults[chainId][callKey].fetchingTimestamp = fetchingTimestamp
        }
      })
    })
    .addCase(errorFetchingGraphqlResults, (state, { payload: { fetchingTimestamp, chainId, calls } }) => {
      state.callResults[chainId] = state.callResults[chainId] ?? {}
      const callsKey = toGraphqlCallKey(calls)
      const current = state.callResults[chainId][callsKey]
      if (!current) {
        return // only should be dispatched if we are already fetching
      }

      if (current.fetchingTimestamp === fetchingTimestamp) {
        current.fetchingTimestamp = undefined
      }
    })
    .addCase(updateGraphqlResults, (state, { payload: { chainId, results, timestamp } }) => {
      state.callResults[chainId] = state.callResults[chainId] ?? {}
      Object.keys(results).forEach(callKey => {
        const current = state.callResults[chainId][callKey]
        if ((current.timestamp ?? 0) > timestamp) {
          return
        }
        state.callResults[chainId][callKey] = {
          ...current,
          resultOrErrors: results[callKey],
          fetchData: current.fetchData.map((data, i) => {
            return {
              ...data,
              subgraphUrlIndex: results[callKey][i].subgraphUrlIndex,
            }
          }),
          fetchingTimestamp: undefined,
          timestamp,
        }
      })
    }),
)
