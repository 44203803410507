import gql from 'graphql-tag'
import { AmmPair as AmmPairGql, createFraction } from './gqlTypeHelpers'
import { ammPairGql } from './queryObjects'
import { useMemo } from 'react'
import { Fraction } from '@dolomite-exchange/sdk-core'
import { Token } from '@dolomite-exchange/v2-sdk'
import { useAllActiveTokensArray, useAllTokens } from '../hooks/Tokens'
import { toChecksumAddress } from '../utils/toChecksumAddress'
import { NO_VARIABLES, useGraphqlResult } from '../state/graphql/hooks'
import { GraphqlClientType } from '../state/graphql/actions'
import { RefreshFrequency } from '../state/chain/hooks'
import { useDefaultFiatValuesWithLoadingIndicator } from '../hooks/useFiatValue'
import { InterestIndex } from '../data/InterestIndex'

const All_PAIR_DATA_GQL = gql`
    query allAmmPairData($blockNumber: Int!) {
        ammPairs(
            block: { number_gte: $blockNumber }
        ) {
            ${ammPairGql(false)}
        }
    }
`

interface AmmPairResponse {
  ammPairs: AmmPairGql[]
}

export interface AmmPair {
  address: string
  token0: Token
  token1: Token
  reserve0: Fraction
  reserve1: Fraction
  reserveUSD: Fraction
  totalSupply: Fraction
}

/**
 * Returns the last year of daily data for the given pair.
 */
export function useAmmPairData(
  indexMap: Record<string, InterestIndex | undefined>,
): {
  loading: boolean
  error: boolean
  data: Record<string, AmmPair | undefined>
} {
  const [fiatPriceMap] = useDefaultFiatValuesWithLoadingIndicator(useAllActiveTokensArray())
  const queryState = useGraphqlResult<AmmPairResponse>(
    GraphqlClientType.Dolomite,
    All_PAIR_DATA_GQL.loc!.source.body,
    NO_VARIABLES,
    RefreshFrequency.Slowest,
  )

  const tokenMap = useAllTokens()

  return useMemo(() => {
    const { loading, error, result } = queryState

    const anyLoading = Boolean(loading)
    const anyError = Boolean(error)

    const pairDataMap = (result?.ammPairs ?? []).reduce<Record<string, AmmPair>>((memo, value) => {
      const address = toChecksumAddress(value.id)
      const token0 = tokenMap[toChecksumAddress(value.token0.id)]
      const token1 = tokenMap[toChecksumAddress(value.token1.id)]
      if (!token0 || !token1) {
        return memo
      }

      const index0 = indexMap[token0.address]
      const index1 = indexMap[token1.address]
      if (!index0 || !index1) {
        return memo
      }

      const price0 = fiatPriceMap[token0.address]
      const price1 = fiatPriceMap[token1.address]
      if (!price0 || !price1) {
        return memo
      }

      const reserve0Wei = createFraction(value.reserve0).multiply(index0.supplyIndex)
      const reserve1Wei = createFraction(value.reserve1).multiply(index1.supplyIndex)

      memo[address] = {
        address,
        token0: token0,
        token1: token1,
        reserve0: reserve0Wei,
        reserve1: reserve1Wei,
        reserveUSD: reserve0Wei.multiply(price0).add(reserve1Wei.multiply(price1)),
        totalSupply: createFraction(value.totalSupply),
      }
      return memo
    }, {})

    return {
      loading: anyLoading,
      error: anyError,
      data: pairDataMap,
    }
  }, [fiatPriceMap, indexMap, queryState, tokenMap])
}
