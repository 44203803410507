import { gql } from '@apollo/client'
import { createFractionUSD, DolomiteHourData as DolomiteHourDataGql } from './gqlTypeHelpers'
import { dolomiteHourDataGql } from './queryObjects'
import { Fraction } from '@dolomite-exchange/sdk-core'
import { useMemo } from 'react'
import { useGraphqlResult } from '../state/graphql/hooks'
import { GraphqlClientType } from '../state/graphql/actions'
import { RefreshFrequency, useBlockTimestamp } from '../state/chain/hooks'

const DOLOMITE_HOUR_DATA_DATA = gql`
    query dolomiteHourDataOverInterval($blockNumber: Int!, $endTimestamp: Int!, $startTimestamp: Int!) {
        dolomiteHourDatas(
            orderBy: hourStartUnix,
            orderDirection: desc,
            block: { number_gte: $blockNumber },
            where: {
                hourStartUnix_lte: $endTimestamp,
                hourStartUnix_gt: $startTimestamp
            }
        ) {
            ${dolomiteHourDataGql()}
        }
    }
`

interface DolomiteDayDataResponse {
  dolomiteHourDatas: DolomiteHourDataGql[] | undefined
}

export interface DolomiteHourData {
  id: string
  hourStartUnix: number
  hourlyTradeVolumeUSD: Fraction
  ammLiquidityUSD: Fraction
}

export function useCurrentDayDolomiteHourData(): {
  loading: boolean
  error: boolean
  data: DolomiteHourData[] | undefined
} {
  const timestamp = useBlockTimestamp()

  const startTimestamp = useMemo(() => {
    return timestamp
      .div('3600')
      .sub('24')
      .mul('3600')
      .toNumber()
  }, [timestamp])

  const endTimestamp = useMemo(() => {
    return timestamp
      .div('3600')
      .mul('3600')
      .toNumber()
  }, [timestamp])

  return useDayDolomiteHourData(startTimestamp, endTimestamp)
}

export function usePreviousDayDolomiteHourData(): {
  loading: boolean
  error: boolean
  data: DolomiteHourData[] | undefined
} {
  const timestamp = useBlockTimestamp()

  const startTimestamp = useMemo(() => {
    return timestamp
      .div('3600')
      .sub('48')
      .mul('3600')
      .toNumber()
  }, [timestamp])

  const endTimestamp = useMemo(() => {
    return timestamp
      .div('3600')
      .sub('24')
      .mul('3600')
      .toNumber()
  }, [timestamp])

  return useDayDolomiteHourData(startTimestamp, endTimestamp)
}

function useDayDolomiteHourData(
  startTimestamp: number,
  endTimestamp: number,
): {
  loading: boolean
  error: boolean
  data: DolomiteHourData[] | undefined
} {
  const variables = useMemo(() => {
    return {
      startTimestamp,
      endTimestamp,
    }
  }, [endTimestamp, startTimestamp])
  const queryState = useGraphqlResult<DolomiteDayDataResponse>(
    GraphqlClientType.Dolomite,
    DOLOMITE_HOUR_DATA_DATA.loc!.source.body,
    variables,
    RefreshFrequency.Slow,
  )

  return useMemo(() => {
    const { loading, error, result } = queryState
    const anyLoading = Boolean(loading)
    const anyError = Boolean(error)

    const hourDatas = (result?.dolomiteHourDatas ?? [])
      .map<DolomiteHourData>(value => {
        return {
          id: value.id,
          hourStartUnix: value.hourStartUnix,
          hourlyTradeVolumeUSD: createFractionUSD(value.hourlyTradeVolumeUSD),
          ammLiquidityUSD: createFractionUSD(value.ammLiquidityUSD),
        }
      })
      .sort((a, b) => a.hourStartUnix - b.hourStartUnix)

    return {
      loading: anyLoading,
      error: anyError,
      data: hourDatas,
    }
  }, [queryState])
}
