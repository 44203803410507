import useENS from '../../hooks/useENS'
import { parseUnits } from '@ethersproject/units'
import { Token, Trade } from '@dolomite-exchange/v2-sdk'
import JSBI from 'jsbi'
import { Currency, CurrencyAmount, Ether, Fraction, TradeType } from '@dolomite-exchange/sdk-core'
import { ParsedQs } from 'qs'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useActiveWeb3React } from '../../hooks'
import { useAllActiveTokensArray, useAllTokens, useTradingTokensArray } from '../../hooks/Tokens'
import { useTradeExactIn, useTradeExactOut } from '../../hooks/Trades'
import useParsedQueryString from '../../hooks/useParsedQueryString'
import { isAddress } from '../../utils'
import { AppDispatch, AppState } from '../index'
import { useDolomiteBalancesWithLoadingIndicator } from '../wallet/hooks'
import {
  Field,
  replaceSwapState,
  selectCurrency,
  setLeverage,
  setRecipient,
  setSubmittedMarginPosition,
  SubmittedMarginPosition,
  switchCurrencies,
  typeInput,
} from './actions'
import { TradeState } from './reducer'
import { useUserSlippageTolerance, useUserTransactionTTL } from '../user/hooks'
import { computeSlippageAdjustedAmounts } from '../../utils/prices'
import { PairState, usePair } from '../../data/Reserves'
import { useTotalSupply } from '../../data/TotalSupply'
import cleanCurrencySymbol from '../../utils/cleanCurrencySymbol'
import { BIG_INT_ZERO, DAI, ONE_ETH_IN_WEI, USDC, WETH, ZERO_FRACTION } from '../../constants'
import { useAccountUsdValues } from '../../hooks/useDolomiteMarginProtocol'
import { ethers } from 'ethers'
import { DerivedSubmittedMarginPosition } from '../../pages/Trade/TradePanel'
import { useHistory, useParams } from 'react-router-dom'
import { deriveMarketFromTokensReq } from '../../utils/marketUtils'
import useDebounce from '../../hooks/useDebounce'
import { ZapOutputParam } from '@dolomite-exchange/zap-sdk'
import { BRIDGED_USDC } from '../../constants/tokens/USDC'
import { useBlockNumber } from '../chain/hooks'

export function useTradeState(): AppState['swap'] {
  return useSelector<AppState, AppState['swap']>(state => state.swap)
}

export function useTradeActionHandlers(): {
  onCurrencySelection: (field: Field, currency: Currency) => void
  onSwitchTokens: () => void
  onUserInput: (field: Field, typedValue: string) => void
  onChangeLeverage: (typedValue: string) => void
  onChangeRecipient: (recipient: string | null) => void
  onChangeSubmittedMarginPosition: (submittedMarginPosition: SubmittedMarginPosition | null) => void
} {
  const history = useHistory()
  const {
    [Field.INPUT]: { currencyId: inputCurrencyId },
    [Field.OUTPUT]: { currencyId: outputCurrencyId },
  } = useTradeState()
  const tokenMap = useAllTokens()
  const inputToken = useMemo(() => tokenMap[inputCurrencyId ?? ''], [tokenMap, inputCurrencyId])
  const outputToken = useMemo(() => tokenMap[outputCurrencyId ?? ''], [tokenMap, outputCurrencyId])

  const dispatch = useDispatch<AppDispatch>()
  const onCurrencySelection = useCallback(
    (field: Field, currency: Currency) => {
      dispatch(
        selectCurrency({
          field,
          currencyId: currency.wrapped.address,
        }),
      )

      if (field === Field.INPUT) {
        history.push(`/trade/${cleanCurrencySymbol(currency)}/${cleanCurrencySymbol(outputToken)}`)
      } else {
        history.push(`/trade/${cleanCurrencySymbol(inputToken)}/${cleanCurrencySymbol(currency)}`)
      }
    },
    [dispatch, history, inputToken, outputToken],
  )

  const onSwitchTokens = useCallback(() => {
    dispatch(switchCurrencies())
    history.push(`/trade/${cleanCurrencySymbol(outputToken)}/${cleanCurrencySymbol(inputToken)}`)
  }, [dispatch, history, inputToken, outputToken])

  const onUserInput = useCallback(
    (field: Field, typedValue: string) => {
      dispatch(
        typeInput({
          field,
          typedValue,
        }),
      )
    },
    [dispatch],
  )

  const onChangeRecipient = useCallback(
    (recipient: string | null) => {
      dispatch(setRecipient({ recipient }))
    },
    [dispatch],
  )

  const onChangeLeverage = useCallback(
    (typedValue: string) => {
      dispatch(setLeverage({ typedLeverage: typedValue }))
    },
    [dispatch],
  )

  const onChangeSubmittedMarginPosition = useCallback(
    (submittedMarginPosition: SubmittedMarginPosition | null) => {
      dispatch(setSubmittedMarginPosition({ submittedMarginPosition: submittedMarginPosition }))
    },
    [dispatch],
  )

  return {
    onSwitchTokens,
    onCurrencySelection,
    onUserInput,
    onChangeRecipient,
    onChangeLeverage,
    onChangeSubmittedMarginPosition,
  }
}

const ether = Ether.onChain(1)

export function tryParseAmountWithNoCurrency(value?: string): Fraction | undefined {
  return tryParseAmount(value, ether)?.asFraction
}

// try to parse a user entered amount for a given token
export function tryParseAmount<T extends Currency>(value?: string, currency?: T): CurrencyAmount<T> | undefined {
  if (!value || !currency) {
    return undefined
  }
  try {
    const typedValueParsed = parseUnits(value, currency.decimals).toString()
    return CurrencyAmount.fromRawAmount(currency, typedValueParsed)
  } catch (error) {
    // should fail if the user specifies too many decimal places of precision (or maybe exceed max uint?)
    console.warn(`Failed to parse input amount: "${value}"`, error)
  }
  // necessary for all paths to return a value
  return undefined
}

export function reqParseAmount<T extends Currency>(value: string, currency: T): CurrencyAmount<T> {
  return tryParseAmount(value, currency)!
}

export function reqParseFraction<T extends Currency>(value: string, decimals: number = 18): Fraction {
  return new Fraction(parseUnits(value, decimals).toString(), '1' + '0'.repeat(decimals))
}

const BAD_RECIPIENT_ADDRESSES: string[] = [
  '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f', // v2 factory
  '0xf164fC0Ec4E93095b804a4795bBe1e041497b92a', // v2 router 01
  '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D', // v2 router 02
]

/**
 * Returns true if any of the pairs or tokens in a trade have the given checksummed address
 * @param trade to check for the given address
 * @param checksummedAddress address to check in the pairs and tokens
 */
function involvesAddress(trade: Trade<Currency, Currency, TradeType>, checksummedAddress: string): boolean {
  return (
    trade.route.path.some(token => token.address === checksummedAddress) ||
    trade.route.pairs.some(pair => pair.liquidityToken.address === checksummedAddress)
  )
}

// from the current Trade inputs, compute the best trade and return it.
export function useDerivedTradeInfo(): {
  currencies: { [field in Field]?: Currency }
  dolomiteBalances: { [field in Field]?: CurrencyAmount<Currency> }
  parsedAmount: CurrencyAmount<Currency> | undefined
  zaps: ZapOutputParam[] | undefined
  v2Trade: Trade<Currency, Currency, TradeType> | undefined
  inputError?: string
  suppliedUSD: Fraction | undefined
  borrowedUSD: Fraction | undefined
  tradeAccountNumber: JSBI
  marginDeposit?: CurrencyAmount<Token>
  submittedMarginPosition: DerivedSubmittedMarginPosition | null
} {
  const { account, chainId } = useActiveWeb3React()
  const [ttl] = useUserTransactionTTL()
  const blockNumber = useBlockNumber(chainId)
  const [{ borrowedUSD, suppliedUSD }] = useAccountUsdValues(account, '0')

  const {
    independentField,
    typedValue,
    [Field.INPUT]: { currencyId: inputCurrencyId },
    [Field.OUTPUT]: { currencyId: outputCurrencyId },
    recipient,
    typedLeverage,
    submittedMarginPosition,
  } = useTradeState()

  const tokenMap = useAllTokens()
  const inputCurrency = useMemo(() => tokenMap[inputCurrencyId ?? ''], [inputCurrencyId, tokenMap])
  const outputCurrency = useMemo(() => tokenMap[outputCurrencyId ?? ''], [outputCurrencyId, tokenMap])
  const recipientLookup = useENS(recipient ?? undefined)
  const to: string | null = (recipient === null ? account : recipientLookup.address) ?? null

  const tokens = useMemo(() => [inputCurrency, outputCurrency], [inputCurrency, outputCurrency])
  const [relevantTokenBalances] = useDolomiteBalancesWithLoadingIndicator(account, tokens)

  const isExactIn: boolean = independentField === Field.INPUT

  const parsedAmount = useMemo(() => {
    return tryParseAmount(typedValue, (isExactIn ? inputCurrency : outputCurrency) ?? undefined)
  }, [typedValue, isExactIn, inputCurrency, outputCurrency])

  const leverage = useMemo(() => {
    return tryParseAmount(typedLeverage, Ether.onChain(chainId))?.asFraction ?? new Fraction('1')
  }, [typedLeverage, chainId])

  const tradeAmount = useMemo(() => {
    if (leverage.greaterThan('1')) {
      return parsedAmount?.multiply(leverage.subtract('1'))
    } else {
      return parsedAmount
    }
  }, [parsedAmount, leverage])

  const bestTradeExactIn = useTradeExactIn(isExactIn ? parsedAmount : undefined, outputCurrency ?? undefined)
  const bestTradeExactOut = useTradeExactOut(inputCurrency ?? undefined, !isExactIn ? tradeAmount : undefined)
  const v2Trade = isExactIn ? bestTradeExactIn : bestTradeExactOut

  const dolomiteBalances = useMemo(
    () => ({
      [Field.INPUT]: relevantTokenBalances[inputCurrencyId ?? ''],
      [Field.OUTPUT]: relevantTokenBalances[outputCurrencyId ?? ''],
    }),
    [relevantTokenBalances, inputCurrencyId, outputCurrencyId],
  )

  const currencies: { [field in Field]?: Currency } = useMemo(
    () => ({
      [Field.INPUT]: inputCurrency ?? undefined,
      [Field.OUTPUT]: outputCurrency ?? undefined,
    }),
    [inputCurrency, outputCurrency],
  )

  let inputError: string | undefined
  if (!account) {
    inputError = 'Connect Wallet'
  }

  if (!parsedAmount) {
    inputError = inputError ?? 'Enter an amount'
  }

  if (!currencies[Field.INPUT] || !currencies[Field.OUTPUT]) {
    inputError = inputError ?? 'Select a token'
  }

  const formattedTo = isAddress(to)
  if (!to || !formattedTo) {
    inputError = inputError ?? 'Enter a recipient'
  } else {
    if (
      BAD_RECIPIENT_ADDRESSES.includes(formattedTo) ||
      (bestTradeExactIn && involvesAddress(bestTradeExactIn, formattedTo)) ||
      (bestTradeExactOut && involvesAddress(bestTradeExactOut, formattedTo))
    ) {
      inputError = inputError ?? 'Invalid recipient'
    }
  }

  const [allowedSlippage] = useUserSlippageTolerance()

  const slippageAdjustedAmounts = v2Trade && allowedSlippage && computeSlippageAdjustedAmounts(v2Trade, allowedSlippage)

  const marginDeposit = useMemo(() => {
    return leverage.greaterThan('1') ? v2Trade?.outputAmount.divide(leverage.subtract('1')).wrapped : undefined
  }, [v2Trade, leverage])

  // compare input balance to max input based on version
  const [balanceUsed, amountIn] = [
    leverage.equalTo('1') ? dolomiteBalances[Field.INPUT] : dolomiteBalances[Field.OUTPUT],
    leverage.equalTo('1') ? (slippageAdjustedAmounts ? slippageAdjustedAmounts[Field.INPUT] : null) : marginDeposit,
  ]

  if (balanceUsed && amountIn && balanceUsed.lessThan(amountIn)) {
    inputError = 'Insufficient ' + cleanCurrencySymbol(amountIn.currency) + ' balance'
  }

  const tradeAccountNumber = useMemo(() => {
    if (!v2Trade || !account || !blockNumber || leverage.equalTo('1')) {
      return JSBI.BigInt('0')
    }

    const types = ['address', 'address', 'address', 'uint256', 'uint256', 'uint256', 'uint256', 'uint256']
    const values = [
      account,
      v2Trade.inputAmount.currency.wrapped.address,
      v2Trade.outputAmount.currency.wrapped.address,
      v2Trade.inputAmount.quotient.toString(),
      v2Trade.outputAmount.quotient.toString(),
      ttl,
      blockNumber,
      new Date().getTime(),
    ]
    const salt = ethers.utils.solidityKeccak256(types, values)
    return leverage.greaterThan('1') ? JSBI.BigInt(salt) : JSBI.BigInt('0')
  }, [leverage, v2Trade, account, ttl, blockNumber])

  const derivedSubmittedMarginPosition = useMemo(() => {
    if (!submittedMarginPosition) {
      return null
    }

    const longAsset = tokenMap[submittedMarginPosition.longAsset]
    const shortAsset = tokenMap[submittedMarginPosition.shortAsset]
    if (!longAsset || !shortAsset) {
      return null
    }

    const derived: DerivedSubmittedMarginPosition = {
      tradeAccountNumber: JSBI.BigInt(submittedMarginPosition.tradeAccountNumber),
      market: deriveMarketFromTokensReq(longAsset, shortAsset),
      isOpening: submittedMarginPosition.isOpening,
      longAsset: longAsset,
      shortAsset: shortAsset,
      heldToken: tokenMap[submittedMarginPosition.heldToken],
      leverage: new Fraction(submittedMarginPosition.leverage, submittedMarginPosition.leverageDenominator),
      positionSize: new Fraction(submittedMarginPosition.positionSize, submittedMarginPosition.positionSizeDenominator),
      depositSize: new Fraction(submittedMarginPosition.depositSize, submittedMarginPosition.depositSizeDenominator),
      liquidationPrice: submittedMarginPosition.liquidationPrice
        ? new Fraction(submittedMarginPosition.liquidationPrice, submittedMarginPosition.liquidationPriceDenominator)
        : undefined,
      openPrice: new Fraction(submittedMarginPosition.openPrice, submittedMarginPosition.openPriceDenominator),
      openTimestamp: new Date(submittedMarginPosition.openTimestamp),
    }

    return derived
  }, [submittedMarginPosition, tokenMap])

  return {
    currencies,
    dolomiteBalances: dolomiteBalances,
    parsedAmount,
    zaps: undefined,
    inputError,
    borrowedUSD,
    suppliedUSD,
    tradeAccountNumber: tradeAccountNumber,
    marginDeposit,
    submittedMarginPosition: derivedSubmittedMarginPosition,
    v2Trade: v2Trade ?? undefined,
  }
}

export interface DepthChart {
  buy: {
    Amount: number
    Price: number
  }[]
  sell: {
    Amount: number
    Price: number
  }[]
  interval: number | undefined
}

const ONE = JSBI.BigInt(1)
const TEN = JSBI.BigInt(10)

function sqrtFraction(value: Fraction): Fraction {
  if (value.equalTo(ZERO_FRACTION)) {
    return ZERO_FRACTION
  }

  let truncationAmount = ONE
  const denominatorLength = value.denominator.toString().length
  const maxDecimals = 18
  if (denominatorLength > maxDecimals) {
    truncationAmount = JSBI.exponentiate(TEN, JSBI.BigInt(denominatorLength - maxDecimals))
  }
  const truncatedValue = new Fraction(
    JSBI.divide(value.numerator, truncationAmount),
    JSBI.divide(value.denominator, truncationAmount),
  )
  return new Fraction(
    parseUnits(Math.sqrt(parseFloat(truncatedValue.toFixed(maxDecimals))).toString(), maxDecimals).toString(),
    ONE_ETH_IN_WEI,
  )
}

function requiredVolume(
  priceChange: Fraction,
  primaryLiquidity: Fraction,
  secondaryLiquidity: Fraction,
  numerator: Fraction,
  denominatorConstant: Fraction,
): Fraction {
  const denominator = denominatorConstant.asFraction.add(priceChange)
  return primaryLiquidity.subtract(sqrtFraction(numerator.divide(denominator)))
}

function requiredVolumeOneHop(
  priceChange: Fraction,
  primaryLiquidity: Fraction,
  primaryBaseLiquidity: Fraction,
  secondaryLiquidity: Fraction,
  secondaryBaseLiquidity: Fraction,
  numerator: Fraction,
  denominatorConstant: Fraction,
  wxConstant: Fraction,
  wxDenominator: Fraction,
): Fraction {
  return primaryLiquidity.subtract(
    sqrtFraction(numerator.divide(priceChange.add(denominatorConstant)))
      .add(wxConstant)
      .divide(wxDenominator),
  )
}

function generateDepthChart(
  interval: number | undefined,
  primaryTokenBuyReserve: Fraction | undefined,
  primaryBaseTokenBuyReserve: Fraction | undefined,
  secondaryTokenBuyReserve: Fraction | undefined,
  secondaryBaseTokenBuyReserve: Fraction | undefined,
  primaryTokenSellReserve: Fraction | undefined,
  primaryBaseTokenSellReserve: Fraction | undefined,
  secondaryTokenSellReserve: Fraction | undefined,
  secondaryBaseTokenSellReserve: Fraction | undefined,
): DepthChart {
  const points = 26
  if (
    interval &&
    primaryTokenBuyReserve &&
    secondaryTokenBuyReserve &&
    primaryTokenSellReserve &&
    secondaryTokenSellReserve
  ) {
    const buyChart = []
    const sellChart = []

    const intervalStep =
      interval >= 1 ? new Fraction(interval.toString()) : new Fraction(ONE, (1 / interval).toString()) // TODO this is the same thing as putting interval in the numerator and making the denominator 1

    if (primaryBaseTokenBuyReserve && secondaryBaseTokenBuyReserve) {
      const primaryLiquidity = primaryTokenBuyReserve
      const secondaryLiquidity = secondaryTokenBuyReserve

      const currentMarketPrice = primaryBaseTokenBuyReserve
        .divide(primaryTokenBuyReserve) //4086.77434
        .multiply(secondaryTokenBuyReserve.divide(secondaryBaseTokenBuyReserve)) //1.081489

      const primaryBaseLiquidity = primaryBaseTokenBuyReserve
      const secondaryBaseLiquidity = secondaryBaseTokenBuyReserve
      const numerator = primaryLiquidity
        .multiply(secondaryLiquidity)
        .multiply(primaryBaseLiquidity)
        .multiply(secondaryBaseLiquidity)
      const denominatorConstant = primaryBaseLiquidity
        .divide(primaryLiquidity)
        .multiply(secondaryLiquidity.divide(secondaryBaseLiquidity))
      const wxConstant = primaryLiquidity.multiply(primaryBaseLiquidity)
      const wxDenominator = primaryBaseLiquidity.add(secondaryBaseLiquidity)

      // TODO possible algorithm change
      // instead of calculating the amounts at all 25 points, you can calculate the amounts at point 1 and 1000. Then,
      // find the exponential rate of change between point 1 and 25. This is equal to sqrt_24(p24/p0)
      for (let i = 1; i < points; i++) {
        buyChart.push({
          Price: Number.parseFloat(currentMarketPrice.add(intervalStep.multiply(i)).toSignificant(10)),
          Amount: Number.parseFloat(
            requiredVolumeOneHop(
              intervalStep.multiply(i),
              primaryLiquidity,
              primaryBaseLiquidity,
              secondaryLiquidity,
              secondaryBaseLiquidity,
              numerator,
              denominatorConstant,
              wxConstant,
              wxDenominator,
            ).toSignificant(10),
          ),
        })
      }
    } else {
      const primaryLiquidity = primaryTokenBuyReserve
      const secondaryLiquidity = secondaryTokenBuyReserve

      const currentMarketPrice = secondaryLiquidity.divide(primaryLiquidity)
      const numerator = secondaryLiquidity.multiply(primaryLiquidity)
      const denominatorConstant = secondaryLiquidity.divide(primaryLiquidity)

      for (let i = 1; i < points; i++) {
        buyChart.push({
          Price: Number.parseFloat(currentMarketPrice.add(intervalStep.multiply(i)).toSignificant(10)),
          Amount: Number.parseFloat(
            requiredVolume(
              intervalStep.multiply(JSBI.BigInt(i)),
              primaryLiquidity,
              secondaryLiquidity,
              numerator,
              denominatorConstant,
            ).toSignificant(10),
          ),
        })
      }
    }
    if (primaryBaseTokenSellReserve && secondaryBaseTokenSellReserve) {
      const primaryLiquidity = primaryTokenSellReserve
      const secondaryLiquidity = secondaryTokenSellReserve

      const currentMarketPrice = primaryBaseTokenSellReserve
        .divide(primaryTokenSellReserve)
        .multiply(secondaryTokenSellReserve.divide(secondaryBaseTokenSellReserve))

      const primaryBaseLiquidity = primaryBaseTokenSellReserve
      const secondaryBaseLiquidity = secondaryBaseTokenSellReserve
      const numerator = primaryLiquidity
        .multiply(secondaryLiquidity)
        .multiply(primaryBaseLiquidity)
        .multiply(secondaryBaseLiquidity)
      const denominatorConstant = primaryBaseLiquidity
        .divide(primaryLiquidity)
        .multiply(secondaryLiquidity.divide(secondaryBaseLiquidity))
      const wxConstant = primaryLiquidity.multiply(primaryBaseLiquidity)
      const wxDenominator = primaryBaseLiquidity.add(secondaryBaseLiquidity)

      for (let i = 1; i < points; i++) {
        if (currentMarketPrice.asFraction.add(intervalStep.multiply(-i)).greaterThan(BIG_INT_ZERO)) {
          sellChart.push({
            Price: parseFloat(
              currentMarketPrice.asFraction.add(intervalStep.multiply(i).multiply(-1)).toSignificant(10),
            ),
            Amount: parseFloat(
              requiredVolumeOneHop(
                intervalStep.multiply(i).multiply('-1'),
                primaryLiquidity,
                primaryBaseLiquidity,
                secondaryLiquidity,
                secondaryBaseLiquidity,
                numerator,
                denominatorConstant,
                wxConstant,
                wxDenominator,
              )
                .multiply('-1')
                .toSignificant(10),
            ),
          })
        }
      }
    } else {
      const primaryLiquidity = primaryTokenSellReserve
      const secondaryLiquidity = secondaryTokenSellReserve

      const currentMarketPrice = secondaryLiquidity.divide(primaryLiquidity)
      const numerator = secondaryLiquidity.multiply(primaryLiquidity)
      const denominatorConstant = secondaryLiquidity.divide(primaryLiquidity)

      for (let i = 1; i < points; i++) {
        if (currentMarketPrice.asFraction.add(intervalStep.multiply(-i)).greaterThan(0)) {
          sellChart.push({
            Price: parseFloat(currentMarketPrice.asFraction.add(intervalStep.multiply(-i)).toSignificant(10)),
            Amount: parseFloat(
              requiredVolume(
                intervalStep.multiply(JSBI.BigInt(i)).multiply(JSBI.BigInt(-1)),
                primaryLiquidity,
                secondaryLiquidity,
                numerator,
                denominatorConstant,
              )
                .multiply('-1')
                .toSignificant(10),
            ),
          })
        }
      }
    }
    return {
      buy: sellChart,
      sell: buyChart,
      interval: interval,
    }
  }
  return {
    buy: [],
    sell: [],
    interval: interval,
  }
}

// Find out how much of secondary currency you receive when swapping inputAmount of primaryCurrency
function useGetSwapOutput(
  primaryToken: Currency | undefined,
  secondaryToken: Currency | undefined,
  inputAmount: Fraction | undefined,
): Fraction | undefined {
  const [, pair] = usePair(primaryToken, secondaryToken)
  if (primaryToken?.symbol === secondaryToken?.symbol || inputAmount === undefined) return undefined
  const x = pair
    ? cleanCurrencySymbol(pair.reserve0.currency) === cleanCurrencySymbol(primaryToken)
      ? pair.reserve0.asFraction
      : pair.reserve1.asFraction
    : undefined
  const y = pair
    ? cleanCurrencySymbol(pair.reserve1.currency) === cleanCurrencySymbol(secondaryToken)
      ? pair.reserve1.asFraction
      : pair.reserve0.asFraction
    : undefined
  const k = x && y ? x.multiply(y) : undefined
  return y && k && x && inputAmount
    ? y.subtract(k.divide(x.add(inputAmount))).multiply(new Fraction(997, 1000))
    : undefined
}

export function useFindBestCommon(
  primaryToken: Currency | undefined,
  secondaryToken: Currency | undefined,
  intervalAmount: Fraction | undefined,
): Currency | undefined {
  const { chainId } = useActiveWeb3React()
  //TODO - compare liquidity in pools shared by the two tokens, or at least stablecoin and ETH pools
  const directSwapOutput = useGetSwapOutput(primaryToken, secondaryToken, intervalAmount)
  const ETH = useMemo(() => Ether.onChain(chainId), [chainId])
  const oneHopETHOutput = useGetSwapOutput(ETH, secondaryToken, useGetSwapOutput(primaryToken, ETH, intervalAmount))
  const oneHopUSDCOutput = useGetSwapOutput(
    USDC[chainId],
    secondaryToken,
    useGetSwapOutput(primaryToken, USDC[chainId], intervalAmount),
  )
  const oneHopDAIOutput = useGetSwapOutput(
    DAI[chainId],
    secondaryToken,
    useGetSwapOutput(primaryToken, DAI[chainId], intervalAmount),
  )

  return useMemo(() => {
    const resultArray = [
      {
        currency: undefined,
        output: directSwapOutput,
      },
      {
        currency: ETH,
        output: oneHopETHOutput,
      },
      {
        currency: USDC[chainId],
        output: oneHopUSDCOutput,
      },
      {
        currency: DAI[chainId],
        output: oneHopDAIOutput,
      },
    ]

    // If no direct Trade, use one hop. if no one hop, use direct Trade. If both, then compare and send the one that had less slippage
    return resultArray.reduce((prev, curr) => {
      if (!prev.output) return curr
      if (!curr.output) return prev
      return prev.output.greaterThan(curr.output) ? prev : curr
    }).currency
  }, [ETH, chainId, directSwapOutput, oneHopETHOutput, oneHopUSDCOutput, oneHopDAIOutput])
}

export function useCheckForCommons(
  primaryToken: Currency | undefined,
  secondaryToken: Currency | undefined,
  primaryLiquidity: Fraction | undefined,
  secondaryLiquidity: Fraction | undefined,
  intervalAmount: Fraction | undefined,
): [Currency | undefined, Currency | undefined] {
  const numerator = useMemo(() => {
    return primaryLiquidity && secondaryLiquidity ? secondaryLiquidity.multiply(primaryLiquidity) : new Fraction('1')
  }, [primaryLiquidity, secondaryLiquidity])

  const denominatorConstant = useMemo(() => {
    return primaryLiquidity && secondaryLiquidity ? secondaryLiquidity.divide(primaryLiquidity) : new Fraction('1')
  }, [primaryLiquidity, secondaryLiquidity])

  const requiredVolumeAmount = useMemo(() => {
    return requiredVolume(
      intervalAmount ?? new Fraction('1'),
      primaryLiquidity ?? new Fraction('1'),
      secondaryLiquidity ?? new Fraction('1'),
      numerator,
      denominatorConstant,
    )
  }, [denominatorConstant, intervalAmount, numerator, primaryLiquidity, secondaryLiquidity])

  const buyAmount = useMemo(() => new Fraction('1').divide(requiredVolumeAmount), [requiredVolumeAmount])

  const buyToken = useFindBestCommon(secondaryToken, primaryToken, buyAmount)
  const sellToken = useFindBestCommon(primaryToken, secondaryToken, requiredVolumeAmount)

  return [buyToken, sellToken]
}

export function useDepthChart(
  interval: number | undefined,
  primaryToken: Currency | undefined,
  secondaryToken: Currency | undefined,
  intervalAmountString: string | undefined,
): DepthChart {
  const [pairState, rawPair] = usePair(primaryToken, secondaryToken)
  const pair = useDebounce(rawPair, 100)
  const totalSupply = useTotalSupply(pair?.liquidityToken)

  const hasNoLiquidity = pairState === PairState.NOT_EXISTS || totalSupply?.equalTo(ZERO_FRACTION)

  const primaryTokenTicker = cleanCurrencySymbol(primaryToken) ?? ''
  const secondaryTokenTicker = cleanCurrencySymbol(secondaryToken) ?? ''

  const primaryLiquidity = useMemo(() => {
    return cleanCurrencySymbol(pair?.reserve0.currency) === primaryTokenTicker
      ? pair?.reserve0.asFraction
      : pair?.reserve1.asFraction
  }, [pair, primaryTokenTicker])

  const secondaryLiquidity = useMemo(() => {
    return cleanCurrencySymbol(pair?.reserve0.currency) === primaryTokenTicker
      ? pair?.reserve1.asFraction
      : pair?.reserve0.asFraction
  }, [pair, primaryTokenTicker])

  const intervalAmount = useMemo(() => {
    return tryParseAmount(intervalAmountString, secondaryToken)?.asFraction
  }, [intervalAmountString, secondaryToken])

  //TODO - need to do this for both the buy and the sell directions
  const [oneHopTokenBuy, oneHopTokenSell] = useCheckForCommons(
    primaryToken,
    secondaryToken,
    primaryLiquidity,
    secondaryLiquidity,
    intervalAmount,
  )

  const oneHopTokenBuyTicker = oneHopTokenBuy ? cleanCurrencySymbol(oneHopTokenBuy) : undefined
  const oneHopTokenSellTicker = oneHopTokenSell ? cleanCurrencySymbol(oneHopTokenSell) : undefined
  const [, primaryCommonPairBuy] = usePair(primaryToken, oneHopTokenBuy)
  const [, secondaryCommonPairBuy] = usePair(oneHopTokenBuy, secondaryToken)
  const [, primaryCommonPairSell] = usePair(primaryToken, oneHopTokenSell)
  const [, secondaryCommonPairSell] = usePair(oneHopTokenSell, secondaryToken)

  const [
    primaryTokenBuyReserve,
    secondaryTokenBuyReserve,
    primaryCommonTokenBuyReserve,
    secondaryCommonTokenBuyReserve,
  ] = useMemo(() => {
    if (oneHopTokenBuy && primaryCommonPairBuy && secondaryCommonPairBuy) {
      const primaryTokenBuyReserve =
        cleanCurrencySymbol(primaryCommonPairBuy.reserve0.currency) === oneHopTokenBuyTicker
          ? primaryCommonPairBuy.reserve1.asFraction
          : primaryCommonPairBuy.reserve0.asFraction
      const secondaryTokenBuyReserve =
        cleanCurrencySymbol(secondaryCommonPairBuy.reserve0.currency) === oneHopTokenBuyTicker
          ? secondaryCommonPairBuy.reserve1.asFraction
          : secondaryCommonPairBuy.reserve0.asFraction
      const primaryCommonTokenBuyReserve =
        cleanCurrencySymbol(primaryCommonPairBuy.reserve0.currency) === oneHopTokenBuyTicker
          ? primaryCommonPairBuy.reserve0.asFraction
          : primaryCommonPairBuy.reserve1.asFraction
      const secondaryCommonTokenBuyReserve =
        cleanCurrencySymbol(secondaryCommonPairBuy.reserve0.currency) === oneHopTokenBuyTicker
          ? secondaryCommonPairBuy.reserve0.asFraction
          : secondaryCommonPairBuy.reserve1.asFraction
      return [
        primaryTokenBuyReserve,
        secondaryTokenBuyReserve,
        primaryCommonTokenBuyReserve,
        secondaryCommonTokenBuyReserve,
      ]
    } else if (!hasNoLiquidity && pair) {
      const primaryTokenBuyReserve =
        cleanCurrencySymbol(pair.reserve0.currency) === primaryTokenTicker
          ? pair.reserve0.asFraction
          : pair.reserve1.asFraction
      const secondaryTokenBuyReserve =
        cleanCurrencySymbol(pair.reserve1.currency) === secondaryTokenTicker
          ? pair.reserve1.asFraction
          : pair.reserve0.asFraction
      return [primaryTokenBuyReserve, secondaryTokenBuyReserve, undefined, undefined]
    }

    return [undefined, undefined, undefined, undefined]
  }, [
    hasNoLiquidity,
    oneHopTokenBuy,
    oneHopTokenBuyTicker,
    pair,
    primaryCommonPairBuy,
    primaryTokenTicker,
    secondaryCommonPairBuy,
    secondaryTokenTicker,
  ])

  const [
    primaryTokenSellReserve,
    secondaryTokenSellReserve,
    primaryCommonTokenSellReserve,
    secondaryCommonTokenSellReserve,
  ] = useMemo(() => {
    if (oneHopTokenSell && primaryCommonPairSell && secondaryCommonPairSell) {
      const primaryTokenSellReserve =
        cleanCurrencySymbol(primaryCommonPairSell.reserve0.currency) === oneHopTokenSellTicker
          ? primaryCommonPairSell.reserve1.asFraction
          : primaryCommonPairSell.reserve0.asFraction
      const secondaryTokenSellReserve =
        cleanCurrencySymbol(secondaryCommonPairSell.reserve0.currency) === oneHopTokenSellTicker
          ? secondaryCommonPairSell.reserve1.asFraction
          : secondaryCommonPairSell.reserve0.asFraction
      const primaryCommonTokenSellReserve =
        cleanCurrencySymbol(primaryCommonPairSell.reserve0.currency) === oneHopTokenSellTicker
          ? primaryCommonPairSell.reserve0.asFraction
          : primaryCommonPairSell.reserve1.asFraction
      const secondaryCommonTokenSellReserve =
        cleanCurrencySymbol(secondaryCommonPairSell.reserve0.currency) === oneHopTokenSellTicker
          ? secondaryCommonPairSell.reserve0.asFraction
          : secondaryCommonPairSell.reserve1.asFraction
      return [
        primaryTokenSellReserve,
        secondaryTokenSellReserve,
        primaryCommonTokenSellReserve,
        secondaryCommonTokenSellReserve,
      ]
    } else if (!hasNoLiquidity && pair) {
      const primaryTokenSellReserve =
        cleanCurrencySymbol(pair.reserve0.currency) === primaryTokenTicker
          ? pair.reserve0.asFraction
          : pair.reserve1.asFraction
      const secondaryTokenSellReserve =
        cleanCurrencySymbol(pair.reserve1.currency) === secondaryTokenTicker
          ? pair.reserve1.asFraction
          : pair.reserve0.asFraction
      return [primaryTokenSellReserve, secondaryTokenSellReserve, undefined, undefined]
    }

    return [undefined, undefined, undefined, undefined]
  }, [
    hasNoLiquidity,
    oneHopTokenSell,
    oneHopTokenSellTicker,
    pair,
    primaryCommonPairSell,
    primaryTokenTicker,
    secondaryCommonPairSell,
    secondaryTokenTicker,
  ])

  const debouncedState = useDebounce(
    {
      interval,
      primaryTokenBuyReserve,
      primaryCommonTokenBuyReserve,
      secondaryTokenBuyReserve,
      secondaryCommonTokenBuyReserve,
      primaryTokenSellReserve,
      primaryCommonTokenSellReserve,
      secondaryTokenSellReserve,
      secondaryCommonTokenSellReserve,
    },
    100,
  )

  const [depthChart, setDepthChart] = useState<DepthChart>({
    buy: [],
    sell: [],
    interval: interval,
  })

  useEffect(() => {
    new Promise<DepthChart>(resolve => {
      const start = new Date().getTime()
      const chart = generateDepthChart(
        debouncedState.interval,
        debouncedState.primaryTokenBuyReserve,
        debouncedState.primaryCommonTokenBuyReserve,
        debouncedState.secondaryTokenBuyReserve,
        debouncedState.secondaryCommonTokenBuyReserve,
        debouncedState.primaryTokenSellReserve,
        debouncedState.primaryCommonTokenSellReserve,
        debouncedState.secondaryTokenSellReserve,
        debouncedState.secondaryCommonTokenSellReserve,
      )
      console.debug('#generateDepthChart in', new Date().getTime() - start)
      resolve(chart)
    }).then(setDepthChart)
  }, [
    debouncedState.interval,
    debouncedState.primaryTokenBuyReserve,
    debouncedState.primaryCommonTokenBuyReserve,
    debouncedState.secondaryTokenBuyReserve,
    debouncedState.secondaryCommonTokenBuyReserve,
    debouncedState.primaryTokenSellReserve,
    debouncedState.primaryCommonTokenSellReserve,
    debouncedState.secondaryTokenSellReserve,
    debouncedState.secondaryCommonTokenSellReserve,
  ])
  return depthChart
}

function parseTokenFromURLPath(symbol: string | undefined, tokens: Token[], defaultToken: Token): Token {
  if (!symbol) {
    return defaultToken
  }

  return tokens.find(token => cleanCurrencySymbol(token)?.toUpperCase() === symbol.toUpperCase()) ?? defaultToken
}

function parseTokenAmountURLParameter(urlParam: any): string {
  return typeof urlParam === 'string' && !isNaN(parseFloat(urlParam)) ? urlParam : ''
}

function parseIndependentFieldURLParameter(urlParam: any): Field {
  return typeof urlParam === 'string' && urlParam.toLowerCase() === 'output' ? Field.OUTPUT : Field.INPUT
}

const ENS_NAME_REGEX = /^[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)?$/
const ADDRESS_REGEX = /^0x[a-fA-F0-9]{40}$/

function validatedRecipient(recipient: any): string | null {
  if (typeof recipient !== 'string') return null
  const address = isAddress(recipient)
  if (address) return address
  if (ENS_NAME_REGEX.test(recipient)) return recipient
  if (ADDRESS_REGEX.test(recipient)) return recipient
  return null
}

export function queryParametersToSwapState(
  parsedQs: ParsedQs,
  tokens: Token[],
  inputCurrencySymbol: string | undefined,
  outputCurrencySymbol: string | undefined,
  weth: Token,
  usdc: Token,
): { swapState: TradeState; inputToken: Token; outputToken: Token } {
  let inputToken = parseTokenFromURLPath(inputCurrencySymbol, tokens, weth)
  let outputToken = parseTokenFromURLPath(outputCurrencySymbol, tokens, usdc)
  if (inputToken.equals(outputToken)) {
    if (inputToken.symbol === weth.symbol) {
      inputToken = usdc
    } else {
      outputToken = weth
    }
  }

  const recipient = validatedRecipient(parsedQs.recipient)

  return {
    inputToken,
    outputToken,
    swapState: {
      [Field.INPUT]: {
        currencyId: inputToken.address,
      },
      [Field.OUTPUT]: {
        currencyId: outputToken.address,
      },
      typedValue: parseTokenAmountURLParameter(parsedQs.exactAmount),
      independentField: parseIndependentFieldURLParameter(parsedQs.exactField),
      recipient,
      typedLeverage: '1',
      submittedMarginPosition: null,
    },
  }
}

export interface UrlPathParam {
  inputCurrency?: string
  outputCurrency?: string
}

export function useUrlPathParams(): UrlPathParam {
  return useParams<UrlPathParam>()
}

// updates the Trade state to use the defaults for a given network
export function useDefaultsFromURL(
  isForTrading: boolean,
): { inputCurrencyId: string | undefined; outputCurrencyId: string | undefined } | undefined {
  const { chainId } = useActiveWeb3React()
  const dispatch = useDispatch<AppDispatch>()
  const parsedQs = useParsedQueryString()
  const [result, setResult] = useState<
    { inputCurrencyId: string | undefined; outputCurrencyId: string | undefined } | undefined
  >()

  const { inputCurrency: inputCurrencyFromURL, outputCurrency: outputCurrencyFromURL } = useUrlPathParams()
  const allTokens = useAllActiveTokensArray()
  const tradingTokens = useTradingTokensArray()
  const tokens = isForTrading ? tradingTokens : allTokens
  const weth = useMemo(() => WETH[chainId], [chainId])
  const usdc = useMemo(() => BRIDGED_USDC[chainId] ?? USDC[chainId], [chainId])
  const history = useHistory()
  const {
    [Field.INPUT]: { currencyId: inputCurrencyId },
    [Field.OUTPUT]: { currencyId: outputCurrencyId },
  } = useTradeState()

  useEffect(() => {
    if (!chainId) {
      return
    }

    const { swapState: parsed, inputToken, outputToken } = queryParametersToSwapState(
      parsedQs,
      tokens,
      inputCurrencyFromURL,
      outputCurrencyFromURL,
      weth,
      usdc,
    )
    if (inputCurrencyId === inputToken.address && outputCurrencyId === outputToken.address) {
      // no work needs to be done if the currencies are actually the same
      return
    }

    const eth = Ether.onChain(chainId)

    dispatch(
      replaceSwapState({
        typedValue: parsed.typedValue,
        field: parsed.independentField,
        inputCurrencyId:
          parsed[Field.INPUT].currencyId === eth.symbol ? eth.wrapped.address : parsed[Field.INPUT].currencyId,
        outputCurrencyId:
          parsed[Field.OUTPUT].currencyId === eth.symbol ? eth.wrapped.address : parsed[Field.OUTPUT].currencyId,
        recipient: parsed.recipient,
        typedLeverage: '1',
        submittedMarginPosition: null,
      }),
    )

    if (
      cleanCurrencySymbol(inputToken) !== inputCurrencyFromURL ||
      cleanCurrencySymbol(outputToken) !== outputCurrencyFromURL
    ) {
      history.replace(`/trade/${cleanCurrencySymbol(inputToken)}/${cleanCurrencySymbol(outputToken)}`)
    }

    setResult({
      inputCurrencyId: parsed[Field.INPUT].currencyId,
      outputCurrencyId: parsed[Field.OUTPUT].currencyId,
    })
  }, [
    dispatch,
    chainId,
    parsedQs,
    tokens,
    inputCurrencyFromURL,
    outputCurrencyFromURL,
    weth,
    usdc,
    history,
    inputCurrencyId,
    outputCurrencyId,
  ])

  return result
}
