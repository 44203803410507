import React, { useState } from 'react' //useEffect
import styled from 'styled-components/macro'
import SelectPool from './SelectPool'
import PoolData from './PoolData'
import { PairState } from '../../data/Reserves'
import { AmmPoolData } from './ManagePool'

const PoolDepositWithdrawWrapper = styled.div`
  background-color: #292938;
  border-radius: 8px;
  display: inline-block;
  padding: 25px 35px;
  vertical-align: top;
  width: 100%;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
  margin-bottom: 25px;

  @media screen and (max-width: 919px) {
    margin: 0 auto 25px;
    width: 70vw;
  }
  @media screen and (max-width: 730px) {
    margin: 0 auto 10px;
    width: 80vw;
  }
  @media screen and (max-width: 580px) {
    margin: 0 auto 20px;
    width: 100%;
    /*padding: 15px 20px 20px;*/
  }
`

interface PoolDepositWithdraw {
  pool?: AmmPoolData
  pairState: PairState
  onPairChange: any
  balanceInfo: any
  isLoading: boolean
}

export default function PoolDepositWithdraw({
  pool,
  pairState,
  onPairChange,
  balanceInfo,
  isLoading,
}: PoolDepositWithdraw) {
  return (
    <PoolDepositWithdrawWrapper>
      <SelectPool
        primary={pool?.pair.token0}
        secondary={pool?.pair.token1}
        onPairChange={(token0, token1) => onPairChange(token0, token1)}
      />
      <PoolData pool={pool} pairState={pairState} isLoading={isLoading} balanceInfo={balanceInfo} />
    </PoolDepositWithdrawWrapper>
  )
}
