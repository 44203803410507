import { BaseProvider, FallbackProvider, FallbackProviderConfig, Web3Provider } from '@ethersproject/providers'

import { MiniRpcProvider } from './MiniRpcProvider'
import { ChainId } from '../constants'
import { ChainIdMap } from '../constants/chainId'

export const CHAIN_ID_TO_URLS_MAP: ChainIdMap<string[]> = {
  [ChainId.MAINNET]: ['https://rpc.ankr.com/eth', 'https://1rpc.io/eth'],
  [ChainId.ARBITRUM_ONE]: ['https://arb1.arbitrum.io/rpc', 'https://rpc.ankr.com/arbitrum'],
  [ChainId.BASE]: ['https://mainnet.base.org', 'https://rpc.ankr.com/base'],
  [ChainId.BERACHAIN]: ['https://bartio.rpc.berachain.com'],
  [ChainId.MANTLE]: ['https://rpc.mantle.xyz', 'https://rpc.ankr.com/mantle'],
  [ChainId.POLYGON_ZKEVM]: ['https://zkevm-rpc.com', 'https://1rpc.io/polygon/zkevm'],
  [ChainId.X_LAYER]: ['https://rpc.xlayer.tech', 'https://rpc.ankr.com/xlayer'],
}

function mapChainIdToProvider(chainId: ChainId): FallbackProvider {
  return new FallbackProvider(
    CHAIN_ID_TO_URLS_MAP[chainId].map<FallbackProviderConfig>((url, i) => {
      return {
        provider: new Web3Provider(new MiniRpcProvider(chainId, url) as any),
        priority: 1 + i,
        stallTimeout: 1_000 * (i + 1),
      }
    }),
    1,
  )
}

export const LIBRARY_MAP: ChainIdMap<BaseProvider> = {
  [ChainId.MAINNET]: mapChainIdToProvider(ChainId.MAINNET),
  [ChainId.ARBITRUM_ONE]: mapChainIdToProvider(ChainId.ARBITRUM_ONE),
  [ChainId.BASE]: mapChainIdToProvider(ChainId.BASE),
  [ChainId.BERACHAIN]: mapChainIdToProvider(ChainId.BERACHAIN),
  [ChainId.MANTLE]: mapChainIdToProvider(ChainId.MANTLE),
  [ChainId.POLYGON_ZKEVM]: mapChainIdToProvider(ChainId.POLYGON_ZKEVM),
  [ChainId.X_LAYER]: mapChainIdToProvider(ChainId.X_LAYER),
}
