import { gql } from '@apollo/client'
import { useMemo } from 'react'
import { AmmPair, AmmPairHourData as AmmPairHourDataGql, createFraction, createFractionUSD } from './gqlTypeHelpers'
import { Fraction } from '@dolomite-exchange/sdk-core'
import { ammPairGql, ammPairHourData } from './queryObjects'
import { ZERO_FRACTION } from '../constants'
import calculatePercentageChange from '../utils/calculatePercentageChange'
import { deriveMarketFromTokensReq } from '../utils/marketUtils'
import { Token } from '@dolomite-exchange/v2-sdk'
import { useAllTokens } from '../hooks/Tokens'
import { useBlockNumberByTimestampLowerBound } from './useTimestampToBlockNumberData'
import { toChecksumAddress } from '../utils/toChecksumAddress'
import { NO_VARIABLES, useGraphqlResult } from '../state/graphql/hooks'
import { GraphqlClientType } from '../state/graphql/actions'
import { RefreshFrequency, useBlockTimestamp } from '../state/chain/hooks'

const GET_PAIRS_BY_TIME_WINDOW_GQL = gql`
    query ammPairHourDataForPastDay(
        $blockNumber: Int!,
        $startTimestamp: Int!,
        $endTimestamp: Int!,
    ) {
        ammPairHourDatas(
            orderBy: hourStartUnix,
            orderDirection: asc,
            block: { number_gte: $blockNumber }
            where: { hourStartUnix_gt: $startTimestamp, hourStartUnix_lte: $endTimestamp }
        ) {
            ${ammPairHourData()}
        }
    }
`

const AMM_PAIRS_STRICT_BLOCK_NUMBER_GQL = gql`
    query ammPairs($strictBlockNumber: Int!) {
        ammPairs(
            orderBy: reserveUSD,
            orderDirection: desc,
            block: { number: $strictBlockNumber }
            where: { reserveUSD_gt: 0 }
        ) {
            ${ammPairGql(true)}
        }
    }
`

const AMM_PAIRS_GQL = gql`
    query ammPairs($blockNumber: Int!) {
        ammPairs(
            orderBy: reserveUSD,
            orderDirection: desc,
            block: { number_gte: $blockNumber }
            where: { reserveUSD_gt: 0 }
        ) {
            ${ammPairGql(true)}
        }
    }
`

interface AmmPairDataResponse {
  ammPairs: AmmPair[]
}

interface AmmPairHourDataResponse {
  ammPairHourDatas: AmmPairHourDataGql[]
}

export interface AmmPairData {
  id: string
  token0: string
  token1: string
  reserve0: Fraction
  reserve1: Fraction
  reserveUSD: Fraction
  volumeUSD: Fraction
  price: Fraction
  change: Fraction
}

interface DisplayData {
  volume: Fraction
}

const getPairPrice = (res0: Fraction | undefined, res1: Fraction | undefined, token0: Token, token1: Token) => {
  if (!res0 || !res1) {
    return ZERO_FRACTION
  }

  const market = deriveMarketFromTokensReq(token0, token1)

  if (market.primaryToken === token0 && !res0.equalTo(ZERO_FRACTION)) {
    return res1.divide(res0)
  } else if (market.primaryToken === token1 && !res1.equalTo(ZERO_FRACTION)) {
    return res0.divide(res1)
  } else {
    return ZERO_FRACTION
  }
}

function useAmmPairsByHourMap(startTimestamp: number, endTimestamp: number): Record<string, DisplayData | undefined> {
  const variables = useMemo(() => {
    return {
      startTimestamp,
      endTimestamp,
    }
  }, [endTimestamp, startTimestamp])
  const queryState = useGraphqlResult<AmmPairHourDataResponse>(
    GraphqlClientType.Dolomite,
    GET_PAIRS_BY_TIME_WINDOW_GQL.loc!.source.body,
    variables,
    RefreshFrequency.Slow,
  )

  return useMemo(() => {
    return (queryState.result?.ammPairHourDatas ?? []).reduce<Record<string, DisplayData | undefined>>(
      (memo, ammPairHourData) => {
        const address = toChecksumAddress(ammPairHourData.id.split('-')[0])
        let memoData: DisplayData
        if (!memo[address]) {
          memoData = {
            volume: createFractionUSD(ammPairHourData.hourlyVolumeUSD),
          }
        } else {
          memoData = {
            volume: memo[address]?.volume.add(createFractionUSD(ammPairHourData.hourlyVolumeUSD)) ?? ZERO_FRACTION,
          }
        }
        memo[address] = memoData
        return memo
      },
      {},
    )
  }, [queryState])
}

function useYesterdayPairMap(
  tokenMap: Record<string, Token | undefined>,
): {
  data: Record<string, AmmPairData>
  loading: boolean
  error: boolean
} {
  const { data: yesterdayBlock } = useBlockNumberByTimestampLowerBound()
  const variables = useMemo(() => {
    if (!yesterdayBlock?.blockNumber) {
      return undefined
    }
    return {
      strictBlockNumber: yesterdayBlock.blockNumber,
    }
  }, [yesterdayBlock?.blockNumber])
  const queryState = useGraphqlResult<AmmPairDataResponse>(
    GraphqlClientType.Dolomite,
    AMM_PAIRS_STRICT_BLOCK_NUMBER_GQL.loc!.source.body,
    variables,
    RefreshFrequency.Medium,
  )

  return useMemo(() => {
    const { loading, error, result } = queryState
    const anyLoading = Boolean(loading)
    const anyError = Boolean(error)

    const pairMap = (result?.ammPairs ?? []).reduce<Record<string, AmmPairData>>((memo, value) => {
      const pairAddress = toChecksumAddress(value.id)
      const token0 = tokenMap[toChecksumAddress(value.token0.id)]
      const token1 = tokenMap[toChecksumAddress(value.token1.id)]
      if (!token0 || !token1) {
        return memo
      }

      const reserve0 = createFraction(value.reserve0 ?? '0')
      const reserve1 = createFraction(value.reserve1 ?? '0')
      const reserveUSD = createFractionUSD(value.reserveUSD)
      const pairPrice = getPairPrice(reserve0, reserve1, token0, token1)
      memo[pairAddress] = {
        id: pairAddress,
        token0: value.token0.id,
        token1: value.token1.id,
        reserve0: reserve0,
        reserve1: reserve1,
        reserveUSD: reserveUSD,
        volumeUSD: ZERO_FRACTION,
        price: pairPrice,
        change: ZERO_FRACTION,
      }
      return memo
    }, {})

    return {
      loading: anyLoading,
      error: anyError,
      data: pairMap,
    }
  }, [queryState, tokenMap])
}

export function useTopPairData(): {
  loading: boolean
  error: boolean
  data: AmmPairData[]
} {
  const topPoolsQueryState = useGraphqlResult<AmmPairDataResponse>(
    GraphqlClientType.Dolomite,
    AMM_PAIRS_GQL.loc!.source.body,
    NO_VARIABLES,
    RefreshFrequency.Slow,
  )
  const tokenMap = useAllTokens()

  const timestamp = useBlockTimestamp()

  const startTimestamp = useMemo(() => {
    return timestamp
      .div('3600')
      .sub('24')
      .mul('3600')
      .toNumber()
  }, [timestamp])

  const endTimestamp = useMemo(() => {
    return timestamp
      .div('3600')
      .mul('3600')
      .toNumber()
  }, [timestamp])

  const ammPairsByHourMap = useAmmPairsByHourMap(startTimestamp, endTimestamp)

  const yesterdayPairMapResult = useYesterdayPairMap(tokenMap)

  return useMemo(() => {
    const { loading: topPoolsLoading, error: topPoolsError, result: topPairsData } = topPoolsQueryState
    const {
      loading: yesterdayPairMapLoading,
      error: yesterdayPairMapError,
      data: yesterdayPairMapData,
    } = yesterdayPairMapResult
    const anyLoading = Boolean(topPoolsLoading || yesterdayPairMapLoading)
    const anyError = Boolean(topPoolsError || yesterdayPairMapError)

    const pools = (topPairsData?.ammPairs ?? [])
      .map<AmmPairData | undefined>(value => {
        const token0 = tokenMap[toChecksumAddress(value.token0.id)]
        const token1 = tokenMap[toChecksumAddress(value.token1.id)]
        if (!token0 || !token1) {
          return undefined
        }

        const pairAddress = toChecksumAddress(value.id)
        const prevPair = yesterdayPairMapData[pairAddress]
        const reserve0 = createFraction(value.reserve0 ?? '0')
        const reserve1 = createFraction(value.reserve1 ?? '0')
        const reserveUSD = createFractionUSD(value.reserveUSD)
        const pairPrice = getPairPrice(reserve0, reserve1, token0, token1)
        const percentageChange =
          prevPair?.price?.greaterThan('0') && pairPrice?.greaterThan('0')
            ? calculatePercentageChange(prevPair.price, pairPrice)
            : ZERO_FRACTION
        return {
          id: pairAddress,
          token0: value.token0.id,
          token1: value.token1.id,
          reserve0: reserve0,
          reserve1: reserve1,
          reserveUSD: reserveUSD,
          volumeUSD: ammPairsByHourMap[pairAddress]?.volume ?? ZERO_FRACTION,
          price: pairPrice,
          change: percentageChange,
        }
      })
      .filter(value => !!value)
      .map<AmmPairData>(value => value!)

    return {
      loading: anyLoading,
      error: anyError,
      data: pools,
    }
  }, [ammPairsByHourMap, topPoolsQueryState, tokenMap, yesterdayPairMapResult])
}
