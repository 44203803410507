import { useActiveWeb3React } from './index'
import { useIsolationModeVaultFactoryContract } from './useContract'
import { useEffect, useMemo, useState } from 'react'
import { ChainId, ZERO_ADDRESS } from '../constants'
import { Token } from '@dolomite-exchange/v2-sdk'
import { getAllSpecialAssets, useSpecialAsset } from '../constants/isolation/special-assets'
import { useSelector } from 'react-redux'
import { AppState } from '../state'
import { useBlockNumber } from '../state/chain/hooks'
import { ChainIdMap, initializeObjectChainIdMap } from '../constants/chainId'

/**
 * Globally stored cache that persists between renders, across the dApp. Maps chainId to token symbol to account to
 * vault address.
 */
const chainIdToSpecialAssetToAccountToVaultMap: ChainIdMap<Record<
  string,
  Record<string, string>
>> = initializeObjectChainIdMap()
let isLoaded: boolean = false

function loadMap() {
  if (isLoaded) {
    return
  }

  Object.keys(chainIdToSpecialAssetToAccountToVaultMap).forEach(chainId => {
    getAllSpecialAssets(Number(chainId)).forEach(specialAsset => {
      if (specialAsset.chainIdToAddressMap[Number(chainId) as ChainId]) {
        chainIdToSpecialAssetToAccountToVaultMap[Number(chainId) as ChainId][specialAsset.symbol] = {}
      }
    })
  })
  isLoaded = true
}

export default function useIsolationModeUserVaultAddressIfCreated(token?: Token): string | undefined {
  loadMap()
  const { account, chainId } = useActiveWeb3React()
  const blockNumber = useBlockNumber(chainId)
  const isolationModeTokenFactory = useIsolationModeVaultFactoryContract(token?.address)
  const specialAsset = useSpecialAsset(token)
  const state = useSelector<AppState, AppState['transactions']>(state => state.transactions)
  const transactions = useMemo(() => (chainId ? state[chainId] ?? {} : {}), [chainId, state])
  const [notYetConfirmedVaultAddress, setNotYetConfirmedVaultAddress] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (isolationModeTokenFactory && account && specialAsset?.isIsolationMode) {
      // check if the user just sent a tx for creating the vault for this account
      // get the transaction list (desc) and check if the user has a tx for creating the vault
      const transactionList = Object.values(transactions).sort((a, b) => b.addedTime - a.addedTime)
      for (const transaction of transactionList) {
        if (
          transaction.vaultCreation?.account === account &&
          transaction.from === account &&
          (!transaction.confirmedTime || transaction.confirmedTime + 15_000 > new Date().getTime())
        ) {
          // if the user has a tx being confirmed for the creation of the vault (plus a 15-second buffer), use it.
          // We don't want to set the vault address this way if the transaction is confirmed (plus the buffer) because
          // there's no guarantee the transaction was successful
          setNotYetConfirmedVaultAddress(transaction.vaultCreation.vault)
          return
        }
      }
      setNotYetConfirmedVaultAddress(undefined)

      const specialAssetToAccountToVault = chainIdToSpecialAssetToAccountToVaultMap[chainId][specialAsset.symbol]
      if (specialAssetToAccountToVault) {
        if (!specialAssetToAccountToVault[account]) {
          isolationModeTokenFactory
            .getVaultByAccount(account)
            .then((vault: string) => {
              if (vault !== ZERO_ADDRESS) {
                specialAssetToAccountToVault[account] = vault
                setNotYetConfirmedVaultAddress(vault) // set it in state here to trigger a change in `useMemo` below
              }
            })
            .catch((error: Error) => {
              console.error('Caught error in useIsolationModeUserVaultAddress', error)
            })
        } else {
          if (process.env.NODE_ENV !== 'production') {
            console.debug('Already have vault address for account', account)
          }
        }
      }
    }
  }, [blockNumber, account, specialAsset, chainId, isolationModeTokenFactory, token, transactions])

  return useMemo(() => {
    if (!specialAsset?.isIsolationMode || !account) {
      return undefined
    }
    return (
      chainIdToSpecialAssetToAccountToVaultMap[chainId][specialAsset.symbol][account] ?? notYetConfirmedVaultAddress
    )
  }, [account, chainId, notYetConfirmedVaultAddress, specialAsset])
}
