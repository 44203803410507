import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import Column from '../Orders/Column'
import { MarginPosition } from '../../types/marginPositionData'
import { Fraction } from '@dolomite-exchange/sdk-core'
import getLogoOrDefault from '../common/TokenLogos'
import { useHistory } from 'react-router-dom'
import { styled as muiStyled } from '@mui/material'
import { TableLoader } from '../Loader'
import { FORMATTER, ZERO_FRACTION } from '../../constants'
import moment from 'moment'
import JSBI from 'jsbi'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ArrowBackwardIcon from '@mui/icons-material/ArrowBack'
import { formatAmount } from '../../utils/formatAmount'
import { PageSizeContext } from '../../pages/App'

const TableWrapper = styled.div`
  background-color: #292938;
  border-radius: 8px;
  display: inline-block;
  vertical-align: top;
  width: 100%;
  height: 100%;
  margin-top: 0;
  font-family: 'Open Sans', sans-serif;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
  text-align: left;
  padding: 25px 35px;
`

export const ColumnTitles = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.text3};

  @media screen and (max-width: 660px) {
    margin-bottom: 0;
    font-size: 13px;
  }
`

const ColumnWrapper = styled(Column)`
  cursor: pointer;
`

const PositionWrapper = styled.div<{ disabled?: boolean }>`
  height: 50px;
  cursor: pointer;
  border-radius: 5px;
  padding: 15px 20px;
  width: calc(100% + 40px);
  margin-left: -20px;
  font-size: 15px;

  @media screen and (max-width: 500px) {
    font-size: 14px;
  }

  :hover {
    background-color: ${({ theme }) => theme.bg2};
  }
`

const Index = styled.div`
  width: 10%;
  display: inline-block;
  vertical-align: top;
`

const Account = styled.div<{ isMobile: boolean; account?: boolean }>`
  width: ${({ isMobile, account }) =>
    account && isMobile ? '30' : account && !isMobile ? '35' : isMobile ? '20' : '25'}%;
  display: inline-block;
  font-size: 15px;
`

const TimeText = styled.div`
  font-size: 12px;
  margin-top: -7px;
`

const AccountText = styled.div`
  @media screen and (max-width: 660px) {
    position: relative;
    top: -6px;
    font-size: 12px;
  }
`

const Metric = styled.div<{ width: number; align: string }>`
  width: ${({ width }) => `${width}%`};
  text-align: ${({ align }) => align};
  display: inline-block;
  vertical-align: top;
`

const TokenLogo = styled.div`
  display: inline-block;
  margin-right: 2px;
  vertical-align: top;
  width: 22px;
  margin-top: -1px;

  img {
    width: 100%;
  }
`

const LogoHelper = styled.span`
  display: inline-block;
  height: 100%;
  vertical-align: middle;
`

const Leverage = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.text3};
`

const Pnl = styled.span<{ color: string }>`
  color: ${({ theme, color }) => (color === 'green' ? theme.green1 : theme.red1)};
  font-weight: 600;
`

const PageSelect = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Arrow = styled.div<{ faded: boolean }>`
  color: ${({ theme }) => theme.text1};
  opacity: ${({ faded }) => (faded ? 0.3 : 1)};
  padding: 0 20px;
  user-select: none;

  :hover {
    cursor: ${({ faded }) => (faded ? 'auto' : 'pointer')} !important;
  }
`

const Forward = muiStyled(ArrowForwardIcon)(() => ({
  fontSize: 12,
  color: '#f9f9f9',
}))

const Backward = muiStyled(ArrowBackwardIcon)(() => ({
  fontSize: 12,
  color: '#f9f9f9',
}))

const SORT = {
  profit: 'profit',
  heldAmount: 'heldAmount',
  open: 'openTimestamp',
  close: 'closeTimestamp',
}

function isFrac(obj: any): obj is Fraction {
  return 'numerator' in obj && 'denominator' in obj && 'quotient' in obj
}

const fieldToVal = (field: Fraction | string | Date | any) => {
  if (!field) return 0
  else if (typeof field === 'string') return field
  else if (field instanceof Date) return field.getTime() / 1000
  else if (isFrac(field)) {
    if (JSBI.toNumber(field.denominator) > 0) return parseFloat(field.toFixed(4))
    else return 0
  } else return 0
}

interface PositionProps {
  position: MarginPosition
  index: number
  isMobile: boolean
  open?: boolean
  account?: boolean
}

const tokenRowComparator = (prevProps: PositionProps, nextProps: PositionProps) => {
  return (
    //TODO - have it compare the exact object
    prevProps.position === nextProps.position
  )
}

export function formatDate(date: string) {
  const [first, ...rest] = date
  if (first === 'a') return `1 ${rest.join('')}`
  return date.replace('minute', 'min')
}

export const PositionRow = React.memo<PositionProps>(function WrappedComponent({
  position,
  index,
  open,
  account,
  isMobile,
}: PositionProps) {
  const history = useHistory()
  const address = position.marginAccount.account
  const addressStart = address.substring(0, 5)
  const addressEnd = address.substring(address.length - 3)
  const symbol0 = position.heldToken.symbol
  const symbol1 = position.owedToken?.symbol
  const time = open ? position.openTimestamp : position.closeTimestamp!
  const date = moment.utc(time)
  const positive = position.profit.greaterThanOrEqual('0')
  const leverage = position.marginDeposit.asFraction.greaterThan('0')
    ? position.heldAmount.asFraction.divide(position.marginDeposit.asFraction)
    : ZERO_FRACTION
  let amount = `${position.heldAmount.toFixed(2, FORMATTER)}`
  let profit = `${position.profit.toFixed(2, FORMATTER).replace('-', '')}`
  if (isMobile) {
    amount = formatAmount(position.heldAmount, undefined, true, '0.00')
    profit = formatAmount(position.profit, undefined, true, '0.00')
  }
  return (
    <PositionWrapper disabled={account} onClick={() => history.push(`/position/${position.id}`)}>
      {account ? undefined : <Index>{index + 1}</Index>}
      <Account isMobile={isMobile} account={account} onClick={() => history.push(`/account/${address}`)}>
        {account ? (
          <TimeText>
            {formatDate(date.fromNow())}
            <br />
            {date.format('MMM D YYYY')}
          </TimeText>
        ) : (
          <AccountText>
            {addressStart}
            ...{isMobile ? <br /> : null}
            {addressEnd}
          </AccountText>
        )}
      </Account>
      <Metric width={isMobile ? 18 : 15} align={'left'}>
        <>
          <TokenLogo>
            <LogoHelper>
              <img src={getLogoOrDefault(symbol0 ?? '')} alt={`${symbol0} logo`} />
            </LogoHelper>
          </TokenLogo>
          <TokenLogo>
            <LogoHelper>
              <img src={getLogoOrDefault(symbol1 ?? '')} alt={`${symbol1} logo`} />
            </LogoHelper>
          </TokenLogo>
        </>
      </Metric>
      <Metric width={isMobile ? 29 : 27} align={'right'}>
        {amount}
        <Leverage>{` (${leverage.toFixed(0)}x)`}</Leverage>
      </Metric>
      <Metric width={23} align={'right'}>
        <Pnl color={positive ? 'green' : 'red'}>
          {positive ? '+' : '-'}
          {profit}
        </Pnl>
      </Metric>
    </PositionWrapper>
  )
},
tokenRowComparator)

const widths = {
  widths: [5, 15, 8, 20, 15],
  starts: [0, 10, 35, 57, 85],
  mobileWidths: [5, 10, 12, 20, 15],
  mobileStarts: [0, 10, 30, 57, 85],
}

const accountWidths = {
  widths: [15, 8, 20, 15],
  starts: [0, 35, 57, 85],
  mobileWidths: [10, 12, 20, 15],
  mobileStarts: [0, 30, 57, 85],
}

export default function Table({
  positions,
  loading,
  open,
  rows = 10,
  account,
}: {
  positions: MarginPosition[]
  loading: boolean
  open?: boolean
  rows?: number
  account?: boolean
}) {
  const { t } = useTranslation()
  const timeSort = open ? SORT.open : SORT.close
  const [sortField, setSortField] = useState(account ? timeSort : SORT.profit)
  const [sortDirection, setSortDirection] = useState<boolean>(true)
  const [page, setPage] = useState(1)
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    isLoading && !loading && setIsLoading(false)
  }, [loading, isLoading])

  const len = positions.length
  const lastPage = Math.floor(len / rows) + (len % rows === 0 ? 0 : 1)

  const sorted = useMemo(() => {
    return positions
      ? positions
          .sort((a, b) => {
            if (a && b) {
              return fieldToVal(a[sortField as keyof MarginPosition]) > fieldToVal(b[sortField as keyof MarginPosition])
                ? (sortDirection ? -1 : 1) * 1
                : (sortDirection ? -1 : 1) * -1
            } else {
              return -1
            }
          })
          .slice(rows * (page - 1), page * rows)
      : []
  }, [positions, page, rows, sortDirection, sortField])

  const handleSort = useCallback(
    (newField: string) => {
      setSortField(newField)
      setSortDirection(sortField !== newField ? true : !sortDirection)
    },
    [sortDirection, sortField],
  )

  const arrow = useCallback(
    (field: string) => {
      return sortField === field ? (!sortDirection ? '↑' : '↓') : ''
    },
    [sortDirection, sortField],
  )

  const { x, y } = useContext(PageSizeContext)
  const isMobile = x <= 660

  return (
    <TableWrapper>
      <ColumnTitles>
        {!account && (
          <Column width={10} textAlign={'left'}>
            #
          </Column>
        )}
        {account ? (
          <ColumnWrapper width={35} mobileWidth={30} textAlign={'left'} onClick={() => handleSort(timeSort)}>
            {open ? t('opened') : t('settled')} {arrow(timeSort)}
          </ColumnWrapper>
        ) : (
          <Column width={25} mobileWidth={20} textAlign={'left'}>
            {t('account')}
          </Column>
        )}
        <Column width={15} mobileWidth={18} textAlign={'left'}>
          {t('pair')}
        </Column>
        <ColumnWrapper width={27} mobileWidth={29} textAlign={'right'} onClick={() => handleSort(SORT.heldAmount)}>
          {t('position')} {arrow(SORT.heldAmount)}
        </ColumnWrapper>
        <ColumnWrapper width={23} textAlign={'right'} onClick={() => handleSort(SORT.profit)}>
          {t('pnl')} {arrow(SORT.profit)}
        </ColumnWrapper>
      </ColumnTitles>
      {isLoading ? (
        <TableLoader
          rows={rows}
          height={30}
          spacing={50}
          wrapperHeight={rows * 50 - 10}
          marginTop={20}
          widths={account ? accountWidths : widths}
          isMobile={isMobile}
        />
      ) : (
        sorted.map((position, index) => (
          <PositionRow
            key={`${index.toString()}`}
            position={position}
            index={index}
            isMobile={isMobile}
            open={open}
            account={account}
          />
        ))
      )}
      <PageSelect>
        <Arrow onClick={() => setPage(page === 1 ? page : page - 1)} faded={page === 1}>
          <Backward />
        </Arrow>
        {page}
        <Arrow onClick={() => setPage(page === lastPage ? page : page + 1)} faded={page === lastPage}>
          <Forward />
        </Arrow>
      </PageSelect>
    </TableWrapper>
  )
}
