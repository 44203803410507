import React, { useCallback, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { Currency, Token } from '@dolomite-exchange/v2-sdk'
import getLogoOrDefault from '../../../components/common/TokenLogos'
import { ReactComponent as DropDown } from '../../../assets/images/dropdown.svg'
import { darken } from 'polished'
import CurrencyModal from '../../../components/CurrencyModal'
import { useFiatValuesWithLoadingIndicator } from '../../../hooks/useFiatValue'
import { useDolomiteBalancesWithLoadingIndicator } from '../../../state/wallet/hooks'
import { useTradingTokens } from '../../../hooks/Tokens'
import { useActiveWeb3React } from '../../../hooks'

const SelectPoolWrapper = styled.div`
  width: 100%;
  text-align: left;
  margin: 0;
`

const Title = styled.div`
  font-weight: 700;
  margin-bottom: 5px;
  color: ${({ theme }) => theme.text1};
  width: fit-content;
  display: inline-block;
  vertical-align: top;

  h3 {
    margin: 0 !important;
  }

  @media (max-width: 400px) {
    width: 100%;
  }
`

const CurrencySelectWrapper = styled.div`
  margin-top: -4px;
  width: fit-content;
  display: inline-block;
  vertical-align: top;
  text-align: right;
  float: right;

  @media (max-width: 400px) {
    width: 100%;
    text-align: center;
    margin-top: 38px;
  }
`

const DescriptionWrapper = styled.div`
  width: 100%;
  text-align: left;
  font-size: 14px;
  font-weight: 300;
  margin-top: 5px;

  @media (max-width: 400px) {
    font-size: 12px;
    margin-top: calc(-4rem - 10px);
    margin-bottom: calc(2rem + 15px);
    height: 34px;
    transform: translateY(70px);
  }
`

const CurrencySelect = styled.button<{ selected: boolean }>`
  align-items: center;
  height: calc(2rem + 4px);
  font-size: 20px;
  font-weight: 500;
  background-color: ${({ selected, theme }) => (selected ? theme.bg1 : theme.primary1)};
  color: ${({ selected, theme }) => (selected ? theme.text1 : theme.white)};
  border-radius: 5px;
  box-shadow: ${({ selected }) => (selected ? 'none' : '0px 6px 10px rgba(0, 0, 0, 0.075)')};
  outline: none;
  cursor: pointer;
  user-select: none;
  border: none;
  margin-left: 4px;
  padding: 2px 6px;
  margin-top: -2px;

  :hover {
    background-color: ${({ selected, theme }) => (selected ? theme.bg2 : darken(0.05, theme.primary1))};
  }

  @media (max-width: 400px) {
    padding: 0 1rem;
    font-size: 18px;
    height: 2rem;
  }
`

const TokenLogo = styled.div`
  display: inline-block;
  margin-right: 5px;
  margin-top: 5px;
  vertical-align: top;
  width: 22px;

  img {
    width: 100%;
  }

  @media (max-width: 400px) {
    width: 22px;
  }
`

const TickerWrapper = styled.div`
  display: inline-block;
  vertical-align: top;
`

const Ticker = styled.div`
  color: #f9f9f9;
  font-size: 16px;
  font-weight: 400;
  margin-top: 0;
`

const Aligner = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const StyledDropDown = styled(DropDown)<{ selected: boolean }>`
  margin: 0 0.25rem 0 0.4rem;
  height: 35%;

  path {
    stroke: ${({ selected, theme }) => (selected ? theme.text1 : theme.white)};
    stroke-width: 1.5px;
  }
`

export default function SelectPool({
  primary,
  secondary,
  onPairChange,
}: {
  primary: Token | null | undefined
  secondary: Token | null | undefined
  onPairChange: (token0: Token, token1: Token) => void
}) {
  const { t } = useTranslation()
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [primaryModal, setPrimaryModal] = useState<boolean>(true)
  const { account } = useActiveWeb3React()
  const tokens = useTradingTokens()
  const tokenList = useMemo(() => Object.values(tokens), [tokens])
  const [dolomiteBalanceMap] = useDolomiteBalancesWithLoadingIndicator(account, tokenList)
  const [dolomiteFiatBalanceMap] = useFiatValuesWithLoadingIndicator(dolomiteBalanceMap, tokenList)

  const toggleModal = (secondary: boolean) => {
    setModalOpen(true)
    setPrimaryModal(!secondary)
  }

  const handleDismissSearch = useCallback(() => {
    setModalOpen(false)
  }, [setModalOpen])

  const onCurrencySelect = useCallback(
    (inputCurrency: Token) => {
      if (primaryModal && secondary) {
        onPairChange(inputCurrency, secondary)
      } else if (primary) {
        onPairChange(primary, inputCurrency)
      }
    },
    [primary, secondary, primaryModal, onPairChange],
  )

  return (
    <SelectPoolWrapper>
      <Title>
        <h3>{t('selectPool')}</h3>
      </Title>
      <CurrencySelectWrapper>
        {!!primary && !!secondary ? (
          [primary, secondary].map((currency: Currency, index: number) => {
            const symbol = currency.symbol === 'WETH' ? 'ETH' : currency.symbol
            return (
              <CurrencySelect
                selected={!!currency}
                className={'open-currency-select-button'}
                onClick={() => toggleModal(!!index)}
                key={`Currency-select-${index}`}
              >
                <Aligner>
                  <TokenLogo>
                    <img src={getLogoOrDefault(symbol ?? '')} alt={`${symbol} logo`} />
                  </TokenLogo>
                  <TickerWrapper>
                    <Ticker>{symbol}</Ticker>
                  </TickerWrapper>
                  <StyledDropDown selected={!!currency} />
                </Aligner>
              </CurrencySelect>
            )
          })
        ) : (
          <div />
        )}
      </CurrencySelectWrapper>
      <DescriptionWrapper>
        Add your tokens to a Dolomite AMM Liquidity pool to earn fees from trades performed on the pool.
      </DescriptionWrapper>
      {modalOpen ? (
        <CurrencyModal
          tokens={tokenList}
          balances={dolomiteBalanceMap}
          fiatBalances={dolomiteFiatBalanceMap}
          isOpen={modalOpen}
          onDismiss={handleDismissSearch}
          currencySelect={(currency: Currency) => {
            // onCurrencySelect(currency.wrapped)
            setModalOpen(false)
          }}
          balanceTitle={t('dolomiteBalance')}
        />
      ) : null}
    </SelectPoolWrapper>
  )
}
