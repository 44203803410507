import useInterval from '../useInterval'
import { useCallback, useState } from 'react'
import { reqParseFraction } from '../../state/trade/hooks'
import { ZERO_FRACTION } from '../../constants'
import truncateNumber from '../../utils/truncateNumber'
import { Fraction } from '@dolomite-exchange/sdk-core'
import fetchWithTimeout from '../../utils/fetchWithTimeout'

/**
 * @deprecated Please switch the `fetchWithTimeout` function to use a proper useGraphqlState hook
 */
export default function usePendleGlp2024Tvl(): Fraction {
  const [tvl, setTvl] = useState(ZERO_FRACTION)
  const getPtGlpApr = useCallback(async () => {
    fetchWithTimeout('https://api-v2.pendle.finance/core/v1/42161/markets/0x7d49e5adc0eaad9c027857767638613253ef125f')
      .response.then(response => response.json())
      .then(data => reqParseFraction(truncateNumber(data.liquidity?.usd, 18).toString()))
      .then(tvlFraction => setTvl(tvlFraction))
  }, [])
  useInterval(getPtGlpApr, 15_000)
  return tvl
}
