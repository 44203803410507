import { createAction } from '@reduxjs/toolkit'
import { ChainId } from '../../constants'

import { RefreshFrequency } from '../chain/hooks'

export enum GraphqlClientType {
  Dolomite = 'Dolomite',
  Blocks = 'Blocks',
  Galxe = 'Galxe',
  Fetch = 'Fetch',
  Post = 'Post',
  Functional = 'Functional',
  NoOp = 'NoOp',
  Web3 = 'Web3',
}

export enum Web3CallType {
  Call = 'Call',
  GetEnsAvatars = 'GetEnsAvatars',
  GetEnsNames = 'GetEnsNames',
  ReverseEnsNames = 'ReverseEnsNames',
}

export interface GraphqlCall {
  chainId: ChainId
  clientType: GraphqlClientType
  query: string | null
  variables: string | null
}

export function toGraphqlCallKey(calls: GraphqlCall[]): string {
  return calls.map(call => `${call.chainId}~~${call.clientType}~~${call.query}~~${call.variables}`).join('<>')
}

export function parseCallKey(allCallsKey: string): GraphqlCall[] {
  if (allCallsKey === '') {
    return []
  }

  const callKeys = allCallsKey.split('<>')
  if (callKeys.length === 0) {
    throw new Error(`Invalid call keys: ${allCallsKey}`)
  }

  return callKeys.map(callKey => {
    const pcs = callKey.split('~~')
    if (pcs.length !== 4) {
      throw new Error(`Invalid call key: ${callKey}`)
    }
    return {
      chainId: Number(pcs[0]),
      clientType: pcs[1] as GraphqlClientType,
      query: pcs[2] === 'null' ? null : pcs[2],
      variables: pcs[3] === 'null' ? null : pcs[3],
    }
  })
}

export interface ListenerOptions {
  /**
   * Enum to specify how often the data should be fetched in seconds
   */
  readonly refreshFrequency: RefreshFrequency
}

export const addGraphqlListeners = createAction<{ chainId: ChainId; calls: GraphqlCall[]; options: ListenerOptions }>(
  'graphql/addGraphqlListeners',
)
export const removeGraphqlListeners = createAction<{
  chainId: ChainId
  calls: GraphqlCall[]
  options: ListenerOptions
}>('graphql/removeGraphqlListeners')
export const fetchingGraphqlResults = createAction<{
  chainId: ChainId
  callsList: GraphqlCall[][]
  fetchingTimestamp: number
}>('graphql/fetchingGraphqlResults')
export const errorFetchingGraphqlResults = createAction<{
  chainId: ChainId
  calls: GraphqlCall[]
  fetchingTimestamp: number
}>('graphql/errorFetchingGraphqlResults')

export interface UpgradeGraphqlResult {
  data: string | undefined
  error: string | undefined
  subgraphUrlIndex: number
}

export const updateGraphqlResults = createAction<{
  chainId: ChainId
  timestamp: number
  results: Record<string, UpgradeGraphqlResult[]>
}>('graphql/updateGraphqlResults')
