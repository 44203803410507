import {
  ALLOWED_PRICE_IMPACT_HIGH,
  ALLOWED_PRICE_IMPACT_LOW,
  ALLOWED_PRICE_IMPACT_MEDIUM,
  BLOCKED_PRICE_IMPACT_NON_EXPERT,
} from '../constants'
import { Currency, CurrencyAmount, Fraction, Percent, Trade, TradeType, Price } from '@dolomite-exchange/v2-sdk'
import { Field } from '../state/trade/actions'
import { basisPointsToPercent } from './index'
import JSBI from 'jsbi'
import { Market } from '../types/Market'
import cleanCurrencySymbol from './cleanCurrencySymbol'

const BASE_FEE = new Percent(JSBI.BigInt(30), JSBI.BigInt(10000))
const ONE_HUNDRED_PERCENT = new Percent(JSBI.BigInt(10000), JSBI.BigInt(10000))
const INPUT_FRACTION_AFTER_FEE = ONE_HUNDRED_PERCENT.subtract(BASE_FEE)

// computes price breakdown for the trade
export function computeTradePriceBreakdown(
  trade?: Trade<Currency, Currency, TradeType> | null,
): { priceImpactWithoutFee: Percent | undefined; realizedLPFee: CurrencyAmount<Currency> | undefined | null } {
  if (!trade) {
    return { priceImpactWithoutFee: undefined, realizedLPFee: undefined }
  }

  // for each hop in our trade, take away the x*y=k price impact from 0.3% fees
  // e.g. for 3 tokens/2 hops: 1 - ((1 - .03) * (1-.03))
  const realizedLPFee = ONE_HUNDRED_PERCENT.subtract(
    trade.route.pairs.reduce<Fraction>(
      (currentFee: Fraction): Fraction => currentFee.multiply(INPUT_FRACTION_AFTER_FEE),
      ONE_HUNDRED_PERCENT,
    ),
  )

  // remove lp fees from price impact
  // the x*y=k impact
  const priceImpactWithoutFeePercent =
    trade && realizedLPFee ? Percent.fromFraction(trade.priceImpact.subtract(realizedLPFee)) : undefined

  const realizedLPFeeAmount = CurrencyAmount.fromRawAmount(
    trade.inputAmount.currency,
    realizedLPFee.multiply(trade.inputAmount.quotient).quotient,
  )

  return { priceImpactWithoutFee: priceImpactWithoutFeePercent, realizedLPFee: realizedLPFeeAmount }
}

// computes the minimum amount out and maximum amount in for a trade given a user specified allowed slippage in bips
export function computeSlippageAdjustedAmounts(
  trade: Trade<Currency, Currency, TradeType> | undefined,
  allowedSlippage: number,
): { [field in Field]?: CurrencyAmount<Currency> } {
  const pct = basisPointsToPercent(allowedSlippage)
  return {
    [Field.INPUT]: trade?.maximumAmountIn(pct),
    [Field.OUTPUT]: trade?.minimumAmountOut(pct),
  }
}

export function warningSeverity(priceImpact: Percent | undefined): 0 | 1 | 2 | 3 | 4 {
  if (!priceImpact?.lessThan(BLOCKED_PRICE_IMPACT_NON_EXPERT)) return 4
  if (!priceImpact.lessThan(ALLOWED_PRICE_IMPACT_HIGH)) return 3
  if (!priceImpact.lessThan(ALLOWED_PRICE_IMPACT_MEDIUM)) return 2
  if (!priceImpact.lessThan(ALLOWED_PRICE_IMPACT_LOW)) return 1
  return 0
}

export function getFormattedPriceForUIFromTrade(
  price: Price<Currency, Currency> | undefined,
  trade: Trade<Currency, Currency, TradeType>,
  inverted?: boolean,
): string {
  if (!price) {
    return ''
  }

  const inputSymbol = cleanCurrencySymbol(trade.inputAmount.currency)
  const outputSymbol = cleanCurrencySymbol(trade.outputAmount.currency)

  return inverted
    ? `${price.invert().toSignificant(6)} ${inputSymbol} per ${outputSymbol}`
    : `${price.toSignificant(6)} ${outputSymbol} per ${inputSymbol}`
}

export function getFormattedPriceForUIFromMarket(
  price: Price<Currency, Currency> | undefined,
  market: Market,
  inverted?: boolean,
): string {
  if (!price) {
    return ''
  }

  const primarySymbol = cleanCurrencySymbol(market.primaryToken)
  const secondarySymbol = cleanCurrencySymbol(market.secondaryToken)

  return inverted
    ? `${price.invert().toSignificant(6)} ${primarySymbol} per ${secondarySymbol}`
    : `${price.toSignificant(6)} ${secondarySymbol} per ${primarySymbol}`
}
