import moment from 'moment'

/**
 * Given a millisecond timestamp, return it in a string form that has an appropriate date/time description based on how recent it was
 * @param timestamp to return formatted date string of
 * @param isFullDate whether to return a full date string or a relative one
 */
export default function toDate(timestamp: number | Date, isFullDate?: boolean, includeYear?: boolean): string {
  const jsDate = timestamp instanceof Date ? timestamp : new Date(timestamp)
  if (isFullDate) {
    return jsDate.toLocaleString([])
  }

  const date = moment.utc(jsDate)
  if (Date.now() < jsDate.getTime()) {
    return moment(date)
      .fromNow()
      .replace('in ', '')
      .replace('an hour', 'one hour')
      .replace('a month', 'one month')
  }

  const today = moment().startOf('day')
  const isToday = date.isSame(today, 'd')
  const isThisYear = date.isSame(today, 'y')

  if (date.isAfter(moment().subtract(1.5, 'hours'))) {
    if (date.isAfter(moment().subtract(1, 'hours'))) {
      return moment(date)
        .fromNow()
        .replace('minutes', 'mins')
        .replace('minute', 'min')
    } else {
      return '1 hour ago'
    }
  }
  if (!isThisYear || includeYear) {
    return date.format('MMM D YYYY')
  } else {
    if (isToday) {
      return jsDate.toLocaleString([], {
        hour: '2-digit',
        minute: '2-digit',
      })
    } else {
      const format = isToday ? 'h:mm a' : 'MMM D'
      return date.format(format)
    }
  }
}
