import { SPECIAL_ASSET_MAP, SpecialAsset, SpecialAssetSymbol } from '../special-assets'
import { ChainId, JONES_USDC_ADDRESSES, JONES_USDC_ISOLATION_MODE_ADDRESSES, STABLECOINS } from '../../index'
import { Token } from '@dolomite-exchange/v2-sdk'
import { calculateJonesUSDCProxyVaultAddress } from '../../../utils/isolation/calculateProxyVaultAddress'
import { getOptionally } from '../special-assets-util-functions'

export const J_USDC_SPECIAL_ASSET: () => SpecialAsset = () => ({
  symbol: SpecialAssetSymbol.djUSDC,
  safeSymbol: SpecialAssetSymbol.djUSDC,
  cleanSymbol: 'jUSDC',
  cleanName: 'Jones USDC',
  hasExternalRewards: true,
  documentationUrl: 'https://docs.dolomite.io/integrations/jones-dao-jusdc',
  isIsolationMode: true,
  isBorrowingEnabled: false,
  chainIdToAddressMap: JONES_USDC_ISOLATION_MODE_ADDRESSES,
  isolationModeInfo: {
    getWrappedToken: (chainId: ChainId) =>
      getOptionally(
        JONES_USDC_ISOLATION_MODE_ADDRESSES[chainId],
        address => new Token(chainId, address, 18, SpecialAssetSymbol.djUSDC, 'Dolomite Isolation: Jones USDC'),
      ),
    getUnderlyingToken: getJonesUSDCUnderlyingToken,
    getUnderlyingSpenderToken: getJonesUSDCUnderlyingToken,
    allowableDebtTokens: (chainId: ChainId) => {
      const wrappedToken = SPECIAL_ASSET_MAP[SpecialAssetSymbol.djUSDC]?.isolationModeInfo?.getWrappedToken(chainId)
      return wrappedToken ? STABLECOINS[chainId].concat(wrappedToken) : undefined
    },
    // only allowable collateral is jUSDC token itself
    allowableCollateralTokens: (chainId: ChainId) => {
      const wrappedToken = SPECIAL_ASSET_MAP[SpecialAssetSymbol.djUSDC]?.isolationModeInfo?.getWrappedToken(chainId)
      return wrappedToken ? STABLECOINS[chainId].concat(wrappedToken) : undefined
    },
    remapAccountAddress: calculateJonesUSDCProxyVaultAddress,
    isAsync: false,
  },
})

function getJonesUSDCUnderlyingToken(chainId: ChainId): Token | undefined {
  return getOptionally(JONES_USDC_ADDRESSES[chainId], address => new Token(chainId, address, 18, 'jUSDC', 'Jones USDC'))
}
