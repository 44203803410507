import { SpecialAsset, SpecialAssetSymbol } from '../special-assets'
import { GRAI } from '../../tokens/GRAI'
import mapChainIdMapValues from '../../../utils/mapChainIdMapValues'

export const GRAI_SPECIAL_ASSET: () => SpecialAsset = () => ({
  symbol: SpecialAssetSymbol.GRAI,
  safeSymbol: SpecialAssetSymbol.GRAI,
  cleanSymbol: 'GRAI',
  cleanName: 'Gravita Debt Token',
  hasExternalRewards: true,
  documentationUrl: undefined,
  isIsolationMode: false,
  isBorrowingEnabled: true,
  chainIdToAddressMap: mapChainIdMapValues(GRAI, t => t?.address),
  isolationModeInfo: undefined,
})
