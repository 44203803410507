import { Pair, Token } from '@dolomite-exchange/v2-sdk'
import { ChainId } from '../constants'

function replaceMigratedTokenSymbol(token?: Token, chainId?: number): string | undefined {
  if (token?.symbol === 'USDC.e' && chainId === ChainId.ARBITRUM_ONE) {
    return 'USDC'
  }
  return token?.symbol
}

export default function getDomainName(pair?: Pair, chainId?: number): string {
  const symbol0 = replaceMigratedTokenSymbol(pair?.token0, chainId)
  const symbol1 = replaceMigratedTokenSymbol(pair?.token1, chainId)
  return `Dolomite LP Token: ${symbol0}_${symbol1}`
}
