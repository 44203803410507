import ArbitrumLogo from '../assets/svg/arbitrum_logo.svg'
import BaseLogo from '../assets/svg/base_logo.svg'
import BerachainLogo from '../assets/svg/berachain_logo.svg'
import EthereumLogo from '../assets/svg/ethereum_logo.svg'
import MantleLogo from '../assets/svg/mantle_logo.svg'
import PolygonLogo from '../assets/svg/polygon_logo.svg'
import XLayerLogo from '../assets/svg/xlayer_logo.svg'
import { useMemo } from 'react'

export enum ChainId {
  MAINNET = 1,
  ARBITRUM_ONE = 42161,
  BASE = 8453,
  BERACHAIN = 80084,
  MANTLE = 5000,
  POLYGON_ZKEVM = 1101,
  X_LAYER = 196,
}

export type ChainIdMap<T> = Record<ChainId, T>

export type NetworkLogo = Record<string, any>

interface Network {
  chainId: ChainId
  label: string
  logo: string
  isActive: boolean
}

export const KEY_CHAIN_ID = 'CHAIN_ID'
export const KEY_CHAIN_ID_TO_BLOCK_NUMBER_MAP = 'CHAIN_ID_TO_BLOCK_NUMBER_MAP'
export const KEY_CHAIN_ID_TO_BLOCK_TIMESTAMP_MAP = 'CHAIN_ID_TO_BLOCK_TIMESTAMP_MAP'
export const KEY_CHAIN_ID_TO_SUBGRAPH_BLOCK_NUMBER_MAP = 'CHAIN_ID_TO_SUBGRAPH_BLOCK_NUMBER_MAP'

export const CHAIN_ID_MAP: ChainIdMap<number> = {
  [ChainId.MAINNET]: 1,
  [ChainId.ARBITRUM_ONE]: 42161,
  [ChainId.BASE]: 8453,
  [ChainId.BERACHAIN]: 80084,
  [ChainId.MANTLE]: 5000,
  [ChainId.POLYGON_ZKEVM]: 1101,
  [ChainId.X_LAYER]: 196,
}

export const CHAIN_IDS_WITH_DEPLOYMENT: ChainId[] = Object.keys(CHAIN_ID_MAP)
  .map(c => parseInt(c) as ChainId)
  .filter(c => c !== ChainId.MAINNET)

const cachedChainId = window.localStorage.getItem(KEY_CHAIN_ID)
export const CHAIN_ID: ChainId =
  new URLSearchParams(window.location.search).get('network') &&
  ChainId[parseInt(new URLSearchParams(window.location.search).get('network') as string, 10) as ChainId]
    ? (parseInt(new URLSearchParams(window.location.search).get('network') as string, 10) as ChainId)
    : cachedChainId && !Number.isNaN(parseInt(cachedChainId))
    ? (parseInt(cachedChainId, 10) as ChainId)
    : (parseInt(process.env.REACT_APP_CHAIN_ID ?? `${ChainId.ARBITRUM_ONE}`, 10) as ChainId)
if (!(CHAIN_ID in ChainId)) {
  throw new Error('CHAIN_ID must be a valid ChainId')
}

export const NETWORK_LABELS: ChainIdMap<string> = {
  [ChainId.MAINNET]: 'Ethereum Mainnet',
  [ChainId.ARBITRUM_ONE]: 'Arbitrum',
  [ChainId.BASE]: 'Base',
  [ChainId.BERACHAIN]: 'Berachain Bartio',
  [ChainId.MANTLE]: 'Mantle',
  [ChainId.POLYGON_ZKEVM]: 'Polygon zkEVM',
  [ChainId.X_LAYER]: 'X Layer',
}

export const NETWORK_LOGOS: NetworkLogo = {
  [ChainId.MAINNET]: EthereumLogo,
  [ChainId.ARBITRUM_ONE]: ArbitrumLogo,
  [ChainId.BASE]: BaseLogo,
  [ChainId.BERACHAIN]: BerachainLogo,
  [ChainId.MANTLE]: MantleLogo,
  [ChainId.POLYGON_ZKEVM]: PolygonLogo,
  [ChainId.X_LAYER]: XLayerLogo,
}

export const NETWORKS: ChainIdMap<Network> = {
  [ChainId.MAINNET]: {
    chainId: ChainId.MAINNET,
    label: NETWORK_LABELS[ChainId.MAINNET],
    logo: NETWORK_LOGOS[ChainId.MAINNET],
    isActive: false,
  },
  [ChainId.ARBITRUM_ONE]: {
    chainId: ChainId.ARBITRUM_ONE,
    label: NETWORK_LABELS[ChainId.ARBITRUM_ONE],
    logo: NETWORK_LOGOS[ChainId.ARBITRUM_ONE],
    isActive: true,
  },
  [ChainId.BASE]: {
    chainId: ChainId.BASE,
    label: NETWORK_LABELS[ChainId.BASE],
    logo: NETWORK_LOGOS[ChainId.BASE],
    isActive: false,
  },
  [ChainId.BERACHAIN]: {
    chainId: ChainId.BERACHAIN,
    label: NETWORK_LABELS[ChainId.BERACHAIN],
    logo: NETWORK_LOGOS[ChainId.BERACHAIN],
    isActive: true,
  },
  [ChainId.MANTLE]: {
    chainId: ChainId.MANTLE,
    label: NETWORK_LABELS[ChainId.MANTLE],
    logo: NETWORK_LOGOS[ChainId.MANTLE],
    isActive: true,
  },
  [ChainId.POLYGON_ZKEVM]: {
    chainId: ChainId.POLYGON_ZKEVM,
    label: NETWORK_LABELS[ChainId.POLYGON_ZKEVM],
    logo: NETWORK_LOGOS[ChainId.POLYGON_ZKEVM],
    isActive: true,
  },
  [ChainId.X_LAYER]: {
    chainId: ChainId.X_LAYER,
    label: NETWORK_LABELS[ChainId.X_LAYER],
    logo: NETWORK_LOGOS[ChainId.X_LAYER],
    isActive: true,
  },
}

export const ACTIVE_NETWORKS: ChainId[] = Object.values(NETWORKS)
  .filter(n => n.isActive)
  .map(n => n.chainId)

export function initializeObjectChainIdMap(): ChainIdMap<any> {
  return {
    [ChainId.MAINNET]: {},
    [ChainId.ARBITRUM_ONE]: {},
    [ChainId.BASE]: {},
    [ChainId.BERACHAIN]: {},
    [ChainId.MANTLE]: {},
    [ChainId.POLYGON_ZKEVM]: {},
    [ChainId.X_LAYER]: {},
  }
}

export function initializeObjectUndefinedChainIdMap(): ChainIdMap<any | undefined> {
  return {
    [ChainId.MAINNET]: undefined,
    [ChainId.ARBITRUM_ONE]: undefined,
    [ChainId.BASE]: undefined,
    [ChainId.BERACHAIN]: undefined,
    [ChainId.MANTLE]: undefined,
    [ChainId.POLYGON_ZKEVM]: undefined,
    [ChainId.X_LAYER]: undefined,
  }
}

export function initializeStringOptChainIdMap(value: Record<string, any>): ChainIdMap<string | undefined> {
  return {
    [ChainId.MAINNET]: value[ChainId.MAINNET]?.address,
    [ChainId.ARBITRUM_ONE]: value[ChainId.ARBITRUM_ONE]?.address,
    [ChainId.BASE]: value[ChainId.BASE]?.address,
    [ChainId.BERACHAIN]: value[ChainId.BERACHAIN]?.address,
    [ChainId.MANTLE]: value[ChainId.MANTLE]?.address,
    [ChainId.POLYGON_ZKEVM]: value[ChainId.POLYGON_ZKEVM]?.address,
    [ChainId.X_LAYER]: value[ChainId.X_LAYER]?.address,
  }
}

export function initializeSingleValueChainIdMap<T>(chainId: ChainId, value: T): ChainIdMap<T | undefined> {
  return {
    [ChainId.MAINNET]: undefined,
    [ChainId.ARBITRUM_ONE]: undefined,
    [ChainId.BASE]: undefined,
    [ChainId.BERACHAIN]: undefined,
    [ChainId.MANTLE]: undefined,
    [ChainId.POLYGON_ZKEVM]: undefined,
    [ChainId.X_LAYER]: undefined,
    [chainId]: value,
  }
}

export function initializeNumberChainIdMap(): ChainIdMap<number> {
  return {
    [ChainId.MAINNET]: 0,
    [ChainId.ARBITRUM_ONE]: 0,
    [ChainId.BASE]: 0,
    [ChainId.BERACHAIN]: 0,
    [ChainId.MANTLE]: 0,
    [ChainId.POLYGON_ZKEVM]: 0,
    [ChainId.X_LAYER]: 0,
  }
}

export function isTestnet(chainId: ChainId): boolean {
  return !isMainnet(chainId)
}

export function isMainnet(chainId: ChainId): boolean {
  return (
    chainId === ChainId.MAINNET ||
    chainId === ChainId.ARBITRUM_ONE ||
    chainId === ChainId.BASE ||
    chainId === ChainId.MANTLE ||
    chainId === ChainId.POLYGON_ZKEVM ||
    chainId === ChainId.X_LAYER
  )
}

export function isArbitrum(chainId: ChainId): boolean {
  return chainId === ChainId.ARBITRUM_ONE
}

export function isBase(chainId: ChainId): boolean {
  return chainId === ChainId.BASE
}

export function isBerachain(chainId: ChainId): boolean {
  return chainId === ChainId.BERACHAIN
}

export function isMantle(chainId: ChainId): boolean {
  return chainId === ChainId.MANTLE
}

export function isPolygon(chainId: ChainId): boolean {
  return chainId === ChainId.POLYGON_ZKEVM
}

export function isXLayer(chainId: ChainId): boolean {
  return chainId === ChainId.X_LAYER
}

export function useChainIdsTransformer<T>(memoizedTransformer: (chainId: ChainId) => T): T[] {
  return useMemo(() => {
    return CHAIN_IDS_WITH_DEPLOYMENT.map(memoizedTransformer)
  }, [memoizedTransformer])
}
