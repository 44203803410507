import { CurrencyAmount, Token } from '@dolomite-exchange/v2-sdk'
import { Web3CallbackState } from '../useTradeCallback'
import { estimateGasAsync, SuccessfulContractCall, useActiveWeb3React } from '../index'
import useIsolationModeUserVaultAddressIfCreated from '../useIsolationModeUserVaultAddressIfCreated'
import { useDplvGlpUserVaultContract } from '../useContract'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { useMemo } from 'react'
import { USER_ERROR_CODES, ZERO_FRACTION } from '../../constants'
import { calculateGasMargin } from '../../utils'
import { useDplvGLPSpecialAsset } from '../../constants/isolation/special-assets'

enum StakeOrUnstakePlvGlpMethodName {
  STAKE_PLV_GLP = 'stakePlvGlp',
  UNSTAKE_PLV_GLP = 'unstakePlvGlp',
}

export function useStakePlvGlp(
  parsedInputAmount: CurrencyAmount<Token> | undefined,
): { state: Web3CallbackState; callback: null | (() => Promise<string>); error: string | null } {
  return useStakeOrUnstakePlvGlp(parsedInputAmount, StakeOrUnstakePlvGlpMethodName.STAKE_PLV_GLP)
}

export function useUnstakePlvGlp(
  parsedInputAmount: CurrencyAmount<Token> | undefined,
): { state: Web3CallbackState; callback: null | (() => Promise<string>); error: string | null } {
  return useStakeOrUnstakePlvGlp(parsedInputAmount, StakeOrUnstakePlvGlpMethodName.UNSTAKE_PLV_GLP)
}

export function useHarvestPlvGlpRewards(): {
  state: Web3CallbackState
  callback: null | (() => Promise<string>)
  error: string | null
} {
  const { account } = useActiveWeb3React()
  const dplvGlp = useDplvGLP()
  const vaultAddress = useIsolationModeUserVaultAddressIfCreated(dplvGlp)
  const vaultContract = useDplvGlpUserVaultContract(vaultAddress)
  const addTransaction = useTransactionAdder()

  return useMemo(() => {
    if (!vaultContract || !dplvGlp || !account) {
      return {
        state: Web3CallbackState.INVALID,
        callback: null,
        error: 'Missing dependencies',
      }
    }

    const contract = vaultContract
    const methodName = 'harvest'
    const params: string[] = []

    return {
      state: Web3CallbackState.VALID,
      callback: async function onCallFunctions(): Promise<string> {
        const estimatedCall = await estimateGasAsync(contract, methodName, params)
        let successfulCall: SuccessfulContractCall
        if ('gasEstimate' in estimatedCall) {
          successfulCall = estimatedCall
        } else {
          throw estimatedCall.error
        }

        return contract[methodName](...params, {
          gasLimit: calculateGasMargin(successfulCall.gasEstimate),
          from: account,
        })
          .then((response: any) => {
            const summary = 'Harvest PLS rewards'
            addTransaction(response, { summary })
            return response.hash
          })
          .catch((error: any) => {
            // if the user rejected the tx, pass this along
            if (error?.code === USER_ERROR_CODES.REJECTED) {
              throw new Error('transaction-rejected')
            } else {
              // otherwise, the error was unexpected, and we need to convey that
              console.error('Call function failed:', error, methodName, params)
              throw new Error(`Call function failed: ${error.message}`)
            }
          })
      },
      error: null,
    }
  }, [vaultContract, dplvGlp, account, addTransaction])
}

function useStakeOrUnstakePlvGlp(
  parsedInputAmount: CurrencyAmount<Token> | undefined,
  methodName: StakeOrUnstakePlvGlpMethodName,
): { state: Web3CallbackState; callback: null | (() => Promise<string>); error: string | null } {
  const { account } = useActiveWeb3React()
  const dplvGlp = useDplvGLP()
  const vaultAddress = useIsolationModeUserVaultAddressIfCreated(dplvGlp)
  const vaultContract = useDplvGlpUserVaultContract(vaultAddress)
  const addTransaction = useTransactionAdder()

  return useMemo(() => {
    if (!vaultContract || !dplvGlp || !account || !parsedInputAmount || parsedInputAmount.equalTo(ZERO_FRACTION)) {
      return {
        state: Web3CallbackState.INVALID,
        callback: null,
        error: 'Missing dependencies',
      }
    }

    const contract = vaultContract
    const params = [parsedInputAmount.quotient.toString()]

    return {
      state: Web3CallbackState.VALID,
      callback: async function onCallFunctions(): Promise<string> {
        const estimatedCall = await estimateGasAsync(contract, methodName, params)
        let successfulCall: SuccessfulContractCall
        if ('gasEstimate' in estimatedCall) {
          successfulCall = estimatedCall
        } else {
          throw estimatedCall.error
        }

        return contract[methodName](...params, {
          gasLimit: calculateGasMargin(successfulCall.gasEstimate),
          from: account,
        })
          .then((response: any) => {
            let summary = ''
            if (methodName === StakeOrUnstakePlvGlpMethodName.STAKE_PLV_GLP) {
              summary = 'Stake plvGLP'
            } else if (methodName === StakeOrUnstakePlvGlpMethodName.UNSTAKE_PLV_GLP) {
              summary = 'Unstake plvGLP'
            }
            addTransaction(response, { summary })
            return response.hash
          })
          .catch((error: any) => {
            // if the user rejected the tx, pass this along
            if (error?.code === USER_ERROR_CODES.REJECTED) {
              throw new Error('transaction-rejected')
            } else {
              // otherwise, the error was unexpected, and we need to convey that
              console.error('Call function failed:', error, methodName, params)
              throw new Error(`Call function failed: ${error.message}`)
            }
          })
      },
      error: null,
    }
  }, [vaultContract, dplvGlp, account, parsedInputAmount, methodName, addTransaction])
}

function useDplvGLP(): Token | undefined {
  const { chainId } = useActiveWeb3React()
  const dplvGLP = useDplvGLPSpecialAsset()
  return useMemo(() => dplvGLP.isolationModeInfo?.getWrappedToken(chainId), [chainId, dplvGLP])
}
