import { Percent } from '@dolomite-exchange/sdk-core'
import {
  ALWAYS_ZERO_INTEREST_SETTER_ADDRESSES,
  ChainId,
  DOUBLE_EXPONENT_INTEREST_SETTER_ADDRESSES,
  ONE_FRACTION,
  ONE_HUNDRED_PERCENT,
  ZERO_PERCENT,
} from '../constants'
import JSBI from 'jsbi'
import { InterestRate } from '../types/interestRateData'
import { DolomiteMarginData } from '../types/dolomiteMarginData'
import calculateAprToApy from './calculateAprToApy'

export default function getInterestRateAtUtilization(
  utilization: Percent,
  chainId: ChainId,
  tokenId: JSBI | undefined,
  interestRateObj: InterestRate | undefined,
  dolomiteMargin: DolomiteMarginData | undefined,
  showYieldAsApr: boolean,
): {
  borrowInterestRate: Percent
  supplyInterestRate: Percent
} {
  if (!tokenId || !interestRateObj || !dolomiteMargin) {
    return {
      borrowInterestRate: ZERO_PERCENT,
      supplyInterestRate: ZERO_PERCENT,
    }
  }

  if (interestRateObj.interestSetter === ALWAYS_ZERO_INTEREST_SETTER_ADDRESSES[chainId]) {
    return {
      borrowInterestRate: ZERO_PERCENT,
      supplyInterestRate: ZERO_PERCENT,
    }
  } else if (interestRateObj.interestSetter === DOUBLE_EXPONENT_INTEREST_SETTER_ADDRESSES[chainId]) {
    console.warn('Double exponent interest rate model not yet supported')
    return {
      borrowInterestRate: ZERO_PERCENT,
      supplyInterestRate: ZERO_PERCENT,
    }
  } else {
    return getLinearStepFunctionInterestRate(utilization, interestRateObj, dolomiteMargin, showYieldAsApr)
  }
}

function getLinearStepFunctionInterestRate(
  utilization: Percent,
  interestRateObj: InterestRate,
  dolomiteMargin: DolomiteMarginData,
  showYieldAsApr: boolean,
): { borrowInterestRate: Percent; supplyInterestRate: Percent } {
  const lowerOptimalRate = Percent.fromFraction(interestRateObj.lowerOptimalRate)
  const upperOptimalRate = Percent.fromFraction(interestRateObj.upperOptimalRate)
  const maxInterestRate = lowerOptimalRate.add(upperOptimalRate)
  const optimalUtilizationRate = interestRateObj.optimalUtilizationRate

  let borrowInterestRate: Percent
  if (utilization.equalTo(ZERO_PERCENT)) {
    borrowInterestRate = ZERO_PERCENT
  } else if (utilization.greaterThanOrEqual(ONE_HUNDRED_PERCENT)) {
    borrowInterestRate = maxInterestRate
  } else if (utilization.greaterThan(optimalUtilizationRate)) {
    const deltaToFullUtilization = ONE_FRACTION.subtract(optimalUtilizationRate)
    const interestToAdd = upperOptimalRate
      .multiply(utilization.subtract(optimalUtilizationRate))
      .divide(deltaToFullUtilization)
    borrowInterestRate = lowerOptimalRate.add(interestToAdd)
  } else {
    borrowInterestRate = lowerOptimalRate.multiply(utilization).divide(optimalUtilizationRate)
  }

  const supplyInterestRate = borrowInterestRate.multiply(dolomiteMargin.earningsRate).multiply(utilization)
  return {
    borrowInterestRate: showYieldAsApr ? borrowInterestRate : calculateAprToApy(borrowInterestRate),
    supplyInterestRate: showYieldAsApr ? supplyInterestRate : calculateAprToApy(supplyInterestRate),
  }
}
