import { Fraction } from '@dolomite-exchange/sdk-core'
import { MarginPositionType } from '../types/marginPositionData'
import { ONE_FRACTION, ZERO_FRACTION } from '../constants'

export default function calculateLeverage(
  positionSize: Fraction,
  owedAmount: Fraction,
  owedPrice: Fraction | undefined,
  positionType?: MarginPositionType,
): Fraction {
  if (!owedPrice || positionSize.equalTo(ZERO_FRACTION)) {
    return ZERO_FRACTION
  }

  const collateralization = positionSize.asFraction.divide(owedAmount.multiply(owedPrice).asFraction)

  return ONE_FRACTION.divide(ONE_FRACTION.subtract(ONE_FRACTION.divide(collateralization))).subtract(
    positionType && positionType === MarginPositionType.SHORT ? ONE_FRACTION : ZERO_FRACTION,
  )
}
