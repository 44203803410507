import { GalxeNFTInfo } from './gqlTypeHelpers'
import { useMemo } from 'react'
import { useGraphqlResult } from '../state/graphql/hooks'
import { GraphqlClientType } from '../state/graphql/actions'
import { RefreshFrequency } from '../state/chain/hooks'
import { DOLOMITE_API_SERVER_URL } from '@dolomite-exchange/zap-sdk'

export interface GalxeAddressData {
  id: string
  campaignID: string
  campaignName: string
  campaignXP: string
}

interface AddressDataResult {
  data?: {
    allNFTsByOwnersCoresAndChain?: {
      nfts?: GalxeNFTInfo[]
    }[]
  }
}

const levels = [...new Array(20)].map((_, i) => (i * i + i) * 5)

export function useGalxeAddressData(
  walletAddress: string | undefined,
): {
  loading: boolean
  error: boolean
  data: GalxeAddressData[] | undefined
  levelData: { level: number; start: number; end: number; total: number }
} {
  const variables = useMemo(() => {
    if (!walletAddress) {
      return undefined
    }
    return {
      walletAddress: walletAddress.toLowerCase(),
    }
  }, [walletAddress])
  const state = useGraphqlResult<AddressDataResult>(
    GraphqlClientType.Fetch,
    walletAddress ? `${DOLOMITE_API_SERVER_URL}/galxe/oats/${walletAddress}` : undefined,
    variables,
    RefreshFrequency.Slow,
  )

  return useMemo(() => {
    const { loading, error, result } = state
    const anyLoading = Boolean(loading)
    const anyError = Boolean(error)

    const addressData = (result?.data?.allNFTsByOwnersCoresAndChain?.[0]?.nfts ?? [])
      .filter(value => value.campaign.dao?.id === '2139' || value.campaign.space?.id === '2139')
      .map<GalxeAddressData>(value => {
        const traits = value.campaign.nftTemplates?.[0]?.traits
        const xp = traits?.find(t => t.name.toLowerCase().includes('exp'))
        return {
          id: value.id,
          campaignID: value.campaign.id,
          campaignName: value.campaign.name,
          campaignXP: xp?.value ?? '0',
        }
      })

    const uniqueAddressData = [
      ...new Map(addressData.map(campaignNFT => [campaignNFT.campaignID, campaignNFT])).values(),
    ]
    const totalXP: number = uniqueAddressData.reduce((memo, campaignNFT) => {
      const xpValue = isNaN(parseInt(campaignNFT.campaignXP)) ? 0 : parseInt(campaignNFT.campaignXP)
      return xpValue > 0 ? memo + xpValue : memo
    }, 0)
    const level = (levels.findIndex(x => totalXP < x) ?? 0) - 1
    const nextXP = levels[level + 1] - levels[level]
    const startXP = totalXP - levels[level]

    const levelData = {
      level: level,
      start: startXP,
      end: nextXP,
      total: totalXP,
    }

    return {
      loading: anyLoading,
      error: anyError,
      data: uniqueAddressData,
      levelData: levelData,
    }
  }, [state])
}
