import React, { useCallback, useRef, useState } from 'react'
import styled, { keyframes } from 'styled-components/macro'
import { lighten } from 'polished'
import { useActiveWeb3React } from '../../hooks'
import { useModalOpen, useToggleModal } from '../../state/application/hooks'
import { ApplicationModal } from '../../state/application/actions'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { ChainId } from '../../constants'
import { NETWORK_LABELS, NETWORK_LOGOS, NETWORKS } from '../../constants/chainId'
import BeraArmLeftImg from '../../assets/images/network-selector-arm-left.svg'
import BeraArmRightImg from '../../assets/images/network-selector-arm-right.svg'
import BearPawLeftImg from '../../assets/images/bear-paw-left.svg'
import BearPawRightImg from '../../assets/images/bear-paw-right.svg'
import BeraHeadImg from '../../assets/images/network-selector-head.svg'

const NetworkSelectorWrapper = styled.div`
  position: relative;
  border-radius: 5px;
  margin-right: 0;
  height: 39.5px;
  width: 39.5px;
  text-align: center;
  color: ${({ theme }) => theme.text1};
`

const StyledNetworkButton = styled.button`
  width: 100%;
  border: none;
  margin: 0;
  height: 40px;
  background-color: ${({ theme }) => theme.bg2};
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);

  padding: 8px 0.5rem;
  border-radius: 5px;

  :hover,
  :focus {
    cursor: pointer;
    outline: none;
    background-color: #3a3a4f;
  }

  svg {
    margin-top: 4px;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    box-shadow: none;
  `};
`

const FlyoutWrapper = styled.div<{ isOpen: boolean }>`
  > span {
    top: 50px;
    transition: opacity 0.3s ease-in-out;
    opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  }

  pointer-events: ${({ isOpen }) => (isOpen ? 'auto' : 'none')};

  ${({ theme, isOpen }) => theme.mediaWidth.upToSmall`
    overflow: hidden;
    position: absolute;
    width: 220px;
    height: 185px;
    right: -20px;
    bottom: 56px;
  
    > span {
      opacity: 1;
      transition: top 0.3s ease-in-out;
      top: ${isOpen ? '0' : '220px'};
    }
  `};
`

const MenuFlyout = styled.span`
  min-width: 210px;
  background-color: ${({ theme }) => theme.bg2};
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
  border-radius: 5px;
  padding: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  position: absolute;
  top: 4rem;
  right: 0;
  z-index: 100;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    top: -160px;
  `};
`

const NetworkRow = styled.div`
  width: 100%;
  text-align: left;
  cursor: pointer;
  padding: 8px 15px;
  font-size: 15px;

  :hover {
    background-color: ${({ theme }) => lighten(0.1, theme.bg2)};
  }

  img {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    margin-bottom: -2px;
  }
`

const refreshingSpinnerAnimation = keyframes`
    to {
        transform: rotate(360deg);
    }
`

const RefreshSpinner = styled.div`
  width: 26px;
  height: 26px;
  top: 7px;
  right: 7px;
  position: absolute;
  border-radius: 50%;
  z-index: 1;
  background: ${({ theme }) => `radial-gradient(closest-side, ${theme.bg2} 79%, transparent 90% 100%),
    conic-gradient(#d5d6e1 calc(63%), ${theme.bg2} 0)`};
  animation: ${refreshingSpinnerAnimation} 1s ease-in-out infinite forwards;
`

const BetaLabel = styled.div`
  position: absolute;
  z-index: 5;
  right: -5px;
  top: -6px;
  background-color: ${({ theme }) => theme.blue1};
  color: #f9f9f9;
  font-size: 7px;
  text-transform: uppercase;
  border-radius: 5px;
  padding: 1.5px 3px;
  font-weight: 600;
`

const TestLabel = styled(BetaLabel)`
  top: auto;
  bottom: -6px;
  font-weight: 800;
  background-color: ${({ theme }) => theme.yellow2};
`

const InlineBetaLabel = styled.div`
  color: ${({ theme }) => theme.text2};
  font-size: 8px;
  text-transform: uppercase;
  border-radius: 5px;
  padding: 1.5px 3px;
  font-weight: 700;
  margin-left: -2px;
  margin-top: 6px;
  vertical-align: top;
  display: inline-block;
  opacity: 0.7;
`

const BeraHead = styled.img`
  position: absolute;
  z-index: -1;
  top: -15px;
  left: -11px;
  width: 53px;
  pointer-events: none;
`

const BeraArmLeft = styled.img`
  width: 50px;
  position: absolute;
  z-index: 2;
  left: -12px;
  top: -10px;
  pointer-events: none;
`

const BeraPawLeft = styled.img`
  width: 60px;
  position: absolute;
  z-index: 2;
  left: -13px;
  top: -15px;
  pointer-events: none;
`

const BeraArmRight = styled.img`
  width: 54px;
  position: absolute;
  z-index: 2;
  right: -7px;
  top: -12px;
  pointer-events: none;
`

const BeraPawRight = styled.img`
  width: 60px;
  position: absolute;
  z-index: 2;
  left: -14px;
  top: -15px;
  pointer-events: none;
`

export default function NetworkSelector() {
  const node = useRef<HTMLDivElement>()
  const { chainId, setChain } = useActiveWeb3React()
  const open = useModalOpen(ApplicationModal.NETWORK)
  const toggle = useToggleModal(ApplicationModal.NETWORK)
  const [loadingChain, setLoadingChain] = useState<ChainId | undefined>(undefined)
  useOnClickOutside(node, open ? toggle : undefined)

  const selectNetwork = useCallback(
    async (selectedChainId: ChainId) => {
      toggle()
      if (chainId !== selectedChainId) {
        setLoadingChain(selectedChainId)
        await setChain(selectedChainId).then(() => {
          setLoadingChain(undefined)
        })
      }
    },
    [setChain, toggle, chainId],
  )

  return (
    <NetworkSelectorWrapper ref={node as any}>
      {chainId === ChainId.BERACHAIN && <BeraHead src={BeraHeadImg} />}
      <StyledNetworkButton onClick={toggle}>
        {loadingChain && <RefreshSpinner />}
        <img
          alt={`${NETWORK_LABELS[loadingChain ?? chainId]} logo`}
          src={NETWORK_LOGOS[loadingChain ?? chainId]}
          style={{
            width: '24px',
            height: '24px',
            position: 'absolute',
            zIndex: 2,
            top: '8px',
            right: '8px',
            filter: loadingChain ? 'opacity(0.3)' : undefined,
          }}
        />
        {(loadingChain
          ? loadingChain === ChainId.POLYGON_ZKEVM || loadingChain === ChainId.X_LAYER
          : chainId === ChainId.POLYGON_ZKEVM || chainId === ChainId.X_LAYER) && <BetaLabel>BETA</BetaLabel>}
        {(loadingChain ? loadingChain === ChainId.BERACHAIN : chainId === ChainId.BERACHAIN) && (
          <TestLabel>TESTNET</TestLabel>
        )}
      </StyledNetworkButton>
      {chainId === ChainId.BERACHAIN && <BeraPawLeft src={BearPawLeftImg} />}
      {chainId === ChainId.BERACHAIN && <BeraPawRight src={BearPawRightImg} />}
      <FlyoutWrapper isOpen={open}>
        <MenuFlyout>
          {Object.values(NETWORKS)
            .sort((a, b) => (a.label < b.label ? -1 : 1))
            .map(
              network =>
                network.isActive && (
                  <NetworkRow key={network.chainId} onClick={() => selectNetwork(network.chainId)}>
                    <img alt={`${network.label} logo`} src={network.logo} />
                    {network.label}
                    {(network.chainId === ChainId.POLYGON_ZKEVM || network.chainId === ChainId.X_LAYER) && (
                      <InlineBetaLabel>BETA</InlineBetaLabel>
                    )}
                    {network.chainId === ChainId.BERACHAIN && <InlineBetaLabel>TESTNET</InlineBetaLabel>}
                  </NetworkRow>
                ),
            )}
        </MenuFlyout>
      </FlyoutWrapper>
    </NetworkSelectorWrapper>
  )
}
