import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import CircularProgress from '@material-ui/core/CircularProgress'
import { StyledTooltip, StyledTooltipWithIcon } from '../../../common/StyledTooltip'
import { useIsTransactionPending } from '../../../../state/transactions/hooks'
import { useYtGlpPendingRewards } from '../../../../hooks/pendle/usePendleYtProtocol'
import usePendleGlp2024Tvl from '../../../../hooks/pendle/usePendleGlp2024Tvl'
import { useClaimYtGlpRewards, useYtGlpTotalBalance } from '../../../../hooks/pendle/useYtGlpDolomiteProxyProtocol'
import { formatAmount } from '../../../../utils/formatAmount'
import { useInterestRateData } from '../../../../types/interestRateData'
import { useDfsGlpToken } from '../../../../hooks/gmx/useGmxDolomiteProxyProtocol'
import { useShowYieldAsApr } from '../../../../state/user/hooks'
import { useTranslation } from 'react-i18next'

const RewardsWrapper = styled.div`
  width: 100%;
`

export const AssetRewardsWrapper = styled.div`
  padding: 7px 35px 30px;
  width: 100%;
  overflow: auto;
`

export const AssetRewardsBody = styled.div`
  font-size: 13px;
  font-weight: 500;
`

const DataSection = styled.div`
  width: 100%;
  margin-bottom: 8px;
`

const DataRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  line-height: 20px;
`

const DataName = styled.div`
  font-weight: 300;

  svg {
    transform: translateY(1px);
  }
`

const DataValue = styled.div`
  font-weight: 600;
`

const SubmitButton = styled.div<{ disabled: boolean }>`
  width: calc(100%);
  padding: 12px 16px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.green2};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.text1};
  opacity: ${({ disabled }) => (disabled ? 0.7 : 1)};
  text-align: center;
  margin-top: 12px;

  :hover {
    background-color: ${({ theme, disabled }) => !disabled && theme.green1};
  }

  > div {
    width: 20px !important;
    height: 20px !important;
    font-size: 12px;
    margin-top: -1px;
    margin-bottom: -5px;
  }

  svg {
    color: ${({ theme }) => theme.text1};
  }
`

export default function ClaimYtGLPRewards() {
  const [submitting, setSubmitting] = useState(false)
  const [awaitingSignature, setAwaitingSignature] = useState(false)
  const { callback: claimCallback } = useClaimYtGlpRewards()
  const { data: interestRateMap } = useInterestRateData()
  const glpToken = useDfsGlpToken()
  const glpYield = interestRateMap[glpToken?.address ?? '']?.totalSupplyInterestRate
  const pendleGlp2024TvlUSD = usePendleGlp2024Tvl()
  const totalBalance = useYtGlpTotalBalance()
  const [pendingRewards] = useYtGlpPendingRewards()
  const [pendingHash, setPendingHash] = useState<string | undefined>(undefined)
  const hasRewards = useMemo(() => pendingRewards?.greaterThan(0), [pendingRewards])
  const isDataLoaded = !!pendleGlp2024TvlUSD && !!totalBalance && !!pendingRewards && !!glpYield
  const isButtonActive = useMemo(() => !submitting && isDataLoaded && hasRewards, [
    hasRewards,
    isDataLoaded,
    submitting,
  ])

  const isHashPending = useIsTransactionPending(pendingHash)
  useEffect(() => {
    if (!isHashPending) {
      setPendingHash(undefined)
    }
    setSubmitting(isHashPending)
  }, [isHashPending])

  const submitClaim = useCallback(() => {
    if (!claimCallback || awaitingSignature) {
      return
    }

    const callback: () => Promise<string> = claimCallback

    setSubmitting(true)
    setAwaitingSignature(true)
    callback()
      .then((hash: any) => {
        if (typeof hash === 'string') {
          setPendingHash(hash)
        }
        setAwaitingSignature(false)
      })
      .catch(() => {
        setAwaitingSignature(false)
        setSubmitting(false)
      })
  }, [awaitingSignature, claimCallback])

  const [showYieldAsApr] = useShowYieldAsApr()
  const [t] = useTranslation()
  const yieldText = showYieldAsApr ? t('APR') : t('APY')

  return (
    <AssetRewardsWrapper>
      <h2>YT-GLP Rewards</h2>
      <AssetRewardsBody>
        <RewardsWrapper>
          <DataSection>
            <DataRow>
              <DataName>GLP {yieldText}</DataName>
              <DataValue>{formatAmount(glpYield)}</DataValue>
            </DataRow>
            <DataRow>
              <DataName>
                Pendle GLP TVL
                <StyledTooltipWithIcon tooltipText={`The total value of all GLP in Pendle's smart contracts.`} />
              </DataName>
              <DataValue>{formatAmount(pendleGlp2024TvlUSD, 2, true, '$0.00', true)}</DataValue>
            </DataRow>
            <DataRow>
              <DataName>
                Total Balance
                <StyledTooltipWithIcon
                  tooltipText={
                    'The total amount of YT-GLP you currently have deposited on Dolomite. This includes YT-GLP you have in your Dolomite Balance and in any borrow positions.'
                  }
                />
              </DataName>
              <DataValue>{formatAmount(totalBalance, 4, true)} YT-GLP</DataValue>
            </DataRow>
            <DataRow
              style={{
                marginTop: '15px',
                fontSize: '19px',
              }}
            >
              <DataName>Pending Rewards</DataName>
              <DataValue>{formatAmount(pendingRewards, 4, true)} ETH</DataValue>
            </DataRow>
          </DataSection>
          <StyledTooltip
            title={
              submitting
                ? awaitingSignature
                  ? 'Awaiting signature...'
                  : 'Submitting...'
                : !isDataLoaded
                ? 'Loading data, please wait...'
                : ''
            }
            position={'top'}
          >
            <SubmitButton disabled={!isButtonActive} onClick={() => isButtonActive && submitClaim()}>
              {submitting ? <CircularProgress /> : hasRewards ? 'Claim Rewards' : 'No Rewards to Claim'}
            </SubmitButton>
          </StyledTooltip>
        </RewardsWrapper>
      </AssetRewardsBody>
    </AssetRewardsWrapper>
  )
}
