import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import { useHistory, useParams } from 'react-router-dom'
import { AssetDenomination, Pair, Token } from '@dolomite-exchange/v2-sdk'
import { USDC, WETH } from '../../../constants'
import PoolBalances from '../PoolBalances'
import { useAllActiveTokensArray } from '../../../hooks/Tokens'
import { useDolomiteBalancesWithLoadingIndicator } from '../../../state/wallet/hooks'
import PoolDepositWithdraw from '../PoolDepositWithdraw'
import { PairState, usePair, usePairs } from '../../../data/Reserves'
import { useActiveWeb3React } from '../../../hooks'
import { useInterestRateData } from '../../../types/interestRateData'
import { CurrencyAmount, Percent } from '@dolomite-exchange/sdk-core'
import JSBI from 'jsbi'
import { useTrackedTokenPairs } from '../../../state/user/hooks'
import { address } from '@dolomite-exchange/dolomite-margin/dist/src/types'
import cleanCurrencySymbol from '../../../utils/cleanCurrencySymbol'
import { BRIDGED_USDC } from '../../../constants/tokens/USDC'

const ManagePoolWrapper = styled.div`
  display: inline-block;
  vertical-align: top;
  width: calc(64% - 60px);
  margin-left: 15px;
  text-align: left;

  @media screen and (max-width: 919px) {
    margin: 0 auto 5px;
    width: 70vw;
  }
  @media screen and (max-width: 730px) {
    margin: 0 auto 20px;
    width: 80vw;
  }
  @media screen and (max-width: 580px) {
    margin: 0 auto;
    width: calc(100% - 32px);
    /*padding: 15px 20px 20px;*/
  }
`

interface UrlParam {
  poolInputCurrency: string
  poolOutputCurrency: string
}

export interface AmmPoolData {
  pair: Pair
  pairState: PairState
}

function symbolToToken(tokens: Token[], symbol?: string): Token | undefined {
  return tokens.find(token => cleanCurrencySymbol(token) === symbol?.toUpperCase())
}

export default function ManagePool() {
  const { chainId, account } = useActiveWeb3React()
  const { poolInputCurrency, poolOutputCurrency } = useParams<UrlParam>()
  const tokens = useAllActiveTokensArray()

  const [loadedInputToken, loadedOutputToken] = useMemo(() => {
    return poolInputCurrency?.toUpperCase() === poolOutputCurrency?.toUpperCase()
      ? [symbolToToken(tokens, poolInputCurrency), undefined]
      : [symbolToToken(tokens, poolInputCurrency), symbolToToken(tokens, poolOutputCurrency)]
  }, [poolInputCurrency, poolOutputCurrency, tokens])

  const history = useHistory()
  const weth = useMemo(() => WETH[chainId], [chainId])
  const usdc = useMemo(() => BRIDGED_USDC[chainId] ?? USDC[chainId], [chainId])
  const denomination = AssetDenomination.Wei

  const [tokenA, setTokenA] = useState<Token | undefined>(loadedInputToken)
  const [tokenB, setTokenB] = useState<Token | undefined>(loadedOutputToken)

  const [pairState, pair] = usePair(tokenA, tokenB, denomination)

  const [balanceList, isLoading] = useDolomiteBalancesWithLoadingIndicator(account, tokens)
  const tokenPairs = useTrackedTokenPairs()
  const { data: interestRateData } = useInterestRateData()
  const allPairs = usePairs(tokenPairs, denomination)
  const [ammPoolDatas, setAmmPoolDatas] = useState<AmmPoolData[]>([])
  const [totalAprMap, setTotalAprMap] = useState<Record<address, Percent | undefined>>({})

  useEffect(() => {
    const newAmmPoolDataMap = allPairs.map<AmmPoolData>(([pairState, pair], index) => {
      if (!pair) {
        const tokenA = tokenPairs[index][0]
        const tokenB = tokenPairs[index][1]
        const [token0, token1] = tokenA.sortsBefore(tokenB) // does safety checks
          ? [tokenA, tokenB]
          : [tokenB, tokenA]
        pair = new Pair(
          CurrencyAmount.fromRawAmount(token0, JSBI.BigInt('0')),
          CurrencyAmount.fromRawAmount(token1, JSBI.BigInt('0')),
        )
      }

      return {
        pair: pair,
        pairState: pairState,
        supplyRate0: interestRateData[pair.token0.address]?.supplyInterestRate,
        supplyRate1: interestRateData[pair.token1.address]?.supplyInterestRate,
      }
    })

    setAmmPoolDatas(newAmmPoolDataMap)
  }, [allPairs, tokenPairs, interestRateData, totalAprMap])

  const setTotalAprForPair = useCallback(
    (pair: Pair, newTotalApr: Percent) => {
      const oldTotalApr = totalAprMap[pair.liquidityToken.address]
      if (!oldTotalApr || !newTotalApr.equalTo(oldTotalApr)) {
        totalAprMap[pair.liquidityToken.address] = newTotalApr
        setTotalAprMap({ ...totalAprMap })
      }
    },
    [totalAprMap],
  )

  const changeSelectedPair = useCallback(
    (token0: Token, token1: Token) => {
      if (!tokenA || !tokenB) {
        setTokenA(token0)
        setTokenB(token1)
        history.replace(`/pool/${cleanCurrencySymbol(token0)}/${cleanCurrencySymbol(token1)}`)
        return
      }

      const [tokenA0, tokenB1] = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA]
      if (!token0.equals(token1) && (!token0.equals(tokenA0) || !token1.equals(tokenB1))) {
        setTokenA(token0)
        setTokenB(token1)
      }
      if (tokenA0.symbol !== token0.symbol || token1.symbol !== tokenB1.symbol) {
        history.push(`/pool/${cleanCurrencySymbol(token0)}/${cleanCurrencySymbol(token1)}`)
      }
    },
    [history, tokenA, tokenB],
  )

  useEffect(() => {
    if (loadedInputToken && loadedOutputToken) {
      changeSelectedPair(loadedInputToken, loadedOutputToken)
    } else if (loadedInputToken && !loadedOutputToken) {
      if (loadedInputToken.address === weth.address) {
        changeSelectedPair(weth, usdc)
      } else {
        changeSelectedPair(loadedInputToken, weth)
      }
    } else {
      changeSelectedPair(weth, usdc)
    }
  }, [loadedInputToken, loadedOutputToken, changeSelectedPair, weth, usdc])

  const selectedPair = useMemo(() => {
    if (!tokenA || !tokenB) {
      return undefined
    } else if (!pair) {
      const [token0, token1] = tokenA.sortsBefore(tokenB) // does safety checks
        ? [tokenA, tokenB]
        : [tokenB, tokenA]
      return new Pair(
        CurrencyAmount.fromRawAmount(token0, JSBI.BigInt('0')),
        CurrencyAmount.fromRawAmount(token1, JSBI.BigInt('0')),
      )
    } else {
      return pair
    }
  }, [pair, tokenA, tokenB])

  const selectedPool = useMemo(() => {
    return ammPoolDatas.find(poolData => poolData.pair.liquidityToken.address === selectedPair?.liquidityToken.address)
  }, [ammPoolDatas, selectedPair])

  return (
    <ManagePoolWrapper>
      <PoolBalances
        ammPoolDatas={ammPoolDatas}
        totalAprMap={totalAprMap}
        setTotalAprForPair={setTotalAprForPair}
        onPairChange={changeSelectedPair}
        walletConnected={!!account}
        isLoading={isLoading}
      />
      <PoolDepositWithdraw
        pool={selectedPool}
        pairState={pairState}
        onPairChange={changeSelectedPair}
        balanceInfo={balanceList}
        isLoading={isLoading}
      />
    </ManagePoolWrapper>
  )
}
