import { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useActiveWeb3React } from '../../hooks'
import { CancelledError, retry } from '../../utils/retry'
import store from '../index'
import state, { AppDispatch, AppState } from '../index'
import {
  errorFetchingGraphqlResults,
  fetchingGraphqlResults,
  GraphqlCall,
  GraphqlClientType,
  parseCallKey,
  toGraphqlCallKey,
  updateGraphqlResults,
  Web3CallType,
} from './actions'
import useDebounce from '../../hooks/useDebounce'
import { blockSubgraphClient, dolomiteSubgraphClient, galxeSubgraphClient } from '../../apollo/client'
import { gql } from '@apollo/client'
import { deserializeAllFunctionalInterestRatesMap, useAllFunctionalInterestRates } from './useAllOutsideInterestRates'
import { ChainId } from '../../constants'
import { RefreshFrequency, useBlockNumberForSubgraph } from '../chain/hooks'
import { LIBRARY_MAP } from '../../connectors'
import fetchWithTimeout from '../../utils/fetchWithTimeout'

function getGraphqlClient(clientType: GraphqlClientType) {
  if (clientType === GraphqlClientType.Dolomite) {
    return dolomiteSubgraphClient
  } else if (clientType === GraphqlClientType.Blocks) {
    return blockSubgraphClient
  } else if (clientType === GraphqlClientType.Galxe) {
    return galxeSubgraphClient
  } else {
    throw new Error(`Unknown client type ${clientType}`)
  }
}

interface DataOrErrors {
  data: string | undefined
  error: string | undefined
  subgraphUrlIndex: number
}

export interface FetchGraphqlResult {
  dataOrErrors: DataOrErrors[]
  timestamp: number
}

/**
 * Fetches a chunk of calls, enforcing a minimum block number constraint
 * @param chunk chunk of calls to make
 * @param timestamp The timestamp at which the fetch is being made
 * @param functionalResultsMap The results from the Functional data
 */
async function fetchGraphqlCall(
  chunk: GraphqlCall[],
  timestamp: number,
  functionalResultsMap: Record<string, string | undefined>,
): Promise<FetchGraphqlResult> {
  const startTime = Date.now()
  let results: DataOrErrors[]
  try {
    results = await Promise.all(
      chunk.map<Promise<DataOrErrors>>(call => {
        const subgraphUrlIndex = store.getState().application.clientToChainToUrlIndex[call.clientType][call.chainId]

        if (call.clientType === GraphqlClientType.Functional) {
          if (!call.variables) {
            console.warn('Variables was undefined for Function call')
            return Promise.resolve({
              subgraphUrlIndex,
              data: undefined,
              error: undefined,
            })
          }
          return Promise.resolve({
            subgraphUrlIndex,
            data: functionalResultsMap[call.variables] ?? undefined,
            error: undefined,
          })
        } else if (call.clientType === GraphqlClientType.Fetch) {
          if (!call.query) {
            return Promise.resolve({
              subgraphUrlIndex,
              data: undefined,
              error: undefined,
            })
          }

          return fetchWithTimeout(call.query)
            .response.then(response => response.json())
            .then(json => {
              return {
                subgraphUrlIndex,
                data: JSON.stringify(json),
                error: undefined,
              }
            })
            .catch(error => {
              console.error('Error fetching Fetch results', error)
              return {
                subgraphUrlIndex,
                data: undefined,
                error: error.message,
              }
            })
        } else if (call.clientType === GraphqlClientType.Post) {
          if (!call.query || !call.variables) {
            return Promise.resolve({
              subgraphUrlIndex,
              data: undefined,
              error: undefined,
            })
          }

          return fetchWithTimeout(call.query, {
            method: 'POST',
            body: call.variables,
            headers: {
              'Content-Type': 'application/json',
            },
          })
            .response.then(response => response.json())
            .then(json => {
              return {
                subgraphUrlIndex,
                data: JSON.stringify(json),
                error: undefined,
              }
            })
            .catch(error => {
              console.error('Error fetching POST results', error)
              return {
                subgraphUrlIndex,
                data: undefined,
                error: error.message,
              }
            })
        } else if (call.clientType === GraphqlClientType.Web3) {
          if (!call.query || !call.variables) {
            return Promise.resolve({
              subgraphUrlIndex,
              data: undefined,
              error: undefined,
            })
          }

          let promise: Promise<string>
          if (call.query === Web3CallType.Call) {
            const transactionWithChainId = JSON.parse(call.variables)
            promise = LIBRARY_MAP[call.chainId as ChainId]
              .call(transactionWithChainId)
              .then(result => JSON.stringify([result]))
          } else if (call.query === Web3CallType.GetEnsAvatars) {
            promise = Promise.all(
              (JSON.parse(call.variables).data as (string | null)[]).map((addressOrNull: string | null) =>
                addressOrNull ? LIBRARY_MAP[ChainId.MAINNET].getAvatar(addressOrNull) : null,
              ),
            ).then(avatarsOrNulls => JSON.stringify(avatarsOrNulls))
          } else if (call.query === Web3CallType.GetEnsNames) {
            promise = Promise.all(
              (JSON.parse(call.variables).data as string[]).map(
                async (address: string) => await LIBRARY_MAP[ChainId.MAINNET].lookupAddress(address),
              ),
            ).then(nameOrNullList => JSON.stringify(nameOrNullList))
          } else if (call.query === Web3CallType.ReverseEnsNames) {
            promise = Promise.all(
              (JSON.parse(call.variables).data as string[]).map((name: string) =>
                LIBRARY_MAP[ChainId.MAINNET].resolveName(name),
              ),
            ).then(addressOrNullList => JSON.stringify(addressOrNullList))
          } else {
            console.warn('Invalid Web3CallType, found', call.query)
            return Promise.resolve({
              subgraphUrlIndex,
              data: undefined,
              error: undefined,
            })
          }

          return promise
            .then(jsonString => {
              return {
                subgraphUrlIndex,
                data: jsonString,
                error: undefined,
              }
            })
            .catch(error => {
              console.error('Error fetching WEB3 results', error)
              return {
                subgraphUrlIndex,
                data: undefined,
                error: error.message,
              }
            })
        } else {
          if (!call.variables || !call.query) {
            return Promise.resolve({
              subgraphUrlIndex,
              data: undefined,
              error: undefined,
            })
          }
          const client = getGraphqlClient(call.clientType)
          const variables = JSON.parse(call.variables)
          variables.blockNumber = state.getState().chain.subgraphBlockNumberMap[call.chainId]

          return client
            .query({
              context: {
                chainId: call.chainId,
              },
              query: gql(call.query),
              variables,
            })
            .then(result => {
              if (result.data) {
                return {
                  subgraphUrlIndex,
                  data: JSON.stringify(result.data),
                  error: undefined,
                }
              } else if (result.errors && result.errors.length > 0) {
                console.warn('Found GraphQL errors while fetching', result.errors, call)
                return {
                  subgraphUrlIndex,
                  data: undefined,
                  error: result.errors[0].message,
                }
              } else if (result.error) {
                console.warn('Found Apollo error while fetching graphql results', result.error, call)
                return {
                  subgraphUrlIndex,
                  data: undefined,
                  error: result.error.message,
                }
              } else {
                console.error('Could not find Graphql data or error in result', result, call)
                return {
                  subgraphUrlIndex,
                  data: undefined,
                  error: 'Could not find Graphql data',
                }
              }
            })
            .catch(error => {
              console.error(`Error fetching GraphQL[${call.clientType}] results`, error.message, error)
              return {
                subgraphUrlIndex,
                data: undefined,
                error: error.message,
              }
            })
        }
      }),
    )
  } catch (error) {
    console.debug('Failed to fetch chunk', error, timestamp, chunk[0]?.chainId)
    throw error
  }

  if (chunk.length > 0) {
    console.debug('Fetched data chunk in', `${Date.now() - startTime}ms`, chunk, timestamp, chunk[0].chainId)
  }
  return {
    dataOrErrors: results,
    timestamp,
  }
}

/**
 * From the current all listeners state, return each call key mapped to the poll interval per fetch. This is how often
 * each key must be fetched.
 * @param allListeners the all listeners state
 * @param chainId the current chain id
 */
export function getActiveListeningKeys(
  allListeners: AppState['graphql']['callListeners'],
  chainId: ChainId,
): Record<string, RefreshFrequency> {
  if (!allListeners) {
    return {}
  }

  const listeners = allListeners[chainId]
  if (!listeners) {
    return {}
  }

  return Object.keys(listeners).reduce<Record<string, RefreshFrequency>>((memo, callKey) => {
    const refreshFrequencyToListenerOptionsMap = listeners[callKey]

    memo[callKey] = Object.keys(refreshFrequencyToListenerOptionsMap)
      .filter(refreshFrequencyString => {
        const refreshFrequency = parseInt(refreshFrequencyString)
        if (refreshFrequency <= 0) {
          return false
        }
        const listener = refreshFrequencyToListenerOptionsMap[refreshFrequency]
        return listener.listenerCount > 0
      })
      .reduce((previousMin, current) => {
        return Math.min(previousMin, parseInt(current))
      }, Infinity)
    return memo
  }, {})
}

/**
 * Return the keys that need to be re-fetched
 *
 * @param callResults current call result state
 * @param listeningKeys each call key mapped to how old the data can be in seconds
 * @param chainId the current chain id
 * @param latestTimestamp the latest timestamp in seconds
 */
export function getListeningKeysThatNeedRefresh(
  callResults: AppState['graphql']['callResults'],
  listeningKeys: Record<string, RefreshFrequency>,
  chainId: ChainId,
  latestTimestamp: number,
): string[] {
  if (!chainId || !latestTimestamp) {
    return []
  }

  return Object.keys(listeningKeys).filter(callKey => {
    const pollInterval = listeningKeys[callKey]

    const data = callResults[chainId][callKey]
    if (!data) {
      // There's no data at all, must fetch
      return true
    }

    const minDataTimestamp = latestTimestamp - (pollInterval - 1)

    const allIndexesMatch = data.fetchData.every(
      innerFetchData =>
        innerFetchData.subgraphUrlIndex === undefined ||
        innerFetchData.subgraphUrlIndex ===
          store.getState().application.clientToChainToUrlIndex[innerFetchData.clientType][innerFetchData.chainId],
    )
    if (data.fetchingTimestamp && data.fetchingTimestamp >= minDataTimestamp && allIndexesMatch) {
      // already fetching it for a recent-enough timestamp, don't re-fetch it
      return false
    }

    // if data is older than minDataTimestamp OR if the data was fetched from a different index, fetch it
    return !data.timestamp || data.timestamp < minDataTimestamp || !allIndexesMatch
  })
}

interface CancellationRef {
  blockNumber: number
  chainId: number
  cancellations: (() => void)[]
}

export default function Updater(): null {
  const dispatch = useDispatch<AppDispatch>()
  const state = useSelector<AppState, AppState['graphql']>(state => state.graphql)

  // wait for listeners to settle before triggering updates
  const debouncedListeners = useDebounce(state.callListeners, 32)

  const { chainId } = useActiveWeb3React()
  const latestBlockNumber = useBlockNumberForSubgraph()
  const cancellationRef = useRef<CancellationRef>({
    cancellations: [],
    blockNumber: latestBlockNumber,
    chainId,
  })

  const listeningKeys: Record<string, RefreshFrequency> = useMemo(() => {
    return getActiveListeningKeys(debouncedListeners, chainId)
  }, [debouncedListeners, chainId])

  const [tick, setTick] = useState(0)
  useEffect(() => {
    const interval = setInterval(() => {
      setTick(tick => tick + 1)
    }, 1_000)
    return () => clearInterval(interval)
  }, [])

  const [unserializedCallKeysThatNeedRefresh, setUnserializedCallKeysThatNeedRefresh] = useState<string[]>([])
  useEffect(() => {
    const latestTimestamp = Math.floor(Date.now() / 1_000)
    setUnserializedCallKeysThatNeedRefresh(
      Array.of(...getListeningKeysThatNeedRefresh(state.callResults, listeningKeys, chainId, latestTimestamp)).sort(),
    )
  }, [chainId, state.callResults, listeningKeys, tick])

  const serializedOutdatedCallKeys = useMemo(() => JSON.stringify(unserializedCallKeysThatNeedRefresh), [
    unserializedCallKeysThatNeedRefresh,
  ])

  const serializedFunctionalResultsMap = useAllFunctionalInterestRates(serializedOutdatedCallKeys)
  useEffect(() => {
    if (!latestBlockNumber || !chainId) {
      return
    }
    const latestTimestamp = Math.floor(Date.now() / 1000)
    const outdatedCallKeys: string[] = JSON.parse(serializedOutdatedCallKeys)
    if (outdatedCallKeys.length === 0) {
      return
    }

    const callsList = outdatedCallKeys.map(key => parseCallKey(key)).filter((c): c is GraphqlCall[] => Boolean(c))

    if (cancellationRef.current.blockNumber !== latestBlockNumber || cancellationRef.current.chainId !== chainId) {
      const list = cancellationRef.current.cancellations
      if (list && list.length > 0) {
        console.debug('Cancelling all outdated Graphql calls')
      }
      list.forEach(c => c())
    }

    dispatch(
      fetchingGraphqlResults({
        chainId,
        callsList,
        fetchingTimestamp: latestTimestamp,
      }),
    )

    cancellationRef.current = {
      chainId,
      blockNumber: latestBlockNumber,
      cancellations: callsList.map(calls => {
        const { cancel, promise } = retry(
          () => {
            const functionalResultsMap = deserializeAllFunctionalInterestRatesMap(serializedFunctionalResultsMap)
            return fetchGraphqlCall(calls, latestTimestamp, functionalResultsMap)
          },
          {
            n: Infinity,
            minWait: 1000,
            maxWait: 2500,
          },
        )
        promise
          .then((fetchResult: FetchGraphqlResult) => {
            cancellationRef.current = {
              cancellations: [],
              blockNumber: latestBlockNumber,
              chainId,
            }

            dispatch(
              updateGraphqlResults({
                chainId,
                results: {
                  [toGraphqlCallKey(calls)]: fetchResult.dataOrErrors,
                },
                timestamp: latestTimestamp,
              }),
            )
          })
          .catch((error: any) => {
            if (error instanceof CancelledError) {
              console.debug('Cancelled GraphQL fetch for blockNumber', latestBlockNumber)
              return
            }
            console.warn('Failed to fetch calls', callsList, chainId, error)
            dispatch(
              errorFetchingGraphqlResults({
                calls,
                chainId,
                fetchingTimestamp: latestTimestamp,
              }),
            )
          })
        return cancel
      }),
    }
  }, [chainId, dispatch, serializedOutdatedCallKeys, latestBlockNumber, serializedFunctionalResultsMap])

  return null
}
