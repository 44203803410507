import { Token } from '@dolomite-exchange/v2-sdk'

export class Market {
  public market: string
  private readonly token0: Token
  private readonly token1: Token

  constructor(marketString: string, token0: Token, token1: Token) {
    this.market = marketString
    this.token0 = token0
    this.token1 = token1
  }

  get primary(): string {
    return this.market.split('-')[0]
  }

  get primaryToken(): Token {
    return this.primary === this.token0.symbol ? this.token0 : this.token1
  }

  get secondary(): string {
    return this.market.split('-')[1]
  }

  get secondaryToken(): Token {
    return this.secondary === this.token0.symbol ? this.token0 : this.token1
  }

  public toString(): string {
    return this.market
  }

  public equals(other: Market): boolean {
    return this.primaryToken.equals(other.primaryToken) && this.secondaryToken.equals(other.secondaryToken)
  }
}
