import useParsedQueryString from './useParsedQueryString'
import { Trade } from '@dolomite-exchange/v2-sdk'
import { Currency, TradeType } from '@dolomite-exchange/sdk-core'

export enum Version {
  v1 = 'v1',
  v2 = 'v2',
}

export const DEFAULT_VERSION: Version = Version.v2

export function getTradeVersion(trade?: Trade<Currency, Currency, TradeType>): Version | undefined {
  if (typeof trade?.route.pairs !== 'undefined') {
    return Version.v2
  }
  return undefined
}

export default function useToggledVersion(): Version {
  const { use } = useParsedQueryString()
  if (!use || typeof use !== 'string') return Version.v2
  if (use.toLowerCase() === 'v1') return Version.v1
  return DEFAULT_VERSION
}
