import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CircularProgress from '@material-ui/core/CircularProgress'
import { AssetRewardsBody, AssetRewardsWrapper } from './GLPBottomSection'
import { processGmxAndGlpData } from '../../../../utils/processGmxAndGlpData'
import {
  useGlpAum,
  useGmxBalanceWithSupplies,
  useGmxDepositInfo,
  useGmxPriceUSD,
  useGmxStakingInfos,
  useGmxSupply,
  useGmxToken,
  useGmxVestingInfo,
  useStakedGmxSupply,
} from '../../../../hooks/gmx/useGmxProtocol'
import { useDefaultFiatValuesWithLoadingIndicator } from '../../../../hooks/useFiatValue'
import useMidAum from '../../../../hooks/gmx/useMidAum'
import { useClaimCompoundRewards, useDGmxToken } from '../../../../hooks/gmx/useGmxDolomiteProxyProtocol'
import useGlpProxyVaultAccount from '../../../../hooks/gmx/useGlpProxyVaultAccount'
import { StyledTooltip } from '../../../common/StyledTooltip'
import { useWethToken } from '../../../../hooks/Tokens'
import { formatAmount } from '../../../../utils/formatAmount'
import useIsolationModeUserVaultAddressIfCreated from '../../../../hooks/useIsolationModeUserVaultAddressIfCreated'
import { useTokenBalance } from '../../../../state/wallet/hooks'

const RewardsWrapper = styled.div`
  width: 100%;
  margin-bottom: -30px;
`

const HorizontalLineWithVerticalMargin = styled.div`
  border-top: 1px solid ${({ theme }) => theme.text1};
  margin-top: 8px;
  margin-bottom: 8px;
`

const DataSection = styled.div`
  width: 100%;
  margin-bottom: 8px;
`

const DataRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  line-height: 20px;
`

const DataName = styled.div`
  font-weight: 300;
`

const DataValue = styled.div`
  font-weight: 600;
`

const ClaimRow = styled.div<{ disabled?: boolean }>`
  width: fit-content;
  text-align: left;
  line-height: 20px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  height: 20px;
  user-select: none;

  svg {
    ${({ disabled }) => disabled && 'opacity: 0.5'};
  }
`

const ModifySection = styled.div<{ visible: boolean }>`
  width: 100%;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  pointer-events: ${({ visible }) => (visible ? 'all' : 'none')};
  transition: opacity 0.2s ease-in-out;
  position: absolute;
  top: 25px;
`

const ClaimSection = styled(ModifySection)``

const CompoundSection = styled(ModifySection)``

const SubmitButton = styled.div<{ disabled: boolean }>`
  width: calc(100% - 75px);
  padding: 12px 16px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.green2};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.text1};
  opacity: ${({ disabled }) => (disabled ? 0.7 : 1)};
  text-align: center;
  margin-top: 12px;

  :hover {
    background-color: ${({ theme, disabled }) => !disabled && theme.green1};
  }

  > div {
    width: 20px !important;
    height: 20px !important;
    font-size: 12px;
    margin-top: -1px;
    margin-bottom: -5px;
  }

  svg {
    color: ${({ theme }) => theme.text1};
  }
`

const CheckBoxWrapper = styled.div`
  display: inline-block;

  svg {
    height: 20px;
    width: 20px;
  }
`

const ModifySectionText = styled.div`
  display: inline-block;
  vertical-align: top;
`

export const ExpandedContent = styled.div<{ expandedHeight: number }>`
  width: calc(100% + 70px);
  margin-left: -35px;
  background: ${({ theme }) => theme.bg2};
  padding: 15px 35px 30px;
  height: ${({ expandedHeight }) => expandedHeight}px;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  position: relative;
`

const ActionButton = styled.div<{ selected: boolean }>`
  width: 50%;
  display: inline-block;
  vertical-align: top;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 15px;
  cursor: pointer;
  background: ${({ theme, selected }) => (selected ? theme.bg2 : theme.bg1)};

  :hover {
    background: ${({ theme, selected }) => (selected ? theme.bg2 : theme.bg7)};
  }

  :nth-of-type(1) {
    border-top-right-radius: 8px;
  }

  :nth-of-type(2) {
    border-top-left-radius: 8px;
  }
`

const ActionButtonsWrapper = styled.div`
  width: calc(100% + 70px);
  margin-left: -35px;
  margin-top: 20px;
`

const CheckBox = ({ isChecked }: { isChecked: boolean }) => {
  return <CheckBoxWrapper>{isChecked ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}</CheckBoxWrapper>
}

export const USD_DECIMALS = 18

enum SelectedTab {
  Claim = 0,
  Compound = 1,
}

export default function GLPTotalRewards() {
  const glpVaultAccount = useGlpProxyVaultAccount()
  const [selectedTab, setSelectedTab] = useState(SelectedTab.Compound)
  const [claimGmxRewards, setClaimGmxRewards] = useState(true)
  const [claimEsGmxRewards, setClaimEsGmxRewards] = useState(true)
  const [claimWethRewards, setClaimWethRewards] = useState(true)
  const [depositWethIntoDolomite, setDepositWethIntoDolomite] = useState(true)
  const [stakeMultiplierPoints, setStakeMultiplierPoints] = useState(true)
  const [stakeGMXRewards, setStakeGMXRewards] = useState(true)
  const [stakeESGMXRewards, setStakeESGMXRewards] = useState(true)
  const [submitting, setSubmitting] = useState(false)
  const [awaitingSignature, setAwaitingSignature] = useState(false)
  const isButtonActive = useMemo(
    () =>
      !submitting &&
      (selectedTab === SelectedTab.Claim
        ? claimGmxRewards || claimEsGmxRewards || claimWethRewards || depositWethIntoDolomite
        : stakeMultiplierPoints ||
          claimGmxRewards ||
          stakeGMXRewards ||
          claimEsGmxRewards ||
          stakeESGMXRewards ||
          claimWethRewards ||
          depositWethIntoDolomite),
    [
      submitting,
      claimEsGmxRewards,
      claimGmxRewards,
      claimWethRewards,
      depositWethIntoDolomite,
      selectedTab,
      stakeESGMXRewards,
      stakeGMXRewards,
      stakeMultiplierPoints,
    ],
  )

  const wethToken = useWethToken()
  const gmxToken = useGmxToken()
  const [fiatPriceMap] = useDefaultFiatValuesWithLoadingIndicator(useMemo(() => [wethToken], [wethToken]))
  const wethPrice = fiatPriceMap[wethToken.address]
  const [stakingData] = useGmxStakingInfos(glpVaultAccount)
  const [vestingData] = useGmxVestingInfo(glpVaultAccount)
  const [gmxPrice] = useGmxPriceUSD()
  const [aums] = useGlpAum()
  const aum = useMidAum(aums)
  const [balanceAndSupplyData] = useGmxBalanceWithSupplies(glpVaultAccount)
  const [depositBalanceData] = useGmxDepositInfo(glpVaultAccount)
  const gmxVaultAddress = useIsolationModeUserVaultAddressIfCreated(useDGmxToken())
  const gmxBalance = useTokenBalance(gmxVaultAddress, gmxToken)
  const gmxBalanceUsd = gmxPrice ? gmxBalance?.asFraction.multiply(gmxPrice) : undefined
  const stakedGmxSupply = useStakedGmxSupply()
  const gmxSupply = useGmxSupply()

  const processedData = useMemo(() => {
    return processGmxAndGlpData({
      balanceData: balanceAndSupplyData?.balanceData,
      supplyData: balanceAndSupplyData?.supplyData,
      nativeTokenPrice: wethPrice,
      depositBalanceData,
      stakingData,
      vestingData,
      aum,
      stakedGmxSupply,
      gmxPrice,
      gmxSupply,
    })
  }, [
    aum,
    balanceAndSupplyData,
    depositBalanceData,
    gmxPrice,
    gmxSupply,
    stakedGmxSupply,
    stakingData,
    vestingData,
    wethPrice,
  ])

  const updateSelectedTab = useCallback(
    (newSelectedTab: SelectedTab) => {
      if (newSelectedTab !== selectedTab) {
        setSelectedTab(newSelectedTab)
        setClaimGmxRewards(true)
        setClaimEsGmxRewards(true)
        setClaimWethRewards(true)
        setDepositWethIntoDolomite(true)
        setStakeMultiplierPoints(true)
        setStakeGMXRewards(true)
        setStakeESGMXRewards(true)
      }
    },
    [selectedTab],
  )

  const { callback: compoundCallback } = useClaimCompoundRewards(
    claimGmxRewards,
    stakeGMXRewards,
    claimEsGmxRewards,
    stakeESGMXRewards,
    stakeMultiplierPoints,
    claimWethRewards,
    depositWethIntoDolomite,
  )

  const { callback: claimCallback } = useClaimCompoundRewards(
    claimGmxRewards,
    false,
    claimEsGmxRewards,
    false,
    false,
    claimWethRewards,
    depositWethIntoDolomite,
  )

  const submitClaimCompound = useCallback(() => {
    if (!claimCallback || !compoundCallback || awaitingSignature) {
      return
    }

    let callback: () => Promise<string>
    if (selectedTab === SelectedTab.Claim) {
      callback = claimCallback
    } else if (selectedTab === SelectedTab.Compound) {
      callback = compoundCallback
    } else {
      console.error('Invalid selected tab', selectedTab)
      return
    }

    setSubmitting(true)
    setAwaitingSignature(true)
    callback()
      .then(() => {
        setAwaitingSignature(false)
        setSubmitting(false)
      })
      .catch(e => {
        setAwaitingSignature(false)
        setSubmitting(false)
      })
  }, [awaitingSignature, claimCallback, compoundCallback, selectedTab])

  return (
    <AssetRewardsWrapper>
      <h2>Asset Rewards</h2>
      <AssetRewardsBody>
        <RewardsWrapper>
          <DataSection>
            <DataRow>
              <DataName>Total GLP in Vault</DataName>
              <DataValue>
                {formatAmount(processedData?.glpBalance, 4, true)} (
                {formatAmount(processedData?.glpBalanceUsd, 2, true, undefined, true)})
              </DataValue>
            </DataRow>
            <DataRow>
              <DataName>Total GMX Staked</DataName>
              <DataValue>
                {formatAmount(processedData?.gmxInStakedGmx, 4, true)} (
                {formatAmount(processedData?.gmxInStakedGmxUsd, 2, true, undefined, true)})
              </DataValue>
            </DataRow>
            <DataRow>
              <DataName>Total GMX in Vault</DataName>
              <DataValue>
                {formatAmount(gmxBalance, 4, true)}{' '}
                {gmxBalanceUsd ? `(${formatAmount(gmxBalanceUsd, 2, true, undefined, true)})` : ''}
              </DataValue>
            </DataRow>
            <DataRow>
              <DataName>Total esGMX Staked</DataName>
              <DataValue>
                {formatAmount(processedData?.esGmxInStakedGmx, 4, true)} (
                {formatAmount(processedData?.esGmxInStakedGmxUsd, 2, true, undefined, true)})
              </DataValue>
            </DataRow>
            <DataRow>
              <DataName>Total esGMX in Vault</DataName>
              <DataValue>
                {formatAmount(processedData?.esGmxBalance, 4, true)} (
                {formatAmount(processedData?.esGmxBalanceUsd, 2, true, undefined, true)})
              </DataValue>
            </DataRow>
            <HorizontalLineWithVerticalMargin />
            <DataRow>
              <DataName>GMX (Rewards)</DataName>
              <DataValue>
                {formatAmount(processedData?.extendedGmxTokenRewards, 4, true)} (
                {formatAmount(processedData?.extendedGmxTokenRewardsUsd, 2, true, undefined, true)})
              </DataValue>
            </DataRow>
            <DataRow>
              <DataName>GMX (Vesting)</DataName>
              <DataValue>
                {formatAmount(processedData?.totalVesterRewards, 4, true)} (
                {formatAmount(processedData?.totalVesterRewardsUsd, 2, true, undefined, true)})
              </DataValue>
            </DataRow>
            <DataRow>
              <DataName>ETH (Legacy)</DataName>
              <DataValue>
                {formatAmount(processedData?.totalNativeTokenRewards, 6, true)} (
                {formatAmount(processedData?.totalNativeTokenRewardsUsd, 2, true, undefined, true)})
              </DataValue>
            </DataRow>
            <DataRow>
              <DataName>Escrowed GMX</DataName>
              <DataValue>
                {formatAmount(processedData?.totalEsGmxRewards, 4, true)} (
                {formatAmount(processedData?.totalEsGmxRewardsUsd, 2, true, undefined, true)})
              </DataValue>
            </DataRow>
            <DataRow
              style={{
                marginTop: '10px',
                fontSize: '22px',
              }}
            >
              <DataName>Total</DataName>
              <DataValue>{formatAmount(processedData?.totalRewardsUsd, 2, true, undefined, true)}</DataValue>
            </DataRow>
          </DataSection>
          <ActionButtonsWrapper>
            <ActionButton
              selected={selectedTab === SelectedTab.Claim}
              onClick={() => {
                updateSelectedTab(SelectedTab.Claim)
              }}
            >
              Claim
            </ActionButton>
            <ActionButton
              selected={selectedTab === SelectedTab.Compound}
              onClick={() => {
                updateSelectedTab(SelectedTab.Compound)
              }}
            >
              Compound
            </ActionButton>
          </ActionButtonsWrapper>
          <ExpandedContent expandedHeight={selectedTab === SelectedTab.Claim ? 190 : 250}>
            <ClaimSection visible={selectedTab === SelectedTab.Claim}>
              <ClaimRow onClick={() => setClaimGmxRewards(!claimGmxRewards)}>
                <CheckBox isChecked={claimGmxRewards} /> <ModifySectionText>GMX Rewards</ModifySectionText>
              </ClaimRow>
              <ClaimRow onClick={() => setClaimEsGmxRewards(!claimEsGmxRewards)}>
                <CheckBox isChecked={claimEsGmxRewards} /> <ModifySectionText>esGMX Rewards</ModifySectionText>
              </ClaimRow>
              <ClaimRow disabled>
                <CheckBox isChecked={claimWethRewards} /> <ModifySectionText>WETH Rewards</ModifySectionText>
              </ClaimRow>
              <ClaimRow onClick={() => setDepositWethIntoDolomite(!depositWethIntoDolomite)}>
                <CheckBox isChecked={depositWethIntoDolomite} />{' '}
                <ModifySectionText>Unwrap and deposit ETH into Dolomite</ModifySectionText>
              </ClaimRow>
              <SubmitButton disabled={!isButtonActive} onClick={() => isButtonActive && submitClaimCompound()}>
                {submitting ? <CircularProgress /> : 'Claim Rewards'}
              </SubmitButton>
            </ClaimSection>
            <CompoundSection visible={selectedTab === SelectedTab.Compound}>
              <ClaimRow disabled>
                <CheckBox isChecked={stakeMultiplierPoints} />{' '}
                <ModifySectionText>Stake Multiplier Points</ModifySectionText>
              </ClaimRow>
              <ClaimRow disabled={stakeGMXRewards} onClick={() => setClaimGmxRewards(!claimGmxRewards)}>
                <CheckBox isChecked={claimGmxRewards} /> <ModifySectionText>Claim GMX Rewards</ModifySectionText>
              </ClaimRow>
              <ClaimRow
                onClick={() => {
                  if (stakeGMXRewards) {
                    setStakeGMXRewards(!stakeGMXRewards)
                  } else {
                    setClaimGmxRewards(true)
                    setStakeGMXRewards(!stakeGMXRewards)
                  }
                }}
              >
                <CheckBox isChecked={stakeGMXRewards} /> <ModifySectionText>Stake GMX Rewards</ModifySectionText>
              </ClaimRow>
              <ClaimRow disabled>
                <CheckBox isChecked={claimEsGmxRewards} /> <ModifySectionText>Claim esGMX Rewards</ModifySectionText>
              </ClaimRow>
              <ClaimRow onClick={() => setStakeESGMXRewards(!stakeESGMXRewards)}>
                <CheckBox isChecked={stakeESGMXRewards} /> <ModifySectionText>Stake esGMX Rewards</ModifySectionText>
              </ClaimRow>
              <ClaimRow disabled>
                <CheckBox isChecked={claimWethRewards} /> <ModifySectionText>Claim WETH Rewards</ModifySectionText>
              </ClaimRow>
              <ClaimRow onClick={() => setDepositWethIntoDolomite(!depositWethIntoDolomite)}>
                <CheckBox isChecked={depositWethIntoDolomite} />{' '}
                <ModifySectionText>Unwrap and deposit ETH into Dolomite</ModifySectionText>
              </ClaimRow>
              <StyledTooltip
                title={submitting ? (awaitingSignature ? 'Awaiting signature...' : 'Submitting...') : ''}
                showTooltip={submitting}
                position={'top'}
              >
                <SubmitButton disabled={!isButtonActive} onClick={() => isButtonActive && submitClaimCompound()}>
                  {submitting ? <CircularProgress /> : 'Compound Rewards'}
                </SubmitButton>
              </StyledTooltip>
            </CompoundSection>
          </ExpandedContent>
        </RewardsWrapper>
      </AssetRewardsBody>
    </AssetRewardsWrapper>
  )
}
