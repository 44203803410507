import { Token } from '@dolomite-exchange/sdk-core'
import { ChainId, ChainIdMap } from '../chainId'
import { MIN_ADDRESSES } from '../index'

const decimals = 18
const symbol = 'MIN'
const name = 'Mineral'

export const MIN: ChainIdMap<Token | undefined> = {
  [ChainId.MAINNET]: getTokenByChain(ChainId.MAINNET),
  [ChainId.ARBITRUM_ONE]: getTokenByChain(ChainId.ARBITRUM_ONE),
  [ChainId.BASE]: getTokenByChain(ChainId.BASE),
  [ChainId.BERACHAIN]: getTokenByChain(ChainId.BERACHAIN),
  [ChainId.MANTLE]: getTokenByChain(ChainId.MANTLE),
  [ChainId.POLYGON_ZKEVM]: getTokenByChain(ChainId.POLYGON_ZKEVM),
  [ChainId.X_LAYER]: getTokenByChain(ChainId.X_LAYER),
}

function getTokenByChain(chainId: ChainId): Token | undefined {
  const tokenAddress = MIN_ADDRESSES[chainId]
  if (!tokenAddress) {
    return undefined
  }

  return new Token(chainId, tokenAddress, decimals, symbol, name)
}
