import { CurrencyAmount, Fraction, Token as SdkToken } from '@dolomite-exchange/sdk-core'
import { parseUnits } from '@ethersproject/units'
import { Transaction as SdkTransaction } from './transaction'
import JSBI from 'jsbi'
import invariant from 'tiny-invariant'

export interface AmmBurn {
  id: string
  transaction: Transaction
  timestamp: string
  pair: AmmPair | AmmPairWithIdAndTokens
  liquidity: string
  sender: string
  amount0: string
  amount1: string
  to: string
  logIndex: string
  amountUSD: string
  needsComplete: boolean
  feeTo: string
  feeLiquidity: string
}

export interface AmmFactory {
  id: string
  pairCount: number
  totalAmmVolumeUSD: string
  ammLiquidityUSD: string
  transactionCount: string
  ammTradeCount: string
  ammMintCount: string
  ammBurnCount: string
}

export interface AmmLiquidityPosition {
  id: string
  user: User | undefined
  pair: AmmPair
  liquidityTokenBalance: string
}

export interface AmmLiquidityPositionSnapshot {
  id: string
  user: User
  pair: AmmPair
  liquidityTokenBalance: string
}

export interface AmmMint {
  id: string
  transaction: Transaction
  timestamp: string
  pair: AmmPair | AmmPairWithIdAndTokens
  to: string
  liquidity: string
  sender: string
  amount0: string
  amount1: string
  logIndex: string
  amountUSD: string
  feeTo: string
  feeLiquidity: string
}

export interface AmmPairWithIdAndTokens {
  id: string
  token0: TokenWithId
  token1: TokenWithId
}

export interface AmmPair extends AmmPairWithIdAndTokens {
  token0: TokenWithId | Token
  token1: TokenWithId | Token
  reserve0: string
  reserve1: string
  totalSupply: string
  reserveETH: string
  reserveUSD: string
  trackedReserveETH: string
  token0Price: string
  token1Price: string
  volumeToken0: string
  volumeToken1: string
  volumeUSD: string
  transactionCount: string
  createdAtTimestamp: string
  createdAtBlockNumber: string
  liquidityProviderCount: string
  pairHourData: AmmPairHourData[]
  liquidityPositions: AmmLiquidityPosition[]
  liquidityPositionSnapshots: AmmLiquidityPositionSnapshot[]
  ammMints: AmmMint[]
  ammBurns: AmmBurn[]
  ammTrades: AmmTrade[]
}

export interface AmmPairHourData {
  id: string
  hourStartUnix: number
  pairAddress: string
  token0: Token | TokenWithId
  token1: Token | TokenWithId
  reserve0: string
  reserve1: string
  reserveUSD: string
  hourlyVolumeToken0: string
  hourlyVolumeToken1: string
  hourlyVolumeUSD: string
  hourlyTransactionCount: string
}

export interface AmmPairDayData {
  id: string
  dayStartUnix: number
  pairAddress: string
  token0: Token | TokenWithId
  token1: Token | TokenWithId
  reserve0: string
  reserve1: string
  reserveUSD: string
  totalSupply: string
  dailyVolumeToken0: string
  dailyVolumeToken1: string
  dailyVolumeUSD: string
  dailyTransactionCount: string
}

export interface AmmTrade {
  id: string
  transaction: Transaction
  timestamp: string
  pair: AmmPair | undefined
  sender: string
  from: string
  amount0In: string
  amount1In: string
  amount0Out: string
  amount1Out: string
  to: string
  logIndex: string
  serialId: string
  amountUSD: string
}

export interface BorrowPosition {
  id: string
  marginAccount: MarginAccount
  openTimestamp: string
  openTransaction: Transaction
  closeTimestamp: string | undefined
  closeTransaction: Transaction | undefined
  status: string
  amounts: BorrowPositionAmount[]
  effectiveSupplyTokens: Token[]
  effectiveBorrowTokens: Token[]
  effectiveUser: {
    id: string
  }
}

export interface BorrowPositionAmount {
  id: string
  token: Token | TokenWithId
  expirationTimestamp: string
  amountPar: string
  amountWei: string
}

export interface Deposit {
  id: string
  transaction: Transaction
  logIndex: string
  marginAccount: MarginAccount
  token: Token
  from: string
  amountDeltaWei: string
  amountUSDDeltaWei: string
  serialId: string
}

export interface DolomiteDayData {
  id: string
  dayStartUnix: number
  dailyAmmTradeVolumeUSD: string
  dailyBorrowVolumeUSD: string
  dailyLiquidationVolumeUSD: string
  dailySupplyVolumeUSD: string
  dailyTradeVolumeUSD: string
  dailyVaporizationVolumeUSD: string
  ammLiquidityUSD: string
  borrowLiquidityUSD: string
  supplyLiquidityUSD: string
  totalAllTransactionCount: string
  totalAmmTradeCount: string
  totalLiquidationCount: string
  totalTradeCount: string
  totalVaporizationCount: string
}

export interface DolomiteHourData {
  id: string
  hourStartUnix: number
  hourlyAmmTradeVolumeUSD: string
  hourlyBorrowVolumeUSD: string
  hourlyLiquidationVolumeUSD: string
  hourlySupplyVolumeUSD: string
  hourlyTradeVolumeUSD: string
  hourlyVaporizationVolumeUSD: string
  ammLiquidityUSD: string
  borrowLiquidityUSD: string
  supplyLiquidityUSD: string
  totalAllTransactionCount: string
  totalAmmTradeCount: string
  totalLiquidationCount: string
  totalTradeCount: string
  totalVaporizationCount: string
}

export interface DolomiteMargin {
  id: string
  liquidationRatio: string
  liquidationReward: string
  earningsRate: string
  minBorrowedValue: string
  supplyLiquidityUSD: string
  borrowLiquidityUSD: string
  totalBorrowVolumeUSD: string
  totalLiquidationVolumeUSD: string
  totalSupplyVolumeUSD: string
  totalTradeVolumeUSD: string
  totalVaporizationVolumeUSD: string
  actionCount: string
  liquidationCount: string
  tradeCount: string
  transactionCount: string
  vaporizationCount: string
}

export interface InterestIndex {
  id: string
  token: Token | TokenWithId
  borrowIndex: string
  supplyIndex: string
  lastUpdate: string
}

export interface InterestRate {
  id: string
  token: Token | TokenWithId
  interestSetter: string
  borrowInterestRate: string
  supplyInterestRate: string
  optimalUtilizationRate: string
  lowerOptimalRate: string
  upperOptimalRate: string
}

export interface MarketRiskInfo {
  id: string
  token: Token | TokenWithId
  isBorrowingDisabled: boolean
  marginPremium: string
  liquidationRewardPremium: string
  oracle: string
  supplyMaxWei: string | undefined
}

export interface Liquidation {
  id: string
  serialId: string
  transaction: Transaction
  logIndex: string
  solidMarginAccount: MarginAccount
  liquidMarginAccount: MarginAccount
  heldToken: Token
  borrowedToken: Token
  borrowedTokenAmountDeltaWei: string
  heldTokenAmountDeltaWei: string
  heldTokenLiquidationRewardWei: string
  borrowedTokenAmountUSD: string
  heldTokenAmountUSD: string
}

export interface MarginAccount {
  id: string
  user: User | UserWithId
  accountNumber: string
  lastUpdatedTimestamp: string
  lastUpdatedBlockNumber: string
  tokenValues: MarginAccountTokenValue[]
}

export interface MarginAccountTokenValue {
  id: string
  marginAccount: MarginAccount | undefined
  marketId: string
  token: Token
  valuePar: string
  expirationTimestamp: string | undefined
}

export interface MarginPosition {
  id: string
  marginAccount: MarginAccount
  openTimestamp: string
  heldToken: TokenWithId | undefined
  marginDeposit: string
  marginDepositUSD: string
  initialMarginDeposit: string
  initialMarginDepositUSD: string
  initialHeldAmountPar: string
  initialHeldAmountWei: string
  initialHeldAmountUSD: string
  initialHeldPrice: string
  initialHeldPriceUSD: string
  closeHeldPrice: string | undefined
  closeHeldPriceUSD: string | undefined
  closeHeldAmountWei: string | undefined
  closeHeldAmountSeized: string | undefined
  closeHeldAmountUSD: string | undefined
  heldAmountPar: string
  owedToken: TokenWithId | undefined
  initialOwedAmountPar: string
  initialOwedAmountWei: string
  initialOwedAmountUSD: string
  initialOwedPriceUSD: string
  initialOwedPrice: string
  closeOwedPriceUSD: string | undefined
  closeOwedPrice: string | undefined
  closeOwedAmountWei: string | undefined
  closeOwedAmountUSD: string | undefined
  owedAmountPar: string
  status: string
  closeTimestamp: string | undefined
  expirationTimestamp: string | undefined
  openTransaction: Transaction
  closeTransaction: Transaction
}

export interface TokenWithId {
  id: string
  marketId: string
}

export interface Token extends TokenWithId {
  chainId: number
  symbol: string
  name: string
  decimals: string
  tradeVolume: string
  tradeVolumeUSD: string
  transactionCount: string
  ammTradeLiquidity: string
  supplyLiquidity: string
  borrowLiquidity: string
  supplyLiquidityUSD: string
  borrowLiquidityUSD: string
  derivedETH: string
}

export interface TokenDayData {
  id: string
  dayStartUnix: number
  dailyAmmTradeVolumeUSD: string
  dailyBorrowVolumeUSD: string
  dailyLiquidationVolumeUSD: string
  dailyTradeVolumeUSD: string
  dailyVaporizationVolumeUSD: string
  dailyAmmTradeVolumeToken: string
  dailyBorrowVolumeToken: string
  dailyLiquidationVolumeToken: string
  dailyTradeVolumeToken: string
  dailyVaporizationVolumeToken: string
  ammLiquidityUSD: string
  borrowLiquidityUSD: string
  supplyLiquidityUSD: string
  ammLiquidityToken: string
  borrowLiquidityToken: string
  supplyLiquidityToken: string
  dailyAllTransactionCount: string
  dailyAmmTradeCount: string
  dailyLiquidationCount: string
  dailyTradeCount: string
  dailyVaporizationCount: string
  openPriceUSD: string
  highPriceUSD: string
  lowPriceUSD: string
  closePriceUSD: string
}

export interface TokenHourData {
  id: string
  hourStartUnix: number
  hourlyAmmTradeVolumeUSD: string
  hourlyBorrowVolumeUSD: string
  hourlyLiquidationVolumeUSD: string
  hourlyTradeVolumeUSD: string
  hourlyVaporizationVolumeUSD: string
  hourlyAmmTradeVolumeToken: string
  hourlyBorrowVolumeToken: string
  hourlyLiquidationVolumeToken: string
  hourlyTradeVolumeToken: string
  hourlyVaporizationVolumeToken: string
  ammLiquidityUSD: string
  borrowLiquidityUSD: string
  supplyLiquidityUSD: string
  ammLiquidityToken: string
  borrowLiquidityToken: string
  supplyLiquidityToken: string
  hourlyAllTransactionCount: string
  hourlyAmmTradeCount: string
  hourlyLiquidationCount: string
  hourlyTradeCount: string
  hourlyVaporizationCount: string
  openPriceUSD: string
  highPriceUSD: string
  lowPriceUSD: string
  closePriceUSD: string
  token: TokenWithId
}

export interface TotalPar {
  id: string
  borrowPar: string
  supplyPar: string
}

export interface Transaction {
  id: string
  blockNumber: string
  timestamp: string
  ammMints: AmmMint[] | undefined
  ammBurns: AmmBurn[] | undefined
  ammTrades: AmmTrade[] | undefined
  deposits: Deposit[] | undefined
  withdrawals: Withdrawal[] | undefined
  transfers: Transfer[] | undefined
  trades: Trade[] | undefined
  liquidations: Liquidation[] | undefined
  vaporization: Vaporization[] | undefined
}

export interface Trade {
  id: string
  serialId: string
  transaction: Transaction
  logIndex: string
  takerMarginAccount: MarginAccount
  makerMarginAccount: MarginAccount | undefined
  takerToken: Token
  makerToken: Token
  takerTokenDeltaWei: string
  makerTokenDeltaWei: string
  takerAmountUSD: string
  makerAmountUSD: string
  traderAddress: string
}

export interface Transfer {
  id: string
  serialId: string
  transaction: Transaction
  logIndex: string
  fromMarginAccount: MarginAccount
  fromAccountAddress: string
  toMarginAccount: MarginAccount
  toAccountAddress: string
  token: Token
  amountDeltaWei: string
  amountUSDDeltaWei: string
  toEffectiveUser: {
    id: string
    effectiveUser: {
      id: string
    }
  }
  fromEffectiveUser: {
    id: string
    effectiveUser: {
      id: string
    }
  }
}

export interface UserWithId {
  id: string
}

export interface User extends UserWithId {
  liquidityPositions: AmmLiquidityPosition[] | undefined
  marginAccounts: MarginAccount[] | undefined
  totalUsdBorrowed: string
  totalUsdCollateralLiquidated: string
  totalUsdAmmTraded: string
  totalUsdTraded: string
  totalBorrowPositionCount: string
  totalLiquidationCount: string
  totalMarginPositionCount: string
  totalTradeCount: string
}

export interface BorrowPositionDuration {
  openTimestamp: string
  closeTimestamp: string | undefined
}

export interface UserLifetime {
  totalBorrowPositionCount: string
  totalBorrowVolumeOriginatedUSD: string
  totalCollateralLiquidatedUSD: string
  totalLiquidationCount: string
  totalMarginPositionCount: string
  totalTradeCount: string
  totalTradeVolumeUSD: string
  totalZapCount: string
  totalZapVolumeUSD: string
  totalBorrowDurationSeconds: string
}

export interface Vaporization {
  id: string
  transaction: Transaction
  logIndex: string
  solidAccount: MarginAccount
  solidAccountAddress: string
  vaporAccount: MarginAccount
  vaporAccountAddress: string
  heldToken: Token
  borrowedToken: Token
  borrowedTokenAmountDeltaWei: string
  heldTokenAmountDeltaWei: string
  amountUSDVaporized: string
}

export interface Withdrawal {
  id: string
  serialId: string
  transaction: Transaction
  logIndex: string
  marginAccount: MarginAccount
  token: Token
  to: string
  amountDeltaWei: string
  amountUSDDeltaWei: string
}

interface PrivateInfo {
  email: string
  twitterUserID: string
  twitterUserName: string
  discordUserID: string
  discordUserName: string
  githubUserID: string
  githubUserName: string
  accessToken: string
}

enum CampaignStatus {
  Draft,
  Active,
  NotStarted,
  Expired,
  CapReached,
  Deleted,
}

interface Trait {
  name: string
  value: string
}

interface GalxeNFTTemplate {
  traits: Trait[]
}

interface GalxeDAO extends Node {
  id: string
  name: string
}

interface Cred {
  id: string
  name: string
  description: string
}

interface CredGroup {
  credentials: Cred[]
}

export interface GalxeCampaignEdge extends Node {
  node: {
    id: string
    name: string
    description: string
    endTime: number
    nftTemplates: GalxeNFTTemplate[] | undefined
    dao: GalxeDAO
    credentialGroups: CredGroup[]
  }
}

export interface Space {
  data: {
    space: {
      campaigns: {
        edges: GalxeCampaignEdge[]
      }
    }
  }
}

interface GalxeNFTCampaign {
  id: string
  name: string
  nftTemplates: GalxeNFTTemplate[]
  creds: CredGroup[]
  dao: GalxeDAO | undefined
  space: GalxeDAO | undefined
}

export interface GalxeNFTInfo {
  id: string
  campaign: GalxeNFTCampaign
}

export interface GalxeNFT extends Node {
  nfts: GalxeNFTInfo[]
}

const units = 18
const bigUnits = 36
const DENOMINATOR = '1000000000000000000'
const BIG_DENOMINATOR = '1000000000000000000000000000000000000'
export const getDayID = (date: Date): string => Math.floor(date.getTime() / 86400000).toString()
export const getHourID = (date: Date): string => Math.floor(date.getTime() / 3600000).toString()
export const createDateOpt = (dateString?: string): Date | undefined => {
  return dateString ? createDate(dateString) : undefined
}
export const createDate = (dateString: string): Date => {
  return new Date(parseInt(dateString) * 1000)
}
export const createFractionOpt = (field?: string): Fraction | undefined => {
  return field ? createFraction(field) : undefined
}
export const createFraction = (field: string): Fraction => {
  return new Fraction(parseUnits(field, units).toString(), DENOMINATOR)
}
export const createBigFraction = (field: string): Fraction => {
  return new Fraction(parseUnits(field, bigUnits).toString(), BIG_DENOMINATOR)
}
export const createBigFractionOpt = (field?: string): Fraction | undefined => {
  return field ? new Fraction(parseUnits(field, bigUnits).toString(), BIG_DENOMINATOR) : undefined
}
export const truncateFractionToCurrencyAmount = (fraction: Fraction, token: SdkToken): CurrencyAmount<SdkToken> => {
  invariant(
    JSBI.equal(fraction.denominator, JSBI.BigInt(DENOMINATOR)) ||
      JSBI.equal(fraction.denominator, JSBI.BigInt(BIG_DENOMINATOR)),
    'attempted to truncate a fraction whose denominator is not 1e18 or 1e54',
  )
  const decimals = JSBI.equal(fraction.denominator, JSBI.BigInt(DENOMINATOR)) ? units : bigUnits
  if (token.decimals === decimals) {
    return CurrencyAmount.fromRawAmount(token, fraction.numerator)
  } else {
    // truncate the numerator and denominator
    return CurrencyAmount.fromRawAmount(
      token,
      JSBI.divide(fraction.numerator, JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(decimals - token.decimals))),
    )
  }
}

export const createFractionUSD = (field: string): Fraction => {
  return new Fraction(parseUnits(field, bigUnits).toString(), BIG_DENOMINATOR)
}
export const createFractionUSDOpt = (field?: string): Fraction | undefined => {
  return field ? new Fraction(parseUnits(field, bigUnits).toString(), BIG_DENOMINATOR) : undefined
}
export const createCurrencyAmountOpt = (token?: SdkToken, field?: string): CurrencyAmount<SdkToken> | undefined => {
  return token && field ? CurrencyAmount.fromRawAmount(token, parseUnits(field, token.decimals).toString()) : undefined
}
export const createCurrencyAmount = (token: SdkToken, field: string): CurrencyAmount<SdkToken> => {
  return CurrencyAmount.fromRawAmount(token, parseUnits(field, token.decimals).toString())
}
export const createTransaction = (transaction: Transaction): SdkTransaction => {
  return {
    transactionHash: transaction.id,
    blockNumber: JSBI.BigInt(transaction.blockNumber),
    timestamp: new Date(parseInt(transaction.timestamp) * 1000),
  }
}

export enum EntityType {
  Deposit = 'Deposit',
  Liquidation = 'Liquidation',
  Trade = 'Trade',
  Transfer = 'Transfer',
  Withdrawal = 'Withdrawal',
}
