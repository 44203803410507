import { R_ETH_ADDRESSES } from '../../index'
import { SpecialAsset, SpecialAssetSymbol } from '../special-assets'

export const R_ETH_SPECIAL_ASSET: () => SpecialAsset = () => ({
  symbol: SpecialAssetSymbol.rETH,
  safeSymbol: SpecialAssetSymbol.rETH,
  cleanSymbol: 'rETH',
  cleanName: 'Rocket ETH',
  hasExternalRewards: false,
  documentationUrl: undefined,
  isIsolationMode: false,
  isBorrowingEnabled: true,
  chainIdToAddressMap: R_ETH_ADDRESSES,
  isolationModeInfo: undefined,
})
