import { gql } from '@apollo/client'
import {
  createCurrencyAmount,
  createFractionUSD,
  createTransaction,
  Deposit as DepositGql,
  EntityType,
  Withdrawal as WithdrawalGql,
} from './gqlTypeHelpers'
import { depositGql, withdrawalGql } from './queryObjects'
import { useMemo } from 'react'
import { useAllTokens } from '../hooks/Tokens'
import { DolomiteDepositOrWithdrawal } from './apiTypeHelpers'
import { toChecksumAddress } from '../utils/toChecksumAddress'
import { useGraphqlResult } from '../state/graphql/hooks'
import { GraphqlClientType } from '../state/graphql/actions'
import { RefreshFrequency } from '../state/chain/hooks'

const PAGE_SIZE = 50

const DEPOSITS_BY_WALLET_DATA = gql`
    query transfersByWallet($blockNumber: Int!, $walletAddress: String!) {
        deposits(
            block: { number_gte: $blockNumber }
            where: { effectiveUser: $walletAddress },
            orderBy: serialId,
            orderDirection: desc,
            first: ${PAGE_SIZE}
        ) {
            ${depositGql()}
        }
        withdrawals: withdrawals(
            block: { number_gte: $blockNumber }
            where: { effectiveUser: $walletAddress },
            orderBy: serialId,
            orderDirection: desc,
            first: ${PAGE_SIZE}
        ) {
            ${withdrawalGql()}
        }
    }
`

const DEPOSITS_BY_TOKEN_DATA = gql`
    query transfersByToken($blockNumber: Int!, $tokenAddress: String!) {
        deposits(
            block: { number_gte: $blockNumber }
            where: { token: $tokenAddress },
            orderBy: serialId,
            orderDirection: desc,
            first: ${PAGE_SIZE}
        ) {
            ${depositGql()}
        }
        withdrawals: withdrawals(
            block: { number_gte: $blockNumber }
            where: { token: $tokenAddress },
            orderBy: serialId,
            orderDirection: desc,
            first: ${PAGE_SIZE}
        ) {
            ${withdrawalGql()}
        }
    }
`

const TRANSFERS_BY_TIME = gql`
    query allTransfers($blockNumber: Int!) {
        deposits(
            block: { number_gte: $blockNumber }
            orderBy: serialId,
            orderDirection: desc,
            first: ${PAGE_SIZE}
        ) {
            ${depositGql()}
        }
        withdrawals: withdrawals(
            block: { number_gte: $blockNumber }
            orderBy: serialId,
            orderDirection: desc,
            first: ${PAGE_SIZE}
        ) {
            ${withdrawalGql()}
        }
    }
`

interface PositionDepositResponse {
  deposits: DepositGql[]
  withdrawals: WithdrawalGql[]
}

export function useTransfersByTime(): {
  loading: boolean
  error: boolean
  data: DolomiteDepositOrWithdrawal[]
} {
  return useTransfers(TRANSFERS_BY_TIME.loc!.source.body, 'filler', undefined)
}

export function useTransfersByToken(
  tokenAddress?: string,
): {
  loading: boolean
  error: boolean
  data: DolomiteDepositOrWithdrawal[]
} {
  return useTransfers(DEPOSITS_BY_TOKEN_DATA.loc!.source.body, undefined, tokenAddress)
}

export function useTransfersByWallet(
  walletAddress?: string,
): {
  loading: boolean
  error: boolean
  data: DolomiteDepositOrWithdrawal[]
} {
  return useTransfers(DEPOSITS_BY_WALLET_DATA.loc!.source.body, walletAddress, undefined)
}

function useTransfers(
  query: string,
  walletAddress?: string | 'filler',
  tokenAddress?: string,
): {
  loading: boolean
  error: boolean
  data: DolomiteDepositOrWithdrawal[]
} {
  const variables = useMemo(() => {
    if (!walletAddress && !tokenAddress) {
      return undefined
    }
    if (walletAddress === 'filler') {
      return {}
    }
    if (tokenAddress) {
      return { tokenAddress: tokenAddress.toLowerCase() }
    }
    return {
      walletAddress: walletAddress?.toLowerCase(),
    }
  }, [walletAddress, tokenAddress])
  const queryState = useGraphqlResult<PositionDepositResponse>(
    GraphqlClientType.Dolomite,
    query,
    variables,
    walletAddress ? RefreshFrequency.Fast : RefreshFrequency.Slow,
  )
  const tokenMap = useAllTokens()

  return useMemo(() => {
    const anyLoading = Boolean(queryState.loading)
    const anyError = Boolean(queryState.error)

    const deposits = (queryState.result?.deposits ?? [])
      .map<DolomiteDepositOrWithdrawal | undefined>(deposit => {
        const token = tokenMap[toChecksumAddress(deposit.token.id)]
        if (!token) {
          return undefined
        }
        return {
          type: EntityType.Deposit,
          transaction: createTransaction(deposit.transaction),
          logIndex: Number.parseInt(deposit.logIndex),
          serialId: Number.parseInt(deposit.serialId),
          account: deposit.from,
          token: token,
          amount: createCurrencyAmount(token, deposit.amountDeltaWei),
          amountUSD: createFractionUSD(deposit.amountUSDDeltaWei),
        }
      })
      .filter(deposit => !!deposit)
      .map<DolomiteDepositOrWithdrawal>(deposit => deposit!)
    const withdrawals = (queryState.result?.withdrawals ?? [])
      .map<DolomiteDepositOrWithdrawal | undefined>(withdrawal => {
        const token = tokenMap[toChecksumAddress(withdrawal.token.id)]
        if (!token) {
          return undefined
        }
        return {
          type: EntityType.Withdrawal,
          transaction: createTransaction(withdrawal.transaction),
          logIndex: Number.parseInt(withdrawal.logIndex),
          serialId: Number.parseInt(withdrawal.serialId),
          account: withdrawal.to,
          token: token,
          amount: createCurrencyAmount(token, withdrawal.amountDeltaWei),
          amountUSD: createFractionUSD(withdrawal.amountUSDDeltaWei),
        }
      })
      .filter(withdrawal => !!withdrawal)
      .map<DolomiteDepositOrWithdrawal>(withdrawal => withdrawal!)
    const depositWithdrawals = deposits.concat(withdrawals).sort((a, b) => b.serialId - a.serialId)
    return {
      loading: anyLoading,
      error: anyError,
      data: depositWithdrawals,
    }
  }, [queryState, tokenMap])
}
