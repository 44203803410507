import { getSpecialAsset } from '../../constants/isolation/special-assets'
import { AutoRow } from '../Row'
import CircularProgress from '@material-ui/core/CircularProgress'
import React from 'react'
import { CurrencyAmount } from '@dolomite-exchange/sdk-core'
import { Currency } from '@dolomite-exchange/v2-sdk'
import { ChainId } from '../../constants'
import styled from 'styled-components/macro'
import { ButtonError } from '../Button'
import { Text } from 'rebass'
import { ReactComponent as LockIcon } from '../../assets/images/shieldIcon.svg'
import { UnlockStage } from './UnlockModal'

const SubmitButton = styled(ButtonError)<{ isLoading: boolean; isUnlock: boolean }>`
  background-color: ${({ theme, isUnlock }) => (isUnlock ? theme.blue1 : theme.green2)} !important;

  :hover {
    background-color: ${({ theme, isLoading, isUnlock }) => (isUnlock ? theme.blue2 : theme.green1)} !important;
  }

  :focus {
    background-color: ${({ theme, isLoading, isUnlock }) => (isUnlock ? theme.blue1 : theme.green2)} !important;
  }

  :active {
    background-color: ${({ theme, isLoading, isUnlock }) => (isUnlock ? theme.blue1 : theme.green2)} !important;
  }

  > div {
    line-height: 30px;
  }
`

const IsolationDescription = styled.div`
  font-size: 12px;
  font-weight: 100;
  pointer-events: auto;
  color: ${({ theme }) => theme.text2};
  width: 100%;
  background: #2f2f40;
  border-radius: 5px;

  div > svg {
    margin-right: 4px;
    margin-bottom: -1px;
    font-size: 30px;
    color: ${({ theme }) => theme.text3};
  }

  a {
    color: ${({ theme }) => theme.text2};
    font-weight: 600;
    margin-left: 3px;
  }

  div {
    display: inline-block;
  }

  div:nth-child(1) {
    vertical-align: top;
    margin-top: 10px;
  }

  div:nth-child(2) {
    width: calc(100% - 34px);
  }

  > div > b {
    color: ${({ theme }) => theme.yellow2};
  }
`

const LoadingWrapper = styled.div`
  display: inline-block;
  vertical-align: top;
  height: 30px;

  > svg,
  > div {
    color: ${({ theme }) => theme.text1} !important;
    font-size: 30px;
    height: 30px !important;
    width: 30px !important;
  }
`

function UnlockModalFooterComparator(prevProps: UnlockModalFooterProps, nextProps: UnlockModalFooterProps) {
  return (
    prevProps.amount === nextProps.amount &&
    prevProps.unlockStage === nextProps.unlockStage &&
    prevProps.onConfirm === nextProps.onConfirm &&
    prevProps.chainId === nextProps.chainId &&
    prevProps.attemptingTxn === nextProps.attemptingTxn &&
    prevProps.isLoading === nextProps.isLoading &&
    prevProps.isUnlockModal === nextProps.isUnlockModal
  )
}

interface UnlockModalFooterProps {
  amount: CurrencyAmount<Currency> | undefined
  unlockStage: UnlockStage
  onConfirm: () => void
  chainId: ChainId
  attemptingTxn: boolean
  isUnlockModal: boolean
  isLoading: boolean
}

function UnlockModalFooter({
  amount,
  isUnlockModal,
  unlockStage,
  onConfirm,
  chainId,
  attemptingTxn,
  isLoading,
}: UnlockModalFooterProps) {
  return (
    <>
      {isUnlockModal && getSpecialAsset(chainId, amount?.currency.wrapped)?.isIsolationMode && (
        <IsolationDescription>
          <div>
            <LockIcon />
          </div>
          <div>
            {`Isolation Mode assets are deployed as wrapped contracts around the listed asset. `}
            <b>The contract appears as an EOA until you make your first deposit.</b>
            <a href={'https://docs.dolomite.io/risk-mitigation#isolation-mode'} target={'_blank'} rel={'noreferrer'}>
              Learn more
            </a>
          </div>
        </IsolationDescription>
      )}
      <AutoRow>
        <SubmitButton
          onClick={() => !isLoading && !attemptingTxn && onConfirm()}
          isLoading={isLoading || attemptingTxn}
          style={{ margin: '10px 0 0 0' }}
          isUnlock={unlockStage === UnlockStage.UNLOCK}
        >
          {attemptingTxn || isLoading ? (
            <LoadingWrapper>
              <CircularProgress />
            </LoadingWrapper>
          ) : (
            <Text fontSize={20} fontWeight={500}>
              {`${unlockStage < UnlockStage.COMPLETE ? 'Confirm' : ''} ${
                unlockStage === UnlockStage.UNLOCK
                  ? isUnlockModal
                    ? 'Unlock'
                    : 'Create Vault'
                  : unlockStage === UnlockStage.DEPOSIT
                  ? 'Deposit'
                  : 'Close'
              }`}
            </Text>
          )}
        </SubmitButton>
      </AutoRow>
    </>
  )
}

export default React.memo(UnlockModalFooter, UnlockModalFooterComparator)
