import { CurrencyAmount } from '@dolomite-exchange/sdk-core'
import { Currency, Fraction } from '@dolomite-exchange/v2-sdk'
import React, { useMemo } from 'react'
import cleanCurrencySymbol from '../../utils/cleanCurrencySymbol'
import Checkmark from '@material-ui/icons/Check'
import checkFractionOptEquality from '../../utils/checkFractionOptEquality'
import styled from 'styled-components/macro'

import { formatAmount } from '../../utils/formatAmount'
import { UnlockStage } from './UnlockModal'

const TopWrapper = styled.div<{ isCompleted: boolean }>`
  margin-top: 20px;
  margin-bottom: ${({ isCompleted }) => (isCompleted ? 0 : -20)}px;
  transition: margin-bottom 0.3s ease-in-out;
`

const UnlockStep = styled.div<{ expanded: boolean }>`
  display: block;
  height: ${({ expanded }) => (expanded ? 84 : 42)}px;
  transition: height 0.3s ease-in-out;
`

const UnlockProgress = styled.div`
  display: inline-block;
  vertical-align: top;
  position: relative;
  height: 100%;
`

const Circle = styled.div<{ complete: boolean }>`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid ${({ complete, theme }) => (complete ? theme.green2 : theme.white)};
  background-color: ${({ complete, theme }) => (complete ? theme.green2 : 'none')};
  font-size: 16px;
  font-weight: 600;
  padding-left: ${({ complete }) => (complete ? 1 : 8.5)}px;
  padding-top: ${({ complete }) => (complete ? 1.4 : 2)}px;
`

const Line = styled.div<{ expanded: boolean; complete: boolean }>`
  width: 2px;
  background-color: ${({ complete, theme }) => (complete ? theme.green2 : theme.white)};
  transition: background-color 0.3s ease-in-out;
  margin-left: 14px;
  height: calc(100% - 30px);
`

const UnlockText = styled.div`
  display: inline-block;
  width: calc(100% - 50px);
  margin-left: 18px;
`

const Title = styled.div`
  font-size: 21px;
`

const Subtitle = styled.div<{ visible: boolean }>`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.text3};
  margin-top: 4px;
  padding-right: 8%;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
`

const Complete = styled.div<{ visible: boolean }>`
  font-size: 15px;
  color: ${({ theme }) => theme.green2};
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
`

interface Props {
  amount: CurrencyAmount<Currency> | undefined
  fiatAmount: Fraction | undefined
  unlockStage: UnlockStage
  isUnlockModal: boolean
}

function UnlockModalHeaderComparator(prevProps: Props, nextProps: Props) {
  return (
    checkFractionOptEquality(prevProps.amount, nextProps.amount) &&
    checkFractionOptEquality(prevProps.fiatAmount, nextProps.fiatAmount) &&
    prevProps.unlockStage === nextProps.unlockStage &&
    prevProps.isUnlockModal === nextProps.isUnlockModal
  )
}

function UnlockModalHeader({ amount, fiatAmount, unlockStage, isUnlockModal }: Props) {
  const symbol = useMemo(() => cleanCurrencySymbol(amount?.currency, false), [amount])
  return (
    <TopWrapper isCompleted={unlockStage === UnlockStage.COMPLETE}>
      <UnlockStep expanded={unlockStage === UnlockStage.UNLOCK}>
        <UnlockProgress>
          <Circle complete={unlockStage > UnlockStage.UNLOCK}>
            {unlockStage === UnlockStage.UNLOCK ? '1' : <Checkmark />}
          </Circle>
          <Line expanded={unlockStage === UnlockStage.UNLOCK} complete={!(unlockStage === UnlockStage.UNLOCK)} />
        </UnlockProgress>
        <UnlockText>
          <Title>{isUnlockModal ? 'Unlock' : 'Create Vault'}</Title>
          <Subtitle visible={unlockStage === UnlockStage.UNLOCK}>
            {isUnlockModal
              ? `Unlocking allows Dolomite to access the ${symbol} in your wallet in order to enable the deposit.`
              : `Creating your smart vault for ${symbol} is a one-time operation and enables you to deposit this asset in Dolomite.`}
          </Subtitle>
        </UnlockText>
      </UnlockStep>
      <UnlockStep expanded={unlockStage === UnlockStage.DEPOSIT}>
        <UnlockProgress>
          <Circle complete={unlockStage > UnlockStage.DEPOSIT}>
            {unlockStage < UnlockStage.COMPLETE ? '2' : <Checkmark />}
          </Circle>
        </UnlockProgress>
        <UnlockText>
          <Title>Deposit</Title>
          <Subtitle visible={unlockStage === UnlockStage.DEPOSIT}>
            {`Complete your deposit of ${formatAmount(amount, 6, true, '0.00')} ${symbol} ${formatAmount(
              amount,
              6,
              true,
              '0.00',
            )} ${symbol} (${formatAmount(fiatAmount, 2, true, '0.00', true)}) from your wallet into Dolomite.`}
          </Subtitle>
        </UnlockText>
      </UnlockStep>
      <Complete visible={unlockStage === UnlockStage.COMPLETE}>Deposit Complete</Complete>
    </TopWrapper>
  )
}

export default React.memo(UnlockModalHeader, UnlockModalHeaderComparator)
