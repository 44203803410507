import { Fraction } from '@dolomite-exchange/sdk-core'
import { BIG_INT_ZERO, MAX_FRACTION, ZERO_FRACTION } from '../constants'
import JSBI from 'jsbi'

export function averageFractions<T>(list: T[], getFraction: (value: T) => Fraction): Fraction {
  return sumFractions(list, getFraction).divide(list.length)
}

export function sumFractions<T>(list: T[], getFraction: (value: T) => Fraction): Fraction {
  return list.reduce((sum, value) => {
    return sum.add(getFraction(value))
  }, ZERO_FRACTION)
}

export function maxValue<T>(list: T[], getFraction: (value: T) => Fraction): Fraction {
  return list.reduce((max, value) => {
    if (getFraction(value).greaterThan(max)) {
      return getFraction(value)
    } else {
      return max
    }
  }, ZERO_FRACTION)
}

export function maxBy<T>(list: T[], getFraction: (value: T) => Fraction): T | undefined {
  if (list.length === 0) {
    return undefined
  }

  return list.reduce((max, value) => {
    if (getFraction(value).greaterThan(getFraction(max))) {
      return value
    } else {
      return max
    }
  }, list[0])
}

export function countBy<T>(list: T[], getTruthy: (value: T) => boolean): number {
  if (list.length === 0) {
    return 0
  }

  return list.reduce((memo, value) => {
    if (getTruthy(value)) {
      return memo + 1
    } else {
      return memo
    }
  }, 0)
}

export function minValue<T>(list: T[], getFraction: (value: T) => Fraction): Fraction {
  const minValue = list.reduce((min, value) => {
    if (getFraction(value).lessThan(min)) {
      return getFraction(value)
    } else {
      return min
    }
  }, MAX_FRACTION)
  return minValue.equalTo(MAX_FRACTION) ? ZERO_FRACTION : minValue
}

export function minBy<T>(list: T[], getFraction: (value: T) => Fraction): T | undefined {
  if (list.length === 0) {
    return undefined
  }

  return list.reduce((min, value) => {
    if (getFraction(value).lessThan(getFraction(min))) {
      return value
    } else {
      return min
    }
  }, list[0])
}

export function sumBigIntegers<T>(list: T[], getJSBI: (value: T) => JSBI): JSBI {
  return list.reduce((sum, value) => {
    return JSBI.add(sum, getJSBI(value))
  }, BIG_INT_ZERO)
}
