import { useActiveWeb3React } from '../../hooks'
import { useEffect } from 'react'
import * as Sentry from '@sentry/browser'

export default function Updater(): null {
  const { account } = useActiveWeb3React()
  useEffect(() => {
    if (account) {
      Sentry.setUser({ username: account })
    } else {
      Sentry.setUser(null)
    }
  }, [account])

  return null
}
