import { Token } from '@dolomite-exchange/sdk-core'
import { ChainId, ChainIdMap, initializeSingleValueChainIdMap } from '../chainId'
import { FEE_GMX_REWARD_TRACKER_ADDRESSES } from '../index'

const decimals = 18
const symbol = 'sbfGMX'
const name = 'Staked + Bonus + Fee GMX'

export const SBF_GMX: ChainIdMap<Token | undefined> = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  new Token(ChainId.ARBITRUM_ONE, FEE_GMX_REWARD_TRACKER_ADDRESSES[ChainId.ARBITRUM_ONE]!, decimals, symbol, name),
)
