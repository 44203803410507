import React from 'react'
import styled from 'styled-components/macro'
import ChainalysisLogo from '../../assets/images/chainalysis-logo.png'
import { useLocation } from 'react-router-dom'

const ChainalysisWrapper = styled.div<{ isSwap: boolean }>`
  ${({ isSwap }) => isSwap && 'display: none;'}
  position: fixed;
  bottom: 10px;
  left: 15px;
  width: 100px;

  a {
    text-decoration: none;
  }

  img {
    width: 100%;
    height: auto;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: none;
  `}
`

const ProtectedByText = styled.div`
  font-size: 9px;
  color: ${({ theme }) => theme.text2};
  font-weight: 300;
`

export default function Chainalysis() {
  const location = useLocation()
  const isSwap = location.pathname.includes('/trade')

  return (
    <ChainalysisWrapper isSwap={isSwap}>
      <a
        href={'https://docs.dolomite.io/audits-and-security/chainalysis-crypto-incident-response'}
        target={'_blank'}
        rel={'noopener noreferrer'}
      >
        <ProtectedByText>Protected By</ProtectedByText>
        <img src={ChainalysisLogo} />
      </a>
    </ChainalysisWrapper>
  )
}
