import { splitSignature } from 'ethers/lib/utils'
import { Currency, CurrencyAmount, Token } from '@dolomite-exchange/sdk-core'
import { Contract } from '@ethersproject/contracts'
import { BigNumber } from 'ethers'
import { Signature } from '@ethersproject/bytes'
import { USER_ERROR_CODES } from '../constants'
import { DolomiteWeb3Context } from './index'
import { Web3Provider } from '@ethersproject/providers'

export interface SignatureDataObject {
  v: number
  r: string
  s: string
  deadline: number
  amount: CurrencyAmount<Token>
}

function mapPermitDataToString(
  domainName: string,
  chainId: number,
  pairContract: Contract,
  account: string,
  spenderAddress: string,
  liquidityAmount: CurrencyAmount<Currency>,
  nonce: BigNumber,
  deadline: BigNumber,
) {
  const EIP712Domain = [
    {
      name: 'name',
      type: 'string',
    },
    {
      name: 'version',
      type: 'string',
    },
    {
      name: 'chainId',
      type: 'uint256',
    },
    {
      name: 'verifyingContract',
      type: 'address',
    },
  ]
  const domain = {
    name: domainName,
    version: '1',
    chainId: chainId,
    verifyingContract: pairContract.address,
  }
  const Permit = [
    {
      name: 'owner',
      type: 'address',
    },
    {
      name: 'spender',
      type: 'address',
    },
    {
      name: 'value',
      type: 'uint256',
    },
    {
      name: 'nonce',
      type: 'uint256',
    },
    {
      name: 'deadline',
      type: 'uint256',
    },
  ]
  const message = {
    owner: account,
    spender: spenderAddress,
    value: liquidityAmount.quotient.toString(),
    nonce: nonce.toHexString(),
    deadline: deadline.toNumber(),
  }

  return JSON.stringify({
    types: {
      EIP712Domain,
      Permit,
    },
    domain,
    primaryType: 'Permit',
    message,
  })
}

/**
 * Attempts to perform a permit for the provided token. If permitting is not supported, it will attempt to approve the
 * token instead via the call to `approveCallback`.
 */
export function onAttemptToPermit(
  parsedAmount: CurrencyAmount<Token> | undefined,
  spenderAddress: string | undefined,
  web3Interface: DolomiteWeb3Context,
  deadline: BigNumber | undefined,
  contract: Contract | null,
  domainName: string,
  isArgentWallet: boolean,
  approveCallback: () => Promise<string>,
  setSignatureData: (o: SignatureDataObject) => void,
): Promise<string | SignatureDataObject> {
  if (!spenderAddress || !contract || !deadline) {
    return Promise.reject(new Error('missing dependencies'))
  }

  const { chainId, library, account } = web3Interface
  if (!chainId || !library || !account || !(library instanceof Web3Provider)) {
    return Promise.reject(new Error('missing dependencies from web3Interface'))
  }

  if (!parsedAmount) {
    return Promise.reject(new Error('missing liquidity amount'))
  }

  if (isArgentWallet) {
    return approveCallback()
  }

  if (!contract.nonces) {
    return approveCallback()
  }

  return contract
    .nonces(account)
    .then((nonce: any) => {
      const data = mapPermitDataToString(
        domainName,
        chainId,
        contract,
        account,
        spenderAddress,
        parsedAmount,
        nonce,
        deadline,
      )
      return library.send('eth_signTypedData_v4', [account, data])
    })
    .then(splitSignature)
    .then((signature: Signature) => {
      const signatureObject = {
        v: signature.v,
        r: signature.r,
        s: signature.s,
        amount: parsedAmount,
        deadline: deadline.toNumber(),
      }
      setSignatureData(signatureObject)
      return signatureObject
    })
    .catch((error: any) => {
      // for all errors other than 4001 (EIP-1193 user rejected request), fall back to manual approve
      if (error?.code !== USER_ERROR_CODES.REJECTED) {
        console.error('Caught an unknown error when attempting to permit:', error)
        return approveCallback()
      } else {
        return Promise.resolve()
      }
    })
}
