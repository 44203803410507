export default class ExpiringMap<K, V> {
  private data = new Map<K, { value: V; expirationTime: number }>()

  constructor(private expirationDurationMs: number) {}

  set(key: K, value: V): void {
    const expirationTime = Date.now() + this.expirationDurationMs
    this.data.set(key, {
      value,
      expirationTime,
    })
  }

  get(key: K): V | undefined {
    const entry = this.data.get(key)
    if (entry && entry.expirationTime > Date.now()) {
      return entry.value
    } else {
      this.data.delete(key)
      return undefined
    }
  }
}
