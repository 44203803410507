import { useWethToken } from '../Tokens'
import { useDefaultFiatValuesWithLoadingIndicator } from '../useFiatValue'
import { useMemo } from 'react'
import {
  useGlpAum,
  useGmxBalanceWithSupplies,
  useGmxDepositInfo,
  useGmxPriceUSD,
  useGmxStakingInfos,
  useGmxSupply,
  useGmxVestingInfo,
  useStakedGmxSupply,
} from './useGmxProtocol'
import useMidAum from './useMidAum'
import useGlpProxyVaultAccount from './useGlpProxyVaultAccount'
import { GmxProcessedData, processGmxAndGlpData } from '../../utils/processGmxAndGlpData'
import moment from 'moment'

export default function useGmxPendingRewards(): [GmxProcessedData | undefined] {
  const wethToken = useWethToken()
  const [fiatPriceMap] = useDefaultFiatValuesWithLoadingIndicator(useMemo(() => [wethToken], [wethToken])) // Takes too long, need to make more efficient
  const wethPrice = fiatPriceMap[wethToken.address]
  const [aums] = useGlpAum()
  const aum = useMidAum(aums)
  const glpVaultAccount = useGlpProxyVaultAccount()
  const [balanceAndSupplyData] = useGmxBalanceWithSupplies(glpVaultAccount)
  const [gmxPrice] = useGmxPriceUSD() // Takes too long, need to make more efficient
  const [depositBalanceData] = useGmxDepositInfo(glpVaultAccount)
  const [stakingData] = useGmxStakingInfos(glpVaultAccount)
  const [vestingData] = useGmxVestingInfo(glpVaultAccount)
  const stakedGmxSupply = useStakedGmxSupply()
  const gmxSupply = useGmxSupply()

  const processedData = useMemo(() => {
    return processGmxAndGlpData({
      balanceData: balanceAndSupplyData?.balanceData,
      supplyData: balanceAndSupplyData?.supplyData,
      nativeTokenPrice: wethPrice,
      depositBalanceData,
      stakingData,
      vestingData,
      aum,
      stakedGmxSupply,
      gmxPrice,
      gmxSupply,
    })
  }, [
    aum,
    balanceAndSupplyData,
    depositBalanceData,
    gmxPrice,
    gmxSupply,
    stakedGmxSupply,
    stakingData,
    vestingData,
    wethPrice,
  ])
  return [processedData]
}
