import React, { useContext, useRef, useState } from 'react'
import styled, { ThemeContext } from 'styled-components/macro'

import QuestionHelper from '../QuestionHelper'
import { TYPE } from '../../theme'
import { AutoColumn } from '../Column'
import { RowBetween, RowFixed } from '../Row'
import { useShowInactiveTokens, useShowYieldAsApr } from '../../state/user/hooks'

import { darken } from 'polished'

enum SlippageError {
  InvalidInput = 'InvalidInput',
  RiskyLow = 'RiskyLow',
  RiskyHigh = 'RiskyHigh',
}

enum DeadlineError {
  InvalidInput = 'InvalidInput',
}

const FancyButton = styled.button`
  color: ${({ theme }) => theme.text1};
  align-items: center;
  height: 2rem;
  border-radius: 36px;
  font-size: 1rem;
  width: auto;
  min-width: 3.5rem;
  border: 1px solid ${({ theme }) => theme.bg3};
  outline: none;
  background: ${({ theme }) => theme.bg1};

  :hover {
    border: 1px solid ${({ theme }) => theme.bg4};
  }

  :focus {
    border: 1px solid ${({ theme }) => theme.primary1};
  }
`

const Option = styled(FancyButton)<{ active: boolean }>`
  margin-right: 8px;

  :hover {
    cursor: pointer;
  }

  background-color: ${({ active, theme }) => active && theme.primary1};
  color: ${({ active, theme }) => (active ? theme.white : theme.text1)};
`

const Input = styled.input`
  background: ${({ theme }) => theme.bg1};
  font-size: 16px;
  width: auto;
  outline: none;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  color: ${({ theme, color }) => (color === 'red' ? theme.red1 : theme.text1)};
  text-align: right;
`

const OptionCustom = styled(FancyButton)<{ active?: boolean; warning?: boolean }>`
  height: 2rem;
  position: relative;
  padding: 0 0.75rem;
  flex: 1;
  border: ${({ theme, active, warning }) => active && `1px solid ${warning ? theme.red1 : theme.primary1}`};

  background-color: ${({ active, theme }) => active && theme.primary1};
  color: ${({ active, theme }) => (active ? theme.white : theme.text1)};

  :hover {
    border: ${({ theme, active, warning }) =>
      active && `1px solid ${warning ? darken(0.1, theme.red1) : darken(0.1, theme.primary1)}`};
  }

  input {
    width: 100%;
    height: 100%;
    border: 0;
    border-radius: 2rem;

    background-color: ${({ active, theme }) => active && theme.primary1};
    color: ${({ active, theme }) => (active ? theme.white : theme.text1)};
  }
`

const AprSelect = styled.div`
  display: inline-block;
  width: 100px;
  margin-top: 4px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.bg1};
  font-size: 14px;
  cursor: pointer;

  :hover {
    background-color: ${({ theme }) => theme.bg3};
  }
`

const AprSelector = styled.div<{ active: boolean }>`
  width: 50px;
  display: inline-block;
  padding: 5px 2px;
  border-radius: 5px;
  text-align: center;
  background-color: ${({ active, theme }) => active && theme.bg4};
`

const DeadlineWrapper = styled.div`
  width: fit-content;

  > div {
    display: inline-block;
  }
`

const SlippageEmojiContainer = styled.span`
  color: #f3841e;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;  
  `}
`

const InactiveTokens = styled.div`
  margin-top: 3px;
`

const TextWrapper = styled.div`
  display: inline-block;
  margin-right: 6px;
`

const Slider = styled.div<{ isOn: boolean }>`
  width: 24px;
  height: 14px;
  border-radius: 7px;
  background-color: ${({ theme, isOn }) => (isOn ? theme.blue1 : theme.bg5)};
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 2px;
`

const SliderDot = styled.div<{ isOn: boolean }>`
    width: 10px;
    height: 10px;
    margin: 2px 0 2px 2px;
    transition: transform 0.2s ease-in-out;
    border-radius: 50%;
    display: inline-block;
    vertical-align: top;
    ${({ isOn }) => isOn && 'transform: translateX(10px);'}
    background: ${({ theme }) => theme.bg2};
`

export interface SlippageTabsProps {
  rawSlippage: number
  setRawSlippage: (rawSlippage: number) => void
  deadline: number
  setDeadline: (deadline: number) => void
}

export default function SlippageTabs({ rawSlippage, setRawSlippage, deadline, setDeadline }: SlippageTabsProps) {
  const theme = useContext(ThemeContext)
  const [showYieldAsApr, setShowYieldAsApr] = useShowYieldAsApr()
  const [showTokens, setShowTokens] = useShowInactiveTokens()

  const inputRef = useRef<HTMLInputElement>()

  const [slippageInput, setSlippageInput] = useState('')
  const [deadlineInput, setDeadlineInput] = useState('')

  const slippageInputIsValid =
    slippageInput === '' || (rawSlippage / 100).toFixed(2) === Number.parseFloat(slippageInput).toFixed(2)
  const deadlineInputIsValid = deadlineInput === '' || (deadline / 60).toString() === deadlineInput

  let slippageError: SlippageError | undefined
  if (slippageInput !== '' && !slippageInputIsValid) {
    slippageError = SlippageError.InvalidInput
  } else if (slippageInputIsValid && rawSlippage < 50) {
    slippageError = SlippageError.RiskyLow
  } else if (slippageInputIsValid && rawSlippage > 500) {
    slippageError = SlippageError.RiskyHigh
  } else {
    slippageError = undefined
  }

  let deadlineError: DeadlineError | undefined
  if (deadlineInput !== '' && !deadlineInputIsValid) {
    deadlineError = DeadlineError.InvalidInput
  } else {
    deadlineError = undefined
  }

  function parseCustomSlippage(value: string) {
    setSlippageInput(value)

    try {
      const valueAsIntFromRoundedFloat = Number.parseInt((Number.parseFloat(value) * 100).toString())
      if (!Number.isNaN(valueAsIntFromRoundedFloat) && valueAsIntFromRoundedFloat < 5000) {
        setRawSlippage(valueAsIntFromRoundedFloat)
      }
      // eslint-disable-next-line no-empty
    } catch {
      console.error('Custom Slippage Error')
    }
  }

  function parseCustomDeadline(value: string) {
    setDeadlineInput(value)

    try {
      const valueAsInt: number = Number.parseInt(value) * 60
      if (!Number.isNaN(valueAsInt) && valueAsInt > 0) {
        setDeadline(valueAsInt)
      }
      // eslint-disable-next-line no-empty
    } catch {
      console.error('Custom Deadline Error')
    }
  }

  return (
    <AutoColumn gap='md'>
      <AutoColumn gap='sm'>
        <RowFixed>
          <TYPE.black fontWeight={400} fontSize={14} color={theme.text2}>
            Slippage tolerance
          </TYPE.black>
          <QuestionHelper text='Your transaction will revert if the price changes unfavorably by more than this percentage.' />
        </RowFixed>
        <RowBetween>
          <Option
            onClick={() => {
              setSlippageInput('')
              setRawSlippage(10)
            }}
            active={rawSlippage === 10}
          >
            0.1%
          </Option>
          <Option
            onClick={() => {
              setSlippageInput('')
              setRawSlippage(50)
            }}
            active={rawSlippage === 50}
          >
            0.5%
          </Option>
          <Option
            onClick={() => {
              setSlippageInput('')
              setRawSlippage(100)
            }}
            active={rawSlippage === 100}
          >
            1%
          </Option>
          <OptionCustom active={![10, 50, 100].includes(rawSlippage)} warning={!slippageInputIsValid} tabIndex={-1}>
            <RowBetween>
              {!!slippageInput &&
              (slippageError === SlippageError.RiskyLow || slippageError === SlippageError.RiskyHigh) ? (
                <SlippageEmojiContainer>
                  <span role='img' aria-label='warning'>
                    ⚠️
                  </span>
                </SlippageEmojiContainer>
              ) : null}
              {/* https://github.com/DefinitelyTyped/DefinitelyTyped/issues/30451 */}
              <Input
                ref={inputRef as any}
                placeholder={(rawSlippage / 100).toFixed(2)}
                value={slippageInput}
                onBlur={() => {
                  parseCustomSlippage((rawSlippage / 100).toFixed(2))
                }}
                onChange={e => parseCustomSlippage(e.target.value)}
                color={!slippageInputIsValid ? 'red' : ''}
              />
              %
            </RowBetween>
          </OptionCustom>
        </RowBetween>
        {!!slippageError && (
          <RowBetween
            style={{
              fontSize: '14px',
              paddingTop: '7px',
              color: slippageError === SlippageError.InvalidInput ? 'red' : '#F3841E',
            }}
          >
            {slippageError === SlippageError.InvalidInput
              ? 'Enter a valid slippage percentage'
              : slippageError === SlippageError.RiskyLow
              ? 'Your transaction may fail'
              : 'Your transaction may be frontrun'}
          </RowBetween>
        )}
      </AutoColumn>

      <AutoColumn gap='sm'>
        <RowFixed>
          <TYPE.black fontSize={14} fontWeight={400} color={theme.text2}>
            Transaction deadline
          </TYPE.black>
          <QuestionHelper text='Your transaction will revert if it is pending for more than this long.' />
        </RowFixed>
        <RowFixed
          style={{
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <DeadlineWrapper>
            <OptionCustom style={{ width: '80px' }} tabIndex={-1}>
              <Input
                color={deadlineError ? 'red' : undefined}
                onBlur={() => {
                  parseCustomDeadline((deadline / 60).toString())
                }}
                placeholder={(deadline / 60).toString()}
                value={deadlineInput}
                onChange={e => parseCustomDeadline(e.target.value)}
              />
            </OptionCustom>
            <TYPE.body style={{ paddingLeft: '8px' }} fontSize={14}>
              minutes
            </TYPE.body>
          </DeadlineWrapper>
        </RowFixed>
        <AutoColumn gap='sm'>
          <RowFixed marginBottom={'-5px'}>
            <TYPE.black fontSize={14} fontWeight={400} color={theme.text2}>
              Interest Rate Selector
            </TYPE.black>
            <QuestionHelper text='Changes between APR and APY across the site' />
          </RowFixed>
          <RowFixed
            style={{
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <AprSelect onClick={() => setShowYieldAsApr(!showYieldAsApr)}>
              <AprSelector active={showYieldAsApr}>APR</AprSelector>
              <AprSelector active={!showYieldAsApr}>APY</AprSelector>
            </AprSelect>
          </RowFixed>
        </AutoColumn>
        <InactiveTokens>
          <TextWrapper>
            <TYPE.black fontWeight={400} fontSize={14} color={theme.text2}>
              Show Inactive Tokens
            </TYPE.black>
          </TextWrapper>
          <Slider isOn={showTokens} onClick={() => setShowTokens(!showTokens)}>
            <SliderDot isOn={showTokens} />
          </Slider>
        </InactiveTokens>
      </AutoColumn>
    </AutoColumn>
  )
}
