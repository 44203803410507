export default function mergeMaps<T>(mergeFn: (a: T, b: T) => T, ...args: Record<string, T>[]): Record<string, T> {
  const result: Record<string, T> = {}
  args.forEach(arg => {
    Object.entries(arg).forEach(([key, newValue]) => {
      const oldValue = result[key]
      if (oldValue) {
        result[key] = mergeFn(oldValue, newValue)
      } else {
        result[key] = newValue
      }
    })
  })
  return result
}
