import React, { MutableRefObject, useContext, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import { Field } from '../../../state/trade/actions'
import { useDerivedTradeInfo } from '../../../state/trade/hooks'
import { useTranslation } from 'react-i18next'
import DataRow from '../../../components/Orders/DataRow'
import Column from '../../../components/Orders/Column'
import OpenInNew from '@material-ui/icons/OpenInNew'
import { collapseTrades, useTradeDataByWallet } from '../../../types/tradeData'
import { useMarginPositionsByWalletAddressData } from '../../../types/marginPositionData'
import { useActiveWeb3React } from '../../../hooks'
import { getEtherscanLink } from '../../../utils'
import { NavLink } from 'react-router-dom'
import { TableLoader } from '../../../components/Loader'
import cleanCurrencySymbol from '../../../utils/cleanCurrencySymbol'
import { PageSizeContext } from '../../App'

const OrdersWrapper = styled.div`
  position: relative;
  width: 100%;
  height: calc(100% - 32px);

  ${({ theme }) => theme.mediaWidth.upToSmall`
    height: fit-content;
  `};
`

const ColumnTitles = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 12px;
  margin-top: 10px;
  padding: 0 25px;
  color: ${({ theme }) => theme.text3};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 20px;
    padding: 0 20px;
  `};
`

const LoaderWrapper = styled.div`
  padding: 0 25px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 0 20px;
  `};
`

const ColumnText = styled.span`
  width: fit-content;
`

const DataRows = styled.div`
  height: 100%;
  width: 100%;
  display: inline-block;
  vertical-align: top;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 0;
  }

  ::-webkit-scrollbar-track {
    background: none;
  }

  ::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 5px;
    border: none;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    height: fit-content;
  `};
`

const FillDetailsWrapper = styled.div`
  width: 100%;
  margin-top: -3px;
  font-size: 12px;
`

const PositionWrapper = styled.div`
  display: inline-block;
  font-weight: 500;
`

const TransactionWrapper = styled.div<{ isPosition: boolean }>`
  display: inline-block;
  float: ${({ isPosition }) => (isPosition ? 'right' : 'left')};

  svg {
    font-size: 13px;
    margin-bottom: -2px;
  }
`

const ExpandedTitle = styled.span`
  font-weight: 500;
  margin-right: 5px;
  color: ${({ theme }) => theme.text3};
`

const EtherscanLinkLarge = styled.span`
  cursor: pointer;

  @media (max-width: 1100px) {
    display: none;
  }
`

const EtherscanLinkSmall = styled.span`
  cursor: pointer;
  display: none;

  @media (max-width: 1100px) {
    display: inline;
  }
`

const HistoryLinkWrapper = styled.div`
  width: 100%;
  padding: 10px 0 20px 0;
  font-size: 14px;
  text-align: center;
  margin-top: 10px;

  > a {
    color: ${({ theme }) => theme.text3};
    text-decoration: none;

    :hover {
      color: ${({ theme }) => theme.text2};
    }
  }
`

const openInNewTab = (url: string) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}

const condenseHash = (hash: string) => {
  return `${hash.substring(0, 6)}...${hash.substring(hash.length - 4)}`
}

const useOutsideAlerter = (ref: any, setExpandedIndex: (arg0: number) => void) => {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref?.current && !ref.current.contains(event.target)) {
        setExpandedIndex(-1)
      }
    }

    document.addEventListener('mouseup', handleClickOutside)
    return () => {
      document.removeEventListener('mouseup', handleClickOutside)
    }
  }, [ref, setExpandedIndex])
}

const widths = {
  widths: [15, 10, 10, 10],
  starts: [0, 43, 67, 90],
  tabletWidths: [20, 12, 12],
  tabletStarts: [0, 68, 88],
  mobileWidths: [22, 20],
  mobileStarts: [0, 80],
}

export default function Fills() {
  const { t } = useTranslation()
  const { account, chainId } = useActiveWeb3React()

  const [expandedIndex, setExpandedIndex] = useState(-1)
  const [expandedRef, setExpandedRef] = useState<null | MutableRefObject<any>>(null)
  const [isPriceToggled, setIsPriceToggled] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState(true)
  const { data: positions } = useMarginPositionsByWalletAddressData(account)
  const positionHashes = useMemo(() => positions.map(position => position.openTransaction?.transactionHash), [
    positions,
  ])

  useOutsideAlerter(expandedRef, setExpandedIndex)

  const {
    currencies: { [Field.INPUT]: inputCurrency, [Field.OUTPUT]: outputCurrency },
  } = useDerivedTradeInfo()

  const { data: unfilteredTrades, loading: isTradeDataLoading } = useTradeDataByWallet(account)
  useEffect(() => {
    isLoading && !isTradeDataLoading && setIsLoading(false)
  }, [isTradeDataLoading, isLoading])
  //TODO - add a spinner in the upper right that displays whenever new content is loading in
  const trades = useMemo(() => {
    const collapsedTrades = collapseTrades(unfilteredTrades, chainId, account)
    return collapsedTrades.filter(trade => {
      const inputAddress = inputCurrency?.wrapped.address
      const outputAddress = outputCurrency?.wrapped.address

      return (
        (trade.primary.address === inputAddress && trade.secondary.address === outputAddress) ||
        (trade.primary.address === outputAddress && trade.secondary.address === inputAddress)
      )
    })
  }, [unfilteredTrades, inputCurrency, outputCurrency, chainId, account])

  const { x, y } = useContext(PageSizeContext)
  const isMobile = x <= 1040
  const isTablet = 825 < x && x <= 1040

  //TODO - import most components from a common one the in OrderTypes directory so all of the pages can be changed at one time

  //TODO - pagination, or perhaps a cutoff for how many it shows and if you want to see older ones you need to look at a different page or the block explorer
  //TODO - speaking of which, perhaps we'll need a transaction history page in the future like the one dYdX has?
  return (
    <OrdersWrapper>
      <ColumnTitles>
        <Column width={30} tabletWidth={40} mobileWidth={70} customMobileWidth={825} textAlign={'left'}>
          Trade
        </Column>
        <Column
          width={30}
          textAlign={'right'}
          customMobileWidth={825}
          customTabletWidth={1040}
          hideOnDesktop={true}
          hideOnMobile={true}
        >
          <ColumnText style={{ cursor: 'pointer' }} onClick={() => setIsPriceToggled(!isPriceToggled)}>
            Price (per {isPriceToggled ? cleanCurrencySymbol(inputCurrency) : cleanCurrencySymbol(outputCurrency)})
          </ColumnText>
        </Column>
        <Column width={20} textAlign={'right'} customTabletWidth={1040} hideOnTablet={true} hideOnMobile={true}>
          Price (per {cleanCurrencySymbol(inputCurrency)})
        </Column>
        <Column width={20} textAlign={'right'} customTabletWidth={1040} hideOnTablet={true} hideOnMobile={true}>
          Price (per {cleanCurrencySymbol(outputCurrency)})
        </Column>
        <Column width={20} textAlign={'right'}>
          Date
        </Column>
      </ColumnTitles>
      <DataRows>
        {isLoading && account ? (
          <LoaderWrapper>
            <TableLoader
              rows={3}
              height={22}
              spacing={44}
              marginTop={10}
              isMobile={isMobile}
              isTablet={isTablet}
              widths={widths}
            />
          </LoaderWrapper>
        ) : (
          trades.slice(0, 10).map((row, index) => (
            <DataRow
              key={index}
              columns={[
                {
                  width: 30,
                  tabletWidth: 40,
                  mobileWidth: 70,
                  textAlign: 'left',
                  smallTextAlign: 'center',
                  type: 'trade',
                  data: row.takerDeltaWei,
                  token: row.takerDeltaWei.currency,
                  data2: row.makerDeltaWei,
                  token2: row.makerDeltaWei.currency,
                  customMobileWidth: 825,
                },
                {
                  width: 30,
                  textAlign: 'right',
                  type: 'amountToggleable',
                  data:
                    inputCurrency?.equals(row.primary) && !isPriceToggled
                      ? row.primaryPriceWei
                      : row.primaryPriceWei.invert(),
                  token: inputCurrency?.equals(row.primary) && !isPriceToggled ? row.primary : row.secondary,
                  data2:
                    inputCurrency?.equals(row.secondary) && !isPriceToggled
                      ? row.primaryPriceWei
                      : row.primaryPriceWei.invert(),
                  token2: inputCurrency?.equals(row.secondary) && !isPriceToggled ? row.primary : row.secondary,
                  date: undefined,
                  customMobileWidth: 825,
                  customTabletWidth: 1040,
                  hideOnDesktop: true,
                  hideOnMobile: true,
                },
                {
                  width: 20,
                  textAlign: 'right',
                  type: 'amount',
                  data: inputCurrency?.equals(row.primary) ? row.primaryPriceWei : row.primaryPriceWei.invert(),
                  token: row.secondary,
                  date: undefined,
                  customTabletWidth: 1040,
                  hideOnTablet: true,
                  hideOnMobile: true,
                },
                {
                  width: 20,
                  textAlign: 'right',
                  type: 'amount',
                  data: inputCurrency?.equals(row.secondary) ? row.primaryPriceWei : row.primaryPriceWei.invert(),
                  token: row.primary,
                  date: undefined,
                  customTabletWidth: 1040,
                  hideOnTablet: true,
                  hideOnMobile: true,
                },
                {
                  width: 20,
                  mobileWidth: 30,
                  textAlign: 'right',
                  type: 'time',
                  date: row.transaction.timestamp,
                  data: undefined,
                },
              ]}
              contentHeight={24}
              expandedHeight={46}
              expandedContent={
                <FillDetailsWrapper>
                  {positionHashes.includes(row.transaction.transactionHash) ? (
                    <PositionWrapper>Opened a Margin Position</PositionWrapper>
                  ) : null}
                  <TransactionWrapper isPosition={positionHashes.includes(row.transaction.transactionHash)}>
                    <ExpandedTitle>{t('transactionHash')}</ExpandedTitle>
                    <EtherscanLinkLarge
                      onClick={() =>
                        openInNewTab(getEtherscanLink(chainId, row.transaction.transactionHash, 'transaction'))
                      }
                    >
                      {row.transaction.transactionHash}
                    </EtherscanLinkLarge>{' '}
                    <EtherscanLinkSmall
                      onClick={() =>
                        openInNewTab(getEtherscanLink(chainId, row.transaction.transactionHash, 'transaction'))
                      }
                    >
                      {condenseHash(row.transaction.transactionHash)}
                    </EtherscanLinkSmall>{' '}
                    <OpenInNew
                      onClick={() =>
                        openInNewTab(getEtherscanLink(chainId, row.transaction.transactionHash, 'transaction'))
                      }
                    />
                  </TransactionWrapper>
                </FillDetailsWrapper>
              }
              expanded={expandedIndex === index}
              toggleExpanded={(ref: MutableRefObject<any>) => {
                if (expandedIndex === index) {
                  //setExpandedIndex(-1)
                } else {
                  setExpandedRef(ref)
                  setExpandedIndex(index)
                }
              }}
            />
          ))
        )}
        <HistoryLinkWrapper>
          <NavLink id={`swap-nav-link`} to={'/history'}>
            View All Trades
          </NavLink>
        </HistoryLinkWrapper>
      </DataRows>
    </OrdersWrapper>
  )
}
