import { Token } from '@dolomite-exchange/v2-sdk'
import { Market } from '../types/Market'
import { useMemo } from 'react'

function decipherWithOrdering(token0: Token, token1: Token): string | undefined {
  if (token0.symbol?.includes('USDC')) {
    return `${token1.symbol}-${token0.symbol}`
  } else if (token1.symbol?.includes('USDC')) {
    return `${token0.symbol}-${token1.symbol}`
  }

  if (token0.symbol?.includes('USDC.e')) {
    return `${token1.symbol}-${token0.symbol}`
  } else if (token1.symbol?.includes('USDC.e')) {
    return `${token0.symbol}-${token1.symbol}`
  }

  if (token0.symbol?.includes('USDT')) {
    return `${token1.symbol}-${token0.symbol}`
  } else if (token1.symbol?.includes('USDT')) {
    return `${token0.symbol}-${token1.symbol}`
  }

  if (token0.symbol?.includes('DAI')) {
    return `${token1.symbol}-${token0.symbol}`
  } else if (token1.symbol?.includes('DAI')) {
    return `${token0.symbol}-${token1.symbol}`
  }

  if (token0.symbol?.includes('BTC')) {
    return `${token1.symbol}-${token0.symbol}`
  } else if (token1.symbol?.includes('BTC')) {
    return `${token0.symbol}-${token1.symbol}`
  }

  if (token0.symbol?.includes('ETH')) {
    return `${token1.symbol}-${token0.symbol}`
  } else if (token1.symbol?.includes('ETH')) {
    return `${token0.symbol}-${token1.symbol}`
  }

  return undefined
}

export function deriveMarketFromTokens(token0?: Token, token1?: Token): Market | undefined {
  if (!token0 || !token1) {
    return undefined
  }

  let market = decipherWithOrdering(token0, token1)
  if (!market) {
    market = decipherWithOrdering(token1, token0)
  }
  if (!market) {
    market = `${token0.symbol}-${token1.symbol}`
  }

  return new Market(market, token0, token1)
}

export function deriveMarketFromTokensReq(token0: Token, token1: Token): Market {
  let market = decipherWithOrdering(token0, token1)
  if (!market) {
    market = decipherWithOrdering(token1, token0)
  }
  if (!market) {
    market = `${token0.symbol}-${token1.symbol}`
  }

  return new Market(market, token0, token1)
}

export function useDeriveMarketFromTokens(token0: Token, token1: Token): Market {
  return useMemo(() => deriveMarketFromTokensReq(token0, token1), [token0, token1])
}
