import { deriveMarketFromTokens } from './marketUtils'
import { CurrencyAmount, Price, Token } from '@dolomite-exchange/v2-sdk'
import { ZapOutputParam } from '../hooks/useGetZapParams'

export function calculatePriceForZap(
  zap: ZapOutputParam,
  tokenMap: Record<string, Token | undefined>,
): Price<Token, Token> | undefined {
  const inputAmount = zap.amountWeisPath[0]
  if (inputAmount.eq(0)) {
    return undefined
  }

  const inputToken = tokenMap[zap.tokensPath[0].address]
  const outputToken = tokenMap[zap.tokensPath[zap.tokensPath.length - 1].address]
  if (!inputToken || !outputToken) {
    return undefined
  }

  const market = deriveMarketFromTokens(inputToken, outputToken)
  if (market?.primaryToken.equals(inputToken)) {
    return new Price({
      baseAmount: CurrencyAmount.fromRawAmount(inputToken, inputAmount.toFixed(0)),
      quoteAmount: CurrencyAmount.fromRawAmount(outputToken, zap.expectedAmountOut.toFixed(0)),
    })
  } else {
    return new Price({
      baseAmount: CurrencyAmount.fromRawAmount(outputToken, zap.expectedAmountOut.toFixed(0)),
      quoteAmount: CurrencyAmount.fromRawAmount(inputToken, inputAmount.toFixed(0)),
    })
  }
}
