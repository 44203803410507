import { Token } from '@dolomite-exchange/sdk-core'
import { ChainId, ChainIdMap, initializeSingleValueChainIdMap } from '../chainId'

const decimals = 18
const symbol = 'WMNT'
const name = 'Wrapped Mantle'

function wmnt(chainId: ChainId, address: string) {
  return new Token(chainId, address, decimals, symbol, name)
}

export const WMNT: ChainIdMap<Token | undefined> = initializeSingleValueChainIdMap(
  ChainId.MANTLE,
  wmnt(ChainId.MANTLE, '0x78c1b0C915c4FAA5FffA6CAbf0219DA63d7f4cb8'),
)
