import { Token } from '@dolomite-exchange/sdk-core'
import { ChainId, ChainIdMap } from '../chainId'

const decimals = 6
const symbol = 'USDT'
const name = 'Tether USD'

function usdt(chainId: ChainId, address: string) {
  return new Token(chainId, address, decimals, symbol, name)
}

export const USDT: ChainIdMap<Token | undefined> = {
  [ChainId.MAINNET]: new Token(ChainId.MAINNET, '0xdAC17F958D2ee523a2206206994597C13D831ec7', decimals, symbol, name),
  [ChainId.ARBITRUM_ONE]: usdt(ChainId.ARBITRUM_ONE, '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9'),
  [ChainId.BASE]: undefined,
  [ChainId.BERACHAIN]: undefined,
  [ChainId.MANTLE]: usdt(ChainId.MANTLE, '0x201EBa5CC46D216Ce6DC03F6a759e8E766e956aE'),
  [ChainId.POLYGON_ZKEVM]: usdt(ChainId.POLYGON_ZKEVM, '0x1E4a5963aBFD975d8c9021ce480b42188849D41d'),
  [ChainId.X_LAYER]: usdt(ChainId.X_LAYER, '0x1E4a5963aBFD975d8c9021ce480b42188849D41d'),
}
