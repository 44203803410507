import React, { useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { Token } from '@dolomite-exchange/v2-sdk'
import Tooltip from '@material-ui/core/Tooltip'
import { useAllActiveTokensArray } from '../../hooks/Tokens'
import { useDefaultFiatValuesWithLoadingIndicator } from '../../hooks/useFiatValue'
import { useInterestRateData } from '../../types/interestRateData'
import { ZERO_FRACTION } from '../../constants'
import { useDolomiteMarginTokenTvlData } from '../../types/dolomiteMarginTokenTvlData'
import useDebounce from '../../hooks/useDebounce'
import { useMarketRiskInfoData } from '../../types/marketRiskInfoData'
import { useDolomiteMarginData } from '../../types/dolomiteMarginData'
import InterestRateChangedProps from './InterestRateChangedProps'
import tokenEqualsOpt from '../../utils/tokenEqualsOpt'
import { percentEqualsOpt } from '../../utils/fractionEqualsOpt'
import BorrowRateRow from './BorrowRateRow'
import { useShowYieldAsApr } from '../../state/user/hooks'

const BorrowRatesWrapper = styled.div`
  background-color: #292938;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  display: inline-block;
  margin-bottom: 15px;
  padding: 20px 35px 25px;
  vertical-align: top;
  width: 100%;
  font-family: 'Open Sans', sans-serif;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
  text-align: left;

  h3 {
    margin: 0 !important;
  }
`

const HeaderWrapper = styled.div`
  margin-bottom: 8px;
`

const HeaderInner = styled.div`
  margin-top: 5px;
  text-align: left;
  display: inline-block;
  width: 100%;
  font-size: 0.75rem;
`

const RateTextWrapper = styled.div`
  width: 48%;
  margin-left: 53%;
  position: relative;
  height: 14px;
`

const RateText = styled.div`
  color: ${({ theme }) => theme.text3};
  background: ${({ theme }) => theme.bg1};
  padding: 1px 5px;
  font-size: 11px;
  position: absolute;
  right: calc(50% - 15px);
  top: 0;
  z-index: 1;
`

const LineGraphic = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.text3};
  position: absolute;
  right: 0;
  top: 8px;
  z-index: 0;
`

const StyledTooltip = styled(props => (
  <Tooltip classes={{ popper: props.className }} {...props}>
    {props.children}
  </Tooltip>
))`
  & .MuiTooltip-tooltip {
    font-family: 'Open Sans', sans-serif !important;
    font-size: 11px !important;
    background-color: #3d3e54 !important;
  }
`

const Column = styled.div`
  color: #d5d6e1;
  display: inline-block;
  font-weight: 100;
  vertical-align: top;
`

const TickerColumn = styled(Column)`
  text-align: left;
  width: 45%;
`

const TickerColumnTitle = styled(TickerColumn)`
  font-size: 14px;
  font-weight: 200;
`

const RateColumn = styled(Column)`
  text-align: right;
  width: 32.5%;
`

const SupplyColumn = styled(RateColumn)`
  width: 25%;
  font-size: 14px;
  line-height: 23px;
`

const BorrowColumn = styled(RateColumn)`
  width: 30%;
  font-size: 14px;
  line-height: 23px;
`

const SupplyColumnTitle = styled(SupplyColumn)`
  font-size: 14px;
  font-weight: 200;
  text-transform: capitalize;
  line-height: normal;
`

const BorrowColumnTitle = styled(BorrowColumn)`
  font-size: 14px;
  font-weight: 200;
  text-transform: capitalize;
  line-height: normal;
`

const LoadingBalancesText = styled.div`
  color: ${({ theme }) => theme.text3};
  font-size: 15px;
  margin-bottom: 18px;
`

function BorrowRatesComparator(prevProps: BorrowRatesProps, nextProps: BorrowRatesProps) {
  return (
    tokenEqualsOpt(prevProps.newBorrowRate?.token, nextProps.newBorrowRate?.token) &&
    percentEqualsOpt(prevProps.newBorrowRate?.rate, nextProps.newBorrowRate?.rate) &&
    tokenEqualsOpt(prevProps.newSupplyRate?.token, nextProps.newSupplyRate?.token) &&
    percentEqualsOpt(prevProps.newSupplyRate?.rate, nextProps.newSupplyRate?.rate)
  )
}

interface BorrowRatesProps {
  newBorrowRate: InterestRateChangedProps | undefined
  newSupplyRate: InterestRateChangedProps | undefined
}

function BorrowRates({ newBorrowRate, newSupplyRate }: BorrowRatesProps) {
  const { t } = useTranslation()
  const tokens = useAllActiveTokensArray()
  const [showYieldAsApr] = useShowYieldAsApr()
  const { data: interestRateMap } = useInterestRateData()
  const [oraclePriceMap] = useDefaultFiatValuesWithLoadingIndicator(tokens)
  const [selectedToken, setSelectedToken] = useState<Token | undefined>(undefined)
  const { data: dolomiteMargin } = useDolomiteMarginData()
  const { data: tokenTvlMap } = useDolomiteMarginTokenTvlData()
  const { data: marketRiskInfoMap } = useMarketRiskInfoData()
  const lastUpdateRef = useRef(Date.now())
  const sortedTokensRef = useRef<Token[]>([])
  const sortedTokens = useMemo(() => {
    const now = Date.now()
    if (now - lastUpdateRef.current < 5000) {
      return sortedTokensRef.current // Return the previous result if within 5 seconds
    }
    lastUpdateRef.current = now

    const sorted = Array.of(...tokens).sort((token1, token2) => {
      const token1OraclePrice = oraclePriceMap[token1.address] ?? ZERO_FRACTION
      const token1SupplyTvl = tokenTvlMap[token1.address]?.supplyLiquidity.asFraction ?? ZERO_FRACTION
      const token1SupplyTvlUSD = token1SupplyTvl.multiply(token1OraclePrice)
      const token2OraclePrice = oraclePriceMap[token2.address] ?? ZERO_FRACTION
      const token2SupplyTvl = tokenTvlMap[token2.address]?.supplyLiquidity.asFraction ?? ZERO_FRACTION
      const token2SupplyTvlUSD = token2SupplyTvl.multiply(token2OraclePrice)
      return token1SupplyTvlUSD.greaterThanOrEqual(token2SupplyTvlUSD) ? -1 : 1
    })

    sortedTokensRef.current = sorted // Cache the sorted result
    return sorted
  }, [tokens, oraclePriceMap, tokenTvlMap])
  const rawIsLoading =
    !dolomiteMargin ||
    Object.keys(oraclePriceMap).length === 0 ||
    Object.keys(interestRateMap).length === 0 ||
    Object.keys(tokenTvlMap).length === 0 ||
    Object.keys(marketRiskInfoMap).length === 0 ||
    sortedTokens.length === 0
  const isLoading = useDebounce(rawIsLoading, 100)

  // TODO - make this a standard component to display balances so it can be reused on the pool page and the balances page
  return (
    <BorrowRatesWrapper>
      <HeaderWrapper>
        <HeaderInner>
          <RateTextWrapper>
            <RateText>{showYieldAsApr ? t('APR') : t('APY')}</RateText>
            <LineGraphic />
          </RateTextWrapper>
          <TickerColumnTitle>Asset</TickerColumnTitle>
          <StyledTooltip title={t('supplyRatesTooltip')} placement='right'>
            <SupplyColumnTitle>{t('supply')}</SupplyColumnTitle>
          </StyledTooltip>
          <StyledTooltip title={t('borrowRatesTooltip')} placement='right'>
            <BorrowColumnTitle>{t('borrow')}</BorrowColumnTitle>
          </StyledTooltip>
        </HeaderInner>
      </HeaderWrapper>
      {isLoading ? (
        <LoadingBalancesText>{t('loadingBorrowRates')}</LoadingBalancesText>
      ) : (
        <div>
          {sortedTokens.map((token: Token) => (
            <BorrowRateRow
              key={`${token.chainId}-${token.address}`}
              token={token}
              selectedToken={selectedToken}
              defaultMinCollateralization={dolomiteMargin?.minCollateralization}
              defaultLiquidationReward={dolomiteMargin?.liquidationReward}
              interestRate={interestRateMap[token.address]}
              oraclePrice={oraclePriceMap[token.address]}
              tvl={tokenTvlMap[token.address]}
              marketRiskInfo={marketRiskInfoMap[token.address]}
              newBorrowRate={newBorrowRate}
              newSupplyRate={newSupplyRate}
              setSelectedToken={setSelectedToken}
              showYieldAsApr={showYieldAsApr}
            />
          ))}
        </div>
      )}
    </BorrowRatesWrapper>
  )
}

export default React.memo(BorrowRates, BorrowRatesComparator)
