import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import NumericalInput from '../../../NumericalInput'
import { StyledTooltip } from '../../../common/StyledTooltip'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useActiveWeb3React } from '../../../../hooks'
import useIsolationModeUserVaultAddressIfCreated from '../../../../hooks/useIsolationModeUserVaultAddressIfCreated'
import { useCreateVaultIsolationModeVault } from '../../../../hooks/useDolomiteIsolationModeProtoocol'
import { useDArbToken, useWrapOrUnwrapVoteEnabledArb } from '../../../../hooks/arb/useArbDolomiteProxyProtocol'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { formatAmount } from '../../../../utils/formatAmount'
import { ARB, ZERO_FRACTION } from '../../../../constants'
import { tryParseAmount } from '../../../../state/trade/hooks'
import { useAllActiveTokensArray, useArbToken } from '../../../../hooks/Tokens'
import { useDolomiteBalancesWithLoadingIndicator } from '../../../../state/wallet/hooks'
import { useIsTransactionPending } from '../../../../state/transactions/hooks'
import cleanCurrencyName from '../../../../utils/cleanCurrencyName'

export const DelegateWrapper = styled.div`
  padding: 7px 35px 30px;
  width: 100%;
  overflow: auto;
`

export const DelegateBody = styled.div`
  font-size: 13px;
  font-weight: 500;
`

const ConvertInfo = styled.div`
  width: 100%;
  margin-top: -10px;
  margin-bottom: 20px;
`

const StyledTabs = styled(({ ...rest }) => <Tabs classes={{ indicator: 'indicator' }} {...rest} />)`
  font-family: 'Open Sans', sans-serif !important;
  justify-content: normal !important;
  min-height: 0 !important;
  margin-top: 10px;
  margin-bottom: 15px;

  .indicator {
    background-color: #f9f9f9 !important;
    bottom: 0 !important;
    display: block !important;
    height: 1.4px !important;
    transform: scale(0.5, 1) !important;
  }

  > div > div {
    justify-content: space-evenly;
  }
`

const StyledTab = styled(({ ...rest }) => (
  <Tab
    classes={{
      root: 'root',
      selected: 'selected',
    }}
    {...rest}
  />
))`
  font-family: 'Open Sans', sans-serif !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  margin-left: 0 !important;
  margin-right: 13px !important;
  text-transform: none !important;
  padding: 0 !important;
  padding-bottom: 3.5px !important;
  padding-left: 4px !important;
  padding-right: 4px !important;
  max-width: 264px;
  min-width: 0 !important;
  color: #606375 !important;
  min-height: 0 !important;

  ${({ selected }) =>
    selected &&
    `
    color: #f9f9f9 !important;
  `}
  .selected {
    color: #f9f9f9 !important;
  }

  .root span {
    font-size: 18px !important;
  }
`

const BalanceRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const BalanceText = styled.div`
  font-size: 14px;
  font-weight: 500;
`

const BalanceAmount = styled.div`
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
`

const InputWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  font-size: 15px;
  padding: 0.5rem 0.75rem;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.bg6};
`

const ConvertSummary = styled.div`
  width: 100%;
  font-size: 12px;
  text-align: center;
  margin-top: 10px;
`

const SubmitButton = styled.div<{ disabled?: boolean; blue?: boolean }>`
    width: 100%;
    padding: 12px 16px;
    border-radius: 5px;
    background-color: ${({ theme, blue }) => (blue ? theme.blue2 : theme.green2)};
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
    font-size: 14px;
    font-weight: 500;
    color: ${({ theme }) => theme.text1};
    opacity: ${({ disabled }) => (disabled ? 0.7 : 1)};
    text-align: center;
        /*${({ disabled }) => disabled && 'pointer-events: none;'}*/
    margin-top: 12px;

    :hover {
        background-color: ${({ theme, disabled, blue }) =>
          blue ? !disabled && theme.blue2 : !disabled && theme.green1};
    }

    > div {
        width: 20px !important;
        height: 20px !important;
        font-size: 12px;
        margin-top: -1px;
        margin-bottom: -5px;
    }

    svg {
        color: ${({ theme }) => theme.text1};
    }
`

enum SelectedAction {
  ARB_TO_VARB = 0,
}

const options = ['ARB to vARB', 'vARB to ARB']

export default function ConvertArb({ onDismiss }: { onDismiss: () => void }) {
  const { account } = useActiveWeb3React()
  const [inputValue, setInputValue] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const [awaitingSignature, setAwaitingSignature] = useState(false)
  const [pendingTx, setPendingTx] = useState<string | undefined>(undefined)
  const [selectedActionType, setSelectedActionType] = useState<SelectedAction>(SelectedAction.ARB_TO_VARB)
  const isTransactionPending = useIsTransactionPending(pendingTx)
  useEffect(() => {
    if (!isTransactionPending) {
      setPendingTx(undefined)
    }
    setSubmitting(isTransactionPending)
  }, [isTransactionPending])

  const dArb = useDArbToken()
  const arb = useArbToken()
  const tokenList = useAllActiveTokensArray()
  const [dolomiteBalanceMap] = useDolomiteBalancesWithLoadingIndicator(account, tokenList)
  const arbBalance = dolomiteBalanceMap[arb?.address ?? '']
  const dArbBalance = dolomiteBalanceMap[dArb?.address ?? '']
  const vaultAddress = useIsolationModeUserVaultAddressIfCreated(dArb)

  const parsedInputValue = useMemo(() => {
    const parsedArbValue = tryParseAmount(inputValue, arb)
    const parsedVArbValue = tryParseAmount(inputValue, dArb)
    return selectedActionType === SelectedAction.ARB_TO_VARB ? parsedArbValue : parsedVArbValue
  }, [arb, dArb, inputValue, selectedActionType])

  const isButtonDisabled =
    !parsedInputValue ||
    parsedInputValue.equalTo(ZERO_FRACTION) ||
    submitting ||
    awaitingSignature ||
    parsedInputValue.greaterThan(
      selectedActionType === SelectedAction.ARB_TO_VARB ? arbBalance ?? ZERO_FRACTION : dArbBalance ?? ZERO_FRACTION,
    )

  const updateSelectedActionType = useCallback((newSelectedActionType: SelectedAction) => {
    setSelectedActionType(oldSelectedActionType => {
      if (oldSelectedActionType !== newSelectedActionType) {
        setInputValue('')
      }
      return newSelectedActionType
    })
  }, [])

  const updateInput = useCallback((inputString: string) => {
    const re = /^\d*(\.\d*)?$/ //Only allow numbers and a single decimal point

    if (inputString === '' || re.test(inputString)) {
      setInputValue(inputString)
    }
  }, [])

  const { callback: wrapOrUnwrapCallback } = useWrapOrUnwrapVoteEnabledArb(
    parsedInputValue,
    selectedActionType === SelectedAction.ARB_TO_VARB ? arbBalance : dArbBalance,
    selectedActionType === SelectedAction.ARB_TO_VARB,
  )
  const { callback: createVaultCallback } = useCreateVaultIsolationModeVault(dArb)

  const setMax = useCallback(() => {
    if (selectedActionType === SelectedAction.ARB_TO_VARB) {
      setInputValue(formatAmount(arbBalance, arb?.decimals, false))
    } else {
      setInputValue(formatAmount(dArbBalance, dArb?.decimals, false))
    }
  }, [arb, arbBalance, dArb, dArbBalance, selectedActionType])

  const submitTransaction = useCallback(() => {
    if (!createVaultCallback) {
      // GUARD statement
      return
    }

    if (!vaultAddress) {
      setSubmitting(true)
      setAwaitingSignature(true)
      createVaultCallback()
        .then(hash => {
          setPendingTx(hash)
          setAwaitingSignature(false)
        })
        .catch(() => {
          setSubmitting(false)
          setAwaitingSignature(false)
        })
      return
    }

    if (!wrapOrUnwrapCallback) {
      // GUARD statement
      return
    }

    setSubmitting(true)
    setAwaitingSignature(true)

    wrapOrUnwrapCallback()
      .then(hash => {
        setAwaitingSignature(false)
        setPendingTx(hash)
        onDismiss()
      })
      .catch(e => {
        console.log(e.message)
        setAwaitingSignature(false)
        setSubmitting(false)
      })
  }, [onDismiss, createVaultCallback, vaultAddress, wrapOrUnwrapCallback])

  return (
    <DelegateWrapper>
      <h2>Convert {cleanCurrencyName(dArb)}</h2>
      <DelegateBody>
        <ConvertInfo>
          <p>
            You can freely convert between ARB and vARB using this panel. ARB/vARB need to be in your Dolomite Balance
            to convert. You can also convert to vARB by zapping assets from within a borrow position.
          </p>
          <p>
            ARB is a lendable and borrowable asset, so you will earn lending interest on any ARB you hold, and you can
            borrow ARB on the Borrow page.
          </p>
          <p>
            vARB is {cleanCurrencyName(dArb)}, which lets you delegate your balance of vARB to be used to vote in
            Arbitrum DAO votes. vARB is not borrowable, however it can still act as collateral for borrowing, even if it
            is delegated.
          </p>
          <p>
            Your total delegated balance is the sum of your Dolomite Balance and any vARB being used as collateral
            across Dolomite.
          </p>
        </ConvertInfo>
        <StyledTabs
          value={selectedActionType}
          onChange={(_: any, index: number) => updateSelectedActionType(index)}
          indicatorColor={'primary'}
          textColor={'primary'}
        >
          {(options ?? []).map((option: string, index: number) => (
            <StyledTab key={`tradeHeader-${index}`} disableRipple label={option} />
          ))}
        </StyledTabs>
        <BalanceRow>
          <BalanceText>Balance:</BalanceText>
          <BalanceAmount onClick={setMax}>
            {formatAmount(selectedActionType === SelectedAction.ARB_TO_VARB ? arbBalance : dArbBalance, 4, true)}{' '}
            {selectedActionType === SelectedAction.ARB_TO_VARB ? 'ARB' : 'vARB'}
          </BalanceAmount>
        </BalanceRow>
        <InputWrapper>
          <NumericalInput
            className={'token-amount-input'}
            value={inputValue}
            onUserInput={val => {
              updateInput(val)
            }}
            fontSize={'inherit'}
            maxDecimals={18}
            unit={selectedActionType === SelectedAction.ARB_TO_VARB ? 'ARB' : 'vARB'}
            placeholder={'0.00'}
          />
        </InputWrapper>
        <ConvertSummary>
          Will convert {parsedInputValue ? `${formatAmount(parsedInputValue, 4, true)} ` : ''}
          {selectedActionType === SelectedAction.ARB_TO_VARB ? 'ARB' : 'vARB'} to{' '}
          {parsedInputValue ? `${formatAmount(parsedInputValue, 4, true)} ` : ''}
          {selectedActionType === SelectedAction.ARB_TO_VARB ? 'vARB' : 'ARB'}
        </ConvertSummary>
        <StyledTooltip
          title={
            submitting || isTransactionPending
              ? awaitingSignature
                ? 'Awaiting signature...'
                : 'Submitting...'
              : isButtonDisabled
              ? 'Invalid input amount'
              : ''
          }
          showTooltip={submitting}
          position={'top'}
        >
          <SubmitButton
            disabled={isButtonDisabled}
            blue={!vaultAddress}
            onClick={() => !isButtonDisabled && submitTransaction()}
          >
            {submitting ? (
              <CircularProgress />
            ) : !vaultAddress ? (
              'Create Dolomite Vault'
            ) : selectedActionType === SelectedAction.ARB_TO_VARB ? (
              'Convert ARB to vARB'
            ) : (
              'Convert vARB to ARB'
            )}
          </SubmitButton>
        </StyledTooltip>
      </DelegateBody>
    </DelegateWrapper>
  )
}
