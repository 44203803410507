import { SpecialAsset, SpecialAssetSymbol } from '../special-assets'
import { ARB } from '../../tokens/ARB'
import mapChainIdMapValues from '../../../utils/mapChainIdMapValues'

export const ARB_SPECIAL_ASSET: () => SpecialAsset = () => ({
  symbol: SpecialAssetSymbol.ARB,
  safeSymbol: SpecialAssetSymbol.ARB,
  cleanSymbol: 'ARB',
  cleanName: 'Arbitrum',
  hasExternalRewards: false,
  documentationUrl: undefined,
  isIsolationMode: false,
  isBorrowingEnabled: true,
  chainIdToAddressMap: mapChainIdMapValues(ARB, t => t?.address),
  isolationModeInfo: undefined,
})
