import { Percent } from '@dolomite-exchange/v2-sdk'
import React from 'react'
import { ONE_BIPS, ZERO_FRACTION } from '../../constants'
import { warningSeverity } from '../../utils/prices'
import { ErrorText } from './styleds'
import { StyledTooltip } from '../common/StyledTooltip'
import WarningAmberIcon from '@material-ui/icons/Warning'

/**
 * Formatted version of price impact text with warning colors
 */
export default function FormattedPriceImpact({
  priceImpact,
  adjustedPriceImpact,
}: {
  priceImpact?: Percent
  adjustedPriceImpact?: Percent | undefined
}) {
  return (
    <ErrorText fontWeight={500} fontSize={12} severity={warningSeverity(adjustedPriceImpact ?? priceImpact)}>
      {priceImpact
        ? priceImpact.lessThan(ONE_BIPS) && priceImpact.greaterThan(ZERO_FRACTION)
          ? '< 0.01%'
          : `${priceImpact.toFixed(2).replace('-', '+')}%`
        : '-'}
      {!!adjustedPriceImpact && warningSeverity(adjustedPriceImpact) > 1 && (
        <StyledTooltip
          title={`Leverage will result in this price impact having a greater effect on your position's initial value`}
          placement={'top'}
        >
          <WarningAmberIcon />
        </StyledTooltip>
      )}
    </ErrorText>
  )
}
