import { BIG_INT_ONE, BIG_INT_TWO, BIG_INT_ZERO, ZERO_FRACTION } from '../constants'
import { InterestIndex } from '../data/InterestIndex'
import { CurrencyAmount, Token } from '@dolomite-exchange/v2-sdk'
import JSBI from 'jsbi'

export default function parToWei(
  par: CurrencyAmount<Token>,
  index: InterestIndex,
  isPositive: boolean,
): CurrencyAmount<Token> {
  const indexFraction = !isPositive || par.lessThan(ZERO_FRACTION) ? index.borrowIndex : index.supplyIndex
  const weiNumerator = JSBI.multiply(par.numerator, indexFraction.numerator)
  // formula for rounding half up is: `target.mul(numerator).add(denominator.div(2)).div(denominator)`
  const denominatorDivTwo = JSBI.divide(indexFraction.denominator, BIG_INT_TWO)
  return CurrencyAmount.fromFractionalAmount(
    par.currency,
    JSBI.add(
      JSBI.divide(JSBI.add(weiNumerator, denominatorDivTwo), indexFraction.denominator),
      isPositive ? BIG_INT_ZERO : BIG_INT_ONE,
    ),
    par.denominator,
  )
}
