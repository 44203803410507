import { Token } from '@dolomite-exchange/sdk-core'
import { ChainId, ChainIdMap, initializeSingleValueChainIdMap } from '../chainId'

const decimals = 18
const symbol = 'ARB'
const name = 'Arbitrum'

export const ARB: ChainIdMap<Token | undefined> = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  getToken(ChainId.ARBITRUM_ONE, '0x912CE59144191C1204E64559FE8253a0e49E6548'),
)

function getToken(chainId: ChainId, address: string): Token {
  return new Token(chainId, address, decimals, symbol, name)
}
