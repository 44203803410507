import { SpecialAsset, SpecialAssetSymbol } from '../special-assets'
import { WETH } from '../../tokens/WETH'
import mapChainIdMapValues from '../../../utils/mapChainIdMapValues'

export const WETH_SPECIAL_ASSET: () => SpecialAsset = () => ({
  symbol: SpecialAssetSymbol.WETH,
  safeSymbol: SpecialAssetSymbol.WETH,
  cleanSymbol: 'ETH',
  cleanName: 'Ethereum',
  hasExternalRewards: false,
  documentationUrl: undefined,
  isIsolationMode: false,
  isBorrowingEnabled: true,
  chainIdToAddressMap: mapChainIdMapValues(WETH, t => t.address),
  isolationModeInfo: undefined,
})
