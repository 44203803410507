export const tokenDayDataGql = (): string => {
  return `
  id
  dayStartUnix
  dailyAmmTradeVolumeUSD
  dailyBorrowVolumeUSD
  dailyLiquidationVolumeUSD
  dailyTradeVolumeUSD
  dailyVaporizationVolumeUSD
  dailyAmmTradeVolumeToken
  dailyBorrowVolumeToken
  dailyLiquidationVolumeToken
  dailyTradeVolumeToken
  dailyVaporizationVolumeToken
  ammLiquidityUSD
  borrowLiquidityUSD
  supplyLiquidityUSD
  ammLiquidityToken
  borrowLiquidityToken
  supplyLiquidityToken
  dailyAllTransactionCount
  dailyAmmTradeCount
  dailyLiquidationCount
  dailyTradeCount
  dailyVaporizationCount
  openPriceUSD
  highPriceUSD
  lowPriceUSD
  closePriceUSD
  `
}

export const tokenHourDataGql = (onlyDataForTopTokens: boolean): string => {
  if (onlyDataForTopTokens) {
    return `
      id
      hourlyTradeVolumeUSD
      openPriceUSD
      closePriceUSD
    `
  }

  return `
  id
  hourStartUnix
  
  hourlyAmmTradeVolumeUSD
  hourlyBorrowVolumeUSD
  hourlyLiquidationVolumeUSD
  hourlyTradeVolumeUSD
  hourlyVaporizationVolumeUSD
  
  hourlyAmmTradeVolumeToken
  hourlyBorrowVolumeToken
  hourlyLiquidationVolumeToken
  hourlyTradeVolumeToken
  hourlyVaporizationVolumeToken
  
  ammLiquidityUSD
  borrowLiquidityUSD
  supplyLiquidityUSD
  
  ammLiquidityToken
  borrowLiquidityToken
  supplyLiquidityToken
  
  hourlyAllTransactionCount
  hourlyAmmTradeCount
  hourlyLiquidationCount
  hourlyTradeCount
  hourlyVaporizationCount
  
  openPriceUSD
  highPriceUSD
  lowPriceUSD
  closePriceUSD
  
  token {
    ${tokenGql(false, true)}
  }
  `
}

export const dolomiteDayDataGql = (): string => {
  return `
    id
    dayStartUnix
    dailyAmmTradeVolumeUSD
    dailyBorrowVolumeUSD
    dailyLiquidationVolumeUSD
    dailySupplyVolumeUSD
    dailyTradeVolumeUSD
    dailyVaporizationVolumeUSD
    ammLiquidityUSD
    borrowLiquidityUSD
    supplyLiquidityUSD
    totalAllTransactionCount
    totalAmmTradeCount
    totalLiquidationCount
    totalTradeCount
    totalVaporizationCount
  `
}

export const dolomiteHourDataGql = (): string => {
  return `
    id
    hourStartUnix
    hourlyAmmTradeVolumeUSD
    hourlyBorrowVolumeUSD
    hourlyLiquidationVolumeUSD
    hourlySupplyVolumeUSD
    hourlyTradeVolumeUSD
    hourlyVaporizationVolumeUSD
    ammLiquidityUSD
    borrowLiquidityUSD
    supplyLiquidityUSD
    totalAllTransactionCount
    totalAmmTradeCount
    totalLiquidationCount
    totalTradeCount
    totalVaporizationCount
  `
}

export const tokenGql = (includeExtraValues: boolean, onlyIncludeId = false): string => {
  if (onlyIncludeId) {
    return '\tid'
  }

  let extraData: string
  if (!includeExtraValues) {
    extraData = ''
  } else {
    extraData = `
    tradeVolume
    tradeVolumeUSD
    transactionCount
    ammTradeLiquidity
    supplyLiquidity
    borrowLiquidity
    supplyLiquidityUSD
    borrowLiquidityUSD
    derivedETH
  `
  }

  if (extraData) {
    return `
        chainId
        id
        marketId
        name
        symbol
        decimals
        ${extraData}
  `
  } else {
    return `
        chainId
        id
        marketId
        name
        symbol
        decimals
  `
  }
}

export const ammPairGql = (includeTokenData: boolean): string => {
  let tokenData: string
  if (includeTokenData) {
    tokenData = tokenGql(false)
  } else {
    tokenData = 'id'
  }

  return `
    id
    token0 { ${tokenData} }
    token1 { ${tokenData} }
    reserve0
    reserve1
    totalSupply
    reserveETH
    reserveUSD
    trackedReserveETH
    token0Price
    token1Price
    volumeToken0
    volumeToken1
    volumeUSD
    transactionCount
    createdAtTimestamp
    createdAtBlockNumber
    liquidityProviderCount
  `
}

export const ammBurnGql = (): string => {
  return `
    id
    transaction { ${transactionGql()} }
    timestamp
    pair { ${ammPairGql(false)} }
    to
    liquidity
    sender
    amount0
    amount1
    logIndex
    amountUSD
    feeTo
    feeLiquidity
  `
}

export const ammMintGql = (): string => {
  return `
    id
    transaction { ${transactionGql()} }
    timestamp
    pair { ${ammPairGql(false)} }
    to
    liquidity
    sender
    amount0
    amount1
    logIndex
    amountUSD
    feeTo
    feeLiquidity
  `
}

export const ammTradeGql = (): string => {
  return `
    id
    transaction { ${transactionGql()} }
    timestamp
    pair { ${ammPairGql(false)} }
    sender
    from
    amount0In
    amount1In
    amount0Out
    amount1Out
    to
    logIndex
    serialId
    amountUSD
  `
}

export const ammPairDayDataGql = (includeTokenData: boolean): string => {
  let tokenData: string
  if (includeTokenData) {
    tokenData = tokenGql(false)
  } else {
    tokenData = 'id'
  }

  return `
  id
  dayStartUnix
  pairAddress
  token0 { ${tokenData} }
  token1 { ${tokenData} }
  reserve0
  reserve1
  totalSupply
  reserveUSD
  dailyVolumeToken0
  dailyVolumeToken1
  dailyVolumeUSD
  `
}

export const transactionGql = (): string => {
  return `
      id
      blockNumber
      timestamp
  `
}

export const ammLiquidityPositionGql = (): string => {
  return `
    id
    liquidityTokenBalance
    pair { ${ammPairGql(false)} }
  `
}

export const ammPairHourData = (): string => {
  return `
    id
    hourStartUnix
    pairAddress
    token0 {
      ${tokenGql(false)}
    }
    token1 {
      ${tokenGql(false)}
    }
    reserve0
    reserve1
    reserveUSD
    hourlyVolumeToken0
    hourlyVolumeToken1
    hourlyVolumeUSD
    hourlyTransactionCount
  `
}

export const userGql = (includeExtraData: boolean): string => {
  let extraData: string
  if (!includeExtraData) {
    extraData = ''
  } else {
    extraData = `
    liquidityPositions {
      ${ammLiquidityPositionGql()}
    }
    totalBorrowVolumeOriginatedUSD
    totalCollateralLiquidatedUSD
    totalTradeVolumeUSD
    totalBorrowPositionCount
    totalLiquidationCount
    totalMarginPositionCount
    totalTradeCount
    marginAccounts: [MarginAccount!]
  `
  }

  return `
    id
    ${extraData}
  `
}

export const marginAccountGql = (includeTokenValues: boolean): string => {
  let tokenValues: string
  if (!includeTokenValues) {
    tokenValues = ''
  } else {
    tokenValues = `
    tokenValues {
      id
      marketId
      token {
        ${tokenGql(false)}
      }
      valuePar
      expirationTimestamp
    }
  `
  }

  return `
      id
      user {
        id
      }
      accountNumber
      lastUpdatedTimestamp
      lastUpdatedBlockNumber
      ${tokenValues}
  `
}

export const borrowPositionGql = (): string => {
  return `
    id
    marginAccount { ${marginAccountGql(false)} }
    openTransaction { ${transactionGql()} }
    closeTransaction { ${transactionGql()} }
    status
    openTimestamp
    closeTimestamp
    effectiveSupplyTokens { ${tokenGql(false)} }
    effectiveBorrowTokens { ${tokenGql(false)} }
    effectiveUser { 
      id
    }
    amounts {
      token { ${tokenGql(false)} }
      expirationTimestamp
      amountPar
      amountWei
    }
  `
}

export const depositGql = (): string => {
  return `
    id
    transaction {
      ${transactionGql()}
    }
    logIndex
    serialId
    marginAccount {
      ${marginAccountGql(false)}
    }
    token {
      ${tokenGql(false)}
    }
    from
    amountDeltaWei
    amountUSDDeltaWei
  `
}

export const withdrawalGql = (): string => {
  return `
    id
    transaction {
      ${transactionGql()}
    }
    logIndex
    serialId
    marginAccount {
      ${marginAccountGql(false)}
    }
    token {
      ${tokenGql(false)}
    }
    to
    amountDeltaWei
    amountUSDDeltaWei
  `
}

export const transferGql = (): string => {
  return `
    id
    transaction {
      ${transactionGql()}
    }
    logIndex
    serialId
    fromMarginAccount {
      ${marginAccountGql(false)}
    }
    toMarginAccount {
      ${marginAccountGql(false)}
    }
    token {
      ${tokenGql(false)}
    }
    amountDeltaWei
    amountUSDDeltaWei
    fromEffectiveUser {
      id
      effectiveUser {
        id
      }
    }
    toEffectiveUser {
      id
      effectiveUser {
        id
      }
    }
  `
}

export const tradeGql = (): string => {
  return `
    id
    transaction {
      ${transactionGql()}
    }
    logIndex
    serialId
    takerMarginAccount {
      ${marginAccountGql(false)}
    }
    makerMarginAccount {
      ${marginAccountGql(false)}
    }
    takerToken {
      ${tokenGql(false)}
    }
    makerToken {
      ${tokenGql(false)}
    }
    takerTokenDeltaWei
    makerTokenDeltaWei
    traderAddress
    takerAmountUSD
    makerAmountUSD
  `
}

export const liquidationGql = (): string => {
  return `
    id
    transaction {
      ${transactionGql()}
    }
    logIndex
    serialId
    solidMarginAccount {
      ${marginAccountGql(false)}
    }
    liquidMarginAccount {
      ${marginAccountGql(false)}
    }
    heldToken {
      ${tokenGql(false, true)}
    }
    borrowedToken {
      ${tokenGql(false, true)}
    }
    heldTokenAmountDeltaWei
    heldTokenLiquidationRewardWei
    borrowedTokenAmountDeltaWei
    heldTokenAmountUSD
    borrowedTokenAmountUSD
  `
}

export const vaporizationGql = (): string => {
  return `
    id
    transaction {
      ${transactionGql()}
    }
    logIndex
    serialId
    solidMarginAccount {
      ${marginAccountGql(false)}
    }
    vaporMarginAccount {
      ${marginAccountGql(false)}
    }
    heldToken {
      ${tokenGql(false)}
    }
    borrowedToken {
      ${tokenGql(false)}
    }
    heldTokenAmountDeltaWei
    borrowedTokenAmountDeltaWei
    amountUSDLiquidated
  `
}

export const marginPositionGql = (): string => {
  return `
    id
    marginAccount {
      ${marginAccountGql(false)}
    }
    openTimestamp
    heldToken {
      ${tokenGql(false)}
    }
    marginDeposit
    marginDepositUSD
    initialMarginDeposit
    initialMarginDepositUSD
    initialHeldAmountPar
    initialHeldAmountWei
    initialHeldAmountUSD
    initialHeldPrice
    initialHeldPriceUSD
    closeHeldPrice
    closeHeldPriceUSD
    closeHeldAmountWei
    closeHeldAmountUSD
    closeHeldAmountSeized
    closeHeldAmountSeizedUSD
    heldAmountPar
    owedToken {
      ${tokenGql(false)}
    }
    initialOwedAmountPar
    initialOwedAmountWei
    initialOwedAmountUSD
    initialOwedPrice
    initialOwedPriceUSD
    closeOwedPrice
    closeOwedPriceUSD
    closeOwedAmountWei
    closeOwedAmountUSD
    owedAmountPar
    status
    closeTimestamp
    expirationTimestamp
    openTransaction {
      ${transactionGql()}
    }
    closeTransaction {
      ${transactionGql()}
    }
  `
}

export const totalParsGql = (): string => {
  return `
    id
    borrowPar
    supplyPar
  `
}

export const galxeAddressDataGql = (): string => {
  return `
    nfts {
      id
      campaign {
        id
        name
        creds {
          id
          description
        }
        nftTemplates {
          traits {
            name
            value
          }
        }
        dao {
          id
          name
        }
      }
    }
  `
}

export const galxeCampaignDataGql = (): string => {
  return `
    edges {
      node {
        id
        name
        description
        endTime
        credentialGroups {
          credentials {
            id
            name
            description
          }
        }
        nftTemplates {
          traits {
            name
            value
          }
        }
      }
    }
  `
}
