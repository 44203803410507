import { W_USDM } from '../../index'
import { SpecialAsset, SpecialAssetSymbol } from '../special-assets'
import mapChainIdMapValues from '../../../utils/mapChainIdMapValues'

export const W_USDM_SPECIAL_ASSET: () => SpecialAsset = () => ({
  symbol: SpecialAssetSymbol.wUSDM,
  safeSymbol: SpecialAssetSymbol.wUSDM,
  cleanSymbol: 'wUSDM',
  cleanName: 'Wrapped Mountain USD',
  hasExternalRewards: true,
  documentationUrl: undefined,
  isIsolationMode: false,
  isBorrowingEnabled: true,
  chainIdToAddressMap: mapChainIdMapValues(W_USDM, t => t?.address),
  isolationModeInfo: undefined,
})
