import { Token } from '@dolomite-exchange/sdk-core'
import { ChainId, ChainIdMap, initializeSingleValueChainIdMap } from '../chainId'

const decimals = 18
const symbol = 'weETH'
const name = 'Wrapped eETH'

function getToken(chainId: ChainId, address: string) {
  return new Token(chainId, address, decimals, symbol, name)
}

export const WE_ETH: ChainIdMap<Token | undefined> = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  getToken(ChainId.ARBITRUM_ONE, '0x35751007a407ca6FEFfE80b3cB397736D2cf4dbe'),
)
