import { Fraction } from '@dolomite-exchange/sdk-core'
import { MarketRiskInfo } from '../types/marketRiskInfoData'
import { DEFAULT_MIN_COLLATERALIZATION, BIG_INT_ONE, ZERO_FRACTION } from '../constants'

export default function calculateLiquidationPrice(
  heldAmount: Fraction,
  owedAmount: Fraction,
  marginRatio?: Fraction,
  heldMarketInfo?: MarketRiskInfo,
  owedMarketInfo?: MarketRiskInfo,
): Fraction {
  if (heldAmount.equalTo('0')) {
    return ZERO_FRACTION
  }

  let finalMarginRatio = marginRatio ?? DEFAULT_MIN_COLLATERALIZATION
  const owedMarginPremium = owedMarketInfo?.marginPremium
  if (owedMarginPremium?.greaterThan(ZERO_FRACTION)) {
    finalMarginRatio = finalMarginRatio.multiply(owedMarginPremium.add(BIG_INT_ONE))
  }
  let finalHeldAmount = heldAmount.asFraction
  const heldMarginPremium = heldMarketInfo?.marginPremium
  if (heldMarginPremium?.greaterThan(ZERO_FRACTION)) {
    finalHeldAmount = finalHeldAmount.divide(heldMarginPremium.add(BIG_INT_ONE))
  }
  return owedAmount.asFraction.multiply(finalMarginRatio).divide(finalHeldAmount)
}
