import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import { StyledTooltip } from '../../../common/StyledTooltip'
import CircularProgress from '@material-ui/core/CircularProgress'
import { AssetRewardsWrapper } from './GLPBottomSection'
import { ethers } from 'ethers'
import Check from '@mui/icons-material/Check'
import Close from '@mui/icons-material/Close'
import ContentCopy from '@mui/icons-material/ContentCopy'
import { useTokenBalance } from '../../../../state/wallet/hooks'
import {
  useVestedGlpTransferredCumulativeRewards,
  useVestedGmxTransferredCumulativeRewards,
  useStakedGlpAverageStakedAmount,
  useStakedGlpCumulativeRewards,
  useStakedGmxAverageStakedAmount,
  useStakedGmxCumulativeRewards,
  useTransferAccountPendingReceivers,
  useVGlpToken,
  useVGmxToken,
  useFeeGmxCumulativeRewards,
  useFeeGmxAverageStakedAmount,
  useFeeGlpCumulativeRewards,
  useFeeGlpAverageStakedAmount,
} from '../../../../hooks/gmx/useGmxProtocol'
import { ZERO_ADDRESS, ZERO_FRACTION } from '../../../../constants'
import useDebounce from '../../../../hooks/useDebounce'
import { toChecksumAddressOpt } from '../../../../utils/toChecksumAddress'
import { useAcceptFullAccountTransfer } from '../../../../hooks/gmx/useGmxDolomiteProxyProtocol'
import useGlpProxyVaultAccount from '../../../../hooks/gmx/useGlpProxyVaultAccount'
import { ExternalLink } from '../../../../theme'
import { useFsGlpToken, useSbfGmxToken } from '../../../../hooks/Tokens'
import WarningRoundedIcon from '@material-ui/icons/WarningRounded'

const Subtitle = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: ${({ theme }) => theme.text2};
  margin-bottom: 7px;

  :nth-of-type(1) {
    margin-top: 0;
  }

  a {
    color: ${({ theme }) => theme.text1};
    font-weight: 700;
    text-decoration: none;
    outline: none !important;

    :hover {
      color: ${({ theme }) => theme.text2};
    }
  }
`

const ContentWrapper = styled.div`
  width: 100%;
`

// TODO - add back in when transferring out is re-enabled
/*const StyledTabs = styled(({ ...rest }) => <Tabs classes={{ indicator: 'indicator' }} {...rest} />)`
  font-family: 'Open Sans', sans-serif !important;
  justify-content: normal !important;
  min-height: 0 !important;
  margin-top: -10px;
  margin-bottom: 15px;

  .indicator {
    background-color: #f9f9f9 !important;
    bottom: 0 !important;
    display: block !important;
    height: 1.4px !important;
    transform: scale(0.5, 1) !important;
  }

  > div > div {
    justify-content: start;
  }
`

const StyledTab = styled(({ ...rest }) => (
  <Tab
    classes={{
      root: 'root',
      selected: 'selected',
    }}
    {...rest}
  />
))`
  font-family: 'Open Sans', sans-serif !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  margin-left: 0 !important;
  margin-right: 13px !important;
  text-transform: capitalize !important;
  padding: 0 !important;
  padding-bottom: 3.5px !important;
  padding-left: 4px !important;
  padding-right: 4px !important;
  max-width: 264px;
  min-width: 0 !important;
  color: #606375 !important;
  min-height: 0 !important;

  ${({ selected }) =>
    selected &&
    `
    color: #f9f9f9 !important;
  `}
  .selected {
    color: #f9f9f9 !important;
  }

  .root span {
    font-size: 18px !important;
  }
`*/

const InputWrapper = styled.div`
  width: 100%;
  align-items: center;
  font-size: 15px;
  padding: 0.5rem 0.75rem;
  border-radius: 5px;
  background-color: #1e1c29;
  margin-bottom: 18px;
  display: flex;
`

const InputTitle = styled.div`
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  margin-top: 10px;
`

const StyledInput = styled.input<{ error?: boolean; fontSize?: string; align?: string }>`
  color: ${({ error, theme }) => (error ? theme.red1 : theme.text1)};
  width: 0;
  position: relative;
  font-weight: 500;
  outline: none;
  border: none;
  flex: 1 1 auto;
  background-color: ${({ theme }) => theme.bg6};
  font-size: ${({ fontSize }) => fontSize ?? '24px'};
  text-align: ${({ align }) => align && align};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0;
  -webkit-appearance: textfield;

  ::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  [type='number'] {
    -moz-appearance: textfield;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  ::placeholder {
    color: ${({ theme }) => theme.text3};
  }

  :invalid {
    box-shadow: none;
  }
`

const CheckRow = styled.div`
  width: 100%;
  margin-bottom: 8px;

  svg {
    display: inline-block;
    vertical-align: top;
    width: 20px;
    height: 20px;
  }
`

const CheckText = styled.div`
  width: calc(100% - 25px);
  margin-left: 5px;
  display: inline-block;
  vertical-align: top;
  font-size: 13px;
`

const DescriptionText = styled.div`
  font-size: 12px;
  font-weight: 100;
  line-height: 14px;
  color: ${({ theme }) => theme.text2};
  width: 100%;
  position: relative;
  margin: 10px auto 10px;
  padding: 12px 16px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.bg2};

  svg {
    margin-right: 5px;
    margin-bottom: -3px;
    font-size: 24px;
    color: ${({ theme }) => theme.text3};
    display: inline-block;
    vertical-align: top;
  }

  a {
    color: ${({ theme }) => theme.text2};
    font-weight: 600;
    outline: none !important;
    border: none !important;
  }

  @media screen and (max-width: 650px) {
  }
`

const DescriptionContent = styled.div`
  width: calc(100% - 30px);
  display: inline-block;
  vertical-align: top;
`

const SubmitButton = styled.div<{ disabled?: boolean }>`
  width: 100%;
  padding: 12px 16px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.green2};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.text1};
  opacity: ${({ disabled }) => (disabled ? 0.7 : 1)};
  text-align: center;
  margin-top: 20px;

  :hover {
    background-color: ${({ theme, disabled }) => !disabled && theme.green1};
  }

  > div {
    width: 20px !important;
    height: 20px !important;
    font-size: 12px;
    margin-top: -1px;
    margin-bottom: -5px;
  }

  svg {
    color: ${({ theme }) => theme.text1};
  }
`

const DolomiteAddressWrapper = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.bg3};
  border-radius: 5px;
  padding: 5px 10px;
  margin-top: 4px;
  font-size: 12px;
  font-weight: 400;
  display: flex;
  justify-content: space-between;

  svg {
    width: 15px;
    height: 15px;
    margin-top: 1px;
    cursor: pointer;
  }
`

const DolomiteAddress = styled.div`
  width: fit-content;
  overflow-wrap: break-word;
  max-width: calc(100% - 30px);
`

const AddressIconWrapper = styled.div`
  display: flex;
  vertical-align: middle;
  align-items: center;
`

const ReceivingWrapper = styled.div`
  width: 100%;
  margin-top: 20px;
`

const ErrorText = styled.div`
  width: 100%;
  font-size: 12px;
  color: ${({ theme }) => theme.red1};
`

enum TransferType {
  Transfer = 0,
  Receive = 1,
}

const options = ['Transfer', 'Receive']

export default function GLPTransferAccount() {
  const vaultAccount = useGlpProxyVaultAccount()
  const [submitting, setSubmitting] = useState(false)
  const [awaitingSignature, setAwaitingSignature] = useState(false)
  const [receiver, setReceiver] = useState(vaultAccount ?? '')
  const [sender, setSender] = useState('')
  const [selectedTransferType] = useState(TransferType.Receive)
  const [copiedAddress, setCopiedAddress] = useState(false)

  const debouncedReceiver = useDebounce(receiver, selectedTransferType === TransferType.Transfer ? 50 : 0)
  const debouncedSender = useDebounce(sender, selectedTransferType === TransferType.Receive ? 50 : 0)

  const parsedReceiver = useMemo(() => {
    if (!debouncedReceiver || !ethers.utils.isAddress(debouncedReceiver) || debouncedReceiver === ZERO_ADDRESS) {
      return undefined
    }
    return toChecksumAddressOpt(debouncedReceiver)
  }, [debouncedReceiver])

  const parsedSender = useMemo(() => {
    if (!debouncedSender || debouncedSender === ZERO_ADDRESS) {
      return undefined
    }
    return toChecksumAddressOpt(debouncedSender)
  }, [debouncedSender])

  useEffect(() => {
    if (!vaultAccount) {
      return
    }

    if (selectedTransferType === TransferType.Transfer) {
      setSender(vaultAccount)
      setReceiver('')
    } else if (selectedTransferType === TransferType.Receive) {
      setSender('')
      setReceiver(vaultAccount)
    }
  }, [selectedTransferType, vaultAccount])

  const vGmxToken = useVGmxToken()
  const gmxVesterBalance = useTokenBalance(parsedSender, vGmxToken)

  const vGlpToken = useVGlpToken()
  const glpVesterBalance = useTokenBalance(parsedSender, vGlpToken)

  const [cumulativeStakedGmxRewards] = useStakedGmxCumulativeRewards(parsedReceiver)
  const [averageStakedGmx] = useStakedGmxAverageStakedAmount(parsedReceiver)
  const [cumulativeFeeGmxRewards] = useFeeGmxCumulativeRewards(parsedReceiver)
  const [averageFeeGmx] = useFeeGmxAverageStakedAmount(parsedReceiver)
  const [transferredCumulativeGmxRewards] = useVestedGmxTransferredCumulativeRewards(parsedReceiver)

  const [cumulativeGlpRewards] = useStakedGlpCumulativeRewards(parsedReceiver)
  const [averageStakedGlp] = useStakedGlpAverageStakedAmount(parsedReceiver)
  const [cumulativeFeeGlpRewards] = useFeeGlpCumulativeRewards(parsedReceiver)
  const [averageFeeGlp] = useFeeGlpAverageStakedAmount(parsedReceiver)
  const [transferredCumulativeGlpRewards] = useVestedGlpTransferredCumulativeRewards(parsedReceiver)

  const fsGlpToken = useFsGlpToken()
  const receiverGlpBalance = useTokenBalance(parsedReceiver, fsGlpToken)

  const sbfGmxToken = useSbfGmxToken()
  const receiverGmxBalance = useTokenBalance(parsedReceiver, sbfGmxToken)

  const [pendingReceiver] = useTransferAccountPendingReceivers(parsedSender)

  const isTransactionSignaled = useMemo(() => {
    if (selectedTransferType === TransferType.Transfer) {
      return false
    } else if (selectedTransferType === TransferType.Receive) {
      return !parsedSender || !parsedReceiver || pendingReceiver === parsedReceiver
    } else {
      return false
    }
  }, [selectedTransferType, parsedSender, pendingReceiver, parsedReceiver])

  const hasVestedGmx = useMemo(() => gmxVesterBalance?.asFraction.greaterThan(ZERO_FRACTION), [gmxVesterBalance])
  const hasVestedGlp = useMemo(() => glpVesterBalance?.asFraction.greaterThan(ZERO_FRACTION), [glpVesterBalance])
  const hasStakedGmx = useMemo(
    () =>
      !!receiverGmxBalance?.greaterThan(ZERO_FRACTION) ??
      !!cumulativeStakedGmxRewards?.greaterThan(ZERO_FRACTION) ??
      !!transferredCumulativeGmxRewards?.greaterThan(ZERO_FRACTION) ??
      !!averageStakedGmx?.greaterThan(ZERO_FRACTION) ??
      !!cumulativeFeeGmxRewards?.greaterThan(ZERO_FRACTION) ??
      !!averageFeeGmx?.greaterThan(ZERO_FRACTION),
    [
      receiverGmxBalance,
      cumulativeStakedGmxRewards,
      transferredCumulativeGmxRewards,
      averageStakedGmx,
      cumulativeFeeGmxRewards,
      averageFeeGmx,
    ],
  )
  const hasStakedGlp = useMemo(
    () =>
      !!receiverGlpBalance?.greaterThan(ZERO_FRACTION) ??
      !!cumulativeGlpRewards?.greaterThan(ZERO_FRACTION) ??
      !!transferredCumulativeGlpRewards?.greaterThan(ZERO_FRACTION) ??
      !!averageStakedGlp?.greaterThan(ZERO_FRACTION) ??
      !!cumulativeFeeGlpRewards?.greaterThan(ZERO_FRACTION) ??
      !!averageFeeGlp?.greaterThan(ZERO_FRACTION),
    [
      receiverGlpBalance,
      cumulativeGlpRewards,
      transferredCumulativeGlpRewards,
      averageStakedGlp,
      cumulativeFeeGlpRewards,
      averageFeeGlp,
    ],
  )

  const updateInput = useCallback((inputString: string, isSender: boolean) => {
    isSender ? setSender(inputString) : setReceiver(inputString)
  }, [])

  const { callback: performAcceptTransfer } = useAcceptFullAccountTransfer(parsedSender)
  const submitTransaction = useCallback(() => {
    if (!performAcceptTransfer) {
      return
    }

    setSubmitting(true)
    setAwaitingSignature(true)

    performAcceptTransfer()
      .then(() => {
        setAwaitingSignature(false)
        setSubmitting(false)
        selectedTransferType === TransferType.Transfer ? setReceiver('') : setSender('')
      })
      .catch(() => {
        setSubmitting(false)
        setAwaitingSignature(false)
      })
  }, [performAcceptTransfer, selectedTransferType])

  const primaryTooltipText = useMemo(() => {
    if (submitting && awaitingSignature) {
      return 'Awaiting signature...'
    } else if (submitting) {
      return 'Submitting...'
    } else if (!vaultAccount) {
      return 'Wallet is not connected'
    } else if (hasVestedGmx) {
      return 'Vested GMX not withdrawn from sender'
    } else if (hasVestedGlp) {
      return 'Vested GLP not withdrawn from sender'
    } else if (!receiver) {
      return 'Enter the receiver address'
    } else if (!parsedReceiver) {
      return 'Invalid receiver address'
    } else if (!sender) {
      return 'Enter the sender address'
    } else if (!parsedSender) {
      return 'Invalid sender address'
    } else if (hasStakedGmx) {
      return 'Receiver has already staked GMX'
    } else if (hasStakedGlp) {
      return 'Receiver has already staked GLP'
    } else if (parsedReceiver === parsedSender) {
      return 'Self-transfer not supported'
    } else if (selectedTransferType === TransferType.Transfer) {
      if (parsedReceiver === pendingReceiver) {
        return 'Transfer already initiated'
      } else if (parsedSender !== vaultAccount) {
        return 'Sender must be your Dolomite Vault'
      } else if (parsedReceiver === vaultAccount) {
        return 'Receiver cannot be your Dolomite Vault'
      }
    } else if (selectedTransferType === TransferType.Receive) {
      if (parsedReceiver !== vaultAccount) {
        return 'Receiver must be your Dolomite Vault'
      } else if (parsedSender === vaultAccount) {
        return 'Sender cannot be your Dolomite Vault'
      } else if (!isTransactionSignaled) {
        return 'Transfer not initiated'
      }
    }
    return ''
  }, [
    awaitingSignature,
    hasStakedGlp,
    hasStakedGmx,
    hasVestedGlp,
    hasVestedGmx,
    isTransactionSignaled,
    parsedReceiver,
    parsedSender,
    pendingReceiver,
    receiver,
    selectedTransferType,
    sender,
    submitting,
    vaultAccount,
  ])

  const primaryText = useMemo(() => {
    if (submitting) {
      return <CircularProgress />
    } else if (selectedTransferType === TransferType.Receive) {
      return 'Finalize Transfer'
    } else {
      return 'Signal Transfer'
    }
  }, [selectedTransferType, submitting])

  const copyToClipboard = () => {
    navigator.clipboard.writeText(parsedReceiver ?? '').then(() => setCopiedAddress(true))
  }

  return (
    <AssetRewardsWrapper>
      <h2>Transfer Account</h2>
      {/* TODO - re-enable when we allow for transferring out as well as receiving
      <StyledTabs
        value={selectedAction}
        onChange={(_: any, index: number) => {
          setSelectedAction(index)
          setCopiedAddress(false)
          setTransferNotSignaledError(false)
        }}
        indicatorColor={'primary'}
        textColor={'primary'}
      >
        {(options ?? []).map((option: string, index: number) => (
          <StyledTab key={`tradeHeader-${index}`} disableRipple label={option} />
        ))}
      </StyledTabs>*/}
      {selectedTransferType === TransferType.Transfer ? (
        <ContentWrapper>
          <Subtitle>Please only use this for full account transfers.</Subtitle>
          <Subtitle>This will transfer all your GMX, esGMX, GLP and Multiplier Points to your new account.</Subtitle>
          <Subtitle>
            Transfers are only supported if the receiving account has not staked GMX or GLP tokens before.
          </Subtitle>
          <Subtitle>
            Transfers are one-way, you will not be able to transfer staked tokens back to the sending account.
          </Subtitle>
          <InputTitle>Receiver Address</InputTitle>
          <InputWrapper>
            <StyledInput
              className={'token-amount-input'}
              value={receiver}
              onChange={event => {
                updateInput(event.target.value, false)
              }}
              fontSize={'inherit'}
              placeholder={'0x'}
            />
          </InputWrapper>
          <CheckRow>
            {!hasVestedGmx ? <Check /> : <Close />}
            <CheckText>Sender has withdrawn all tokens from GMX Vesting Vault</CheckText>
          </CheckRow>
          <CheckRow>
            {!hasVestedGlp ? <Check /> : <Close />}
            <CheckText>Sender has withdrawn all tokens from GLP Vesting Vault</CheckText>
          </CheckRow>
          <CheckRow>
            {!hasStakedGmx ? <Check /> : <Close />}
            <CheckText>Receiver has not staked GMX tokens before</CheckText>
          </CheckRow>
          <CheckRow>
            {!hasStakedGlp ? <Check /> : <Close />}
            <CheckText>Receiver has not staked GLP tokens before</CheckText>
          </CheckRow>
          <StyledTooltip title={primaryTooltipText} showTooltip={!!primaryTooltipText} position={'top'}>
            <SubmitButton disabled={!!primaryTooltipText} onClick={() => !primaryTooltipText && submitTransaction()}>
              {primaryText}
            </SubmitButton>
          </StyledTooltip>
        </ContentWrapper>
      ) : (
        <ContentWrapper>
          <Subtitle>
            {`To transfer your GMX account to Dolomite, you must use a Dolomite proxy contract (shown below) associated with your wallet as the receiving address. Additionally:`}
          </Subtitle>
          <CheckRow>
            {!hasVestedGmx ? <Check /> : <Close />}
            <CheckText>Sender has withdrawn all tokens from GMX Vesting Vault</CheckText>
          </CheckRow>
          <CheckRow>
            {!hasVestedGlp ? <Check /> : <Close />}
            <CheckText>Sender has withdrawn all tokens from GLP Vesting Vault</CheckText>
          </CheckRow>
          <CheckRow>
            {!hasStakedGmx ? <Check /> : <Close />}
            <CheckText>Receiver has not staked GMX tokens before</CheckText>
          </CheckRow>
          <CheckRow>
            {!hasStakedGlp ? <Check /> : <Close />}
            <CheckText>Receiver has not staked GLP tokens before</CheckText>
          </CheckRow>
          <DescriptionText>
            <WarningRoundedIcon />
            <DescriptionContent>
              {`Currently account transfers are only supported to your Dolomite Vault. Transferring out is not yet supported. You can withdraw assets such as GMX, but currently esGMX transferred to Dolomite will need to be vested on Dolomite.`}
            </DescriptionContent>
          </DescriptionText>
          <Subtitle>
            {`Make sure you have the correct wallet selected and connected to Dolomite, then use the following address as the receiving address on the GMX transfer account panel:`}
          </Subtitle>
          <InputTitle style={{ marginTop: '20px' }}>Receiver Address</InputTitle>
          <DolomiteAddressWrapper>
            <DolomiteAddress>{parsedReceiver}</DolomiteAddress>
            <AddressIconWrapper>
              {copiedAddress ? (
                <StyledTooltip title={'Address copied!'} position={'top'}>
                  <Check onClick={copyToClipboard} />
                </StyledTooltip>
              ) : (
                <ContentCopy onClick={copyToClipboard} />
              )}
            </AddressIconWrapper>
          </DolomiteAddressWrapper>
          <InputTitle>Sender Address</InputTitle>
          <Subtitle>Enter the address of the wallet that is transferring the account to Dolomite</Subtitle>
          <InputWrapper>
            <StyledInput
              className={'token-amount-input'}
              value={sender}
              onChange={event => {
                updateInput(event.target.value, true)
              }}
              fontSize={'inherit'}
              placeholder={'0x'}
            />
          </InputWrapper>
          <ReceivingWrapper>
            {!isTransactionSignaled && (
              <ErrorText>
                Transfer not signaled. Make sure you initiate the transfer on GMX before completing the transfer here.
                You can learn more about the transfer process by reading the{' '}
                <ExternalLink href={'https://docs.dolomite.io/integrations/gmx-glp#full-account-transfers'}>
                  docs
                </ExternalLink>
                .
              </ErrorText>
            )}
            <StyledTooltip title={primaryTooltipText} showTooltip={!!primaryTooltipText} position={'top'}>
              <SubmitButton disabled={!!primaryTooltipText} onClick={() => !primaryTooltipText && submitTransaction()}>
                {primaryText}
              </SubmitButton>
            </StyledTooltip>
          </ReceivingWrapper>
        </ContentWrapper>
      )}
    </AssetRewardsWrapper>
  )
}
