import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import { PanelSubtitle, PanelTitle } from './index'
import toDate from '../../utils/toDate'
import { formatAmount } from '../../utils/formatAmount'
import {
  LiquidityMiningVestingPosition,
  useAllVestingPositions,
  VestingPositionStatus,
} from '../../types/liquidityMiningVestingData'
import { useActiveWeb3React } from '../../hooks'
import { SimpleLoader } from '../../components/Loader'
import { StyledTooltip, StyledTooltipWithIcon } from '../../components/common/StyledTooltip'
import VestingExecuteModal from './VestingExecuteModal'
import { useGalxeAddressData } from '../../types/galxeAddressData'
import VestingTransitionModal from './VestingTransitionModal'
import { useIsPositionGrandfatherable, useTokensWithOTokenRewards } from '../../hooks/useLiquidityMining'
import { useAggregateBalanceData } from '../../types/aggregateBalanceData'
import { useFiatPricesWithLoadingIndicator } from '../../hooks/useFiatValue'
import { Fraction } from '@dolomite-exchange/sdk-core'

const VestingPositionsWrapper = styled.div`
  width: 500px;
  display: inline-block;
  vertical-align: top;
  margin: 20px;
  text-align: left;

  @media screen and (max-width: 660px) {
    margin-bottom: 100px;
  }

  @media screen and (max-width: 650px) {
    width: 100%;
    margin: 20px 0 100px;
  }
`

const NoVestingPositionsNotice = styled.div`
  width: 100%;
  text-align: center;
  color: ${({ theme }) => theme.text2};
`

const LoadingText = styled.div`
  width: 100%;
  text-align: center;
  color: ${({ theme }) => theme.text1};
  font-size: 14px;
  margin-top: 10px;
`

const VestingPosition = styled.div`
  width: 100%;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
  height: auto;
  border-radius: 8px;
  padding: 16px 24px;
  margin-top: 10px;
  background-color: ${({ theme }) => theme.bg1};

  @media screen and (max-width: 650px) {
    padding: 12px 18px;
  }
`

const CompletedVestingTitle = styled.div`
  color: ${({ theme }) => theme.text2};
  font-size: 21px;
  font-weight: 400;
  margin-top: 15px;
`

const VestingTitleRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 600;
  color: ${({ theme }) => theme.text3};

  @media screen and (max-width: 650px) {
    font-size: 10px;
  }
`

const AmountTitle = styled.div`
  width: 30%;
`

const DiscountTitle = styled.div`
  width: 30%;
  text-align: right;
`

const TimeRemainingTitle = styled.div`
  width: 40%;
  text-align: right;
`

const VestingContentRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  font-weight: 400;
  color: ${({ theme }) => theme.text1};

  @media screen and (max-width: 650px) {
    font-size: 13px;
  }
`

const VestingAmount = styled.div`
  width: 30%;
`

const VestingDiscount = styled.div`
  width: 30%;
  text-align: right;
`

const VestingStatus = styled.div<{ wasExecuted: boolean }>`
  width: 40%;
  color: ${({ theme, wasExecuted }) => (wasExecuted ? theme.green1 : theme.red1)};
  text-align: right;
  font-size: 16px;
  margin-top: -9px;
`

const VestingTimeRemaining = styled.div`
  width: 40%;
  text-align: right;
`

const VestingButtonWrapper = styled.div`
  width: 93px;
  margin-left: calc(40% - 93px);
  margin-top: -14px;
  height: 32px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;

  @media screen and (max-width: 650px) {
    height: 28px;
    font-size: 12px;
    line-height: 13px;
    text-align: center;
    margin-top: -12px;
  }
`

const VestingButton = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.green2};
  color: ${({ theme }) => theme.text1};
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  padding: 8px 20px;
  cursor: pointer;

  :hover {
    background-color: ${({ theme }) => theme.green1};
  }

  @media screen and (max-width: 650px) {
    font-size: 12px;
    line-height: 13px;
    text-align: center;
  }
`

const TimeUntilDetonation = styled.div<{ isNearDetonation: boolean }>`
  color: ${({ theme, isNearDetonation }) => (isNearDetonation ? theme.red1 : theme.text2)};
  font-size: 9px;
  width: calc(100% + 40px);
  text-align: center;
  transform: translateY(0px);
  margin-left: -28px;

  svg {
    height: 10px;
    transform: translateY(1px);
  }
`

const TransitionWrapper = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.text2};
  display: flex;
  justify-content: space-between;
  padding: 10px 24px 10px;
  background: #23212f;
  width: calc(100% + 48px);
  margin-left: -24px;
  margin-top: 8px;
  margin-bottom: -16px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;

  @media screen and (max-width: 650px) {
    width: calc(100% + 36px);
    margin-left: -18px;
  }
`

const TransitionText = styled.div`
  margin-top: 4px;

  svg {
    transform: translate(-3px, 2px);
  }
`

const TransitionButton = styled.div`
  border-radius: 5px;
  background-color: ${({ theme }) => theme.blue1};
  padding: 6px 12px;
  color: white;
  font-size: 11px;
  cursor: pointer;

  :hover {
    background-color: ${({ theme }) => theme.blue2};
  }
`

const DAYS_UNTIL_DETONATION = 28
const DETONATION_DURATION = DAYS_UNTIL_DETONATION * 86_400_000

const ActiveVestingPositionRow = ({
  vestingPosition,
  setShowExecuteModal,
  setShowTransitionModal,
}: {
  vestingPosition: LiquidityMiningVestingPosition
  setShowExecuteModal: (arg1: LiquidityMiningVestingPosition) => void
  setShowTransitionModal: (arg1: LiquidityMiningVestingPosition) => void
}) => {
  const mustExecuteByDate = new Date(vestingPosition.vestingCompleteTimestamp)
  mustExecuteByDate.setDate(mustExecuteByDate.getDate() + DAYS_UNTIL_DETONATION)
  const timeDifference = mustExecuteByDate.getTime() - new Date().getTime()
  const isGrandfatherable = useIsPositionGrandfatherable(vestingPosition)
  const isNearDetonation = timeDifference < DETONATION_DURATION / 4
  return vestingPosition.status === VestingPositionStatus.ACTIVE ? (
    <VestingPosition key={vestingPosition.id}>
      <VestingTitleRow>
        <AmountTitle>Amount</AmountTitle>
        <DiscountTitle>Discount</DiscountTitle>
        <TimeRemainingTitle>
          {vestingPosition.vestingCompleteTimestamp.getTime() < Date.now() ? <>{` `}</> : <>Time Remaining</>}
        </TimeRemainingTitle>
      </VestingTitleRow>
      <VestingContentRow>
        <VestingAmount>{formatAmount(vestingPosition.oTokenAmount, 2, true)} oARB</VestingAmount>
        <VestingDiscount>{formatAmount(vestingPosition.discountPercentage)}</VestingDiscount>
        {vestingPosition.vestingCompleteTimestamp.getTime() < Date.now() ? (
          <VestingButtonWrapper>
            <VestingButton onClick={() => setShowExecuteModal(vestingPosition)}>Execute</VestingButton>
          </VestingButtonWrapper>
        ) : (
          <StyledTooltip
            title={vestingPosition.vestingCompleteTimestamp.toLocaleString([], {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
              hour: 'numeric',
              minute: '2-digit',
            })}
          >
            <VestingTimeRemaining>{toDate(vestingPosition.vestingCompleteTimestamp)}</VestingTimeRemaining>
          </StyledTooltip>
        )}
      </VestingContentRow>
      {isGrandfatherable && (
        <TransitionWrapper>
          <TransitionText>
            This vest is eligible to be transitioned{' '}
            <StyledTooltipWithIcon
              tooltipText={
                'Vests that were opened when the max vesting discount was 20% and have yet to be executed are eligible to transition to a new vesting duration, allowing for a longer vesting period and a discount of up to 100%'
              }
            />
          </TransitionText>
          <TransitionButton onClick={() => setShowTransitionModal(vestingPosition)}>Transition</TransitionButton>
        </TransitionWrapper>
      )}
    </VestingPosition>
  ) : (
    <></>
  )
}

type AccountToPreviousNumberOfPositionsMap = Record<string, number | undefined>

function VestingPositions() {
  const { account } = useActiveWeb3React()
  const { levelData, loading } = useGalxeAddressData(account)
  const { level, start, end, total } = levelData
  const [showExecuteModal, setShowExecuteModal] = useState<LiquidityMiningVestingPosition | undefined>(undefined)
  const [hasLoadedData, setHasLoadedData] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [showLoadingVest, setShowLoadingVest] = useState(false)
  const [showTransitionModal, setShowTransitionModal] = useState<LiquidityMiningVestingPosition | undefined>(undefined)
  const [accountToPreviousNumberOfPositions, setAccountToPreviousNumberOfPositions] = useState<
    AccountToPreviousNumberOfPositionsMap
  >({})
  const rewardTokens = useTokensWithOTokenRewards()
  const { data: tokensToBalances } = useAggregateBalanceData(account, rewardTokens)
  const [tokenFiatBalanceMap] = useFiatPricesWithLoadingIndicator(rewardTokens)
  const totalDepositedFiatValue = useMemo(
    () =>
      rewardTokens.reduce((total, token) => {
        return total.add(
          tokenFiatBalanceMap[token.address]?.asFraction.multiply(
            (tokensToBalances && tokensToBalances[token.address]?.asFraction) ?? new Fraction(0),
          ) ?? new Fraction(0),
        )
      }, new Fraction(0)),
    [rewardTokens, tokenFiatBalanceMap, tokensToBalances],
  )
  const { data: activeVestingPositions, loading: activeVestingPositionsLoading } = useAllVestingPositions(
    account,
    totalDepositedFiatValue,
  )

  useEffect(() => {
    if (activeVestingPositionsLoading) {
      setHasLoadedData(true)
    }
  }, [activeVestingPositionsLoading])

  useEffect(() => {
    setShowTransitionModal(undefined)
  }, [account])

  useEffect(() => {
    if (hasLoadedData && !activeVestingPositionsLoading) {
      setIsLoading(false)
    }
  }, [hasLoadedData, activeVestingPositionsLoading])

  const handleAddLoadingVest = useCallback(() => {
    if (account) {
      setAccountToPreviousNumberOfPositions(prev => {
        return {
          ...prev,
          [account]: activeVestingPositions.length,
        }
      })
    }
    setShowLoadingVest(true)
  }, [account, activeVestingPositions.length])

  useEffect(() => {
    if (
      activeVestingPositions &&
      account &&
      activeVestingPositions.length > (accountToPreviousNumberOfPositions[account] ?? 0)
    ) {
      setAccountToPreviousNumberOfPositions(prev => {
        return {
          ...prev,
          [account]: activeVestingPositions.length,
        }
      })
      setShowLoadingVest(false)
    }
  }, [activeVestingPositions, accountToPreviousNumberOfPositions, account])

  useEffect(() => {
    const listener = handleAddLoadingVest
    document.addEventListener('onCreateVest', listener)
    return () => document.removeEventListener('onCreateVest', listener)
  }, [handleAddLoadingVest])

  const hasPastPositions = useMemo(() => {
    return activeVestingPositions.some(
      position =>
        position.status === VestingPositionStatus.CLOSED || position.status === VestingPositionStatus.FORCE_CLOSED,
    )
  }, [activeVestingPositions])

  return (
    <VestingPositionsWrapper>
      <VestingTransitionModal
        vestingPosition={showTransitionModal}
        userLevel={level}
        setShowTransitionModal={setShowTransitionModal}
      />
      <VestingExecuteModal
        vestingPosition={showExecuteModal}
        totalDeposited={totalDepositedFiatValue}
        setShowExecuteModal={setShowExecuteModal}
      />
      <PanelTitle>Currently Vesting</PanelTitle>
      <PanelSubtitle>
        Currently vesting oARB is displayed here. Once vesting is completed, you will be able to execute your vest to
        receive your locked ARB and the ARB you vested from oARB. If your discount is less than 100%, you will need ETH
        in your Dolomite Balance to purchase the discounted ARB.
      </PanelSubtitle>
      {isLoading ? (
        <LoadingText>Loading...</LoadingText>
      ) : (
        <>
          {showLoadingVest && (
            <VestingPosition
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <SimpleLoader height={28} width={90} />
              <SimpleLoader height={28} width={70} />
            </VestingPosition>
          )}
          {activeVestingPositions.length === 0 && !showLoadingVest ? (
            <NoVestingPositionsNotice>No oARB currently vesting</NoVestingPositionsNotice>
          ) : (
            activeVestingPositions.map(vestingPosition => (
              <ActiveVestingPositionRow
                vestingPosition={vestingPosition}
                setShowExecuteModal={setShowExecuteModal}
                setShowTransitionModal={setShowTransitionModal}
                key={vestingPosition.id}
              />
            ))
          )}
          {hasPastPositions && <CompletedVestingTitle>Completed Vesting</CompletedVestingTitle>}
          {activeVestingPositions.map(
            vestingPosition =>
              (vestingPosition.status === VestingPositionStatus.CLOSED ||
                vestingPosition.status === VestingPositionStatus.FORCE_CLOSED) && (
                <VestingPosition key={vestingPosition.id}>
                  <VestingTitleRow>
                    <AmountTitle>Amount</AmountTitle>
                    <DiscountTitle>Discount</DiscountTitle>
                    <TimeRemainingTitle></TimeRemainingTitle>
                  </VestingTitleRow>
                  <VestingContentRow>
                    <VestingAmount>{formatAmount(vestingPosition.oTokenAmount, 2, true)} oARB</VestingAmount>
                    <VestingDiscount>{formatAmount(vestingPosition.discountPercentage)}</VestingDiscount>
                    <VestingStatus wasExecuted={vestingPosition.status === VestingPositionStatus.CLOSED}>
                      {vestingPosition.status === VestingPositionStatus.FORCE_CLOSED ? 'Detonated' : 'Executed'}
                    </VestingStatus>
                  </VestingContentRow>
                </VestingPosition>
              ),
          )}
        </>
      )}
    </VestingPositionsWrapper>
  )
}

export default React.memo(VestingPositions)
