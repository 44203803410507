import { Token } from '@dolomite-exchange/sdk-core'
import { ChainId, ChainIdMap, initializeSingleValueChainIdMap } from '../chainId'
import { STAKED_GLP_REWARD_TRACKER_ADDRESSES } from '../index'

const decimals = 18
const symbol = 'fsGLP'
const name = 'Fee + Staked GLP'

export const FS_GLP: ChainIdMap<Token | undefined> = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  new Token(
    ChainId.ARBITRUM_ONE,
    STAKED_GLP_REWARD_TRACKER_ADDRESSES[ChainId.ARBITRUM_ONE]!, // the fsGLP address
    decimals,
    symbol,
    name,
  ),
)
