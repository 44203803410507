import { ChainId, GLV_BTC_ADDRESSES, GLV_BTC_ISOLATION_MODE_ADDRESSES } from '../../index'
import { Token } from '@dolomite-exchange/v2-sdk'
import { getOptionally } from '../special-assets-util-functions'
import { SpecialAsset, SpecialAssetSymbol } from '../special-assets'
import {
  getGmCollateralTokens,
  getGmDebtTokens,
  getGmUsdValidInputTokens,
  getGmUsdValidOutputTokens,
} from './special-gm-tokens'
import { WBTC } from '../../tokens/WBTC'
import { calculateGlvBTCUSDProxyVaultAddress } from '../../../utils/isolation/calculateProxyVaultAddress'

const cleanSymbol = 'GLV-BTC'
const cleanName = 'GLV: BTC-USD'

export const GLV_BTC_USD_SPECIAL_ASSET: () => SpecialAsset = () => ({
  cleanSymbol,
  cleanName,
  symbol: SpecialAssetSymbol.GLVBTC,
  safeSymbol: SpecialAssetSymbol.GLVBTC,
  hasExternalRewards: false,
  documentationUrl: 'https://docs.dolomite.io/integrations/gmx/gm',
  isIsolationMode: true,
  isBorrowingEnabled: false,
  chainIdToAddressMap: GLV_BTC_ISOLATION_MODE_ADDRESSES,
  isolationModeInfo: {
    getWrappedToken: (chainId: ChainId) =>
      getOptionally(
        GLV_BTC_ISOLATION_MODE_ADDRESSES[chainId],
        address => new Token(chainId, address, 18, cleanSymbol, `Dolomite Isolation: ${cleanName}`),
      ),
    getUnderlyingToken: getGlvToken,
    getUnderlyingSpenderToken: getGlvToken,
    allowableCollateralTokens: (chainId: ChainId) =>
      getGmCollateralTokens(SpecialAssetSymbol.GLVBTC, chainId, WBTC[chainId]),
    allowableDebtTokens: (chainId: ChainId) => getGmDebtTokens(chainId, WBTC[chainId]),
    allowableInputTokensForZap: (chainId: ChainId) => getGmUsdValidInputTokens(chainId, WBTC[chainId]),
    allowableOutputTokensForZap: (chainId: ChainId) => getGmUsdValidOutputTokens(chainId, WBTC[chainId]),
    remapAccountAddress: calculateGlvBTCUSDProxyVaultAddress,
    isAsync: true,
  },
})

function getGlvToken(chainId: ChainId): Token | undefined {
  return getOptionally(GLV_BTC_ADDRESSES[chainId], address => new Token(chainId, address, 18, cleanSymbol, cleanSymbol))
}
