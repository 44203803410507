import { Token } from '@dolomite-exchange/v2-sdk'
import { getSpecialAsset } from '../../constants/isolation/special-assets'
import { USDM } from '../../constants/tokens/USDM'
import { ChainId, USDM_ROUTER_ADDRESSES } from '../../constants'
import invariant from 'tiny-invariant'

export default function remapSpenderForAllowance(
  token: Token,
  account: string,
  defaultSpender: string | undefined,
): string | undefined {
  const usdm = USDM[token.chainId as ChainId]
  if (usdm?.equals(token)) {
    const usdmRouterAddress = USDM_ROUTER_ADDRESSES[token.chainId as ChainId]
    invariant(!!usdmRouterAddress, `USDM Router address not set for chain ${token.chainId}`)
    return usdmRouterAddress
  }

  const remapAddressFunction = getSpecialAsset(token.chainId, token)?.isolationModeInfo?.remapAccountAddress
  if (remapAddressFunction) {
    return remapAddressFunction(account, token.chainId) ?? defaultSpender
  } else {
    return defaultSpender
  }
}
