import React, { MutableRefObject, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components/macro'
import DataRow from '../../../components/Orders/DataRow'
import {
  MarginPosition,
  MarginPositionStatus,
  MarginPositionStatusUtil,
  MarginPositionType,
} from '../../../types/marginPositionData'
import toDate from '../../../utils/toDate'
import Column from '../../../components/Orders/Column'
import { Percent, Trade, TradeType } from '@dolomite-exchange/v2-sdk'
import { Currency, Fraction } from '@dolomite-exchange/sdk-core'
import ConfirmTradeModal from '../../../components/Trade/ConfirmTradeModal'
import { useUserSlippageTolerance } from '../../../state/user/hooks'
import { useClosePositionCallback } from '../../../hooks/useTradeCallback'
import ReactGA from 'react-ga'
import cleanCurrencySymbol from '../../../utils/cleanCurrencySymbol'
import { getTradeVersion } from '../../../hooks/useToggledVersion'
import { NavLink } from 'react-router-dom'
import JSBI from 'jsbi'
import { useDerivedTradeInfo, useTradeActionHandlers } from '../../../state/trade/hooks'
import { DerivedSubmittedMarginPosition } from '../TradePanel'
import CircularProgress from '@material-ui/core/CircularProgress'
import { StyledTooltip } from '../../../components/common/StyledTooltip'
import { MarginInformation } from '../../../components/Trade/TradeModalFooter'
import { TableLoader } from '../../../components/Loader'
import ManagePositionModal, { FeesButton, Slider, SliderDot } from '../../../components/Trade/ManagePositionModal'
import { useDefaultMarginAccount } from '../../../types/marginAccount'
import { useCloseBorrowPosition } from '../../../hooks/useBorrowPositionProtocol'
import { formatAmount } from '../../../utils/formatAmount'
import { ZERO_FRACTION, ZERO_PERCENT } from '../../../constants'
import { PageSizeContext } from '../../App'

const OrdersWrapper = styled.div`
  position: relative;
  width: 100%;
  height: calc(100% - 32px);
`

const ColumnTitles = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 25px;
  font-weight: 600;
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 3px;
  color: ${({ theme }) => theme.text3};
  cursor: default;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-top: 20px;
    padding: 0 20px;
  `};
`

const LoaderWrapper = styled.div`
  padding: 0 25px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 0 20px;
  `};
`

const DataRows = styled.div`
  height: 100%;
  width: 100%;
  display: inline-block;
  vertical-align: top;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 0;
  }

  ::-webkit-scrollbar-track {
    background: none;
  }

  ::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 5px;
    border: none;
  }
`

const HistoryLinkWrapper = styled.div`
  width: 100%;
  padding: 10px 0 20px 0;
  font-size: 14px;
  text-align: center;
  margin-top: 10px;

  > a {
    color: ${({ theme }) => theme.text3};
    text-decoration: none;

    :hover {
      color: ${({ theme }) => theme.text2};
    }
  }
`

const Unit = styled.span`
  font-weight: 200;
`

const HideOnTablet = styled.span`
  @media (max-width: 950px) {
    display: none;
  }
`

const ShowOnTablet = styled.span`
  display: none;

  @media (max-width: 950px) {
    display: inline;
  }
`

const MarginRowWrapper = styled.div<{ expanded: boolean; isFaded?: boolean }>`
  width: 100%;
  height: fit-content;
  overflow: hidden;
  position: relative;
  /*cursor: pointer;*/
  ${({ theme, expanded }) => (expanded ? `background-color: ${theme.bg2};` : '')}
  padding-top: 10px;
  padding: 8px 20px 8px;

  ${({ isFaded }) => isFaded && 'opacity: 0.6;'}
  > div {
    height: ${({ expanded }) => (expanded ? '87px;' : '34px;')};
    transition: height 0.2s ease-in-out;

    > div:nth-child(2) {
      ${({ expanded }) => (expanded ? 'opacity: 1;' : 'opacity: 0;')}
      transition: opacity 0.2s ease-in-out;
    }
  }

  :hover {
    background-color: ${({ theme }) => theme.bg2};
  }
`

const MarginRowInner = styled.div`
  overflow: hidden;
`

export const WarningBubble = styled.div`
  border-radius: 50%;
  color: #f9f9f9;
  font-weight: 600;
  font-size: 10px;
  background-color: #fc3b26;
  width: 13px;
  height: 13px !important;
  line-height: 13px;
  text-align: center;
  padding-left: 0;
  position: absolute;
  left: 4px;
  top: 3px;
`

const InnerWarningBubble = styled(WarningBubble)`
  left: 3px;
  top: 0;
  padding-left: 0.5px;
  padding-right: 0;
  font-weight: 600;
  display: inline-block;
  position: relative;
`

const Top = styled.div`
  width: 100%;
  height: 36px;
  display: flex;
  justify-content: space-between;
`

const MarketSection = styled.div`
  width: 45%;
  font-size: 10px;
`

const Market = styled.div`
  width: 100%;
  font-weight: 200;
  height: 16px;
  line-height: 16px;
  font-size: 12px;
  margin-top: 2px;

  @media (max-width: 345px) {
    font-size: 11px;
  }
`

const Type = styled.span<{ type: MarginPositionType }>`
  font-weight: 700;
  color: ${({ theme, type }) => {
    if (type === MarginPositionType.LONG) {
      return theme.green1
    } else if (type === MarginPositionType.SHORT) {
      return theme.red1
    } else {
      return theme.yellow1
    }
  }};
`

const ProfitLossWrapper = styled.div`
  width: 23%;
`

const ProfitLoss = styled.div<{ isPositive: boolean | null }>`
  width: fit-content;
  background-color: ${({ theme, isPositive }) => {
    return isPositive === null ? theme.text2 : isPositive ? theme.green1 : theme.red1
  }};
  border-radius: 5px;
  color: white;
  font-weight: 700;
  padding: 4px 8px;
  font-size: 12px;
  margin-top: 5px;

  @media (max-width: 345px) {
    padding: 4px 5px;
    font-size: 11px;
  }
`

const FeesWrapper = styled.div`
  display: inline-block;
  vertical-align: top;
  margin-left: 5px;

  > div {
    margin: 0 !important;
    color: ${({ theme }) => theme.text3} !important;
  }

  @media screen and (max-width: 750px) {
    display: block;
    margin: 0;

    > div {
      float: left;
    }
  }
`

const LiquidationWrapper = styled.div`
  width: 33%;
  height: 36px;
  line-height: 36px;
  text-align: right;
`

const Liquidation = styled.div`
  width: fit-content;
  height: 100%;
  font-weight: 600;
  font-size: 12px;
  display: inline-block;
  vertical-align: top;

  @media (max-width: 345px) {
    font-size: 11px;
  }
`

const Bottom = styled.div`
  width: 100%;
  height: 40px;
  font-size: 11px;
  display: flex;
  justify-content: space-between;
  margin-top: 3px;
`

const BottomSizeEquity = styled.div`
  width: 40%;
`

const BottomManagePosition = styled.div<{ closed?: boolean }>`
  width: ${({ closed }) => (closed ? '0%' : '30%')};
  min-width: 104px;
`

const BottomCloseButton = styled.div<{ closed?: boolean }>`
  width: ${({ closed }) => (closed ? '50%' : '25%')};
  min-width: 90px;
`

const CloseButton = styled.div`
  width: 100%;
  border-radius: 5px;
  font-size: 10px;
  font-weight: 600;
  background: ${({ theme }) => theme.bg4};
  cursor: pointer;
  padding: 10px 4px;
  margin-top: 4px;
  text-align: center;
  text-transform: uppercase;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-top: 0px;
    height: 28px;
    line-height: 18px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 4px;
    height: inherit;
    line-height: inherit;
  `};

  :hover {
    background: ${({ theme }) => theme.bg5};
  }
`

const SpinnerWrapper = styled.div`
  > div {
    color: white !important;
    height: 22px !important;
    width: 22px !important;
    margin-bottom: -2px !important;
  }
`

const BottomRow = styled.div`
  width: 100%;
  text-align: left;
  position: relative;
`

const BottomDataItem = styled.div`
  width: fit-content;
  line-height: 18px;
`

const BottomLabel = styled.span`
  color: ${({ theme }) => theme.text2};
  margin-right: 3px;
`

const BottomValue = styled.span`
  color: ${({ theme }) => theme.text1};
`

const ExpandedMarginInfo = styled.div`
  width: 100%;
  display: inline-block;
  vertical-align: top;
  font-size: 12px;
`

const ExpandedMarginInfoSection = styled.div`
  width: fit-content;
  display: inline-block;
  vertical-align: top;
  margin-right: 25px;

  > div {
    height: 18px;
  }
`

const ExpandedInfoTitle = styled.div`
  color: ${({ theme }) => theme.text3};
  width: fit-content;
`

const ExpandedInfoContent = styled.div`
  color: ${({ theme }) => theme.text1};
`

const ClosePositionButton = styled.button`
  width: fit-content;
  background-color: ${({ theme }) => theme.bg4};
  color: white;
  border-radius: 5px;
  padding: 8px 15px;
  border: none;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  float: right;
  margin-top: 5px;

  :hover {
    background-color: ${({ theme, disabled }) => !disabled && theme.bg5};
  }
`

const ManagePositionButton = styled(ClosePositionButton)`
  margin-right: 8px;
`

interface MarginRowProps {
  row: MarginPosition
  isMobile: boolean
  index: number
  setManagePositionState: (props: MarginPosition) => void
  setClosePositionState: (props: ClosePositionProps) => void
  expanded: boolean
  toggleExpanded: (arg0: MutableRefObject<any>) => void
  addClosing: (arg0: string) => void
  removeClosing: (arg0: string) => void
  isClosing?: boolean
  showProfitWithFees?: boolean
}

const marginRowComparator = (prevProps: MarginRowProps, nextProps: MarginRowProps) => {
  // TODO - have it compare the exact object
  // return prevProps.row === nextProps.row && prevProps.isMobile === nextProps.isMobile
  return false
}

const MarginRow = React.memo<MarginRowProps>(
  ({
    row,
    isMobile,
    index,
    setManagePositionState,
    setClosePositionState,
    expanded,
    toggleExpanded,
    addClosing,
    removeClosing,
    isClosing,
    showProfitWithFees,
  }: MarginRowProps) => {
    const wrapperRef = useRef(null)

    const defaultMarginAccount = useDefaultMarginAccount()
    const { callback: closePositionCallback, trade: closePositionTrade } = useClosePositionCallback(
      row,
      defaultMarginAccount,
    )
    const heldTokens = useMemo(() => [row.heldToken], [row])
    const { callback: transferCallback } = useCloseBorrowPosition(
      row.marginAccount.accountNumber,
      defaultMarginAccount.accountNumber,
      heldTokens,
      undefined,
    )

    const handleTrade = useCallback(() => {
      if (!closePositionCallback) {
        return
      }
      setClosePositionState({
        attemptingTxn: true,
        tradeToConfirm: closePositionTrade,
        showConfirm: true,
        tradeErrorMessage: undefined,
        txHash: undefined,
        handleTrade: handleTrade,
        closingPosition: row,
      })
      closePositionCallback()
        .then(hash => {
          addClosing(row.id)
          setClosePositionState({
            attemptingTxn: false,
            tradeToConfirm: closePositionTrade,
            showConfirm: true,
            tradeErrorMessage: undefined,
            txHash: hash,
            handleTrade: handleTrade,
            closingPosition: row,
          })

          ReactGA.event({
            category: 'Close Position',
            action: 'Close position w/o Send',
            label: [
              cleanCurrencySymbol(closePositionTrade?.inputAmount.currency),
              cleanCurrencySymbol(closePositionTrade?.outputAmount.currency),
              getTradeVersion(closePositionTrade),
            ].join('/'),
          })

          ReactGA.event({
            category: 'Routing',
            action: 'Trade with multihop enabled',
          })
        })
        .catch(error => {
          removeClosing(row.id)
          setClosePositionState({
            attemptingTxn: false,
            showConfirm: true,
            tradeToConfirm: closePositionTrade,
            tradeErrorMessage: error.message,
            txHash: undefined,
            handleTrade: handleTrade,
            closingPosition: row,
          })
        })
    }, [closePositionCallback, setClosePositionState, closePositionTrade, row, addClosing, removeClosing])

    const onClosePosition = () => {
      setClosePositionState({
        tradeToConfirm: closePositionTrade,
        attemptingTxn: false,
        tradeErrorMessage: undefined,
        showConfirm: true,
        txHash: undefined,
        handleTrade: handleTrade,
        closingPosition: row,
      })
    }

    const onWithdrawCollateralFromLiquidation = () => {
      if (!transferCallback) {
        return
      }

      setClosePositionState({
        attemptingTxn: true,
        tradeToConfirm: undefined,
        showConfirm: true,
        tradeErrorMessage: undefined,
        txHash: undefined,
        handleTrade: undefined,
        isWithdrawCollateral: true,
        closingPosition: undefined,
      })
      transferCallback()
        .then(hash => {
          addClosing(row.id)
          setClosePositionState({
            attemptingTxn: false,
            tradeToConfirm: undefined,
            showConfirm: true,
            tradeErrorMessage: undefined,
            txHash: hash,
            handleTrade: undefined,
            isWithdrawCollateral: undefined,
            closingPosition: undefined,
          })

          ReactGA.event({
            category: 'Withdraw Collateral from Position',
            action: 'Withdraw all collateral from position',
            label: [cleanCurrencySymbol(row.heldToken)].join('/'),
          })
        })
        .catch(error => {
          removeClosing(row.id)
          setClosePositionState({
            attemptingTxn: false,
            showConfirm: true,
            tradeToConfirm: undefined,
            tradeErrorMessage: error.message,
            txHash: undefined,
            handleTrade: undefined,
            isWithdrawCollateral: undefined,
            closingPosition: undefined,
          })
        })
    }

    const longLeverage =
      row.positionType === MarginPositionType.LONG ? row.leverage.toFixed(2) : row.leverage.add('1').toFixed(2)
    const shortLeverage =
      row.positionType === MarginPositionType.SHORT ? row.leverage.toFixed(2) : row.leverage.subtract('1').toFixed(2)
    const lpFee = new Percent('3', '10000')
    const lpWithLev = lpFee.multiply(row.heldAmount.divide(row.marginDeposit)).multiply(new Fraction('2'))
    const profitPctWithoutFees = lpWithLev.add(row.profitPercentage)
    const feeValue = row.equityHeldAmount.asFraction.multiply(lpWithLev)
    const profitAmount = row.equityHeldAmount.greaterThan('0')
      ? row.equityHeldAmount.asFraction.subtract(row.marginDeposit.asFraction)
      : row.equityHeldAmount.asFraction
    const THREE_DAYS_MILLIS = 1_000 * 86_400 * 3
    const showExpirationWarning =
      row.status === MarginPositionStatus.Open && row.expirationTimestamp
        ? row.expirationTimestamp.getTime() - Date.now() < THREE_DAYS_MILLIS
        : false
    const showCollateralWarning = row.status === MarginPositionStatus.Liquidated && row.isLiquidatedAndHasCollateral

    const isFaded =
      !(
        row.status === MarginPositionStatus.Open ||
        ((row.status === MarginPositionStatus.Expired || row.status === MarginPositionStatus.Liquidated) &&
          row.isLiquidatedAndHasCollateral)
      ) || isClosing

    if (isMobile) {
      // TODO - Show a little red circle with an exclamation point when close to expiry
      return (
        <MarginRowWrapper
          expanded={expanded}
          // onClick={() =>
          //   !(
          //     row.status === MarginPositionStatus.Closed ||
          //     ((row.status === MarginPositionStatus.Expired || row.status == MarginPositionStatus.Liquidated) &&
          //       !row.isLiquidatedAndHasCollateral)
          //   ) && toggleExpanded(wrapperRef)
          // }
          onClick={() => {
            toggleExpanded(wrapperRef)
          }}
          ref={wrapperRef}
          isFaded={isFaded}
        >
          {showCollateralWarning && !expanded ? (
            <StyledTooltip title={'Position has collateral that can be withdrawn'} placement={'top'}>
              <WarningBubble>!</WarningBubble>
            </StyledTooltip>
          ) : (
            showExpirationWarning &&
            !expanded && (
              <StyledTooltip title={'Position is close to expiration'} placement={'top'}>
                <WarningBubble>!</WarningBubble>
              </StyledTooltip>
            )
          )}
          <MarginRowInner>
            <Top>
              <MarketSection>
                <Market>
                  <Type type={MarginPositionType.LONG}>LONG</Type> {cleanCurrencySymbol(row.heldToken)} ({longLeverage}
                  x)
                </Market>
                <Market>
                  <Type type={MarginPositionType.SHORT}>SHORT</Type>{' '}
                  {row.heldToken.symbol === row.market.primaryToken.symbol
                    ? cleanCurrencySymbol(row.market.secondaryToken)
                    : cleanCurrencySymbol(row.market.primaryToken)}{' '}
                  ({shortLeverage}x)
                </Market>
              </MarketSection>
              <ProfitLossWrapper>
                <ProfitLoss isPositive={row.profitPercentage.greaterThanOrEqual('0')}>
                  {row.profitPercentage.greaterThanOrEqual('0') ? '+' : ''}
                  {row.profitPercentage.toFixed(2)}%
                </ProfitLoss>
              </ProfitLossWrapper>
              <LiquidationWrapper>
                <Liquidation>
                  {row.status === MarginPositionStatus.Closed ? (
                    'CLOSED'
                  ) : row.status === MarginPositionStatus.Expired ? (
                    'EXPIRED'
                  ) : row.status === MarginPositionStatus.Liquidated ? (
                    'LIQUIDATED'
                  ) : (
                    <>
                      {row.liquidationPrice.equalTo('0') ? '-' : formatAmount(row.liquidationPrice)}{' '}
                      <Unit>{row.market.secondary}</Unit>
                    </>
                  )}
                </Liquidation>
              </LiquidationWrapper>
            </Top>
            <Bottom>
              <BottomSizeEquity>
                <BottomRow>
                  <BottomLabel>Size:</BottomLabel>
                  <BottomValue>
                    {formatAmount(row.heldAmount)} {cleanCurrencySymbol(row.heldToken)}
                  </BottomValue>
                </BottomRow>
                <BottomRow>
                  <BottomLabel>Equity:</BottomLabel>
                  <BottomValue>
                    {formatAmount(row.equityHeldAmount)} {cleanCurrencySymbol(row.heldToken)}
                  </BottomValue>
                </BottomRow>
                <BottomRow>
                  <BottomLabel>Expires:</BottomLabel>
                  <BottomValue>
                    {row.status === MarginPositionStatus.Open
                      ? row.expirationTimestamp
                        ? toDate(row.expirationTimestamp)
                        : '-'
                      : MarginPositionStatusUtil.toUiString(row.status)}
                  </BottomValue>
                  {showCollateralWarning ? (
                    <StyledTooltip title={'Position has collateral that can be withdrawn'} placement={'top'}>
                      <InnerWarningBubble>!</InnerWarningBubble>
                    </StyledTooltip>
                  ) : (
                    showExpirationWarning && (
                      <StyledTooltip title={'Position is close to expiration'} placement={'top'}>
                        <InnerWarningBubble>!</InnerWarningBubble>
                      </StyledTooltip>
                    )
                  )}
                </BottomRow>
              </BottomSizeEquity>
              <BottomManagePosition
                closed={
                  row.status === MarginPositionStatus.Closed ||
                  row.status === MarginPositionStatus.Expired ||
                  row.status === MarginPositionStatus.Liquidated
                }
              >
                {row.status === MarginPositionStatus.Open && (
                  <CloseButton
                    onClick={e => {
                      e.stopPropagation()
                      setManagePositionState(row)
                    }}
                  >
                    Manage Position
                  </CloseButton>
                )}
              </BottomManagePosition>
              <BottomCloseButton
                closed={
                  row.status === MarginPositionStatus.Closed ||
                  row.status === MarginPositionStatus.Expired ||
                  row.status === MarginPositionStatus.Liquidated
                }
              >
                {row.status === MarginPositionStatus.Open ? (
                  <CloseButton
                    onClick={e => {
                      e.stopPropagation()
                      !isClosing && onClosePosition()
                    }}
                  >
                    {isClosing ? (
                      <SpinnerWrapper>
                        <CircularProgress />
                      </SpinnerWrapper>
                    ) : (
                      <div>Close Position</div>
                    )}
                  </CloseButton>
                ) : (
                  row.isLiquidatedAndHasCollateral && (
                    <CloseButton
                      onClick={e => {
                        e.stopPropagation()
                        !isClosing && onWithdrawCollateralFromLiquidation()
                      }}
                    >
                      {isClosing ? (
                        <SpinnerWrapper>
                          <CircularProgress />
                        </SpinnerWrapper>
                      ) : (
                        <div>Withdraw Collateral</div>
                      )}
                    </CloseButton>
                  )
                )}
              </BottomCloseButton>
            </Bottom>
          </MarginRowInner>
        </MarginRowWrapper>
      )
    } else {
      return (
        <DataRow
          key={index}
          columns={[
            {
              width: 20,
              textAlign: 'left',
              type: 'longShortMarket',
              data: {
                longAsset: row.heldToken,
                shortAsset:
                  row.heldToken.symbol === row.market.primaryToken.symbol
                    ? row.market.secondaryToken
                    : row.market.primaryToken,
                leverage: row.leverage,
                type: row.positionType,
              },
            },
            {
              width: 17,
              textAlign: 'left',
              type: 'pnlToggle',
              data: row.profitPercentage,
              data2: profitAmount,
              otherData: {
                profitPct: profitPctWithoutFees,
                profit: profitAmount.add(feeValue),
                showProfitWithFees: showProfitWithFees,
              },
              token: row.heldToken,
            },
            {
              width: 23,
              textAlign: 'right',
              type: 'sizeEquity',
              data: row.heldAmount,
              token: row.heldToken,
              data2: row.equityHeldAmount,
              token2: row.heldToken,
            },
            {
              width: 23,
              textAlign: 'right',
              type: 'liqOpen',
              data: row.liquidationPrice,
              token: row.market.primaryToken,
              data2: row.openPrice,
              token2: row.market.secondaryToken,
            },
            /*{width: 15, textAlign: 'right', type: 'stopLoss', data: row.status, hideOnMobile: true}, {width: 15, textAlign: 'right', type: 'takeProfit', data: row.status, hideOnMobile: true},*/
            {
              width: 17,
              textAlign: 'right',
              type: isClosing && row.status === MarginPositionStatus.Open ? 'loading' : 'time',
              data: row.status,
              date: row.status === MarginPositionStatus.Open ? row.expirationTimestamp : undefined,
              showExpirationWarning: showExpirationWarning,
              showCollateralWarning: showCollateralWarning,
            },
          ]}
          contentHeight={36}
          expandedHeight={80}
          expandedContent={
            <ExpandedMarginInfo style={{ fontSize: 12 }}>
              <ExpandedMarginInfoSection>
                <ExpandedInfoTitle>Interest Earned</ExpandedInfoTitle>
                <ExpandedInfoContent>
                  {formatAmount(row.interestEarned)} {cleanCurrencySymbol(row.interestEarned.currency)}
                </ExpandedInfoContent>
              </ExpandedMarginInfoSection>
              <ExpandedMarginInfoSection>
                <ExpandedInfoTitle>Interest Owed</ExpandedInfoTitle>
                <ExpandedInfoContent>
                  {formatAmount(row.interestOwed)} {cleanCurrencySymbol(row.interestOwed.currency)}
                </ExpandedInfoContent>
              </ExpandedMarginInfoSection>
              <StyledTooltip
                title={`The net annualized interest rate between the interest earned on held assets and the interest paid on borrowed assets since the position has been opened`}
                placement={'top'}
              >
                <ExpandedMarginInfoSection>
                  <ExpandedInfoTitle>Net Interest Rate</ExpandedInfoTitle>
                  <ExpandedInfoContent>
                    {row.netInterest.greaterThanOrEqual(0) ? '+' : ''}
                    {row.netInterest.toFixed(2)}% APR
                  </ExpandedInfoContent>
                </ExpandedMarginInfoSection>
              </StyledTooltip>
              <ExpandedMarginInfoSection>
                <ExpandedInfoTitle>Margin Deposit</ExpandedInfoTitle>
                <ExpandedInfoContent>
                  {formatAmount(row.marginDeposit)} {cleanCurrencySymbol(row.marginDeposit.currency)}
                </ExpandedInfoContent>
              </ExpandedMarginInfoSection>
              {row.status === MarginPositionStatus.Open ? (
                <ClosePositionButton
                  onClick={e => {
                    e.stopPropagation()
                    onClosePosition()
                  }}
                  disabled={isClosing}
                >
                  Close Position
                </ClosePositionButton>
              ) : row.isLiquidatedAndHasCollateral ? (
                <ClosePositionButton
                  onClick={e => {
                    e.stopPropagation()
                    onWithdrawCollateralFromLiquidation()
                  }}
                  disabled={isClosing}
                >
                  Withdraw Collateral
                </ClosePositionButton>
              ) : (
                <div />
              )}
              {row.status === MarginPositionStatus.Open && (
                <ManagePositionButton
                  onClick={e => {
                    e.stopPropagation()
                    setManagePositionState(row)
                  }}
                >
                  Manage Position
                </ManagePositionButton>
              )}
            </ExpandedMarginInfo>
          }
          expanded={expanded}
          // toggleExpanded={() =>
          //   !(
          //     row.status === MarginPositionStatus.Closed ||
          //     (row.status === MarginPositionStatus.Expired && !row.isLiquidatedAndHasCollateral)
          //   ) && toggleExpanded(wrapperRef)
          // }
          toggleExpanded={() => toggleExpanded(wrapperRef)}
          isFaded={isFaded}
          pending={isClosing}
        />
      )
    }
  },
  marginRowComparator,
)
MarginRow.displayName = 'MarginRow'

const PendingRow = ({ position, isMobile }: { position: DerivedSubmittedMarginPosition; isMobile: boolean }) => {
  const longLeverage = position.leverage.toFixed(2)
  const shortLeverage = position.leverage.subtract('1').toFixed(2)
  if (isMobile) {
    return (
      <MarginRowWrapper expanded={false} isFaded={true}>
        <MarginRowInner>
          <Top>
            <MarketSection>
              <Market>
                <Type type={MarginPositionType.LONG}>LONG</Type> {cleanCurrencySymbol(position.heldToken)} (
                {longLeverage}
                x)
              </Market>
              <Market>
                <Type type={MarginPositionType.SHORT}>SHORT</Type>{' '}
                {position.heldToken?.symbol === position.market.primaryToken.symbol
                  ? cleanCurrencySymbol(position.market.secondaryToken)
                  : cleanCurrencySymbol(position.market.primaryToken)}{' '}
                ({shortLeverage}x)
              </Market>
            </MarketSection>
            <ProfitLossWrapper>
              <ProfitLoss isPositive={null}>-%</ProfitLoss>
            </ProfitLossWrapper>
            <LiquidationWrapper>
              <SpinnerWrapper>
                <CircularProgress />
              </SpinnerWrapper>
            </LiquidationWrapper>
          </Top>
          <Bottom>
            <BottomDataItem>
              <BottomLabel>Size -</BottomLabel>
              <BottomValue>- {cleanCurrencySymbol(position.longAsset)}</BottomValue>
            </BottomDataItem>
            <BottomDataItem>
              <BottomLabel>Open -</BottomLabel>
              <BottomValue>- {cleanCurrencySymbol(position.longAsset)}</BottomValue>
            </BottomDataItem>
          </Bottom>
        </MarginRowInner>
      </MarginRowWrapper>
    )
  } else {
    return (
      <DataRow
        key={0}
        columns={[
          {
            width: 20,
            textAlign: 'left',
            type: 'longShortMarket',
            data: {
              longAsset: position.heldToken,
              shortAsset:
                position.heldToken?.symbol === position.market.primaryToken.symbol
                  ? position.market.secondaryToken
                  : position.market.primaryToken,
              leverage: position.leverage,
              type: MarginPositionType.LONG,
            },
          },
          {
            width: 17,
            textAlign: 'left',
            type: 'pnlToggle',
            data: undefined,
            data2: undefined,
            otherData: {
              profitPct: ZERO_PERCENT,
              profit: ZERO_FRACTION,
              showProfitWithFees: false,
            },
          },
          {
            width: 23,
            textAlign: 'right',
            type: 'sizeEquity',
            data: undefined,
            token: position.longAsset,
            data2: undefined,
            token2: position.heldToken,
          },
          {
            width: 23,
            textAlign: 'right',
            type: 'liqOpen',
            data: undefined,
            token: position.longAsset,
            data2: undefined,
            token2: position.shortAsset,
          },
          /*{width: 15, textAlign: 'right', type: 'stopLoss', data: row.status, hideOnMobile: true},
           {width: 15, textAlign: 'right', type: 'takeProfit', data: row.status, hideOnMobile: true},*/
          {
            width: 17,
            textAlign: 'right',
            type: 'loading',
            data: undefined,
          },
        ]}
        contentHeight={36}
        expandedHeight={80}
        expandedContent={undefined}
        expanded={false}
        toggleExpanded={undefined}
        isFaded={true}
        pending={true}
      />
    )
  }
}

export interface ClosePositionProps {
  showConfirm: boolean
  tradeToConfirm: Trade<Currency, Currency, TradeType> | undefined
  attemptingTxn: boolean
  tradeErrorMessage: string | undefined
  txHash: string | undefined
  handleTrade: (() => void) | undefined
  isWithdrawCollateral?: boolean | undefined
  closingPosition: MarginPosition | undefined
}

const THREE_DAYS_AGO = new Date().getTime() - 3 * 24 * 60 * 60 * 1000

const widths = {
  widths: [15, 12, 16, 16, 8],
  starts: [0, 20, 44, 67, 92],
  mobileWidths: [30, 20, 25],
  mobileStarts: [0, 45, 75],
}

export default function Positions({ positions, loading }: { positions: MarginPosition[]; loading: boolean }) {
  const [expandedIndex, setExpandedIndex] = useState(-1)
  const [expandedRef, setExpandedRef] = useState<null | MutableRefObject<any>>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [showProfitWithFees, setShowProfitWithFees] = useState(true)
  const { submittedMarginPosition } = useDerivedTradeInfo()
  const { onChangeSubmittedMarginPosition } = useTradeActionHandlers()
  const [selectedManagePositionId, setSelectedManagePositionId] = useState<string | undefined>(undefined)
  const [closingPositions, setClosingPositions] = useState<string[]>([]) // position IDs

  const addClosingPosition = useCallback((positionId: string) => {
    setClosingPositions(prev => [...prev, positionId])
  }, [])

  const removeClosingPosition = useCallback((positionId: string) => {
    setClosingPositions(prev => prev.filter(id => id !== positionId))
  }, [])

  const positionMap = useMemo(() => {
    return positions.reduce<Record<string, MarginPosition>>((memo, position) => {
      memo[position.id] = position
      return memo
    }, {})
  }, [positions])

  useEffect(() => {
    isLoading && !loading && setIsLoading(false)
  }, [loading, isLoading])
  //TODO - add a spinner in the upper right that displays whenever new content is loading in

  const [
    {
      showConfirm,
      tradeToConfirm,
      tradeErrorMessage,
      attemptingTxn,
      txHash,
      handleTrade,
      isWithdrawCollateral,
      closingPosition,
    },
    setCloseState,
  ] = useState<ClosePositionProps>({
    showConfirm: false,
    tradeToConfirm: undefined,
    attemptingTxn: false,
    tradeErrorMessage: undefined,
    txHash: undefined,
    handleTrade: undefined,
    closingPosition: undefined,
  })

  useEffect(() => {
    if (positions.length > 0 && submittedMarginPosition) {
      const shouldClearPosition = submittedMarginPosition.isOpening
        ? positions.some(position => {
            return JSBI.equal(
              position.marginAccount.accountNumber,
              JSBI.BigInt(submittedMarginPosition.tradeAccountNumber),
            )
          })
        : positions.some(position => {
            return (
              JSBI.equal(
                position.marginAccount.accountNumber,
                JSBI.BigInt(submittedMarginPosition.tradeAccountNumber),
              ) && position.status !== MarginPositionStatus.Open
            )
          })
      if (shouldClearPosition) {
        onChangeSubmittedMarginPosition(null)
      }
    }
  }, [onChangeSubmittedMarginPosition, positions, submittedMarginPosition])

  const [allowedSlippage] = useUserSlippageTolerance()
  const handleConfirmDismiss = useCallback(() => {
    setCloseState(prev => {
      return {
        showConfirm: false,
        tradeToConfirm: prev.tradeToConfirm,
        attemptingTxn: prev.attemptingTxn,
        tradeErrorMessage: prev.tradeErrorMessage,
        txHash: prev.txHash,
        handleTrade: prev.handleTrade,
        closingPosition: prev.closingPosition,
      }
    })
  }, [])

  const handleAcceptChanges = useCallback(() => {
    setCloseState(prev => ({
      txHash,
      attemptingTxn,
      showConfirm,
      closingPosition,
      tradeToConfirm: prev.tradeToConfirm,
      tradeErrorMessage: prev.tradeErrorMessage,
      handleTrade: prev.handleTrade,
    }))
  }, [txHash, attemptingTxn, showConfirm, closingPosition])

  const marginInformation = useMemo<MarginInformation | undefined>(() => {
    return {
      longAsset: undefined,
      shortAsset: undefined,
      leverage: closingPosition?.leverage ?? new Fraction('1'),
      marginDeposit: closingPosition?.marginDeposit,
      marginHeldAmount: closingPosition?.heldAmount,
      isClosing: true,
    }
  }, [closingPosition])

  const selectedPosition = useMemo(
    () => (selectedManagePositionId ? positionMap[selectedManagePositionId] : undefined),
    [positionMap, selectedManagePositionId],
  )

  const onConfirm = useCallback(() => {
    if (handleTrade) {
      handleTrade()
    } else {
      console.error('undefined handleTrade')
    }
  }, [handleTrade])

  const { x, y } = useContext(PageSizeContext)
  const isMobile = x < 950
  const isTablet = 660 < x && x < 950

  //TODO !important! - show more and more info as the screen gets wider, less as it gets smaller. On a phone, just show the trade done and the date, maybe a colored dot for the status, and if room maybe the type of trade. On the largest screen show everything including the Good Til and fill percentage and anything else you'd hide in a dropdown on click.
  return (
    <OrdersWrapper>
      {selectedPosition && (
        <ManagePositionModal
          isOpen={!!selectedManagePositionId}
          position={selectedPosition}
          onDismissMemoized={() => setSelectedManagePositionId(undefined)}
          addClosingMemoized={addClosingPosition}
          removeClosingMemoized={removeClosingPosition}
          setClosePositionState={setCloseState}
        />
      )}
      <ConfirmTradeModal
        title={'Close Position'}
        isOpen={showConfirm}
        trade={tradeToConfirm}
        originalTrade={tradeToConfirm}
        onAcceptChanges={handleAcceptChanges}
        attemptingTxn={attemptingTxn}
        txHash={txHash}
        allowedSlippage={allowedSlippage}
        onConfirm={onConfirm}
        tradeErrorMessage={tradeErrorMessage}
        onDismiss={handleConfirmDismiss}
        leverage={undefined}
        isWithdrawCollateral={isWithdrawCollateral ?? false}
        marginInformation={marginInformation}
      />
      <ColumnTitles>
        <Column width={20} tabletWidth={45} mobileWidth={45} textAlign={'left'}>
          <StyledTooltip title={'The asset on the long and the short side of the position'} placement={'top'}>
            <span>Market / Side</span>
          </StyledTooltip>
        </Column>
        <Column width={17} tabletWidth={23} mobileWidth={23} textAlign={'left'} smallTextAlign={'center'}>
          <StyledTooltip
            title={
              'The profit or loss on your position. It reflects the difference in value between the current market price and the price at which the position was opened, as well as the expected price impact of closing the position.'
            }
            placement={'top'}
          >
            <span style={{ cursor: 'default' }}>P&L</span>
          </StyledTooltip>
          <FeesWrapper>
            <FeesButton
              selected={false}
              onClick={(event: React.MouseEvent) => {
                setShowProfitWithFees(!showProfitWithFees)
                event.stopPropagation()
              }}
            >
              Fees{' '}
              <Slider isOn={showProfitWithFees}>
                <SliderDot isOn={showProfitWithFees} />
              </Slider>
            </FeesButton>
          </FeesWrapper>
        </Column>
        <Column width={23} textAlign={'right'} hideOnMobile={true} hideOnTablet={true} customTabletWidth={950}>
          <StyledTooltip
            title={`The size of your position is the total value including all borrowed assets, the equity is just the portion that isn't borrowed and will be received when the position is closed.`}
            placement={'top'}
          >
            <span>Size / Equity</span>
          </StyledTooltip>
        </Column>
        <Column width={23} tabletWidth={33} mobileWidth={33} textAlign={'right'}>
          <HideOnTablet>
            <StyledTooltip
              title={
                'The liquidation price is the price at which your position will be automatically closed in order to reimburse lenders you are borrowing from. The open price was the market price at which you opened your position.'
              }
              placement={'top'}
            >
              <span>Liq / Open</span>
            </StyledTooltip>
          </HideOnTablet>
          <ShowOnTablet>
            <StyledTooltip
              title={
                'The liquidation price is the price at which your position will be automatically closed in order to reimburse lenders you are borrowing from.'
              }
              placement={'top'}
            >
              <span>Liquidation</span>
            </StyledTooltip>
          </ShowOnTablet>
        </Column>
        {/*<Column width={15} textAlign={'right'} hideOnMobile={true}>
               Stop Loss
               </Column>
               <Column width={15} textAlign={'right'} hideOnMobile={true}>
               Take Profit
               </Column>*/}
        <Column width={17} mobileWidth={20} textAlign={'right'} hideOnTablet={true} hideOnMobile={true}>
          <StyledTooltip title={'How long until your position expires and is automatically closed.'} placement={'top'}>
            <span>Expires</span>
          </StyledTooltip>
        </Column>
      </ColumnTitles>
      <DataRows>
        {isLoading ? (
          <LoaderWrapper>
            <TableLoader
              rows={3}
              height={isMobile ? 28 : 30}
              spacing={isMobile ? 50 : 53}
              marginTop={10}
              isMobile={isMobile}
              isTablet={isTablet}
              widths={widths}
            />
          </LoaderWrapper>
        ) : (
          <>
            {submittedMarginPosition?.isOpening && (
              <PendingRow position={submittedMarginPosition} isMobile={isMobile} />
            )}
            {positions.map(
              (position, index) =>
                (position.closeTimestamp === undefined ||
                  position.closeTimestamp.getTime() > THREE_DAYS_AGO ||
                  position.isLiquidatedAndHasCollateral) && (
                  <MarginRow
                    row={position}
                    isMobile={isMobile}
                    index={index}
                    key={index}
                    setManagePositionState={position => setSelectedManagePositionId(position.id)}
                    setClosePositionState={setCloseState}
                    expanded={index === expandedIndex}
                    addClosing={addClosingPosition}
                    removeClosing={removeClosingPosition}
                    isClosing={closingPositions.some(p => p === position.id)}
                    toggleExpanded={(ref: MutableRefObject<any>) => {
                      if (expandedIndex === index) {
                        setExpandedIndex(-1) // TODO - determine if this is the best approach or if it will unintentionally unexpand the tab for people
                      } else {
                        setExpandedRef(ref)
                        setExpandedIndex(index)
                      }
                    }}
                    showProfitWithFees={showProfitWithFees}
                  />
                ),
            )}
          </>
        )}
        <HistoryLinkWrapper>
          <NavLink id={`trade-nav-link`} to={'/history'}>
            View All Positions
          </NavLink>
        </HistoryLinkWrapper>
      </DataRows>
    </OrdersWrapper>
  )
}
