import { GmxAums } from './useGmxProtocol'
import { useMemo } from 'react'
import { Fraction } from '@dolomite-exchange/sdk-core'

export default function useMidAum(aum: GmxAums | undefined): Fraction | undefined {
  return useMemo(() => {
    if (!aum) {
      return undefined
    }
    return aum.bidAum.add(aum.askAum).divide(2)
  }, [aum])
}
