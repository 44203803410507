import { darken, lighten } from 'polished'
import React, { useMemo } from 'react'
import { Activity } from 'react-feather'
import styled from 'styled-components/macro'
import { useWalletModalToggle } from '../../state/application/hooks'
import { isTransactionRecent, useAllTransactions } from '../../state/transactions/hooks'
import { TransactionDetails } from '../../state/transactions/reducer'
import { shortenAddress } from '../../utils'
import { ButtonSecondary } from '../Button'

import Loader from '../Loader'

import { RowBetween } from '../Row'
import WalletModal from '../WalletModal'
import { INVALID_CHAIN, useActiveWeb3React } from '../../hooks'
import WalletIcon from '../WalletIcon'
import Web3StatusConnectButton from '../Web3StatusConnectButton'
import useENSName from '../../hooks/useENSName'
import useENSAvatar from '../../hooks/useENSAvatar'

const Web3StatusGeneric = styled(ButtonSecondary)`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  align-items: center;
  padding: 0.5rem;
  border-radius: 5px;
  cursor: pointer;
  user-select: none;

  :focus {
    outline: none;
  }
`
const Web3StatusError = styled(Web3StatusGeneric)`
  background-color: ${({ theme }) => theme.red1};
  color: ${({ theme }) => theme.white};
  font-weight: 500;

  :hover,
  :focus {
    background-color: ${({ theme }) => darken(0.1, theme.red1)};
  }
`

const Web3StatusConnected = styled(Web3StatusGeneric)<{ pending?: boolean }>`
    background-color: ${({ pending, theme }) => (pending ? theme.primary1 : theme.bg2)};
        /*border: 1px solid ${({ pending, theme }) => (pending ? theme.primary1 : theme.bg3)};*/
    color: ${({ pending, theme }) => (pending ? theme.white : theme.text1)};
    font-weight: 500;

    :hover {
        background-color: ${({ pending, theme }) =>
          pending ? darken(0.05, theme.primary1) : lighten(0.05, theme.bg2)};

        :focus {
                /*border: 1px solid ${({ pending, theme }) =>
                  pending ? darken(0.1, theme.primary1) : darken(0.1, theme.bg3)};*/
        }
    }
`

const Text = styled.p`
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 0.5rem 0 0.25rem;
  font-size: 1rem;
  width: fit-content;
  font-weight: 500;

  @media screen and (max-width: 345px) {
    font-size: 0.8rem;
    line-height: 1.4rem;
  }
`

const NetworkIcon = styled(Activity)`
  margin-left: 0.25rem;
  margin-right: 0.5rem;
  width: 16px;
  height: 16px;
`

// we want the latest one to come first, so return negative if `a` is after `b`
function newTransactionsFirst(a: TransactionDetails, b: TransactionDetails) {
  return b.addedTime - a.addedTime
}

const EnsIcon = styled.img`
  border-radius: 50%;
  height: 24px;
  width: 24px;
`

function Web3StatusInner() {
  const { account, wallet, error: web3Error } = useActiveWeb3React()

  const { ensName } = useENSName(account)
  const { avatar: ensProfilePhoto } = useENSAvatar(account)

  const allTransactions = useAllTransactions()

  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions)
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst)
  }, [allTransactions])

  const pending = useMemo(() => sortedRecentTransactions.filter(tx => !tx.receipt).map(tx => tx.hash), [
    sortedRecentTransactions,
  ])

  const hasPendingTransactions = !!pending.length
  const toggleWalletModal = useWalletModalToggle()

  if (web3Error) {
    return (
      <Web3StatusError onClick={toggleWalletModal}>
        <NetworkIcon />
        <Text>{web3Error.message === INVALID_CHAIN ? 'Wrong Network' : 'Error'}</Text>
      </Web3StatusError>
    )
  } else if (account) {
    return (
      <Web3StatusConnected id='web3-status-connected' onClick={toggleWalletModal} pending={hasPendingTransactions}>
        {hasPendingTransactions ? (
          <RowBetween>
            <Text>{pending.length} Pending</Text> <Loader stroke='white' />
          </RowBetween>
        ) : (
          <>
            {ensProfilePhoto ? <EnsIcon alt={'Profile Photo'} src={ensProfilePhoto} /> : null}
            <Text>{ensName ?? shortenAddress(account)}</Text>
          </>
        )}
        {!hasPendingTransactions ? <WalletIcon wallet={wallet} /> : null}
      </Web3StatusConnected>
    )
  } else {
    return <Web3StatusConnectButton />
  }
}

export default function Web3Status() {
  const allTransactions = useAllTransactions()

  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions)
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst)
  }, [allTransactions])

  const pending = useMemo(() => sortedRecentTransactions.filter(tx => !tx.receipt).map(tx => tx.hash), [
    sortedRecentTransactions,
  ])
  const confirmed = useMemo(() => sortedRecentTransactions.filter(tx => tx.receipt).map(tx => tx.hash), [
    sortedRecentTransactions,
  ])

  return (
    <>
      <Web3StatusInner />
      <WalletModal pendingTransactions={pending} confirmedTransactions={confirmed} />
    </>
  )
}
