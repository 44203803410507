import { ARB, ARB_ISOLATION_MODE_ADDRESSES, ChainId } from '../../index'
import { Token } from '@dolomite-exchange/v2-sdk'
import { calculateARBProxyVaultAddress } from '../../../utils/isolation/calculateProxyVaultAddress'
import { getOptionally } from '../special-assets-util-functions'
import { SpecialAsset, SpecialAssetSymbol } from '../special-assets'

export const D_ARB_SPECIAL_ASSET: () => SpecialAsset = () => ({
  symbol: SpecialAssetSymbol.dARB,
  safeSymbol: SpecialAssetSymbol.dARB,
  cleanSymbol: 'vARB',
  cleanName: 'Vote-Enabled ARB',
  hasExternalRewards: false,
  documentationUrl: 'https://docs.dolomite.io/integrations/arbitrum-varb',
  isIsolationMode: true,
  isBorrowingEnabled: false,
  chainIdToAddressMap: ARB_ISOLATION_MODE_ADDRESSES,
  isolationModeInfo: {
    getWrappedToken: (chainId: ChainId) =>
      getOptionally(
        ARB_ISOLATION_MODE_ADDRESSES[chainId],
        address => new Token(chainId, address, 18, 'dARB', 'Dolomite Isolation: Arbitrum'),
      ),
    getUnderlyingToken: getArbToken,
    getUnderlyingSpenderToken: getArbToken,
    allowableCollateralTokens: () => [],
    allowableDebtTokens: () => [],
    remapAccountAddress: calculateARBProxyVaultAddress,
    isAsync: false,
  },
})

function getArbToken(chainId: ChainId): Token | undefined {
  return ARB[chainId]
}
