import { Fraction, Percent } from '@dolomite-exchange/sdk-core'
import { ONE_FRACTION } from '../constants'

export default function calculateLiquidationThreshold(
  minCollateralization: Fraction | undefined,
  marginPremium: Fraction | undefined,
): Percent | undefined {
  if (!minCollateralization || !marginPremium) {
    return undefined
  }

  const liquidationThresholdFraction = minCollateralization.multiply(marginPremium.add(ONE_FRACTION)).invert()
  return Percent.fromFraction(liquidationThresholdFraction)
}
