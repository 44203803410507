import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Redirect, RouteComponentProps } from 'react-router-dom'
import { AppDispatch } from '../../state'
import { ApplicationModal, setOpenModal } from '../../state/application/actions'

// Redirects to Trade but only replace the pathname
export function RedirectPathToBalancesOnly({ location }: RouteComponentProps): JSX.Element {
  return <Redirect to={{ ...location, pathname: '/balances' }} />
}

interface RedirectToSwapProps {
  outputCurrency: string
  inputCurrency?: string
}

// Redirects from the /Trade/:outputCurrency path to the /Trade?outputCurrency=:outputCurrency format
export function OpenClaimAddressModalAndRedirectToSwap(props: RouteComponentProps): JSX.Element {
  const dispatch = useDispatch<AppDispatch>()
  useEffect(() => {
    dispatch(setOpenModal(ApplicationModal.ADDRESS_CLAIM))
  }, [dispatch])
  return <RedirectPathToBalancesOnly {...props} />
}
