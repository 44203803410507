import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import { PanelSubtitle, PanelTitle } from './index'
import WarningRoundedIcon from '@material-ui/icons/WarningRounded'
import { StyledTooltipWithIcon } from '../../components/common/StyledTooltip'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useIsTransactionPending } from '../../state/transactions/hooks'
import { useOToken } from '../../hooks/Tokens'
import { useTokenBalance } from '../../state/wallet/hooks'
import { useActiveWeb3React } from '../../hooks'
import { useClaimLiquidityMiningRewards } from '../../hooks/useLiquidityMining'
import { useAllLiquidityMiningClaims } from '../../types/liquidityMiningClaimData'
import { CurrencyAmount } from '@dolomite-exchange/v2-sdk'
import { BIG_INT_ZERO, ZERO_FRACTION } from '../../constants'
import { formatAmount } from '../../utils/formatAmount'
import InfoRoundedIcon from '@material-ui/icons/InfoRounded'

const ClaimOARB = styled.div`
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
  width: 500px;
  height: auto;
  border-radius: 8px;
  padding: 25px 35px 20px;
  background-color: ${({ theme }) => theme.bg1};
  display: inline-block;
  vertical-align: top;
  margin: 20px;
  text-align: left;

  @media screen and (max-width: 650px) {
    width: 100%;
    margin: 20px 0 0;
    padding: 20px 25px 15px;
  }
`

const DocsLink = styled.div`
  width: 100%;
  text-align: left;
  margin-top: -10px;
  margin-bottom: 16px;
  font-size: 12px;

  a {
    color: ${({ theme }) => theme.text1} !important;
    text-decoration: none !important;
    font-weight: 700;

    :hover {
      color: ${({ theme }) => theme.text2} !important;
    }
  }
`

const DescriptionText = styled.div`
  font-size: 12px;
  font-weight: 100;
  line-height: 14px;
  color: ${({ theme }) => theme.text2};
  width: 100%;
  position: relative;
  margin: -10px auto 10px;
  padding: 12px 16px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.bg2};

  svg {
    margin-right: 5px;
    margin-bottom: -3px;
    font-size: 24px;
    color: ${({ theme }) => theme.text3};
    display: inline-block;
    vertical-align: top;
  }

  a {
    color: ${({ theme }) => theme.text2};
    font-weight: 600;
  }

  @media screen and (max-width: 650px) {
  }
`

const DescriptionContent = styled.div`
  width: calc(100% - 30px);
  display: inline-block;
  vertical-align: top;
`

const ClaimableSection = styled.div`
  width: 100%;
  margin-top: 10px;
`

const ClaimableLeft = styled.div`
  width: 50%;
  display: inline-block;
  vertical-align: top;
  /*margin-bottom: 10px;*/
`

const ClaimableRight = styled.div`
  width: 50%;
  display: inline-block;
  vertical-align: top;
  text-align: right;
`

const ClaimableTitle = styled.div`
  font-size: 13px;
  font-weight: 600;
  color: ${({ theme }) => theme.text2};

  svg {
    transform: translateY(1.5px);
  }

  @media screen and (max-width: 650px) {
    font-size: 12px;
  }
`

const ClaimableAmount = styled.div`
  font-size: 20px;
  font-weight: 400;
  color: ${({ theme }) => theme.text1};
  margin-bottom: 12px;

  @media screen and (max-width: 650px) {
    font-size: 17px;
  }
`

const ClaimButton = styled.div<{ isClaimable: boolean }>`
  border-radius: 5px;
  background-color: ${({ theme, isClaimable }) => (isClaimable ? theme.green2 : theme.bg4)};
  color: ${({ theme }) => theme.text1};
  font-size: 14px;
  font-weight: 600;
  padding: 8px 20px;
  cursor: ${({ isClaimable }) => (isClaimable ? 'pointer' : 'default')};
  float: right;
  width: 100%;
  text-align: center;
  line-height: 22px;
  margin-top: 0;
  margin-bottom: 10px;
  height: 38px;

  :hover {
    background-color: ${({ theme, isClaimable }) => (isClaimable ? theme.green1 : theme.bg4)};
  }

  > svg,
  > div {
    color: ${({ theme }) => theme.text1} !important;
    font-size: 20px;
    height: 20px !important;
    width: 20px !important;
  }
`

const SEASON_NUMBER = 0

type AccountToEpochToClaimMap = Record<string, Record<number, boolean> | undefined>

function ClaimPanel() {
  const { account } = useActiveWeb3React()
  const [isClaiming, setIsClaiming] = React.useState(false)
  const [awaitingSignature, setAwaitingSignature] = useState(false)
  const [pendingHash, setPendingHash] = useState<string | undefined>(undefined)
  const [accountToEpochToClaimedMap, setAccountToEpochToClaimedMap] = useState<AccountToEpochToClaimMap>({})
  const {
    data: { unfinalizedClaims },
  } = useAllLiquidityMiningClaims(account, SEASON_NUMBER, useOToken())
  const oToken = useOToken()
  const { callback: claimCallback } = useClaimLiquidityMiningRewards(unfinalizedClaims, oToken)
  const userOTokenBalance = useTokenBalance(account, oToken)

  const totalClaimableAmount = useMemo(() => {
    if (!oToken) {
      return undefined
    }

    return unfinalizedClaims?.reduce((total, unclaimedEpoch) => {
      if (!accountToEpochToClaimedMap[account ?? '']?.[unclaimedEpoch.epoch]) {
        return total.add(unclaimedEpoch.amount)
      }
      return total
    }, CurrencyAmount.fromRawAmount(oToken, BIG_INT_ZERO))
  }, [account, accountToEpochToClaimedMap, oToken, unfinalizedClaims])
  const isClaimable = useMemo(() => totalClaimableAmount?.asFraction.greaterThan(ZERO_FRACTION) ?? false, [
    totalClaimableAmount,
  ])

  const isHashPending = useIsTransactionPending(pendingHash)
  useEffect(() => {
    if (!isHashPending && pendingHash) {
      setPendingHash(undefined)
      setAccountToEpochToClaimedMap(prevState => {
        const unclaimedEpochMap = unfinalizedClaims?.reduce<Record<number, boolean>>((map, unclaimedEpoch) => {
          return {
            ...map,
            [unclaimedEpoch.epoch]: true,
          }
        }, {})
        return {
          ...prevState,
          [account ?? '']: {
            ...prevState[account ?? ''],
            ...unclaimedEpochMap,
          },
        }
      })
      setIsClaiming(false)
    }
  }, [account, isHashPending, pendingHash, unfinalizedClaims])

  const onClaim = useCallback(() => {
    if (!claimCallback || awaitingSignature) {
      return
    }

    setIsClaiming(true)
    setAwaitingSignature(true)
    claimCallback()
      .then(hash => {
        setPendingHash(hash)
        setAwaitingSignature(false)
      })
      .catch(() => {
        setAwaitingSignature(false)
        setIsClaiming(false)
      })
  }, [awaitingSignature, claimCallback])

  return (
    <ClaimOARB>
      <PanelTitle>2. Claim oARB</PanelTitle>
      <PanelSubtitle>
        Earned oARB is accrued over the course of a week, and becomes claimable after the week ends. You will only be
        able to view the oARB you have accrued at the end of the week.
      </PanelSubtitle>
      <DocsLink>
        Read more about oARB in{' '}
        <a href={'https://docs.dolomite.io/oarb-incentives-program'} target={'_blank'} rel={'noreferrer'}>
          our documentation
        </a>
        .
      </DocsLink>
      <DescriptionText style={{ marginBottom: '23px' }}>
        <InfoRoundedIcon />
        <DescriptionContent style={{ marginTop: '4px' }}>
          Your claimable oARB balance will update once a week on Thursdays.
        </DescriptionContent>
      </DescriptionText>
      <DescriptionText>
        <WarningRoundedIcon />
        <DescriptionContent>
          {`You are free to claim oARB any time after it becomes claimable, but vesting of oARB is first come, first served, and can run out. It is recommended that you claim and vest regularly.`}
          &nbsp;&nbsp;
          <a href={'https://docs.dolomite.io/oarb-incentives-program'} target={'_blank'} rel={'noreferrer'}>
            Learn more
          </a>
        </DescriptionContent>
      </DescriptionText>
      <ClaimableSection>
        <ClaimableLeft>
          <ClaimableTitle>
            Your oARB Balance
            <StyledTooltipWithIcon tooltipText={'The amount of oARB you have already claimed and in your wallet'} />
          </ClaimableTitle>
          <ClaimableAmount>{formatAmount(userOTokenBalance) || '-'} oARB</ClaimableAmount>
        </ClaimableLeft>
        {/*<ClaimableRight>
          <ClaimableTitle>
            oARB Earned This Week
            <StyledTooltipWithIcon
              tooltipText={
                'The amount of oARB you have earned so far this week. At the end of this week, it will be added to your claimable oARB'
              }
            />
          </ClaimableTitle>*/}
        {/*<ClaimableSubtitle>
            The amount of oARB you have earned so far this week. At the end of this week, it will be added to your
            claimable oARB
          </ClaimableSubtitle>*/}
        {/*<ClaimableAmount>1,237.45 oARB</ClaimableAmount>
        </ClaimableRight>*/}
        <ClaimableRight>
          <ClaimableTitle>
            Claimable
            <StyledTooltipWithIcon
              tooltipText={
                'The oARB you have earned from previous weeks that is currently claimable. Claim it to add it to your balance.'
              }
            />
          </ClaimableTitle>
          {/*<ClaimableSubtitle>
            The oARB you have earned from previous weeks that is currently claimable. Claim it to add it to your
            balance.
          </ClaimableSubtitle>*/}
          <ClaimableAmount>{formatAmount(totalClaimableAmount)} oARB</ClaimableAmount>
        </ClaimableRight>
        <ClaimButton isClaimable={isClaimable} onClick={isClaimable ? onClaim : undefined}>
          {isClaiming ? <CircularProgress /> : isClaimable ? 'Claim' : 'No oARB to claim'}
        </ClaimButton>
      </ClaimableSection>
    </ClaimOARB>
  )
}

export default React.memo(ClaimPanel)
