import React from 'react'
import ContentLoader from 'react-content-loader'
import styled, { keyframes } from 'styled-components/macro'
const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`
const StyledSVG = styled.svg<{ size: string; stroke?: string }>`
  animation: 2s ${rotate} linear infinite;
  height: ${({ size }) => size};
  width: ${({ size }) => size};
  path {
    stroke: ${({ stroke, theme }) => stroke ?? theme.primary1};
  }
`
export default function Loader({
  size = '16px',
  stroke,
  ...rest
}: {
  size?: string
  stroke?: string
  [k: string]: any
}) {
  return (
    <StyledSVG viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' size={size} stroke={stroke} {...rest}>
      <path
        d='M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 9.27455 20.9097 6.80375 19.1414 5'
        strokeWidth='2.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </StyledSVG>
  )
}
const primary = '#606375'
const secondary = '#2f2f40'
const TableWrapper = styled.div<{ height?: number; top?: number }>`
  width: 100%;
  margin-top: ${({ top }) => `${top}px`};
  height: ${({ height }) => (height ? `${height}px` : '100%')};
`
interface Widths {
  widths: (number | string)[]
  starts: (number | string)[]
  tabletWidths?: number[]
  tabletStarts?: number[]
  mobileWidths?: number[]
  mobileStarts?: number[]
}
export const TableLoader = ({
  rows = 3,
  height,
  spacing,
  wrapperHeight,
  marginTop,
  isMobile,
  isTablet,
  widths,
}: {
  rows: number
  height: number
  spacing: number
  wrapperHeight?: number
  marginTop?: number
  isMobile?: boolean
  isTablet?: boolean
  widths: Widths
}) => {
  const sizes =
    isTablet && widths.tabletWidths
      ? widths.tabletWidths
      : isMobile && widths.mobileWidths
      ? widths.mobileWidths
      : widths.widths
  const starts =
    isTablet && widths.tabletStarts
      ? widths.tabletStarts
      : isMobile && widths.mobileStarts
      ? widths.mobileStarts
      : widths.starts
  return (
    <TableWrapper height={wrapperHeight} top={marginTop}>
      <ContentLoader
        style={{ width: '100%', height: '100%' }}
        speed={2}
        foregroundColor={primary}
        backgroundColor={secondary}
      >
        {[...Array(rows)].map((_, i) => (
          <TableRow key={`loaderRow-${i}`} sizes={sizes} starts={starts} y={i * spacing} height={height} />
        ))}
      </ContentLoader>
    </TableWrapper>
  )
}
const TableRow = ({
  sizes,
  starts,
  y,
  height,
}: {
  sizes: (number | string)[]
  starts: (number | string)[]
  y: number
  height: number
}) => {
  return (
    <>
      {sizes.map((size, i) => {
        const width = typeof size === 'number' ? `${size}%` : size
        const start = starts[i]
        const x = typeof start === 'number' ? `${start}%` : start
        return <rect key={`loaderCol-${i}`} x={x} y={y} rx='5' ry='5' width={width} height={height} />
      })}
    </>
  )
}
export const SimpleLoader = ({
  width,
  height,
  spacing,
  marginTop,
}: {
  width: number
  height: number
  spacing?: number
  marginTop?: number
}) => {
  return (
    <ContentLoader
      style={{ width: width, height: spacing ?? height }}
      speed={2}
      foregroundColor={primary}
      backgroundColor={secondary}
      viewBox={`0 0 ${width} ${spacing ?? height}`}
    >
      <rect x='0' y={marginTop ?? 0} rx='5' ry='5' width={width} height={height} />
    </ContentLoader>
  )
}
