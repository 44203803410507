import { SpecialAsset, SpecialAssetSymbol } from '../special-assets'
import { USDC } from '../../tokens/USDC'
import mapChainIdMapValues from '../../../utils/mapChainIdMapValues'

export const USDC_SPECIAL_ASSET: () => SpecialAsset = () => ({
  symbol: SpecialAssetSymbol.USDC,
  safeSymbol: SpecialAssetSymbol.USDC,
  cleanSymbol: 'USDC',
  cleanName: 'USD Coin',
  hasExternalRewards: false,
  documentationUrl: undefined,
  isIsolationMode: false,
  isBorrowingEnabled: true,
  chainIdToAddressMap: mapChainIdMapValues(USDC, t => t.address),
  isolationModeInfo: undefined,
})
