import { createAction } from '@reduxjs/toolkit'

export enum Field {
  INPUT = 'INPUT',
  OUTPUT = 'OUTPUT',
}

export interface SubmittedMarginPosition {
  tradeAccountNumber: string
  isOpening: boolean
  longAsset: string
  shortAsset: string
  heldToken: string
  leverage: string
  leverageDenominator: string
  positionSize: string
  positionSizeDenominator: string
  depositSize: string
  depositSizeDenominator: string
  liquidationPrice: string | undefined
  liquidationPriceDenominator: string
  openPrice: string
  openPriceDenominator: string
  openTimestamp: number
}

export const selectCurrency = createAction<{ field: Field; currencyId: string }>('Trade/selectCurrency')
export const switchCurrencies = createAction('Trade/switchCurrencies')
export const typeInput = createAction<{ field: Field; typedValue: string }>('Trade/typeInput')
export const replaceSwapState = createAction<{
  field: Field
  typedValue: string
  inputCurrencyId?: string
  outputCurrencyId?: string
  recipient: string | null
  typedLeverage: string
  submittedMarginPosition: SubmittedMarginPosition | null
}>('Trade/replaceSwapState')
export const setRecipient = createAction<{ recipient: string | null }>('Trade/setRecipient')
export const setLeverage = createAction<{ typedLeverage: string }>('Trade/setLeverage')
export const setSubmittedMarginPosition = createAction<{ submittedMarginPosition: SubmittedMarginPosition | null }>(
  'Trade/setSubmittedMarginPosition',
)
