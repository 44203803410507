import { gql } from '@apollo/client'
import { createCurrencyAmount, createFractionUSD, TokenDayData as TokenDayDataGql } from './gqlTypeHelpers'
import { tokenDayDataGql } from './queryObjects'
import { CurrencyAmount, Fraction, Token } from '@dolomite-exchange/sdk-core'
import JSBI from 'jsbi'
import { useMemo } from 'react'
import { useGraphqlResult } from '../state/graphql/hooks'
import { GraphqlClientType } from '../state/graphql/actions'
import { RefreshFrequency, useBlockTimestamp } from '../state/chain/hooks'

const TOKEN_DAY_DATA_DATA_GQL = gql`
    query tokenDayDatas($blockNumber: Int!, $tokenAddress: String!, $startTimestamp: Int!, $endTimestamp: Int!) {
        tokenDayDatas(
            orderBy: dayStartUnix,
            orderDirection: desc,
            where: { token: $tokenAddress, dayStartUnix_lte: $endTimestamp, dayStartUnix_gt: $startTimestamp },
            block: { number_gte: $blockNumber }
            first: 365
        ) {
            ${tokenDayDataGql()}
        }
    }
`

interface TokenDayDataResponse {
  tokenDayDatas: TokenDayDataGql[] | undefined
}

export interface TokenDayData {
  dayStartUnix: number
  token: Token
  dailyAmmTradeVolumeUSD: Fraction
  dailyBorrowVolumeUSD: Fraction
  dailyLiquidationVolumeUSD: Fraction
  dailyTradeVolumeUSD: Fraction
  dailyVaporizationVolumeUSD: Fraction
  dailyAmmTradeVolumeToken: CurrencyAmount<Token>
  dailyBorrowVolumeToken: CurrencyAmount<Token>
  dailyLiquidationVolumeToken: CurrencyAmount<Token>
  dailyTradeVolumeToken: CurrencyAmount<Token>
  dailyVaporizationVolumeToken: CurrencyAmount<Token>
  ammLiquidityUSD: Fraction
  borrowLiquidityUSD: Fraction
  supplyLiquidityUSD: Fraction
  ammLiquidityToken: CurrencyAmount<Token>
  borrowLiquidityToken: CurrencyAmount<Token>
  supplyLiquidityToken: CurrencyAmount<Token>
  dailyAllTransactionCount: JSBI
  dailyAmmTradeCount: JSBI
  dailyLiquidationCount: JSBI
  dailyTradeCount: JSBI
  dailyVaporizationCount: JSBI
  openPriceUSD: Fraction
  highPriceUSD: Fraction
  lowPriceUSD: Fraction
  closePriceUSD: Fraction
}

export function useTokenDayDataData(
  token: Token | undefined,
): {
  loading: boolean
  error: boolean
  data: TokenDayData[] | undefined
} {
  const timestamp = useBlockTimestamp()

  const startTimestamp = useMemo(() => {
    return timestamp
      .div('86400')
      .sub('31')
      .mul('86400')
      .toNumber()
  }, [timestamp])

  const endTimestamp = useMemo(() => {
    return timestamp
      .div('86400')
      .sub('0')
      .mul('86400')
      .toNumber()
  }, [timestamp])

  const variables = useMemo(() => {
    if (!token) {
      return undefined
    }
    return {
      tokenAddress: token.address.toLowerCase(),
      startTimestamp,
      endTimestamp,
    }
  }, [token, endTimestamp, startTimestamp])

  const queryResult = useGraphqlResult<TokenDayDataResponse>(
    GraphqlClientType.Dolomite,
    TOKEN_DAY_DATA_DATA_GQL.loc!.source.body,
    variables,
    RefreshFrequency.Medium,
  )

  return useMemo(() => {
    const { loading, error, result } = queryResult
    const anyLoading = Boolean(loading)
    const anyError = Boolean(error)

    if (!token) {
      return {
        loading: false,
        error: true,
        data: undefined,
      }
    }

    const tokenDayDatas = result?.tokenDayDatas?.map<TokenDayData>(data => {
      return {
        dayStartUnix: data.dayStartUnix,
        token: token,
        dailyAmmTradeVolumeUSD: createFractionUSD(data.dailyAmmTradeVolumeUSD),
        dailyBorrowVolumeUSD: createFractionUSD(data.dailyBorrowVolumeUSD),
        dailyLiquidationVolumeUSD: createFractionUSD(data.dailyLiquidationVolumeUSD),
        dailyTradeVolumeUSD: createFractionUSD(data.dailyTradeVolumeUSD),
        dailyVaporizationVolumeUSD: createFractionUSD(data.dailyVaporizationVolumeUSD),
        dailyAmmTradeVolumeToken: createCurrencyAmount(token, data.dailyAmmTradeVolumeToken),
        dailyBorrowVolumeToken: createCurrencyAmount(token, data.dailyBorrowVolumeToken),
        dailyLiquidationVolumeToken: createCurrencyAmount(token, data.dailyLiquidationVolumeToken),
        dailyTradeVolumeToken: createCurrencyAmount(token, data.dailyTradeVolumeToken),
        dailyVaporizationVolumeToken: createCurrencyAmount(token, data.dailyVaporizationVolumeToken),
        ammLiquidityUSD: createFractionUSD(data.ammLiquidityUSD),
        borrowLiquidityUSD: createFractionUSD(data.borrowLiquidityUSD),
        supplyLiquidityUSD: createFractionUSD(data.supplyLiquidityUSD),
        ammLiquidityToken: createCurrencyAmount(token, data.ammLiquidityToken),
        borrowLiquidityToken: createCurrencyAmount(token, data.borrowLiquidityToken),
        supplyLiquidityToken: createCurrencyAmount(token, data.supplyLiquidityToken),
        dailyAllTransactionCount: JSBI.BigInt(data.dailyAllTransactionCount),
        dailyAmmTradeCount: JSBI.BigInt(data.dailyAmmTradeCount),
        dailyLiquidationCount: JSBI.BigInt(data.dailyLiquidationCount),
        dailyTradeCount: JSBI.BigInt(data.dailyTradeCount),
        dailyVaporizationCount: JSBI.BigInt(data.dailyVaporizationCount),
        openPriceUSD: createFractionUSD(data.openPriceUSD),
        highPriceUSD: createFractionUSD(data.highPriceUSD),
        lowPriceUSD: createFractionUSD(data.lowPriceUSD),
        closePriceUSD: createFractionUSD(data.closePriceUSD),
      }
    })

    const sorted = tokenDayDatas?.sort((a, b) => a.dayStartUnix - b.dayStartUnix)

    return {
      loading: anyLoading,
      error: anyError,
      data: sorted,
    }
  }, [queryResult, token])
}
