import { AssetDenomination, BalanceCheckFlag, NativeCurrency, Token, Trade, TradeType } from '@dolomite-exchange/v2-sdk'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components/macro'
import ReactGA from 'react-ga'
import { Text } from 'rebass'
import { ButtonError } from '../../components/Button'
import { GreyCard } from '../../components/Card'
import { AutoColumn } from '../../components/Column'
import ConfirmTradeModal from '../../components/Trade/ConfirmTradeModal'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import confirmPriceImpactWithoutFee from '../../components/Trade/confirmPriceImpactWithoutFee'
import {
  BottomGrouping,
  ConnectBtn,
  ConnectWalletButton,
  TradeCallbackError,
  TradeWrapper,
  Wrapper,
} from '../../components/Trade/styleds'
import Web3Status from '../../components/Web3Status'

import { useActiveWeb3React } from '../../hooks'
import { useTradeCallback } from '../../hooks/useTradeCallback'
import { getTradeVersion } from '../../hooks/useToggledVersion'
import { Field, SubmittedMarginPosition } from '../../state/trade/actions'
import {
  tryParseAmount,
  useDefaultsFromURL,
  useDerivedTradeInfo,
  useTradeActionHandlers,
  useTradeState,
} from '../../state/trade/hooks'
import { useExpertModeManager, useUserSingleHopOnly, useUserSlippageTolerance } from '../../state/user/hooks'
import { TYPE } from '../../theme'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { computeTradePriceBreakdown, warningSeverity } from '../../utils/prices'
import JSBI from 'jsbi'
import { Currency, Ether, Fraction } from '@dolomite-exchange/sdk-core'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import { useTranslation } from 'react-i18next'
import cleanCurrencySymbol from '../../utils/cleanCurrencySymbol'
import { Input as NumericalInput } from '../../components/NumericalInput'
import EditIcon from '@material-ui/icons/Edit'
import { ONE_FRACTION } from '../../constants'
import useIpGeolocation from '../../hooks/useIpGeolocation'
import AutorenewIcon from '@material-ui/icons/Autorenew'
import { usePair } from '../../data/Reserves'
import { Market } from '../../types/Market'
import { StyledTooltip, StyledTooltipWithIcon } from '../../components/common/StyledTooltip'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { MarginInformation } from '../../components/Trade/TradeModalFooter'
import { useDefaultMarginAccount } from '../../types/marginAccount'
import { formatAmount } from '../../utils/formatAmount'

enum TradeMode {
  SPOT = 0,
  MARGIN = 1,
}

// const PriceRow = styled.div`
//   width: 100%;
//   display: inline-block;
//   vertical-align: top;
// `

// const SpotMarginTabs = styled.div`
//   width: 100%;
//   margin-top: 10px;
//   margin-left: auto;
//   margin-right: auto;
//
//   span {
//     color: ${({ theme }) => theme.text1};
//     font-family: 'Open Sans', sans-serif;
//   }
//
//   > div > div {
//     height: 36px;
//   }
//
//   > div > div > span {
//     background-color: ${({ theme }) => theme.text2};
//     margin-top: -20px;
//   }
//
//   > div > div > div {
//     height: 30px;
//   }
//
//   > div > div > div > button {
//     text-transform: none;
//     min-height: 30px;
//     font-size: 16px;
//     font-weight: 400;
//   }
// `

const SwitchArrow = styled.div`
  text-align: center;
  font-size: 18px;
  margin-bottom: -12px;
  color: ${({ theme }) => theme.text3};

  svg {
    color: ${({ theme }) => theme.text3};
    transition: color 0.1s ease-in-out;
  }

  + div > div > div:nth-of-type(1) {
    padding-top: 0;
  }
`

const SwitchArrowInner = styled.div`
  width: fit-content;
  height: 24px;
  display: inline-block;
  vertical-align: top;
  transition: transform 0.2s ease-in-out;
  cursor: pointer;

  :hover {
    transform: rotate(180deg);

    svg {
      color: ${({ theme }) => theme.text2};
    }
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    :hover {
      transform: none;
  
      svg {
        color: inherit;
      }
    }
  `};
`

const StyledTabs = styled(({ ...rest }) => <Tabs classes={{ indicator: 'indicator' }} {...rest} />)`
  font-family: 'Open Sans', sans-serif !important;
  justify-content: normal !important;
  min-height: 0 !important;

  > div > div {
    justify-content: center;
  }

  .indicator {
    background-color: #f9f9f9 !important;
    bottom: 0 !important;
    display: none !important;
    height: 1.4px !important;
    transform: scale(0.5, 1) !important;
  }
`

const StyledTab = styled(({ ...rest }) => (
  <Tab
    classes={{
      root: 'root',
      selected: 'selected',
    }}
    {...rest}
  />
))`
  font-family: 'Open Sans', sans-serif !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  margin-left: 6px !important;
  margin-right: 6px !important;
  text-transform: capitalize !important;
  padding: 0 !important;
  padding-bottom: 3.5px !important;
  padding-left: 4px !important;
  padding-right: 4px !important;
  max-width: 264px;
  min-width: 0 !important;
  color: #606375 !important;
  min-height: 0 !important;

  ${({ selected }) =>
    selected &&
    `
    color: #f9f9f9 !important;
  `}
  .selected {
    color: #f9f9f9 !important;
  }

  .root span {
    font-size: 18px !important;
  }
`

const LongShortPanelWrapper = styled.div``

const LongShortInfo = styled.div`
  width: 100%;
  font-size: 11px;
  font-weight: 500;
  text-align: center;
  margin-top: 18px;
  margin-bottom: -6px;
  cursor: pointer;

  a {
    color: ${({ theme }) => theme.text3} !important;
    text-decoration: none !important;

    :hover {
      color: ${({ theme }) => theme.text1} !important;
    }
  }
`

const LongShortPanel = styled.div`
  width: 100%;
  text-align: center;
  margin: 5px 0 -5px;
`

const LongShortSection = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 30%;
  text-align: center;
  cursor: default;
`

const LongShortType = styled.div<{ type: string }>`
  width: 100%;
  text-align: center;
  color: ${({ theme, type }) => (type === 'SHORT' ? theme.red1 : theme.green1)};
  font-size: 12px;
`

const LongShortToken = styled.div`
  width: 100%;
  text-align: center;
  color: ${({ theme }) => theme.text1};
  font-size: 18px;
  font-weight: 500;
`

const MarginAmountAlt = styled.div`
  font-size: 12px;
  margin: -8px 0 -3px;
  color: ${({ theme }) => theme.text3};
  font-weight: 600;
`

const LongShortSwitchButton = styled.div`
  display: inline-block;
  vertical-align: bottom;
  width: fit-content;
  margin: 0 7px -4px;
  cursor: pointer;
  transform: rotate(45deg);

  svg {
    color: ${({ theme }) => theme.text3};
    transition: color 0.1s ease-in-out;

    :hover {
      color: ${({ theme }) => theme.text2};
    }
  }
`

const LeverageSelectorWrapper = styled.div<{ expanded: boolean }>`
  /*width: calc(100% - 16px);*/
  width: 100%;
  height: auto;
  max-height: ${({ expanded }) => (expanded ? '100px' : '30px')};
  overflow: hidden;
  transition: max-height 0.2s ease-in-out;
  margin-bottom: 5px;

  > div:nth-of-type(2) {
    opacity: ${({ expanded }) => (expanded ? '1' : '0')};
    transition: opacity 0.2s ease-in-out;
  }
`

const LeverageTitle = styled.div`
  width: 100%;
  height: 30px;
  text-align: left;
  cursor: pointer;
`

const LeverageTitleText = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  display: inline-block;
  vertical-align: top;
`

const CurrentLeverage = styled.div`
  font-size: 10px;
  font-weight: 700;
  line-height: 18px;
  border-radius: 5px;
  height: 18px;
  display: inline-block;
  vertical-align: top;
  margin-left: 5px;
  margin-top: 3px;
  background-color: ${({ theme }) => theme.text3};
  padding: 0 5px;
`

// const LeverageSectionIcon = styled.div`
//   height: 30px;
//   float: right;
//   color: ${({ theme }) => theme.text3};
//   margin-top: 2px;
// `

const LeverageOptions = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  flex-basis: 21%;
  justify-content: space-between;
  position: relative;
`

const LeverageOptionWrapper = styled.div<{ numberPerLine: number }>`
    position: relative;
        /*width: ${({ numberPerLine }) => (numberPerLine === 3 ? '33%' : '25%')};*/
    width: 18.75%;
    height: 30px;
    text-align: center;
`

const LeverageOption = styled.div<{ selected: boolean }>`
    font-size: 11px;
    font-weight: 700;
    text-align: center;
        /*line-height: ${({ selected }) => (selected ? '24px' : '22px')};*/
    line-height: 30px;
    border-radius: 5px;
        /*height: ${({ selected }) => (selected ? '24px' : '24px')};*/
    height: 30px;
    /*margin: 3px;*/
    display: inline-block;
    vertical-align: top;
    background-color: ${({ theme, selected }) => (selected ? theme.text3 : theme.bg7)};
        /*border: ${({ theme, selected }) => (selected ? 'none' : `1px solid ${theme.text3}`)};*/
    cursor: ${({ selected }) => (selected ? 'default' : 'pointer')};
    /*width: calc(100% - 6px);*/
    width: 100%;

    :hover {
        background-color: ${({ theme }) => theme.text3};
    }
`

const EditIconWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  width: 100%;
  font-size: 11px;
  line-height: 30px;
  height: 30px;
  pointer-events: none;

  svg {
    margin: 0 auto;
    font-size: 20px;
    height: 30px;
  }
`

const TradeWarning = styled.div`
  width: 100%;
  text-align: center;
  color: ${({ theme }) => theme.red1};
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 2px;
`

/*const LeverageDescriptior = styled.div`
 width: 100%;
 color: ${({ theme }) => theme.text3};
 text-align: center;
 font-size: 11px;
 margin-bottom: 5px;
 margin-top: -5px;
 `*/

interface LeverageSelectorProps {
  maxLeverage: number
  currentLeverage: Fraction | undefined
  setCurrentLeverage: (leverage: string) => void
  fromTokenTicker: string | undefined
  toTokenTicker: string | undefined
}

const onCustomLeverageInput = (
  oldInput: string,
  newInput: string,
  maxLeverage: number,
  chainId: number,
  setCustomValue: (arg0: string) => void,
) => {
  const newInputFraction = tryParseAmount(newInput, Ether.onChain(chainId))?.asFraction
  if (!newInput) {
    setCustomValue(newInput)
  } else if (!newInputFraction) {
    setCustomValue('')
  } else if (newInputFraction.lessThanOrEqual(maxLeverage) && newInput.length < 7) {
    setCustomValue(newInput)
  }
}

const LeverageSelector = ({ maxLeverage, currentLeverage, setCurrentLeverage }: LeverageSelectorProps) => {
  const { t } = useTranslation()
  const [expanded] = useState(true)
  const [typingCustom, setTypingCustom] = useState(false)
  const [customValue, setCustomValue] = useState(t(' '))
  const { chainId } = useActiveWeb3React()

  return (
    <LeverageSelectorWrapper expanded={expanded}>
      <LeverageTitle /*onClick={() => setExpanded(!expanded)}*/>
        <LeverageTitleText>{t('leverage')}</LeverageTitleText>
        <CurrentLeverage>
          {currentLeverage?.equalTo('0') ? t('none') : `${currentLeverage?.toSignificant(8)}x`}
        </CurrentLeverage>
        <StyledTooltipWithIcon
          tooltipText={
            'The leverage of your margin trade determines the amount of assets that will be purchased with borrowed assets to open your position. The higher the leverage, the higher your exposure to fluctuations in price. Higher leverage positions will gain value faster with increases in price, but also lose value faster with decreases in price.'
          }
          verticalAlign={'middle'}
        />
        {/* TODO - if you change to a tab system (with 'spot' and 'margin'), move this to be next to 'Position Size'. Or maybe move it there regardless */}
        {/*<LeverageSectionIcon>{expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</LeverageSectionIcon>*/}
      </LeverageTitle>
      <LeverageOptions>
        {Array.from(new Array(maxLeverage - 1)).map((x, index) => (
          <LeverageOptionWrapper key={index} numberPerLine={maxLeverage < 4 ? 4 : 3}>
            <LeverageOption
              selected={currentLeverage?.equalTo(index + 2) ?? false}
              onClick={() => setCurrentLeverage((index + 2).toString())}
            >
              {index + 2 > maxLeverage ? t('custom') : `${index + 2}x`}
            </LeverageOption>
          </LeverageOptionWrapper>
        ))}
        <LeverageOptionWrapper key={maxLeverage} numberPerLine={maxLeverage < 4 ? 4 : 3}>
          <LeverageOption
            selected={typingCustom || !Number.isInteger(Number.parseFloat(currentLeverage?.toFixed(6) ?? '1'))}
          >
            <NumericalInput
              className={'token-amount-input'}
              value={customValue}
              onUserInput={val => {
                onCustomLeverageInput(customValue, val, maxLeverage, chainId, setCustomValue)
              }}
              fontSize={'inherit'}
              unit={'x'}
              onFocus={() => {
                setTypingCustom(true)
                setCustomValue('')
              }}
              onBlur={() => {
                setTypingCustom(false)
                if (customValue !== '' && !customValue.startsWith('0')) {
                  setCustomValue(`${customValue}x`)
                  setCurrentLeverage(customValue)
                } else {
                  setCustomValue(' ')
                  setCurrentLeverage('2')
                }
              }}
              maxDecimals={4}
              style={{
                width: '100%',
                background: 'none',
                textAlign: 'center',
                fontFamily: 'inherit',
                fontSize: 'inherit',
                fontWeight: 'inherit',
                lineHeight: 'inherit',
              }}
            />
          </LeverageOption>
          <EditIconWrapper>
            {!typingCustom && (customValue === '' || customValue === ' ') && <EditIcon />}
          </EditIconWrapper>
        </LeverageOptionWrapper>
      </LeverageOptions>
      {/* TODO - maybe put text here to clarify the trade? Like 'Short ETH / LONG USDC'? Or maybe put it next to the leverage amount in the title row? Don't show stablecoins actually, only show both tokens if neither are stablecoins, I think otherwise it will confuse people that you're shorting USDC */}
    </LeverageSelectorWrapper>
  )
}

interface InputFieldInfoInterface {
  typedInput: string
  selectedField: string
}

export interface DerivedSubmittedMarginPosition {
  tradeAccountNumber: JSBI
  market: Market
  isOpening: boolean // true if opening, false if closing
  longAsset: NativeCurrency | Token | undefined
  shortAsset: NativeCurrency | Token | undefined
  heldToken: NativeCurrency | Token | undefined
  leverage: Fraction
  positionSize: Fraction | undefined
  depositSize: Fraction | undefined
  liquidationPrice: Fraction | undefined
  openPrice: Fraction | undefined
  openTimestamp: Date
}

export default function TradePanel(): JSX.Element {
  const { t } = useTranslation()
  const { chainId } = useActiveWeb3React()

  useDefaultsFromURL(true)

  const [selectedTradeMode] = useState<TradeMode>(TradeMode.SPOT)
  const options = [t('swap'), t('margin')]

  const { account } = useActiveWeb3React()

  // toggle wallet when disconnected
  // const toggleWalletModal = useWalletModalToggle()

  // for expert mode
  // const toggleSettings = useToggleSettingsMenu()
  const [isExpertMode] = useExpertModeManager()

  // get custom setting values for user
  const [allowedSlippage] = useUserSlippageTolerance()
  const allowedSlippageJSBI = useMemo(() => JSBI.BigInt(allowedSlippage), [allowedSlippage])

  // Trade state
  const { independentField, typedValue, typedLeverage } = useTradeState()
  const leverage = useMemo(() => {
    return tryParseAmount(typedLeverage, Ether.onChain(chainId))?.asFraction ?? ONE_FRACTION
  }, [typedLeverage, chainId])

  const {
    v2Trade: trade,
    dolomiteBalances,
    parsedAmount,
    currencies,
    inputError: tradeInputError,
    tradeAccountNumber,
    marginDeposit,
  } = useDerivedTradeInfo()

  const inputCurrencySymbol = currencies[Field.INPUT]?.symbol
  const outputCurrencySymbol = currencies[Field.OUTPUT]?.symbol
  const [, pair] = usePair(currencies[Field.INPUT], currencies[Field.OUTPUT])
  const marketPrice = useMemo(() => {
    if (!pair || !currencies[Field.INPUT] || !currencies[Field.OUTPUT]) return undefined
    return currencies[Field.OUTPUT]?.symbol === pair.token0.symbol
      ? pair.reserve0.asFraction.divide(pair.reserve1.asFraction)
      : pair.reserve1.asFraction.divide(pair.reserve0.asFraction)
  }, [pair, currencies])

  const parsedAmounts = {
    [Field.INPUT]: independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
    [Field.OUTPUT]: independentField === Field.OUTPUT ? parsedAmount : trade?.outputAmount,
  }

  const [inputFieldInfo, setInputFieldInfo] = useState<InputFieldInfoInterface>({
    typedInput: parsedAmount?.toExact() ?? '',
    selectedField: independentField,
  })

  const {
    onCurrencySelection,
    onUserInput,
    onChangeLeverage,
    onSwitchTokens,
    onChangeSubmittedMarginPosition,
  } = useTradeActionHandlers()
  const isValid = !tradeInputError
  const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT

  useEffect(() => {
    setInputFieldInfo({
      typedInput: typedValue,
      selectedField: independentField,
    })
  }, [independentField, typedLeverage, typedValue, inputCurrencySymbol, outputCurrencySymbol])

  const typingTimer = useRef<NodeJS.Timeout>()
  const doneTypingInterval = 200

  const handleTypeInput = useCallback(
    (value: string) => {
      setInputFieldInfo({
        typedInput: value,
        selectedField: Field.INPUT,
      })
      if (typingTimer.current) {
        clearTimeout(typingTimer.current)
      }
      typingTimer.current = setTimeout(() => {
        onUserInput(Field.INPUT, value)
      }, doneTypingInterval)
    },
    [onUserInput],
  )
  const handleTypeOutput = useCallback(
    (value: string) => {
      setInputFieldInfo({
        typedInput: value,
        selectedField: Field.OUTPUT,
      })
      if (typingTimer.current) {
        clearTimeout(typingTimer.current)
      }
      typingTimer.current = setTimeout(() => {
        onUserInput(Field.OUTPUT, value)
      }, doneTypingInterval)
    },
    [onUserInput],
  )

  // modal and loading
  const [{ showConfirm, tradeToConfirm, tradeErrorMessage, attemptingTxn, txHash }, setTradeState] = useState<{
    showConfirm: boolean
    tradeToConfirm: Trade<Currency, Currency, TradeType> | undefined
    attemptingTxn: boolean
    tradeErrorMessage: string | undefined
    txHash: string | undefined
  }>({
    showConfirm: false,
    tradeToConfirm: undefined,
    attemptingTxn: false,
    tradeErrorMessage: undefined,
    txHash: undefined,
  })

  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: parsedAmounts[dependentField]?.toSignificant(6) ?? '',
  }

  const route = trade?.route
  const userHasSpecifiedInputOutput = Boolean(
    currencies[Field.INPUT] && currencies[Field.OUTPUT] && parsedAmounts[independentField]?.greaterThan(JSBI.BigInt(0)),
  )
  const noRoute = !route

  const maxAmountInput = maxAmountSpend(dolomiteBalances[Field.INPUT])
  const maxAmountOutput = maxAmountSpend(dolomiteBalances[Field.OUTPUT])
  const atMaxAmountInput = Boolean(maxAmountInput && parsedAmounts[Field.INPUT]?.equalTo(maxAmountInput))
  const atMaxAmountOutput = Boolean(maxAmountOutput && parsedAmounts[Field.OUTPUT]?.equalTo(maxAmountOutput))

  const { error: ipError, isUnitedStatesIp } = useIpGeolocation()
  const defaultMarginAccount = useDefaultMarginAccount()

  const balanceCheckFlag = useMemo(() => {
    // When doing a spot trade, make sure neither account goes below 0. For margin trades, just the funding account
    // should be checked
    return leverage.equalTo(ONE_FRACTION) ? BalanceCheckFlag.Both : BalanceCheckFlag.OtherAccount
  }, [leverage])

  // the callback to execute the trade
  const { callback: tradeCallback, error: tradeCallbackError } = useTradeCallback(
    trade,
    allowedSlippageJSBI,
    tradeAccountNumber,
    defaultMarginAccount.accountNumber,
    AssetDenomination.Wei,
    true,
    true,
    balanceCheckFlag,
    true,
    marginDeposit,
    isUnitedStatesIp,
  )

  const { priceImpactWithoutFee } = useMemo(() => computeTradePriceBreakdown(trade), [trade])

  const [singleHopOnly] = useUserSingleHopOnly()

  const onConfirmTrade = useCallback(() => {
    if (priceImpactWithoutFee && !confirmPriceImpactWithoutFee(priceImpactWithoutFee)) {
      return
    }
    if (!tradeCallback) {
      return
    }
    setTradeState({
      attemptingTxn: true,
      tradeToConfirm,
      showConfirm,
      tradeErrorMessage: undefined,
      txHash: undefined,
    })
    let submittedMarginPosition: SubmittedMarginPosition | null = null
    if (leverage.greaterThan('1')) {
      if (pair && currencies[Field.OUTPUT] !== undefined && currencies[Field.INPUT] !== undefined) {
        const outputToken = currencies[Field.OUTPUT]
        const inputToken = currencies[Field.INPUT]
        if (outputToken && inputToken && marginDeposit && tradeToConfirm) {
          const positionSize = tradeToConfirm.outputAmount.wrapped.add(marginDeposit)
          submittedMarginPosition = {
            tradeAccountNumber: tradeAccountNumber.toString(),
            isOpening: leverage.greaterThan('1'),
            longAsset: outputToken.wrapped.address,
            shortAsset: inputToken.wrapped.address,
            heldToken: outputToken.wrapped.address,
            depositSize: marginDeposit.numerator.toString(),
            depositSizeDenominator: marginDeposit.denominator.toString(),
            leverage: leverage.numerator.toString(),
            leverageDenominator: leverage.denominator.toString(),
            liquidationPrice: undefined,
            liquidationPriceDenominator: '1',
            openPrice: tradeToConfirm.executionPrice.numerator.toString(),
            openPriceDenominator: tradeToConfirm.executionPrice.denominator.toString(),
            openTimestamp: new Date().getTime(),
            positionSize: positionSize.numerator.toString(),
            positionSizeDenominator: positionSize.denominator.toString(),
          }
        }
      }
    }

    tradeCallback()
      .then(hash => {
        setTradeState({
          attemptingTxn: false,
          tradeToConfirm,
          showConfirm,
          tradeErrorMessage: undefined,
          txHash: hash,
        })
        if (submittedMarginPosition) {
          const switchTabEvent = new Event('switchToMarginTab')
          document.dispatchEvent(switchTabEvent)
          onChangeSubmittedMarginPosition(submittedMarginPosition)
        }

        const action = leverage.greaterThan('1') ? 'Margin-Trade' : 'Spot-Trade'
        ReactGA.event({
          category: 'Trade',
          action: `${action} w/o Send`,
          label: [
            cleanCurrencySymbol(trade?.inputAmount.currency),
            cleanCurrencySymbol(trade?.outputAmount.currency),
            getTradeVersion(trade),
          ].join('/'),
        })

        ReactGA.event({
          category: 'Routing',
          action: singleHopOnly ? 'Trade with multihop disabled' : 'Trade with multihop enabled',
        })
      })
      .catch(error => {
        setTradeState({
          attemptingTxn: false,
          tradeToConfirm,
          showConfirm,
          tradeErrorMessage: error.message,
          txHash: undefined,
        })
      })
  }, [
    priceImpactWithoutFee,
    tradeCallback,
    tradeToConfirm,
    showConfirm,
    leverage,
    trade,
    singleHopOnly,
    pair,
    currencies,
    marginDeposit,
    onChangeSubmittedMarginPosition,
    tradeAccountNumber,
  ])

  // warnings on slippage
  const adjustedPriceImpactWithoutFee =
    priceImpactWithoutFee?.multiply(leverage).multiply(new Fraction(3, 2)) ?? undefined
  const priceImpactSeverity = warningSeverity(
    leverage && priceImpactWithoutFee && leverage.greaterThan(JSBI.BigInt(1))
      ? adjustedPriceImpactWithoutFee
      : priceImpactWithoutFee,
  )

  const handleConfirmDismiss = useCallback(() => {
    setTradeState({
      showConfirm: false,
      tradeToConfirm,
      attemptingTxn,
      tradeErrorMessage: tradeErrorMessage,
      txHash,
    })
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(independentField, '')
      setInputFieldInfo({
        typedInput: '',
        selectedField: independentField,
      })
    }
  }, [attemptingTxn, onUserInput, tradeErrorMessage, tradeToConfirm, txHash, independentField])

  const handleAcceptChanges = useCallback(() => {
    setTradeState({
      tradeToConfirm: trade,
      tradeErrorMessage: tradeErrorMessage,
      txHash,
      attemptingTxn,
      showConfirm,
    })
  }, [attemptingTxn, showConfirm, tradeErrorMessage, trade, txHash])

  const handleInputSelect = useCallback(
    inputCurrency => {
      onCurrencySelection(Field.INPUT, inputCurrency)
    },
    [onCurrencySelection],
  )

  const handleMaxInput = useCallback(() => {
    maxAmountInput && handleTypeInput(maxAmountInput.toExact())
  }, [maxAmountInput, handleTypeInput])

  const handleMaxMarginInput = useCallback(() => {
    maxAmountOutput && handleTypeOutput(maxAmountOutput.toExact())
  }, [maxAmountOutput, handleTypeOutput])

  const outputCurrencyBalance = dolomiteBalances[Field.OUTPUT]
  const handleClickOutputBalance = useCallback(() => {
    outputCurrencyBalance && handleTypeOutput(outputCurrencyBalance.toExact() || '0')
  }, [outputCurrencyBalance, handleTypeOutput])

  useEffect(() => {
    document.addEventListener('selectedInputBalance', handleMaxInput)
    return () => {
      document.removeEventListener('selectedInputBalance', handleMaxInput)
    }
  }, [handleMaxInput])

  useEffect(() => {
    document.addEventListener('selectedOutputBalance', handleClickOutputBalance)
    return () => {
      document.removeEventListener('selectedOutputBalance', handleClickOutputBalance)
    }
  }, [handleClickOutputBalance])

  const handleOutputSelect = useCallback(outputCurrency => onCurrencySelection(Field.OUTPUT, outputCurrency), [
    onCurrencySelection,
  ])

  const maxLeverage = 5

  const marginInformation = useMemo<MarginInformation | undefined>(() => {
    return leverage.greaterThan('1')
      ? {
          longAsset: currencies[Field.OUTPUT],
          shortAsset: currencies[Field.INPUT],
          leverage: leverage,
          marginDeposit: marginDeposit,
          marginHeldAmount: undefined,
          isClosing: false,
        }
      : undefined
  }, [leverage, currencies, marginDeposit])

  return (
    <TradeWrapper>
      <Wrapper id={'trade-page'}>
        <ConfirmTradeModal
          title={
            leverage.greaterThan('1') || selectedTradeMode === TradeMode.MARGIN ? 'Open Position' : 'Confirm Trade'
          }
          isOpen={showConfirm}
          trade={trade}
          originalTrade={tradeToConfirm}
          onAcceptChanges={handleAcceptChanges}
          attemptingTxn={attemptingTxn}
          txHash={txHash}
          allowedSlippage={allowedSlippage}
          onConfirm={onConfirmTrade}
          tradeErrorMessage={tradeErrorMessage}
          onDismiss={handleConfirmDismiss}
          leverage={leverage}
          isWithdrawCollateral={false}
          marginInformation={marginInformation}
        />
        <StyledTabs
          value={leverage.greaterThan('1') ? 1 : 0}
          onChange={(_: any, index: number) => onChangeLeverage('' + (index + 1))}
          indicatorColor={'primary'}
          textColor={'primary'}
        >
          {(options ?? []).map((option: string, index: number) => (
            <StyledTab key={`tradeHeader-${index}`} disableRipple label={option} />
          ))}
        </StyledTabs>
        <AutoColumn gap={'md'}>
          {typedLeverage === '1' ? (
            <CurrencyInputPanel
              label={
                leverage.greaterThan('1') || selectedTradeMode === TradeMode.MARGIN
                  ? t('tradeAmountEstimated')
                  : independentField === Field.OUTPUT && trade
                  ? t('fromEstimated')
                  : t('fromCapital')
              }
              value={
                inputFieldInfo.selectedField === Field.INPUT.toString()
                  ? inputFieldInfo.typedInput
                  : formattedAmounts[Field.INPUT]
              }
              showMaxButton={true}
              disableMaxButton={atMaxAmountInput}
              currency={currencies[Field.INPUT]}
              onUserInput={handleTypeInput}
              onMax={handleMaxInput}
              onCurrencySelect={handleInputSelect}
              otherCurrency={currencies[Field.OUTPUT]}
              disabled={leverage.greaterThan('1') || selectedTradeMode === TradeMode.MARGIN}
              isForTrading={true}
              id={'trade-currency-input'}
            />
          ) : (
            <LongShortPanelWrapper>
              <LongShortPanel>
                <StyledTooltip
                  title={`Your position gains value when the short asset (${cleanCurrencySymbol(
                    currencies[Field.INPUT],
                  )}) loses value against the long asset (${cleanCurrencySymbol(
                    currencies[Field.OUTPUT],
                  )}), or put differently, when the price of ${cleanCurrencySymbol(
                    currencies[Field.INPUT],
                  )} goes down in terms of ${cleanCurrencySymbol(currencies[Field.OUTPUT])}.`}
                  placement={'top'}
                >
                  <LongShortSection>
                    <LongShortType type={'SHORT'}>SHORT</LongShortType>
                    <LongShortToken>{cleanCurrencySymbol(currencies[Field.INPUT]) ?? '-'}</LongShortToken>
                  </LongShortSection>
                </StyledTooltip>
                <LongShortSwitchButton onClick={() => onSwitchTokens()}>
                  <AutorenewIcon />
                </LongShortSwitchButton>
                <StyledTooltip
                  title={`Your position gains value when the long asset (${cleanCurrencySymbol(
                    currencies[Field.OUTPUT],
                  )}) gains value against the short asset (${cleanCurrencySymbol(
                    currencies[Field.INPUT],
                  )}), or put differently, when the price of ${cleanCurrencySymbol(
                    currencies[Field.OUTPUT],
                  )} goes up in terms of ${cleanCurrencySymbol(currencies[Field.INPUT])}.`}
                  placement={'top'}
                >
                  <LongShortSection>
                    <LongShortType type={'LONG'}>LONG</LongShortType>
                    <LongShortToken>{cleanCurrencySymbol(currencies[Field.OUTPUT]) ?? '-'}</LongShortToken>
                  </LongShortSection>
                </StyledTooltip>
              </LongShortPanel>
              <LongShortInfo>
                <a href={'https://www.dolomite.io'} target={'blank'}>
                  Learn more about margin
                </a>
              </LongShortInfo>
            </LongShortPanelWrapper>
          )}
          {typedLeverage === '1' && (
            <SwitchArrow>
              <SwitchArrowInner>
                <ArrowDownwardIcon onClick={() => onSwitchTokens()} />
              </SwitchArrowInner>
            </SwitchArrow>
          )}
          <CurrencyInputPanel
            value={
              inputFieldInfo.selectedField === Field.OUTPUT.toString()
                ? inputFieldInfo.typedInput
                : formattedAmounts[Field.OUTPUT]
            }
            onUserInput={handleTypeOutput}
            label={
              leverage.greaterThan('1') || selectedTradeMode === TradeMode.MARGIN
                ? t('depositAmount')
                : independentField === Field.INPUT && trade
                ? t('toEstimated')
                : t('toCapital')
            }
            showMaxButton={leverage.greaterThan('1')}
            disableMaxButton={!leverage.greaterThan('1') || atMaxAmountOutput}
            onMax={handleMaxMarginInput}
            currency={currencies[Field.OUTPUT]}
            onCurrencySelect={handleOutputSelect}
            otherCurrency={currencies[Field.INPUT]}
            id={'trade-currency-output'}
            tooltipText={
              leverage.greaterThan('1') || selectedTradeMode === TradeMode.MARGIN
                ? 'When you open a margin position, you are borrowing assets from lenders, and using those assets to purchase the asset on the long side of your margin trade. In order to do this, you must deposit collateral which, in the case of liquidation, will be sold and used to reimburse lenders.'
                : undefined
            }
            isForTrading={true}
          />
          {typedLeverage !== '1' && (
            <>
              <MarginAmountAlt>
                ≈{' '}
                {marketPrice
                  ? formatAmount(
                      tryParseAmount(inputFieldInfo.typedInput, currencies[Field.OUTPUT])?.divide(marketPrice),
                    )
                  : '-'}{' '}
                {cleanCurrencySymbol(currencies[Field.INPUT])}
              </MarginAmountAlt>
            </>
          )}
          {/* selectedMode === TradeMode.MARGIN && <CurrentLeverage style={{position: 'absolute', top: '100px', lineHeight: '16px', height: '16px', left: '80px'}}>{currentLeverage?.equalTo('0') ? t('none') : `${currentLeverage}x`}</CurrentLeverage> */}

          {leverage.greaterThan('1') && (
            <LeverageSelector
              maxLeverage={maxLeverage}
              currentLeverage={leverage}
              setCurrentLeverage={leverage => onChangeLeverage(leverage)}
              fromTokenTicker={cleanCurrencySymbol(currencies[Field.INPUT])}
              toTokenTicker={cleanCurrencySymbol(currencies[Field.OUTPUT])}
            />
          )}
        </AutoColumn>
        {
          <BottomGrouping>
            {!account ? (
              <ConnectWalletButton>
                <ConnectBtn key={'accountItem-StartTrading'}>
                  <Web3Status />
                </ConnectBtn>
              </ConnectWalletButton>
            ) : noRoute && userHasSpecifiedInputOutput ? (
              <GreyCard style={{ textAlign: 'center' }}>
                <TYPE.main mb={'4px'}>Insufficient liquidity for this trade.</TYPE.main>
                {singleHopOnly && <TYPE.main mb={'4px'}>Try enabling multi-hop trades.</TYPE.main>}
              </GreyCard>
            ) : (
              <div>
                {parsedAmounts[Field.INPUT] && parsedAmounts[Field.OUTPUT] ? (
                  leverage?.greaterThan(1) ? (
                    priceImpactSeverity === 3 &&
                    isValid && <TradeWarning>This position will have high price impact</TradeWarning>
                  ) : (
                    priceImpactSeverity === 3 &&
                    isValid && <TradeWarning>Trade will have high price impact</TradeWarning>
                  )
                ) : (
                  <></>
                )}
                <ButtonError
                  onClick={() => {
                    if (isExpertMode) {
                      onConfirmTrade()
                    } else {
                      setTradeState({
                        tradeToConfirm: trade,
                        attemptingTxn: false,
                        tradeErrorMessage: undefined,
                        showConfirm: true,
                        txHash: undefined,
                      })
                    }
                  }}
                  id={'trade-button'}
                  disabled={!isValid || (priceImpactSeverity > 3 && !isExpertMode) || !!tradeCallbackError}
                  error={isValid && priceImpactSeverity > 2 && !tradeCallbackError}
                >
                  <Text fontSize={16} fontWeight={500}>
                    {tradeInputError
                      ? tradeInputError
                      : priceImpactSeverity > 3 && !isExpertMode
                      ? `Price Impact Too High`
                      : typedLeverage !== '1'
                      ? `Open Position`
                      : `Trade${priceImpactSeverity > 2 ? ' Anyway' : ''}`}
                  </Text>
                </ButtonError>
              </div>
            )}
            {isExpertMode && tradeErrorMessage ? <TradeCallbackError error={tradeErrorMessage} /> : null}
          </BottomGrouping>
        }
      </Wrapper>
    </TradeWrapper>
  )
}
