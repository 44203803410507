import { useEffect, useMemo } from 'react'
import { useActiveWeb3React } from '../../hooks'
import usePrevious from '../../hooks/usePrevious'
import { useAddPopup } from '../application/hooks'
import { useUserLifetimeDataOnAllNetworks } from '../../types/userLifetimeData'
import { Fraction } from '@dolomite-exchange/sdk-core'

const milestones = {
  zaps: [10, 100, 1000],
  time: [150, 1250, 10000],
  positions: [5, 50, 250],
}

export default function Updater(): null {
  const { account } = useActiveWeb3React()
  const { data: userData, loading: isLoadingUserData } = useUserLifetimeDataOnAllNetworks(account)
  const numZaps = userData?.totalZapCount ?? 0
  const borrowTime = userData?.totalBorrowDurationSeconds.divide(new Fraction(60)).divide(new Fraction(60)) ?? 0
  const numBorrowPositions = userData?.totalBorrowPositionCount ?? 0
  const numZapsParsed = parseFloat(numZaps.toFixed(2))
  const borrowTimeParsed = parseFloat(borrowTime.toFixed(2))
  const numBorrowPositionsParsed = parseFloat(numBorrowPositions.toFixed(2))
  const prevTime = usePrevious(borrowTimeParsed) ?? 0
  const prevZaps = usePrevious(numZapsParsed) ?? 0
  const prevPositions = usePrevious(numBorrowPositionsParsed) ?? 0

  const addPopup = useAddPopup()
  useEffect(() => {
    if (numZapsParsed === prevZaps + 1 && numZapsParsed % 10 === 0) {
      if (milestones.zaps.includes(numZapsParsed)) {
        addPopup({
          txn: { hash: 'Claim', summary: `Milestone Complete! - Perform ${numZapsParsed} Zaps`, success: true },
        })
      }
    }
    if (borrowTimeParsed === prevTime + 0.01 && borrowTimeParsed % 10 === 0) {
      if (milestones.time.includes(borrowTimeParsed)) {
        addPopup({
          txn: { hash: 'Claim', summary: `Milestone Complete! - ${borrowTimeParsed} Hours Borrowed`, success: true },
        })
      }
    }
    if (numBorrowPositionsParsed === prevPositions + 1 && prevPositions % 5 === 0) {
      if (milestones.positions.includes(numBorrowPositionsParsed)) {
        addPopup({
          txn: {
            hash: 'Claim',
            summary: `Milestone Complete! - Open ${numBorrowPositionsParsed} Borrow Positions`,
            success: true,
          },
        })
      }
    }
  }, [borrowTimeParsed, numBorrowPositionsParsed, numZapsParsed, addPopup, prevTime, prevZaps, prevPositions])

  return null
}
