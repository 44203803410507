import { useActiveDolomiteZapClient } from '../apollo/client'
import { useEffect, useMemo, useState } from 'react'
import { CurrencyAmount, Fraction, Token } from '@dolomite-exchange/v2-sdk'
import { BigNumber, INTEGERS } from '@dolomite-exchange/zap-sdk'
import { useActiveWeb3React } from './index'
import { useDolomiteMarginTokenAddressToIdMap } from './useDolomiteMarginProtocol'
import useDebounce from './useDebounce'
import { CANCELLED_MESSAGE, retry } from '../utils/retry'
import { useUserSlippageTolerance } from '../state/user/hooks'
import { deserializeToken, deserializeTokenOpt, serializeToken, serializeTokenOpt, useAllTokens } from './Tokens'
import { AccountInfo, GenericTraderParam, Integer } from '@dolomite-exchange/zap-sdk/dist/src/lib/ApiTypes'
import { ZERO_FRACTION, ZERO_PERCENT } from '../constants'
import { Percent } from '@dolomite-exchange/sdk-core'
import { reqParseAmount } from '../state/trade/hooks'
import store from '../state'

const DEFAULT_ADDRESS = '0x1234567812345678123456781234567812345678'

const SLIPPAGE_TOLERANCE_ASYNC_SWAP = 25
const SLIPPAGE_BASE = new BigNumber(10000)

export interface ZapOutputParam {
  marketIdsPath: Integer[]
  tokensPath: Token[]
  amountWeisPath: Integer[]
  traderParams: GenericTraderParam[]
  makerAccounts: AccountInfo[]
  expectedAmountOut: Integer
  originalAmountOutMin: Integer
  executionFee: Integer
  priceImpact: Percent
}

const MIN_WAIT = 750
const MAX_WAIT = 1_500
export const ZAP_REFRESH_INTERVAL_SECONDS = 10

function serializeAmountOpt(amount: CurrencyAmount<Token> | undefined): string | undefined {
  if (!amount) {
    return undefined
  }

  return JSON.stringify({
    amount: amount.quotient.toString(),
    token: serializeToken(amount.currency),
  })
}

function deserializeAmountOpt(json: string | undefined): CurrencyAmount<Token> | undefined {
  if (!json) {
    return undefined
  }

  const { amount, token } = JSON.parse(json)
  return CurrencyAmount.fromRawAmount(deserializeToken(token), amount)
}

export function useGetZapExactTokensForTokensParams(
  amountIn: CurrencyAmount<Token> | undefined,
  tokenOut: Token | undefined,
  isZapActivated: boolean,
  externalIncrementor: number,
  subAccountNumber: string | undefined, // more easily memoizable vs JSBI
  fiatValueMap: Record<string, Fraction | undefined>,
): { loading: boolean; error: string | undefined; outputs: ZapOutputParam[] | undefined } {
  const [allowedSlippage] = useUserSlippageTolerance()
  const tokenMap = useAllTokens()

  const [incrementor, setIncrementor] = useState(0) // used to manually refresh every 'x' interval
  const { account } = useActiveWeb3React()
  const zapClient = useActiveDolomiteZapClient()
  const tokenToMarketIdMap = useDolomiteMarginTokenAddressToIdMap()
  const [currentOutputs, setCurrentOutputs] = useState<ZapOutputParam[]>()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | undefined>(undefined)

  const amountInDebounced = useDebounce(amountIn?.equalTo(ZERO_FRACTION) ? undefined : amountIn, 200)

  const { marketIdIn, marketIdOut } = useMemo(() => {
    return {
      marketIdIn: tokenToMarketIdMap[amountInDebounced?.currency.address ?? '']?.toString(),
      marketIdOut: tokenToMarketIdMap[tokenOut?.address ?? '']?.toString(),
    }
  }, [tokenToMarketIdMap, amountInDebounced?.currency.address, tokenOut?.address])

  const tokenOutSerialized = useMemo(() => serializeTokenOpt(tokenOut), [tokenOut])
  const amountInDebouncedSerialized = useMemo(() => serializeAmountOpt(amountInDebounced), [amountInDebounced])

  useEffect(() => {
    const amountInDebouncedDeserialized = deserializeAmountOpt(amountInDebouncedSerialized)
    // refresh the current outputs if it exists and 10 seconds have passed
    if (!amountInDebouncedDeserialized && loading) {
      return undefined
    }
    const id = setTimeout(() => {
      setIncrementor(oldIncrementor => oldIncrementor + 1)
    }, ZAP_REFRESH_INTERVAL_SECONDS * 1_000)
    return () => clearTimeout(id)
  }, [amountInDebouncedSerialized, loading])

  useEffect(() => {
    setCurrentOutputs(undefined)
  }, [
    account,
    amountInDebouncedSerialized,
    tokenOutSerialized,
    marketIdIn,
    marketIdOut,
    zapClient,
    allowedSlippage,
    tokenMap,
  ])
  useEffect(() => {
    const tokenOutDeserialized = deserializeTokenOpt(tokenOutSerialized)
    const amountInDebouncedDeserialized = deserializeAmountOpt(amountInDebouncedSerialized)
    if (!amountInDebouncedDeserialized || !tokenOutDeserialized) {
      return undefined
    }

    if (!marketIdIn || !marketIdOut || marketIdIn === marketIdOut) {
      return undefined
    }

    if (!isZapActivated) {
      return undefined
    }

    if (!subAccountNumber) {
      return undefined
    }

    console.debug('Starting zap...', amountInDebouncedDeserialized.currency.symbol, tokenOutDeserialized.symbol)
    const startTimestamp = Date.now()
    setLoading(true)
    let isFinished = false
    const { promise: result, cancel } = retry(
      async () => {
        try {
          if (!zapClient) {
            return []
          }

          const zapMarketIdIn = new BigNumber(marketIdIn)
          const zapMarketIdOut = new BigNumber(marketIdOut)
          const isAsyncSwap =
            zapClient.getIsAsyncAssetByMarketId(zapMarketIdIn) || zapClient.getIsAsyncAssetByMarketId(zapMarketIdOut)
          const zapOutputParams = await zapClient.getSwapExactTokensForTokensParams(
            {
              marketId: zapMarketIdIn,
              symbol: amountInDebouncedDeserialized.currency.symbol ?? '',
            },
            new BigNumber(amountInDebouncedDeserialized.quotient.toString()),
            {
              marketId: zapMarketIdOut,
              symbol: tokenOutDeserialized.symbol ?? '',
            },
            new BigNumber(1),
            account ?? DEFAULT_ADDRESS,
            {
              slippageTolerance: new BigNumber(isAsyncSwap ? SLIPPAGE_TOLERANCE_ASYNC_SWAP : allowedSlippage)
                .div(SLIPPAGE_BASE)
                .toNumber(),
              subAccountNumber: new BigNumber(subAccountNumber),
              disallowAggregator:
                zapClient.getIsAsyncAssetByMarketId(zapMarketIdIn) ||
                zapClient.getIsAsyncAssetByMarketId(zapMarketIdOut),
            },
          )

          console.debug(`Fetched zap in ${Date.now() - startTimestamp}ms`)
          setLoading(false)
          setError(undefined)
          return zapOutputParams
        } catch (e) {
          const error = e as any
          if (error.message === CANCELLED_MESSAGE) {
            console.debug('Zap cancelled')
          } else {
            console.log('Found zap error:', error)
          }
          setLoading(false)
          setError(error.message)
          return Promise.reject(error)
        }
      },
      {
        n: Math.floor((ZAP_REFRESH_INTERVAL_SECONDS * 1_000) / MAX_WAIT),
        minWait: MIN_WAIT,
        maxWait: MAX_WAIT,
      },
    )

    result
      .then(newOutputs => {
        const zaps = newOutputs.reduce<ZapOutputParam[]>((memo, output) => {
          const tokensPath = output.tokensPath
            .map(token => tokenMap[token.tokenAddress])
            .filter(token => token !== undefined) as Token[]
          if (tokensPath.length !== output.tokensPath.length) {
            // GUARD statement
            return memo
          }
          memo.push({
            tokensPath,
            marketIdsPath: output.marketIdsPath,
            amountWeisPath: output.amountWeisPath,
            traderParams: output.traderParams,
            makerAccounts: output.makerAccounts,
            expectedAmountOut: output.expectedAmountOut,
            originalAmountOutMin: output.originalAmountOutMin,
            executionFee: output.executionFee ? output.executionFee : INTEGERS.ZERO,
            priceImpact: ZERO_PERCENT,
          })
          return memo
        }, [])
        setCurrentOutputs(zaps)
        isFinished = true
      })
      .catch(async error => {
        if (error.message !== 'Cancelled') {
          console.error('Caught error creating a zap:', error)
        }
        setCurrentOutputs(undefined)
        isFinished = true
      })

    return () => {
      if (!isFinished) {
        console.debug('Canceling zap for unfinished call...')
      }
      cancel()
    }
  }, [
    incrementor,
    externalIncrementor,
    account,
    amountInDebouncedSerialized,
    tokenOutSerialized,
    marketIdIn,
    marketIdOut,
    zapClient,
    allowedSlippage,
    tokenMap,
    isZapActivated,
    subAccountNumber,
  ])

  return useMemo(
    () => ({
      loading,
      error,
      outputs: addPriceImpactToZaps(currentOutputs, fiatValueMap),
    }),
    [currentOutputs, error, loading, fiatValueMap],
  )
}

const MAX_POSITIVE_SLIPPAGE_NUMERATOR = 0
const MAX_POSITIVE_SLIPPAGE_DENOMINATOR = 10_000

function addPriceImpactToZaps(
  zaps: ZapOutputParam[] | undefined,
  fiatValueMap: Record<string, Fraction | undefined>,
): ZapOutputParam[] | undefined {
  const bestZap = zaps?.[0]
  if (bestZap) {
    const fiatPriceIn = fiatValueMap[bestZap.tokensPath[0].address]
    const fiatPriceOut = fiatValueMap[bestZap.tokensPath[bestZap.tokensPath.length - 1].address]
    if (!fiatPriceIn || !fiatPriceOut) {
      return zaps
    }

    const amountIn = CurrencyAmount.fromRawAmount(bestZap.tokensPath[0], bestZap.amountWeisPath[0].toFixed())
    const amountOut = CurrencyAmount.fromRawAmount(
      bestZap.tokensPath[bestZap.tokensPath.length - 1],
      bestZap.expectedAmountOut.toFixed(),
    )
    const fiatValueIn = amountIn.asFraction.multiply(fiatPriceIn)
    const fiatValueOut = amountOut.asFraction.multiply(fiatPriceOut)

    const maxPositiveSlippageNumerator = MAX_POSITIVE_SLIPPAGE_NUMERATOR - store.getState().user.userSlippageTolerance
    const slippageFraction = fiatValueIn.subtract(fiatValueOut).divide(fiatValueIn)
    let newAmountOut: BigNumber
    if (slippageFraction.lessThan(new Fraction(maxPositiveSlippageNumerator, MAX_POSITIVE_SLIPPAGE_DENOMINATOR))) {
      // Anything greater than positive slippage config means we cap it to prevent reversions for small orders
      // Positive slippage implies you're getting more than you're putting in
      const fiatValueOutAdj = fiatValueIn.multiply(
        new Fraction(
          maxPositiveSlippageNumerator + MAX_POSITIVE_SLIPPAGE_DENOMINATOR,
          MAX_POSITIVE_SLIPPAGE_DENOMINATOR,
        ),
      )
      const amountOutFractionAdj = fiatValueOutAdj.divide(fiatPriceOut)
      const tokenOut = amountOut.currency
      const amountOutAdj = reqParseAmount(amountOutFractionAdj.toFixed(tokenOut.decimals), tokenOut)
      newAmountOut = new BigNumber(amountOutAdj.quotient.toString())
    } else {
      newAmountOut = bestZap.amountWeisPath[bestZap.amountWeisPath.length - 1]
    }

    const priceImpact = new Percent(slippageFraction.numerator, slippageFraction.denominator)

    return zaps?.map(zap => {
      zap.priceImpact = priceImpact
      zap.amountWeisPath[zap.amountWeisPath.length - 1] = newAmountOut
      return zap
    })
  }

  return zaps
}
