import { Token } from '@dolomite-exchange/sdk-core'
import { ChainId, ChainIdMap, initializeSingleValueChainIdMap } from '../chainId'

const decimals = 18
const symbol = 'PENDLE'
const name = 'Pendle'

function rdnt(chainId: ChainId, address: string) {
  return new Token(chainId, address, decimals, symbol, name)
}

export const PENDLE: ChainIdMap<Token | undefined> = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  rdnt(ChainId.ARBITRUM_ONE, '0x0c880f6761F1af8d9Aa9C466984b80DAb9a8c9e8'),
)
