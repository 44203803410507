import { Web3CallbackState } from './useTradeCallback'
import { estimateGasAsync, SuccessfulContractCall, useActiveWeb3React } from './index'
import { useMemo } from 'react'
import { calculateGasMargin } from '../utils'
import {
  ARB,
  BIG_INT_ZERO,
  ChainId,
  LINK,
  LIQUIDITY_MINING_MINERAL_CLAIM_ADDRESSES,
  LIQUIDITY_MINING_O_TOKEN_CLAIM_ADDRESSES,
  ONE_FRACTION,
  SECONDS_PER_WEEK,
  USDC,
  USDT,
  USDY,
  USER_ERROR_CODES,
  W_USDM,
  WBTC,
  WMNT,
  WO_ETH,
  ZERO_FRACTION,
} from '../constants'
import { useTransactionAdder } from '../state/transactions/hooks'
import { Currency, CurrencyAmount, Fraction, Token } from '@dolomite-exchange/v2-sdk'
import { formatAmount } from '../utils/formatAmount'
import { useLiquidityMiningClaimContract, useLiquidityMiningVesterContract } from './useContract'
import { useDefaultMarginAccount } from '../types/marginAccount'
import { useArbToken, useMineralToken, useOToken } from './Tokens'
import { NEVER_RELOAD, useSingleCallResult } from '../state/multicall/hooks'
import { LiquidityMiningVestingPosition } from '../types/liquidityMiningVestingData'
import { Ether } from '@dolomite-exchange/sdk-core'
import { useTokenBalance } from '../state/wallet/hooks'
import { WETH } from 'constants/tokens/WETH'
import { useAggregateBalanceData } from '../types/aggregateBalanceData'
import { LiquidityMiningClaim } from '../types/liquidityMiningClaimData'
import { address } from '@dolomite-exchange/dolomite-margin/dist/src/types'
import { ChainIdMap } from '../constants/chainId'
import { reqParseAmount } from '../state/trade/hooks'
import { MIN } from '../constants/tokens/MIN'
import { METH } from '../constants/tokens/METH'
import { BorrowPosition, BorrowPositionAmount } from '../types/borrowPositionData'
import { GRAI } from '../constants/tokens/GRAI'
import { RS_ETH } from '../constants/tokens/RS_ETH'
import { GM_LINK_USD_SPECIAL_ASSET } from '../constants/isolation/assets/GM_LINK_USD_SPECIAL_ASSET'
import { GM_BTC_SPECIAL_ASSET } from '../constants/isolation/assets/GM_BTC_SPECIAL_ASSET'
import { GM_BTC_USD_SPECIAL_ASSET } from '../constants/isolation/assets/GM_BTC_USD_SPECIAL_ASSET'
import { GM_UNI_USD_SPECIAL_ASSET } from '../constants/isolation/assets/GM_UNI_USD_SPECIAL_ASSET'
import { GM_ARB_USD_SPECIAL_ASSET } from 'constants/isolation/assets/GM_ARB_USD_SPECIAL_ASSET'
import { GM_ETH_USD_SPECIAL_ASSET } from '../constants/isolation/assets/GM_ETH_USD_SPECIAL_ASSET'
import { GM_ETH_SPECIAL_ASSET } from '../constants/isolation/assets/GM_ETH_SPECIAL_ASSET'
import { CM_ETH } from 'constants/tokens/CM_ETH'

export interface MineralMultiplierInfo {
  /**
   * The multiplier boost that is applied to the user's mineral output. Usually used for promotional periods
   */
  boost: Fraction | undefined
  /**
   * The current multiplier that the user is on track to earn. Use `.toFixed(2)` to get the formatted multiplier
   */
  currentMultiplier: Fraction
  /**
   * The highest multiplier that can be achieved if the user is on track to earn more minerals this week than last week.
   * Use `.toFixed(2)` to get the formatted multiplier
   */
  highestMultiplier: Fraction
  /**
   * The amount of minerals earned the prior week. This is the number to beat if the user wishes to retain their
   * multiplier.
   */
  previousWeekMineralAmount: CurrencyAmount<Token> | undefined
  projectedAmountWithMultiplier: CurrencyAmount<Token> | undefined
  currentAmountWithMultiplier: CurrencyAmount<Token> | undefined
}

function getTokensWithOTokenRewards(): ChainIdMap<Token[]> {
  return {
    [ChainId.MAINNET]: [],
    [ChainId.ARBITRUM_ONE]: [
      USDC[ChainId.ARBITRUM_ONE],
      WETH[ChainId.ARBITRUM_ONE],
      WBTC[ChainId.ARBITRUM_ONE]!,
      ARB[ChainId.ARBITRUM_ONE]!,
      RS_ETH[ChainId.ARBITRUM_ONE]!,
      LINK[ChainId.ARBITRUM_ONE]!,
      W_USDM[ChainId.ARBITRUM_ONE]!,
      GRAI[ChainId.ARBITRUM_ONE]!,
      WO_ETH[ChainId.ARBITRUM_ONE]!,
      GM_BTC_SPECIAL_ASSET().isolationModeInfo!.getWrappedToken(ChainId.ARBITRUM_ONE)!,
      GM_BTC_USD_SPECIAL_ASSET().isolationModeInfo!.getWrappedToken(ChainId.ARBITRUM_ONE)!,
      GM_ETH_SPECIAL_ASSET().isolationModeInfo!.getWrappedToken(ChainId.ARBITRUM_ONE)!,
      GM_ETH_USD_SPECIAL_ASSET().isolationModeInfo!.getWrappedToken(ChainId.ARBITRUM_ONE)!,
      GM_ARB_USD_SPECIAL_ASSET().isolationModeInfo!.getWrappedToken(ChainId.ARBITRUM_ONE)!,
      GM_LINK_USD_SPECIAL_ASSET().isolationModeInfo!.getWrappedToken(ChainId.ARBITRUM_ONE)!,
      GM_UNI_USD_SPECIAL_ASSET().isolationModeInfo!.getWrappedToken(ChainId.ARBITRUM_ONE)!,
    ],
    [ChainId.BASE]: [],
    [ChainId.BERACHAIN]: [],
    [ChainId.MANTLE]: [],
    [ChainId.POLYGON_ZKEVM]: [],
    [ChainId.X_LAYER]: [],
  }
}

function getTokensWithGoTokenRewards(): ChainIdMap<Token[]> {
  return {
    [ChainId.MAINNET]: [],
    [ChainId.ARBITRUM_ONE]: [GRAI[ChainId.ARBITRUM_ONE]!],
    [ChainId.BASE]: [],
    [ChainId.BERACHAIN]: [],
    [ChainId.MANTLE]: [],
    [ChainId.POLYGON_ZKEVM]: [],
    [ChainId.X_LAYER]: [],
  }
}

export const TOKENS_WITH_MINERAL_REWARDS: ChainIdMap<Token[]> = {
  [ChainId.MAINNET]: [],
  [ChainId.ARBITRUM_ONE]: [USDC[ChainId.ARBITRUM_ONE], WETH[ChainId.ARBITRUM_ONE], WBTC[ChainId.ARBITRUM_ONE]!],
  [ChainId.BASE]: [],
  [ChainId.BERACHAIN]: [],
  [ChainId.MANTLE]: [
    CM_ETH[ChainId.MANTLE]!,
    METH[ChainId.MANTLE]!,
    WETH[ChainId.MANTLE],
    USDT[ChainId.MANTLE]!,
    USDY[ChainId.MANTLE]!,
    WMNT[ChainId.MANTLE]!,
  ],
  [ChainId.POLYGON_ZKEVM]: [],
  [ChainId.X_LAYER]: [USDT[ChainId.X_LAYER]!, WETH[ChainId.X_LAYER], WBTC[ChainId.X_LAYER]!],
}

export const TOKENS_WITH_GRAVITA_REWARDS: ChainIdMap<Token[]> = {
  [ChainId.MAINNET]: [],
  [ChainId.ARBITRUM_ONE]: [GRAI[ChainId.ARBITRUM_ONE]!],
  [ChainId.BASE]: [],
  [ChainId.BERACHAIN]: [],
  [ChainId.MANTLE]: [],
  [ChainId.POLYGON_ZKEVM]: [],
  [ChainId.X_LAYER]: [],
}

interface MineralsRewardsPerWeek {
  amount: CurrencyAmount<Token>
  displayDecimals: number
}

/**
 * The amount of minerals distributed per week per unit of token supplied
 */
const MINERALS_PER_TOKEN_PER_WEEK_MAP: ChainIdMap<Record<string, MineralsRewardsPerWeek | undefined>> = {
  [ChainId.MAINNET]: {},
  [ChainId.ARBITRUM_ONE]: {
    [USDC[ChainId.ARBITRUM_ONE].address]: {
      amount: reqParseAmount('1', MIN[ChainId.ARBITRUM_ONE]!),
      displayDecimals: 0,
    },
    [WBTC[ChainId.ARBITRUM_ONE]!.address]: {
      amount: reqParseAmount('100000', MIN[ChainId.ARBITRUM_ONE]!),
      displayDecimals: 0,
    },
    [WETH[ChainId.ARBITRUM_ONE].address]: {
      amount: reqParseAmount('5000', MIN[ChainId.ARBITRUM_ONE]!),
      displayDecimals: 0,
    },
  },
  [ChainId.BASE]: {},
  [ChainId.BERACHAIN]: {},
  [ChainId.MANTLE]: {
    [CM_ETH[ChainId.MANTLE]!.address]: {
      amount: reqParseAmount('5000', MIN[ChainId.MANTLE]!),
      displayDecimals: 2,
    },
    [METH[ChainId.MANTLE]!.address]: {
      amount: reqParseAmount('5000', MIN[ChainId.MANTLE]!),
      displayDecimals: 2,
    },
    [WETH[ChainId.MANTLE].address]: {
      amount: reqParseAmount('5000', MIN[ChainId.MANTLE]!),
      displayDecimals: 0,
    },
    [USDT[ChainId.MANTLE]!.address]: {
      amount: reqParseAmount('1', MIN[ChainId.MANTLE]!),
      displayDecimals: 0,
    },
    [USDY[ChainId.MANTLE]!.address]: {
      amount: reqParseAmount('1.25', MIN[ChainId.MANTLE]!),
      displayDecimals: 2,
    },
    [WMNT[ChainId.MANTLE]!.address]: {
      amount: reqParseAmount('1.5', MIN[ChainId.MANTLE]!),
      displayDecimals: 2,
    },
  },
  [ChainId.POLYGON_ZKEVM]: {},
  [ChainId.X_LAYER]: {
    [USDT[ChainId.X_LAYER]!.address]: {
      amount: reqParseAmount('1', MIN[ChainId.X_LAYER]!),
      displayDecimals: 0,
    },
    [WBTC[ChainId.X_LAYER]!.address]: {
      amount: reqParseAmount('100000', MIN[ChainId.X_LAYER]!),
      displayDecimals: 0,
    },
    [WETH[ChainId.X_LAYER].address]: {
      amount: reqParseAmount('5000', MIN[ChainId.X_LAYER]!),
      displayDecimals: 0,
    },
  },
}

export const LIQUIDITY_MINING_LEVEL_THRESHOLD = 4
export const REWARDS_DEPOSIT_THRESHOLD = new Fraction(100000)

export const LIQUIDITY_MINING_PENDLE_EPOCH_LOWER_BOUND = 10_000
export const LIQUIDITY_MINING_PENDLE_EPOCH_UPPER_BOUND = 11_000

const EPOCH_REVISION_THRESHOLD = 9000

export interface ExternalMineralsSource {
  title: string
  titleShort: string
  link: string
  multiplier: Fraction
  claimableAmount: CurrencyAmount<Token>
  pendingAmount: CurrencyAmount<Token>
  totalAmountEarned: CurrencyAmount<Token>
}

export interface MineralsThisWeekData {
  projectedAmount: CurrencyAmount<Token>
  currentAmount: CurrencyAmount<Token>
}

const START_TIMESTAMP = Math.floor(Date.now() / 1000)

export function useProjectedMineralsThisWeek(
  currentAggregateBalances: Record<string, CurrencyAmount<Token> | undefined> | undefined,
  allClaims: LiquidityMiningClaim[] | undefined,
): MineralsThisWeekData | undefined {
  const mineralToken = useMineralToken()
  const tokenToMineralsPerWeekMap = useTokenToMineralsPerWeekMap()
  return useMemo(() => {
    if (!currentAggregateBalances || !mineralToken || !allClaims) {
      return undefined
    }

    const currentWeek = Math.floor(Date.now() / 1000 / SECONDS_PER_WEEK) * SECONDS_PER_WEEK
    const mostRecentClaim = allClaims.find(
      claim => claim.startTimestamp === currentWeek && claim.epoch < EPOCH_REVISION_THRESHOLD,
    )
    let pastAccruedMinerals: CurrencyAmount<Token>
    let timeLeftInWeek: number
    let timeSinceLastUpdate: number
    if (mostRecentClaim) {
      pastAccruedMinerals = mostRecentClaim.amount
      timeLeftInWeek = mostRecentClaim.startTimestamp + SECONDS_PER_WEEK - mostRecentClaim.endTimestamp
      timeSinceLastUpdate = Math.floor(Date.now() / 1000) - mostRecentClaim.endTimestamp
    } else {
      pastAccruedMinerals = CurrencyAmount.fromRawAmount(mineralToken, BIG_INT_ZERO)
      const currentTimestamp = Math.floor(Date.now() / 1000)
      const endTimestamp = Math.floor(currentTimestamp / SECONDS_PER_WEEK) * SECONDS_PER_WEEK + SECONDS_PER_WEEK
      timeLeftInWeek = endTimestamp - currentTimestamp
      timeSinceLastUpdate = currentTimestamp - START_TIMESTAMP
    }

    const { projectedAmount, currentAmount } = Object.keys(currentAggregateBalances).reduce<MineralsThisWeekData>(
      (acc, tokenAddress) => {
        const mineralsPerWeek = tokenToMineralsPerWeekMap[tokenAddress]
        if (!mineralsPerWeek) {
          return acc
        }
        const currentBalance = currentAggregateBalances[tokenAddress]?.asFraction ?? ZERO_FRACTION
        const projectedMineralsPerWeek = mineralsPerWeek.amount
          .multiply(currentBalance)
          .multiply(new Fraction(timeLeftInWeek, SECONDS_PER_WEEK))

        const currentMineralsAccrued = mineralsPerWeek.amount
          .multiply(currentBalance)
          .multiply(new Fraction(timeSinceLastUpdate, SECONDS_PER_WEEK))

        acc.projectedAmount = acc.projectedAmount.add(projectedMineralsPerWeek)
        acc.currentAmount = acc.currentAmount.add(currentMineralsAccrued)
        return acc
      },
      {
        projectedAmount: CurrencyAmount.fromRawAmount(mineralToken, '0'),
        currentAmount: CurrencyAmount.fromRawAmount(mineralToken, '0'),
      },
    )

    return {
      projectedAmount: projectedAmount.add(pastAccruedMinerals),
      currentAmount: currentAmount.add(pastAccruedMinerals),
    }
  }, [currentAggregateBalances, mineralToken, tokenToMineralsPerWeekMap, allClaims])
}

export function useExternalMinerals(
  allClaims: LiquidityMiningClaim[] | undefined,
  unfinalizedClaims: LiquidityMiningClaim[] | undefined,
):
  | {
      externalMineralSources: ExternalMineralsSource[]
      totalExternalMinerals: CurrencyAmount<Token>
    }
  | undefined {
  const { chainId } = useActiveWeb3React()
  const mineralToken = useMineralToken()
  return useMemo(() => {
    if (!mineralToken) {
      return undefined
    }

    if (chainId !== ChainId.ARBITRUM_ONE || !allClaims || !unfinalizedClaims) {
      return {
        externalMineralSources: [],
        totalExternalMinerals: CurrencyAmount.fromRawAmount(mineralToken, '0'),
      }
    }

    const usdcMarketId = '17'
    const wbtcMarketId = '4'

    const pendleUsdcClaimableAmount = unfinalizedClaims.reduce((acc, claim) => {
      if (
        claim.epoch > LIQUIDITY_MINING_PENDLE_EPOCH_LOWER_BOUND &&
        claim.epoch < LIQUIDITY_MINING_PENDLE_EPOCH_UPPER_BOUND &&
        claim.pendleData!.marketIdToAmountMap[usdcMarketId]
      ) {
        return acc.add(claim.pendleData!.marketIdToAmountMap[usdcMarketId])
      }
      return acc
    }, CurrencyAmount.fromRawAmount(mineralToken, 0))

    const pendleUsdcPendingAmount = allClaims.reduce((acc, claim) => {
      if (
        claim.epoch > LIQUIDITY_MINING_PENDLE_EPOCH_LOWER_BOUND &&
        claim.epoch < LIQUIDITY_MINING_PENDLE_EPOCH_UPPER_BOUND &&
        claim.pendleData!.marketIdToAmountMap[usdcMarketId] &&
        !claim.isEpochFinalized
      ) {
        return acc.add(claim.pendleData!.marketIdToAmountMap[usdcMarketId])
      }
      return acc
    }, CurrencyAmount.fromRawAmount(mineralToken, 0))

    const pendleUsdcTotalAmountEarned = allClaims.reduce((acc, claim) => {
      if (
        claim.epoch > LIQUIDITY_MINING_PENDLE_EPOCH_LOWER_BOUND &&
        claim.epoch < LIQUIDITY_MINING_PENDLE_EPOCH_UPPER_BOUND &&
        claim.pendleData!.marketIdToAmountMap[usdcMarketId] &&
        claim.isEpochFinalized
      ) {
        return acc.add(claim.pendleData!.marketIdToAmountMap[usdcMarketId])
      }
      return acc
    }, CurrencyAmount.fromRawAmount(mineralToken, 0))

    const pendleWbtcClaimableAmount = unfinalizedClaims.reduce((acc, claim) => {
      if (
        claim.epoch > LIQUIDITY_MINING_PENDLE_EPOCH_LOWER_BOUND &&
        claim.epoch < LIQUIDITY_MINING_PENDLE_EPOCH_UPPER_BOUND &&
        claim.pendleData!.marketIdToAmountMap[wbtcMarketId]
      ) {
        return acc.add(claim.pendleData!.marketIdToAmountMap[wbtcMarketId])
      }
      return acc
    }, CurrencyAmount.fromRawAmount(mineralToken, 0))

    const pendleWbtcPendingAmount = allClaims.reduce((acc, claim) => {
      if (
        claim.epoch > LIQUIDITY_MINING_PENDLE_EPOCH_LOWER_BOUND &&
        claim.epoch < LIQUIDITY_MINING_PENDLE_EPOCH_UPPER_BOUND &&
        claim.pendleData!.marketIdToAmountMap[wbtcMarketId] &&
        !claim.isEpochFinalized
      ) {
        return acc.add(claim.pendleData!.marketIdToAmountMap[wbtcMarketId])
      }
      return acc
    }, CurrencyAmount.fromRawAmount(mineralToken, 0))

    const pendleWbtcTotalAmountEarned = allClaims.reduce((acc, claim) => {
      if (
        claim.epoch > LIQUIDITY_MINING_PENDLE_EPOCH_LOWER_BOUND &&
        claim.epoch < LIQUIDITY_MINING_PENDLE_EPOCH_UPPER_BOUND &&
        claim.pendleData!.marketIdToAmountMap[wbtcMarketId] &&
        claim.isEpochFinalized
      ) {
        return acc.add(claim.pendleData!.marketIdToAmountMap[wbtcMarketId])
      }
      return acc
    }, CurrencyAmount.fromRawAmount(mineralToken, 0))

    const pendleTotalAmountEarned = allClaims.reduce((acc, claim) => {
      if (
        claim.epoch > LIQUIDITY_MINING_PENDLE_EPOCH_LOWER_BOUND &&
        claim.epoch < LIQUIDITY_MINING_PENDLE_EPOCH_UPPER_BOUND &&
        claim.isEpochFinalized
      ) {
        return acc.add(claim.amount)
      }

      return acc
    }, CurrencyAmount.fromRawAmount(mineralToken, 0))

    return {
      externalMineralSources: [
        {
          title: 'Pendle dUSDC YT, LP, and liquid lockers',
          titleShort: 'Pendle dUSDC',
          link:
            'https://app.pendle.finance/trade/markets/0x0bd6890b3bb15f16430546147734b254d0b03059/swap?view=yt&chain=arbitrum',
          multiplier: new Fraction(3),
          claimableAmount: pendleUsdcClaimableAmount,
          pendingAmount: pendleUsdcPendingAmount,
          totalAmountEarned: pendleUsdcTotalAmountEarned,
        },
        {
          title: 'Pendle dWBTC YT, LP, and liquid lockers',
          titleShort: 'Pendle dWBTC',
          link:
            'https://app.pendle.finance/trade/markets/0x8cab5fd029ae2fbf28c53e965e4194c7260adf0c/swap?view=yt&chain=arbitrum',
          multiplier: new Fraction(3),
          claimableAmount: pendleWbtcClaimableAmount,
          pendingAmount: pendleWbtcPendingAmount,
          totalAmountEarned: pendleWbtcTotalAmountEarned,
        },
      ],
      totalExternalMinerals: pendleTotalAmountEarned,
    }
  }, [allClaims, chainId, mineralToken, unfinalizedClaims])
}

export function useMineralMultiplierInfo(
  mineralsThisWeek: MineralsThisWeekData | undefined,
  allClaims: LiquidityMiningClaim[] | undefined,
  currentEpoch: number | undefined,
): MineralMultiplierInfo {
  const { chainId } = useActiveWeb3React()
  return useMemo(() => {
    const mostRecentFinalizedClaim = allClaims?.reduce<LiquidityMiningClaim | undefined>((a, b) => {
      if (!b.proof) {
        return a
      }
      if ((a && a.epoch > b.epoch) || b.epoch >= EPOCH_REVISION_THRESHOLD) {
        return a
      }
      return b
    }, undefined)

    let initialMultiplier = ONE_FRACTION
    if (
      chainId === ChainId.MANTLE &&
      currentEpoch !== undefined &&
      currentEpoch >= 1 // boost starts on epoch 1 for Mantle
    ) {
      initialMultiplier = new Fraction(2)
    }

    const mostRecentAmount = mostRecentFinalizedClaim?.amount
    const mostRecentMultiplier = mostRecentFinalizedClaim?.multiplier?.multiply(initialMultiplier)
    const mostRecentNormalizedAmount =
      mostRecentAmount && mostRecentMultiplier ? mostRecentAmount.divide(mostRecentMultiplier) : undefined

    let highestMultiplier = initialMultiplier
    if (mostRecentFinalizedClaim && mineralsThisWeek && mostRecentFinalizedClaim.multiplier) {
      highestMultiplier = mostRecentFinalizedClaim.multiplier.add(new Fraction(1, 2))
      const five = new Fraction(5)
      if (highestMultiplier.greaterThan(five)) {
        highestMultiplier = five
      }
      highestMultiplier = highestMultiplier.multiply(initialMultiplier)
    }

    let currentMultiplier = initialMultiplier
    if (
      mineralsThisWeek &&
      mostRecentNormalizedAmount &&
      mineralsThisWeek.projectedAmount.greaterThan(mostRecentNormalizedAmount)
    ) {
      currentMultiplier = highestMultiplier
    }

    return {
      boost: initialMultiplier.greaterThan(ONE_FRACTION) ? initialMultiplier : undefined,
      currentMultiplier,
      highestMultiplier,
      previousWeekMineralAmount: mostRecentNormalizedAmount,
      projectedAmountWithMultiplier: mineralsThisWeek?.projectedAmount.multiply(currentMultiplier),
      currentAmountWithMultiplier: mineralsThisWeek?.currentAmount.multiply(currentMultiplier),
    }
  }, [chainId, allClaims, mineralsThisWeek, currentEpoch])
}

export function useMineralTokenBalance(account: string | undefined) {
  const mineralToken = useMineralToken()
  return useTokenBalance(account, mineralToken)
}

export function useTokensWithOTokenRewards(): Token[] {
  const { chainId } = useActiveWeb3React()
  return useMemo(() => {
    return getTokensWithOTokenRewards()[chainId]
  }, [chainId])
}

export function useTokensWithGoArbLiquidityMiningRewards(): Token[] {
  const { chainId } = useActiveWeb3React()
  return useMemo(() => {
    return getTokensWithGoTokenRewards()[chainId]
  }, [chainId])
}

export function useTokensWithMineralRewards(): Token[] {
  const { chainId } = useActiveWeb3React()
  return useMemo(() => {
    return TOKENS_WITH_MINERAL_REWARDS[chainId]
  }, [chainId])
}

export function useTokensWithGravitaRewards(): Token[] {
  const { chainId } = useActiveWeb3React()
  return useMemo(() => {
    return TOKENS_WITH_GRAVITA_REWARDS[chainId]
  }, [chainId])
}

export function useTokenToMineralsPerWeekMap(): Record<string, MineralsRewardsPerWeek | undefined> {
  const { chainId } = useActiveWeb3React()
  return useMemo(() => {
    return MINERALS_PER_TOKEN_PER_WEEK_MAP[chainId]
  }, [chainId])
}

export function useAggregateBalanceDataForMinerals(
  account: string | undefined,
): {
  loading: boolean
  error: boolean
  data: Record<address, CurrencyAmount<Token> | undefined> | undefined
} {
  const tokens = useTokensWithMineralRewards()
  return useAggregateBalanceData(account, tokens)
}

export function useCalculatePositionMinerals(
  position: BorrowPosition,
  showRatios?: BorrowPositionAmount,
  showUsdRatios?: BorrowPositionAmount,
  priceMap?: Record<string, Fraction | undefined>,
): Fraction {
  const eligibleTokens = useTokensWithMineralRewards()
  const tokenToMineralsPerWeekMap = useTokenToMineralsPerWeekMap()
  return useMemo(() => {
    return eligibleTokens.reduce<Fraction>((acc, token) => {
      const supplyAsset = position.supplyAmounts.find(supply => supply.token.address === token.address)
      if (supplyAsset) {
        const mineralsPerWeek = tokenToMineralsPerWeekMap[token.address]
        if (showRatios && mineralsPerWeek) {
          return showRatios.token.symbol === supplyAsset.token.symbol
            ? acc.add(mineralsPerWeek.amount.asFraction)
            : supplyAsset.amountTokenWei.asFraction
                .divide(showRatios.amountTokenWei.asFraction)
                .multiply(mineralsPerWeek.amount.asFraction)
        }
        if (showUsdRatios && mineralsPerWeek && priceMap) {
          const USDValueOfAsset = priceMap[supplyAsset.token.address]
          return USDValueOfAsset
            ? showUsdRatios.token.symbol === supplyAsset.token.symbol
              ? acc.add(mineralsPerWeek.amount.asFraction)
              : supplyAsset.amountUSD.asFraction
                  .divide(USDValueOfAsset.asFraction)
                  .multiply(mineralsPerWeek.amount.asFraction)
            : acc
        }
        if (mineralsPerWeek) {
          return acc.add(mineralsPerWeek.amount.asFraction.multiply(supplyAsset.amountTokenWei.asFraction))
        }
      }
      return acc
    }, new Fraction(0))
  }, [eligibleTokens, position.supplyAmounts, priceMap, showRatios, showUsdRatios, tokenToMineralsPerWeekMap])
}

export function useDistributorAddressByClaimToken(token: Token | undefined): string | undefined {
  const { chainId } = useActiveWeb3React()
  const oToken = useOToken()
  const mineralToken = useMineralToken()
  if (token && oToken && token.equals(oToken)) {
    return LIQUIDITY_MINING_O_TOKEN_CLAIM_ADDRESSES[chainId]
  } else if (token && mineralToken && token.equals(mineralToken)) {
    return LIQUIDITY_MINING_MINERAL_CLAIM_ADDRESSES[chainId]
  }

  return undefined
}

export function useClaimLiquidityMiningRewards(
  unfinalizedClaims: LiquidityMiningClaim[] | undefined,
  claimToken: Token | undefined,
): {
  state: Web3CallbackState
  callback: null | (() => Promise<string>)
  error: string | null
} {
  const { account, chainId, library } = useActiveWeb3React()
  const distributorAddress = useDistributorAddressByClaimToken(claimToken)
  const claimContract = useLiquidityMiningClaimContract(distributorAddress)
  const addTransaction = useTransactionAdder()

  return useMemo(() => {
    if (!claimContract || !library || !account || !chainId || !unfinalizedClaims || !claimToken) {
      return {
        state: Web3CallbackState.INVALID,
        callback: null,
        error: 'Missing dependencies',
      }
    }

    const contract = claimContract
    const methodName = 'claim'
    const args: any[] = [
      unfinalizedClaims.map(claim => ({
        epoch: claim.epoch,
        amount: claim.amount.quotient.toString(),
        proof: claim.proof,
      })),
    ]

    return {
      state: Web3CallbackState.VALID,
      callback: async function onClaim(): Promise<string> {
        const symbol = claimToken.symbol
        const estimatedCall = await estimateGasAsync(contract, methodName, args)
        let successfulCall: SuccessfulContractCall
        if ('gasEstimate' in estimatedCall) {
          successfulCall = estimatedCall
        } else {
          throw estimatedCall.error
        }

        const amount = unfinalizedClaims.reduce(
          (total, info) => total.add(info.amount),
          CurrencyAmount.fromRawAmount(claimToken, 0),
        )
        return contract[methodName](...args, {
          gasLimit: calculateGasMargin(successfulCall.gasEstimate),
          from: account,
        })
          .then((response: any) => {
            const summary = `Claimed ${formatAmount(amount)} ${symbol} Rewards`
            addTransaction(response, { summary })
            return response.hash
          })
          .catch((error: any) => {
            // if the user rejected the tx, pass this along
            if (error?.code === USER_ERROR_CODES.REJECTED) {
              throw new Error('transaction-rejected')
            } else {
              // otherwise, the error was unexpected, and we need to convey that
              console.error(`Claiming ${symbol} failed`, error, methodName, args)
              throw new Error(`Claiming ${symbol} failed with message: ${error.message}`)
            }
          })
      },
      error: null,
    }
  }, [claimContract, claimToken, library, account, chainId, unfinalizedClaims, addTransaction])
}

export function useVestOToken(
  lockupPeriodInSeconds: number,
  effectiveLockupPeriodInSeconds: Fraction,
  amount: CurrencyAmount<Token> | undefined,
): { state: Web3CallbackState; callback: (() => Promise<string>) | null; error: string | null } {
  const { account, chainId, library } = useActiveWeb3React()
  const vestContract = useLiquidityMiningVesterContract()
  const addTransaction = useTransactionAdder()
  const accountNumber = useDefaultMarginAccount()
  const oToken = useOToken()

  return useMemo(() => {
    if (!vestContract || !library || !account || !chainId || !amount || !oToken) {
      return {
        state: Web3CallbackState.INVALID,
        callback: null,
        error: 'Missing dependencies',
      }
    } else if (lockupPeriodInSeconds % 604_800 !== 0) {
      return {
        state: Web3CallbackState.INVALID,
        callback: null,
        error: 'Lockup period must be a multiple of 1 week',
      }
    }

    const contract = vestContract
    const methodName = 'vest'
    const args: any[] = [
      accountNumber.accountNumber.toString(),
      lockupPeriodInSeconds.toString(),
      amount.quotient.toString(),
    ]

    return {
      state: Web3CallbackState.VALID,
      callback: async function onVest(): Promise<string> {
        const symbol = oToken.symbol
        const estimatedCall = await estimateGasAsync(contract, methodName, args)
        let successfulCall: SuccessfulContractCall
        if ('gasEstimate' in estimatedCall) {
          successfulCall = estimatedCall
        } else {
          throw estimatedCall.error
        }

        return contract[methodName](...args, {
          gasLimit: calculateGasMargin(successfulCall.gasEstimate),
          from: account,
        })
          .then((response: any) => {
            const lockupPeriod = effectiveLockupPeriodInSeconds.divide(604800).toFixed(2)
            const summary = `Began vesting ${formatAmount(
              amount,
              undefined,
              true,
            )} ${symbol} for a period of ${lockupPeriod.replace('.00', '')} ${
              lockupPeriod === '1.00' ? 'week' : 'weeks'
            }`
            addTransaction(response, { summary })
            return response.hash
          })
          .catch((error: any) => {
            // if the user rejected the tx, pass this along
            if (error?.code === USER_ERROR_CODES.REJECTED) {
              throw new Error('transaction-rejected')
            } else {
              // otherwise, the error was unexpected, and we need to convey that
              console.error(`Vesting ${symbol} failed`, error, methodName, args)
              throw new Error(`Vesting ${symbol} failed with message: ${error.message}`)
            }
          })
      },
      error: null,
    }
  }, [
    vestContract,
    library,
    account,
    chainId,
    amount,
    oToken,
    lockupPeriodInSeconds,
    accountNumber.accountNumber,
    effectiveLockupPeriodInSeconds,
    addTransaction,
  ])
}

export function useExecuteVest(
  positionId: string | undefined,
  maxPaymentAmount: CurrencyAmount<Token> | undefined,
): {
  state: Web3CallbackState
  callback: null | (() => Promise<string>)
  error: string | null
} {
  const { account, chainId, library } = useActiveWeb3React()
  const executeContract = useLiquidityMiningVesterContract()
  const addTransaction = useTransactionAdder()
  const marginAccount = useDefaultMarginAccount()

  return useMemo(() => {
    if (!executeContract || !library || !account || !chainId || !positionId || !maxPaymentAmount) {
      return {
        state: Web3CallbackState.INVALID,
        callback: null,
        error: 'Missing dependencies',
      }
    }

    const contract = executeContract
    const methodName = 'closePositionAndBuyTokens'
    const args: any[] = [
      positionId,
      marginAccount.accountNumber.toString(),
      marginAccount.accountNumber.toString(),
      maxPaymentAmount.quotient.toString(),
    ]

    return {
      state: Web3CallbackState.VALID,
      callback: async function onClosePositionAndBuyTokens(): Promise<string> {
        const estimatedCall = await estimateGasAsync(contract, methodName, args)
        let successfulCall: SuccessfulContractCall
        if ('gasEstimate' in estimatedCall) {
          successfulCall = estimatedCall
        } else {
          throw estimatedCall.error
        }

        return contract[methodName](...args, {
          gasLimit: calculateGasMargin(successfulCall.gasEstimate),
          from: account,
        })
          .then((response: any) => {
            const summary = `Executed vest and purchased ARB`
            addTransaction(response, { summary })
            return response.hash
          })
          .catch((error: any) => {
            // if the user rejected the tx, pass this along
            if (error?.code === USER_ERROR_CODES.REJECTED) {
              throw new Error('transaction-rejected')
            } else {
              // otherwise, the error was unexpected, and we need to convey that
              console.error(`Executing vest failed`, error, methodName, args)
              throw new Error(`Executing vest failed with message: ${error.message}`)
            }
          })
      },
      error: null,
    }
  }, [executeContract, library, account, chainId, maxPaymentAmount, positionId, marginAccount, addTransaction])
}

export function useExtendVestForGrandfatheredPosition(
  positionId: string | undefined,
  newDurationSeconds: number | undefined,
): {
  state: Web3CallbackState
  callback: null | (() => Promise<string>)
  error: string | null
} {
  const { account, chainId, library } = useActiveWeb3React()
  const executeContract = useLiquidityMiningVesterContract()
  const addTransaction = useTransactionAdder()
  const grandfatheredId = useGrandfatherIdCutoff()

  return useMemo(() => {
    if (
      !executeContract ||
      !library ||
      !account ||
      !chainId ||
      !positionId ||
      !newDurationSeconds ||
      !grandfatheredId
    ) {
      return {
        state: Web3CallbackState.INVALID,
        callback: null,
        error: 'Missing dependencies',
      }
    } else if (parseInt(positionId) > grandfatheredId) {
      return {
        state: Web3CallbackState.INVALID,
        callback: null,
        error: 'Position is not grandfathered',
      }
    }

    const contract = executeContract
    const methodName = 'extendDurationForGrandfatheredPosition'
    const args: any[] = [positionId, newDurationSeconds]

    return {
      state: Web3CallbackState.VALID,
      callback: async function onExtendDurationForGrandfatheredPosition(): Promise<string> {
        const estimatedCall = await estimateGasAsync(contract, methodName, args)
        let successfulCall: SuccessfulContractCall
        if ('gasEstimate' in estimatedCall) {
          successfulCall = estimatedCall
        } else {
          throw estimatedCall.error
        }

        return contract[methodName](...args, {
          gasLimit: calculateGasMargin(successfulCall.gasEstimate),
          from: account,
        })
          .then((response: any) => {
            const summary = `Extend vesting duration to ${newDurationSeconds / 604_800} weeks`
            addTransaction(response, { summary })
            return response.hash
          })
          .catch((error: any) => {
            // if the user rejected the tx, pass this along
            if (error?.code === USER_ERROR_CODES.REJECTED) {
              throw new Error('transaction-rejected')
            } else {
              // otherwise, the error was unexpected, and we need to convey that
              console.error(`Extending vesting duration failed`, error, methodName, args)
              throw new Error(`Extending vesting duration failed with message: ${error.message}`)
            }
          })
      },
      error: null,
    }
  }, [executeContract, library, account, chainId, positionId, newDurationSeconds, addTransaction, grandfatheredId])
}

export function useInitiateLevelRequest(): {
  state: Web3CallbackState
  callback: null | (() => Promise<string>)
  error: string | null
} {
  const { account, chainId, library } = useActiveWeb3React()
  const vesterContract = useLiquidityMiningVesterContract()
  const addTransaction = useTransactionAdder()
  const ethFee = useLevelRequestFeeEth()

  return useMemo(() => {
    if (!vesterContract || !library || !account || !chainId) {
      return {
        state: Web3CallbackState.INVALID,
        callback: null,
        error: 'Missing dependencies',
      }
    }

    const contract = vesterContract
    const methodName = 'initiateLevelRequest'
    const args: any[] = [account]

    return {
      state: Web3CallbackState.VALID,
      callback: async function onInitiateLevelRequest(): Promise<string> {
        const options = { value: ethFee.quotient.toString() }
        const estimatedCall = await estimateGasAsync(contract, methodName, args, options)
        let successfulCall: SuccessfulContractCall
        if ('gasEstimate' in estimatedCall) {
          successfulCall = estimatedCall
        } else {
          throw estimatedCall.error
        }

        return contract[methodName](...args, {
          ...options,
          gasLimit: calculateGasMargin(successfulCall.gasEstimate),
          from: account,
        })
          .then((response: any) => {
            const summary = `Fetch level for vesting`
            addTransaction(response, { summary })
            return response.hash
          })
          .catch((error: any) => {
            // if the user rejected the tx, pass this along
            if (error?.code === USER_ERROR_CODES.REJECTED) {
              throw new Error('transaction-rejected')
            } else {
              // otherwise, the error was unexpected, and we need to convey that
              console.error(`Fetch level for vesting failed`, error, methodName, args)
              throw new Error(`Fetch level for vesting failed with message: ${error.message}`)
            }
          })
      },
      error: null,
    }
  }, [vesterContract, library, account, chainId, addTransaction, ethFee])
}

export function useAvailableRewardTokensForLiquidityMining(): CurrencyAmount<Token> | undefined {
  const vestingContract = useLiquidityMiningVesterContract()
  const arbToken = useArbToken()
  const callState = useSingleCallResult(vestingContract, 'availableTokens', undefined)
  return useMemo(() => {
    const arbTokensData = callState.result?.[0]
    if (arbTokensData && arbToken) {
      return CurrencyAmount.fromRawAmount(arbToken, arbTokensData.toString())
    } else {
      return undefined
    }
  }, [callState, arbToken])
}

export function useEffectiveLevelForUser(): number | undefined {
  const { account } = useActiveWeb3React()
  const vestingContract = useLiquidityMiningVesterContract()
  const inputs = useMemo(() => [account], [account])
  const callState = useSingleCallResult(vestingContract, 'getEffectiveLevelByUser', inputs)
  return useMemo(() => {
    const level = callState.result?.[0]
    if (level) {
      return parseInt(level.toString())
    } else {
      return undefined
    }
  }, [callState])
}

export function useHasPendingLevelUpdateRequest(): boolean | undefined {
  const { account } = useActiveWeb3React()
  const vestingContract = useLiquidityMiningVesterContract()
  const inputs = useMemo(() => [account], [account])
  const callState = useSingleCallResult(vestingContract, 'getLevelRequestByUser', inputs)
  return useMemo(() => {
    const level = callState.result?.[0]
    if (level) {
      return !new Fraction(level.toString()).equalTo(ZERO_FRACTION)
    } else {
      return undefined
    }
  }, [callState])
}

export function useGrandfatherIdCutoff(): number {
  return 270
}

const EIGHT_WEEKS = 4_838_400

export function useIsPositionGrandfatherable(
  vestingPosition: LiquidityMiningVestingPosition | undefined,
): boolean | undefined {
  const grandfatherId = useGrandfatherIdCutoff()
  if (grandfatherId && vestingPosition) {
    return parseInt(vestingPosition.nftId) <= grandfatherId && vestingPosition.durationSeconds < EIGHT_WEEKS
  } else {
    return undefined
  }
}

function useLevelRequestFeeEth(): CurrencyAmount<Currency> {
  const { chainId } = useActiveWeb3React()
  const ether = useMemo(() => Ether.onChain(chainId), [chainId])
  const vestingContract = useLiquidityMiningVesterContract()
  const callState = useSingleCallResult(vestingContract, 'levelRequestFee', undefined, NEVER_RELOAD)
  return useMemo(() => {
    const levelRequestFee = callState.result?.[0]
    if (levelRequestFee) {
      return CurrencyAmount.fromRawAmount(ether, levelRequestFee.toString())
    } else {
      return CurrencyAmount.fromRawAmount(ether, '300000000000000')
    }
  }, [callState.result, ether])
}
