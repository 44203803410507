import { Token } from '@dolomite-exchange/sdk-core'
import { ChainId, ChainIdMap, initializeSingleValueChainIdMap } from '../chainId'

const decimals = 18
const symbol = 'wstETH'
const name = 'Wrapped Staked ETH'

function getToken(chainId: ChainId, address: string) {
  return new Token(chainId, address, decimals, symbol, name)
}

export const WST_ETH: ChainIdMap<Token | undefined> = initializeSingleValueChainIdMap(
  ChainId.ARBITRUM_ONE,
  getToken(ChainId.ARBITRUM_ONE, '0x5979D7b546E38E414F7E9822514be443A4800529'),
)
