import { BalanceCheckFlag, CurrencyAmount, Fraction, Token } from '@dolomite-exchange/v2-sdk'
import { USER_ERROR_CODES, INITIAL_ALLOWED_SLIPPAGE } from '../constants'
import { estimateGasAsync, SuccessfulContractCall, useActiveWeb3React } from './index'
import { useTransactionAdder } from '../state/transactions/hooks'
import { useMemo } from 'react'
import { calculateGasMargin } from '../utils'
import { Web3CallbackState } from './useTradeCallback'
import { useDolomiteAmmRouterContract } from './useContract'
import useTransactionDeadline from './useTransactionDeadline'
import { SignatureDataObject } from './usePermitOrApprove'
import { ApprovalState } from './useApproveCallback'
import JSBI from 'jsbi'
import { TransactionResponse } from '@ethersproject/providers'
import { useDefaultMarginAccount } from '../types/marginAccount'
import cleanCurrencySymbol from 'utils/cleanCurrencySymbol'
import { formatAmount } from '../utils/formatAmount'

export function useWithdrawFromPool(
  approval: ApprovalState,
  signatureData: SignatureDataObject | null,
  tokenA: string | undefined,
  tokenB: string | undefined,
  liquidity: CurrencyAmount<Token> | undefined,
  amountAMin: CurrencyAmount<Token> | undefined,
  amountBMin: CurrencyAmount<Token> | undefined,
): { state: Web3CallbackState; callback: null | (() => Promise<string>); error: string | null } {
  const web3Context = useActiveWeb3React()
  const { account, chainId, library } = web3Context

  const contract = useDolomiteAmmRouterContract()
  const deadline = useTransactionDeadline()
  const addTransaction = useTransactionAdder()
  const defaultAccount = useDefaultMarginAccount()

  return useMemo(() => {
    if (
      !contract ||
      !library ||
      !account ||
      !chainId ||
      !tokenA ||
      !tokenB ||
      !liquidity ||
      !amountAMin ||
      !amountBMin
    ) {
      return {
        state: Web3CallbackState.INVALID,
        callback: null,
        error: 'Missing dependencies',
      }
    }

    let methodName: string
    if (signatureData) {
      methodName = 'removeLiquidityWithPermit'
    } else {
      methodName = 'removeLiquidity'
    }

    // use the signature deadline if it's available to prevent signature invalidation
    const args: any[] = [
      {
        toAccountNumber: defaultAccount.accountNumber.toString(),
        tokenA: tokenA,
        tokenB: tokenB,
        liquidityWei: (signatureData?.amount ?? liquidity).quotient.toString(10),
        amountAMinWei: amountAMin.quotient.toString(10),
        amountBMinWei: amountBMin.quotient.toString(10),
        deadline: signatureData?.deadline ?? deadline,
      },
      account, // _to
    ]

    if (signatureData) {
      args.push({
        approveMax: false,
        v: signatureData.v,
        r: signatureData.r,
        s: signatureData.s,
      })
    }

    return {
      state: Web3CallbackState.VALID,
      callback: async function onRemoveLiquidity(): Promise<string> {
        if (approval !== ApprovalState.APPROVED && !signatureData) {
          return Promise.reject('Attempting to withdraw without approval or a signature. Please contact support.')
        }

        const estimatedCall = await estimateGasAsync(contract, methodName, args)
        let successfulCall: SuccessfulContractCall
        if ('gasEstimate' in estimatedCall) {
          successfulCall = estimatedCall
        } else {
          throw estimatedCall.error
        }

        return contract[methodName](...args, {
          gasLimit: calculateGasMargin(successfulCall.gasEstimate),
          from: account,
        })
          .then((response: TransactionResponse) => {
            const tokenASymbol = cleanCurrencySymbol(amountAMin.currency)
            const tokenBSymbol = cleanCurrencySymbol(amountBMin.currency)

            const actionText = `Removed liquidity for pair ${tokenASymbol}-${tokenBSymbol}.`
            addTransaction(response, {
              summary: actionText,
            })

            return response.hash
          })
          .catch((error: any) => {
            // if the user rejected the tx, pass this along
            if (error?.code === USER_ERROR_CODES.REJECTED) {
              throw new Error('transaction-rejected')
            } else {
              // otherwise, the error was unexpected, and we need to convey that
              console.error(`Remove liquidity failed`, error, methodName, args)
              throw new Error(`Remove liquidity failed: ${error.message}`)
            }
          })
      },
      error: null,
    }
  }, [
    tokenA,
    tokenB,
    liquidity,
    amountAMin,
    amountBMin,
    approval,
    signatureData,
    account,
    library,
    chainId,
    contract,
    deadline,
    addTransaction,
    defaultAccount,
  ])
}

export function useDepositIntoPool(
  tokenA: string | undefined,
  tokenB: string | undefined,
  amountADesired: CurrencyAmount<Token> | undefined,
  amountBDesired: CurrencyAmount<Token> | undefined,
  allowedSlippage: number = INITIAL_ALLOWED_SLIPPAGE, // in bips
): { state: Web3CallbackState; callback: null | (() => Promise<string>); error: string | null } {
  const { account, chainId, library } = useActiveWeb3React()

  const contract = useDolomiteAmmRouterContract()

  const deadline = useTransactionDeadline()

  const addTransaction = useTransactionAdder()

  const defaultAccount = useDefaultMarginAccount()

  return useMemo(() => {
    if (!contract || !library || !account || !chainId || !tokenA || !tokenB || !amountADesired || !amountBDesired) {
      return {
        state: Web3CallbackState.INVALID,
        callback: null,
        error: 'Missing dependencies',
      }
    }
    const baseBigIntIsh = '10000'
    const base = new Fraction(baseBigIntIsh, baseBigIntIsh)
    const slippageDecimal = base.subtract(new Fraction(allowedSlippage.toString(10), baseBigIntIsh))

    const methodName = 'addLiquidity'

    const args = [
      {
        fromAccountNumber: defaultAccount.accountNumber.toString(),
        tokenA: tokenA,
        tokenB: tokenB,
        amountADesiredWei: amountADesired.quotient.toString(),
        amountBDesiredWei: amountBDesired.quotient.toString(),
        amountAMinWei: JSBI.divide(
          JSBI.multiply(amountADesired.quotient, slippageDecimal.numerator),
          slippageDecimal.denominator,
        ).toString(),
        amountBMinWei: JSBI.divide(
          JSBI.multiply(amountBDesired.quotient, slippageDecimal.numerator),
          slippageDecimal.denominator,
        ).toString(),
        deadline: deadline,
        balanceCheckFlag: BalanceCheckFlag.FromAccount,
      },
      account,
    ]

    return {
      state: Web3CallbackState.VALID,
      callback: async function onAddLiquidity(): Promise<string> {
        const estimatedCall = await estimateGasAsync(contract, methodName, args)

        let successfulCall: SuccessfulContractCall
        if ('gasEstimate' in estimatedCall) {
          successfulCall = estimatedCall
        } else {
          throw estimatedCall.error
        }

        return contract[methodName](...args, {
          gasLimit: calculateGasMargin(successfulCall.gasEstimate),
          from: account,
        })
          .then((response: TransactionResponse) => {
            const tokenASymbol = cleanCurrencySymbol(amountADesired.currency)
            const tokenBSymbol = cleanCurrencySymbol(amountBDesired.currency)
            const tokenAAmount = formatAmount(amountADesired)
            const tokenBAmount = formatAmount(amountBDesired)

            const actionText = `Added ${tokenAAmount} ${tokenASymbol} and ${tokenBAmount} ${tokenBSymbol} to ${tokenASymbol}-${tokenBSymbol} liquidity pool`
            addTransaction(response, {
              summary: actionText,
            })

            return response.hash
          })
          .catch((error: any) => {
            // if the user rejected the tx, pass this along
            if (error?.code === USER_ERROR_CODES.REJECTED) {
              throw new Error('transaction-rejected')
            } else {
              // otherwise, the error was unexpected, and we need to convey that
              console.error(`Add liquidity failed`, error, methodName, args)
              throw new Error(`Add liquidity failed: ${error.message}`)
            }
          })
      },
      error: null,
    }
  }, [
    tokenA,
    tokenB,
    amountADesired,
    amountBDesired,
    allowedSlippage,
    account,
    library,
    chainId,
    contract,
    deadline,
    addTransaction,
    defaultAccount,
  ])
}
