import { useCallback, useEffect, useMemo } from 'react'
import {
  updateDolomiteBalancesWithLoadingIndicator,
  updateFiatPricesWithLoadingIndicator,
  updateInterestRateData,
  updateMarketRiskData,
} from './actions'
import { useDispatch } from 'react-redux'
import { useLoadFiatPricesWithLoadingIndicatorData } from '../../hooks/useFiatValue'
import { Fraction } from '@dolomite-exchange/sdk-core'
import { useLoadMarketRiskInfoData } from '../../types/marketRiskInfoData'
import { InterestRateDataResponse, MarketRiskDataResponse } from './reducer'
import { useLoadInterestRateData } from '../../types/interestRateData'
import { TokenBalancesWithLoadingIndicator, useLoadDolomiteBalancesWithLoadingIndicatorData } from '../wallet/hooks'
import { useActiveWeb3React } from '../../hooks'
import { useAllActiveTokensArrayWithExternalTokens, useEther, useSerializedTokens } from '../../hooks/Tokens'
import { Token } from '@dolomite-exchange/v2-sdk'
import { ChainId } from '../../constants'

const MAX_UPDATE_FREQUENCY = 1000

export default function Updater(): null {
  const dispatch = useDispatch()
  const { account, chainId } = useActiveWeb3React()
  //const tokens = useAllActiveTokensArray()
  const ether = useEther()
  //const allTokenList = useAllActiveTokensArray()
  const allTokenListWithExternalTokens = useAllActiveTokensArrayWithExternalTokens()
  const currencyList = useMemo(() => [ether, ...allTokenListWithExternalTokens], [
    allTokenListWithExternalTokens,
    ether,
  ])
  const rawTokenList = useMemo(
    () =>
      currencyList.reduce<Token[]>((memo, currency) => {
        if (currency.isToken) {
          memo.push(currency.wrapped)
        }
        return memo
      }, []),
    [currencyList],
  )
  const tokenList = useSerializedTokens(rawTokenList)
  /*const [dolomiteBalanceData] = useDolomiteBalancesWithLoadingIndicator(account, tokens)*/
  const [fiatValueMapArbitrum, isLoadingFiatValuesArbitrum] = useLoadFiatPricesWithLoadingIndicatorData(
    undefined,
    ChainId.ARBITRUM_ONE,
  ) // Needs to aggregate across all chains
  const [fiatValueMapMantle, isLoadingFiatValuesMantle] = useLoadFiatPricesWithLoadingIndicatorData(
    undefined,
    ChainId.MANTLE,
  ) // Needs to aggregate across all chains
  const [fiatValueMapCurrent, isLoadingFiatValuesCurrent] = useLoadFiatPricesWithLoadingIndicatorData(
    undefined,
    chainId,
  ) // Needs to aggregate across all chains
  const marketRiskData = useLoadMarketRiskInfoData()
  const interestRateDataArbitrum = useLoadInterestRateData(ChainId.ARBITRUM_ONE) // Needs to aggregate across chains (check if needed)
  const interestRateDataMantle = useLoadInterestRateData(ChainId.MANTLE) // Needs to aggregate across chains (check if needed)
  const interestRateDataCurrent = useLoadInterestRateData(chainId) // Needs to aggregate across chains (check if needed)
  const dolomiteBalancesWithLoadingIndicatorData = useLoadDolomiteBalancesWithLoadingIndicatorData(account, tokenList)

  //const lastFiatPricesUpdate = useRef<number>(0)
  const fiatPricesArbitrumCallback = useCallback(
    (fiatPrices: Record<string, Fraction | undefined>, isLoadingFiatPrices: boolean) => {
      //const now = Date.now()
      //if (now - lastFiatPricesUpdate.current > MAX_UPDATE_FREQUENCY) {
      dispatch(
        updateFiatPricesWithLoadingIndicator({
          chainId: ChainId.ARBITRUM_ONE,
          mapData: {
            fiatPriceMap: fiatPrices,
            isLoading: isLoadingFiatPrices,
          },
        }),
      )
      //lastFiatPricesUpdate.current = now
      //}
    },
    [dispatch],
  )

  useEffect(() => {
    fiatPricesArbitrumCallback(fiatValueMapArbitrum, isLoadingFiatValuesArbitrum)
  }, [fiatPricesArbitrumCallback, fiatValueMapArbitrum, isLoadingFiatValuesArbitrum])

  //const lastFiatPricesUpdate = useRef<number>(0)
  const fiatPricesMantleCallback = useCallback(
    (fiatPrices: Record<string, Fraction | undefined>, isLoadingFiatPrices: boolean) => {
      //const now = Date.now()
      //if (now - lastFiatPricesUpdate.current > MAX_UPDATE_FREQUENCY) {
      dispatch(
        updateFiatPricesWithLoadingIndicator({
          chainId: ChainId.MANTLE,
          mapData: {
            fiatPriceMap: fiatPrices,
            isLoading: isLoadingFiatPrices,
          },
        }),
      )
      //lastFiatPricesUpdate.current = now
      //}
    },
    [dispatch],
  )

  useEffect(() => {
    fiatPricesMantleCallback(fiatValueMapMantle, isLoadingFiatValuesMantle)
  }, [fiatPricesMantleCallback, fiatValueMapMantle, isLoadingFiatValuesMantle])

  //const lastFiatPricesUpdate = useRef<number>(0)
  const fiatPricesCurrentCallback = useCallback(
    (fiatPrices: Record<string, Fraction | undefined>, isLoadingFiatPrices: boolean) => {
      //const now = Date.now()
      //if (now - lastFiatPricesUpdate.current > MAX_UPDATE_FREQUENCY) {
      dispatch(
        updateFiatPricesWithLoadingIndicator({
          chainId: chainId,
          mapData: {
            fiatPriceMap: fiatPrices,
            isLoading: isLoadingFiatPrices,
          },
        }),
      )
      //lastFiatPricesUpdate.current = now
      //}
    },
    [chainId, dispatch],
  )

  useEffect(() => {
    fiatPricesCurrentCallback(fiatValueMapCurrent, isLoadingFiatValuesCurrent)
  }, [fiatPricesCurrentCallback, fiatValueMapCurrent, isLoadingFiatValuesCurrent])

  //const lastMarketRiskUpdate = useRef<number>(0)
  const marketRiskCallback = useCallback(
    (marketRiskData: MarketRiskDataResponse) => {
      //const now = Date.now()
      //if (now - lastMarketRiskUpdate.current > MAX_UPDATE_FREQUENCY) {
      dispatch(updateMarketRiskData(marketRiskData))
      //  lastMarketRiskUpdate.current = now
      //}
    },
    [dispatch],
  )

  useEffect(() => {
    marketRiskCallback(marketRiskData)
  }, [marketRiskCallback, marketRiskData])

  //const lastInterestRateUpdate = useRef<number>(0)
  const interestRateCallbackArbitrum = useCallback(
    (InterestRateData: InterestRateDataResponse) => {
      //const now = Date.now()
      //if (now - lastInterestRateUpdate.current > MAX_UPDATE_FREQUENCY) {
      dispatch(updateInterestRateData({ chainId: ChainId.ARBITRUM_ONE, interestData: InterestRateData }))
      //  lastInterestRateUpdate.current = now
      //}
    },
    [dispatch],
  )

  useEffect(() => {
    interestRateCallbackArbitrum(interestRateDataArbitrum)
  }, [interestRateCallbackArbitrum, interestRateDataArbitrum])

  //const lastInterestRateUpdate = useRef<number>(0)
  const interestRateCallbackMantle = useCallback(
    (InterestRateData: InterestRateDataResponse) => {
      //const now = Date.now()
      //if (now - lastInterestRateUpdate.current > MAX_UPDATE_FREQUENCY) {
      dispatch(updateInterestRateData({ chainId: ChainId.MANTLE, interestData: InterestRateData }))
      //  lastInterestRateUpdate.current = now
      //}
    },
    [dispatch],
  )

  useEffect(() => {
    interestRateCallbackMantle(interestRateDataMantle)
  }, [interestRateCallbackMantle, interestRateDataMantle])

  //const lastInterestRateUpdate = useRef<number>(0)
  const interestRateCallbackCurrent = useCallback(
    (InterestRateData: InterestRateDataResponse) => {
      //const now = Date.now()
      //if (now - lastInterestRateUpdate.current > MAX_UPDATE_FREQUENCY) {
      dispatch(updateInterestRateData({ chainId: chainId, interestData: InterestRateData }))
      //  lastInterestRateUpdate.current = now
      //}
    },
    [chainId, dispatch],
  )

  useEffect(() => {
    interestRateCallbackCurrent(interestRateDataCurrent)
  }, [interestRateCallbackCurrent, interestRateDataCurrent])

  //const lastDolomiteBalancesUpdate = useRef<number>(0)
  const dolomiteBalancesCallback = useCallback(
    (dolomiteBalancesData: TokenBalancesWithLoadingIndicator) => {
      //const now = Date.now()
      //if (now - lastDolomiteBalancesUpdate.current > MAX_UPDATE_FREQUENCY) {
      dispatch(updateDolomiteBalancesWithLoadingIndicator(dolomiteBalancesData))
      //  lastDolomiteBalancesUpdate.current = now
      //}
    },
    [dispatch],
  )

  useEffect(() => {
    dolomiteBalancesCallback(dolomiteBalancesWithLoadingIndicatorData)
  }, [dolomiteBalancesCallback, dolomiteBalancesWithLoadingIndicatorData])
  /*const blockTimestampCallback = useCallback(
    (chainId: ChainId, blockTimestamp: number) => {
      dispatch(
        updateBlockTimestamp({
          chainId,
          blockTimestamp,
        }),
      )
    },
    [dispatch],
  )

  const subgraphBlockNumberCallback = useCallback(
    (chainId: ChainId, subgraphBlockNumber: number) => {
      dispatch(
        updateSubgraphBlockNumber({
          chainId,
          subgraphBlockNumber,
        }),
      )
    },
    [dispatch],
  )

  // attach/detach listeners
  useEffect(() => {
    if (!library || !windowVisible) {
      return undefined
    }

    getLatestBlockFromLibraryAndPerformCallback(library, blockNumberCallback)

    const intervalId = setInterval(() => {
      getLatestBlockFromLibraryAndPerformCallback(library, blockNumberCallback)
    }, 5_000)

    return () => {
      clearInterval(intervalId)
    }
  }, [blockNumberCallback, library, windowVisible])

  useEffect(() => {
    if (!windowVisible) {
      return undefined
    }

    ACTIVE_NETWORKS.forEach(chainId =>
      getLatestBlockFromSubgraphAndPerformCallback(dolomiteSubgraphClient, subgraphBlockNumberCallback, chainId),
    )

    const intervalId = setInterval(() => {
      ACTIVE_NETWORKS.forEach(chainId =>
        getLatestBlockFromSubgraphAndPerformCallback(dolomiteSubgraphClient, subgraphBlockNumberCallback, chainId),
      )
    }, 2_500)

    return () => {
      clearInterval(intervalId)
    }
  }, [subgraphBlockNumberCallback, windowVisible])

  const multicall = useMulticallContract()
  const blockTimestampResult = useSingleCallResult(multicall, 'getCurrentBlockTimestamp')

  const blockTimestampChainId = blockTimestampResult.chainId
  const blockTimestamp = blockTimestampResult.result?.[0]?.toNumber() ?? 0
  useEffect(() => {
    blockTimestampCallback(blockTimestampChainId, blockTimestamp)
  }, [blockTimestamp, blockTimestampCallback, blockTimestampChainId])

  const [chainId, libraryBlockNumber, subgraphBlockNumber] = useSelector((state: AppState) => [
    state.chain.chainId,
    state.chain.blockNumberMap[state.chain.chainId],
    state.chain.subgraphBlockNumberMap[state.chain.chainId],
  ])
  useEffect(() => {
    if (!windowVisible) {
      return
    }

    if (
      subgraphBlockNumber &&
      libraryBlockNumber &&
      libraryBlockNumber - subgraphBlockNumber > getSubgraphLagThreshold(chainId)
    ) {
      const didIncrement = incrementUrlIndexByClientType(GraphqlClientType.Dolomite, dispatch, chainId)
      if (didIncrement) {
        console.warn(
          'Subgraph block number is too far behind head, incrementing url index',
          subgraphBlockNumber,
          libraryBlockNumber,
        )
      }
    }
  }, [windowVisible, dispatch, chainId, libraryBlockNumber, subgraphBlockNumber])

  useEffect(() => {
    dispatch(updateChainId({ chainId: web3ChainId }))
  }, [dispatch, web3ChainId])*/

  return null
}
