import { Currency, Fraction, Token } from '@dolomite-exchange/v2-sdk'
import React, { useCallback, useMemo } from 'react'
import { ConfirmationModalContent, TransactionErrorContent } from '../TransactionConfirmationModal'
import { CurrencyAmount } from '@dolomite-exchange/sdk-core'
import { ChainId } from '../../constants'
import { AutoRow } from '../Row'
import {
  Circle,
  Complete,
  IsolationDescription,
  Line,
  LoadingWrapper,
  SubmitButton,
  Subtitle,
  Title,
  TopWrapper,
  UnlockProgress as ZapProgress,
  UnlockStep as ZapStep,
  UnlockText as ZapText,
} from './styled'
import { Text } from 'rebass'
import cleanCurrencySymbol from '../../utils/cleanCurrencySymbol'
import { getSpecialAsset } from '../../constants/isolation/special-assets'
import { ReactComponent as LockIcon } from '../../assets/images/shieldIcon.svg'
import { useActiveWeb3React } from '../../hooks'
import Checkmark from '@material-ui/icons/Check'
import CircularProgress from '@material-ui/core/CircularProgress'
import { formatAmount } from '../../utils/formatAmount'
import Modal from '../Modal'
import { ZapStage } from '../../pages/Borrow/OpenNewBorrow'

function BorrowModalHeader({
  symbol0,
  symbol1,
  vaultStep,
  amount0,
  amount1,
  fiatAmount,
  modalStage,
  onConfirm,
}: {
  symbol0: string
  symbol1: string
  vaultStep: boolean
  amount0: CurrencyAmount<Currency> | undefined
  amount1: CurrencyAmount<Currency> | undefined
  fiatAmount: Fraction | undefined
  modalStage: ZapStage
  onConfirm: () => void
}) {
  return (
    <TopWrapper isCompleted={modalStage === ZapStage.COMPLETE}>
      {vaultStep && (
        <ZapStep expanded={modalStage === ZapStage.VAULT}>
          <ZapProgress>
            <Circle complete={modalStage > ZapStage.VAULT}>
              {modalStage === ZapStage.VAULT ? '1' : <Checkmark />}
            </Circle>
            <Line expanded={modalStage === ZapStage.VAULT} complete={modalStage > ZapStage.VAULT} />
          </ZapProgress>
          <ZapText>
            <Title>Create Vault</Title>
            <Subtitle
              visible={modalStage === ZapStage.VAULT}
            >{`Creating a vault for ${symbol1} enables Dolomite to access and deposit the ${symbol1} into a position`}</Subtitle>
          </ZapText>
        </ZapStep>
      )}
      <ZapStep expanded={modalStage === ZapStage.ZAP}>
        <ZapProgress>
          <Circle complete={modalStage > ZapStage.ZAP}>
            {modalStage < ZapStage.COMPLETE ? vaultStep ? '3' : '2' : <Checkmark />}
          </Circle>
        </ZapProgress>
        <ZapText>
          <Title>Zap</Title>
          <Subtitle visible={modalStage === ZapStage.ZAP}>
            {`Zapping ${formatAmount(amount0)} ${symbol0} into ${amount1 ? formatAmount(amount1) : '0.00'} ${symbol1}`}
          </Subtitle>
        </ZapText>
      </ZapStep>
      <Complete visible={modalStage === ZapStage.COMPLETE}>Zap Complete</Complete>
    </TopWrapper>
  )
}

function BorrowModalFooter({
  amount,
  token,
  fiatAmount,
  modalStage,
  onConfirm,
  chainId,
  attemptingTxn,
}: {
  amount: CurrencyAmount<Currency> | undefined
  token: Token
  fiatAmount: Fraction | undefined
  modalStage: ZapStage
  onConfirm: () => void
  chainId: ChainId
  attemptingTxn: boolean
}) {
  return (
    <>
      {getSpecialAsset(chainId, token) && (
        <IsolationDescription>
          <div>
            <LockIcon />
          </div>
          <div>
            {`Isolation Mode assets are deployed as wrapped contracts around the listed asset. So the contract address you see on block explorers may appear different.`}
            <a href={'https://docs.dolomite.io/risk-mitigation#isolation-mode'} target={'_blank'} rel={'noreferrer'}>
              Learn more
            </a>
          </div>
        </IsolationDescription>
      )}
      {attemptingTxn && <>Confirm this transaction in your wallet.</>}
      <AutoRow>
        <SubmitButton onClick={() => !attemptingTxn && onConfirm()} style={{ margin: '10px 0 0 0' }}>
          {attemptingTxn ? (
            <LoadingWrapper>
              <CircularProgress />
            </LoadingWrapper>
          ) : (
            <Text fontSize={20} fontWeight={500}>
              {modalStage === ZapStage.VAULT ? 'Create Vault' : modalStage === ZapStage.ZAP ? 'Confirm Zap' : 'Close'}
            </Text>
          )}
        </SubmitButton>
      </AutoRow>
    </>
  )
}

export default function ConfirmBorrowModal({
  token0,
  token1,
  vaultStep,
  amount0,
  amount1,
  fiatAmount,
  onConfirm,
  onDismiss,
  modalStage,
  loading,
  errorMessage,
  isOpen,
  attemptingTxn,
  txHash,
}: {
  token0: Token
  token1: Token
  vaultStep: boolean
  amount0: CurrencyAmount<Currency> | undefined
  amount1: CurrencyAmount<Currency> | undefined
  fiatAmount: Fraction | undefined
  attemptingTxn: boolean
  txHash: string | undefined
  modalStage: ZapStage
  loading: boolean
  isOpen: boolean
  onConfirm: () => void
  errorMessage: string | undefined
  onDismiss: () => void
}) {
  const symbol0 = useMemo(() => cleanCurrencySymbol(token0), [token0])
  const symbol1 = useMemo(() => cleanCurrencySymbol(token1), [token1])

  const { chainId } = useActiveWeb3React()
  const pendingText = useMemo(() => {
    if (!amount0) {
      return ''
    } else if (modalStage === ZapStage.VAULT) {
      return `Creating ${symbol1} vault`
    }

    return `Zapping ${formatAmount(amount0)} ${symbol0} into ${formatAmount(amount1)} ${symbol1}`
  }, [amount0, amount1, symbol0, symbol1, modalStage])
  const modalHeader = useCallback(() => {
    return amount0 ? (
      <BorrowModalHeader
        symbol0={symbol0 ?? ''}
        symbol1={symbol1 ?? ''}
        vaultStep={vaultStep}
        amount0={amount0}
        amount1={amount1}
        fiatAmount={fiatAmount}
        modalStage={modalStage}
        onConfirm={onConfirm}
      />
    ) : null
  }, [symbol0, symbol1, vaultStep, amount0, amount1, modalStage, fiatAmount, onConfirm])

  const modalBottom = useCallback(() => {
    return amount0 ? (
      <BorrowModalFooter
        amount={amount0}
        token={token1}
        fiatAmount={fiatAmount}
        modalStage={modalStage}
        onConfirm={onConfirm}
        chainId={chainId}
        attemptingTxn={attemptingTxn}
      />
    ) : null
  }, [amount0, token1, modalStage, onConfirm, fiatAmount, chainId, attemptingTxn])

  const confirmationContent = useCallback(
    () =>
      errorMessage ? (
        <TransactionErrorContent onDismiss={onDismiss} message={errorMessage} />
      ) : (
        <ConfirmationModalContent
          title={`Zap ${symbol0} to ${symbol1}`}
          onDismiss={onDismiss}
          topContent={modalHeader}
          bottomContent={modalBottom}
        />
      ),
    [errorMessage, onDismiss, modalHeader, modalBottom, symbol0, symbol1],
  )

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} maxHeight={90} maxWidthPx={400}>
      {confirmationContent()}
    </Modal>
  )
}
