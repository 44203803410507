import React from 'react'
import styled from 'styled-components/macro'
import { escapeRegExp } from '../../utils'

const StyledInput = styled.input<{ error?: boolean; fontSize?: string; align?: string }>`
  color: ${({ error, theme }) => (error ? theme.red1 : theme.text1)};
  width: 0;
  position: relative;
  font-weight: 500;
  outline: none;
  border: none;
  flex: 1 1 auto;
  background-color: ${({ theme }) => theme.bg6};
  font-size: ${({ fontSize }) => fontSize ?? '24px'};
  text-align: ${({ align }) => align && align};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0;
  -webkit-appearance: textfield;

  ::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  [type='number'] {
    -moz-appearance: textfield;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  ::placeholder {
    color: ${({ theme }) => theme.text3};
  }

  :invalid {
    box-shadow: none;
  }
`

const Unit = styled.div`
  color: ${({ theme }) => theme.text2};
`

export const Input = React.memo(function InnerInput({
  value,
  onUserInput,
  placeholder,
  unit,
  maxDecimals,
  ...rest
}: {
  value: string | number
  onUserInput: (input: string) => void
  error?: boolean
  fontSize?: string
  align?: 'right' | 'left'
  unit?: string
  maxDecimals: number
} & Omit<React.HTMLProps<HTMLInputElement>, 'ref' | 'onChange' | 'as'>) {
  // match escaped "." characters via in a non-capturing group
  const inputRegex = RegExp(`^\\d*(?:\\\\[.])?\\d{0,${maxDecimals}}$`)
  const enforcer = (nextUserInput: string) => {
    if (nextUserInput === '' || inputRegex.test(escapeRegExp(nextUserInput))) {
      onUserInput(nextUserInput)
    }
  }

  return (
    <>
      <StyledInput
        {...rest}
        value={value}
        onChange={event => {
          // replace commas with periods, because we exclusively uses period as the decimal separator
          enforcer(event.target.value.replace(/,/g, '.'))
        }}
        // universal input options
        inputMode='decimal'
        title='Token Amount'
        autoComplete='off'
        autoCorrect='off'
        // text-specific options
        type='text'
        pattern={`^[0-9]*[.,]?[0-9]{0,${maxDecimals}}$`}
        placeholder={placeholder ?? '0.0'}
        minLength={1}
        maxLength={79}
        spellCheck='false'
      />
      {unit && <Unit style={{ cursor: rest.disabled ? 'not-allowed' : 'text' }}>{unit}</Unit>}
    </>
  )
})

export default Input

// const inputRegex = RegExp(`^\\d*(?:\\\\[.])?\\d*$`) // match escaped "." characters via in a non-capturing group
