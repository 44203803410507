import React from 'react'
import styled from 'styled-components/macro'

const TooltipWrapper = styled.div<{ wrapperClassName?: string }>`
  position: relative;
  display: inline-block;
  width: fit-content;
  text-align: center;

  ${({ wrapperClassName }) => wrapperClassName}
`

const TooltipContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  /*bottom: 0;
  margin-bottom: 50%;*/
`

const TooltipInner = styled.div`
  .tooltipInner {
    position: absolute;
    bottom: calc(100% + 10px);
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 999;
    pointer-events: none;
`

const TooltipBox = styled.div<{ boxClassName?: string }>`
  height: fit-content;
  line-height: 20px;
  background-color: #3a3a4f;
  font-weight: 100;
  font-size: 12px;
  padding: 3px 10px;
  border-radius: 5px;
  position: relative;
  /*top: -80px;margin-top: -40%;*/
  opacity: 0;
  pointer-events: none;
  transition: all 0.1s ease-in-out;
  z-index: 999;
  width: max-content;
  max-width: 250px;
  left: 50%;
  transform: translateX(-50%);

  ${({ boxClassName }) => boxClassName}
`

export default function Tooltip({
  className,
  boxClassName,
  title,
  children,
}: {
  className?: string
  boxClassName?: string
  title: string
  children?: JSX.Element
}) {
  return (
    <TooltipWrapper wrapperClassName={className}>
      <TooltipContainer>
        <TooltipInner>
          <TooltipBox boxClassName={boxClassName}>{title}</TooltipBox>
        </TooltipInner>
      </TooltipContainer>
      {children}
    </TooltipWrapper>
  )
}
