import React from 'react'
import styled from 'styled-components/macro'
import TradingViewWidget, { Themes, BarStyles } from 'react-tradingview-widget'
import { Field } from '../../state/trade/actions'
import { useTradeState } from '../../state/trade/hooks'
import { useToken } from '../../hooks/Tokens'
import { deriveMarketFromTokens } from '../../utils/marketUtils'
import { StyledTooltip } from '../../components/common/StyledTooltip'
import cleanCurrencySymbol from 'utils/cleanCurrencySymbol'

const ChartWrapper = styled.div`
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  max-height: calc(100vh - 300px);
  display: inline-block;
  vertical-align: top;
  padding-top: 10px;
  padding-left: 20px;
  position: relative;
  z-index: 0;

  > article {
    position: absolute;
    z-index: 3;
    top: 10px;
    left: 20px;
    height: calc(100% - 10px) !important;
    width: calc(100% - 20px) !important;
  }

  @media screen and (min-width: 1440px) {
    width: calc(100% - 320px);
  }

  @media screen and (max-width: 1440px) {
    width: calc(100% - 320px);
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    /*margin-bottom: 6px;*/
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 0;
    width: calc(100% + 20px);
    margin-left: -10px;
    height: 250px;

    > article {
      position: absolute;
      z-index: 3;
      top: 0;
      left: 0;
      height: calc(100%) !important;
      width: calc(100%) !important;
    }
  `};
`

const LoadingIndicator = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
`

const BorderEraser = styled.div`
  position: absolute;
  width: calc(100% - 20px);
  height: calc(100% - 10px);
  top: 10px;
  border: 1px solid #1a1a25;
  pointer-events: none;
  z-index: 4;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 0;
    width: calc(100%);
    height: 250px;
    top: 0;
  `};
`

const PriceBorderEraser = styled(BorderEraser)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: calc(100% - 64px);
    border-right: none;
  `};
`

const DateBorderEraser = styled(BorderEraser)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    height: calc(100% - 28px);
  `};
`

const ExplanationText = styled.div`
  font-size: 10px;
  font-weight: 400;
  color: ${({ theme }) => theme.text3};
  position: absolute;
  bottom: -12px;
  /*left: calc(50% - 192px);*/
  right: 0;
  cursor: default;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    /*right: 4px;
    font-size: 9px;*/
    display: none;
  `};
`

export default function TradingViewChart({ isMobile }: { isMobile?: boolean }) {
  const {
    [Field.INPUT]: { currencyId: inputCurrencyId },
    [Field.OUTPUT]: { currencyId: outputCurrencyId },
  } = useTradeState()

  const inputToken = useToken(inputCurrencyId)
  const outputToken = useToken(outputCurrencyId)

  const inputTokenSymbol = inputToken?.symbol?.replace('USDC.e', 'USDC')
  const outputTokenSymbol = outputToken?.symbol?.replace('USDC.e', 'USDC')

  const hasUsdc = [inputTokenSymbol, outputTokenSymbol].includes('USDC')
  const market = deriveMarketFromTokens(inputToken, outputToken)
  const firstMarket = cleanCurrencySymbol(market?.primaryToken)
  const secondMarket = cleanCurrencySymbol(market?.secondaryToken)
  const firstPart = `COINBASE:${firstMarket}USD`
  const secondPart = hasUsdc ? '' : `/COINBASE:${secondMarket}USD`
  let override: string | undefined = undefined
  if (firstMarket?.includes('ETH') && secondMarket?.includes('BTC')) {
    override = 'COINBASE:ETHBTC'
  } else if (firstMarket?.includes('LINK') && secondMarket?.includes('BTC')) {
    override = 'COINBASE:LINKBTC'
  }

  return (
    <ChartWrapper>
      {isMobile ? (
        <TradingViewWidget
          symbol={override ? override : `${firstPart}${secondPart}`}
          theme={Themes.DARK}
          locale={'en'}
          allow_symbol_change={false}
          style={BarStyles.CANDLES}
          // hide_top_toolbar={false} // TODO enable this on mobile to change chart interval
          hide_top_toolbar={true}
          hide_legend={true}
          enable_publishing={false}
          save_image={false}
          hide_side_toolbar={true}
          interval={'60'}
          autosize
        />
      ) : (
        <TradingViewWidget
          symbol={override ? override : `${firstPart}${secondPart}`}
          theme={Themes.DARK}
          locale={'en'}
          allow_symbol_change={false}
          interval={'60'}
          autosize
        />
      )}
      <LoadingIndicator>Loading chart...</LoadingIndicator>
      <BorderEraser />
      {isMobile && <PriceBorderEraser />}
      {/*isMobile && <DateBorderEraser />*/}
      {/*<ExplanationText>
        This chart is uses Coinbase trade data to provide a more accurate view of the overall market
      </ExplanationText>*/}
      <StyledTooltip
        title={
          'This chart uses data from Coinbase to provide price data that better matches the overall crypto market.'
        }
        placement={'top'}
      >
        <ExplanationText>Why is the price on the chart different?</ExplanationText>
      </StyledTooltip>
    </ChartWrapper>
  )
}
