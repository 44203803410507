import { useMemo } from 'react'
import { gql } from '@apollo/client'
import { CurrencyAmount, Token } from '@dolomite-exchange/v2-sdk'
import { useAllTokens } from '../hooks/Tokens'
import { reqParseAmount } from '../state/trade/hooks'
import { toChecksumAddress } from '../utils/toChecksumAddress'
import { address } from '@dolomite-exchange/dolomite-margin/dist/src/types'
import { NO_VARIABLES, useGraphqlResult } from '../state/graphql/hooks'
import { GraphqlClientType } from '../state/graphql/actions'
import { RefreshFrequency } from '../state/chain/hooks'

const DOLOMITE_MARGIN_TOKENS_TVL_GQL = gql`
  query tokenTvls($blockNumber: Int!) {
    tokens(block: { number_gte: $blockNumber }) {
      id
      supplyLiquidity
      borrowLiquidity
    }
  }
`

interface TokenTvlGql {
  id: string
  supplyLiquidity: string
  borrowLiquidity: string
}

export interface TokenTvl {
  address: string
  supplyLiquidity: CurrencyAmount<Token>
  borrowLiquidity: CurrencyAmount<Token>
}

interface TokenTvlResponse {
  tokens: TokenTvlGql[]
}

export function useDolomiteMarginTokenTvlData(): {
  loading: boolean
  error: boolean
  data: Record<address, TokenTvl | undefined>
} {
  const queryState = useGraphqlResult<TokenTvlResponse>(
    GraphqlClientType.Dolomite,
    DOLOMITE_MARGIN_TOKENS_TVL_GQL.loc!.source.body,
    NO_VARIABLES,
    RefreshFrequency.Slow,
  )
  const tokenMap = useAllTokens()

  return useMemo(() => {
    const { loading, error, result } = queryState
    const anyLoading = Boolean(loading)
    const anyError = Boolean(error)

    const tokens = (result?.tokens ?? []).reduce<Record<string, TokenTvl>>((memo, gql) => {
      const tokenAddress = toChecksumAddress(gql.id)
      const token = tokenMap[tokenAddress]
      if (token) {
        memo[tokenAddress] = {
          address: tokenAddress,
          supplyLiquidity: reqParseAmount(gql.supplyLiquidity, token),
          borrowLiquidity: reqParseAmount(gql.borrowLiquidity, token),
        }
      }
      return memo
    }, {})

    return {
      loading: anyLoading,
      error: anyError,
      data: tokens,
    }
  }, [queryState, tokenMap])
}
