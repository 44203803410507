import { AmmPairHourData } from '../types/ammPairHourData'
import { Pair } from '@dolomite-exchange/v2-sdk'
import { Fraction } from '@dolomite-exchange/sdk-core'
import JSBI from 'jsbi'
import { maxBy, sumBigIntegers, sumFractions } from './numberOperations'

export interface SummedAmmPairData {
  pair: Pair
  reserve0: Fraction
  reserve1: Fraction
  reserveUSD: Fraction
  volumeToken0: Fraction
  volumeToken1: Fraction
  volumeUSD: Fraction
  transactionCount: JSBI
}

export default function sumPairHourData(pair: Pair, pair24HourData: AmmPairHourData[]): SummedAmmPairData {
  return {
    pair,
    reserve0: maxBy<AmmPairHourData>(pair24HourData, data => new Fraction(data.hourStartUnix))!.reserve0,
    reserve1: maxBy<AmmPairHourData>(pair24HourData, data => new Fraction(data.hourStartUnix))!.reserve1,
    reserveUSD: maxBy<AmmPairHourData>(pair24HourData, data => new Fraction(data.hourStartUnix))!.reserveUSD,
    volumeToken0: sumFractions<AmmPairHourData>(pair24HourData, data => data.hourlyVolumeToken0),
    volumeToken1: sumFractions<AmmPairHourData>(pair24HourData, data => data.hourlyVolumeToken1),
    volumeUSD: sumFractions<AmmPairHourData>(pair24HourData, data => data.hourlyVolumeUSD),
    transactionCount: sumBigIntegers<AmmPairHourData>(pair24HourData, data => data.hourlyTransactionCount),
  }
}
