import { Currency } from '@dolomite-exchange/sdk-core'
import { getSpecialAsset } from '../constants/isolation/special-assets'
import { ChainId } from '../constants'
import { ChainIdMap } from '../constants/chainId'

const keysToReplacements: ChainIdMap<Record<string, string | undefined>> = {
  [ChainId.MAINNET]: { WETH: 'ETH' },
  [ChainId.ARBITRUM_ONE]: { WETH: 'ETH' },
  [ChainId.BASE]: { WETH: 'ETH' },
  [ChainId.BERACHAIN]: { WBERA: 'BERA' },
  [ChainId.MANTLE]: { WMNT: 'MNT' },
  [ChainId.POLYGON_ZKEVM]: { WETH: 'ETH' },
  [ChainId.X_LAYER]: { WOKB: 'OKB' },
}

export default function cleanCurrencySymbol(
  currency: Currency | undefined,
  cleanWrappedCurrency: boolean = true,
): string | undefined {
  if (currency) {
    if (keysToReplacements[currency.chainId as ChainId][currency.symbol ?? ''] && !cleanWrappedCurrency) {
      return currency.symbol
    }
  }
  if (currency?.isToken && getSpecialAsset(currency.chainId, currency)) {
    return getSpecialAsset(currency.chainId, currency)?.cleanSymbol
  }
  return currency
    ? keysToReplacements[currency.chainId as ChainId][currency.symbol ?? ''] ?? currency?.symbol
    : undefined
}
