import { Fraction, Percent } from '@dolomite-exchange/sdk-core'
import { Currency, CurrencyAmount } from '@dolomite-exchange/v2-sdk'

export function fractionEqualsOpt(valueA: Fraction | undefined, valueB: Fraction | undefined): boolean {
  if (!valueA && !valueB) {
    return true
  } else if (!valueA || !valueB) {
    return false
  }

  return valueA.equalTo(valueB)
}

export function currencyAmountEqualsOpt(
  valueA: CurrencyAmount<Currency> | undefined,
  valueB: CurrencyAmount<Currency> | undefined,
): boolean {
  if (!valueA && !valueB) {
    return true
  } else if (!valueA || !valueB) {
    return false
  }

  return valueA.equalTo(valueB)
}

export function percentEqualsOpt(valueA: Percent | undefined, valueB: Percent | undefined): boolean {
  if (!valueA && !valueB) {
    return true
  } else if (!valueA || !valueB) {
    return false
  }

  return valueA.equalTo(valueB)
}
