import { Token } from '@dolomite-exchange/sdk-core'
import { ChainId, ChainIdMap } from '../chainId'

const decimals = 8
const symbol = 'WBTC'
const name = 'Wrapped BTC'

function wbtc(chainId: ChainId, address: string) {
  return new Token(chainId, address, decimals, symbol, name)
}

export const WBTC: ChainIdMap<Token | undefined> = {
  [ChainId.MAINNET]: wbtc(ChainId.MAINNET, '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599'),
  [ChainId.ARBITRUM_ONE]: wbtc(ChainId.ARBITRUM_ONE, '0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f'),
  [ChainId.BASE]: undefined,
  [ChainId.BERACHAIN]: undefined,
  [ChainId.MANTLE]: wbtc(ChainId.MANTLE, '0xCAbAE6f6Ea1ecaB08Ad02fE02ce9A44F09aebfA2'),
  [ChainId.POLYGON_ZKEVM]: wbtc(ChainId.POLYGON_ZKEVM, '0xEA034fb02eB1808C2cc3adbC15f447B93CbE08e1'),
  [ChainId.X_LAYER]: wbtc(ChainId.X_LAYER, '0xEA034fb02eB1808C2cc3adbC15f447B93CbE08e1'),
}
