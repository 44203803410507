import { useWethToken } from '../Tokens'
import { useFiatPricesWithLoadingIndicator } from '../useFiatValue'
import { useMemo } from 'react'
import { useGmxBalanceWithSupplies, useGmxDepositInfo, useGmxStakingInfos, useGmxToken } from './useGmxProtocol'
import { BASIS_POINTS_DIVISOR } from '../../utils/processGmxAndGlpData'
import { SECONDS_PER_YEAR, ZERO_FRACTION, ZERO_PERCENT } from '../../constants'
import { Percent } from '@dolomite-exchange/v2-sdk'
import useGlpProxyVaultAccount from './useGlpProxyVaultAccount'
import { InterestRatePart, InterestRatePartCategory } from '../../types/InterestRatePart'

export default function useGmxYield(): InterestRatePart[] {
  const vaultAccount = useGlpProxyVaultAccount()
  const gmxToken = useGmxToken()
  const wethToken = useWethToken()
  const [fiatPriceMap] = useFiatPricesWithLoadingIndicator()
  const gmxPrice = fiatPriceMap[gmxToken?.address ?? '']
  const wethPrice = fiatPriceMap[wethToken.address]
  const [stakingData] = useGmxStakingInfos(vaultAccount)
  const [balanceAndSupplyData] = useGmxBalanceWithSupplies(vaultAccount)
  const [depositBalanceData] = useGmxDepositInfo(vaultAccount)

  return useMemo(() => {
    if (
      !balanceAndSupplyData?.supplyData.stakedGmxTracker ||
      !depositBalanceData?.bnGmxInFeeGmx ||
      !depositBalanceData.stakedBnGmxInFeeGmx ||
      !stakingData?.stakedGmxTracker ||
      !stakingData.extendedGmxFeeTracker ||
      !gmxPrice ||
      !wethPrice
    ) {
      return []
    }

    const stakedGmxTrackerSupplyUsd = balanceAndSupplyData.supplyData.stakedGmxTracker.multiply(gmxPrice)
    const stakedGmxTrackerAnnualRewardsUsd = stakingData.stakedGmxTracker.tokensPerInterval
      .multiply(SECONDS_PER_YEAR)
      .multiply(gmxPrice)
    const extendedGmxFeeTrackerSupplyUsd = stakingData.extendedGmxFeeTracker.totalSupply.multiply(gmxPrice)

    let gmxAprForEsGmx = ZERO_PERCENT
    if (stakedGmxTrackerSupplyUsd.greaterThan(ZERO_FRACTION)) {
      const rawApr = stakedGmxTrackerAnnualRewardsUsd.multiply(BASIS_POINTS_DIVISOR).divide(stakedGmxTrackerSupplyUsd)
      gmxAprForEsGmx = new Percent(rawApr.numerator, rawApr.denominator)
    }
    const extendedGmxFeeTrackerAnnualRewardsUsd = stakingData.extendedGmxFeeTracker.tokensPerInterval
      .multiply(SECONDS_PER_YEAR)
      .multiply(gmxPrice)

    let gmxAprForNativeToken = ZERO_PERCENT
    if (extendedGmxFeeTrackerSupplyUsd.greaterThan(ZERO_FRACTION)) {
      const rawApr = extendedGmxFeeTrackerAnnualRewardsUsd
        .multiply(BASIS_POINTS_DIVISOR)
        .divide(extendedGmxFeeTrackerSupplyUsd)
      gmxAprForNativeToken = new Percent(rawApr.numerator, rawApr.denominator)
    }

    let boostPercentage = ZERO_PERCENT
    if (depositBalanceData.stakedBnGmxInFeeGmx.greaterThan(ZERO_FRACTION)) {
      const boostFraction = depositBalanceData.bnGmxInFeeGmx.divide(depositBalanceData.stakedBnGmxInFeeGmx)
      boostPercentage = new Percent(boostFraction.numerator, boostFraction.denominator)
    }

    const gmxBoostAprForNativeToken = gmxAprForNativeToken.multiply(boostPercentage).divide(BASIS_POINTS_DIVISOR)
    return [
      {
        label: 'GMX Base Yield',
        interestRate: gmxAprForNativeToken,
        isBorrowRateImpacted: false,
        metadata: null,
        rewardToken: gmxToken,
        category: InterestRatePartCategory.NATIVE_YIELD,
        subcategory: undefined,
        rewardClaimUrl: undefined,
      },
      {
        label: 'GMX Boosted Yield',
        interestRate: gmxBoostAprForNativeToken,
        isBorrowRateImpacted: false,
        metadata: null,
        rewardToken: gmxToken,
        category: InterestRatePartCategory.NATIVE_YIELD,
        subcategory: undefined,
        rewardClaimUrl: undefined,
      },
      {
        label: 'esGMX Yield',
        interestRate: gmxAprForEsGmx,
        isBorrowRateImpacted: false,
        metadata: null,
        rewardToken: undefined,
        category: InterestRatePartCategory.NATIVE_YIELD,
        subcategory: undefined,
        rewardClaimUrl: undefined,
      },
    ]
  }, [balanceAndSupplyData, depositBalanceData, gmxToken, gmxPrice, stakingData, wethPrice])
}
