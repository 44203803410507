import { useMemo } from 'react'
import {
  createCurrencyAmount,
  createFraction,
  createFractionUSD,
  Token,
  TokenHourData as TokenHourDataGql,
} from './gqlTypeHelpers'
import { Fraction, Percent, Token as SdkToken } from '@dolomite-exchange/sdk-core'
import { useAllActiveTokensArray } from '../hooks/Tokens'
import { useDefaultFiatValuesWithLoadingIndicator } from '../hooks/useFiatValue'
import { toChecksumAddress } from '../utils/toChecksumAddress'
import cleanCurrencyName from '../utils/cleanCurrencyName'
import calculatePercentageChange from '../utils/calculatePercentageChange'
import { ZERO_FRACTION, ZERO_PERCENT } from '../constants'
import { NO_VARIABLES, useGraphqlResult } from '../state/graphql/hooks'
import { GraphqlClientType } from '../state/graphql/actions'
import { isTokenIgnored } from '../constants/isolation/special-assets'
import { RefreshFrequency } from '../state/chain/hooks'
import { useShowInactiveTokens } from '../state/user/hooks'
import { DOLOMITE_API_SERVER_URL } from '@dolomite-exchange/zap-sdk'
import { useActiveWeb3React } from '../hooks'

interface TopTokenResponse {
  tokens: Token[]
}

interface TokenHourDataResponse {
  tokenHourDatas: TokenHourDataGql[]
}

interface DisplayData {
  open: string | undefined
  close: string | undefined
  volume: Fraction
}

export interface TopTokenData {
  id: string
  token: SdkToken
  symbol: string
  name: string
  volume: Fraction
  priceUSD: Fraction
  borrowLiquidity: Fraction
  borrowLiquidityUSD: Fraction
  supplyLiquidity: Fraction
  supplyLiquidityUSD: Fraction
  poolTvl: Fraction
  change: Percent
}

export function useTopTokenData(): {
  loading: boolean
  error: boolean
  data: TopTokenData[]
} {
  const { chainId } = useActiveWeb3React()
  const tokenList = useAllActiveTokensArray()
  const tokenMap = useMemo(
    () =>
      tokenList.reduce<Record<string, SdkToken>>((memo, token) => {
        memo[token.address] = token
        return memo
      }, {}),
    [tokenList],
  )

  const tokensQueryState = useGraphqlResult<TopTokenResponse>(
    GraphqlClientType.Fetch,
    `${DOLOMITE_API_SERVER_URL}/tokens/${chainId}/stats/top-tokens`,
    NO_VARIABLES,
    RefreshFrequency.Slow,
  )
  const hoursQueryState = useGraphqlResult<TokenHourDataResponse>(
    GraphqlClientType.Fetch,
    `${DOLOMITE_API_SERVER_URL}/tokens/${chainId}/stats/hourly-data`,
    NO_VARIABLES,
    RefreshFrequency.Slow,
  )
  const [fiatData] = useDefaultFiatValuesWithLoadingIndicator(tokenList)

  const tokenHourDataMap = useMemo(() => {
    return (hoursQueryState.result?.tokenHourDatas ?? []).reduce<Record<string, DisplayData | undefined>>(
      (memo, tokenHourData) => {
        const address = tokenHourData.id.split('-')[0]
        let memoData: DisplayData
        if (!memo[address]) {
          memoData = {
            open: tokenHourData.openPriceUSD,
            close: '0',
            volume: createFractionUSD(tokenHourData.hourlyTradeVolumeUSD),
          }
        } else {
          memoData = {
            open: memo[address]?.open,
            close: tokenHourData.closePriceUSD,
            volume: memo[address]?.volume.add(createFractionUSD(tokenHourData.hourlyTradeVolumeUSD)) ?? ZERO_FRACTION,
          }
        }
        memo[address] = memoData
        return memo
      },
      {},
    )
  }, [hoursQueryState])

  const [showInactiveTokens] = useShowInactiveTokens()

  return useMemo(() => {
    const { loading, error, result } = tokensQueryState
    const anyLoading = Boolean(loading)
    const anyError = Boolean(error)

    const tokens = (result?.tokens ?? [])
      .map<TopTokenData | undefined>(value => {
        const token = tokenMap[toChecksumAddress(value.id)]
        const fiatValue = fiatData[token?.address ?? '']
        if (!fiatValue || isTokenIgnored(token.chainId, token.address, token.symbol, showInactiveTokens)) {
          return undefined
        }

        const dayData = tokenHourDataMap[value.id]
        const open = createFractionUSD(dayData?.open ?? '0')
        const close = createFractionUSD(dayData?.close ?? '0')
        const percentageChange =
          open.greaterThan('0') && close.greaterThan('0') ? calculatePercentageChange(open, close) : ZERO_PERCENT
        const swapLiq = createFraction(value.ammTradeLiquidity)
        const borrowLiquidity = createCurrencyAmount(token, value.borrowLiquidity)
        const supplyLiquidity = createCurrencyAmount(token, value.supplyLiquidity)
        const borrowLiquidityUSD = borrowLiquidity.asFraction.multiply(fiatValue)
        const supplyLiquidityUSD = supplyLiquidity.asFraction.multiply(fiatValue)
        const poolTvl = swapLiq.multiply(fiatValue)

        return {
          token,
          id: value.id,
          symbol: value.symbol,
          name: cleanCurrencyName(token) ?? '',
          volume: dayData?.volume ?? ZERO_FRACTION,
          priceUSD: fiatValue,
          borrowLiquidityUSD,
          borrowLiquidity,
          supplyLiquidityUSD,
          supplyLiquidity,
          poolTvl,
          change: percentageChange,
        }
      })
      .filter(value => !!value)
      .map<TopTokenData>(value => value!)

    return {
      loading: anyLoading,
      error: anyError,
      data: tokens,
    }
  }, [tokensQueryState, tokenHourDataMap, tokenMap, fiatData, showInactiveTokens])
}
