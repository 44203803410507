import { SpecialAsset, SpecialAssetSymbol } from '../special-assets'
import { ChainId, PENDLE_PT_GLP_SEP_2024_ADDRESSES, PENDLE_PT_GLP_SEP_2024_ISOLATION_MODE_ADDRESSES } from '../../index'
import { Token } from '@dolomite-exchange/v2-sdk'
import { calculatePendlePtGLPSep2024ProxyVaultAddress } from '../../../utils/isolation/calculateProxyVaultAddress'
import { getOptionally } from '../special-assets-util-functions'
import { PENDLE_PT_DOCS_LINK } from './constants'

export const PT_GLP_SEP_2024_SPECIAL_ASSET: () => SpecialAsset = () => ({
  symbol: SpecialAssetSymbol.dPtGLPSeptember2024,
  safeSymbol: SpecialAssetSymbol.dPtGLPSeptember2024.replace('-', '_'),
  cleanSymbol: 'PT-GLP',
  cleanName: 'PT GLP 2024-09-26',
  hasExternalRewards: false,
  documentationUrl: PENDLE_PT_DOCS_LINK,
  isIsolationMode: true,
  isBorrowingEnabled: false,
  chainIdToAddressMap: PENDLE_PT_GLP_SEP_2024_ISOLATION_MODE_ADDRESSES,
  isolationModeInfo: {
    getWrappedToken: (chainId: ChainId) =>
      getOptionally(
        PENDLE_PT_GLP_SEP_2024_ISOLATION_MODE_ADDRESSES[chainId],
        address =>
          new Token(
            chainId,
            address,
            18,
            SpecialAssetSymbol.dPtGLPSeptember2024,
            'Dolomite Isolation: PT GLP 26SEP2024',
          ),
      ),
    getUnderlyingToken: getPendlePtGLPUnderlyingToken,
    getUnderlyingSpenderToken: getPendlePtGLPUnderlyingToken,
    allowableDebtTokens: () => [],
    allowableCollateralTokens: () => [],
    remapAccountAddress: calculatePendlePtGLPSep2024ProxyVaultAddress,
    isAsync: false,
  },
})

function getPendlePtGLPUnderlyingToken(chainId: ChainId): Token | undefined {
  return getOptionally(
    PENDLE_PT_GLP_SEP_2024_ADDRESSES[chainId],
    address => new Token(chainId, address, 18, 'PT-GLP', 'PT GLP 26SEP2024'),
  )
}
