import { useMemo } from 'react'
import { NO_VARIABLES, useGraphqlResult } from '../state/graphql/hooks'
import { GraphqlClientType } from '../state/graphql/actions'
import { CurrencyAmount, Token } from '@dolomite-exchange/v2-sdk'
import { RefreshFrequency } from '../state/chain/hooks'
import { toChecksumAddress } from '../utils/toChecksumAddress'
import { reqParseAmount } from '../state/trade/hooks'
import { Fraction } from '@dolomite-exchange/sdk-core'
import useENSNameList from '../hooks/useENSNameList'
import useENSAvatarList from '../hooks/useENSAvatarList'
import { DOLOMITE_API_SERVER_URL } from '@dolomite-exchange/zap-sdk'

interface LeaderboardResponse {
  leaderboard: [string, string][]
}

interface LeaderboardRankResponse {
  rank: number | null
}

export interface LeaderboardItem {
  userAddress: string
  userName: string | undefined
  profilePhoto: string | undefined
  totalClaimAmount: CurrencyAmount<Token>
  rank: number
}

function getEffectiveSymbol(symbol: string | undefined) {
  return symbol?.toLowerCase() === 'min' ? 'mineral' : symbol?.toLowerCase()
}

function useLeaderboardUrl(claimToken: Token | undefined): string | undefined {
  const symbol = getEffectiveSymbol(claimToken?.symbol)
  if (!symbol) {
    return undefined
  }

  return `${DOLOMITE_API_SERVER_URL}/liquidity-mining/leaderboard/${symbol}`
}

function useLeaderboardRankByAccountUrl(
  claimToken: Token | undefined,
  account: string | undefined,
): string | undefined {
  const symbol = getEffectiveSymbol(claimToken?.symbol)
  if (!symbol || !account) {
    return undefined
  }

  return `${DOLOMITE_API_SERVER_URL}/liquidity-mining/leaderboard/${symbol}/rank/${account}`
}

/**
 *
 * @param claimToken The claim token whose leaderboard will be retrieved
 */
export function useLiquidityMiningLeaderboard(
  claimToken: Token | undefined,
): {
  loading: boolean
  error: boolean
  data: LeaderboardItem[] | undefined
} {
  const leaderboardQueryState = useGraphqlResult<LeaderboardResponse>(
    GraphqlClientType.Fetch,
    useLeaderboardUrl(claimToken),
    NO_VARIABLES,
    RefreshFrequency.Slowest,
  )
  const usersSerialized = JSON.stringify(leaderboardQueryState.result?.leaderboard ?? null)
  const users = useMemo(() => {
    const leaderboard = JSON.parse(usersSerialized) as [string, string][] | null
    if (!leaderboard) {
      return []
    }
    return leaderboard.map(l => l[0])
  }, [usersSerialized])

  const { ensNames } = useENSNameList(users)
  const ensNamesSerialized = JSON.stringify(ensNames ?? null)
  const usersWithEnsNames = useMemo(() => {
    const ensNames = JSON.parse(ensNamesSerialized) as (string | null)[] | null
    if (!ensNames) {
      return undefined
    }
    return ensNames.map((ensNameOrNull, i) => (ensNameOrNull ? users[i] : null))
  }, [ensNamesSerialized, users])
  const { avatars } = useENSAvatarList(usersWithEnsNames)

  return useMemo(() => {
    if (!claimToken) {
      return {
        data: undefined,
        error: true,
        loading: false,
      }
    }

    const anyLoading = Boolean(leaderboardQueryState.loading)
    const anyError = Boolean(leaderboardQueryState.error)

    const leaderboardItems = (leaderboardQueryState.result?.leaderboard ?? []).map<LeaderboardItem>(
      ([user, totalClaimAmount], i) => {
        return {
          userAddress: toChecksumAddress(user),
          userName: ensNames?.[i] ?? undefined,
          profilePhoto: avatars[i] ?? undefined,
          totalClaimAmount: reqParseAmount(totalClaimAmount, claimToken),
          rank: i + 1,
        }
      },
    )
    return {
      loading: anyLoading,
      error: anyError,
      data: leaderboardItems,
    }
  }, [
    claimToken,
    ensNames,
    avatars,
    leaderboardQueryState.error,
    leaderboardQueryState.loading,
    leaderboardQueryState.result?.leaderboard,
  ])
}

export function useLiquidityMiningLeaderboardRankByAccount(
  claimToken: Token | undefined,
  account: string | undefined,
): {
  loading: boolean
  error: boolean
  data: Fraction | undefined
} {
  const leaderboardQueryState = useGraphqlResult<LeaderboardRankResponse>(
    GraphqlClientType.Fetch,
    useLeaderboardRankByAccountUrl(claimToken, account),
    NO_VARIABLES,
    RefreshFrequency.Slowest,
  )
  return useMemo(() => {
    if (!claimToken || !account) {
      return {
        data: undefined,
        error: true,
        loading: false,
      }
    }

    const anyLoading = Boolean(leaderboardQueryState.loading)
    const anyError = Boolean(leaderboardQueryState.error)
    const rank = leaderboardQueryState.result?.rank ?? undefined

    return {
      loading: anyLoading,
      error: anyError,
      data: typeof rank === 'number' ? new Fraction(rank) : undefined,
    }
  }, [
    account,
    claimToken,
    leaderboardQueryState.error,
    leaderboardQueryState.loading,
    leaderboardQueryState.result?.rank,
  ])
}
