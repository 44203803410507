import { useMemo } from 'react'
import { useGraphqlResult } from '../state/graphql/hooks'

import { RefreshFrequency } from '../state/chain/hooks'
import { GraphqlClientType, Web3CallType } from '../state/graphql/actions'

/**
 * Does a lookup for an ENS name to find its content hash.
 */
export default function useENSNameList(
  addresses: string[] | undefined,
): {
  loading: boolean
  ensNames: (string | null)[] | undefined
} {
  const variables = useMemo(() => ({ data: addresses ?? [] }), [addresses])
  const ensNamesState = useGraphqlResult<(string | null)[]>(
    GraphqlClientType.Web3,
    Web3CallType.GetEnsNames,
    variables,
    RefreshFrequency.Never,
  )
  return useMemo(() => {
    return {
      loading: ensNamesState.loading,
      ensNames: ensNamesState.result === undefined ? undefined : ensNamesState.result ?? [],
    }
  }, [ensNamesState.loading, ensNamesState.result])
}
