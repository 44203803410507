import { useMemo } from 'react'
import useDebounce from './useDebounce'
import useENSAddressList from './useENSAddressList'

/**
 * Does a lookup for an ENS name to find its address.
 */
export default function useENSAddress(
  ensName?: string | null,
): {
  loading: boolean
  ensAddress: string | null | undefined
} {
  const debouncedName = useDebounce(ensName ?? undefined, 200)
  const variables = useMemo(() => (debouncedName ? [debouncedName] : undefined), [debouncedName])
  const { loading, ensAddresses } = useENSAddressList(variables)
  const ensAddress = ensAddresses ? ensAddresses[0] : undefined
  return useMemo(
    () => ({
      loading,
      ensAddress,
    }),
    [loading, ensAddress],
  )
}
