import { SpecialAsset, SpecialAssetSymbol } from '../special-assets'
import { WBTC } from '../../tokens/WBTC'
import mapChainIdMapValues from '../../../utils/mapChainIdMapValues'

export const WBTC_SPECIAL_ASSET: () => SpecialAsset = () => ({
  symbol: SpecialAssetSymbol.WBTC,
  safeSymbol: SpecialAssetSymbol.WBTC,
  cleanSymbol: 'WBTC',
  cleanName: 'Wrapped BTC',
  hasExternalRewards: false,
  documentationUrl: undefined,
  isIsolationMode: false,
  isBorrowingEnabled: true,
  chainIdToAddressMap: mapChainIdMapValues(WBTC, t => t?.address),
  isolationModeInfo: undefined,
})
