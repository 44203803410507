import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import PageTitle from '../../components/common/PageTitle'
import Trades from '../../components/History/Trades'
import Transfers from '../../components/History/Transfers'
import Pooling from '../../components/History/Pooling'
import Borrow from '../../components/History/Borrow'
import Strategies from '../../components/History/Strategies'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import TOSPopover from '../../components/TOSPopover'
import useApprovedToS from '../../hooks/useApprovedToS'
import { useHistory, useParams } from 'react-router-dom'
import { ChainId } from '../../constants'
import { useActiveWeb3React } from '../../hooks'

const BORROW = 0
const STRATEGIES = 1
const TRADING = 2
const TRANSFERS = 3
const POOLING = 4

const Transactions = styled.div`
  margin: 25px auto 0;
  width: 70vw;
  height: 70vh;
  max-width: 1000px;

  @media screen and (max-width: 1219px) {
    width: 80vw;
  }

  @media screen and (max-width: 919px) {
    margin-top: 15px;
  }

  @media screen and (max-width: 730px) {
    width: 90vw;
  }

  @media screen and (max-width: 580px) {
    margin: 0 auto 15px;
    width: calc(100vw - 32px);
    height: calc(100vh - 215px);
  }
`

const HistoryWrapper = styled.div`
  background-color: #292938;
  border-radius: 8px;
  display: inline-block;
  vertical-align: top;
  width: 100%;
  height: 100%;
  margin-top: 20px;
  font-family: 'Open Sans', sans-serif;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
  text-align: left;
  padding: 25px 35px 0;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 5px 0 0;
  `};
`

const StyledTabs = styled(({ ...rest }) => <Tabs classes={{ indicator: 'indicator' }} {...rest} />)`
  font-family: 'Open Sans', sans-serif !important;
  justify-content: normal !important;
  min-height: 0 !important;

  .indicator {
    background-color: #f9f9f9 !important;
    bottom: 0 !important;
    display: block !important;
    height: 1.4px !important;
    transform: scale(0.5, 1) !important;
  }
`

const StyledTab = styled(({ ...rest }) => <Tab classes={{ root: 'root', selected: 'selected' }} {...rest} />)`
  font-family: 'Open Sans', sans-serif !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  margin-left: 0 !important;
  margin-right: 13px !important;
  text-transform: capitalize !important;
  padding: 0 !important;
  padding-bottom: 3.5px !important;
  padding-left: 4px !important;
  padding-right: 4px !important;
  max-width: 264px;
  min-width: 0 !important;
  color: #606375 !important;
  min-height: 0 !important;

  ${({ selected }) =>
    selected &&
    `
    color: #f9f9f9 !important;
  `}
  .selected {
    color: #f9f9f9 !important;
  }

  .root span {
    font-size: 18px !important;
  }
`

export default function History() {
  const { t } = useTranslation()
  const { chainId } = useActiveWeb3React()
  const [selectedTab, setSelectedTab] = useState<number>(BORROW)
  const options = [t('borrows'), t('strategies'), t('trades'), t('transfers'), t('pooling')]
  const { page } = useParams<{ page: string }>()
  const history = useHistory()

  useEffect(() => {
    const pages = ['borrows', 'strategies', 'trades', 'transfers', 'pooling']
    pages.map((option, index) => {
      if (option === page) setSelectedTab(index)
    })
  }, [page])

  const [hasApprovedToS, setHasApprovedToS] = useApprovedToS()

  return (
    <div>
      <PageTitle title={chainId === ChainId.BERACHAIN ? 'Bolomite | History' : 'Dolomite | History'} />
      {!hasApprovedToS && <TOSPopover setApprovedToS={setHasApprovedToS} />}
      <Transactions>
        <StyledTabs
          value={selectedTab}
          onChange={(_: any, index: number) => setSelectedTab(index)}
          indicatorColor={'primary'}
          textColor={'primary'}
        >
          {(options ?? []).map((option: string, index: number) => (
            <StyledTab
              key={`historyHeader-${index}`}
              disableRipple
              label={option}
              onClick={() => history.push(`/history/${option.toLowerCase()}`)}
            />
          ))}
        </StyledTabs>
        <HistoryWrapper>
          {selectedTab === TRADING ? (
            <Trades />
          ) : selectedTab === BORROW ? (
            <Borrow />
          ) : selectedTab === TRANSFERS ? (
            <Transfers />
          ) : selectedTab === STRATEGIES ? (
            <Strategies />
          ) : (
            <Pooling />
          )}
        </HistoryWrapper>
      </Transactions>
    </div>
  )
}
