import { CollateralActionType, LoanActionType, ManageType } from '../types'
import styled from 'styled-components/macro'
import React, { useMemo } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { StyledTooltip } from '../../../components/common/StyledTooltip'
import Button from '@material-ui/core/Button'
import { useTranslation } from 'react-i18next'
import { address } from '@dolomite-exchange/dolomite-margin/dist/src/types'
import { CurrencyAmount, Token } from '@dolomite-exchange/v2-sdk'
import BoltIcon from '@mui/icons-material/Bolt'
import { useActiveWeb3React } from '../../../hooks'

const BorrowSubmitButtonWrapper = styled.div`
  display: inline-block;
  vertical-align: top;

  @media screen and (max-width: 550px) {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
  }
`

const SubmitWrapper = styled.div<{ isZapActivated: boolean }>`
  margin-left: 10px;
  /*pointer-events: none;*/
  width: 90px;
  vertical-align: top;
  display: inline-block;
  transform: translateY(${({ isZapActivated }) => (isZapActivated ? '98px' : '0')});
  transition: transform 0.3s ease-in-out 0.2s;

  button {
    height: 33px;
  }

  @media screen and (max-width: 550px) {
    width: 100%;
    margin-top: 5px;
    margin-left: 0;
    transform: none;

    > button {
      width: 100% !important;
    }
  }
`

// TODO - decide if it makes more sense to leave pointer events none when disabled because of odd issue where the tooltip doesn't disappear when mouse leaves the button
const SubmitButton = styled(Button)<{ wide?: number; disabled?: boolean; unlocked?: boolean; small?: boolean }>`
  background-color: ${({ theme, unlocked }) => (unlocked ? theme.blue1 : theme.green2)} !important;
  display: inline-block;
  margin: 0 !important;
  vertical-align: bottom;
  width: 89px;
  height: 36px;
  color: ${({ theme }) => theme.text1} !important; //#d5d6e1 !important;
  font-family: 'Open Sans', sans-serif !important;
  text-transform: capitalize !important;
  line-height: 12px !important;

  ${({ small }) => small && 'font-size: 12px !important;'}
  ${({ disabled }) =>
    disabled
      ? `pointer-events: auto; opacity: 0.6;`
      : `pointer-events: auto !important; cursor: pointer !important;`}
  &:hover {
    ${({ theme, disabled, unlocked }) =>
      !disabled && `background-color: ${unlocked ? theme.blue2 : theme.green1} !important;`}
  }

  > span > div {
    height: 20px !important;
    width: 20px !important;
  }

  svg {
    color: white !important;
  }

  ${({ wide }) =>
    wide &&
    `
    width: 108px;
  `}
`

const ZapWrapper = styled.span`
  display: contents;
  vertical-align: top;
  font-weight: 700;

  svg {
    height: 20px;
    width: 16px;
    margin-right: -1px;
    margin-bottom: -1px;
    margin-left: -5px;
  }
`

function BorrowSubmitButtonComparator(prevProps: BorrowSubmitButtonProps, nextProps: BorrowSubmitButtonProps) {
  return (
    prevProps.manageType === nextProps.manageType &&
    prevProps.selectedTab === nextProps.selectedTab &&
    prevProps.isClosingTransaction === nextProps.isClosingTransaction &&
    prevProps.dolomiteBalanceData === nextProps.dolomiteBalanceData &&
    prevProps.isIsolationModeCompatibilityValid === nextProps.isIsolationModeCompatibilityValid &&
    prevProps.inputValue === nextProps.inputValue &&
    prevProps.isInputValueValid === nextProps.isInputValueValid &&
    prevProps.isBorrowStateValid === nextProps.isBorrowStateValid &&
    prevProps.inputError === nextProps.inputError &&
    prevProps.isAttemptingTx === nextProps.isAttemptingTx &&
    prevProps.isTxPending === nextProps.isTxPending &&
    prevProps.isZapActivated === nextProps.isZapActivated &&
    prevProps.handleSubmit === nextProps.handleSubmit
  )
}

interface BorrowSubmitButtonProps {
  manageType: ManageType
  selectedTab: CollateralActionType | LoanActionType
  isClosingTransaction: boolean
  dolomiteBalanceData?: Record<address, CurrencyAmount<Token> | undefined>
  isIsolationModeCompatibilityValid: boolean
  isGmCompatibilityValid: boolean
  inputValue: string
  isInputValueValid: boolean
  isBorrowStateValid: boolean
  inputError?: string
  isAttemptingTx: boolean
  isTxPending: boolean
  isZapActivated: boolean
  handleSubmit: () => void
  tooltipText: string
}

function BorrowSubmitButton({
  manageType,
  selectedTab,
  isClosingTransaction,
  dolomiteBalanceData,
  isIsolationModeCompatibilityValid,
  isGmCompatibilityValid,
  inputValue,
  isInputValueValid,
  isBorrowStateValid,
  isAttemptingTx,
  isTxPending,
  isZapActivated,
  handleSubmit,
  tooltipText,
}: BorrowSubmitButtonProps) {
  const { t } = useTranslation()
  const { account } = useActiveWeb3React()

  const submitButtonText = useMemo(() => {
    return isZapActivated ? (
      <ZapWrapper>
        <BoltIcon /> ZAP
      </ZapWrapper>
    ) : manageType === ManageType.COLLATERAL ? (
      selectedTab === CollateralActionType.DEPOSIT ? (
        t('add')
      ) : isClosingTransaction ? (
        t('closeBorrow')
      ) : (
        t('remove')
      )
    ) : selectedTab === LoanActionType.BORROW ? (
      t('borrow')
    ) : (
      t('repay')
    )
  }, [isZapActivated, isClosingTransaction, manageType, selectedTab, t])

  const isSubmitDisabled =
    !dolomiteBalanceData ||
    !account ||
    !isIsolationModeCompatibilityValid ||
    !isGmCompatibilityValid ||
    !inputValue ||
    !isInputValueValid ||
    !isBorrowStateValid

  const hideTooltip = useMemo(() => {
    return (
      !(
        !isBorrowStateValid ||
        !isIsolationModeCompatibilityValid ||
        !isGmCompatibilityValid ||
        !inputValue ||
        !isInputValueValid
      ) && !isClosingTransaction
    )
  }, [
    inputValue,
    isBorrowStateValid,
    isClosingTransaction,
    isGmCompatibilityValid,
    isInputValueValid,
    isIsolationModeCompatibilityValid,
  ])

  return (
    <BorrowSubmitButtonWrapper>
      <StyledTooltip title={tooltipText} placement={'top'} hideTooltip={hideTooltip}>
        <SubmitWrapper isZapActivated={isZapActivated}>
          {isAttemptingTx || isTxPending ? (
            <SubmitButton disabled={true}>
              <CircularProgress />
            </SubmitButton>
          ) : (
            <SubmitButton
              disabled={isSubmitDisabled}
              onClick={handleSubmit}
              small={isClosingTransaction ? isClosingTransaction : undefined}
            >
              {submitButtonText}
            </SubmitButton>
          )}
        </SubmitWrapper>
      </StyledTooltip>
    </BorrowSubmitButtonWrapper>
  )
}

export default React.memo(BorrowSubmitButton, BorrowSubmitButtonComparator)
