import { Currency } from '@dolomite-exchange/v2-sdk'
import React, { useMemo } from 'react'
import styled from 'styled-components/macro'

import EthereumLogo from '../../assets/images/ethereum-logo.png'
import Logo from '../Logo'
import getLogoOrDefault from '../common/TokenLogos'
import cleanCurrencySymbol from '../../utils/cleanCurrencySymbol'

const StyledEthereumLogo = styled.img<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.075);
  border-radius: 24px;
`

const StyledLogo = styled(Logo)<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: ${({ size }) => size};
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.075);
  background-color: transparent;
`

export default function CurrencyLogo({
  currency,
  size = '24px',
  style,
}: {
  currency?: Currency
  size?: string
  style?: React.CSSProperties
}) {
  const srcs = useMemo(() => {
    if (!currency) {
      return []
    }

    return [getLogoOrDefault(cleanCurrencySymbol(currency) ?? '')]
  }, [currency])

  if (currency?.isNative) {
    return <StyledEthereumLogo src={EthereumLogo} size={size} style={style} />
  }

  return <StyledLogo size={size} srcs={srcs} alt={`${currency?.symbol ?? 'token'} logo`} style={style} />
}
