import React from 'react'
import styled from 'styled-components/macro'
import Column from '../Orders/Column'
import { styled as muiStyled } from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ArrowBackwardIcon from '@mui/icons-material/ArrowBack'

export const StatTable = styled.div`
  background-color: ${({ theme }) => theme.bg1};
  border-radius: 8px;
  display: inline-block;
  vertical-align: top;
  width: 100%;
  font-family: 'Open Sans', sans-serif;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
  text-align: left;
  padding: 25px 35px 10px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 20px 20px 10px;
  `};
`

export const StatRows = styled.div`
  font-size: 17px;
  margin-bottom: 5px;

  @media (max-width: 960px) {
    font-size: 15px;
  }

  @media (max-width: 660px) {
    font-size: 13px;
  }
`

export const ColumnTitles = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.text3};
`

export const ColumnWrapper = styled(Column)`
  cursor: pointer;
`

export const TokenLogo = styled.div`
  display: inline-block;
  margin-right: 2px;
  margin-top: 0;
  vertical-align: top;
  width: 19px;

  @media (max-width: 960px) {
    width: 17px;
  }

  @media (max-width: 660px) {
    width: 15px;
  }

  img {
    width: 100%;
  }
`

export const LogoHelper = styled.span`
  display: inline-block;
  height: 100%;
  vertical-align: middle;
`

export const Ticker = styled.span<{ secondary?: boolean | null }>`
  color: ${({ theme, secondary }) => (secondary ? theme.text3 : theme.text1)};
  font-weight: 400;
  margin-top: 0;
  margin-left: 2px;
`

export const PoolRowWrapper = styled.div`
  height: 40px;
  cursor: pointer;
  border-radius: 5px;
  padding: 10px 20px;
  width: calc(100% + 40px);
  margin-left: -20px;
  font-size: 15px;

  :hover {
    background-color: ${({ theme }) => theme.bg2};
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 14px;
    padding: 8px 20px;
  `};

  @media screen and (max-width: 420px) {
    font-size: 12px;
  }
`

export const Index = styled.div`
  width: 5%;
  display: inline-block;
`

export const PoolName = styled.div`
  width: 50%;
  display: inline-block;

  @media (max-width: 960px) {
    width: 41%;
  }

  @media (max-width: 660px) {
    width: 35%;
  }
`

export const PoolMetric = styled.div<{ hideOnMobile?: boolean | null }>`
  width: 15%;
  text-align: right;
  display: inline-block;

  @media (max-width: 960px) {
    width: 18%;
  }

  @media (max-width: 660px) {
    width: 30%;
    display: ${({ hideOnMobile }) => (hideOnMobile ? 'none' : 'inline-block')};
  }
`

export const TokenRowWrapper = styled.div`
  width: 100%;
  height: 40px;
  cursor: pointer;
  padding: 10px 20px;
  width: calc(100% + 40px);
  margin-left: -20px;
  border-radius: 5px;
  font-size: 15px;

  :hover {
    background-color: ${({ theme }) => theme.bg2};
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 14px;
    padding: 8px 20px;
  `};

  @media screen and (max-width: 420px) {
    font-size: 12px;
  }
`

export const TokenName = styled.div`
  width: 35%;
  display: inline-block;

  > ${Ticker}:nth-of-type(2) {
    margin-left: 5px;
  }

  @media (max-width: 960px) {
    width: 38%;
  }

  @media (max-width: 660px) {
    width: 45%;
  }
`

export const TokenMetric = styled.div<{ hideOnMobile?: boolean | null; hideOnTablet?: boolean | null }>`
  width: 15%;
  text-align: right;
  display: inline-block;

  @media (max-width: 960px) {
    width: 19%;
    display: ${({ hideOnTablet }) => (hideOnTablet ? 'none' : 'inline-block')};
  }

  @media (max-width: 660px) {
    width: 25%;
    display: ${({ hideOnMobile }) => (hideOnMobile ? 'none' : 'inline-block')};
  }
`

export const Percentage = styled.span<{ color: string }>`
  color: ${({ theme, color }) => (color === 'green' ? theme.green1 : theme.red1)};
`

export const MoverWrapper = styled.div<{ width: number }>`
  height: 60px;
  min-width: ${({ width }) => width}px !important;
  margin: 5px;
  cursor: pointer;
  padding: 15px 10px 10px;
  border-radius: 8px;
  font-size: 15px;
  display: inline-block;
  background-color: ${({ theme }) => theme.bg3};
  overflow: hidden;

  :hover {
    background-color: ${({ theme }) => theme.bg2};
  }
`

export const MoverLogo = styled.div`
  width: 30px;
  display: inline-block;
  margin-right: 5px;
  vertical-align: top;

  img {
    width: 100%;
  }
`

export const SecondLogo = styled.div`
  margin-left: -5px;
  display: inline-block;
  vertical-align: top;
`

export const MoverData = styled.div`
  display: inline-block;
`

export const MoverPrice = styled.div`
  > span:nth-child(2) {
    margin-left: 5px;
  }
`

export const MoverTicker = styled.div`
  margin-top: -5px;
`

export const MoverMetric = styled.span`
  font-size: 13px;
`

export const Loading = styled.div`
  text-align: center;
  font-size: 14px;
  color: ${({ theme }) => theme.text2};
  margin: 40px 0;
`

export const HideOnSmall = styled.span`
  @media (max-width: 500px) {
    display: none;
  }
`

export const PageSelect = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 5px;
`

export const Arrow = styled.div<{ faded: boolean }>`
  color: ${({ theme }) => theme.text1};
  opacity: ${({ faded }) => (faded ? 0.3 : 1)};
  padding: 0 20px;
  user-select: none;
  :hover {
    cursor: ${({ faded }) => (faded ? 'auto' : 'pointer')} !important;
  }
`

export const Forward = muiStyled(ArrowForwardIcon)(({ theme }) => ({
  fontSize: 12,
  color: '#f9f9f9',
}))

export const Backward = muiStyled(ArrowBackwardIcon)(({ theme }) => ({
  fontSize: 12,
  color: '#f9f9f9',
}))
