import { Fraction, Percent, Rounding } from '@dolomite-exchange/sdk-core'
import { CurrencyAmount, Price } from '@dolomite-exchange/v2-sdk'
import { ZERO_FRACTION } from '../constants'
import { BigNumberish } from 'ethers'
import JSBI from 'jsbi'

function numberWithCommas(x: BigNumberish) {
  if (!x) {
    return '...'
  }

  const parts = x.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return parts.join('.')
}

const limitDecimals = (amount: BigNumberish, maxDecimals?: number) => {
  let amountStr = amount.toString()
  if (maxDecimals === undefined) {
    return amountStr
  }
  if (maxDecimals === 0) {
    return amountStr.split('.')[0]
  }
  const dotIndex = amountStr.indexOf('.')
  if (dotIndex !== -1) {
    const decimals = amountStr.length - dotIndex - 1
    if (decimals > maxDecimals) {
      amountStr = amountStr.substr(0, amountStr.length - (decimals - maxDecimals))
    }
  }
  return amountStr
}

const padDecimals = (amount: BigNumberish, minDecimals: number) => {
  let amountStr = amount.toString()
  const dotIndex = amountStr.indexOf('.')
  if (dotIndex !== -1) {
    const decimals = amountStr.length - dotIndex - 1
    if (decimals < minDecimals) {
      amountStr = amountStr.padEnd(amountStr.length + (minDecimals - decimals), '0')
    }
  } else {
    amountStr = amountStr + '.0000'
  }
  return amountStr
}

const ONE_HUNDRED_BI = JSBI.BigInt(100)
const TEN_BI = JSBI.BigInt(10)
const ONE_BI = JSBI.BigInt(1)

export const formatAmount = (
  amount: Fraction | undefined,
  displayDecimals?: number,
  useCommas: boolean = true,
  defaultValue?: string,
  useDollarSign?: boolean,
  rounding?: Rounding,
) => {
  if (!defaultValue) {
    defaultValue = '-'
  }
  if (typeof amount === 'undefined') {
    return defaultValue
  }
  if (amount instanceof Price) {
    amount = amount.asFraction.multiply(amount.scalar)
  }

  if (displayDecimals === undefined) {
    if (amount instanceof Percent) {
      displayDecimals = 2
    } else if (amount.asFraction.abs.greaterThanOrEqual(ONE_HUNDRED_BI)) {
      displayDecimals = 2
    } else if (amount.asFraction.abs.greaterThanOrEqual(TEN_BI)) {
      displayDecimals = 3
    } else if (amount.asFraction.abs.greaterThanOrEqual(ONE_BI)) {
      displayDecimals = 4
    } else {
      displayDecimals = 6
    }
  }
  const displayDecimalsForLessThan = amount instanceof Percent ? displayDecimals + 2 : displayDecimals
  const isNegative = amount.lessThan(ZERO_FRACTION)
  const isLessThanDisplayDecimals =
    displayDecimalsForLessThan !== 0 &&
    amount.abs.asFraction.lessThan(new Fraction(1, Math.pow(10, displayDecimalsForLessThan))) &&
    amount.abs.greaterThan(ZERO_FRACTION)

  const tokenDecimals = amount instanceof CurrencyAmount ? amount.currency.decimals : 18
  let amountStr = (amount.equalTo(ZERO_FRACTION) ? ZERO_FRACTION : amount).toFixed(
    tokenDecimals,
    undefined,
    rounding ? rounding : amount instanceof Percent ? Rounding.ROUND_HALF_UP : undefined,
  )
  amountStr = limitDecimals(amountStr, displayDecimals)
  if (displayDecimals !== 0) {
    amountStr = padDecimals(amountStr, displayDecimals)
  }

  const symbolToPrefix = useDollarSign ? '$' : ''
  const symbolToPostfix = amount instanceof Percent ? '%' : ''
  const finalAmountStr = useCommas ? numberWithCommas(amountStr) : amountStr
  return isLessThanDisplayDecimals
    ? `< ${isNegative ? '-' : ''}${symbolToPrefix}0.${'0'.repeat(displayDecimals - 1)}1${symbolToPostfix}`
    : `${symbolToPrefix}${finalAmountStr}${symbolToPostfix}`
}
