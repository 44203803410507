import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { darken } from 'polished'
import { ReactComponent as DropDown } from '../../assets/images/dropdown.svg'
import { Currency } from '@dolomite-exchange/v2-sdk'
import { Field } from '../../state/trade/actions'
import { useTradeActionHandlers, useTradeState } from '../../state/trade/hooks'
import { useToken, useTradingTokens } from '../../hooks/Tokens'
import LOGO_IMAGES from '../../components/common/TokenLogos'
import getLogoOrDefault from '../../components/common/TokenLogos'
import cleanCurrencySymbol from '../../utils/cleanCurrencySymbol'
import ArrowDropUp from '@material-ui/icons/ArrowDropUp'
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'
import JSBI from 'jsbi'
import { ZERO_FRACTION } from '../../constants'
import { usePair } from '../../data/Reserves'
import AutorenewIcon from '@material-ui/icons/Autorenew'
import { useActiveWeb3React } from '../../hooks'
import { deriveMarketFromTokens } from '../../utils/marketUtils'
import { maxBy, minBy } from '../../utils/numberOperations'
import { Fraction } from '@dolomite-exchange/sdk-core'
import calculatePercentageChange from '../../utils/calculatePercentageChange'
import { useCurrentDayTokenHourlyData } from '../../types/tokenHourData'
import CurrencyModal from '../../components/CurrencyModal'
import { useFiatValuesWithLoadingIndicator } from '../../hooks/useFiatValue'
import { useDolomiteBalancesWithLoadingIndicator } from '../../state/wallet/hooks'
import { StyledTooltip } from '../../components/common/StyledTooltip'
import { formatAmount } from '../../utils/formatAmount'

const TokenSelectionWrapper = styled.div`
  width: 100%;
  height: 40px;
  font-family: 'Open Sans', sans-serif;
`

const TokenSelectionInner = styled.div`
  width: 90%;
  display: flex;
  justify-content: space-around;
  margin: 0 auto;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    float: left;
    width: fit-content;
    
    > button:first-of-type {
      margin-right: 5px;
    }
  `};
`

const CurrencySelect = styled.button<{ selected: boolean }>`
  align-items: center;
  height: 2.2rem;
  font-size: 20px;
  font-weight: 500;
  background-color: ${({ selected, theme }) => (selected ? theme.bg2 : theme.primary1)};
  color: ${({ selected, theme }) => (selected ? theme.text1 : theme.white)};
  border-radius: 5px;
  box-shadow: ${({ selected }) => (selected ? 'none' : '0px 6px 10px rgba(0, 0, 0, 0.075)')};
  outline: none;
  cursor: pointer;
  user-select: none;
  border: none;
  padding: 0 0.5rem;

  :focus,
  :hover {
    background-color: ${({ selected, theme }) => (selected ? theme.bg2 : darken(0.05, theme.primary1))};
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 16px;
    height: 2rem;
  `};

  @media (max-width: 350px) {
    padding: 0 0.3rem 0 0.4rem;
  }
`

const Aligner = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    width: 20px;
  }
`

const StyledTokenName = styled.span<{ active?: boolean }>`
  ${({ active }) => (active ? '  margin: 0 0.25rem 0 0.5rem;' : '  margin: 0 0.25rem 0 0.25rem;')}
  font-size: ${({ active }) => (active ? '16px' : '14px')};

  @media (max-width: 350px) {
    ${active => (active ? '  margin: 0 0.2rem 0 0.3rem;' : '  margin: 0 0.2rem 0 0.2rem;')}
  }
`

const StyledDropDown = styled(DropDown)<{ selected: boolean }>`
  margin: 0 0.25rem 0 0.5rem;
  height: 35%;

  path {
    stroke: ${({ selected, theme }) => (selected ? theme.text1 : theme.white)};
    stroke-width: 1.5px;
  }

  @media (max-width: 350px) {
    margin: 0 0.2rem 0 0.3rem;
  }
`

const MobileTokenPrice = styled.div`
  display: none;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: block;
    float: right;
  `};
`

const TokenPrice = styled.div`
  font-size: 15px;
  font-weight: 500;
`

const Unit = styled.span`
  font-weight: 200;

  color: ${({ theme }) => theme.text2};
`

const PriceChangeWrapper = styled.div<{ isPositive: boolean }>`
  color: ${({ theme, isPositive }) => (isPositive ? theme.green1 : theme.red1)};
  font-size: 11px;
  text-align: right;

  svg {
    font-size: 18px;
  }
`

const PriceChange = styled.div`
  display: inline-block;
  vertical-align: top;
`

const SwapCurrenciesIcon = styled.div`
  display: inline-block;
  transform: rotate(45deg);
  margin: 4px -2px 0 -7px;
  cursor: pointer;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `}
  svg {
    color: ${({ theme }) => theme.text3};
    transition: color 0.1s ease-in-out;

    :hover {
      color: ${({ theme }) => theme.text2};
    }
  }
`

interface CurrencySelectorProps {
  currency: Currency | undefined
  setModalOpen: (currency: Currency | undefined) => void
  t: any
  logoImages: any
}

const currencySelectorComparator = (prevProps: CurrencySelectorProps, nextProps: CurrencySelectorProps) => {
  if (!prevProps && !nextProps) return true
  if (!!prevProps !== !!nextProps) return true
  return !!(
    prevProps.currency &&
    nextProps.currency &&
    prevProps.currency.symbol === nextProps.currency.symbol &&
    prevProps.logoImages === nextProps.logoImages
  )
}

const CurrencySelector = React.memo<CurrencySelectorProps>(({ currency, setModalOpen, t }: CurrencySelectorProps) => {
  return (
    <CurrencySelect
      selected={!!currency}
      className='open-currency-select-button'
      onClick={() => {
        setModalOpen(currency)
      }}
    >
      <Aligner>
        {currency ? (
          <img src={getLogoOrDefault(cleanCurrencySymbol(currency) ?? '')} alt={`${currency.symbol} logo`} />
        ) : null}
        <StyledTokenName className='token-symbol-container' active={Boolean(currency?.symbol)}>
          {(currency?.symbol && currency.symbol.length > 20
            ? currency.symbol.slice(0, 4) +
              '...' +
              currency.symbol.slice(currency.symbol.length - 5, currency.symbol.length)
            : cleanCurrencySymbol(currency)) ?? t('selectToken')}
        </StyledTokenName>
        <StyledDropDown selected={!!currency} />
      </Aligner>
    </CurrencySelect>
  )
}, currencySelectorComparator)
CurrencySelector.displayName = 'CurrencySelector'

const TokenSelection = React.memo(function TokenSelectionComponent() {
  const { t } = useTranslation()
  const { account } = useActiveWeb3React()
  const { onCurrencySelection, onSwitchTokens } = useTradeActionHandlers()
  const [tokenSelectOpen, setTokenSelectOpen] = useState<boolean>(false)
  const [modalOpen, setModalOpen] = useState<Currency | undefined>(undefined)
  const [swapTokens, setSwapTokens] = useState(false)
  const tokens = useTradingTokens()
  const tokenList = useMemo(() => Object.values(tokens), [tokens])
  const [dolomiteBalanceMap] = useDolomiteBalancesWithLoadingIndicator(account, tokenList)
  const [dolomiteFiatBalanceMap] = useFiatValuesWithLoadingIndicator(dolomiteBalanceMap, tokenList)

  const openModal = useCallback(
    (currency: any) => {
      setModalOpen(currency)
      setTokenSelectOpen(true)
    },
    [setModalOpen],
  )

  const handleDismissSearch = useCallback(() => {
    setModalOpen(undefined)
    setTokenSelectOpen(false)
  }, [setModalOpen])

  const handleInputSelect = useCallback(
    (inputCurrency: Currency) => {
      onCurrencySelection(Field.INPUT, inputCurrency)
      setTokenSelectOpen(false)
    },
    [onCurrencySelection],
  )

  const handleOutputSelect = useCallback(
    (outputCurrency: Currency) => {
      onCurrencySelection(Field.OUTPUT, outputCurrency.wrapped)
      setTokenSelectOpen(false)
    },
    [onCurrencySelection],
  )

  const {
    [Field.INPUT]: { currencyId: inputCurrencyId },
    [Field.OUTPUT]: { currencyId: outputCurrencyId },
  } = useTradeState()

  const inputCurrencyField = useToken(inputCurrencyId)
  const outputCurrencyField = useToken(outputCurrencyId)

  const market = useMemo(() => deriveMarketFromTokens(inputCurrencyField, outputCurrencyField), [
    inputCurrencyField,
    outputCurrencyField,
  ])

  const primaryToken = useMemo(() => {
    return swapTokens ? market?.secondaryToken : market?.primaryToken
  }, [swapTokens, market])

  const secondaryToken = useMemo(() => {
    return swapTokens ? market?.primaryToken : market?.secondaryToken
  }, [swapTokens, market])

  const [, pair] = usePair(primaryToken ?? undefined, secondaryToken ?? undefined)

  const primaryLiquidity = useMemo(() => {
    return cleanCurrencySymbol(primaryToken) === cleanCurrencySymbol(pair?.reserve0.currency)
      ? pair?.reserve0.asFraction
      : pair?.reserve1.asFraction
  }, [pair, primaryToken])

  const secondaryLiquidity = useMemo(() => {
    return cleanCurrencySymbol(secondaryToken) === cleanCurrencySymbol(pair?.reserve1.currency)
      ? pair?.reserve1.asFraction
      : pair?.reserve0.asFraction
  }, [pair, secondaryToken])

  const marketPrice = useMemo(() => {
    if (!secondaryLiquidity || !primaryLiquidity) {
      return undefined
    }
    return primaryLiquidity.equalTo('0') ? ZERO_FRACTION : secondaryLiquidity.divide(primaryLiquidity)
  }, [primaryLiquidity, secondaryLiquidity])

  const { data: inputTokenData } = useCurrentDayTokenHourlyData(inputCurrencyField)
  const { data: outputTokenData } = useCurrentDayTokenHourlyData(outputCurrencyField)

  const openPriceUSD = useMemo(() => {
    const data = market?.primaryToken.address === inputCurrencyField?.address ? inputTokenData : outputTokenData
    return minBy(data ?? [], data => new Fraction(data.hourStartUnix.getTime()))?.openPriceUSD
  }, [market?.primaryToken.address, inputCurrencyField?.address, inputTokenData, outputTokenData])

  const closePriceUSD = useMemo(() => {
    const data = market?.primaryToken.address === inputCurrencyField?.address ? inputTokenData : outputTokenData
    return maxBy(data ?? [], data => new Fraction(data.hourStartUnix.getTime()))?.closePriceUSD
  }, [market?.primaryToken.address, inputCurrencyField?.address, inputTokenData, outputTokenData])

  const priceChange = useMemo(() => {
    return openPriceUSD && closePriceUSD ? calculatePercentageChange(openPriceUSD, closePriceUSD) : ZERO_FRACTION
  }, [openPriceUSD, closePriceUSD])

  return (
    <TokenSelectionWrapper>
      {tokenSelectOpen ? (
        <CurrencyModal
          tokens={tokenList}
          balances={dolomiteBalanceMap}
          fiatBalances={dolomiteFiatBalanceMap}
          isOpen={tokenSelectOpen}
          onDismiss={handleDismissSearch}
          currencySelect={(currency: Currency) =>
            inputCurrencyField?.symbol === modalOpen?.symbol
              ? handleInputSelect(currency)
              : handleOutputSelect(currency)
          }
          balanceTitle={t('dolomiteBalance')}
        />
      ) : null}
      <TokenSelectionInner>
        {/* TODO - change this to be a component so it can handle its own modal and spare me making an extra state variable. Plus can prevent some rerendering*/}
        {/* TODO - look at screenshot from new loopring redesign, you could stack the logos together in the same way and make the text smaller in the same way*/}
        {/* TODO - edit the popup that comes up when you click one of these. We have far fewer tokens and no need to search for a token contract address. Can instead maybe show a little more about each token, almost like the token explorer from Dolomite? Could also have a button to Trade the currently selected tokens. Or make it so when you click (for example) ETH as the output currency when its currently the input currency, it just swaps the input and output */}
        <CurrencySelector currency={inputCurrencyField} setModalOpen={openModal} t={t} logoImages={LOGO_IMAGES} />
        <SwapCurrenciesIcon onClick={() => onSwitchTokens()}>
          <StyledTooltip title={'Trade input/output tokens'} placement={'top'}>
            <AutorenewIcon />
          </StyledTooltip>
        </SwapCurrenciesIcon>
        <CurrencySelector currency={outputCurrencyField} setModalOpen={openModal} t={t} logoImages={LOGO_IMAGES} />
      </TokenSelectionInner>
      <MobileTokenPrice onClick={() => setSwapTokens(!swapTokens)}>
        <TokenPrice>
          {marketPrice && !marketPrice.equalTo(JSBI.BigInt(0)) ? formatAmount(marketPrice) : '-'}{' '}
          <Unit>{cleanCurrencySymbol(outputCurrencyField)}</Unit>
        </TokenPrice>
        <PriceChangeWrapper isPositive={priceChange.greaterThan(ZERO_FRACTION)}>
          {priceChange.greaterThan(ZERO_FRACTION) ? <ArrowDropUp /> : <ArrowDropDown />}{' '}
          <PriceChange>{priceChange.toFixed(2)}%</PriceChange>
        </PriceChangeWrapper>
      </MobileTokenPrice>
    </TokenSelectionWrapper>
  )
})

export default TokenSelection
