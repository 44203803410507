import { BorrowPosition as BorrowPositionData } from '../../../types/borrowPositionData'
import { Fraction, Rounding } from '@dolomite-exchange/v2-sdk'
import React from 'react'
import styled from 'styled-components/macro'
import { ZERO_FRACTION } from '../../../constants'
import AllInclusiveIcon from '@material-ui/icons/AllInclusive'
import ArrowRight from '@material-ui/icons/ArrowForward'
import CircularProgress from '@material-ui/core/CircularProgress'
import { StyledTooltip } from '../../../components/common/StyledTooltip'
import { PositionHealthTooltip } from '../BorrowPositionRow'
import { useTranslation } from 'react-i18next'

const ChangingHealthWrapper = styled.div<{ isolationModeWarning?: boolean; visible?: boolean }>`
  width: fit-content;
  text-align: center;
  position: absolute;
  right: 0;
  margin-top: ${({ isolationModeWarning }) => (isolationModeWarning ? 51 : 9)}px;
  transition: ${({ isolationModeWarning }) =>
    isolationModeWarning ? 'margin-top 0.3s ease-in-out' : 'margin-top 0.3s ease-in-out 0.2s'};
  font-size: 11px;
  z-index: 9;
  pointer-events: ${({ visible }) => (visible ? 'all' : 'none')};

  @media screen and (max-width: 615px) {
    /* width: calc(100% - 70px);
    top: 90px;
    margin-top: 0;
    left: 35px;*/
    display: inline-block;
    vertical-align: bottom;
    position: relative;
    margin: 0;
    top: ${({ isolationModeWarning }) => (isolationModeWarning ? 58 : 0)}px;
    width: 100%;
    right: auto;
    left: 0;
    text-align: left;
    transition: ${({ isolationModeWarning }) =>
      isolationModeWarning ? 'top 0.3s ease-in-out' : 'top 0.3s ease-in-out 0.2s'};
  }
`

const ChangingHealthContainer = styled.div<{ marginTop?: string; visible: boolean }>`
  display: inline-block;
  vertical-align: top;
  font-size: 11px;
  font-weight: 300;
  width: fit-content;
  opacity: ${({ visible }) => (visible ? '1' : '0')};
  transition: opacity 0.3s ease-in-out;

  @media screen and (max-width: 615px) {
    display: inline-flex;
    margin-top: 0;
    margin-bottom: 0;
    opacity: 1;
    font-weight: 600;
    div:nth-of-type(2) > div:nth-of-type(2),
    svg {
      transition: opacity 0.3s ease-in-out;
      opacity: ${({ visible }) => (visible ? '1' : '0')};
    }
  }
`

const ChangingValuesTitle = styled.div`
  width: 100%;
  text-align: center;
  color: ${({ theme }) => theme.text2};
  line-height: 12px;

  @media screen and (max-width: 615px) {
    font-size: 11px;
    font-weight: 400;
    margin-right: 7px;
    color: ${({ theme }) => theme.text1};

    ::after {
      content: ':';
    }
  }
`

const ChangingValue = styled.div<{ isNegative?: boolean }>`
  width: auto;
  display: flex;
  justify-content: center;
  font-size: 14px;
  text-align: center;
  font-weight: 600;

  svg {
    height: 20px;
  }

  > svg {
    color: ${({ theme, isNegative }) => (isNegative ? theme.red1 : theme.green1)};
  }

  @media screen and (max-width: 615px) {
    font-size: 12px;
    line-height: 12px;

    svg {
      height: 15px;
      margin-top: -2px;
    }
  }
`

const OldValue = styled.div`
  color: ${({ theme }) => theme.text1};

  @media screen and (max-width: 615px) {
    font-weight: 300;
  }
`

const HealthLoadingSpinner = styled.div`
  height: 16px;
  width: 16px;
  margin-top: 1px;

  > div {
    height: 16px !important;
    width: 16px !important;
    color: ${({ theme }) => theme.text1} !important;

    svg {
      height: 16px !important;
    }
  }

  @media screen and (max-width: 615px) {
    height: 12px;
    width: 12px;
    margin-top: 0;

    > div {
      height: 12px !important;
      width: 12px !important;
      color: ${({ theme }) => theme.text1} !important;

      svg {
        height: 12px !important;
        margin-top: 0;
      }
    }
  }
`

const NewValue = styled.div<{ isNegative?: boolean | undefined; health?: number; error?: boolean }>`
  color: ${({ theme, isNegative, error }) =>
    error ? theme.red1 : isNegative !== undefined ? (isNegative ? theme.red1 : theme.green1) : 'inherit'};
  ${({ theme, health }) =>
    health && `color: ${health === 3 ? theme.green1 : health === 2 ? theme.yellow1 : theme.red1};`};
`

interface ChangingHealthProps {
  inputValue: string
  position: BorrowPositionData
  newPositionHealth: Fraction | undefined
  newPositionHealthFactor: 3 | 2 | 1
  isLoadingZap: boolean
  isolationModeWarning?: boolean
  hasError: boolean
}

const ChangingValuesComparator = (prevProps: ChangingHealthProps, nextProps: ChangingHealthProps) => {
  if (prevProps.hasError === nextProps.hasError && prevProps.hasError) {
    return prevProps.isolationModeWarning === nextProps.isolationModeWarning
  }
  return (
    prevProps.hasError === nextProps.hasError &&
    prevProps.isolationModeWarning === nextProps.isolationModeWarning &&
    prevProps.inputValue === nextProps.inputValue &&
    (prevProps.position.positionHealth ?? ZERO_FRACTION).equalTo(nextProps.position.positionHealth ?? ZERO_FRACTION) &&
    (prevProps.newPositionHealth ?? ZERO_FRACTION).equalTo(nextProps.newPositionHealth ?? ZERO_FRACTION) &&
    prevProps.newPositionHealthFactor === nextProps.newPositionHealthFactor &&
    prevProps.isLoadingZap === nextProps.isLoadingZap
  )
}

export const ChangingHealth = React.memo<ChangingHealthProps>(
  ({
    inputValue,
    position,
    newPositionHealth,
    newPositionHealthFactor,
    isLoadingZap,
    isolationModeWarning,
    hasError,
  }: ChangingHealthProps) => {
    const { t } = useTranslation()
    return (
      <ChangingHealthWrapper isolationModeWarning={isolationModeWarning} visible={!!inputValue && !hasError}>
        <ChangingHealthContainer visible={!!inputValue && !hasError}>
          <ChangingValuesTitle>{t('positionHealth')}</ChangingValuesTitle>
          <ChangingValue
            isNegative={
              (!!position.positionHealth &&
                !!newPositionHealth &&
                position.positionHealth.greaterThan(newPositionHealth)) ||
              !position.positionHealth
            }
          >
            <OldValue>
              {position.positionHealth?.greaterThanOrEqual(ZERO_FRACTION) ? (
                position.positionHealth.greaterThan('10') ? (
                  '>10'
                ) : (
                  position.positionHealth.toFixed(2, undefined, Rounding.ROUND_DOWN)
                )
              ) : (
                <AllInclusiveIcon />
              )}
            </OldValue>
            <ArrowRight />
            {isLoadingZap ? (
              <HealthLoadingSpinner>
                <CircularProgress />
              </HealthLoadingSpinner>
            ) : (
              <StyledTooltip
                title={
                  <PositionHealthTooltip
                    positionHealth={
                      newPositionHealth
                        ? parseFloat(newPositionHealth.toFixed(2, undefined, Rounding.ROUND_DOWN))
                        : undefined
                    }
                    positionHealthFactor={newPositionHealthFactor}
                    t={t}
                    isNew={true}
                  />
                }
                placement='top'
                arrow={true}
              >
                <NewValue isNegative={undefined} health={newPositionHealthFactor}>
                  {newPositionHealth?.greaterThanOrEqual(ZERO_FRACTION) ? (
                    newPositionHealth.greaterThan('10') ? (
                      '>10'
                    ) : (
                      newPositionHealth.toFixed(2, undefined, Rounding.ROUND_DOWN)
                    )
                  ) : (
                    <AllInclusiveIcon />
                  )}
                </NewValue>
              </StyledTooltip>
            )}
          </ChangingValue>
        </ChangingHealthContainer>
      </ChangingHealthWrapper>
    )
  },
  ChangingValuesComparator,
)
ChangingHealth.displayName = 'ChangingValuesSection'
