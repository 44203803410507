import { createReducer } from '@reduxjs/toolkit'
import {
  updateDolomiteBalancesWithLoadingIndicator,
  updateFiatPricesWithLoadingIndicator,
  updateInterestRateData,
  updateMarketRiskData,
  /*updateBlockTimestamp,
  updateChainId,
  updateIsSettingChainId,
  updateSubgraphBlockNumber,*/
} from './actions'
import { Fraction } from '@dolomite-exchange/sdk-core'
import { address } from '@dolomite-exchange/dolomite-margin/dist/src/types'
import { MarketRiskInfo } from '../../types/marketRiskInfoData'
import { InterestRate } from '../../types/interestRateData'
import { TokenBalancesWithLoadingIndicator } from '../wallet/hooks'
import { ChainId, ChainIdMap } from '../../constants/chainId'

export interface MarketRiskDataResponse {
  loading: boolean
  error: boolean
  data: Record<address, MarketRiskInfo | undefined>
}

export interface InterestRateDataResponse {
  loading: boolean
  error: boolean
  data: Record<string, InterestRate | undefined>
}

export interface DataState {
  readonly fiatPricesWithLoadingIndicator: ChainIdMap<
    | {
        fiatPriceMap: Record<string, Fraction | undefined>
        isLoading: boolean
      }
    | undefined
  >
  readonly marketRiskData: MarketRiskDataResponse
  readonly interestRateData: ChainIdMap<InterestRateDataResponse | undefined>
  readonly dolomiteBalancesWithLoadingIndicator: TokenBalancesWithLoadingIndicator
  /*readonly blockTimestampMap: Readonly<ChainIdMap<number>>
  readonly subgraphBlockNumberMap: Readonly<ChainIdMap<number>>
  readonly chainId: ChainId
  readonly isSettingChainId: boolean*/
}

const initialState: DataState = {
  fiatPricesWithLoadingIndicator: {
    [ChainId.MAINNET]: undefined,
    [ChainId.ARBITRUM_ONE]: {
      fiatPriceMap: {},
      isLoading: true,
    },
    [ChainId.BASE]: undefined,
    [ChainId.BERACHAIN]: undefined,
    [ChainId.MANTLE]: {
      fiatPriceMap: {},
      isLoading: true,
    },
    [ChainId.POLYGON_ZKEVM]: undefined,
    [ChainId.X_LAYER]: undefined,
  },
  marketRiskData: {
    loading: true,
    error: false,
    data: {},
  },
  interestRateData: {
    [ChainId.MAINNET]: undefined,
    [ChainId.ARBITRUM_ONE]: {
      loading: true,
      error: false,
      data: {},
    },
    [ChainId.BASE]: undefined,
    [ChainId.BERACHAIN]: undefined,
    [ChainId.MANTLE]: {
      loading: true,
      error: false,
      data: {},
    },
    [ChainId.POLYGON_ZKEVM]: undefined,
    [ChainId.X_LAYER]: undefined,
  },
  dolomiteBalancesWithLoadingIndicator: [{}, true],
  /*blockTimestampMap: getCachedValuesOrDefault(KEY_CHAIN_ID_TO_BLOCK_TIMESTAMP_MAP),
  subgraphBlockNumberMap: getCachedValuesOrDefault(KEY_CHAIN_ID_TO_SUBGRAPH_BLOCK_NUMBER_MAP),
  chainId: CHAIN_ID,
  isSettingChainId: false,*/
}

export default createReducer(
  initialState,
  builder =>
    builder
      .addCase(updateFiatPricesWithLoadingIndicator, (state, { payload: { chainId, mapData } }) => {
        state.fiatPricesWithLoadingIndicator[chainId] = mapData
      })
      .addCase(updateMarketRiskData, (state, { payload }) => {
        state.marketRiskData = payload
      })
      .addCase(updateInterestRateData, (state, { payload: { chainId, interestData } }) => {
        state.interestRateData[chainId] = interestData
      })
      .addCase(
        updateDolomiteBalancesWithLoadingIndicator,
        (state, { payload: dolomiteBalancesWithLoadingIndicatorData }) => {
          state.dolomiteBalancesWithLoadingIndicator = dolomiteBalancesWithLoadingIndicatorData
        },
      ),
  /*.addCase(updateBlockTimestamp, (state, action) => {
      const { chainId, blockTimestamp } = action.payload
      state.blockTimestampMap[chainId] = Math.max(blockTimestamp, state.blockTimestampMap[chainId])
      window.localStorage.setItem(KEY_CHAIN_ID_TO_BLOCK_TIMESTAMP_MAP, JSON.stringify(state.blockTimestampMap))
    })
    .addCase(updateSubgraphBlockNumber, (state, action) => {
      const { chainId, subgraphBlockNumber } = action.payload
      state.subgraphBlockNumberMap[chainId] = Math.max(subgraphBlockNumber, state.subgraphBlockNumberMap[chainId])
      window.localStorage.setItem(
        KEY_CHAIN_ID_TO_SUBGRAPH_BLOCK_NUMBER_MAP,
        JSON.stringify(state.subgraphBlockNumberMap),
      )
    })
    .addCase(updateChainId, (state, action) => {
      const { chainId } = action.payload
      window.localStorage.setItem(KEY_CHAIN_ID, chainId.toString())
      state.chainId = chainId
    })
    .addCase(updateIsSettingChainId, (state, { payload: { isSettingChainId } }) => {
      state.isSettingChainId = isSettingChainId
    }),*/
)
