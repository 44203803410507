import { CurrencyAmount, Token } from '@dolomite-exchange/v2-sdk'
import cleanCurrencySymbol from '../../utils/cleanCurrencySymbol'
import { formatAmount } from '../../utils/formatAmount'
import { StyledTooltip } from '../common/StyledTooltip'
import React from 'react'
import styled from 'styled-components/macro'

const Ticker = styled.span`
  color: ${({ theme }) => theme.text2};
  margin-left: 4px;
`

interface Props {
  amount: CurrencyAmount<Token> | undefined
  ignoreTicker?: boolean
}

function AmountWithTooltipComparator(prevProps: Props, nextProps: Props) {
  const prevAmount = prevProps.amount
  const nextAmount = nextProps.amount
  if (prevAmount === undefined && nextAmount === undefined) {
    return true
  } else if (prevAmount === undefined || nextAmount === undefined) {
    return false
  }

  return prevAmount.equalTo(nextAmount) && prevProps.ignoreTicker === nextProps.ignoreTicker
}

function AmountWithFullTooltip({ amount, ignoreTicker }: Props) {
  return (
    <StyledTooltip
      title={`${amount ? amount.toFixed(amount.currency.decimals) : '0.00'} ${
        amount ? cleanCurrencySymbol(amount.currency) : ''
      }`}
      placement={'right'}
      style={{ cursor: 'pointer' }}
    >
      <span>
        {formatAmount(amount)}
        {amount && !ignoreTicker ? <Ticker>{cleanCurrencySymbol(amount.currency)}</Ticker> : null}
      </span>
    </StyledTooltip>
  )
}

export default React.memo(AmountWithFullTooltip, AmountWithTooltipComparator)
