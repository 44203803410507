import { BalanceCheckFlag, BigintIsh, CurrencyAmount, Fraction, Token } from '@dolomite-exchange/v2-sdk'
import {
  ChainId,
  USER_ERROR_CODES,
  MAX_UINT_256,
  ONE_ETH_IN_WEI,
  W_USDM,
  WMNT_ISOLATION_MODE_ADDRESSES,
  ZERO_FRACTION,
} from '../constants'
import { estimateGasAsync, SuccessfulContractCall, useActiveWeb3React } from './index'
import { useTransactionAdder } from '../state/transactions/hooks'
import { useMemo } from 'react'
import { calculateGasMargin, mapExternalTokenToListedAddress } from '../utils'
import { Web3CallbackState } from './useTradeCallback'
import {
  useDepositWithdrawalProxyContract,
  useDolomiteMarginContract,
  useIsolationModeUserVaultContract,
  useIsolationModeUserVaultPayableContract,
  useIsolationModeVaultFactoryContract,
  useUsdmRouterContract,
} from './useContract'
import { SignatureDataObject } from './usePermitOrApprove'
import { ApprovalState } from './useApproveCallback'
import { Currency } from '@dolomite-exchange/sdk-core'
import { useSingleCallResult, useSingleContractMultipleData } from '../state/multicall/hooks'
import JSBI from 'jsbi'
import { InterestIndex } from '../data/InterestIndex'
import { useDolomiteMarginTokenData } from '../types/dolomiteMarginTokenData'
import { useMarketsTotalParData } from '../types/totalParData'
import { useAllTokens } from './Tokens'
import { address } from '@dolomite-exchange/dolomite-margin/dist/src/types'
import useIsolationModeUserVaultAddressIfCreated from './useIsolationModeUserVaultAddressIfCreated'
import { useSpecialAsset } from '../constants/isolation/special-assets'
import { formatAmount } from '../utils/formatAmount'
import cleanCurrencySymbol from '../utils/cleanCurrencySymbol'
import { USDM } from '../constants/tokens/USDM'

export function useDolomiteMarginTokenAddressToIdMap(): Record<address, JSBI | undefined> {
  const { data: tokenAddressMap } = useDolomiteMarginTokenData()
  const serializedMap = useMemo(() => {
    const tokenToMarketIdMap = Object.values(tokenAddressMap).reduce<Record<string, string>>((memo, token) => {
      memo[token.address] = token.marketId.toString()
      return memo
    }, {})
    return JSON.stringify(tokenToMarketIdMap)
  }, [tokenAddressMap])

  return useMemo(() => {
    const tokenToMarketIdStringMap = JSON.parse(serializedMap)
    return Object.keys(tokenToMarketIdStringMap).reduce((memo, key) => {
      memo[key] = JSBI.BigInt(tokenToMarketIdStringMap[key])
      return memo
    }, {} as Record<address, JSBI | undefined>)
  }, [serializedMap])
}

export function useDolomiteMarginTokenIdMap(): Record<string, string> {
  const map = useDolomiteMarginTokenAddressToIdMap()

  const serializedMap = useMemo(() => {
    const marketIdToTokenMap = Object.keys(map).reduce<Record<string, string>>((memo, tokenAddress) => {
      const marketId = map[tokenAddress]
      memo[marketId?.toString() ?? ''] = tokenAddress
      return memo
    }, {})
    return JSON.stringify(marketIdToTokenMap)
  }, [map])

  return useMemo(() => JSON.parse(serializedMap), [serializedMap])
}

export function useMarketIndices(): [Record<string, InterestIndex | undefined>, boolean] {
  const contract = useDolomiteMarginContract()
  const tokenIdMap = useDolomiteMarginTokenIdMap()
  const tokenIds = useMemo(() => Object.keys(tokenIdMap).map(key => [key]), [tokenIdMap])
  const callStates = useSingleContractMultipleData(contract, 'getMarketCurrentIndex', tokenIds)
  return useMemo(() => {
    const indexMap = callStates.reduce<Record<string, InterestIndex>>((memo, callState, index) => {
      const tuple = callState.result?.[0]
      if (tuple) {
        const tokenAddress = tokenIdMap[tokenIds[index][0]]
        const borrowIndex = new Fraction(tuple[0], ONE_ETH_IN_WEI)
        const supplyIndex = new Fraction(tuple[1], ONE_ETH_IN_WEI)
        memo[tokenAddress] = {
          marketId: JSBI.BigInt(index.toString()),
          tokenAddress: tokenAddress,
          borrowIndex: borrowIndex,
          supplyIndex: supplyIndex,
          lastUpdate: new Date(tuple[2] * 1000),
        }
      }
      return memo
    }, {})
    return [indexMap, callStates.some(state => state.loading)]
  }, [callStates, tokenIdMap, tokenIds])
}

export interface MarketTotalWei {
  supplyWei: CurrencyAmount<Token>
  borrowWei: CurrencyAmount<Token>
}

export function useMarketsTotalWeiData(): [Record<address, MarketTotalWei | undefined>, boolean] {
  const tokenMap = useAllTokens()
  const { data: totalParMap, loading: isTotalParMapLoading } = useMarketsTotalParData()
  const [marketIndexMap, isMarketIndexMapLoading] = useMarketIndices()

  return useMemo(() => {
    const totalWeiMap = Object.keys(totalParMap).reduce<Record<string, MarketTotalWei | undefined>>(
      (memo, tokenAddress) => {
        const totalPar = totalParMap[tokenAddress]
        const marketIndex = marketIndexMap[tokenAddress]
        const token = tokenMap[tokenAddress]
        if (!totalPar || !marketIndex || !token) {
          return memo
        }

        memo[tokenAddress] = {
          supplyWei: totalPar.supplyPar.multiply(marketIndex.supplyIndex),
          borrowWei: totalPar.borrowPar.multiply(marketIndex.borrowIndex),
        }
        return memo
      },
      {},
    )

    return [totalWeiMap, isTotalParMapLoading || isMarketIndexMapLoading]
  }, [isMarketIndexMapLoading, isTotalParMapLoading, marketIndexMap, tokenMap, totalParMap])
}

export function useAccountUsdValues(
  account: string | undefined,
  number: BigintIsh,
): [{ suppliedUSD?: Fraction; borrowedUSD?: Fraction }, boolean] {
  const inputs = useMemo(
    () => [
      {
        owner: account,
        number: number.toString(),
      },
    ],
    [account, number],
  )
  const contract = useDolomiteMarginContract()
  const callState = useSingleCallResult(contract, 'getAccountValues', inputs)
  return useMemo(() => {
    const result = callState.result
    if (result) {
      const denominator = '1000000000000000000000000000000000000'
      return [
        {
          suppliedUSD: new Fraction(result[0].toString(), denominator),
          borrowedUSD: new Fraction(result[1].toString(), denominator),
        },
        callState.loading,
      ]
    } else {
      return [
        {
          suppliedUSD: undefined,
          borrowedUSD: undefined,
        },
        callState.loading,
      ]
    }
  }, [callState])
}

function isOldProxyContract(chainId: ChainId): boolean {
  return [ChainId.ARBITRUM_ONE, ChainId.BASE, ChainId.MANTLE, ChainId.POLYGON_ZKEVM, ChainId.X_LAYER].includes(chainId)
}

export function useDepositIntoDolomiteMarginProtocol(
  approvalState: ApprovalState,
  signatureData: SignatureDataObject | null,
  currency: Currency | undefined,
  amount: CurrencyAmount<Currency> | undefined,
): { state: Web3CallbackState; callback: null | (() => Promise<string>); error: string | null } {
  const { account, chainId, library } = useActiveWeb3React()

  const depositWithdrawalProxyContract = useDepositWithdrawalProxyContract()
  const usdmRouterContract = useUsdmRouterContract()
  const isolationModeVaultAddress = useIsolationModeUserVaultAddressIfCreated(amount?.currency.wrapped)
  const isolationModeVaultContract = useIsolationModeUserVaultContract(isolationModeVaultAddress)
  const isolationModeVaultPayableContract = useIsolationModeUserVaultPayableContract(isolationModeVaultAddress)
  const specialAsset = useSpecialAsset(amount?.currency.wrapped)
  const isolationModeVaultFactoryContract = useIsolationModeVaultFactoryContract(
    specialAsset?.chainIdToAddressMap[chainId],
  )
  const tokenAddressToMarketId = useDolomiteMarginTokenAddressToIdMap()
  const addTransaction = useTransactionAdder()
  return useMemo(() => {
    if (
      !depositWithdrawalProxyContract ||
      !library ||
      !account ||
      !chainId ||
      !currency ||
      !amount ||
      amount.equalTo(ZERO_FRACTION)
    ) {
      return {
        state: Web3CallbackState.INVALID,
        callback: null,
        error: 'Missing dependencies',
      }
    }

    const marketId = tokenAddressToMarketId[mapExternalTokenToListedAddress(currency.wrapped)]
    if (!marketId) {
      return {
        state: Web3CallbackState.INVALID,
        callback: null,
        error: `Could not find market ID for ${currency.symbol}`,
      }
    }

    const usdm = USDM[chainId]

    let contract = depositWithdrawalProxyContract
    let methodName = currency.isNative
      ? !isOldProxyContract(chainId)
        ? 'depositPayableIntoDefaultAccount'
        : 'depositETHIntoDefaultAccount'
      : 'depositWeiIntoDefaultAccount'
    let args: any[] = currency.isNative ? [] : [marketId.toString(), amount.quotient.toString()]
    let value = '0'

    if (specialAsset?.isIsolationMode) {
      const toAccountNumber = 0 // must be 0 for now, enforced on the contract
      if (
        isolationModeVaultPayableContract &&
        chainId === ChainId.MANTLE &&
        WMNT_ISOLATION_MODE_ADDRESSES[chainId] === amount.currency.wrapped.address
      ) {
        contract = isolationModeVaultPayableContract
        methodName = 'depositPayableIntoVaultForDolomiteMargin'
        args = [toAccountNumber]
        value = amount.quotient.toString()
      } else if (isolationModeVaultContract) {
        contract = isolationModeVaultContract
        methodName = 'depositIntoVaultForDolomiteMargin'
        args = [toAccountNumber, amount.quotient.toString()]
      } else if (isolationModeVaultFactoryContract) {
        // the user's vault is not created yet. Create and deposit in 1 transaction
        contract = isolationModeVaultFactoryContract
        methodName = 'createVaultAndDepositIntoDolomiteMargin'
        args = [toAccountNumber, amount.quotient.toString()]
      } else {
        // should not happen ever!
        return {
          state: Web3CallbackState.INVALID,
          callback: null,
          error: 'Missing isolation mode vault factory dependency',
        }
      }
    } else if (usdm?.equals(currency)) {
      const fromAccountNumber = 0
      if (usdmRouterContract) {
        contract = usdmRouterContract
        methodName = 'depositUSDM'
        args = [fromAccountNumber, amount.quotient.toString()]
      } else {
        // the user's vault is not created yet (this should not happen). Error out.
        return {
          state: Web3CallbackState.INVALID,
          callback: null,
          error: `Could not find USDM Router for chain ${chainId}`,
        }
      }
    }

    return {
      state: Web3CallbackState.VALID,
      callback: async function onDeposit(): Promise<string> {
        if (approvalState !== ApprovalState.APPROVED && !signatureData) {
          return Promise.reject(
            new Error('Attempting to deposit without approval or a signature. Please contact support.'),
          )
        }

        const options = { value: currency.isNative ? amount.quotient.toString() : value }
        const estimatedCall = await estimateGasAsync(contract, methodName, args, options)
        let successfulCall: SuccessfulContractCall
        if ('gasEstimate' in estimatedCall) {
          successfulCall = estimatedCall
        } else {
          throw estimatedCall.error
        }
        return contract[methodName](...args, {
          gasLimit: calculateGasMargin(successfulCall.gasEstimate),
          from: account,
          value: currency.isNative ? amount.quotient.toString() : value,
        })
          .then((response: any) => {
            const vault = specialAsset?.isolationModeInfo?.remapAccountAddress?.(account, chainId)
            addTransaction(response, {
              summary: `Deposit ${formatAmount(amount)} ${cleanCurrencySymbol(currency, false)} into Dolomite`,
              vaultCreation:
                methodName === 'createVaultAndDepositIntoDolomiteMargin' && vault
                  ? {
                      vault,
                      account,
                    }
                  : undefined,
            })
            return response.hash
          })
          .catch((error: any) => {
            // if the user rejected the tx, pass this along
            if (error?.code === USER_ERROR_CODES.REJECTED) {
              throw new Error('transaction-rejected')
            } else {
              // otherwise, the error was unexpected, and we need to convey that
              console.error(`Deposit into DolomiteMargin failed`, error, methodName, args)
              throw new Error(`Deposit into DolomiteMargin failed: ${error.message}`)
            }
          })
      },
      error: null,
    }
  }, [
    depositWithdrawalProxyContract,
    library,
    account,
    chainId,
    currency,
    amount,
    tokenAddressToMarketId,
    specialAsset,
    isolationModeVaultContract,
    isolationModeVaultPayableContract,
    isolationModeVaultFactoryContract,
    usdmRouterContract,
    approvalState,
    signatureData,
    addTransaction,
  ])
}

export function useWithdrawFromDolomiteMarginProtocol(
  currency: Currency | undefined,
  amount: CurrencyAmount<Currency> | undefined,
  isWithdrawAll: boolean,
  balanceCheckFlag: BalanceCheckFlag,
  isUnwrapSelected: boolean,
): { state: Web3CallbackState; callback: null | (() => Promise<string>); error: string | null } {
  const { account, chainId, library } = useActiveWeb3React()
  const specialAsset = useSpecialAsset(amount?.currency.wrapped)

  const depositWithdrawalProxyContract = useDepositWithdrawalProxyContract()
  const usdmRouterContract = useUsdmRouterContract()
  const isolationModeVaultAddress = specialAsset?.isolationModeInfo?.remapAccountAddress?.(account, chainId)
  const isolationModeVaultContract = useIsolationModeUserVaultContract(isolationModeVaultAddress)
  const isolationModeVaultPayableContract = useIsolationModeUserVaultPayableContract(isolationModeVaultAddress)
  const tokenAddressToMarketId = useDolomiteMarginTokenAddressToIdMap()
  const addTransaction = useTransactionAdder()

  return useMemo(() => {
    if (!depositWithdrawalProxyContract || !library || !account || !chainId || !currency || !amount) {
      return {
        state: Web3CallbackState.INVALID,
        callback: null,
        error: 'Missing dependencies',
      }
    }

    const marketId = tokenAddressToMarketId[currency.wrapped.address]
    if (!marketId) {
      return {
        state: Web3CallbackState.INVALID,
        callback: null,
        error: `Could not find market ID for ${currency.symbol}`,
      }
    }

    const wusdm = W_USDM[chainId]
    const withdrawalAmountArg = isWithdrawAll ? MAX_UINT_256.toString() : amount.quotient.toString()

    let contract = depositWithdrawalProxyContract
    let methodName =
      wusdm?.equals(currency) && isUnwrapSelected
        ? 'withdrawUSDM'
        : currency.isNative
        ? !isOldProxyContract(chainId)
          ? 'withdrawPayableFromDefaultAccount'
          : 'withdrawETHFromDefaultAccount'
        : 'withdrawWeiFromDefaultAccount'
    let args: any[] = currency.isNative
      ? [withdrawalAmountArg, balanceCheckFlag.toString()]
      : [marketId.toString(), withdrawalAmountArg, balanceCheckFlag.toString()]

    if (specialAsset?.isIsolationMode) {
      const fromAccountNumber = 0 // must be 0 for now, enforced on the contract
      if (
        isolationModeVaultPayableContract &&
        chainId === ChainId.MANTLE &&
        WMNT_ISOLATION_MODE_ADDRESSES[chainId] === amount.currency.wrapped.address
      ) {
        contract = isolationModeVaultPayableContract
        methodName = 'withdrawPayableFromVaultForDolomiteMargin'
        args = [fromAccountNumber, amount.quotient.toString()]
      } else if (isolationModeVaultContract) {
        contract = isolationModeVaultContract
        methodName = 'withdrawFromVaultForDolomiteMargin'
        args = [fromAccountNumber, amount.quotient.toString()]
      } else {
        // the user's vault is not created yet (this should not happen). Error out.
        return {
          state: Web3CallbackState.INVALID,
          callback: null,
          error: 'Isolation mode vault not found',
        }
      }
    } else if (wusdm?.equals(currency) && isUnwrapSelected) {
      const fromAccountNumber = 0 // must be 0 for now, enforced on the contract
      if (usdmRouterContract) {
        contract = usdmRouterContract
        methodName = 'withdrawUSDM'
        args = [fromAccountNumber, amount.quotient.toString(), BalanceCheckFlag.Both]
      } else {
        // the user's vault is not created yet (this should not happen). Error out.
        return {
          state: Web3CallbackState.INVALID,
          callback: null,
          error: `Could not find USDM Router for chain ${chainId}`,
        }
      }
    }

    return {
      state: Web3CallbackState.VALID,
      callback: async function onWithdraw(): Promise<string> {
        const estimatedCall = await estimateGasAsync(contract, methodName, args)
        let successfulCall: SuccessfulContractCall
        if ('gasEstimate' in estimatedCall) {
          successfulCall = estimatedCall
        } else {
          throw estimatedCall.error
        }

        return contract[methodName](...args, {
          gasLimit: calculateGasMargin(successfulCall.gasEstimate),
          from: account,
        })
          .then((response: any) => {
            const readableAmount = isWithdrawAll ? 'all' : formatAmount(amount)
            const summary = `Withdraw ${readableAmount} ${cleanCurrencySymbol(currency, false)} from Dolomite`
            addTransaction(response, { summary })
            return response.hash
          })
          .catch((error: any) => {
            // if the user rejected the tx, pass this along
            if (error?.code === USER_ERROR_CODES.REJECTED) {
              throw new Error('transaction-rejected')
            } else {
              // otherwise, the error was unexpected, and we need to convey that
              console.error(`Withdraw from DolomiteMargin failed`, error, methodName, args)
              throw new Error(`Withdraw from DolomiteMargin failed: ${error.message}`)
            }
          })
      },
      error: null,
    }
  }, [
    depositWithdrawalProxyContract,
    library,
    account,
    chainId,
    currency,
    amount,
    tokenAddressToMarketId,
    isWithdrawAll,
    isUnwrapSelected,
    balanceCheckFlag,
    specialAsset?.isIsolationMode,
    isolationModeVaultContract,
    isolationModeVaultPayableContract,
    usdmRouterContract,
    addTransaction,
  ])
}
